const SubscribeAPI = {

  subscribe(successfulPage,errorPage,type,email) {
    return config.API.post("/month/subscribe",{urlOk:successfulPage,urlNo:errorPage,type:type,email:email});
  },
  cancel() {
    return config.API.get("/month/subscribe/end")
  },
  checkExists() {
    return config.API.get("/month/subscribe/checkExists")
  },
  getToken(id_transaction,reqType) {
    return config.API.post("/getToken", {id_transaction:id_transaction,reqType:reqType})
  },
  getTokenPresent(id_transaction, reqType)
  {
    return config.API.post("/getTokenPresent", {id_transaction:id_transaction,reqType:reqType})
  },
  checkCoupon(coupon) {
    return config.API.post("/subscribe/checkCoupon", {coupon:coupon})
  },
  subscribeViaCoupon(coupon) {
    return config.API.post("/subscribe/subscribeViaCoupon", {coupon:coupon})
  },
  getPrice(){
    return config.API.get("/getPrice");
  },
  getPrices(){
    return config.API.get("/getPrices");
  },
  subscribeViaSchoolCoupon(coupon,class_name, class_num) {
    return config.API.post("/subscribe/subscribeViaSchoolCoupon", {coupon:coupon,class_name:class_name,class_num:class_num});
  },
  sendCode(data){
    return config.API.post("/sendCode",data);
  },
  sendCodeEmail(data)
  {
    return config.API.post("/sendCodeEmail",data);
  }
};

export { SubscribeAPI }