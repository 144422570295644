<template>
  <app-loading :loading="loading.sync">
  <div class="goal-wrapper" v-if="!loading.sync">

    <div style="padding:0 0 20px 0" class="select-wrapper">
      <SelectClass
        style="margin: 0 auto;width: 145px"
        :style="{direction:direction}"
        :ltr="direction==='ltr'"
        @input="filterTeacher"
        class="select-i"
        placeholder="Chose option"
        :options="teachers.array"
        :key="lang"
        v-model="teachers.selected"/>
      <SelectTeacher
        :ltr="direction==='ltr'"
        :style="{direction:direction}"
        style="margin: 0 auto"
        @input="filterDate"
        class="select-i"
        placeholder="Chose option"
        :key="lang"
        :options="getTranslatedDates.dates"
        v-model="before.selected"/>
    </div>

    <div class="content-info">
      <div class="question-wrapper">
        <FreeActivity
          :open="swiped==='free'"
          :goals="goals"
          :total="data.played.students"
          :teacher="data.played.teacher"
        />
        <Creativity
          :open="swiped==='creativity'"
          :total_playlist="data.request.total_playlist"
          :total_shootlist="data.request.total_shootlist"
          :playlists="data.request.playlist"
          :shootlists="data.request.shootlist"
           />
        <ClassroomGames
          :open="swiped==='games'"
          :gameClass="data.gameClass"
           />
        <EducationTime
          :open="swiped==='education'"
          :educationClass="data.educationClass"
          v-if="check(teachers.selected).position==='teacher'"
          />


        <GamesInvention
          v-if="check(teachers.selected).position==='teacher'"
          :value="$ml.get('הפעלת קורס המצאת משחקים')"
          :title="$ml.get('קורס המצאת משחקים')" />
        <GamesInvention
          v-if="check(teachers.selected).position==='teacher'"
          value="$ml.get('הפעלת קורס המצאת משחקים')"
          title="$ml.get('תורני משחקים')"  />
      </div>
    </div>

  </div>
  </app-loading>
</template>

<script>

import FreeActivity from "./reach-goals/FreeActivity";
import Creativity from "./reach-goals/Creativity";
import ClassroomGames from "./reach-goals/ClassroomGames";
import EducationTime from "./reach-goals/EducationTime";
import GamesInvention from "./reach-goals/GamesInvention";
import SelectTeacher from "../SelectTeacher";
import SelectClass from "../SelectClass";

import {TeacherAPI, DirectorAPI} from "@/api";
import axios from "axios";

export default {
  components: {
    EducationTime,
    ClassroomGames,
    Creativity,
    SelectClass,
    FreeActivity,
    GamesInvention,
    SelectTeacher,
  },
  props: {
    swiped:{

    }
  },
  created() {
    this.loading.sync = true;
    let month = new Date().getMonth() + 1;


    this.before.selected = this.getTranslatedDates.selected;



    DirectorAPI.getClasses().then(res => {
      this.teachers.array.push({position: 'all_school', name: 'כל מחנכות בית הספר', id_user: ''});
      res.data.forEach(item => {
        if (item.class!==''){
          item.name = item.class;
          this.teachers.array.push(item);
        }
      });
      DirectorAPI.getProf().then(res => {
        this.teachers.array.push({position: 'all_prof', name: 'כל המורים המקצועים', id_user: ''});
        res.data.forEach(item => {
          this.teachers.array.push(item);
        });
        this.teacherTranslate();
        axios.post('/director/report/fullreport', {
          dateFilter: this.checkDate(this.before.selected),
          filter: this.check(this.teachers.selected).filter,
          id_teacher: this.check(this.teachers.selected).id,
        }).then(res=>{
          this.data = res.data.data;
          this.loading.sync = false;
        });
      }).catch();
    }).catch();

    this.$emit('title', this.currentSelect)

  },
  methods: {
    check(value) {
      if (this.allProf.find(item=> {return item.name===value})) {
        return {filter: 'all_prof'}

      } else if (this.allT.find(item=> {return item.name===value})) {
        return {filter: 'all_school'}
      } else {
        let user = this.teachers.array.filter((item) => {
          return item.name === value
        });
        return user[0].position==='prof-teacher' ? {filter: user[0].position, id: user[0].id_user} : {class: user[0].class, class_number: user[0].class_number, filter: 'class' }
      }
    },
    teacherTranslate(){
      this.teachers.array = this.teachers.array.map(item=>{
        if(item.position==='all_school'){
          item.name = this.allT.filter(item=>{ 
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          })[0].name
        } else if( item.position==='all_prof'){
          item.name = this.allProf.filter(item=>{ 
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          })[0].name
        }
        return item
      });
      this.currentSelect = this.teachers.array[0];
      this.teachers.selected = this.teachers.array[0].name;
    },
    checkDate(data) {
      return this.before.options.filter(item => {
        return item.name === data;
      })[0].index;
    },
    filterDate(data) {

      axios.post('/director/report/fullreport',
          {
            dateFilter: this.checkDate(data),
            filter: this.check(this.teachers.selected).filter,
            id_teacher: this.check(this.teachers.selected).id,
            class:this.check(this.teachers.selected).class,
            class_number: this.check(this.teachers.selected).class_number,
          }).then(res => {
        this.data = res.data.data;
      }).catch();
    },
    filterTeacher(data) {
      this.teachers.selected = data;
      axios.post('/director/report/fullreport',
          {
            dateFilter: this.checkDate(this.before.selected),
            filter: this.check(data).filter,
            id_teacher: this.check(data).id,
            class:this.check(data).class,
            class_number: this.check(data).class_number,
          }).then(res => {
        this.data = res.data.data;
      }).catch();
      this.currentSelect = this.teachers.array.filter(item=>{
        return item.name === data;
      })[0];

      this.$emit('title', this.currentSelect);
    },
  },
  computed:{
    getTranslatedDates(){
      return this.$store.getters['lang-store/dates'];
    },
    lang(){
      return this.$store.getters['lang-store/data']
    },
    direction(){
      return this.$store.getters['lang-store/direction']
    }
  },
  watch:{
    lang(){
      // this.before.filtered = this.getTranslatedDates.dates;
      this.before.selected = this.getTranslatedDates.selected;
      this.teacherTranslate();
    }
  },
  data() {
    return {
      data: [],
      loading: {
        sync: false,
      },
      currentSelect: {name: 'כל מחנכות בית הספר'},
      teachers: {
        array: [],
        selected: '',
      },
      allT: [{lang: 'heb',name:'כל המורים המקצועים'}, {lang: 'en',name : 'All professional teachers'}, {lang: 'ru',name: 'Все проф учителя'} ,{lang: 'ar',name : 'جميع المعلمين المحترفين'}],
      allProf:[{lang: 'heb',name: 'כל מחנכות בית הספר'}, {lang: 'en',name: 'All teachers'}, {lang: 'ru',name: 'Все учителя'}, {lang: 'ar',name: 'كل معلمي المدارس'}] ,
      goals: [

      ],

      before: {
        options: [
          {name: 'שנה', str: 'Year', index: 0},
          {name: 'ינואר', str: 'Jan', index: 1},
          {name: 'פברואר', str: 'Feb', index: 2},
          {name: 'מרץ', str: 'Mar', index: 3},
          {name: 'אפריל', str: 'Apr', index: 4},
          {name: 'מאי', str: 'May', index: 5},
          {name: 'יוני', str: 'Jun', index: 6},
          {name: 'יולי', str: 'Jul', index: 7},
          {name: 'אוגוסט', str: 'Aug', index: 8},
          {name: 'ספטמבר', str: 'Sep', index: 9},
          {name: 'אוקטובר', str: 'Oct', index: 10},
          {name: 'נובמבר', str: 'Nov', index: 11},
          {name: 'דצמבר', str: 'Dec', index: 12},
        ],
        filtered: [],
        selected: 'שנה',
      }
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.goal-wrapper {
  width: 100%;
  .select-wrapper{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 300px;
    align-items: center;
    justify-content: space-around;
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    color: #333333;
  }
}

</style>
