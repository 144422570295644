<template>
  <div class="checkbox-wrapper">
    <slot v-if="!disable" :setValue="changeValue"/>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      // default: [],
      type: Array,
    },
    disable: {
      default: false,
    },
  },
  methods: {
    changeValue(value) {

      const new_array = [ ...this.value ];
        if(this.value.includes(value)) {
          const index = this.value.indexOf(value);
          new_array.splice(index, 1);
          this.$emit('input', new_array);

        } else {
          new_array.push(value);
          this.$emit('input', new_array);
        }

    }
  },
}
</script>

<style>

</style>