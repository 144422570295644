<template>
  <main class="img-but-subs-bg" dir="rtl">
    <!-- SUBSCRIBE TO PEWDIEPIE -->
    <simple-header>
      <span slot="title">רכישת מנוי</span>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </simple-header>
    <div class="sm-cont">
      <div class="top">
        <img src="@/assets/img/idea-subs.svg" alt="" />
      </div>
      <div class="buttons">
        <div class="line">
          <button @click="subscribeSched()" class="theme-button-outline">
            מנוי חודשי
          </button>
          <div class="text">
            {{ prices.month }} לחודש
          </div>
        </div>
        <div class="line">
          <button @click="subscribe('games')" class="theme-button-outline">
            {{ prices.games }}
            ש“ח
          </button>
          <div class="text">
            מנוי ל -{{ prices.games_num }}
            משחקים
          </div>
        </div>
        <div class="line">
          <button @click="subscribe('month')" class="theme-button-outline">
            {{ prices.month }}
            ש“ח
          </button>
          <div class="text">
            מנוי לחודש
            <div class="subtext">
              משחקים ללא הגבלה
            </div>
          </div>
        </div>
        <div class="line">
          <button @click="subscribe('six')" class="theme-button-outline">
            {{ prices.six }} ש“ח
          </button>
          <div class="text">
            מנוי לחצי שנה
            <div class="subtext">
              משחקים ללא הגבלה
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">במידה ויש ברשותכם קוד קופון לחצו כאן</div>
        <br />
        <button @click="usePromo" class="promo theme-button-outline">
          קוד קופון
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import Payment from "@/components/modals/Payment.vue";
import PromoCode from "@/components/modals/PromoCode.vue";
import SidebarToggler from "@/components/SidebarToggler";
import { SubscribeAPI } from '@/api';

export default {
  data() {
    return {
      sent: false,
      prices: {

      }
    };
  },
  methods: {

    getPrices() {
      axios.get("/variable/prices").then(res => {
        this.prices = res.data.value;
      });
    },

    async startTransaction(type) {
      const data = await axios.get("/subscribe/url/" + type)
        .then(res => res.data);

      this.$modal.show(
        Payment,
        {
          url: data.url,
          id_transaction: data.id_transaction,
          ended: () => {
            this.$store.commit("playlist/reset");
            this.$store.dispatch("user/updateData");
            this.$router.replace("/account");
          }
        },
        {
          adaptive: true,
          height: "90%",
          width: "90%"
        }
      );
    },

    async subscribeSched() {
      let successfulPage=window.location.origin+'/#/successfulPayment';
      let errorPage=window.location.origin+'/#/errorPayment';
      const data = await SubscribeAPI.subscribe(successfulPage,errorPage)
          .then(res => res.data);

      this.$modal.show(
        Payment,
        {
          url: data.url,
          id_transaction: data.id_transaction,
          type: 'subs',
          ended: () => {
            this.$store.commit("playlist/reset");
            this.$store.dispatch("user/updateData");
            this.$router.replace("/account");
          }
        },
        {
          adaptive: true,
          height: "90%",
          width: "90%"
        }
      );
    },

    usePromo() {
      this.$modal.show(
        PromoCode,
        {},
        {
          height: "auto",
          width: 300
        }
      );
    },

    subscribe(type) {
      if (this.sent) return;
      this.sent = true;
      this.startTransaction(type).then(() => {
        this.sent = false;
      });
    }
  },
  components: {
    SimpleHeader,
    SidebarToggler,
  },
  created() {
    this.$router.push({name: 'main'});
    this.getPrices();
  }
};
</script>

<style lang="scss" scoped>
main {
  color: #fff;
  background-color: #005d7a;
  background-position: bottom -40px right -40px;
  background-repeat: no-repeat;
  background-size: auto;
  padding-bottom: 200px;
  .top {
    padding: 0 15px;
    text-align: left;
  }
  .buttons {
    border: 1px solid #fff;
    border-left: 0;
    border-right: 0;
    margin: 0 15px;
    padding: 15px 0;
    .line {
      padding: 10px 0;
      line-height: 40px;
      position: relative;
      .subtext {
        position: absolute;
        line-height: 0;
        font-size: 10px;
        bottom: 10px;
      }
      button {
        width: 120px;
        background-color: #e36574;
        float: left;
      }
    }
  }
  .bottom {
    padding: 15px;
    width: 100%;
    padding-top: 25px;

    .title {
      text-align: center;
    }
  }
  .promo {
    width: 100%;
  }
}
</style>
