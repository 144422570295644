<template>
  <div class="modal" dir="rtl">
    <p class="big">

      <span> {{$ml.get('תודה')}} </span>
      <span><span>{{(user.surname ? user.surname : '')}}</span>
      <span>{{  ' ' +user.name}}</span></span>
    </p>
    <p>


     <span>{{$ml.get('השאלה שלך נשלחה ונמצאת בבדיקה')}}</span>
        <span> {{$ml.get('נחזור אליך בהקדם')}} </span>
    </p>

    <button @click="$emit('close')" class="theme-button-outline">
      {{$ml.get('חזרה למדברים בכיף')}}
    </button>
  </div>
</template>

<script>
export default {
  computed:{
    user(){
      return this.$store.getters['user/data'];
    },
  }
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    font-size: 50px;
  }
  p {
    max-width: 214px;
    margin: 5px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    &.big{
      font-size: 16px;
    }
  }
  .theme-button-outline {
    margin-top: 20px;
    margin-bottom: 5px;
    background: #005D79;
    border-radius: 18px;
    color: white;
    font-size: 14px;
  }
  .nope {
    color: #005d7a;
    font-size: 16px;
  }
}
</style>
