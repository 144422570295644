<template>
  <div class="main" style="background: #005d79;">
    <div class="main-wrapper sm-cont" style="background: #fff;">
      <simple-header  route="/main">
        <template v-slot:title>

          <span>{{$ml.get("התאמת שאלות לשיחה")}}</span>
        </template>
        <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
      </simple-header>
      <app-loading v-if="loading==true">
        <template>
          <div :style="{direction:direction}" class="small" dir="rtl">
            
            <div v-if="user.position!==null && user.position!=='director' " class="ability-wrapper">

            </div>
            <div class="ability-wrapper">

              <div style="margin-top: 17px;" :style="direction==='ltr'? 'padding-left: 10px;' :''" class="block-title">{{$ml.get(" ")}}</div>
              <div v-if="categ" :key="rend" style="margin-top: 22px;"  class="abilities">
                <div v-for="(s,index) in catsskelet" :key="index" class="icon-wrapper">
                  <div class="circle"></div>
                </div>

                <div class="name"><div class="line h17 w40 m10"></div></div>

              </div>
            </div>
            <div class="level-wrapper ">

              <div class="level-title">{{$ml.get("")}}</div>
              <div class="levels-wrapper" style="margin-top: 8px; margin-bottom: 300px;">

                <div v-for="(s,index) in sectsskelet" :key="index" class="icon-wrapper">
                  <div class="circle-second"></div>
                </div>

                <div class="name"><div class="line-second h17 w40 m10"></div></div>
                
              </div>
            </div>

            <div :style="direction==='ltr'? 'padding-left: 10px;' :''" class="title-main">{{$ml.get("")}}</div>
            <div class="players-names-wrapper" style="margin-top: 40px;">
              <div class="icon-wrapper">
                <div class="name"><div class="line-third1 h17 w40-1 m10"></div></div>
                <div class="name"><div class="line-third2 h17 w40-1 m10"></div></div>
                <div class="name"><div class="line-third3 h17 w40-1 m10"></div></div>
                <div class="name"><div class="line-third4 h17 w40-1 m10"></div></div>
                </div>

                <div class="name"><div class="line-third h17 w40 m10"></div></div>
            </div>

            <!-- <app-btn :loading="loading"  @click="update"  class="save-b">{{$ml.get("שמור")}}</app-btn> -->
          </div>
        </template>
      </app-loading>

      <app-loading v-else class="sm-cont" >
        <template>
          <div :style="{direction:direction}" class="small" dir="rtl">
            
            <div v-if="user.position!==null && user.position!=='director' " class="ability-wrapper">

            </div>
            <div class="ability-wrapper">

              <div style="margin-top: 17px;" :style="direction==='ltr'? 'padding-left: 10px;' :''" class="block-title">{{$ml.get("סגנון השאלות")}}:</div>
              <div v-if="categ" :key="rend"  class="abilities">
                <CheckboxWrapper
                  v-model="selected.categories"
                  :key="cat.id"
                  v-for="cat in categ"
                  :style="selected.categories.includes(cat.id) ? {background:cat.color, border:'1px solid '+cat.color} : {border:'1px solid '+cat.color}"
                  :class="[selected.categories.includes(cat.id) ? 'active' : 'unactive' ]">
                  <template v-slot="{setValue}">
                    <div
                      @click="setValue(cat.id)"
                      class="checkbox-slot">
                      <img :src="cat.img?  cat.img_url : ''" alt="">
                      <NameTranslated class="title" :translate="cat.translation" />
                    </div>
                  </template>
                </CheckboxWrapper>

              </div>
            </div>
            <div class="level-wrapper ">

              <div class="level-title">{{$ml.get("מספר המשתתפים")}}:</div>
              <div class="levels-wrapper">


                <RadioWrapper
                  :key="index*33" v-for="(item, index) in people.arr"
                  v-model="selected.people"
                  :class="[selected.people === item ? 'active' : 'unactive' ]"                >
                  <template v-slot="{setValue}">
                    <div
                      @click="setValue(item)"
                      class="checkbox-slot">
                      <div class="title">{{item}}</div>
                    </div>
                  </template>
                </RadioWrapper>
              </div>
            </div>

            <div :style="direction==='ltr'? 'padding-left: 10px;' :''" class="title-main">{{$ml.get("שמות המשתתפים")}}:</div>
            <div class="players-names-wrapper">
              <PlayersNames :key="index*3" v-model="selected.names[index]" :counter="index" v-for="(name, index) in  parseInt(selected.people)"  />
            </div>
              <div class="btns">
                <app-btn :loading="loadingSend"  @click="update"  class="save-b">{{$ml.get("מתחילים לדבר")}}</app-btn>
                </div>
          </div>
        </template>
      </app-loading>

    </div>
  </div>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader";
  import SidebarToggler from "@/components/SidebarToggler";
  import CheckboxWrapper from "@/components/CheckboxWrapper";
  import RadioWrapper from "@/components/playlist/playlist-options/RadioWrapper";
  import PlayersNames from "@/components/shootlist/shootlist-options/PlayersNames";
  import { ShootlistAPI } from "@/api";
  import { mapGetters } from 'vuex';
  import store from "../../store";
  import NameTranslated from "@/components/director/director-reports/NameTranslated";
  export default {
    data() {
      return {
        loadingSend:false,
        catsskelet:[1,2,3,4],
        sectsskelet:[1,2,3,4,5,6,7,8],
        index: 1,
        gender : {
          arr: ['girl', 'boy'],
        },
        rend: '',
        selected:{
          gender: 'boy',
          categories: [],
          show_teacher_favourite: '',
          show_school_inventors: '',
          show_director_favorite: '',
          for_type: '1',
          people: '4',
          names: [],
        },
        categ: [],
        categories : {
          arr: ['5', '3', '1'],
        },
        for_type : {
          arr: {names: [ 'מעורב',' ילדים ', ' מבוגרים'], ids: [ '1', '2', '3']},
        },
        people : {
          arr: ['2','3','4','5','6','7','8'],
        },
        items: [],
        loading: false,
        isDefault: []
      };
    },
    components: {
      NameTranslated,
      SimpleHeader,
      SidebarToggler,
      CheckboxWrapper,
      RadioWrapper,
      PlayersNames
    },
    methods:{
      update(){
        this.loadingSend = true;
        if(this.settings.categories==null)
        {
        this.isDefault = this.category
                .filter(item => item.is_default)
                .map(item => {
                  return item.id;
                });
        this.settings.categories = [...new Set(this.isDefault)];
        }
        else if(this.settings.categories.length==0)
        {
          this.isDefault = this.category
                .filter(item => item.is_default)
                .map(item => {
                  return item.id;
                });
        this.settings.categories = [...new Set(this.isDefault)];
        this.$store.commit('shootlist/setSettings',this.settings);
        }
        if(this.selected.categories.length<=0)
        {
          this.selected.categories = this.settings.categories;
        }
        if(!this.isDefaultUser)
        {

        if(!this.isDefaultUser)
        {
        ShootlistAPI.saveSettings(this.selected).then(()=>{
          this.$store.commit('shootlist/setSettings',
               this.selected
          );
          // this.$route.meta.uuid = Math.UUID();
          this.loadingSend = false;
          
          this.$store.commit('shootlist/resetGames');

          
        }).finally(()=>{
          this.$router.push('/shootlist');
        });
        }
        else
        {
          this.$store.commit('shootlist/setSettings',
               this.selected
          );
          // this.$route.meta.uuid = Math.UUID();
          this.loadingSend = false;
          
          this.$store.commit('shootlist/resetGames');
          this.$router.push('/shootlist');
        }
        }
        else
        {
          this.$store.commit('shootlist/setSettings',
               this.selected
          );
          // this.$route.meta.uuid = Math.UUID();
          this.loadingSend = false;
          
          this.$store.commit('shootlist/resetGames');
          this.$router.push('/shootlist');
        }
      },
    },
    computed: {
      ...mapGetters({
        settings: 'shootlist/settings',
        category: 'shootlist-categories/data',
        school: 'user/school',
        user: 'user/data',
        isDefaultUser: "user/getDefaultUser"
      }),
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    watch:{
      'selected.people'(){
         this.selected.names.length = parseInt(this.selected.people);
      }
    },
    async created() {
      window.fbq('track','הגדרות מדברים בכיף');
      // if(!Object.keys(this.settings).length) {
      //   this.$store.dispatch('shootlist/setDefaultSettings');
      // }
      this.loading = true;
      if(!this.$store.getters['shootlist/isSettings']){
      await this.$store.dispatch('shootlist/getState');;
    }
      setTimeout(()=>{
        this.loading = false;
      },500);
      this.categ = _.cloneDeep(this.category) || ['5']; //addcategory
      
      if(this.settings.categories==null)
      {
      this.isDefault = this.category
              .filter(item => item.is_default)
              .map(item => {
                return item.id;
              });
      this.settings.categories = [...new Set(this.isDefault)];
      }
      else if(this.settings.categories.length==0)
      {
        this.isDefault = this.category
              .filter(item => item.is_default)
              .map(item => {
                return item.id;
              });
      this.settings.categories = [...new Set(this.isDefault)];
      }

      this.selected.gender = _.cloneDeep(this.settings.gender) || 'boy';
      this.selected.categories = _.cloneDeep(this.settings.categories) || ['5'];
      this.selected.for_type = _.cloneDeep(this.settings.for_type) || '1';
      this.selected.names = _.cloneDeep(this.settings.names) || [];
      this.selected.people = _.cloneDeep(this.settings.people) || '4';
      this.selected.show_teacher_favourite = _.cloneDeep(this.settings.show_teacher_favourite) || false;
      this.selected.show_school_inventors = _.cloneDeep(this.settings.show_school_inventors) || false;
      this.selected.show_director_favorite = _.cloneDeep(this.settings.show_director_favorite) || false;
      this.selected.viewed_shootlist =  this.settings.viewed_shootlist || [];
      if ( this.selected.names.length ===0){
        this.selected.names.length = parseInt(this.selected.people);
      }
      this.$store.commit('shootlist/setSettings',
               this.selected
          );
      if (!this.$store.getters["tutorials/isUsed"]("shootlistDidntSeeSettings")) {
      this.$store.commit("tutorials/setTutorial", "shootlistDidntSeeSettings");
    }
    },
  };
</script>

<style lang="scss" scoped>
.btns{
  text-align: center;
    position: fixed;
    bottom: 0px;
    z-index: 111111;
    width: 100%;
    right: 0px;
    // padding: 20px 0px;
}
.circle-second{
  height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 9px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-second {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 205px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-third {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 271px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-third1 {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 291px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-third2 {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 321px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-third3 {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 351px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

.line-third4 {
  
  position: absolute;
     height: 12px;
     right: 10px;
     top: 381px;
     width:17%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}

 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:17% !important;
}
.w40-1{
  width: 50% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 4px !important;
    height: 74px !important;
     width: 98px !important;
     margin: 5px;
}

.circle-second{
  border-radius: 4px !important;
    height: 26px !important;
     width: 42px !important;
     margin: 3px;
}

 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

.main {
  display: flex;
  position: relative;
  flex-grow: 1;
  background: #FFFFFF;
  color: #4D4D4D;
  overflow: hidden;

  .main-wrapper {
    /*min-height: 700px;*/
    z-index: 2;
    position: relative;
    flex-grow: 1;
    .title-main{
      padding-right: 10px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: right;

      color: #4D4D4D;
    }
    .block-title {
      padding-right: 10px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: right;

      color: #4D4D4D;

    }
    .save-b {
      margin: 19px auto;
      max-width: 340px;
      width: 100%;
      height: 40px;

      background: #005D79;
      border-radius: 20px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;

      color: #FFFFFF;
    }

    .section-wrapper, .abilities {
      justify-content: center;
      // padding: 7px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      .radio-wrapper{
        @media screen and (min-width: 375px) {
          width: 31%;
        }
        @media screen and (max-width: 370px) {
          width: 31%;
        }
        z-index: 4;
        width: 114px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        position: relative;
        .slot-radio{
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title{
            margin-bottom: 5px;
            text-align: center;
            font-family: 'App';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */

            text-align: center;

            color: #06364B;
          }
          img{
            height: 40px;
            margin-top: 7px;
          }
        }
        &.active{

          &:after{
            position: absolute;
            content: '';

            border: 1px solid #00465B;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            top: 5px;
            right: 5px;
            background: white;
            z-index: -2;
          }
          &:before{
            position: absolute;
            content: '';
            background: #00465B;
            width:6px;
            height: 6px;
            border-radius: 50%;
            top: 8px;
            right: 8px;
            z-index: -1;
          }
        }
        &.unactive{
          &:after{
            top: 5px;
            right: 5px;
            position: absolute;
            content: '';
            background: #FFFFFF;
            border: 1px solid #FFD600;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            z-index: -2;
          }
        }
      }
    }
    
    .ability-wrapper {
      img {

      }
      .abilities{
        display: flex;
        flex-wrap: wrap;

        .checkbox-wrapper{
          @media screen and (min-width: 375px) {
            width: 31%;
          }
          @media screen and (max-width: 370px) {
          width: 31%;
        }

          // &:nth-child(n+5){
          //   margin-top: 10px!important;
          // }
          z-index: 4;
          margin: 3px;
          width: 114px;
          height: 76px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-radius: 4px;
          position: relative;
          .checkbox-slot{
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .class-round{
              width: 40px;
              height: 40px;
              margin: 0 auto;
              margin-top: 6px;
              font-style: normal;
              font-weight: bold;
              color: #FBB03B;
              display: flex;
              align-items:  center;
              justify-content: center;
              background: #FFFFFF;
              border-radius: 50%;
              .class{
                font-size: 22px;
                line-height: 27px;
                margin-top: -5px;
              }
              .num{
                margin-right: 3px;
                font-size: 16px;
                line-height: 22px;
              }
            }
            .title{
              margin-bottom: 5px;
              text-align: center;
              font-family: 'App';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              /* identical to box height */

              text-align: center;

              color: #06364B;
            }
            img{
              margin-top: 14px;
              height: 35px;
            }
          }
          &.active{
            &:after{
              position: absolute;
              content: '';

              background: white;
              border: 1px solid white;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              top: 5px;
              right: 5px;
              z-index: -2;
            }
            &:before{
              position: absolute;
              content: '';
              height: 3px;
              z-index: -1;
              width: 6px;
              border-left: 1px solid #00465B;
              border-bottom: 1px solid #00465B;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 9px;
              right: 8px;
            }
          }
          &.unactive{
            &:after{
              top: 5px;
              right: 5px;
              position: absolute;
              content: '';
              background: #FFFFFF;
              border: 1px solid #A9DDF3;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              z-index: -2;
            }
          }
        }
      }
      .title {
        margin-top: unset !important;
      }
    }
  }
}

.locations-wrapper {
  .locations {
    padding: 0 7px 4px 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .radio-wrapper{
      display: inline-block;


      min-width: 82px;
      height: 30px;
      z-index: 4;
      background: #FFFFFF;
      border: 1px solid #A9DDF3;
      box-sizing: border-box;
      border-radius: 4px;

      position: relative;
      margin: 3px;
      .checkbox-slot{
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title{
          text-align: center;
          color: #06364B;
          @media screen and (min-width: 375px){
            font-size: 14px;
          }
          padding: 0 5px;
          padding-right: 15px;
          font-size: 12px;
        }
      }
      &.active{
        background: #A9DDF3;
        &:after{
          position: absolute;
          content: '';
          background: #fff;
          border: 1px solid white;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 7px;
          right: 5px;

        }
        &:before{
          position: absolute;
          content: '';
          background: #00465B;
          width:6px;
          height: 6px;
          border-radius: 50%;
          top: 10px;
          right: 8px;
          z-index: 1;
        }
      }
      &.unactive{
        &:after{
          top: 7px;
          right: 5px;
          position: absolute;
          content: '';
          background: #FFFFFF;
          border: 1px solid #A9DDF3;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }


  }
}

.gifts-wrapper {
  padding: 5px 10px 10px 10px;

  .gifts-title {

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #4D4D4D;
  }

  .gifts-b {
    width: 171px;
    height: 28px;
    @media screen and (min-width: 375px) {
      height: 35px;
    }
    background: #005D79;
    border-radius: 54px;

    span {
      font-size: 12px;
      color: #FFFFFF;
      @media screen and (min-width: 375px) {
        font-size: 14px;
      }
    }
  }
}

.level-wrapper {
  padding: 8px;
  padding-top: 22px;
  padding-bottom: 22px;
  .level-title {
    font-family: 'App';
    // margin-bottom: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: right;

      color: #4D4D4D;
    // @media screen and (min-width: 375px) {
    //   font-size: 16px;
    // }
  }

  .levels-wrapper {
    // margin: auto;
    // max-width: 375px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .radio-wrapper{
      display: inline-block;


      min-width: 13%;
      height: 28px;

      background: #FFFFFF;
      border: 1px solid #A9DDF3;
      box-sizing: border-box;
      border-radius: 4px;
      z-index: 4;
      position: relative;
      margin: 3px 0px;
      .checkbox-slot{
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title{
          text-align: center;
          @media screen and (min-width: 375px){
            font-size: 14px;
          }
          padding: 0 1px;
          padding-right: 16px;
          font-size: 12px;
        }
      }
      &.active{
        background: #A9DDF3;
        &:after{
          position: absolute;
          content: '';
          background: #fff;
          border: 1px solid white;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 7px;
          right: 5px;

        }
        &:before{
          position: absolute;
          content: '';
          background: #00465B;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          top: 10px;
          right: 8px;
          z-index: 1;

        }
      }
      &.unactive{
        &:after{
          z-index: -1;
          top: 7px;
          right: 5px;
          position: absolute;
          content: '';
          background: #FFFFFF;
          border: 1px solid #A9DDF3;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
    }


  }
}
.players-names-wrapper{
  padding: 2px 8px 10px 10px;
  // margin-right: 12px;
  max-width: 100% !important;
  margin: auto;
}
</style>
