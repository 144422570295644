<template>
  <div class="teacher-profile-radio">
    <button
      class="radio-wrapper"
      :class="[value.includes('לא') ? 'active' : 'unactive' ]"
      @click="$emit('input', 'לא' )">
      {{$ml.get('לא')}}
    </button>

    <button
      class="radio-wrapper"
      :class="[value.includes('כן') ? 'active' : 'unactive' ]"
      @click="$emit('input', 'כן' )">
      {{$ml.get('כן')}}
    </button>

  </div>
</template>

<script>
 export default {
  name: "TeacherProfileRadio",

  computed:{

  },
  props:{
    value: {},
  }
}
</script>

<style scoped lang="scss">
.teacher-profile-radio{
    width: 80px;
    align-items: center;
  justify-content: space-between;
    display: flex;
    .radio-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 20px 5px 2px;

      z-index: 4;
      height: 20px;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #06364B;
      border-radius: 4px;
      position: relative;

      &.active{

        &:after{
          position: absolute;
          content: '';

          border: 1px solid #00465B;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 5px;
          right: 5px;
          background: white;
          z-index: -2;
        }
        &:before{
          position: absolute;
          content: '';
          background: #00465B;
          width:6px;
          height: 6px;
          border-radius: 50%;
          top: 8px;
          right: 8px;
          z-index: -1;
        }
      }
      &.unactive{
        &:after{
          top: 5px;
          right: 5px;
          position: absolute;
          content: '';
          background: #FFFFFF;
          border: 1px solid #00465B;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          z-index: -2;
        }
      }
    }
}

</style>