<template>
  <div class="funkit-card">
    <div class="sm-cont">
      <div class="block-side img-b">
        <div class="img">
          <img  :src="item.img ? 'https://api.playlistgame.com'+item.img : ''" alt=""/>
        </div>
        <div class="btns-w">
          <button
            @click="butItem(item)"
            class="funkit-b"
          >
            {{$ml.get('רכישה')}}
          </button>
          <button @click="addBasketItem(item)" class="funkit-b">
            {{$ml.get('הוספה לסל')}}
          </button>
        </div>

      </div>
      <div class="block-side list">
        <h4>{{ translated.name }}</h4>

        <ContentTranslated class="advantages" :translate="item.translation" />

      </div>
      <!-- <div class="delivery-disclaimer">
        עלות משלוח  17 ש"ח
      </div> -->
    </div>
  </div>
</template>

<script>
  import ContentTranslated from "../translated/ContentTranslated";
  export default {
    name: "FunkitCard",
    components:{
      ContentTranslated
    },
    props:{
      item:{},
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.item.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data
        }
      },
    },
    methods:{
      addBasketItem(item){
      this.$store.commit('funkit/pushItem', item)
      },
      parseBr(text){
         return  text.replace(/\n/g, "<br>");
      },
      butItem(item){
        item.count=1;
        this.$store.commit('funkit/addItem', item);
        this.$router.push({name: 'funkit-delivery',params:{type: 'solo'}});
      }
    }
  }
</script>

<style scoped lang="scss">

  .funkit-card {
    position: relative;
    border-radius: 6px;
    padding: 10px 15px 30px 15px;
    margin-top: 20px;
    text-align: right;

    .sm-cont {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &:first-child {
      margin-top: 0px;
    }
    h4 {
      margin: 0;

      padding: 10px 0 0 0;
      text-align: right;
      font-weight: bold;
      font-size: 22px;
      line-height: 19px;
      /* identical to box height */
      color: #00465B;
    }
    .img {
      width: 136px;
      height: 143px;
      top: 10px;
      position: relative;
      border-radius: 8px;
      right: 0;
      line-height: 100px;
      text-align: center;
      img {
        width: 100%;
        position: absolute;
        vertical-align: middle;
        right: -15px;
        bottom: 28px;
      }
    }
    .btns-w{
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .funkit-b{
        font-family: Heebo;
        margin: 6px 0;
        background: #FFFFFF;
        border-radius: 22px;
        width: 100px;
        height: 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #005D7A;
      }
    }

    .advantages {
      padding: 34px 0 0 0;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      color: #00465B;
    }
    .delivery-disclaimer {
      position: absolute;
      left: 10px;
      bottom: 0;
      font-size: 15px;
    }
  }
.block-side{
  &.list{
    width: 70%;
  }
}
</style>