<template>
  <div
    class="select-register">
    <select
      ref="select"
      :style="ltr? 'padding: 4px 14px;!important' : 'padding: 4px 4px;'"
      class="select-reg"
      :value="value"
      @input="$emit('input', $event.target.value)" >
      <option

        :key="i*3"
        :value="option.name"
        v-for="(option, i) in options">
        {{option.name+(option.class_number? ' '+option.class_number  :'')}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectClass",
  props: {
    value: {},
    required:{
      default: false,
    },
    ltr: {
      default:false,
    },
    options: {},
    placeholder:{
      default: '',
    },
  },
  methods:{

  },
  mounted() {
    this.$emit('input', this.value);
  }
}
</script>

<style scoped lang="scss">
  //
:active, :hover, :focus {
   outline: 0;
   outline-offset: 0;
}

.select-register {
  width: 113px;
  position: relative;
  border-bottom: 1px solid #000000;
  &.active{
    &:after{
      position: absolute;
      content: '*';
      color: #ff5c5c;
      right: 0;
    }
  }
  .select-reg {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid #FFFFFF;
    padding: 4px 4px;
    line-height: 20px;
    text-align: right;
    color: #3FBCD4;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url('../../../assets/img/icons/select-arrow-black.svg'); /* Add custom arrow */
    background-position: left;
    background-repeat: no-repeat;
    font-weight: normal;
    font-size: 15px;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
    option {
      color: black;
      padding-left: 20px;
      span{
        color: red;
      }
    }
  }
}


</style>