<template>
  <div class="class">
    <div class="title">
      איזה כיתה אני?
    </div>
    <div class="select-group">
      <select v-model="classNumber">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
      </select>
      <select v-model="className">
        <option>א</option>
        <option>ב</option>
        <option>ג</option>
        <option>ד</option>
        <option>ה</option>
        <option>ו</option>
        <option>ז</option>
        <option>ח</option>
        <option>ט</option>
        <option> י</option>
        <option>יא</option>
        <option>יב</option>
        <option>אחר</option>
      </select>
    </div>
    <div class="buttons">
      <app-btn :loading="loading" @click="saveUserClass">
        המשך
      </app-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classNumber: "1",
      className: "א",
      loading: false,
    };
  },
  computed: {
    userClass() {
      return this.className + this.classNumber;
    }
  },
  methods: {
    saveUserClass() {
      if (this.userClass) {
        this.loading = true;
        axios.put("/user/update", { class: this.userClass }).then(res => {
          this.$router.replace('fullname');
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.class {

  display: flex;
  flex-direction: column;
  align-content: center;

  background: #56bcdc;
  border-radius: 10px;
  padding: 20px;

  .buttons {
    margin-top: 30px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .select-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
  }

  select {
    border: 0;
    border-radius: 0.5rem;
    text-align: center;
    height: 47px;
    width: 80px;
    margin: 0 10px;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }
}
</style>
