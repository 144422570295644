<template>
  <header class="simple-header" dir="ltr" :class="{ inverse: inverse }">
    <div class="wrapper" :class="{ 'sm-cont': !$env.CORDOVA }">
      <button class="location" @click="location">
        <!--i class="icon ion-md-pin"></i-->

        <img
          src="@/assets/img/location.svg"
          style="width: 2rem;height: 2rem;"
        />
      </button>
      <slot dir="rtl" name="title"></slot>
      <slot name="additional"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: "game-header",
  props: {
    route: {},
    inverse: {
      default: false,
      type: Boolean
    },
    button: {}
  },
  methods: {
    location() {
      //this.$store.commit('animation/back');
      this.$router.push("/playlist/options/child");
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  padding: 15px 10px;
  text-align: center;
  color: #fff;
  height: 80px;
  position: relative;
  padding-bottom: 35px;

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &.inverse {
    color: #005d7a;
    .back,
    .custom-back {
      color: #005d7a;
    }
  }
  .location,
  .custom-location {
    width: 30px;
    height: 30px;
    position: absolute;
    font-size: 20px;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: visible;
    i {
      font-size: 2rem;
      padding-left: 1.5rem;
    }
  }
  .custom-back {
    font-size: 16px;
    width: auto;
  }
}
</style>
