<template>
  <div class="funkit-basket-element">
    <div class="side-block right">
      <div class="img">
        <img  :src="item.img ? 'https://api.playlistgame.com'+item.img : ''" alt="">
      </div>

    </div>
    <div class="side-block">
     <div class="title">{{translated.name}}</div>
      <div  class="drop-down">

        <span>{{$ml.get("כמות")}}:</span>
        <select
          @change="changeValue(item,  item.count)"

          class="select-e"
          v-model="item.count">
          <option  v-for="count in 10"  :value="count">{{count}}</option>
        </select>
      </div>
      <div class="price">
        <span>{{$ml.get("מחיר")}}:</span>

        <span :key="update" class="price-s">{{item.price*item.count}}  {{$ml.get("ש”ח")}}</span>
      </div>

        <button @click="deleteItem(item)" class="delete-b">
          {{$ml.get("הסרה")}}
        </button>

    </div>
  </div>
</template>

<script>

  export default {
    name: "FunkitBasketElement",
    props:{
      item:{},
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.item.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data
        }
      },
    },
    methods:{
      deleteItem(item){
        this.$store.commit('funkit/deleteItem', item)
        this.$emit('update', Math.floor(Math.random() * Math.floor(9999)) );

      },
      changeValue(item,selected){
        this.update = selected;
        item = this.item.count;
        this.$store.commit('funkit/changeCount', {item: item, selected: this.item.count})
        this.$emit('update', Math.floor(Math.random() * Math.floor(9999)) );
      }
    },
    data(){
      return{
        count:[1,2,3,4,5,6,7,8,9,],
        selected: 1,
        update: '',
      }
    }
  }
</script>

<style scoped lang="scss">
  .funkit-basket-element{
    border-bottom: 1px solid #AAD9E7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    .side-block{
      &.right{
        margin:20px 40px 20px 40px;
      }
      .title{
        font-size: 18px;
        line-height: 25px;
        text-align: right;
        color: #005D7A;
      }
      .drop-down{
        span{
          margin-left: 5px;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: #4D4D4D;
        }
      }
      .price{
        span{
          font-size: 12px;
          line-height: 19px;
          text-align: right;
          margin-left: 5px;
          color: #4D4D4D;
        }
        .price-s{
          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #000000;
        }
      }

        .delete-b{
          position: absolute;
          bottom: 15px;
          left: 0;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: #149CC6;
        }
      .img {
        width: 136px;
        height: 143px;
        top: 10px;
        position: relative;
        border-radius: 8px;
        right: 0;
        line-height: 100px;
        text-align: center;
        img {
          width: 100%;
          position: absolute;
          vertical-align: middle;
          right: -15px;
          bottom: 28px;
        }
      }
    }
    .select-e{
      width: 62px;
      height: 28px;
      direction: ltr;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .dropdown .dropdown-menu{ max-height:250px; overflow:auto; }
  }
</style>