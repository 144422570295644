<template>
<div class="playlist-element">

  <div  class="game-i">
    <img :src="item.img" alt="">
    <div class="name">{{item.name}}</div>
  </div>

  <div class="author-i">
    <img :src="item.author.avatar" alt="">
    <div class="name-w">
      <div class="name">{{item.author.name +' '+ (item.author.surname ? item.author.surname : '') }}</div>
      <div class="school">{{item.author.class+''+ item.author.class_number}}</div>

    </div>
  </div>

</div>
</template>

<script>
  import {DirectorAPI} from "@/api";

  export default {
    props: {
      item: {},
      isDirector: {
        default: false,
      },
    },
    data() {
      return {
        value: 'כולם',
        filter: [
          {name: 'כולם', value: 'all'},
          {name: 'א\'-ג\'', value: 'child'},
          {name: 'ד\'-ו\'', value: 'adult'},
        ]
      }
    },
    created() {


    },
    computed: {
      userPosition() {
        return (this.user.position==='director' || this.user.position==='teacher');
      },
      user() {
        return this.$store.getters['user/data'];
      },
    },
    methods: {
      go() {
        this.$router.push({
          name: "playlist",
          params: {
            id_playlist: this.item.id_playlist
          }
        });
      },

    }
  };
</script>

<style lang="scss" scoped>
  //
.playlist-element{
  padding: 7px 10px;
  height: 70px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .l-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    .select-filter{
      margin-left: 15px;
      border: none;
    }
  }
  .icon{
    margin-left: 10px;
  }
  .game-i{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
       width: 50px;
       height: 50px;
     }
    .name{
      margin-left: 10px;
      font-family: Cool;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 16px;

       margin-right: 10px;
      color: #00465B;
    }
  }
  .author-i{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
      border-radius: 50%;
      object-fit: contain;
      width: 42px;
      height: 42px;
    }
    .name-w{
      margin-right: 10px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
    .name{
      font-family: Cool;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #00465B;
    }
    .school{
      font-size: 12px;
      color: #4D4D4D;
    }
  }

  .close-b{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #F2F2F2;
    border-radius: 15px;
  }
}
</style>
