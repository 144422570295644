<template>
  <div class="faq-wrapper">
    <div class="content-info">
<!--      <div class="questions-wrapper" v-bind:key="i*Math.floor(Math.random() * (99999 - 1 + 1)) + 1"  v-for="(report, i) in reports">-->
<!--        <ReportElement  :report="report" />-->
<!--      </div>-->
      <div class="question-wrapper">
        <FreeActivity @swipe="swipe"   :goal="goals.total" />
        <Creativity  @swipe="swipe"    :goal="goals.request" />
        <ClassroomGames  @swipe="swipe"    :goal="goals.gameClass" />
        <EducationTime   @swipe="swipe"  :goal="goals.educationClass" />
      </div>
    </div>
  </div>
</template>

<script>
  import FreeActivity from "./class-report/FreeActivity";
  import Creativity from "./class-report/Creativity";
  import ClassroomGames from "./class-report/ClassroomGames";
  import EducationTime from "./class-report/EducationTime";
export default {
 components:{
   EducationTime,
   ClassroomGames,
   Creativity,
   FreeActivity,
 },
  props:{
   goals:{

   }
  },
  methods:{
   swipe(data){
     this.$emit('swipe', data);
   }
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  //
.faq-wrapper{
  width: 100%;
  .content-info{

  }
  h2{
    font-weight: 600;
    font-size: 24px;
    color: #333333;
  }
}
</style>
