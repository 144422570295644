export default {
  namespaced: true,
  state: {
    opened: false,
    text: '',
    title: '',
  },
  mutations: {
    close(state) {
      state.opened = false;
    },
    open(state, data) {
      state.opened = true;
      state.text = data.text;
      state.title = data.title;
    },
    switch(state) {
      state.opened = !state.opened;
    },
  },
  actions: {

  },
  getters: {
    isOpened(state) {
      return state.opened;
    },
    content(state) {
      return state.text;
    },
    title(state){
      return state.title;
    },
  }
};