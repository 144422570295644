<template>
  <div class="players-spinner">
    <div class="container" style="padding:0px !important" ref="parent" @click="rotate">

      <div class="names">
        <div 
          v-for="(name, i) in names" 
          :style="getWordPoint(i)" 
          :class="{ 'current': selected === i }"
          class="name"
          :id="'user-label_' + i"
        >
          <template v-if="name">
            {{ name }}
          </template>

          <template v-else>
            {{$ml.get("משתתף")}} {{ i + 1 }}
          </template>
        </div>
      </div>

      <svg class="polygons">
        <polygon
          v-for="(_, n) in Array(num)"
          :points="getPoints(n)"
          :fill="getColor(n)"
        />
        <polygon 
          :points="getPoints(selected)" 
          class="selector"
          ref="selector"
        />
      </svg>

      <svg
        class="arrow"
        :style="{ transform: `rotate(${rotation}deg)` }"
        viewBox="0 0 65 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            d="M19.7341 6.20903L33.5124 19.7562C36.3891 16.952 43.7244 10.1344 50.0519 5.29775C58.8548 3.04534 60.0354 9.53236 59.5254 13.0574C55.658 20.9645 46.6486 40.6473 41.6051 52.2412C38.2045 60.0587 30.9221 59.4701 27.7319 52.8305C23.5517 44.1301 13.9181 24.65 8.88007 12.4521C8.63525 3.06707 16.0141 4.37963 19.7341 6.20903Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0.560303"
            y="0.507324"
            width="63.7226"
            height="67.4814"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="0" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import tick_sound from '@/assets/tick.mp3'
export default {
  props: {
    num: {
      type: [String, Number]
    },
    names: {
      type: Array
    }
  },
  data() {
    return {
      center: [],
      radius: 0,
      interval: null,
      rotation: 0,
      selected: 0,
      colors: [
        "#EF8787",
        "#D073E8",
        "#8E91EB",
        "#E7EF87",
        "#CEE7EF",
        "#73E878",
        "#EFB987",
        "#73E1E8"
      ]
    };
  },
  methods: {
    processSelected() {
      
      let index = 0;
      new Array(this.num).fill(null).forEach((_, n) => {
        const chunk = this.degreeChunksArray
          .find(z => z.degs[0] <= this.rotation && this.rotation <= z.degs[1])

        if(chunk) {
          index = chunk.index;
          return false;
        }
      });
      
      this.selected = index;

      const el = this.$refs.selector;
      const label_el = document.querySelector('#user-label_' + index);

      setImmediate(() => {
        el.classList.add('animate');
        label_el.classList.add('animate');
      });

      setTimeout(() => {
        el.classList.remove('animate');
        label_el.classList.remove('animate');
      }, 1500);
    },
    rotate() {
      clearInterval(this.interval);
      let last_section = 0;
      const section_size = (360 / this.num);
      let speed = 5 + (5 * Math.random());
      this.interval = setInterval(() => {
        if(speed <= 0) {
          clearInterval(this.interval);
          this.processSelected();
          this.audio.pause();
          this.audio.currentTime = 0;
        }
        this.rotation = (this.rotation % 360) + speed;
        speed -= 0.01;

        const new_section = Math.floor((this.rotation - (section_size / 2)) / section_size);
        if(new_section !== last_section && (this.audio.currentTime > 0.015 || this.audio.currentTime === 0)) { 
          this.audio.currentTime = 0;
          this.audio.play();
        }
        last_section = new_section;
      }, 1);
    },
    getColor(n) {
      return this.colors[n % this.colors.length];
    },
    getCoords(n) {
      var deg = this.degreeChunk * n;
      var half_deg = this.degreeChunk / 2;

      return [
        [
          this.center[0] + this.radius * Math.sin(deg - half_deg),
          this.center[1] + this.radius * Math.cos(deg - half_deg)
        ],
        [
          this.center[0] + this.radius * Math.sin(deg - half_deg / 2),
          this.center[1] + this.radius * Math.cos(deg - half_deg / 2)
        ],
        [
          this.center[0] + this.radius * Math.sin(deg + half_deg / 2),
          this.center[1] + this.radius * Math.cos(deg + half_deg / 2)
        ],
        [
          this.center[0] + this.radius * Math.sin(deg + half_deg),
          this.center[1] + this.radius * Math.cos(deg + half_deg)
        ]
      ];
    },
    getWordPoint(n) {
      var deg = this.degreeChunk * n;

      return {
        left: this.center[0] + 80 * Math.sin(deg) + "px",
        top: this.center[1] + 80 * Math.cos(deg) + "px"
      };
    },
    getPoints(n) {
      var points = this.getCoords(n).map(z => {
        return ` ${z[0]},${z[1]}`;
      });

      return `${this.center[0]},${this.center[1]}${points}`;
    },
    recalcBoundings() {
      this.radius = (this.$refs.parent.clientWidth / 2) * 2;
      this.center = [
        this.$refs.parent.clientWidth / 2,
        this.$refs.parent.clientHeight / 2
      ];
    },
  },
  computed: {
    degreeChunk() {
      return (Math.PI * 2) / this.num;
    },

    degreeChunksArray() {
      const deg_chunk_size = (360 / this.num);
      return new Array(this.num).fill(null).map((_, n) => {
        const deg = 360 - (deg_chunk_size * n);
        const half_deg = (deg_chunk_size / 2);

        if((deg - half_deg) < 0) {
          return {
            degs: [
              0,
              (deg + half_deg)
            ],
            index: 0
          }
        }
        else if((deg + half_deg) > 360) {
          return {
            degs: [
              (deg - half_deg),
              360
            ],
            index: 0
          }
        }
        else {
          return {
            degs: [
              (deg - half_deg),
              (deg + half_deg)
            ],
            index: n
          }
        }

        
      });
    }
  },
  mounted() {
    document.addEventListener('resize', this.recalcBoundings);
    this.recalcBoundings();
    this.audio = new Audio(tick_sound);
  },
  destroyed() {
    document.removeEventListener('resize', this.recalcBoundings);
  }
};
</script>

<style lang="scss" scoped>

@keyframes selectorOpen {
  0% {
    stroke-width: 0;
  }
  100% {
    stroke-width: 5px;
  }
}

@keyframes blinkSelector {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.players-spinner {

  .bottom-text {
    text-align: center;
    margin: 0;
    @media (max-width: 370px) {
      font-size: 15px;
    }
  }

  .names {
    .name {
      position: absolute;
      transform: translate(-50%, -50%);
      color: #005d7a;
      &.current {
        color: #fff;
      }
      &.animate {
        animation: 0.5s ease blinkSelector infinite;
      }
    }
  }

  .container {
    position: relative;
    border: 3px solid #fff;
    height: 230px;
    cursor: pointer;
    background: #fff;
    border-radius: 10px;

    .polygons {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      .selector {
        fill: transparent;
        stroke: #fff;
        stroke-width: 5px;
        stroke-linejoin: round;

        &.animate {
          animation: 0.5s ease blinkSelector infinite;
        }
      }
    }
    .arrow {
      width: 68px;
      height: 68px;
      position: absolute;
      left: 50%;
      margin-left: -34px;
      top: 50%;
      margin-top: -34px;
    }
  }
}

</style>
