const UserAPI = {


  getSchool(id) {
    return config.API.get('/school/' + id)
  },
  popUpUpdate() {
    return config.API.get('/updatePopUpShown');
  },
  popPlaylistUpUpdate() {
    return config.API.get('/updatePlaylistPopUpShown');
  },
  getAuthors(data) {
    return config.API.post('/user/getAuthors', data);
  },
  decreaseGamesCounter(){
    return config.API.get('user/decreaseGamesCounter');
  },
  decreaseShootlistCounter(){
    return config.API.get('user/decreaseShootlistCounter');
  },
  defaultGamesCounter(){
    return config.API.get('user/defaultGamesCounter');
  },
  defaultShootlistCounter(){
    return config.API.get('user/defaultShootlistCounter');
  },
  changeClassName(data) {
    return config.API.post('/changeClassName',data);
  },
  getDevices(data) {
    return config.API.get('/getDevices');
  },
  updateDevices(data){
    return config.API.post('/updateDevices',data);
  },
  changeClassNum(data) {
    return config.API.post('/changeClassNum',data);
  },
  updateVars(data) {
    return config.API.post('/user/update/localVariables', data);
  },
  getNewInvented(){
    return config.API.get('/user/lastLogin');
  },
  deleteUser(){
    return config.API.get('/deleteUser');
  },
  countersContent(){
    return config.API.get('/countersContent');
  },
  updateLocale(data){
    return config.API.post('/user/updateLocale',data)
  }
};
export  { UserAPI };