<template>
  <div class="modal" dir="rtl">
    <div class="socials">
      <button @click="shareFb" class="facebook">
        <img alt="" src="@/assets/img/facebook-letter-logo.svg" />
      </button>
      <button @click="shareWhatsapp" class="whatsapp">
        <img alt="" src="@/assets/img/whatsapp-logo.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    title: String,
    onSuccess: Function
  },
  data() {
    return {};
  },
  methods: {
    shareWhatsapp() {
      window.open(`https://wa.me/?text=${this.text}`);
      this.onSuccess();
      this.$emit("close");
    },
    shareFb() {
      FB.ui(
        {
          method: "share",
          href: process.env['VUE_APP_MARKETING_URL']
        },
        function(res) {
          if (!res.error_message) {
            this.onSuccess();
            this.$emit("close");
          }
        }
      );
    }
  },
  computed: {
    twitterURL() {
      return ``;
    },
    facebookURL() {
      return `https://twitter.com/share?url=${process.env['VUE_APP_MARKETING_URL']}&text=${this.text}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px;
  .socials {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    button {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 70%;
        max-height: 70%;
      }
      &.facebook {
        background-color: #3b5998;
      }
      &.whatsapp {
        background-color: #6ebf62;
      }
    }
  }
}
</style>
