<template>
  <main style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get("עריכת פרופיל") }}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading :loading="load" class="sm-cont" style="background: #f2f2f2;">
      <div class="sm-cont">
        <div class="logo-wrapper">
          <div
            class="avatar bg-img"
            :style="{ backgroundImage: `url(${user.img})` }"
          >
            <img
              id="icon-f"
              :src="user.img"
              style="opacity: 0; height: 0; width: 0"
              alt=""
            />
            <input type="file" @change="onFileChange" ref="file" />
            <i v-if="!user.img" class="icon ion-md-images"></i>
            <!-- <button class="edit" @click="onImageClick">
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1" fill="white">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 0.5C10 0.223858 9.77614 0 9.5 0H4.5C4.22386 0 4 0.223858 4 0.5C4 0.776142 3.77614 1 3.5 1H1C0.447715 1 0 1.44772 0 2V10C0 10.5523 0.447716 11 1 11H13C13.5523 11 14 10.5523 14 10V2C14 1.44772 13.5523 1 13 1H10.5C10.2239 1 10 0.776142 10 0.5Z"
                  />
                </mask>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10 0.5C10 0.223858 9.77614 0 9.5 0H4.5C4.22386 0 4 0.223858 4 0.5C4 0.776142 3.77614 1 3.5 1H1C0.447715 1 0 1.44772 0 2V10C0 10.5523 0.447716 11 1 11H13C13.5523 11 14 10.5523 14 10V2C14 1.44772 13.5523 1 13 1H10.5C10.2239 1 10 0.776142 10 0.5Z"
                  fill="white"
                />
                <path
                  d="M4.5 1H9.5V-1H4.5V1ZM1 2H3.5V0H1V2ZM1 10V2H-1V10H1ZM13 10H1V12H13V10ZM13 2V10H15V2H13ZM10.5 2H13V0H10.5V2ZM10.5 0C10.7761 0 11 0.223858 11 0.5H9C9 1.32843 9.67157 2 10.5 2V0ZM15 2C15 0.895431 14.1046 0 13 0V2H15ZM13 12C14.1046 12 15 11.1046 15 10H13V12ZM-1 10C-1 11.1046 -0.104569 12 1 12V10H1H-1ZM1 0C-0.10457 0 -1 0.895431 -1 2H1V2V0ZM3 0.5C3 0.223858 3.22386 0 3.5 0V2C4.32843 2 5 1.32843 5 0.5H3ZM9.5 1C9.22386 1 9 0.776142 9 0.5H11C11 -0.328427 10.3284 -1 9.5 -1V1ZM4.5 -1C3.67157 -1 3 -0.328427 3 0.5H5C5 0.776142 4.77614 1 4.5 1V-1Z"
                  fill="white"
                  mask="url(#path-1-inside-1)"
                />
                <circle cx="7" cy="6" r="2.5" fill="white" stroke="#00465B" />
              </svg>
            </button> -->
          </div>

          <IconsTab
            class="icons-tab-w"
            style="margin: 15px auto 0"
            @change="setImage"
          />
        </div>

        <div class="inputs">
          <div style="padding: 7px 20px 15px" class="first">
            <div style="margin-top: 0" class="section">
              <p>
                {{ $ml.get("שם פרטי") }}
              </p>

              <InputRegister
                textColor="#00465B"
                border="#00465B"
                :placeholder="$ml.get('שם פרטי')"
                class="input-r"
                v-model.trim="name"
              />
            </div>
            <div class="section">
              <p>
                {{ $ml.get("שם משפחה") }}
              </p>
              <InputRegister
                textColor="#00465B"
                border="#00465B"
                :placeholder="$ml.get('שם משפחה')"
                class="input-r"
                v-model.trim="surname"
              />
            </div>
          </div>
        </div>

        <!--New User Update-->
        <div class="inputs">
          <ul style="margin: 0px 10px">
            <li v-if="!user.emailVerified && user.userStatus == 'new'">
              <svg
                width="29"
                height="26"
                viewBox="0 0 29 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3656 3C13.1354 1.66667 15.0599 1.66667 15.8297 3L24.49 18C25.2598 19.3333 24.2975 21 22.7579 21H5.4374C3.8978 21 2.93555 19.3333 3.70535 18L12.3656 3Z"
                  fill="#fce93a"
                />
                <path
                  d="M14.8525 14.4478H13.0654L12.6919 7.29199H15.2261L14.8525 14.4478ZM12.6626 16.9453C12.6626 16.54 12.7725 16.2324 12.9922 16.0225C13.2119 15.8125 13.5269 15.7075 13.937 15.7075C14.3521 15.7075 14.6694 15.8149 14.8892 16.0298C15.1138 16.2446 15.2261 16.5498 15.2261 16.9453C15.2261 17.3359 15.1113 17.6436 14.8818 17.8682C14.6572 18.0879 14.3423 18.1978 13.937 18.1978C13.5366 18.1978 13.2241 18.0903 12.9995 17.8755C12.7749 17.6558 12.6626 17.3457 12.6626 16.9453Z"
                  fill="#01465B"
                />
              </svg>
              <span style="font-size: 14px; margin-right: 5px">{{
                $ml.get("צריך לאמת אימייל")
              }}</span>
            </li>
            <li v-if="!user.phoneVerified && user.userStatus == 'new'">
              <svg
                width="29"
                height="26"
                viewBox="0 0 29 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3656 3C13.1354 1.66667 15.0599 1.66667 15.8297 3L24.49 18C25.2598 19.3333 24.2975 21 22.7579 21H5.4374C3.8978 21 2.93555 19.3333 3.70535 18L12.3656 3Z"
                  fill="#fce93a"
                />
                <path
                  d="M14.8525 14.4478H13.0654L12.6919 7.29199H15.2261L14.8525 14.4478ZM12.6626 16.9453C12.6626 16.54 12.7725 16.2324 12.9922 16.0225C13.2119 15.8125 13.5269 15.7075 13.937 15.7075C14.3521 15.7075 14.6694 15.8149 14.8892 16.0298C15.1138 16.2446 15.2261 16.5498 15.2261 16.9453C15.2261 17.3359 15.1113 17.6436 14.8818 17.8682C14.6572 18.0879 14.3423 18.1978 13.937 18.1978C13.5366 18.1978 13.2241 18.0903 12.9995 17.8755C12.7749 17.6558 12.6626 17.3457 12.6626 16.9453Z"
                  fill="#01465B"
                />
              </svg>
              <span style="font-size: 14px; margin-right: 5px">{{
                $ml.get("צריך לאמת את הטלפון")
              }}</span>
            </li>
          </ul>
        </div>

        <div class="inputs pasww" v-if="user.userStatus == 'new' && !user.emailVerified">
          <div
            style="padding: 17px 20px 17px 0"
            :style="flagEmailConfirmation ? 'height: 265px;' : 'height:auto'"
            class="first"
          >
            <div class="password">
              <button
                :style="direction === 'ltr' ? 'right:109px' : ''"
                class="show-b"
                @click="flagEmailConfirmation = !flagEmailConfirmation"
              >
                <span class="icon">
                  <svg
                    :style="
                      !flagEmailConfirmation
                        ? 'transform: rotate(0)'
                        : 'transform: rotate(180deg)'
                    "
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.65723L6.65685 11.3141L12.3137 5.65723"
                      stroke="#00465B"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </button>
              <div class="question">
                <button @click="flagEmailConfirmation = !flagEmailConfirmation">
                  {{ $ml.get("אימות אימייל") }}
                </button>
              </div>
              <transition name="fade-move-left">
                <div class="answer" v-if="flagEmailConfirmation">
                  <div class="section">
                    <app-btn
                      :class="validEmailNumForCode ? 'active' : 'disabled'"
                      :loading="loadingEmailConfirm"
                      @click="confirmEmail"
                      class="reg-b"
                      :disabled="btnDisabledEmail"
                    >
                      {{ $ml.get("לשלוח קוד אישור") }}...</app-btn
                    >
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(errorEmailCodeSend) }}</span>
                  </div>
                  <div class="section" v-if="timerActivatedEmail"  style="font-size: 12px; margin-top: 5px">
                    {{ $ml.get('שלח שוב קוד באמצעות: ')+timeToSendEmail() }}
                  </div>

                  <div class="section" v-if="validEmailNumForCodeInput">
                    <InputRegister
                    textColor="#00465B"
                      border="#00465B"
                      :important="true"
                      :placeholder="$ml.get('קוד אישור')"
                      v-model.trim="validUserEmailCode"
                      @input="validEmailCode"
                    />
                  </div>
                <div class="section" v-if="emailCodeVerified">
                  <app-btn
                      :class="!loadConfirmEmailCode ? 'active' : 'disabled'"
                      :loading="loadConfirmEmailCode"
                      @click="confirmedEmail"
                      class="reg-b"
                    >
                      {{ $ml.get("לְאַשֵׁר") }}...</app-btn
                    >
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="inputs pasww" v-if="user.userStatus == 'new' && !user.phoneVerified">
          <div
            style="padding: 17px 20px 17px 0"
            :style="flagPhoneConfirmation ? 'height: 265px;' : 'height:auto'"
            class="first"
          >
            <div class="password">
              <button
                :style="direction === 'ltr' ? 'right:109px' : ''"
                class="show-b"
                @click="flagPhoneConfirmation = !flagPhoneConfirmation"
              >
                <span class="icon">
                  <svg
                    :style="
                      !flagPhoneConfirmation
                        ? 'transform: rotate(0)'
                        : 'transform: rotate(180deg)'
                    "
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.65723L6.65685 11.3141L12.3137 5.65723"
                      stroke="#00465B"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </button>
              <div class="question">
                <button @click="flagPhoneConfirmation = !flagPhoneConfirmation">
                  {{ $ml.get("אישור הטלפון") }}
                </button>
              </div>
              <transition name="fade-move-left">
                <div class="answer" v-if="flagPhoneConfirmation">
                  <div class="section">
                    <app-btn
                      :class="validPhoneNumForCodeSend ? 'active' : 'disabled'"
                      :loading="loadingPhoneConfirm"
                      @click="confirmPhone"
                      class="reg-b"
                      :disabled="btnDisabledPhone"
                    >
                      {{ $ml.get("לשלוח קוד אישור") }}...</app-btn
                    >
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(errorPhoneCodeSend) }}</span>
                  </div>
                  <div class="section" v-if="timerActivatedPhone"  style="font-size: 12px; margin-top: 5px">
                    {{ $ml.get('שלח שוב קוד באמצעות: ')+timeToSendPhone() }}
                  </div>

                  <div class="section" v-if="validPhoneNumForCodeInput">
                    <InputRegister
                    textColor="#00465B"
                      border="#00465B"
                      :important="true"
                      :placeholder="$ml.get('קוד אישור')"
                      v-model.trim="validUserPhoneCode"
                      @input="validPhoneCode"
                    />
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{$ml.get(msgPhoneCode) }}</span>
                  </div>
                <div class="section" v-if="phoneCodeVerified">
                  <app-btn
                      :class="!loadConfirmPhoneCode ? 'active' : 'disabled'"
                      :loading="loadConfirmPhoneCode"
                      @click="confirmedPhone"
                      class="reg-b"
                    >
                      {{ $ml.get("לְאַשֵׁר") }}...</app-btn
                    >
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="inputs pasww" v-if="user.userStatus == 'new'">
          <div
            style="padding: 17px 20px 17px 0"
            :style="flag ? 'height: 340px;' : 'height:auto'"
            class="first"
          >
            <div class="password">
              <button
                :style="direction === 'ltr' ? 'right:109px' : ''"
                class="show-b"
                @click="flag = !flag"
              >
                <span class="icon">
                  <svg
                    :style="
                      !flag
                        ? 'transform: rotate(0)'
                        : 'transform: rotate(180deg)'
                    "
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.65723L6.65685 11.3141L12.3137 5.65723"
                      stroke="#00465B"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </button>
              <div class="question">
                <button @click="flag = !flag">
                  {{ $ml.get("שינוי סיסמה") }}
                </button>
              </div>
              <transition name="fade-move-left">
                <div class="answer" v-if="flag">
                  <div class="section old">
                    <p>
                      {{ $ml.get("סיסמה קודמת") }}
                    </p>

                    <InputRegister
                      textColor="#00465B"
                      border="#00465B"
                      placeholder=""
                      style="width: 180px !important"
                      class="input-r"
                      v-model="pass"
                      name="oldPsv"
                    />
                  </div>
                  <div class="section">
                    <p>
                      {{ $ml.get("סיסמה חדשה") }}
                    </p>
                    <InputRegister
                      textColor="#00465B"
                      border="#00465B"
                      style="width: 180px !important"
                      placeholder=" "
                      class="input-r"
                      :type="pass_new.type"
                      v-model="pass_new.text"
                      @input="validatePassword"
                    />

                    <button
                      @click.stop="pass_new.type = getType(pass_new.type)"
                    >
                      <Eye :state="pass_new.type === 'text'" />
                    </button>
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(msgPassword) }}</span>
                  </div>
                  <div class="section">
                    <p>
                      {{ $ml.get("סיסמה חדשה") }}
                    </p>
                    <InputRegister
                      textColor="#00465B"
                      border="#00465B"
                      style="width: 180px !important"
                      placeholder=" "
                      :type="pass_rep.type"
                      class="input-r"
                      v-model="pass_rep.text"
                      @input="validatePasswordConfirm"
                    />
                    <button
                      @click.stop="pass_rep.type = getType(pass_rep.type)"
                    >
                      <Eye :state="pass_rep.type === 'text'" />
                    </button>
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(msgPasswordConfirm) }}</span>
                  </div>
                  <div class="section">
                    <app-btn
                      :class="
                        password.passwordsVerified ? 'active' : 'disabled'
                      "
                      :loading="loadingPassword"
                      @click="updatePass"
                      class="reg-b"
                    >
                      {{ $ml.get("עדכן סיסמה") }}...</app-btn
                    >
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(errorPassUpdate) }}</span>
                  </div>
                  <ValidateReg
                    :centered="true"
                    bg="white"
                    class="error-v"
                    :message="error"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="inputs pasww" v-if="user.userStatus == 'new'">
          <div
            style="padding: 17px 20px 17px 0"
            :style="flagEmail ? 'height: 220px;' : 'height:auto'"
            class="first"
          >
            <div class="password">
              <button
                :style="direction === 'ltr' ? 'right:109px' : ''"
                class="show-b"
                @click="flagEmail = !flagEmail"
              >
                <span class="icon">
                  <svg
                    :style="
                      !flagEmail
                        ? 'transform: rotate(0)'
                        : 'transform: rotate(180deg)'
                    "
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.65723L6.65685 11.3141L12.3137 5.65723"
                      stroke="#00465B"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </button>
              <div class="question">
                <button @click="flagEmail = !flagEmail">
                  {{ $ml.get("עדכן אימייל") }}
                </button>
              </div>
              <transition name="fade-move-left">
                <div class="answer" v-if="flagEmail">
                  <div class="section">
                    <InputRegister
                      textColor="#00465B"
                      border="#00465B"
                      :important="true"
                      :placeholder="$ml.get('אימייל')"
                      v-model.trim="email"
                      @input="validateEmail"
                    />
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(msgEmail) }}</span>
                  </div>
                  <div class="section">
                    <app-btn
                      :class="emailVerified ? 'active' : 'disabled'"
                      :loading="loadingEmail"
                      @click="updateEmail"
                      class="reg-b"
                    >
                      {{ $ml.get("עדכן אימייל") }}...</app-btn
                    >
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(errorEmailUpdate) }}</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="inputs pasww" v-if="user.userStatus == 'new'">
          <div
            style="padding: 17px 20px 17px 0"
            :style="flagPhone ? 'height: 210px;' : 'height:auto'"
            class="first"
          >
            <div class="password">
              <button
                :style="direction === 'ltr' ? 'right:109px' : ''"
                class="show-b"
                @click="flagPhone = !flagPhone"
              >
                <span class="icon">
                  <svg
                    :style="
                      !flagPhone
                        ? 'transform: rotate(0)'
                        : 'transform: rotate(180deg)'
                    "
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.65723L6.65685 11.3141L12.3137 5.65723"
                      stroke="#00465B"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </button>
              <div class="question">
                <button @click="flagPhone = !flagPhone">
                  {{ $ml.get("החלף טלפון") }}
                </button>
              </div>
              <transition name="fade-move-left">
                <div class="answer" v-if="flagPhone">
                  <div class="section">
                    <vue-tel-input
                      class="vue-tel-input"
                      v-model="phone"
                      v-bind="bindProps"
                      @validate="validPhone"
                      @input="clearValidation"
                    ></vue-tel-input>
                  </div>
                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(validationPhoneInputText) }}</span>
                  </div>

                  <div class="section">
                    <app-btn
                      :class="validPhoneNum ? 'active' : 'disabled'"
                      :loading="loadingPhone"
                      @click="updatePhone"
                      class="reg-b"
                    >
                      {{ $ml.get("עדכן את הטלפון") }}...</app-btn
                    >
                  </div>

                  <div class="section" style="font-size: 12px; margin-top: 5px">
                    <span>{{ $ml.get(errorUpdatePhone) }}</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="disc" v-if="!$store.getters['sub/getDisabled']">
          <template v-if="!$store.getters['user/checkSubs']">
            <div class="bordered">
              <div class="col">
                <!-- <p>
                {{$ml.get('מנוי ל')}}- {{ user.subscribe_total_games}} {{$ml.get('משחקים')}}
              </p>
              <p class="gray" v-if="user.subscribe_games!=null && user.subscribe_games!=0">    
                {{ user.subscribe_total_games }}/{{user.subscribe_games}}   {{$ml.get('משחקים זמינים')}}
              </p>
              <p class="gray" v-else>    
                {{ user.subscribe_total_games }}/{{ user.subscribe_total_games}}   {{$ml.get('משחקים זמינים')}}
              </p>



              <p class="gray" v-if="user.subscribe_games!=null && user.subscribe_games!=0">    
                {{ user.subscribe_total_shootlist }}/{{ user.subscribe_shootlist }} {{$ml.get('שאלות זמינות')}}
              </p>
              <p class="gray" v-else>    
                {{ user.subscribe_total_shootlist }}/{{ user.subscribe_total_shootlist}}   {{$ml.get('שאלות זמינות')}}
              </p> -->
                <div v-if="!$store.getters['user/hasSubs']">
                  <p class="small">
                    <!--                  מנוי ל- {{ user.subscribe_total_games}} משחקים-->
                    {{ $ml.get("ללא מנוי") }}
                  </p>
                  <p
                    class="small"
                    v-if="
                      user.subscribe_games != null && user.subscribe_games != 0
                    "
                  >
                    {{ user.subscribe_games }} {{ $ml.get("משחקים זמינים") }}
                  </p>
                  <p class="small" v-else>
                    {{
                     playlistCounter
                    }}/{{ user.subscribe_total_games }}
                    {{ $ml.get("משחקים זמינים") }}
                  </p>

                  <p
                    class="small"
                    v-if="
                      user.subscribe_shootlist != null &&
                      user.subscribe_shootlist != 0
                    "
                  >
                    {{ user.subscribe_shootlist }} {{ $ml.get("שאלות זמינות") }}
                  </p>
                  <p class="small" v-else>
                    {{
                     shootlistCounter
                    }}/{{ user.subscribe_total_shootlist }}
                    {{ $ml.get("שאלות זמינות") }}
                  </p>
                </div>
                <div v-else-if="!$store.getters['sub/getDisabled']">
                  <p
                    class="small"
                    v-if="user.subscribe_interval == 2.62 * Math.pow(10, 6)"
                  >
                    {{ $ml.get("מנוי חודשי") }}
                  </p>
                  <p
                    class="small"
                    v-else-if="user.subscribe_interval == 15700000"
                  >
                    6 {{ $ml.get("חודשים") }}
                  </p>

                  <p class="small">
                    {{ $ml.get("מתאריך") }}
                    {{
                      new Date(user.subscribe * 1000) | dateFormat("DD/MM/YYYY")
                    }}
                  </p>
                  <p class="small">
                    {{ $ml.get(" עד") }}
                    {{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | dateFormat("DD/MM/YYYY")
                    }}
                  </p>
                </div>
              </div>
              <div class="col" v-if="!$store.getters['sub/getDisabled']">
                <router-link class="button-subcr" :to="{ name: 'subsPlans' }">{{
                  $ml.get("לרכישת מנוי")
                }}</router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="bordered">
              <div
                v-if="$store.getters['user/validTIme']"
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
                class="col"
              >
                <div class="col">
                  <p>
                    <span> {{ $ml.get("מנוי שנתי") }}</span
                    ><span>|</span>
                    <span>
                      <span> {{ $ml.get("מנוי עד") }} </span>
                      <span> {{ $ml.get("מטעם") }}</span>
                      <span>{{
                        user.promo ? user.promo.client : ""
                      }}</span></span
                    >
                  </p>
                  <p class="gray">
                    {{$ml.get('עד ל-')}}
                    {{ school.endDate }}
                  </p>
                </div>
                <div class="col">
                  <!--                <button-->
                  <!--                  class="cancel"-->
                  <!--                  @click="cancel"-->
                  <!--                  v-if="user.is_subscribe"-->
                  <!--                >-->
                  <!--                  בטל את הרישום-->
                  <!--                </button>-->
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
                v-else-if="
                  new Date(
                    (user.subscribe + user.subscribe_interval) * 1000
                  ).getFullYear() === 2071
                "
                class="col"
              >
                <div class="col">
                  <p>
                    <span>{{ $ml.get("מנוי שנתי") }}</span
                    ><span>|</span>
                    <span>
                      <span>{{ $ml.get("מנוי עד") }}</span>
                      <span>{{ school.endDate }} {{ $ml.get("מטעם") }}</span>
                      <span>{{
                        user.promo ? user.promo.client : ""
                      }}</span></span
                    >
                  </p>
                  <p class="gray">
                    {{ $ml.get("עד") }}
                    {{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | dateFormat("DD/MM/YYYY")
                    }}
                  </p>
                </div>
                <div class="col">
                  <button
                    class="cancel"
                    @click="cancel"
                    v-if="$store.getters['user/hasSubs'] && is_subscribe"
                  >
                    {{ $ml.get("בטל את הרישום") }}
                  </button>
                  <!--                <router-link v-else :to="{name: 'subscribe'}">לרכישת מנוי</router-link>-->
                </div>
              </div>
              <div v-else class="col">
                <p v-if="user.subscribe_interval == 2.62 * Math.pow(10, 6)">
                  {{ $ml.get("מנוי חודשי") }}
                </p>
                <p v-else-if="user.subscribe_interval == 15700000">
                  {{ $ml.get("חודשים") }} 6
                </p>

                <p>
                  {{ $ml.get("מתאריך") }}
                  {{
                    new Date(user.subscribe * 1000) | dateFormat("DD/MM/YYYY")
                  }}
                </p>
                <p class="gray">
                  {{ $ml.get("עד") }}
                  {{
                    new Date((user.subscribe + user.subscribe_interval) * 1000)
                      | dateFormat("DD/MM/YYYY")
                  }}
                </p>
              </div>
              <div class="col">
                <!--              <router-link v-else-if="new Date((user.subscribe + user.subscribe_interval) * 1000).getFullYear()!==2071" :to="{name: 'subscribe'}">לרכישת מנוי</router-link>-->
                <button
                  class="cancel"
                  style="width: 100px"
                  @click="cancel"
                  v-if="$store.getters['user/hasSubs'] && is_subscribe"
                >
                  {{ $ml.get("בטל את הרישום") }}
                </button>
              </div>
            </div>
          </template>
        </div>
        <!-- <div class="inputs" style="margin-top: 20px;text-align: center; padding-bottom: 0px;">
          <span @click="deviceManagers" class="titles-delete">{{$ml.get('ניהול מכשירים')}}</span>
        </div> -->

        <div class="inputs" style="text-align: center;">
          <span @click="deleteProfile" class="titles-delete">{{$ml.get('מחק חשבון')}}</span>
        </div>

        <btn @click="saveProfile" class="save-b"> {{ $ml.get("שמור") }}</btn>
        <!--      <div class="end" dir="rtl">-->
        <!--        <button @click="logout" class="bot-text">התנתקות</button>-->
        <!--      </div>-->
      </div>
      <Confirm />
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Switcher from "@/components/Switcher.vue";
import Logout from "@/components/modals/Logout.vue";
import SavedProfile from "@/components/modals/SavedProfile.vue";
import IconsTab from "@/components/IconsTab";
import Btn from "@/components/ui/app-btn/AppBtn";
import ValidateReg from "@/components/ValidateReg";
import Eye from "@/components/ui/Eye";
import { AuthAPI, SubscribeAPI } from "@/api";
import Confirm from "@/components/modals/modal/Confirm";
import Payment from "@/components/modals/Payment";
import { mapGetters } from "vuex";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import CryptoJS from "crypto-js";
import { Base64 } from 'js-base64';
export default {
  data() {
    return {
      is_subscribe:false,
      btnDisabledPhone:false,
      loadingPhoneConfirm:false,
      validPhoneNumForCodeSend:true,
      flagPhoneConfirmation:false,
      errorPhoneCodeSend:null,
      timerActivatedPhone:null,
      validPhoneNumForCodeInput:false,
      validUserPhoneCode:null,
      currentTimePhone: 120,
      timerCountPhone: null,
      timerActivatedPhone: false,
      sendCodeCountPhone:2,
      phoneCodeVerified:false,
      loadConfirmPhoneCode:false,
      timerPhoneCode:null,
      phoneCode:null,
      msgPhoneCode:null,
      
      validUserEmailCode:null,
      currentTimeEmail: 120,
      timerCountEmail: null,
      timerActivatedEmail: false,
      sendCodeCountEmail:2,
      msgEmailCode: "הזן את הקוד מהמייל",
      validEmailNumForCode:true,
      emailCode:null,
      emailCodeVerified:null,
      timerEmailCode:null,
      loadConfirmEmailCode:false,

      errorEmailCodeSend:null,
      loadingEmailConfirm:null,
      canSendEmailConfirmCode:true,
      bindProps: {
        autoFormat: true,
        autoDefaultCountry: false,
        defaultCountry: "IL",
        validCharactersOnly: true,
        mode: "international",
        disabled: false,
        preferredCountries: ["IL", "US", "UA"],
        dropdownOptions: {
          showDialCodeInList: true,
          showFlags: true,
          width: "250px",
        },
        inputOptions: {
          showDialCode: true,
          autocomplete: "off",
          placeholder: "הזן מספר טלפון",
          required: true,
          type: "tel",
          name: "phone",
          maxLen: 25,
        },
      },
      errorPassUpdate: null,
      msgPassword: null,
      msgPasswordConfirm: null,
      errorUpdatePhone: null,
      validEmailNumForCodeInput:false,
      btnDisabledEmail:false,
      password: {
        passwordVerified: false,
        passwordConfirmVerified: false,
        passwordsVerified: false,
      },
      loadingPhone: null,
      timerPassword: null,
      timerConfirmPassword: null,
      pass: "",
      flagPhone: false,
      msgEmail: "",
      email: "",
      name: "",
      timerEmail: null,
      surname: "",
      age: "",
      phone: "",
      gender: "",
      load: true,
      interval: null,
      pass_new: {
        type: "password",
        text: "",
      },
      pass_rep: {
        type: "password",
        text: "",
      },
      btnDisabled: false,
      error: "",
      flag: false,
      flagEmail: false,
      flagEmailConfirmation:false,
      chageImg: false,
      loadingPassword: false,
      emailVerified: false,
      loadingEmail: false,
      errorEmailUpdate: "",
      validationPhoneInputText: "",
      validationPhoneInputTextDefault: "הזן מספר טלפון",
      validPhoneNum: false,
      timerPhone: null,
    };
  },
  mounted() {
    this.$store.dispatch("user/updateData");
  },
  computed: {
    ...mapGetters({
      settingsPlaylist: "playlist/settings",
      settingsShootlist: "shootlist/settings",
      playlistCounter: "user/getPlaylistCounter",
      shootlistCounter: "user/getShootlistCounter",
    }),
    user() {
      return this.$store.getters["user/data"];
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
    school() {
      return this.$store.getters["user/school"];
    },
  },

  methods: {
    encryptObject(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env['VUE_APP_SECURE_KEY']);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

      const objectToEncryptString = JSON.stringify(data);

      const encrypted = CryptoJS.AES.encrypt(objectToEncryptString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 8,
      });

      const encryptedObject = {
        iv: ivBase64,
        value: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
      return encryptedObject;
    },
    deviceManagers(){
      this.$router.push({name:'device-manager'});
    },
    deleteProfile(){
      this.$modal.show(
        DeleteConfirmation,
          {},
          {
            adaptive: true,
            height: "320px",
            width: "295px",
          }
        );
    },
    confirmedEmail(){
      this.loadConfirmEmailCode = true;
      AuthAPI.confirmedEmail().then(()=>{
        window.location.reload();
      }).catch(()=>{
        this.loadConfirmEmailCode = false;
      }).finally(()=>{
      });
    },
    validEmailCode(event) {

      var md5 = require('md5');
      this.emailCodeVerified = false;
      clearTimeout(this.timerEmailCode);
      this.timerEmailCode = setTimeout(() => {
      if(this.emailCode == md5(this.validUserEmailCode))
      {
        this.msgEmailCode = 'קוד תואם';
        this.emailCodeVerified = true;
      }
      else if(this.validUserEmailCode==''){
          this.msgEmailCode = 'הזן את הקוד וההודעות בטלפון';
          this.emailCodeVerified = false;
      } 
      else{
          this.emailCodeVerified = false;
          this.msgEmailCode = 'קוד שגוי';
      }
      },1000);
    },
    timeToSendEmail(){
    let minutes = Math.floor(this.currentTimeEmail / 60);

    let seconds = this.currentTimeEmail % 60;

    let result = `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;

    return result;
    },
    startTimerEmail() {
      this.timerCountEmail = setInterval(() => {
        this.currentTimeEmail--
      }, 1000)
    },
    stopTimerEmail() {
      clearTimeout(this.timerCountEmail)
    },
    padTo2Digits(num) {
    return num.toString().padStart(2, '0');
    },
    confirmEmail(){
      this.loadingEmailConfirm = true;
          let data = {
            email: this.email,
          };
          AuthAPI.sendEmail(data).then((res) => {
            if(res.data.error)
            {
              this.loadingEmailConfirm = false;
              this.validEmailNumForCodeInput = false;
              this.btnDisabledEmail = true;
              this.errorEmailCodeSend = "Oops.. Something went wrong :()";
            }
            else
            {
              this.sendCodeCountEmail --;
              if(this.sendCodeCountEmail>0)
              {
                this.timerActivatedEmail = true;
              this.startTimerEmail();
              }
            this.emailCode = res.data.code;
            this.validEmailNumForCodeInput = true;
            this.btnDisabledEmail = true;
            }
          })
          .catch(err=>{
            this.loadingEmailConfirm = false;
            this.validEmailNumForCodeInput = false;
            this.btnDisabledEmail = false;
          }).finally(()=>{
            this.loadingEmailConfirm = false;
          });
    },

    confirmPhone(){
      this.loadingPhoneConfirm = true;
      let data = {
            phone: this.phone,
          };
          AuthAPI.sendSMSCode(data).then((res) => {
            if(res.data.error)
            {
              this.loadingPhoneConfirm = false;
              this.validPhoneNumForCodeInput = false;
              this.btnDisabledPhone = true;
              this.errorPhoneCodeSend = "Oops.. Something went wrong :()";
            }
            else
            {
              this.sendCodeCountPhone --;
              if(this.sendCodeCountPhone>0)
              {
                this.timerActivatedPhone = true;
              this.startTimerPhone();
              }
            this.phoneCode = res.data.code;
            this.validPhoneNumForCodeInput = true;
            this.btnDisabledPhone = true;
            }
          })
          .catch(err=>{
            this.loadingPhoneConfirm = false;
            this.validPhoneNumForCodeInput = false;
            this.btnDisabledPhone = false;
          }).finally(()=>{
            this.loadingPhoneConfirm = false;
          });
    },
    validPhoneCode(){
      var md5 = require('md5');
      this.phoneCodeVerified = false;
      clearTimeout(this.timerPhoneCode);
      this.timerPhoneCode = setTimeout(() => {
      if(this.phoneCode == md5(this.validUserPhoneCode))
      {
        this.msgPhoneCode = 'קוד תואם';
        this.phoneCodeVerified = true;
      }
      else if(this.validUserPhoneCode==''){
          this.msgPhoneCode = 'הזן את הקוד וההודעות בטלפון';
          this.phoneCodeVerified = false;
      } 
      else{
          this.phoneCodeVerified = false;
          this.msgPhoneCode = 'קוד שגוי';
      }
      },1000);
    },
    confirmedPhone(){
      this.loadConfirmPhoneCode = true;
      AuthAPI.confirmedPhone().then(()=>{
        window.location.reload();
      }).catch(()=>{
        this.loadConfirmPhoneCode = false;
      }).finally(()=>{
      });
    },
    startTimerPhone() {
      this.timerCountPhone = setInterval(() => {
        this.currentTimePhone--
      }, 1000)
    },
    stopTimerEmail() {
      clearTimeout(this.timerCountPhone)
    },
    padTo2Digits(num) {
    return num.toString().padStart(2, '0');
    },

    timeToSendPhone(){
    let minutes = Math.floor(this.currentTimePhone / 60);

    let seconds = this.currentTimePhone % 60;

    let result = `${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;

    return result;
    },




    updatePhone() {
      this.loadingPhone = true;
      AuthAPI.updatePhone(this.encryptObject({ phone: this.phone }))
        .then((res) => {
          if (res.data.success == true) {
            this.errorUpdatePhone = "אימייל עודכן";
          }
        })
        .catch((err) => {
          this.loadingPhone = false;
          if (err.response.data.message == "Already in use") {
            this.errorUpdatePhone = 'דוא"ל כבר בשימוש';
          } else {
            this.errorUpdatePhone = "Error";
          }
        })
        .finally(() => {
          this.loadingPhone = false;
        });
    },
    clearValidation() {
      this.validationPhoneInputText = this.validationPhoneInputTextDefault;
    },
    validPhone(event) {
      this.btnDisabled = false;
      this.validationPhoneInputText = this.validationPhoneInputTextDefault;
      clearTimeout(this.timerPhone);
      this.timerPhone = setTimeout(() => {
        this.validPhoneNum = event.valid;

        if (!event.valid) {
          this.validationPhoneInputText = "המספר לא נכון";
          this.validPhoneNumForCode = false;
        } else if (event.valid) {
          this.validPhoneNumForCode = true;
          this.validationPhoneInputText = "";
        }
      }, 500);
    },
    updateEmail() {
      this.loadingEmail = true;
      AuthAPI.updateEmail(this.encryptObject({ email: this.email }))
        .then((res) => {
          if (res.data.success == true) {
            this.errorEmailUpdate = "אימייל עודכן";
          }
        })
        .catch((err) => {
          this.loadingEmail = false;
          if (err.response.data.message == "Already in use") {
            this.errorEmailUpdate = 'דוא"ל כבר בשימוש';
          } else {
            this.errorEmailUpdate = "Error";
          }
        })
        .finally(() => {
          this.loadingEmail = false;
        });
    },
    validateEmail() {
      this.msgEmail = "נא להזין את המייל שלך";
      this.emailVerified = false;
      clearTimeout(this.timerEmail);
      this.timerEmail = setTimeout(() => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          this.msgEmail = "";
          this.emailVerified = true;
        } else {
          this.msgEmail = "כתובת אימייל לא חוקית";
          this.emailVerified = false;
        }
      }, 1000);
    },
    updatePass() {
      this.loadingPassword = true;
      AuthAPI.updatePass(this.encryptObject({ passOld: this.pass, passNew: this.pass_rep.text }))
        .then((res) => {
          if (res.data.success == true) {
            this.errorPassUpdate = "הסיסמה עודכנה";
          }
        })
        .catch((err) => {
          this.loadingPassword = false;
          if (err.response.data.message = "Wrong password") {
            this.errorPassUpdate = "סיסמה ישנה שגויה";
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("סיסמה ישנה שגויה"),
              type: "error",
              duration: 5000,
            });
          }
          else if(err.response.data.message = "Password in use")
          {
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("השתמשו בסיסמה זו לאחרונה"),
              type: "error",
              duration: 5000,
            });
          }
          else
          {
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("סיסמה ישנה שגויה"),
              type: "error",
              duration: 5000,
            });
          }
        })
        .finally(() => {
          this.loadingPassword = false;
        });
    },
    validatePassword() {
      this.password.passwordsVerified = false;
      this.password.passwordVerified = false;
      this.msgPassword = "";
      clearTimeout(this.timerPassword);
      this.timerPassword = setTimeout(() => {
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
            this.pass_new.text
          )
        ) {
          this.password.passwordVerified = true;
          this.msgPassword = "הסיסמה התקבלה";
          if (this.password.passwordConfirmVerified) {
            if (this.pass_new.text == this.pass_rep.text) {
              this.password.passwordsVerified = true;
              this.msgPassword = "הסיסמה התקבלה";
              this.msgPasswordConfirm = "הסיסמה התקבלה";
            } else {
              this.msgPassword = "סיסמאות לא תואמות";
              this.msgPasswordConfirm = "סיסמאות לא תואמות";
            }
          }
        } else {
          this.msgPassword = "הסיסמה אינה תואמת את הדרישות";
        }
      }, 1000);
    },
    validatePasswordConfirm() {
      this.password.passwordsVerified = false;
      this.password.passwordConfirmVerified = false;
      this.msgPasswordConfirm = "";
      clearTimeout(this.timerConfirmPassword);
      this.timerConfirmPassword = setTimeout(() => {
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
            this.pass_rep.text
          )
        ) {
          this.password.passwordConfirmVerified = true;
          this.msgPasswordConfirm = "הסיסמה התקבלה";
          if (this.password.passwordConfirmVerified) {
            if (this.pass_rep.text == this.pass_new.text) {
              this.password.passwordsVerified = true;
              this.msgPassword = "הסיסמה התקבלה";
              this.msgPasswordConfirm = "הסיסמה התקבלה";
            } else {
              this.msgPassword = "סיסמאות לא תואמות";
              this.msgPasswordConfirm = "סיסמאות לא תואמות";
            }
          }
        } else {
          this.msgPasswordConfirm = "הסיסמה אינה תואמת את הדרישות";
        }
      }, 1000);
    },
    setImage(data) {
      this.chageImg = true;
      this.user.img = data;
    },
    async subscribeSched() {
      let successfulPage = window.location.origin + "/#/successfulPayment";
      let errorPage = window.location.origin + "/#/errorPayment";
      const data = await SubscribeAPI.subscribe(successfulPage, errorPage).then(
        (res) => res.data
      );

      this.$modal.show(
        Payment,
        {
          url: data.url,
          id_transaction: data.id_transaction,
          type: "subs",
          ended: () => {
            this.$store.commit("playlist/reset");
            this.$store.dispatch("user/updateData");
            this.$router.replace("/account");
          },
        },
        {
          adaptive: true,
          height: "90%",
          width: "90%",
        }
      );
    },
    unsubcribe() {
      this.$store.commit("modals");
    },
    getType(value) {
      return value === "text" ? "password" : "text";
    },
    onImageClick() {
      this.$refs.file.click();
    },
    onFileChange() {
      var data = new FormData();
      data.append("image", this.$refs.file.files[0]);
      axios
        .post("/user/image/permanent", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.user.img = res.data.url;
          this.user.full_img = res.data.full_url;
        });
    },
    saveProfile() {
        this.saveOld();
    },
    saveOld() {
      this.error = "";
        axios
          .put("/user/edit", {
            surname: this.surname,
            name: this.name,
          })
          .then((res) => {
            if (!this.chageImg) {
              this.$store.dispatch("user/updateData");
              this.$modal.show(
                SavedProfile,
                {},
                {
                  height: "auto",
                  adaptive: true,
                  width: 300,
                }
              );
              this.$router.push("/main");
            } else {
              const img = document.getElementById("icon-f");
              fetch(img.src)
                .then((res) => res.blob())
                .then((blob) => {
                  const file = new File([blob], "dot.png", blob);
                  var data = new FormData();
                  data.append("image", file);
                  axios
                    .post("/user/image/permanent", data, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((res) => {
                      this.user.img = res.data.url;
                      this.user.full_img = res.data.full_url;
                      this.$store.dispatch("user/updateData");
                      this.$modal.show(
                        SavedProfile,
                        {},
                        {
                          height: "auto",
                          adaptive: true,
                          width: 300,
                        }
                      );
                      this.$router.push("/main");
                    });
                });
            }
          })
          .catch((res) => {
            this.$store.commit("alert", res.data.error);
          });
    },
    cancel() {
      alert('ok')
      this.$store.commit("modals/confirm/open", {
        title: "האם אתה בטוח שאתה מעוניין\n" + "לבטל את המנוי?",
        status: "false",
      });
    },
    logout() {
      this.$modal.show(
        Logout,
        {},
        {
          height: "auto",
          adaptive: true,
          width: 300,
        }
      );
    },
  },
  components: {
    Confirm,
    Btn,
    Switcher,
    IconsTab,
    SimpleHeader,
    SidebarToggler,
    ValidateReg,
    DeleteConfirmation,
    Eye,
  },

  created() {
    SubscribeAPI.checkExists().then((res)=>{
      this.is_subscribe = res.data.data
    })
    window.fbq("track", "פרופיל אישי");
    this.validPhoneNumForCode = false;
    this.validPhoneNumForCodeInput = false;
    this.validationPhoneInputText = this.validationPhoneInputTextDefault;
    this.interval = setInterval(() => {
      let params = new URL(document.location).searchParams;
    }, 2000);
    this.email = this.user.email;
    this.name = this.user.name;
    this.surname = this.user.surname;
    this.age = this.user.age;
    this.phone = this.user.phone;
    this.gender = this.user.gender;
    this.load = false;
  },
};
</script>

<style lang="scss" scoped>
.titles-delete{
  font-size: 14px;
}
.titles-delete:hover{
  cursor: pointer;
}
.vti__input {
  background: transparent !important;
}
.vue-tel-input {
  direction: ltr;
  max-width: 250px;
}
main {
  overflow: hidden;
  padding-bottom: 20px;
  background: #f2f2f2;
  header {
    padding: 15px 10px;
    padding-bottom: 0;
    button {
      color: #fff;
    }
  }
  .cancel {
  }

  .disc {
    background: #ffffff;
    border-radius: 10px;
    margin: 0 10px;
    padding: 5px;
    padding-bottom: 0;

    .bordered {
      padding: 13px 20px;
      border: 1px solid #fff;
      display: flex;
      position: relative;

      align-items: flex-start;

      color: #00465b;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a {
        font-size: 14px;
        line-height: 19px;
        color: #149cc6;
      }
      p {
        &.gray {
          color: #4d4d4d;
        }
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465b;
        margin: 0;
      }
      &.no-subs {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a {
          font-size: 14px;
          line-height: 19px;
          color: #149cc6;
        }
      }
    }
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #dbdbdb;
    margin: 0 auto;
    position: relative;
    color: #fff;
    text-align: center;
    line-height: 100px;
    font-size: 38px;
    input {
      position: absolute;
      top: 0;
      left: -99999px;
      opacity: 0;
    }
    .edit {
      padding: 0;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #00465b;
      color: #dbdbdb;
      left: 0;
      bottom: 0;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNDMUMxQzEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDE2IDI3Ny4zMzNIMjc3LjMzM1Y0MTZoLTQyLjY2NlYyNzcuMzMzSDk2di00Mi42NjZoMTM4LjY2N1Y5Nmg0Mi42NjZ2MTM4LjY2N0g0MTZ2NDIuNjY2eiIvPjwvc3ZnPg==);*/
      /*background-position: center;*/
      /*background-repeat: no-repeat;*/
      background-size: 30px;
      &:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        top: 50%;
        left: 50%;
      }
    }
  }
  .inputs {
    padding-bottom: 20px;
    &.pasww {
      p {
        margin: 0;
        margin-top: 7px;

        width: unset !important;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465b;
      }
      .input-r {
        width: 53% !important;
        @media screen and (max-width: 360px) {
          width: 50% !important;
        }
      }
      .section {
        padding: 0 15px;
        @media screen and (max-width: 360px) {
          padding: 0 !important;
        }
      }
    }
    .first {
      background: #ffffff;
      border-radius: 10px;
      margin: 0 10px;
      padding: 17px 20px;
      .section {
        margin-top: 10px;

        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
        &.old {
          &:after {
            content: "מספר הטלפון";
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            bottom: -16px;
            color: #a2a2a2;
          }
        }
        &.new {
          &:after {
            content: "אישור הסיסמה";
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            bottom: -16px;
            line-height: 16px;
            text-align: right;
            color: #a2a2a2;
          }
        }
        p {
          margin: 0;
          margin-top: 7px;
          width: 70px;

          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #00465b;
        }
        .input-r {
          width: 140px;
          margin-top: 10px;
          margin-right: 9px;
          font-size: 16px !important;
          position: relative;
        }
        .input-register {
          .input-reg {
            line-height: 22px;
            text-align: right;
            color: #00465b;
          }
        }
        input {
          width: 100%;
        }
      }
    }
  }
  .pre-bot-text,
  .bot-text {
    display: block;
    color: #fff;
    padding: 0 20px;
  }
  .bot-text {
    margin-top: 20px;
  }
}
.icons-tab-w {
  margin-top: 25px;
}
.logo-wrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;

  margin: 10px 10px 20px 10px;
  /*.no-photo {*/
  /*  width: 70px;*/
  /*  height: 70px;*/
  /*  border-radius: 100%;*/
  /*  background: #eee;*/

  /*  display: flex;*/
  /*  align-items: center;*/
  /*  justify-content: center;*/

  /*  svg {*/
  /*    width: 50px;*/
  /*    fill: #aaa;*/
  /*  }*/
  /*}*/
}
button {
  &.cancel {
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    background-color: white;
    border: none;
    box-shadow: none;
    color: #149cc6;
  }
}
//
.isActive {
  font-size: 14px;
  opacity: 1;
  margin-top: 17px;
  display: block;
  transition: all 0.4s ease-in-out;
}
.unActive {
  transition: all 0.4s ease-in-out;
  font-size: 0 !important;
  opacity: 0;
  margin-top: 0 !important;
  display: none;
}
.password {
  position: relative;
  margin: 2px 0;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  color: #828282;
  .question {
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #00465b;
    .terms {
      left: 30px;
      position: absolute;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: right;
      color: #ffffff;
      max-width: 145px;
    }
    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      width: 88%;
      text-align: start;
    }
  }
  .show-b {
    cursor: pointer;
    position: absolute;
    right: 63px;
    padding: 9px;
    top: -13px;
    margin-right: 5px;
    span {
      svg {
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  .answer {
    background: white;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .error-v {
      margin-top: 20px;
      position: absolute;
      left: 68px;
    }
  }
}
.save-b {
  width: 180px;
  margin: 30px auto 30px;
}
.button-subcr {
  font-size: 14px;
  line-height: 19px;
  color: #149cc6;
}
</style>
