<template>
  <div class="controls-panel" v-if="section =='gift'" style="border-top: 3px solid #93DAE8;">
    <button @click="returnLastCard" style="position: relative;">
      <div style="position:absolute;z-index:1; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
      <svg
      style="margin-left:1px;z-index:2;"
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0909 0L18 5L13.0909 10V5.83333H5.31818C3.28946 5.83333 1.63636 7.51705 1.63636 9.58333C1.63636 11.6496 3.28946 13.3333 5.31818 13.3333H6.54545V15H5.31818C2.3969 15 0 12.5587 0 9.58333C0 6.60795 2.3969 4.16667 5.31818 4.16667H13.0909V0ZM16.3636 13.3333V15H14.7273V13.3333H16.3636ZM13.0909 13.3333V15H11.4545V13.3333H13.0909ZM9.81818 13.3333V15H8.18182V13.3333H9.81818Z"
          fill="#06364B"
        />
      </svg>
    </button>
    <button @click="share" style="position: relative;">
      <div style="position:absolute;z-index:1; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
      <svg
      style="margin-right:2px;z-index:2;"
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8333 0C9.64527 0 8.66667 1.08399 8.66667 2.4C8.66667 2.55276 8.6824 2.7017 8.70757 2.84687L3.55469 6.17656C3.17672 5.82228 2.69539 5.6 2.16667 5.6C0.978604 5.6 0 6.68399 0 8C0 9.31601 0.978604 10.4 2.16667 10.4C2.69539 10.4 3.17672 10.1777 3.55469 9.82344L8.70757 13.1516C8.68223 13.2972 8.66667 13.4467 8.66667 13.6C8.66667 14.916 9.64527 16 10.8333 16C12.0214 16 13 14.916 13 13.6C13 12.284 12.0214 11.2 10.8333 11.2C10.3043 11.2 9.82337 11.4235 9.44531 11.7781L4.29243 8.44688C4.3176 8.3017 4.33333 8.15276 4.33333 8C4.33333 7.84724 4.3176 7.6983 4.29243 7.55312L9.44531 4.22344C9.82328 4.57772 10.3046 4.8 10.8333 4.8C12.0214 4.8 13 3.71601 13 2.4C13 1.08399 12.0214 0 10.8333 0ZM10.8333 1.6C11.2408 1.6 11.5556 1.9487 11.5556 2.4C11.5556 2.8513 11.2408 3.2 10.8333 3.2C10.4259 3.2 10.1111 2.8513 10.1111 2.4C10.1111 1.9487 10.4259 1.6 10.8333 1.6ZM2.16667 7.2C2.57409 7.2 2.88889 7.5487 2.88889 8C2.88889 8.4513 2.57409 8.8 2.16667 8.8C1.75924 8.8 1.44444 8.4513 1.44444 8C1.44444 7.5487 1.75924 7.2 2.16667 7.2ZM10.8333 12.8C11.2408 12.8 11.5556 13.1487 11.5556 13.6C11.5556 14.0513 11.2408 14.4 10.8333 14.4C10.4259 14.4 10.1111 14.0513 10.1111 13.6C10.1111 13.1487 10.4259 12.8 10.8333 12.8Z"
          fill="#06364B"
        />
      </svg>
    </button>
    <button @click="mute" style="position: relative;">
      <div style="position:absolute;z-index:1; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
        <svg
          style="margin-left:2px;z-index:2;"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.75345 0L2.96307 4.77785H0V11.2222H2.96307L7.75345 16V0ZM11.8725 2.98804L9.58833 5.2662L10.502 6.17747L12.7861 3.89931L11.8725 2.98804ZM6.4612 3.11139V12.8886L3.49814 9.93329H1.29224V6.06671H3.49814L3.68743 5.87791L6.4612 3.11139ZM10.9916 7.35557V8.64443H14.2222V7.35557H10.9916ZM10.502 9.82253L9.58833 10.7338L11.8725 13.012L12.7861 12.1007L10.502 9.82253Z"
            fill="#06364B"
          />
        </svg>
      
    </button>
    <button @click="onClick" style="position: relative;">
      <div style="position:absolute;z-index:1;left:-2px; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
        
      </div>
      <div v-if="section =='gift'" class="out" style="position: absolute; z-index: 1;">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="46" height="47">

      <g transform="matrix(1.3333334 0 0 1.3333334 0 0)">
          <image  x="0" y="0" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANwAAACcCAYAAAAUAsXtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAmeSURBVHhe7d0/
          j2P1FcbxBVKkgqGnQEjp2Yp2RANFChokuiBFSBRRtD3NvgAkijR0K0UiUqpNmVT7ErZLkRQUaVCUiCZRyuWcyc+rmbOP51z/ufZzr7+P9GlOxKx97nNj+9rjefC7v/3wMHwcvgxfhz+HH8ILAHvLcyjPpTyn8tzKc+zhg22J//G98KvwXfhXUD8UwP/
          lOZLnSp4z743TaL/ED3gjfBSehP8G9Q8ClybPhTwn8tx4Y5wux0384DfDb8Lfg7oRwNpl9/MceHOcFvMn/rHXw2fhr0HdKGBtsuvZ+dfHaXD65D8evgj/DOpGAkuX3c6On+9Eq4kb81b4NqgbDCxVdvqtUXO/xI27Dv8YNxZYquzw9ai1d+KGvh3+NG44
          sDTZ3bdHnZeRuMGvha/GHQCWIjv72qjx8hI3/tPwv3FnAFfZ0U9HbZeduCMfhv+MOwa4yW5+OOq6jsQd+mDcMXWHgXPJTn4warquxB3LRzqeXsJFdnFdj2w1cQfzNZ2688CpreM1W5e4o1y9xLl9Neq4/sSdzbcMeJ8O55LdW+6l/30SdzjfHOcTKTi17
          Nyy3tQ+VuKO58fA1FKAuSzj41pzJRbAB55xKt+O2l1uYgn5Wwb8ag/mlh3z/dT/KROLyN81UksCjuWLUTcSy8hfYuU3xzGX7JbPL486JBaSv7qulgUc6rNRM7JJLCUf5fhiIhxbdopHN5VYTH4TkloasK/fjnqRmlhOfgUf33uJY8ku5QcsLutTJbsklp
          NfrKmWB+zq9yG/xJgTbltiOfkttmp5wK5+GfLaACfctsRy8v+R+FsGONS/w88DF0y6xJL+MJYG7OuP4WeBR7cusaT8CyRqicBUvw68fpuSWFL+qSy1RGCqXwROtqmJZfFHIbGv7A6v3XZJLCz/eqRaJtD5y6gRmZpYWv6pVrVMoPP1qBGZmlha/l1ktUy
          g8+WoEZmaWFr+EXK1TKDz8agRmZpY2sOyRGCqh6NGZGpiae+UJQJTvTNqRKYmlnZVlghMdTVqRKYml1aWCEzle8K9ePHCllgk0Ko9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUMXaplAp/bIiRy6UMsEOrVH
          TuTQhVom0Kk9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUMXaplAp/bIiRy6UMsEOrVHTuTQhVom0Kk9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUMXaplAp/bIi
          Ry6UMsEOrVHTuTQhVom0Kk9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUMXaplAp/bIiRy6UMsEOrVHTuTQhVom0Kk9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUM
          XaplAp/bIiRy6UMsEOrVHTuTQhVom0Kk9ciKHLtQygU7tkRM5dKGWCXRqj5zIoQu1TKBTe+REDl2oZQKd2iMncuhCLRPo1B45kUMXaplAp/bIiRy6iOU9r8sEGs9Vl1zIoYtY3rOyTKDzTHXJhRy6iOU9LcsEOk9Vl1zIoYtY3uOyTKDzWHXJhRy6iOU9K
          ssEOo9Ul1zIoYtY3nVZJtC5Vl1yIYcuYnlXZZlA50p1yYUcusjEAn8sCwW2+TE7o7rkQg5djBOOtwYw1TNOuAOME44rlZjqMSfcAcYJx4UTTHXNCXeATcRigVeMusguuZBDF5vEMnkdh87N67eM6pILOXSxSSyT13Ho3Lx+y6guuZBDF5vEMt8vywWq90d
          dZJdcyKGL24mFfl8WDGx8P2pyE9UlF3Lo4nZiqd+UJQMb34ya3ER1yYUcuridWCpPK7HNy6eTGdUlF3LooiYWy9NKVHeeTmZUl1zIoYuaWC5XK1G9vDq5ieqSCzl0URPLfbcsG3h31ONlVJdcyKELlVgwX7uAjaejFneiuuRCDl2oxJI/KUvH5fpk1OJOV
          JdcyKGLbYlFc/EEr1ws2UR1yYUcutiWWPbnZfm4PJ+POrwS1SUXcujivsTCeZS7XFsf3TKqSy7k0MV9iaXzKHe5tj66ZVSXXMihiy6xeB7lLs+9j24Z1SUXcuiiSyyfK5aXR16ZvB3VJRdy6GJK4gDwy6mX4+Uvmd4X1SUXcuhiSuIg8KHmy3HnQ8rborr
          kQg5dTE0cCH51Z/3u/ArOfVFdciGHLqYmDkZ+QzMXUNYrj+3VONxtVJdcyKGLXRIHhK/TW6+br7+bGtUlF3LoYtfEgeGp5fpMfiq5ieqSCzl0sWvi4PDUcl12eiq5ieqSCzl0sU/iAHHVcj0mXZWsUV1yIYcu9k0cKD72tXz3fnzrvqguuZBDF4ckDtiTc
          gCxHE/GYdwrqksu5NDFoYkD97wcSPh7Pg7f3lFdciGHLg5NHDwuoizLXhdJalSXXMihi2MkDmBeROGvqPrLY7TXRZIa1SUXcujiWMkDOQ6oOtA4v6OdbBnVJRdy6OKYyQM6Dqw64Difo55sGdUlF3Lo4tjJAzsOsDrwOL2jn2wZ1SUXcuhijuQBHgdaFQCn
          M8vJllFdciGHLuZKHuhxwFURML/ZTraM6pILOXQxZ/KAB96nO73c+WwnW0Z1yYUcupg7ceDzfTpOutPJXR/8PlsX1SUXcujiVIkS8DGw+R30ca1dorrkQg5dnDJRiPzAM6/rji93uvcHkfeJ6pILOXRx6kQxeF13XLO/XlNRXXIhhy7OkShIvq7jN8cPlzuc/
          fWaiuqSCzl0cc5EWfI7Uvjg8+5yZzt9B8mxo7rkQg5dnDtRnHy0488cT5e7Osuj2u2oLrmQQxcuiRLlnzrmG563y9288qd/zxXVJRdy6MItUSqeZt519qePKqpLLuTQhWuiZPkWwiWfeHnfT3qpf5eoLrmQQxfuydKFS3obIe+r7Ym2ieqSCzl0sZRECfOp5po
          /rZL3ze6p47aoLrmQQxdLS5QyL648Cmt4upn3Ie+LzcWQqVFdciGHLpacKGp+aiXf/F3SyZe3NW/zyT8dcsyoLrmQQxdrSRY45KOF41sLeZvyti36JLsd1SUXcuhirYly52u+fJP4aTjlI2D+W/lv5r+9mNdku0Z1yYUcurik5AkQ8qpnngx5kSIfefa5Apr
          /Tf63+TPyZ+XPXO3JpaK65EIOAcxDDgHM4cWDnwBQ5oyscoK+zAAAAABJRU5ErkJggg==" width="37" height="45"/>
      </g>
      </svg>
      </div>
        
      <svg style="z-index: 12;" data-v-7d622f5c="" width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-7d622f5c="" d="M2.05536 0V1.5H4.77805L0 6.21973L1.07365 7.28027L5.85171 2.56055V5.25H7.37024V0H2.05536ZM14.2037 0C12.9547 0 11.9259 1.01627 11.9259 2.25C11.9259 3.48373 12.9547 4.5 14.2037 4.5C15.4526 4.5 16.4815 3.48373 16.4815 2.25C16.4815 1.01627 15.4526 0 14.2037 0ZM14.2037 1.5C14.6327 1.5 14.9629 1.82623 14.9629 2.25C14.9629 2.67377 14.6327 3 14.2037 3C13.7746 3 13.4444 2.67377 13.4444 2.25C13.4444 1.82623 13.7746 1.5 14.2037 1.5ZM13.4444 5.25C11.7694 5.25 10.4073 6.5955 10.4073 8.25V12.186L11.9259 12.936V18H13.4444V12.0103L11.9259 11.2603V8.25C11.9259 7.42275 12.6069 6.75 13.4444 6.75H14.9629C15.8004 6.75 16.4815 7.42275 16.4815 8.25V11.2603L14.9629 12.0103V18H16.4815V12.936L18 12.186V8.25C18 6.5955 16.6379 5.25 14.9629 5.25H13.4444ZM7.37024 9V18H8.88878V9H7.37024ZM4.33317 12V18H5.85171V12H4.33317ZM1.29609 15V18H2.81463V15H1.29609Z" fill="#06364B">
        </path></svg>
      
    </button>
  </div>
  <div v-else-if="section =='main'" class="controls-panel">
    <div v-if="muted" class="wrapper-popups">
      </div>
    <button style="position: relative;">
      <div v-if="dots.fourh && muted" class="help-popup-fourth fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 18px;">{{$ml.get("חזרה למשחק הקודם")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons">
              <div class="dots" :class="!dots.first?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.second?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.third?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fourh?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dots.fourh" @click="returnLastCard" style="position:absolute;z-index:12; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
        <div v-else @click="returnLastCard" style="position:absolute;z-index:1; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
      <svg @click="returnLastCard"
      v-if="dots.fourh"
      style="margin-left:1px;z-index:12;"
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0909 0L18 5L13.0909 10V5.83333H5.31818C3.28946 5.83333 1.63636 7.51705 1.63636 9.58333C1.63636 11.6496 3.28946 13.3333 5.31818 13.3333H6.54545V15H5.31818C2.3969 15 0 12.5587 0 9.58333C0 6.60795 2.3969 4.16667 5.31818 4.16667H13.0909V0ZM16.3636 13.3333V15H14.7273V13.3333H16.3636ZM13.0909 13.3333V15H11.4545V13.3333H13.0909ZM9.81818 13.3333V15H8.18182V13.3333H9.81818Z"
          fill="#06364B"
        />
      </svg>
      <svg @click="returnLastCard"
      v-else
      style="margin-left:1px;z-index:2;"
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0909 0L18 5L13.0909 10V5.83333H5.31818C3.28946 5.83333 1.63636 7.51705 1.63636 9.58333C1.63636 11.6496 3.28946 13.3333 5.31818 13.3333H6.54545V15H5.31818C2.3969 15 0 12.5587 0 9.58333C0 6.60795 2.3969 4.16667 5.31818 4.16667H13.0909V0ZM16.3636 13.3333V15H14.7273V13.3333H16.3636ZM13.0909 13.3333V15H11.4545V13.3333H13.0909ZM9.81818 13.3333V15H8.18182V13.3333H9.81818Z"
          fill="#06364B"
        />
      </svg>
    </button>
    <button style="position: relative;">
      <div v-if="dots.third && muted" class="help-popup-third fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 18px;">{{$ml.get("שיתוף המשחק עם חברים")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons">
              <div class="dots" :class="!dots.first?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.second?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.third?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fourh?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dots.third" @click="share" style="position:absolute;z-index:12; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;"></div>
      <div v-else @click="share" style="position:absolute;z-index:1; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
      <svg @click="share"
      v-if="dots.third"
      style="margin-right:2px;z-index:12;"
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8333 0C9.64527 0 8.66667 1.08399 8.66667 2.4C8.66667 2.55276 8.6824 2.7017 8.70757 2.84687L3.55469 6.17656C3.17672 5.82228 2.69539 5.6 2.16667 5.6C0.978604 5.6 0 6.68399 0 8C0 9.31601 0.978604 10.4 2.16667 10.4C2.69539 10.4 3.17672 10.1777 3.55469 9.82344L8.70757 13.1516C8.68223 13.2972 8.66667 13.4467 8.66667 13.6C8.66667 14.916 9.64527 16 10.8333 16C12.0214 16 13 14.916 13 13.6C13 12.284 12.0214 11.2 10.8333 11.2C10.3043 11.2 9.82337 11.4235 9.44531 11.7781L4.29243 8.44688C4.3176 8.3017 4.33333 8.15276 4.33333 8C4.33333 7.84724 4.3176 7.6983 4.29243 7.55312L9.44531 4.22344C9.82328 4.57772 10.3046 4.8 10.8333 4.8C12.0214 4.8 13 3.71601 13 2.4C13 1.08399 12.0214 0 10.8333 0ZM10.8333 1.6C11.2408 1.6 11.5556 1.9487 11.5556 2.4C11.5556 2.8513 11.2408 3.2 10.8333 3.2C10.4259 3.2 10.1111 2.8513 10.1111 2.4C10.1111 1.9487 10.4259 1.6 10.8333 1.6ZM2.16667 7.2C2.57409 7.2 2.88889 7.5487 2.88889 8C2.88889 8.4513 2.57409 8.8 2.16667 8.8C1.75924 8.8 1.44444 8.4513 1.44444 8C1.44444 7.5487 1.75924 7.2 2.16667 7.2ZM10.8333 12.8C11.2408 12.8 11.5556 13.1487 11.5556 13.6C11.5556 14.0513 11.2408 14.4 10.8333 14.4C10.4259 14.4 10.1111 14.0513 10.1111 13.6C10.1111 13.1487 10.4259 12.8 10.8333 12.8Z"
          fill="#06364B"
        />
      </svg>
      <svg @click="share"
      v-else
      style="margin-right:2px;z-index:2;"
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.8333 0C9.64527 0 8.66667 1.08399 8.66667 2.4C8.66667 2.55276 8.6824 2.7017 8.70757 2.84687L3.55469 6.17656C3.17672 5.82228 2.69539 5.6 2.16667 5.6C0.978604 5.6 0 6.68399 0 8C0 9.31601 0.978604 10.4 2.16667 10.4C2.69539 10.4 3.17672 10.1777 3.55469 9.82344L8.70757 13.1516C8.68223 13.2972 8.66667 13.4467 8.66667 13.6C8.66667 14.916 9.64527 16 10.8333 16C12.0214 16 13 14.916 13 13.6C13 12.284 12.0214 11.2 10.8333 11.2C10.3043 11.2 9.82337 11.4235 9.44531 11.7781L4.29243 8.44688C4.3176 8.3017 4.33333 8.15276 4.33333 8C4.33333 7.84724 4.3176 7.6983 4.29243 7.55312L9.44531 4.22344C9.82328 4.57772 10.3046 4.8 10.8333 4.8C12.0214 4.8 13 3.71601 13 2.4C13 1.08399 12.0214 0 10.8333 0ZM10.8333 1.6C11.2408 1.6 11.5556 1.9487 11.5556 2.4C11.5556 2.8513 11.2408 3.2 10.8333 3.2C10.4259 3.2 10.1111 2.8513 10.1111 2.4C10.1111 1.9487 10.4259 1.6 10.8333 1.6ZM2.16667 7.2C2.57409 7.2 2.88889 7.5487 2.88889 8C2.88889 8.4513 2.57409 8.8 2.16667 8.8C1.75924 8.8 1.44444 8.4513 1.44444 8C1.44444 7.5487 1.75924 7.2 2.16667 7.2ZM10.8333 12.8C11.2408 12.8 11.5556 13.1487 11.5556 13.6C11.5556 14.0513 11.2408 14.4 10.8333 14.4C10.4259 14.4 10.1111 14.0513 10.1111 13.6C10.1111 13.1487 10.4259 12.8 10.8333 12.8Z"
          fill="#06364B"
        />
      </svg>
    </button>
    <button style="position: relative;">
      <div v-if="dots.second && muted" class="help-popup-second fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 18px;">{{$ml.get("שמיעת הוראות המשחק")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons">
              <div class="dots" :class="!dots.first?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.second?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.third?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fourh?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>
      <div @click="mute" v-if="!volume && dots.second" style="position:absolute;z-index:12; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;"></div>
      <div @click="mute" v-else="!volume" style="position:absolute;z-index:2; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;"></div>
      
      <div @click="mute" v-if="volume" style="position:absolute;z-index:2; background: #AAD9E7; border-radius:50%; width:32px; height: 32px;">
      </div>
        <svg
        v-if="dots.second"
        @click="mute"
          style="margin-left:2px;z-index:12;"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.75345 0L2.96307 4.77785H0V11.2222H2.96307L7.75345 16V0ZM11.8725 2.98804L9.58833 5.2662L10.502 6.17747L12.7861 3.89931L11.8725 2.98804ZM6.4612 3.11139V12.8886L3.49814 9.93329H1.29224V6.06671H3.49814L3.68743 5.87791L6.4612 3.11139ZM10.9916 7.35557V8.64443H14.2222V7.35557H10.9916ZM10.502 9.82253L9.58833 10.7338L11.8725 13.012L12.7861 12.1007L10.502 9.82253Z"
            fill="#06364B"
          />
        </svg>
        <svg
        v-else
        @click="mute"
          style="margin-left:2px;z-index:12;"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.75345 0L2.96307 4.77785H0V11.2222H2.96307L7.75345 16V0ZM11.8725 2.98804L9.58833 5.2662L10.502 6.17747L12.7861 3.89931L11.8725 2.98804ZM6.4612 3.11139V12.8886L3.49814 9.93329H1.29224V6.06671H3.49814L3.68743 5.87791L6.4612 3.11139ZM10.9916 7.35557V8.64443H14.2222V7.35557H10.9916ZM10.502 9.82253L9.58833 10.7338L11.8725 13.012L12.7861 12.1007L10.502 9.82253Z"
            fill="#06364B"
          />
        </svg>
      
    </button>
    <button :id="currentItem.id_playlist+'_id'"  style="position: relative; pointer-events: none;">
      <div v-if="dots.first && muted" class="help-popup-first fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 18px;">{{$ml.get("הכישורים שהמשחק מפתח")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons">
              <div class="dots" :class="!dots.first?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.second?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.third?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fourh?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dots.first" style="position:absolute;z-index:12;left:-2px; background: #DDF0F5; border-radius:50%; width:30px; height: 30px;">
      </div>
      <div v-else @click="onClick" style="position:absolute;z-index:1;left:-2px; background: #DDF0F5; border-radius:50%; width:30px; height: 30px; pointer-events: all;">
      </div>
      <div v-if="section =='gift' && dots.first" class="out" style="position: absolute; z-index: 12;">
        <svg style="z-index: 12;" data-v-7d622f5c="" width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-7d622f5c="" d="M2.05536 0V1.5H4.77805L0 6.21973L1.07365 7.28027L5.85171 2.56055V5.25H7.37024V0H2.05536ZM14.2037 0C12.9547 0 11.9259 1.01627 11.9259 2.25C11.9259 3.48373 12.9547 4.5 14.2037 4.5C15.4526 4.5 16.4815 3.48373 16.4815 2.25C16.4815 1.01627 15.4526 0 14.2037 0ZM14.2037 1.5C14.6327 1.5 14.9629 1.82623 14.9629 2.25C14.9629 2.67377 14.6327 3 14.2037 3C13.7746 3 13.4444 2.67377 13.4444 2.25C13.4444 1.82623 13.7746 1.5 14.2037 1.5ZM13.4444 5.25C11.7694 5.25 10.4073 6.5955 10.4073 8.25V12.186L11.9259 12.936V18H13.4444V12.0103L11.9259 11.2603V8.25C11.9259 7.42275 12.6069 6.75 13.4444 6.75H14.9629C15.8004 6.75 16.4815 7.42275 16.4815 8.25V11.2603L14.9629 12.0103V18H16.4815V12.936L18 12.186V8.25C18 6.5955 16.6379 5.25 14.9629 5.25H13.4444ZM7.37024 9V18H8.88878V9H7.37024ZM4.33317 12V18H5.85171V12H4.33317ZM1.29609 15V18H2.81463V15H1.29609Z" fill="#06364B">
        </path></svg>
      </div>
      <div v-else-if="section =='gift'" class="out" style="position: absolute; z-index: 1;">
        <svg style="z-index: 12;" data-v-7d622f5c="" width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-7d622f5c="" d="M2.05536 0V1.5H4.77805L0 6.21973L1.07365 7.28027L5.85171 2.56055V5.25H7.37024V0H2.05536ZM14.2037 0C12.9547 0 11.9259 1.01627 11.9259 2.25C11.9259 3.48373 12.9547 4.5 14.2037 4.5C15.4526 4.5 16.4815 3.48373 16.4815 2.25C16.4815 1.01627 15.4526 0 14.2037 0ZM14.2037 1.5C14.6327 1.5 14.9629 1.82623 14.9629 2.25C14.9629 2.67377 14.6327 3 14.2037 3C13.7746 3 13.4444 2.67377 13.4444 2.25C13.4444 1.82623 13.7746 1.5 14.2037 1.5ZM13.4444 5.25C11.7694 5.25 10.4073 6.5955 10.4073 8.25V12.186L11.9259 12.936V18H13.4444V12.0103L11.9259 11.2603V8.25C11.9259 7.42275 12.6069 6.75 13.4444 6.75H14.9629C15.8004 6.75 16.4815 7.42275 16.4815 8.25V11.2603L14.9629 12.0103V18H16.4815V12.936L18 12.186V8.25C18 6.5955 16.6379 5.25 14.9629 5.25H13.4444ZM7.37024 9V18H8.88878V9H7.37024ZM4.33317 12V18H5.85171V12H4.33317ZM1.29609 15V18H2.81463V15H1.29609Z" fill="#06364B">
        </path></svg>
      </div>
      <svg v-if="dots.first" style="z-index: 12;" data-v-7d622f5c="" width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-7d622f5c="" d="M2.05536 0V1.5H4.77805L0 6.21973L1.07365 7.28027L5.85171 2.56055V5.25H7.37024V0H2.05536ZM14.2037 0C12.9547 0 11.9259 1.01627 11.9259 2.25C11.9259 3.48373 12.9547 4.5 14.2037 4.5C15.4526 4.5 16.4815 3.48373 16.4815 2.25C16.4815 1.01627 15.4526 0 14.2037 0ZM14.2037 1.5C14.6327 1.5 14.9629 1.82623 14.9629 2.25C14.9629 2.67377 14.6327 3 14.2037 3C13.7746 3 13.4444 2.67377 13.4444 2.25C13.4444 1.82623 13.7746 1.5 14.2037 1.5ZM13.4444 5.25C11.7694 5.25 10.4073 6.5955 10.4073 8.25V12.186L11.9259 12.936V18H13.4444V12.0103L11.9259 11.2603V8.25C11.9259 7.42275 12.6069 6.75 13.4444 6.75H14.9629C15.8004 6.75 16.4815 7.42275 16.4815 8.25V11.2603L14.9629 12.0103V18H16.4815V12.936L18 12.186V8.25C18 6.5955 16.6379 5.25 14.9629 5.25H13.4444ZM7.37024 9V18H8.88878V9H7.37024ZM4.33317 12V18H5.85171V12H4.33317ZM1.29609 15V18H2.81463V15H1.29609Z" fill="#06364B">
        </path></svg>

      <svg v-else @click="onClick" style="z-index: 12;" data-v-7d622f5c="" width="16" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path data-v-7d622f5c="" d="M2.05536 0V1.5H4.77805L0 6.21973L1.07365 7.28027L5.85171 2.56055V5.25H7.37024V0H2.05536ZM14.2037 0C12.9547 0 11.9259 1.01627 11.9259 2.25C11.9259 3.48373 12.9547 4.5 14.2037 4.5C15.4526 4.5 16.4815 3.48373 16.4815 2.25C16.4815 1.01627 15.4526 0 14.2037 0ZM14.2037 1.5C14.6327 1.5 14.9629 1.82623 14.9629 2.25C14.9629 2.67377 14.6327 3 14.2037 3C13.7746 3 13.4444 2.67377 13.4444 2.25C13.4444 1.82623 13.7746 1.5 14.2037 1.5ZM13.4444 5.25C11.7694 5.25 10.4073 6.5955 10.4073 8.25V12.186L11.9259 12.936V18H13.4444V12.0103L11.9259 11.2603V8.25C11.9259 7.42275 12.6069 6.75 13.4444 6.75H14.9629C15.8004 6.75 16.4815 7.42275 16.4815 8.25V11.2603L14.9629 12.0103V18H16.4815V12.936L18 12.186V8.25C18 6.5955 16.6379 5.25 14.9629 5.25H13.4444ZM7.37024 9V18H8.88878V9H7.37024ZM4.33317 12V18H5.85171V12H4.33317ZM1.29609 15V18H2.81463V15H1.29609Z" fill="#06364B">
        </path></svg>
      
      
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PlaylistAPI } from '@/api';
export default {
  data() {
    return{
      // section:'main',
      volume:false,
      audio_played: false,
      shared: false,
      giftsShown: false,
    }
  },
  props:{
    uuid:null,
    dots:{
            first:false,
            second:false,
            third:false,
            fourh:false,
            fifth:false,
            sixth:false
      },
    muted:false,
    section:null,
  },
  watch:{
    
  },
  mounted(){
    
  },
  methods: {
    handleChange (event) {
       this.$emit("customChange")
    },
    onClick(){
      
      if(!this.giftsShown)
      {
      PlaylistAPI.addActivity(this.currentItem.id_playlist, 'gif');
        this.giftsShown = true;
      }
      this.$root.$emit('CardGame',this.uuid)
      // if(this.section=='main')
      // {
      //   this.section = 'gift'
      // }
      // else
      // {
      //   this.section = 'main'
      // }
    },
    share() {
      // this.$store.commit('setReplyCounter', 50);
      if(!this.shared)
      {
      PlaylistAPI.addActivity(this.currentItem.id_playlist, 'sha');
        this.shared = true;
      }
      this.$store.dispatch("sharePlaylist", this.currentItem.id_playlist);
    },
    mute() {
      let el = this.getCurrentEl();
      el.__vue__.showed = !el.__vue__.showed;
      this.volume = !this.volume;
      if(!this.audio_played)
      {
      PlaylistAPI.addActivity(this.currentItem.id_playlist, 'aud');
        this.audio_played = true;
      }
    },
    getCurrentEl() {
      const cards = document.querySelectorAll(
        "#playlist_cards_container .card-game"
      );
      return cards[cards.length - 1];
    },
    returnLastCard() {
      this.$store.dispatch('playlist/returnCard');
      //this.$store.commit("playlist/setPrev", true);
     // this.getRandom();
    },
    getRandom(data = {}) {
      if (this.$route.params.infinity) {
        data.gifts = this.$route.params.gifts;
        data.giftsOnly = true;
      }
      return this.$store
        .dispatch("playlist/getRandom", data)
        .then((res) => {
          this.index++;
           this.$store.commit("playlist/setPrev", false);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
        });
    },
  },
  computed: {
    ...mapGetters({
      currentItem: "playlist/currentItem",
      // section: "playlist/prop"
    }),
  },
  props:{
    uuid:null,
    dots:{},
    muted:false,
    section:null,
  }
};
</script>

<style lang="scss" scoped>
.dots{
    display: inline-block;
    box-sizing: content-box;
    width: 6px !important; 
    height: 6px !important;
    border-radius: 50%;
    margin: 4px 3px;
}
.dots-simple{
    background-color: #D9D9D9;
    cursor: pointer;
}
.dots-active{
  width: 7px !important;
    height: 7px !important;
    background-color: #149CC6;
    cursor: pointer;
}
.buttons{
  display: inline-block;
  margin-left: 9px;
}
.controll-info{
  display: inline-block;
  text-align: left;
  .button-info{
    margin-left: 7px;
    margin-bottom: 7px;
    padding: 0px !important;
    width: 52px; 
    height: 24px; 
    background: #3FBCD4;
    border-radius: 2px;
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #FFFFFF;
  }
}
.wrapper-popups{
    pointer-events: none;
    position: absolute;
    z-index: 9;
    background: #000000;
    opacity: 0.65;
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    top: -93px;
    bottom: 0;
    overflow-y: auto;
}
.help-popup-first {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -91px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-second {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-third {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-fourth {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.out{
  // background: blue;
    width: 38px;
    height: 36px;
    bottom: 7px;
    // border-radius: 0 0 25px 25px;
}
//
.controls-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    width: 30px;
    height: 20px;
    margin: 0 10px;
    border-radius: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>