import store from './../index';

const SearchPlaylistStore = {
  namespaced: true,
  state: {
    data: null,
  },
  mutations: {
    saveData(state, data) {
      state.data = data;
    },
  },
  actions: {
   
  },
  getters: {
    data(state) {
      return state.data;
    },
  }
};

export { SearchPlaylistStore }
