import Vue from 'vue'

window.ebus = document.documentElement;

document.addEventListener(
  "deviceready",
  function() {
    MobileAccessibility.usePreferredTextZoom(false);
  },
  false
);

Math.UUID = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

MouseEvent.prototype.relativeOffsetByEvent = function (container) {
  var rect = container.getBoundingClientRect();
  var x = this.clientX - rect.left;
  var y = this.clientY - rect.top;

  return { x, y };
}

Element.prototype.relativeOffset = function (to) {
  var rect_a = this.getBoundingClientRect();
  var rect_b = to.getBoundingClientRect();

  var x = rect_a.left - rect_b.left;
  var y = rect_a.top - rect_b.top;

  return { x, y };
}

if (!Element.prototype.triggerEvent) {
  Element.prototype.triggerEvent = function(eventName) {
    var event;

    if (document.createEvent) {
      event = document.createEvent("HTMLEvents");
      event.initEvent(eventName, true, true);
    } else {
      event = document.createEventObject();
      event.eventType = eventName;
    }

    event.eventName = eventName;
    if (document.createEvent) {
      this.dispatchEvent(event);
    } else {
      this.fireEvent("on" + event.eventType, event);
    }
  };
}

Number.prototype.map = function(in_min, in_max, out_min, out_max) {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

window.scrollSmoothTo = function(to, duration) {
  return new Promise(function(resolve, reject) {
    const element = document.scrollingElement;
    const start = (element && element.scrollTop) || window.pageYOffset,
      change = to - start,
      increment = 20;
    let currentTime = 0;

    const animateScroll = function() {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        window.setTimeout(animateScroll, increment);
      } else {
        resolve();
      }
    };
    animateScroll();
  });
};

Vue.prototype.$attent = function (selector) {
  const el = document.querySelector(selector);
  if(el) {
    el.classList.add('--attent')
    setTimeout(() => {
      el.classList.remove('--attent')
    }, 2000);
  } else {
    console.warn('No elem found for attent');
  }
}
