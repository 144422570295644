const ShootlistAPI = {

  getRandomGame(data) {
    return config.API.post("/shootlist/random", data)
  },

  getState(data) {
    return config.API.post("/shootlist/filter-state/get", data)
  },

  addActivity(shootlist_id, type, data) {
    return config.API.put("/shootlist/" + shootlist_id + "/activity/" + type, data);
  },
   saveSettings(data) {
    return config.API.post('shootlist/saveFilter', data)
   },
  getCategories() {
    return config.API.get('shootlist/category')
  }
};

export { ShootlistAPI }