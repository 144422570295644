<template>
  <main class="games-activity" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get('מעקב התקדמות')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading :loading="loading.sync" class="sm-cont">
      <template v-if="!loading.sync">
        <div class="games-activity-wrapper">
          <div class="header">

            <div class="title"> {{data.user.name + (data.user.surname ? ' '+data.user.surname : '') }}  </div>
              <SelectTeacher
                @input="filter"
                style="margin: 0 auto;"
                placeholder="Chose date"
                :options="after.filtered"
                v-model="after.selected"/>
          </div>

          <div class="gameq-wrapper">
            <div

              class="title">

              <span  >{{$ml.get('משחקים ששחקתי:')+' '}} {{data.playlist.playedPlaylist.playlists.length}}</span>
            </div>
            <div class="sections">
              <div  :key="i*81" class="item" v-for="(section,i) in playlistSections">
                <div class="color" :style="{ backgroundColor: section.color }"></div>
                <NameTranslated class="name" :translate="section.translation" />
              </div>
              </div>
          </div>
          <div class="game-list">
            <div v-if="!data.playlist.playedPlaylist.playlists.length" class="game-w center">
              {{$ml.get('הרשימה ריקה')}}
            </div>
            <div
              v-else
              v-for="(game,index) in data.playlist.playedPlaylist.playlists"
              :key="index"
              class="game-w">
              <GameActivityElement :game="game" :index="index" />
            </div>

          </div>

        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import SelectTeacher from "@/components/teacher/SelectTeacher";
import GameActivityElement from "@/components/teacher/GameActivityElement";
import {TeacherAPI} from "@/api";
import ShootEl from "@/components/playlist/playlist-requests/ShootEl";
import NameTranslated from "@/components/teacher/NameTranslated";
export default {
  data() {
    return {
      index: 0,
      popup_showed: false,
      choice: '',
      radio: '',
      loading: {
        next: false,
        sync: false
      },
      data:[],

      categories: ['כמה פעמים בכית השבוע? ', 'כמה פעמים צחקת השבוע? ', 'כמה פעמים בכית השבוע? '],
        after: {
          options: [
            {name: 'שנה', str: 'Year', index: '0'},
            {name: 'ינואר', str: 'Jan', index: '1'},
            {name: 'פברואר', str: 'Feb', index: '2'},
            {name: 'מרץ', str: 'Mar', index: '3'},
            {name: 'אפריל', str: 'Apr', index: '4'},
            {name: 'מאי', str: 'May', index: '5'},
            {name: 'יוני', str: 'Jun', index: '6'},
            {name: 'יולי', str: 'Jul', index: '7'},
            {name: 'אוגוסט', str: 'Aug', index: '8'},
            {name: 'ספטמבר', str: 'Sep', index: '9'},
            {name: 'אוקטובר', str: 'Oct', index: '10'},
            {name: 'נובמבר', str: 'Nov', index: '11'},
            {name: 'דצמבר', str: 'Dec', index: '12'},
          ],
          filtered: [],
          selected: 'שנה',
        },
    };
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    SelectTeacher,
    GameActivityElement,
    ShootEl,
    NameTranslated
  },
  methods:{
    filter(data){
      let s = this.after.options.filter(item=>{
        return this.$ml.get(item.name) == data;
      });
      TeacherAPI.gamesDetails({id:this.$route.params.id , filter:s[0]!=null?s[0].index:'0'}).then(res=>{
        this.data = res.data;
      }).catch();
    },
  },
  created() {
    window.fbq("track", "מעקב התקדמות");
    this.loading.sync = true;

    let month = new Date().getMonth()+1;

    this.after.filtered = this.after.options;

    TeacherAPI.gamesDetails({id:this.$route.params.id , filter:'0'}).then(res=>{
      this.data = res.data;
      this.loading.sync = false;
    }).catch();
  },
  computed: {
    playlistSections()
    {
      return this.data.playlist.playedPlaylist.sections.sort((a,b)=>a.id_section<b.id_section?1:-1);
    }
  }

};  
</script>
<style scoped lang="scss">
.sections {
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      margin: 3px 0 8px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 5px;
        width: 108px;

        .name {
          color: #000000;
          font-size: 12px;
        }
        .color {
          margin-left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }
    }
.center{
  text-align: center;
}
.games-activity {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {
    flex-grow: 1;
    min-height: 450px;
    background: #F2F2F2;

    .games-activity-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        margin: 20px 0 10px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #00465B;
      }
      .header{
        background: white;
        width: 100%;
        padding: 15px 15px;
        .child-name{
          width: 100%;
          text-align: right;
          font-size: 14px;
          color: #00465B;
        }
      }
      .selectors{
        margin: 0 auto;
        max-width: 220px;
        display: flex;
        justify-content: space-between;
      }
      .game-list{
        max-height: calc(100vh - 289px);
        overflow: scroll;
        background: #f2f2f2;
        width: 100%;
        padding: 0 20px 0 20px;
        .game-activity-element{
          padding-top: 15px;
        }
      }
      .games-wrapper{
        width: 100%;
        padding: 15px 20px 0 20px;

        .title{
          margin: 0 0 10px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          color: #00465B;
        }
        .categories{
           background: white;
          padding-bottom: 10px;
          border-radius: 10px;
          .cat{
            padding: 10px 13px 0;
            display: flex;
            align-items: center;
            flex-direction: row;
            span{
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #005D79;
            }
            .round{
              width: 6px;
              height: 6px;
              background: #005D79;
              border-radius: 50%;
              margin-left: 6px;
            }
          }
        }
        .big-categories{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
           padding-bottom: 16px;
          .cat{
            margin: 0 5px;
            display: flex;
            align-items: center;
            flex-direction: row;
            span{
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #005D79;
            }
            .round{
              width: 6px;
              height: 6px;
              background: #005D79;
              border-radius: 50%;
              margin-left: 6px;
            }
          }
        }
      }

    }
  }
}

</style>