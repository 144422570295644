<template>
  <main style="background: #005d79">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get("שגיאת מנוי") }}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading   :loading="load" class="sm-cont" style="background: #DDF0F5;">
    <div  class="sm-cont-s" style="margin: 131px auto;">
      <div class="logo-wrapper" style="text-align:center;">
        <div style="background: #FFFFFF;border: 2px solid #DDF0F5;border-radius: 6px; padding: 10px;width: 345px;height: 345px;">
          <div style="margin: auto; width: 200px; margin-top: 77px;">
            <span style="font-family: 'Kartiv';font-weight: 700;font-size: 34px;line-height: 35px;text-align: center;color: #FF7384;">{{ $ml.get('אירעה שגיאה בעת ההרשמה') }}</span>
          </div>
          <div style="width: 100%;margin-top: 105px;">
            <app-btn class="btn-success">{{ $ml.get('חזרה למשחקים') }}</app-btn>
          </div>
          
        </div>
      </div>
    </div>
    </app-loading>
  </main>
</template>

<script>
import { SubscribeAPI } from '@/api/subscribe.api';
import SimpleHeader from "@/components/SimpleHeader.vue";
import AppBtn from "@/components/ui/app-btn/AppBtn.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Btn from "@/components/ui/app-btn/AppBtn";

export default {
  created(){
  SubscribeAPI.getPrice().then((res)=>{
    window.fbq('track','Purchase',{value: res.data.data, currency: "ILS"});
  });
  },
  components:{
    Btn,
    SimpleHeader,
    SidebarToggler,
    AppBtn,
  }
};
</script>

<style lang="scss" scoped>
.btn-success{
  margin: auto;
  width: 300px;
  height: 48px;

  background: #FDC671 !important;
  border-radius: 24px;
  font-family: 'App';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #000000;

}

main {
  overflow: hidden;
  padding-bottom: 20px;
  background: #F2F2F2;
  header {
    padding: 15px 10px;
    padding-bottom: 0;
    button {
      color: #fff;
    }
  }
  .cancel {

  }

  .disc {
    background: #FFFFFF;
    border-radius: 10px;
    margin: 0 10px;
    padding: 5px;
    padding-bottom: 0;


    .bordered {
      padding: 13px 20px;
      border: 1px solid #fff;
      display: flex;
      position: relative;


      align-items: flex-start;

      color: #00465B;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a {
        font-size: 14px;
        line-height: 19px;
        color: #149CC6;
      }
      p {
        &.gray{
          color: #4D4D4D;
        }
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465B;
        margin: 0;

      }
      &.no-subs {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a{
          font-size: 14px;
          line-height: 19px;
          color: #149CC6;
        }
      }
    }
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #dbdbdb;
    margin: 0 auto;
    position: relative;
    color: #fff;
    text-align: center;
    line-height: 100px;
    font-size: 38px;
    input {
      position: absolute;
      top: 0;
      left: -99999px;
      opacity: 0;
    }
    .edit {
      padding: 0;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #00465B;
      color: #dbdbdb;
      left: 0;
      bottom: 0;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNDMUMxQzEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDE2IDI3Ny4zMzNIMjc3LjMzM1Y0MTZoLTQyLjY2NlYyNzcuMzMzSDk2di00Mi42NjZoMTM4LjY2N1Y5Nmg0Mi42NjZ2MTM4LjY2N0g0MTZ2NDIuNjY2eiIvPjwvc3ZnPg==);*/
      /*background-position: center;*/
      /*background-repeat: no-repeat;*/
      background-size: 30px;
      &:after{
       content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        top: 50%;
        left: 50%;
      }
    }
  }
  .inputs {
    padding-bottom: 20px;
    &.pasww{
      p {
        margin: 0;
        margin-top: 7px;

        width: unset!important;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465B;
      }
      .input-r{
        width: 53%!important;
        @media screen and (max-width: 360px){
          width: 50%!important;
        }
      }
      .section{
        padding: 0 15px;
        @media screen and (max-width: 360px){
          padding: 0!important;
        }
      }
    }
    .first{
      background: #FFFFFF;
      border-radius: 10px;
      margin: 0 10px;
      padding: 17px 20px;
      .section {
        margin-top: 10px;

        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
        &.old{
          &:after{
            content: 'מספר הטלפון' ;
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            bottom: -16px;
            color: #A2A2A2;
          }
        }
        &.new{
          &:after{
            content: 'אישור הסיסמה' ;
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            bottom: -16px;
            line-height: 16px;
            text-align: right;
            color: #A2A2A2;
          }
        }
        p {
          margin: 0;
          margin-top: 7px;
          width: 70px;

          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #00465B;
        }
        .input-r{
          width: 140px;
          margin-top: 10px;
          margin-right: 9px;
          font-size: 16px!important;
          position: relative;

        }
        .input-register{
          .input-reg{
            line-height: 22px;
            text-align: right;
            color: #00465B;
          }
        }
        input {
          width: 100%;

        }
      }
    }

  }
  .pre-bot-text,
  .bot-text {
    display: block;
    color: #fff;
    padding: 0 20px;
  }
  .bot-text {
    margin-top: 20px;
  }
}
  .icons-tab-w{
    margin-top: 25px;
  }
  .logo-wrapper{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 5px;
    width: 355px;
    height: 355px;
    margin: 0px 10px 20px 10px;
  }
button{
  &.cancel{
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    background-color: white;
    border: none;
    box-shadow: none;
    color: #149CC6;
  }
}
  //
.isActive{
  font-size: 14px;
  opacity: 1;
  margin-top: 17px;
  display: block;
  transition: all .4s ease-in-out;
}
.unActive{
  transition: all .4s ease-in-out;
  font-size: 0!important;
  opacity: 0;
  margin-top: 0!important;
  display: none;
}
.password{

  position: relative;
  margin: 2px 0;
  background: #FFFFFF;
  border-radius: 10px;
  transition: all .4s ease-in-out;
  color: #828282;
  .question{
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #00465B;
    .terms{
      left: 30px;
      position: absolute;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: right;
      color: #FFFFFF;
      max-width: 145px;
    }
    button{
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      width: 88%;
      text-align: start;

    }
  }
  .show-b{
    cursor: pointer;
    position: absolute;
    right: 63px;
    padding: 9px;
    top: -13px;
    span{
      svg{
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  .answer{
    background: white;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .error-v{
      margin-top: 20px;
      position: absolute;
      left: 68px;
    }
  }
}
.save-b{
  width: 180px;
  margin: 30px auto 30px;

}
  .button-subcr{
    font-size: 14px;
    line-height: 19px;
    color: #149CC6;
  }
</style>
