<template>
  <transition name="fade-move-left" mode="out-in">
    <div  class="player" style="position: relative; text-align: right; font-size: 14px;">
      <span style="position: absolute; top: -7px; right: 2px;">{{ $ml.get('לשמיעת הוראות המשחק:') }}</span>
      <div class="seek-bar-wrapper">
        <div class="seek-bar">
          <div class="play-button">
            <a @click.prevent="playing = !playing" title="Play/Pause" href="#">
              <img alt="" v-if="playing" src="@/assets/img/icons/pause.svg" />
              <img alt="" v-else src="@/assets/img/icons/play.svg" />
            </a>
          </div>
          <div class="cancel-button">
            <a @click.prevent="$emit('input', !value)" title="Play/Pause" href="#">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00001 1L1 9" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M1 1L9.00001 9" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
            </a>
          </div>
          <div
            @click="seek"
            class="player-progress"
            id="player-time"
            title="Time played : Total time"
          >
            <div    class="player-background"></div>
            <div
              :style="{ width: this.percentComplete + '%' }"
              class="player-seeker"
              :class="!playing ? 'stopped' : ''"
            ></div>

            <!--        <div class="player-time-current">{{ currentTime }}</div>-->
          </div>
        </div>
      </div>
      <audio
        ref="audiofile"
        :src="file"
        preload="auto"
        style="display: none;"
      ></audio>
    </div>
  </transition>
</template>

<script>
const convertTimeHHMMSS = val => {
  let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
  return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
};

export default {
  props: {
    file: {
      type: String,
      default: null
    },
    value:{},
  },
  data: () => ({
    audio: undefined,
    currentSeconds: 0,
    durationSeconds: 0,
    innerLoop: false,
    loaded: false,
    playing: false,
    volume: 100
  }),
  computed: {
    currentTime() {
      return convertTimeHHMMSS(this.currentSeconds);
    },
    durationTime() {
      return convertTimeHHMMSS(this.durationSeconds);
    },
    percentComplete() {
      return parseInt((this.currentSeconds / this.durationSeconds) * 100);
    }
  },
  watch: {
    playing(value) {
      if (value) {
        return this.audio.play();
      }
      this.audio.pause();
    }
  },
  methods: {
    load() {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        this.durationSeconds = parseInt(this.audio.duration);
        return false;
      }
      throw new Error("Failed to load sound file.");
    },
    seek(e) {
      if (!this.playing || e.target.tagName === "SPAN") {

          return;
      }
      const el = document.getElementById('player-time').getBoundingClientRect();

      const seekPos = (e.clientX - el.left) / el.width;
      this.audio.currentTime = parseInt(this.audio.duration * seekPos);
    },
    stop() {
      this.playing = false;
      this.audio.currentTime = 0;
    },
    update(e) {
      this.currentSeconds = parseInt(this.audio.currentTime);
    }
  },
  created() {
    this.innerLoop = this.loop;
  },
  mounted() {
    this.audio = this.$el.querySelectorAll("audio")[0];
    this.audio.addEventListener("timeupdate", this.update);
    this.audio.addEventListener("loadeddata", this.load);
    this.audio.addEventListener("pause", () => {
      this.playing = false;
    });
  }
};
</script>

<style lang="scss" scoped>
  //
$player-progress-color: #87d4ec;
$player-seeker-color: #ffffff;
.seek-bar{
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 25px;
  position: relative;
  border-radius: 0.2rem;
  margin-top: 15px;
}
.player {
  width: 330px;
  margin: 0 auto;

  .play-button {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 0;
    left: 7px;
    top: 5px;
    a {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
  .cancel-button{
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 0;
    right: 7px;
    top: 5px;
    a {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
  .player-progress {

    cursor: pointer;
    height: 1.5rem;
    min-width: 200px;
    position: relative;

    display: flex;
    justify-content: space-around;
    .player-seeker {
      background: #FBB03B;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 0;
      top: 0;
      margin-top: 0.2rem;
      margin: 0.3rem;
      border-radius: 2px;
      &.stopped {
        background: #FBB03B;

      }
    }
    .player-background{
      z-index: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      top: 0;
      background: #CCDFE4;
      border-radius: 2px;
      margin-top: 0.2rem;
      margin: 0.3rem;
      width: 97%;
      border-radius: 2px;
    }
    .player-time-current {
      padding-left: 5px;
      border-radius: 2px;
      display: flex;
      flex-direction: row-reverse;
      padding-right: 0.5rem;
      line-height: 1.2;
      color: #ffffff;
    }
  }
  .seek-bar {
  }
}
</style>
