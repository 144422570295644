<template>
    <div class="modal" style="text-align: center; padding: 20px">
      <span class="header-title">{{ $ml.get("כדי להבטיח את אבטחת חשבונך, אנו ממליצים לך לשנות את הסיסמה שלך לחדשה") }}</span>
        <div class="password-input password-inputFirst" style="margin-top: 20px;">
            <v-text-field
            v-model="user.oldPassword"
            :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :type="show ? 'text' : 'password'"
            class="test"
            id="pass"
            name="input-10-1"
            :label="$ml.get('צור סיסמה')"
            @click:append="show = !show"
            autocomplete="new-password"
            @input="oldPassword"
            ></v-text-field>
        </div>
        <div class="password-input password-inputSecond">
        <v-text-field
          v-model="user.newPassword"
          :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :type="show ? 'text' : 'password'"
          class="test"
          id="passSecond"
          name="input-10-1"
          :label="$ml.get('צור סיסמה')"
          @click:append="show = !show"
          autocomplete="new-password"
          @input="validatePassword"
        ></v-text-field>
      </div>
      <div class="alerts-block">
        <div style="width: 100; text-align: start">
          <span class="title">{{ $ml.get("הסיסמה חייבת להכיל")+':' }}</span>
        </div>
        <div style="width: 100; text-align: start">
          <div style="display: inline-block">
            <ul class="hr">
              <li>
                <svg
                  v-if="user.newPassword == ''"
                  style="margin-left: 3px"
                  width="4"
                  height="7"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="white" />
                </svg>

                <svg
                  v-else-if="password.length == false"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="3.16804"
                    y1="2.82805"
                    x2="8.8249"
                    y2="8.4849"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                  <line
                    x1="3.16789"
                    y1="8.48528"
                    x2="8.82475"
                    y2="2.82842"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                </svg>

                <svg
                  v-else-if="password.length == true"
                  style="margin-left: 3px"
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.48468 0.828427L3.82782 6.48528L0.999396 3.65685"
                    stroke="#8CC63F"
                    stroke-width="2"
                  />
                </svg>

                <span style="margin-right: 2px">{{$ml.get('לפחות 8 אותיות וספרות')}}</span>
              </li>
              <li style="padding-right: 5px">
                <svg
                  v-if="user.newPassword == ''"
                  style="margin-left: 3px"
                  width="4"
                  height="7"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="white" />
                </svg>

                <svg
                  v-else-if="password.num == false"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="3.16804"
                    y1="2.82805"
                    x2="8.8249"
                    y2="8.4849"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                  <line
                    x1="3.16789"
                    y1="8.48528"
                    x2="8.82475"
                    y2="2.82842"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                </svg>

                <svg
                  v-else-if="password.num == true"
                  style="margin-left: 3px"
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.48468 0.828427L3.82782 6.48528L0.999396 3.65685"
                    stroke="#8CC63F"
                    stroke-width="2"
                  />
                </svg>

                <span style="margin-right: 2px">{{$ml.get('לפחות ספרה אחת')}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="button">
        <app-btn
          :loading="loading"
          :class="disabled == true ? 'active' : 'disabled'"
          @click="change"
          class="reg-b"
        >
          {{ $ml.get("שנה סיסמא") }}</app-btn
        >
      </div>
    </div>
  </template>
  
  <script>
  import { SubscribeAPI, AuthAPI, UserAPI } from "@/api";
  import CryptoJS from "crypto-js";
import { Base64 } from 'js-base64';
  export default {
    data() {
      return {
        user:{
            oldPassword:"",
            newPassword:"",
        },
        password: {
          length: false,
          nums: false,
          verified: false,
        },
        loading:false
      }
    },
    computed: {
      disabled() {
        if (this.user.oldPassword!="" && this.user.oldPassword!=null && this.user.newPassword!="" && this.user.newPassword!=null && this.password.verified) {
          return true;
        } else {
          return false;
        }
    },
    },
    mounted(){
      $('#pass').focus(function(){
      $('.password-inputSecond').removeClass('focused');
      $('.password-inputFirst').addClass('focused');
    })
    $('#passSecond').focus(function(){
      $('.password-inputFirst').removeClass('focused');
      $('.password-inputSecond').addClass('focused');
    })
    $('#pass').focusout(function(){
      $('.password-inputFirst').removeClass('focused');
    })
    $('#passSecond').focusout(function(){
      $('.password-inputSecond').removeClass('focused');
    })
    },
    methods: {
      encryptObject(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env['VUE_APP_SECURE_KEY']);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

      const objectToEncryptString = JSON.stringify(data);

      const encrypted = CryptoJS.AES.encrypt(objectToEncryptString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 8,
      });

      const encryptedObject = {
        iv: ivBase64,
        value: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
      return encryptedObject;
    },
      oldPassword(){

      },
      validatePassword() {
        this.password.verified = false;
        let lengthCheck = this.user.newPassword.length;
        let numCheck = parseInt(this.user.newPassword.replace(/\D/g, "")) || 0;

        let num = numCheck == 0 ? false : true;
        let length = lengthCheck >= 8 ? true : false;

        this.password.num = num;
        this.password.length = length;

        if (length && num) {
          this.password.verified = true;
        }
      },
      change() {
      this.loadingPassword = true;
      AuthAPI.updatePass(this.encryptObject({ passOld: this.user.oldPassword, passNew: this.user.newPassword }))
        .then((res) => {
          if (res.data.success == true) {
            this.$notify({
            title: this.$ml.get("הַצלָחָה"),
            text: this.$ml.get("הסיסמה עודכנה"),
            type: "success",
            duration: 5000,
          });
            this.$emit('close');
          }
          else
          {
            this.$notify({
            title: this.$ml.get("שְׁגִיאָה"),
            text: this.$ml.get("סיסמה ישנה שגויה"),
            type: "error",
            duration: 5000,
          });
          }
        })
        .catch((err) => {
          this.loadingPassword = false;
          if (err.response.data.message = "Wrong password") {
            this.errorPassUpdate = "סיסמה ישנה שגויה";
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("סיסמה ישנה שגויה"),
              type: "error",
              duration: 5000,
            });
          }
          else if(err.response.data.message = "Password in use")
          {
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("השתמשו בסיסמה זו לאחרונה"),
              type: "error",
              duration: 5000,
            });
          }
          else
          {
            this.$notify({
              title: this.$ml.get("שְׁגִיאָה"),
              text: this.$ml.get("סיסמה ישנה שגויה"),
              type: "error",
              duration: 5000,
            });
          }
        })
        .finally(() => {
          this.loadingPassword = false;
        });
    },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .focused{
  border: 2px solid #fdc671;
  }
  .header-title{
    color: white; 
    font-size: 17px; 
    font-family: 'App';
  }
  .button {
  margin: auto;
  margin-top: 20px;
  text-align: center;

  .reg-b {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 54px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }

  .reg-c {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 48px !important;
    color: black;
    background: #a9ddf3 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
  .reg-d {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 48px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
}
  ul.hr li {
  display: inline; /* Отображать как строчный элемент */
  margin-right: 5px; /* Отступ слева */
  padding: 3px 0px; /* Поля вокруг текста */
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #ffffff;
}
  .alerts-block {
  padding: 0px 17px;
  height: 65px;
  width: 304px;
  border: 1px solid #00465b;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;

  .title {
    padding: 3px 0px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    text-align: right;

    color: #ffffff;
  }
}
  .password-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.test ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
}

.testLtr ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
  left: 0px !important;
  right: auto !important;
}

.test ::v-deep .v-input__slot::before {
  border-style: none;
}

.test ::v-deep .v-input__icon .mdi-check {
  color: #8cc63f;
}

.test ::v-deep .v-input__icon .mdi-close {
  color: #e41e1e;
}
.test ::v-deep .v-input__slot::after {
  border-style: none;
}

.test ::v-deep .v-label {
  margin-top: 2px;
}

.test ::v-deep .v-text-field__slot input {
  padding-top: 12px;
}

.test {
  margin: 0;
}


  
  .modal {
    border-radius: 6px;
    background: #005d79;
    width: 100%;
    height: 100%;
    // padding: 10px;
    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  
    &-close {
      position: absolute;
      right: 5px;
      top: 5px;
      background: #fff;
      box-shadow: rgba($color: #000000, $alpha: 0.2);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      padding: 0;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      svg {
        width: 10px;
      }
    }
  }
  </style>
  