<template>
  <main class="code">
    <div class="title">
      קבלת קוד הטבה?
    </div>
    <span class="error" v-if="error">קוד שגוי</span>
    <app-text-input type="text" label="הקלד קוד שקבלת" v-model="code" />
    <app-btn :loading="loading" @click="applyCode">
      המשך
    </app-btn>
    <app-btn styling="inline" @click="cancelPromo">
      אין לי קוד
    </app-btn>
  </main>
</template>

<script>
export default {
  data() {
    return {
      code: null,
      loading: false,
      error: false,
    }
  },
  methods: {
    
    applyCode() {
      if (this.code) {
        this.loading = true;
        axios.post("/subscribe/promo", { promo: this.code }).then(res => {
          if (res.data.message === 'Promo code is invalid') {
          } else {
            this.$store.dispatch('user/updateData');
            this.redirect();
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
    cancelPromo() {
      this.redirect();
    },
    redirect() {
      this.$router.replace({ name: 'main', params: { attent: true } });
    }
  }
}
</script>

<style lang="scss">

.code {
  display: flex;
  flex-direction: column;
  align-content: stretch;

  background: #56bcdc;
  border-radius: 10px;
  padding: 20px;

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .error {
    align-self: flex-end; 
    font-size: 14px; 
    padding-left: 10px; 
    color: #f00;
  }

  .app-text-input {
    margin-bottom: 20px;
  }

  .app-btn {
    margin-top: 20px;
  }
}

</style>