<template>
  <main dir="rtl">
    <simple-header>
      <span slot="title">דו"ח מעקב</span>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </simple-header>
    <div class="sm-cont">
      <p class="text-new">
        כהורים תוכלו לצפות כאן אלו משחקים שיחקו ילדיכם, ואלו מתנות לחיזוק האופי
        הם אספו באמצעות המשחקים
      </p>
      <table>
        <tr class="header">
          <td>
            צפייה בין תאריכים
          </td>
          <td>
            מ-
            <input v-model="from" @change="sync" type="date" />
          </td>
          <td>
            עד-
            <input v-model="to" @change="sync" type="date" />
          </td>
        </tr>
        <tr class="sub-header">
          <td>משחקים</td>
          <td>מתנות</td>
          <td>אהבתי</td>
        </tr>
        <tr v-for="item in data" class="content">
          <td class="main">
            <p
              @click="
                $router.push({
                  name: 'playlist',
                  params: { id_playlist: item.id_playlist }
                })
              "
            >
              {{ item.name }}
            </p>
            <small>
              <span class="date">{{
                new Date(item.date * 1000) | dateFormat("DD.MM.YYYY")
              }}</span>
              <span class="time">{{
                new Date(item.date * 1000) | dateFormat("HH:mm")
              }}</span>
            </small>
          </td>
          <td class="category">
            <span v-for="(category, i) in item.categories"
              >{{ i != 0 ? "," : "" }} {{ category.name }}</span
            >
          </td>
          <td class="like-wr">
            <button
              class="like"
              :class="{ liked: item.liked }"
              @click="like(item)"
            >
              <i class="icon ion-md-heart"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
export default {
  data() {
    var date = new Date();
    var timestamp = date.getTime() / 1000; // Num of sec to substract a week from it
    var week_ago = new Date((timestamp - 2.62 * Math.pow(10, 6)) * 1000);

    return {
      data: [],
      from: this.$options.filters.dateFormat(week_ago, "YYYY-MM-DD"),
      to: this.$options.filters.dateFormat(date, "YYYY-MM-DD")
    };
  },
  methods: {
    sync() {
      axios
        .post("/parent/get-activity", {
          from: this.from,
          to: this.to
        })
        .then(res => {
          this.data = res.data;
        });
    },
    like(item) {
      axios.put("/playlist/" + item.id_playlist + "/like");
      item.liked = !item.liked;
    }
  },
  created() {
    this.sync();
  },
  components: {
    SimpleHeader,
    SidebarToggler
  }
};
</script>

<style lang="scss" scoped>
main {
  background: #005d7a;
  color: #fff;
  table {
    width: 100%;
    border-spacing: 0;
  }
  .header {
    font-size: 15px;
    td {
      padding-bottom: 10px;
      &:last-child {
        text-align: center;
      }
    }
  }
  .sub-header {
    background: #fff;
    color: #005d7a;
    font-size: 15px;
    td {
      padding: 10px;
      &:last-child {
        text-align: center;
      }
    }
  }
  .like-wr {
    text-align: center;
    .like {
      color: #fff;
      &.liked {
        color: #ff7384;
      }
    }
  }

  .content {
    td {
      border-top: 2px solid #eb5757;
      min-width: 120px;
    }
    .category {
      font-size: 13px;
      p {
        margin: 0;
      }
    }
    .main {
      p {
        margin: 0;
        font-size: 18px;
        text-decoration: underline;
      }
      .time {
        margin-right: 10px;
      }
    }
  }
  input[type="date"] {
    background: transparent;
    border: 0;
    text-decoration: underline;
    -webkit-appearance: none;
    padding: 0;
    color: #24a0c6;
    width: 75px;
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
      visibility: hidden;
    }
  }
  .text-new {
    padding: 0 15px;
  }
}
</style>
