import { render, staticRenderFns } from "./BadgeSelector.vue?vue&type=template&id=5fdeb166&scoped=true&"
import script from "./BadgeSelector.vue?vue&type=script&lang=js&"
export * from "./BadgeSelector.vue?vue&type=script&lang=js&"
import style0 from "./BadgeSelector.vue?vue&type=style&index=0&id=5fdeb166&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdeb166",
  null
  
)

export default component.exports