import { render, staticRenderFns } from "./Restore.vue?vue&type=template&id=026fc732&scoped=true&"
import script from "./Restore.vue?vue&type=script&lang=js&"
export * from "./Restore.vue?vue&type=script&lang=js&"
import style0 from "./Restore.vue?vue&type=style&index=0&id=026fc732&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026fc732",
  null
  
)

export default component.exports