<template>
  <div class="educational-value">
    <img v-if="status" src="@/assets/img/icons/done-e.svg" alt="">
    <img v-else src="@/assets/img/icons/cancel-e.svg" alt="">

    <div class="text">{{value}}</div>
  </div>
</template>
<script>
   export default {
    props:{
      value: {},
      status: {},
    },
    components:{

    },
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>
  //
 .educational-value{
 display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   margin: 5px 0;
   .text{
     font-size: 14px;
     line-height: 19px;
     text-align: right;
     color: #000000;
   }
   img{
    margin-left: 6px;
   }
 }
</style>

