const SessionsStore = {
  namespaced: true,
  state: {
    ip: [],
    data: [],
    loaded: false,
  },
  mutations: {
    saveIp(state,ip) {
      state.ip = ip;
    },
    saveData(state, data) {
      state.data = data;
    },
    load(state) {
      state.loaded = true;
    },
    clear(state){
      // localStorage.removeItem('sessions');
      state.date = [];
    },
    searchSession(state,data){
      let index = '';
      state.data.forEach((item, i)=>{
          if (item.token === data){
            index=i;
          }
      });
      state.data.splice(index, 1);
    },
    setSession(state, data) {
      let row = state.data.find(n => (n.name == data.name && n.surname == data.surname));
      if(!row) {
        state.data.push({
          name: data.name,
          token: data.token,
          phone: data.phone,
          surname: data.surname,
          userStatus: data.userStatus,
          pass: data.pass
        });
      }
      else if(row)
      {
        state.data.forEach(element=>{
          if(element.name == data.name && element.phone == data.phone)
          {
            element.name = data.name;
            element.token = data.token;
            element.phone = data.phone;
            element.surname = data.surname;
            element.userStatus = data.userStatus;
            element.pass = data.pass;
          }
        })
      }
      localStorage.setItem('sessions', JSON.stringify(state.data))
    },
  },
  actions: {
    updateIp(){
      
    },
    removeOldVersionSessions() {
      const data = JSON.parse(localStorage.getItem('sessions'));
      let array = Array();
      try{
      data.forEach((element)=>{
        if(element.phone && element.phone != null && element.phone != "")
        {
          array.push(element)
        }
      })
      localStorage.setItem('sessions', JSON.stringify(array))
    }
    catch(err)
    {
      
    }

    },
    updateData({ commit }) {
      const data = localStorage.getItem('sessions');
      commit('saveData', data ? JSON.parse(data) : []);
      commit('load');
    },
  },
  getters: {
    isUsed: state => name => {
      const row = state.data.find(n => n.name == name);
      if(!row) {
        return false;
      } else {
        return !!row.value;
      }
    },
    data(store){
      return store.data
    },
    isLoaded(state) {
      return state.loaded;
    },
  }
};


export { SessionsStore };
