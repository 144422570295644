import {TeacherAPI } from '@/api';
import store from './../index';

const CounterStore = {
  namespaced: true,
  state: {
    count: null,
    loaded: false,
  },
  mutations: {
    saveData(state, data) {
      state.count = data;
    },
  },
  actions: {
    updateData({ commit },id) {
        TeacherAPI.getAllPlayedGameDetails({id:id})
      .then(res=>{
        commit('saveData',res.data.playlist.playedPlaylist);
      }).catch();
    },
  },
  getters: {
    data(state) {
      return state.count;
    },
  }
};

export { CounterStore }
