import { ShootlistAPI } from '@/api';
import store from "@/store";
const ShootlistCategories = {
  namespaced: true,
  state: {
    data: [],
    dataPromo: [],
    loaded: false,
  },
  mutations: {
    setCategories(state, data) {
      state.data = data;
    },
    setCategoriesPromo(state, data) {
      state.dataPromo = data;
    },
    load(state) {
      state.loaded = true;
    },
  },
  actions: {
    updateData({ commit }) {
      return ShootlistAPI.getCategories().then(res=>{
        commit('setCategories', res.data.categories);
        commit('setCategoriesPromo', res.data.promo);
        commit('load')
      })
    },
  },
  getters: {
    data(state) {
      if(state.dataPromo!=null && state.dataPromo.length>0)
      {
        return state.data.filter(x=>state.dataPromo.includes(x.id));
      }
      else
      {
      return state.data;
      }
    },
    isLoaded(state) {
      return state.loaded;
    },
    isData(state){
      return state.data.length>0;
    },
    getCategoryById: state => id => {
      if(id!=null)
      {
        for(let i =0; i<id.length;i++)
        {
          const categ = state.data.find(n => n.id == id[i]);
          if(categ!=null)
          {
            return categ;
          }
        }
        return null;
      }
      else{
        return null
      }
    },
    getTranslatedNameById: state => id => {
      const categ = state.data.find(n => n.id == id);
      if(categ!=null)
      {
        let name = categ.translation.find(item=>{
          if(store.getters['lang-store/data']=='eng')
          {
            return item.langKey === "en"
          }
          else
          {
          return item.langKey === store.getters['lang-store/data']
          }
        }).data.name;
        return name!=="" ? name : categ.translation[0].data.name;
      }
      else
      {
        return null;
      }
    }
  }
};
export { ShootlistCategories };