<template>
  <div class="modal-default" dir="rtl">
    <i class="main-icon icon ion-md-alert"></i>
    <p class="main-text">
      {{$ml.get('הוצגו כל המשחקים המתאימים להגדרות שלך')}}
    </p>
    <button @click="$emit('close')" class="yes theme-button-outline">
      {{$ml.get('הצג את המשחקים שוב')}}
    </button>
    <button @click="goToOptions" class="nope theme-button-outline">
      {{$ml.get('שנה הגדרות')}}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    toOptions: {
      type: Function
    }
  },
  methods: {
    goToOptions() {
      this.$router.push('/playlist/options');
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>
