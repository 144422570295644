import Vue from 'vue';
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage';
// import store from '@/store';

import English from "./english";
import Hebrew from "./hebrew";
import Russian from './russian';
import Arabic from './arabic';
import Spanish from './spanish';
import Ukrainian from './ukrainian';
import {parsPath, replacePath} from './helpers';

Vue.use(MLInstaller);

var LANG_DEBUG = false;

var lang = new MLCreate({
  middleware: (component, path) => {
    if (!path || path == null || path == undefined)
      return '';

    path = replacePath(path);

    var newPath =path;

    var index = component.$ml.list.indexOf(component.$ml.current);
    var strIndex = component.$ml.db[index][newPath];

    if (!strIndex && !LANG_DEBUG) {
      component.$ml.db[index][newPath] = path;
    }

    return newPath;
  },
  initial: 'heb',
  // save: process.env.NODE_ENV === 'production',
  save: false,
  languages: [
    English,
    Hebrew,
    Russian,
    Arabic,
    // Ukrainian,
    // Spanish
  ]
});


export default lang;
