const GiftsAPI = {
  selectAllWithStats() {
    return config.API.get('/gifts/with-stats')
  },
  educationCategories(){
    return config.API.get('/popular/category');
  },
  matchedGames(){
    return config.API.get('/matched-games/category')
  },
  fromChores(){
    return config.API.get('/from-chores/category')
  },
  sociallSkills(){
    return config.API.get('/sociall-skills/category')
  },
  learningSkills(){
    return config.API.get('/learning-skills/category')
  },
  sevenPage(){
    return config.API.get('/seven-page/category')
  },
  eightPage(){
    return config.API.get('/eight-page/category')
  },
  ninePage(){
    return config.API.get('/nine-page/category')
  },
  tenPage(){
    return config.API.get('/ten-page/category')
  },
  elevenPage(){
    return config.API.get('/eleven-page/category')
  },
  twelvePage(){
    return config.API.get('/twelve-page/category')
  },
  
};

export { GiftsAPI }