import {UserAPI, SubscribeAPI} from "@/api";
import store from "@/store";

export default {
  namespaced: true,
  state: {
   playlistDefaultCount:10,
   playlistDefaultCurrentCount:0,
   shootlistDefaultCount:10,
   shootlistDefaultCurrentCount:0,
  },
  mutations: {
    decreaseGamesCounter(state) {
        if(state.playlistDefaultCurrentCount<10)
        {
        state.playlistDefaultCurrentCount ++;
        let df_pl = Buffer.from(state.playlistDefaultCurrentCount.toString()).toString('base64');
        localStorage.setItem('df_pl',df_pl);
        }
    },
    decreaseShootlistCounter(state,commit){
        if(state.shootlistDefaultCurrentCount<10)
        {
        state.shootlistDefaultCurrentCount ++;
        let df_sl = Buffer.from(state.shootlistDefaultCurrentCount.toString()).toString('base64');
        localStorage.setItem('df_sl',df_sl);
        }
    },
    setDefaultPL(state,data)
    {
        state.playlistDefaultCurrentCount = data;
        let df_pl = Buffer.from(state.playlistDefaultCurrentCount.toString()).toString('base64');
        localStorage.setItem('df_pl',df_pl);
    },
    setDefaultSL(state,data)
    {
        state.shootlistDefaultCurrentCount = data;
        let df_sl = Buffer.from(state.shootlistDefaultCurrentCount.toString()).toString('base64');
        localStorage.setItem('df_sl',df_sl);
    }
  },
  actions: {
    updateData({commit}){
        let playlist = localStorage.getItem('df_pl');
        let shootlist = localStorage.getItem('df_sl');
        //playlists
        if(playlist)
        {
        const decodedBufferPL = Buffer.from(playlist, 'base64');
        const decodedStringPL = decodedBufferPL.toString('utf8');
        let df_pl = parseInt(decodedStringPL, 10);
        df_pl = Math.floor(df_pl);
        commit("setDefaultPL",df_pl);
        }
        //shootlists
        if(shootlist)
        {
        const decodedBufferSL = Buffer.from(shootlist, 'base64');
        const decodedStringSL = decodedBufferSL.toString('utf8');
        let df_sl = parseInt(decodedStringSL, 10);
        df_sl = Math.floor(df_sl);
        commit("setDefaultSL",df_sl)
        }
    }
  },
  getters: {
    getDefaultUserTotalPL(state)
    {
        return state.playlistDefaultCount;
    },
    getDefaultUserCurrentPL(state)
    {
        return state.playlistDefaultCurrentCount;
    },
    getDefaultUserTotalSL(state)
    {
        return state.shootlistDefaultCount;
    },
    getDefaultUserCurrentSL(state)
    {
        return state.shootlistDefaultCurrentCount;
    },
  }
};
