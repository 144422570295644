<template>
<div class="range-slider">
  <div :class="uncative? 'unactive' : ''" class="value">{{value}}</div>
  <VueSlider
    :max="max"
    :min="min"
    class="slider"
    @change="$emit('input', value)"
    :class="uncative? 'unactive' : ''"
    v-model="value" />

</div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
export default {
  name: 'RangeSlider',
  components:{
    VueSlider,
  },
  props:{
    min:{
      default: 0,
    },
    value:{},
    uncative:{},
    max:{},
  },
  data(){
    return{

    }
  }
};
</script>

<style lang="scss" scoped>
.range-slider{

  display: flex;
  justify-content: space-between;
  align-items: center;
  .slider{

    width: 160px!important;

  }
  .value{
    width: 34px;
    margin-left: 20px;
    padding: 2px 8px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #00465B;
    border: 1px solid #F2F2F2;
    border-radius: 2px;
    &.unactive{
      color: #C4C4C4;
    }
  }
}

</style>
