const FunkitStore = {
  namespaced: true,
  state: {
    data: [],
    item: [],
    loaded: false,
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    addItem(state, data) {
      state.item = [data];
    },
    pushItem(state, data) {
      let check = state.data.filter(item=>{
        return item.id_funkit === data.id_funkit
      });
      if (check.length===0){
        data.count=1;
        state.data.push(data);
      }
    },
    changeCount(state,data){
        state.data.forEach((item,i)=>{
          if (item.id_funkit === data.item.id_funkit){

          }
      });
    },
    deleteItem(state, data) {
      state.data = state.data.filter(item=>{
        return item.id_funkit !== data.id_funkit
      });
    },
    load(state) {
      state.loaded = true;
    },
  },
  actions: {

  },
  getters: {
    data(state) {
      return state.data;
    },
    item(state) {
      return state.item;
    },
    isLoaded(state) {
      return state.loaded;
    },

  }
};

export { FunkitStore };
