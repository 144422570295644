<template>
  <main class="teachers-tutorials" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get('שעת חינוך')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading class="sm-cont">
      <template>
        <div
          :style="{direction:direction}"
          class="tutorials-wrapper">
          <div class="header">
            <div class="icon">
              <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 0C5.14 0 2 3.14 2 7C2 9.42374 3.24026 11.5634 5.11719 12.8203C2.15369 14.0889 0 16.7391 0 20H4C4 17.9469 6.06037 16 9 16C11.9396 16 14 17.9469 14 20H18C18 16.7391 15.8463 14.0889 12.8828 12.8203C14.7597 11.5634 16 9.42374 16 7C16 3.14 12.86 0 9 0ZM22 0V4C29.5221 4 35.7991 9.17001 37.5273 16.1328L41.4102 15.168C39.2464 6.45077 31.3579 0 22 0ZM9 4C10.654 4 12 5.346 12 7C12 8.654 10.654 10 9 10C7.346 10 6 8.654 6 7C6 5.346 7.346 4 9 4ZM35 20C31.14 20 28 23.14 28 27C28 29.4237 29.2403 31.5634 31.1172 32.8203C28.1537 34.0889 26 36.7391 26 40H30C30 37.9469 32.0604 36 35 36C37.9396 36 40 37.9469 40 40H44C44 36.7391 41.8463 34.0889 38.8828 32.8203C40.7597 31.5634 42 29.4237 42 27C42 23.14 38.86 20 35 20ZM35 24C36.654 24 38 25.346 38 27C38 28.654 36.654 30 35 30C33.346 30 32 28.654 32 27C32 25.346 33.346 24 35 24ZM6.73047 24.8008L2.91797 26C5.471 34.1194 13.0657 40 22 40V36C14.8183 36 8.76944 31.2853 6.73047 24.8008Z"
                  fill="#00465B"/>
              </svg>
            </div>

            <div  class="title">{{$ml.get('שעת חינוך ממושחקת')}}</div>
            <div class="description">
              <span>{{$ml.get(' חווים ומפנימים את הערכים')}}</span><br/>

              <span>{{$ml.get('באמצעות משחקים ושאלות')}}</span>
            </div>
          </div>

            <div class="tutorial-info" :style="{background: '#45A2BE' }"    >
              <div class="name-element">
                <div class="name">{{$ml.get('המשחקים')}}</div>
              </div>
              <div class="tutorials-list">
                <div class="tutorial-info-element"  >
                  <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">1.{{$ml.get('ללא מפסידים')}}</div>
                  <div class="description">
                    {{$ml.get('מדגישים שהמשחקים הם ללא מפסידים')}}
                    {{$ml.get('ומנצחים - משחקים  עם החבר ולא נגדו')}}.
                    {{$ml.get('מציינים שזהו ערך לחיים בפני עצמו')}}.
                  </div>
                </div>
                <div class="tutorial-info-element"  >
                  <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">2.{{$ml.get('ללא ציוד')}}</div>
                  <div class="description">
                    {{$ml.get('המשחקים פשוטים ואינם דורשים')}}
                    {{$ml.get('ציוד')}}. {{$ml.get('על כן')}},  {{$ml.get('מעודדים את הילדים')}}
                    {{$ml.get('לשחק אותם שוב בבית ובהפסקות')}}.
                  </div>
                </div>
                <div class="tutorial-info-element"  >
                  <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">3.{{$ml.get('סיכום')}}</div>
                  <div class="description">
                    {{$ml.get('לאחר כל משחק מפנימים עם שאלות')}}:
                    {{$ml.get('“מה התחדד אצלכם במשחק')}}?
                    “{{$ml.get('איך הרגשתם במשחק')}}”? {{$ml.get('וכדומה')}}.
                  </div>
                </div>
              </div>
            </div>

          <div class="tutorial-info" :style="{background: '#005D7A' }"    >
            <div class="name-element">
              <div class="name">{{$ml.get('השאלות')}}</div>
            </div>
            <div class="tutorials-list">
              <div class="tutorial-info-element"  >
                <div :style="direction==='ltr'? 'text-align:left' : ''"
                     class="title">1.{{$ml.get('הפנמה')}}</div>
                <div class="description">
                  {{$ml.get('רצוי לרשום על הלוח כל שאלה')}}, {{$ml.get('עם ציון הזמן')}}
                  {{$ml.get('לכתיבת תשובה אישית במחברת ייעודית')}}.
                  {{$ml.get('לאחר מכן אפשר לשמוע את תשובות הילדים')}}.
                </div>
              </div>
              <div class="tutorial-info-element"  >
                <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">2.{{$ml.get('דוגמה אישית')}}</div>
                <div class="description">
                  {{$ml.get('לעיתים המורה תקריא את תשובתה')}}
                  {{$ml.get('האישית כמודל ליחס רציני לנושא')}}.
                  {{$ml.get('המורה תשתתף גם במשחקים')}}.
                </div>
              </div>
              <div class="tutorial-info-element"  >
                <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">3.{{$ml.get('המשך בבית')}}</div>
                <div class="description">
                  {{$ml.get('ניתן להזמין שליחת תשובות במייל למורה')}},
                  {{$ml.get('שתגיב בשיעור הבא באישור הכותבים')}}.
                 {{$ml.get('בנוסף')}},  {{$ml.get('לעודד דיון בשאלות עם ההורים')}}.
                </div>
              </div>
            </div>
          </div>

          <div class="tutorial-info" :style="{background:  '#013646' }" >
            <div class="name-element">
              <div class="name">{{$ml.get('משחוק השאלות')}}</div>
            </div>
            <div class="tutorials-list">
              <div class="tutorial-info-element" >
                <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">1.{{$ml.get('פתקים')}}</div>
                <div class="description">
                  {{$ml.get('שמים בכלי פתקים עם שמות הילדים')}}.
                  {{$ml.get('מי ששמו עלה בפתק מקריא שאלה')}}.
                </div>
              </div>
              <div class="tutorial-info-element" >
                <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">2.{{$ml.get('אאאאלף')}}...</div>
                <div class="description">
                  {{$ml.get('א‘ מריץ בראשו אותיות')}}, {{$ml.get('וב‘ מכריז')}}
                  {{$ml.get('לפתע')}}: “{{$ml.get('סטופ')}}!“ {{$ml.get('ראשונים יענו הילדים')}}
                  {{$ml.get('ששמם מתחיל באות שבה נעצר א‘')}}.
                </div>
              </div>
              <div class="tutorial-info-element" >
                <div :style="direction==='ltr'? 'text-align:left' : ''" class="title">3.{{$ml.get('רביעיות')}}</div>
                <div class="description">
                  {{$ml.get('כל רביעיית ילדים בוחרת אחד')}}
                  {{$ml.get('מתוכם שיקריא את תשובתו בכיתה')}}.
                </div>
              </div>
            </div>
          </div>



          <div class="checkbox">
            <CheckboxWrapper
              v-model="choice"
               :class="[choice.includes('אל תציג מסך זה שוב') ? 'active' : 'unactive' ]"

            >
              <template v-slot="{setValue}">
                <div
                  @click="setValue('אל תציג מסך זה שוב')"
                  class="checkbox-slot">
                   <div class="title">{{$ml.get('אל תציג מסך זה שוב')}}</div>
                </div>
              </template>
            </CheckboxWrapper>
          </div>
          <div class="footer">
            <app-btn
              style="width: 180px;height: 36px;"
              @click="goRoute"
              class="save-b">{{$ml.get('למערכי השיעור')}}</app-btn>
          </div>
        </div>

      </template>
    </app-loading>
  </main>
</template>

<script>

import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import CheckboxWrapper from "@/components/CheckboxWrapper";
export default {
  data() {
    return {
      index: 0,
      popup_showed: false,
      choice: '',
      radio: '',
      loading: {
        next: false,
        sync: false
      },
      arr: [
        {
          color: '#45A2BE',
          title: 'המשחקים  ',
          list: [
            {
              title: '1. ללא מפסידים ',
              description:  'מדגישים שהמשחקים הם ללא מפסידים\n' +
                  'ומנצחים - משחקים  עם החבר ולא נגדו.\n' +
                  'מציינים שזהו ערך לחיים בפני עצמו. \n'
            },
            {
              title: '2. ללא ציוד ',
              description: 'המשחקים פשוטים ואינם דורשים\n' +
                  'ציוד. על כן, מעודדים את הילדים  \n' +
                  'לשחק אותם שוב בבית ובהפסקות.'
            },
            {
              title: '3. סיכום ',
              description:  '\n לאחר כל משחק מפנימים עם שאלות:' +
                  '“מה התחדד אצלכם במשחק?“  \n' +
                   '”איך הרגשתם במשחק?“ וכדומה.'
            },
          ]
        },
        {
          color: '#005D7A',
          title: 'השאלות ',
          list: [
            {
              title: '1. הפנמה ',
              description:  'רצוי לרשום על הלוח כל שאלה, עם ציון הזמן\n' +
                  'לכתיבת תשובה אישית במחברת ייעודית.\n' +
                  ' לאחר מכן אפשר לשמוע את תשובות הילדים.'
            },
            {
              title: '2. דוגמה אישית ',
              description: 'לעיתים המורה תקריא את תשובתה \n' +
                  'האישית כמודל ליחס רציני לנושא.\n' +
                  'המורה תשתתף גם במשחקים.'
            },
            {
              title: '3. המשך בבית  ',
              description:  'ניתן להזמין שליחת תשובות במייל למורה, \n' +
                  'שתגיב בשיעור הבא באישור הכותבים.\n' +
                  'בנוסף, לעודד דיון בשאלות עם ההורים.\n'
            },
          ]
        },
        {
          color: '#013646',
          title: 'משחוק השאלות ',
          list: [
            {
              title: '1. פתקים',
              description:   'שמים בכלי פתקים עם שמות הילדים.\n' +
                  'מי ששמו עלה בפתק מקריא שאלה.  \n' +
                  '\n'

            },
            {
              title: '2. אאאאלף...',
              description:  'א‘ מריץ בראשו אותיות, וב‘ מכריז\n' +
                  'לפתע: “סטופ!“ ראשונים יענו הילדים \n' +
                  'ששמם מתחיל באות שבה נעצר א‘.'
            },
            {
              title: '3. רביעיות ',
              description:  'כל רביעיית ילדים בוחרת אחד\n' +
                  'מתוכם שיקריא את תשובתו בכיתה.\n' +
                  '\n'
            },
          ]
        },
      ]
    };
  },
  components: {

    // GiftLine,
    SimpleHeader,
    SidebarToggler,
    CheckboxWrapper,
  },
  computed:{
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  },
  methods: {
    goRoute(){
      if (this.choice.length>0){
          this.$store.commit('tutorials/setTutorial', 'teacherTutorial');
          this.$store.dispatch('tutorials/updateData');
      }
      this.$router.push({name: 'teacher-educational-classes'});
    }
  },
  created(){
    window.fbq("track", "מורים הסבר");
  }

};
</script>
<style scoped lang="scss">
.teachers-tutorials {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {
    flex-grow: 1;
    min-height: 450px;
    background: #00465B;

    .tutorials-wrapper {
      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 221px;
        padding: 0 10px;
        padding-bottom: 30px;
        background: #FFFFFF;

        .icon {

        }

        .title {
          font-weight: bold;
          font-size: 27px;
          text-align: center;
          color: #00465B;
        }

        .description {
          font-size: 17px;
          text-align: center;
          color: #00465B;
        }
      }

      .tutorial-info {
        position: relative;
        background: #45A2BE;

        .name-element {
          position: relative;
          margin: 0 auto;
          width: 100%;
          height: 10px;
          @media screen and (max-width: 340px) {
            width: 90%;
          }
          .name {
            border-radius: 6px;
            width: 319px;
            height: 60px;
            background: #FF7384;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            position: absolute;
            top: -10px;
            left: 0;
            bottom: 0;
            right: 0;
            font-weight: bold;
            font-size: 24px;
            color: #FFFFFF;
            @media screen and (max-width: 340px) {
              width: 100%;
            }
            &:before {
              content: "";
              position: absolute;
              right: 46%;
              top: 46px;
              width: 18px;
              height: 18px;
              background: #FF7384;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              border-radius: 2px;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }

        .tutorials-list {
          padding-top: 39px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 50px;
          .tutorial-info-element {
            width: 320px;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 6px;
            margin: 10px 0;
            @media screen and (max-width: 340px) {
              width: 90%;
            }
            .title {
              font-weight: 500;
              font-size: 24px;
              text-align: right;
              color: #005D7A;
            }

            .description {
              font-size: 16px;
              color: #005D7A;
              line-height: 22px;
            }
          }
        }

      }
      .footer{
        border-top: 1px solid  #C4C4C4;
        position: fixed;
        bottom: 0;
        z-index: 20;
        height: 70px;
        width: 460px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 460px) {
          width:100%;
        }
      }
      .checkbox{


        background: #013646;
        .checkbox-wrapper {
          margin: 0 auto;
          width: 150px;
          border-radius: 4px;
          font-style: normal;
          font-weight: normal;
          padding-bottom: 100px;
          @media screen and (min-width: 375px){
            font-size: 14px;
          }

          font-weight: normal;
          font-size: 14px;
          text-align: center;
          color: #FFFFFF;
          position: relative;
          .checkbox-slot{
            z-index: 4;
            position: relative;
            width: 100%;
            height: 100%;
            /* top: -3px; */
            /* right: 6px; */
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            .title{
              margin-right: 17px;
            }
          }
          &.active{

            &:after{
              position: absolute;
              content: '';
              z-index: 1;
              border: 1px solid #00465B;
              background: #FFFFFF;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              top: 6px;
              right: 5px;

            }
            &:before{
              position: absolute;
              content: '';
              z-index: 2;
              height: 3px;
              width: 6px;
              background: #FFFFFF;
              border-left: 1px solid #00465B;
              border-bottom: 1px solid #00465B;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 10px;
              right: 8px;
            }
          }
          &.unactive{

            &:after{
              top: 6px;
              right: 5px;
              position: absolute;
              content: '';
              background: #FFFFFF;
              border: 1px solid #00465B;
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>