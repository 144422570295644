import { PlaylistAPI } from '@/api';
import { DefaultPlaylistSettings } from './default-playlist-settings.dto';


const PlaylistStore = {
  namespaced: true,
  state: {
    data: [],
    played: [],
    order_index: 1,
    settings: {
      // viewed_playlist_seen:[],
    },
    viewed_teacher: [],
    count: 1,
    random: false,
    defaultGamePassed: 0,
    isSubed:false,
    dataViewedCategory:[],
    prop:'main',
  },
  mutations: {
    setProp(state,prop)
    {
      state.prop = prop;
    },
    setSubed(state)
    {
      state.isSubed = true;
    },
    setUnSubed(state)
    {
      state.isSubed = false;
    },
    increaseGamePassedCounter(state)
    {
      state.defaultGamePassed ++;
    },
    resetGamePassedCounter(state)
    {
      state.defaultGamePassed = 0;
    },
    addViewedCategory(state,id)
    {
      let array = state.dataViewedCategory;
      array.push(id);
      state.dataViewedCategory = array;
    },
    updateViewedCategory(state,data)
    {
      state.dataViewedCategory = data;
    },
    reset(state) {
      state.data = [];
      state.order_index = 1;
      state.settings = {};
      state.count = 1;
      state.random = false;
    },
    // setSeen(state,data) {
    //   state.settings.viewed_playlist_seen = data;
    // },

    resetGames(state) {
      state.data = [];
      state.played = [];
      state.order_index = 1;
    },
    resetSettings(state) {
      state.settings = [];
    },

    deleteLastCard(state) {
      const _length = state.data.length;
      const item = state.data[_length - 1];
      state.data = state.data.filter((n, i) => i !== (_length - 1));

      state.played.push({
        ...item,
        played: true,
      });
    },

    deleteLastPlayedCard(state) {
      state.played = state.played
        .filter((n, i) => i !== (state.played.length - 1));
    },

    incrementOrderIndex(state) {
      state.order_index++;
    },

    setOrderIndex(state, index) {
      state.order_index = index;
    },
    clearStorage(state){
      localStorage.removeItem('playlists-played');
    },
    setSettings(state, data) {
      state.settings = data;
    },
    setDefaultSeen(state)
    {
      state.settings.seen = [1,2];
    },
    // clearViewedSeen(state)
    // {
    //   state.settins.viewed_playlist_seen = [];
    // },
    setViewed(state, data) {
      if(!state.isSubed)
      {
      let newArray = [];
      data.forEach(element => {
        if(!newArray.includes(element.toString()))
        {
          newArray.push(element.toString())
        }
      });
      state.settings.viewed_playlist = newArray;
      }
      else
      {
        state.settings.viewed_playlist = data;
      }
    },
    // pushViewedSeen(state,data) {
    //   state.settings.viewed_playlist_seen.push(data);
    //   if(!state.isSubed)
    //   {
    //   let array = localStorage.getItem('playlists-played');
    //   if(!array)
    //   {
    //     let newArray = [];
    //     newArray.push(data);
    //     localStorage.setItem('playlists-played',newArray);
    //   }
    //   else
    //   {
    //     let existsArray = localStorage.getItem('playlists-played').split(',');
    //       existsArray.push(data);
    //       localStorage.setItem('playlists-played',existsArray);
    //   }
    // }
    // },
    pushViewed(state, data) {
        state.settings.viewed_playlist.push(data);
        if(!state.isSubed)
        {
            let array = localStorage.getItem('playlists-played');
            if(!array)
            {
              let newArray = [];
              newArray.push(data);
              localStorage.setItem('playlists-played',newArray);
            }
            else
            {
              let existsArray = localStorage.getItem('playlists-played').split(',');
              if(!existsArray.includes(data.toString()))
              {
                existsArray.push(data);
                localStorage.setItem('playlists-played',existsArray);
              }
            }
        } 
    },
    pushViewedT(state, data) {
      state.viewed_teacher.push(data);
    },

    setViewedT(state, data) {
      state.viewed_teacher = data;
    },
    setLifeSettings(state,data){
      state.settings.life_skills = data;
    },
    setRandomMode(state, mode) {
      state.random = mode;
      if(mode==true)
      {
        state.settings.viewed_playlist = [];
        state.settings.seen = [1,2]
      }
    },

    setCardsCount(state, count) {
      state.count = count;
    },

    addCard(state, card) {
      state.data.unshift(card);
    },

    addCardInFront(state, card) {
      state.data.push(card);
    },
    setPrev(state, mode) {
      state.settings.previous_game = mode;
    },

    
  },
  actions: {
    setDefaultSettings({  commit }) {
      const settings = new DefaultPlaylistSettings();
      commit('setSettings', settings);
    },
    throwActivity({ getters, state }, type) {
      return PlaylistAPI.addActivity(getters.currentItem.id_playlist, type, {
        filters: state.settings,
        index: state.order_index,
      }).then(()=>{
        return true;
      }).catch(()=>{
        return false;
      });
    },

    loadState({ state, commit }) {
      return PlaylistAPI.getState(state.settings).then(res => {
        const index = res.data.order_index;
        let data =  res.data!=='' ?  res.data.filter :  {};
        const settings = new DefaultPlaylistSettings();
        state.settings = {...settings,...data};
        if(state.settings.seen==null)
        {
          state.settings.seen = [1,2];
        }
        if(state.settings.life_skills==null)
        {
          state.settings.life_skills=false;
        }
        if(!state.isSubed)
        {
          let array = localStorage.getItem('playlists-played');
          if(array)
          {
            state.settings.viewed_playlist = array.split(',');
            // const counts = {};
  
            // for (let i = 0; i < state.settings.viewed_playlist.length; i++) {
            //   const element = state.settings.viewed_playlist[i];
            //   if (counts[element]) {
            //     counts[element]++;
            //   } else {
            //     counts[element] = 1;
            //   }
            // }
            // const keysWithCountGreaterThanTwo = Object.keys(counts).filter((key) => counts[key] > 1);
            // state.settings.viewed_playlist_seen = keysWithCountGreaterThanTwo;
          }
        }
        else
        {
          localStorage.removeItem('playlists-played');
        }
        // state.settings = res.data.filter;
        if (index && index > 1) {
          commit('setOrderIndex', index);
        } else {
          commit('setOrderIndex', 1);
        }
      })
    },

    returnCard({ state, commit }) {
      const card = state.played[state.played.length - 1];
      if(card) {
        commit('deleteLastPlayedCard');
        commit('addCardInFront', card);
      }
      state.prev =true;
    },
    
    // add new card
    async getRandom({ state, commit, dispatch }, data) {
        if (data.giftsOnly){
          data = {
            ...data,
            viewed_playlist: state.viewed_teacher,
            order_index: state.order_index,
            dataViewedCategory:state.dataViewedCategory,
            previous_id:state.data.length>0?state.data:null,
          };
        } else {
          data = {
            ...data,
            ...state.settings,
            order_index: state.order_index,
            dataViewedCategory:state.dataViewedCategory,
            previous_id:state.data.length>0?state.data:null,
          };
        }

        
      return PlaylistAPI.getRandomGame(data)
        .then(res => {
          commit("decreaseReplyCounter", null, { root: true });
          commit("user/increaseGameCounter", null, { root: true });
          commit('addCard', {
            ...res.data.result,
            order_index: state.order_index,
            _uuid: Math.UUID(),
          });
          commit('updateViewedCategory', res.data.meta.viewed_category_playlists)
          commit('addViewedCategory',res.data.result.id_playlist);
          commit('setRandomMode', res.data.meta.random);
          commit('setCardsCount', res.data.meta.count);
          // commit('setSeen',res.data.meta.viewed_playlist_seen);
          // if(res.data.meta.needSeen == true)
          // {
          //   commit('pushViewedSeen',res.data.result.id_playlist);
          // }
          if (!data.giftsOnly) {
            commit('setViewed', res.data.meta.viewed_playlist);
          } else {
            commit('setViewedT', res.data.meta.viewed_playlist);
          }
          commit('incrementOrderIndex');

          return res.data.result;
        })
        .catch(err => {
          dispatch('loadState');
          if (err.response.status == 402) {

            commit('addCard', { state: "give-money" });
          }
          return Promise.reject(err);
        })
    }

    // remove last
  },
  getters: {
    getDefaultGamePassed(state)
    {
      return state.defaultGamePassed;
    },
    data(state) {
      return state.data;
    },
    orderIndex(state) {
      return state.order_index;
    },
    settings(state) {
      return state.settings;
    },
   isSettings(state){
     return Object.keys(state.settings).length > 1 && state.settings.constructor === Object
   },
    teacherViewed(state){
      return state.viewed_teacher.length>0
    },
    count(state) {
      return state.count;
    },
    random(state) {
      return state.random;
    },
    currentItem(state) {
      return state.data[state.data.length - 1];
    },
    prop(state){
      return state.prop;
    }
  }
};

export { PlaylistStore };
