import store from "@/store";

const DefaultShootlistSettings = function() {
  this.gender = 'boy';
  this.categories = null;
  this.for_type = '1';

  if (store.getters['user/data'].position!==null  && store.getters['user/data'].position!=='director' ){
    this.show_teacher_favourite= true;
    this.show_school_inventors= true;
    this.show_director_favorite = true;
  } else {
    this.show_teacher_favourite= false;
    this.show_school_inventors= false;
    this.show_director_favorite = false;
  }

  this.viewed_shootlist  = [];
  this.names = [ 0, 0, 0, 0].map((n, i) => {
    return null;
  });
};

export { DefaultShootlistSettings }