export default {
  namespaced: true,
  state: {
    opened: false,
    array:null,
  },
  mutations: {
    close(state) {
      state.opened = false;
      state.array = null;
    },
    open(state) {
      state.opened = true;
    },
    saveData(state,data) {
      state.array = data;
    },
    switch(state) {
      state.opened = !state.opened;
    },
  },
  actions: {
    updateData(context) {
      return axios.get("/user/lastLogin").then(res => {
        context.commit("saveData", res.data);
        return res.data;
      });
    },
  },
  getters: {
    isOpened(state) {
      return state.opened = state.opened;
    },
    data(state){
      return state.array;
    }
  }
};