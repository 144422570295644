<template>
  <div class="faq-element">
    <button class="show-b" @click="flag=!flag">
      <span      class="icon">
        <svg :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div class="terms">

        <div
          :class="{widt: direction==='ltr'}"
          :style="!flag ?'color:#AAD9E7' : ''"
          class="term">{{$ml.get('לעומת חודש שעבר')}}
        </div>
        <div
          :class="{widt: direction==='ltr'}"
          :style="!flag ?'color:#AAD9E7' : ''"
          class="term">{{$ml.get('לעומת שנה שעברה')}}
        </div>
      </div>
      <button @click="flag=!flag" >{{$ml.get(' פעילות חופשית')}}
      </button>
      <button style="width: 30px;" @click="swipe">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-0.000453115 4.69235L4.24219 8.93499V0.449707L-0.000453115 4.69235Z" fill="#06364B"/>
        </svg>
      </button>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >


        <ReportStatusElement :title="$ml.get('משחקים בכיף') + ':'" :data="goal.playlist" />
        <ReportStatusElement :title="$ml.get('מדברים בכיף') + ':'" :data="goal.shootlist" />
      </div>
    </transition>
  </div>
</template>
<script>
  import ReportStatusElement from "../ReportStatusElement";
  export default {
    props:{
      report:{
        title:{},
        games: {},
      },
      goal: {},
    },
    methods:{
      swipe(){
        this.$emit('swipe', 'free');
      }
    },
    components:{
      ReportStatusElement,
    },
    computed:{
      direction(){
        return this.$store.getters['lang-store/direction']
      }
    },
    data() {
      return {
        flag: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .widt{
    width: 83px!important;
  }
  .faq-element{


    position: relative;
    margin: 2px 0;



    transition: all .4s ease-in-out;
    color: #828282;
    .question{
      background: #AAD9E7;
      padding: 15px 41px 15px 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #06364B;
      .terms{
        left: 5px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .term{
          transition: color 0.3s ease-in-out;
          margin: 0 10px;
          width: 77px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #00465B;
        }
      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;

        text-align: start;
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: 9px;
      top: 5px;
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      padding: 12px 0 25px 0;
      background: #F2F2F2;
      transition: all 0.4s ease-in-out;

    }
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

