<template>
  <main class="feedback-educational">
    <SimpleHeader
      :inverseColor="true"
      route="/main">
      <template v-slot:title>

        <span>
        {{$ml.get('יצירת משחק')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading class="sm-cont">
      <template>
        <div
          class="feedback-wrapper">
          <div class="header">

            <div :style="{direction:direction}" class="title">{{$ml.get('נשמח לקבל משוב, הערות והצעות לשיפורים')}}</div>
          </div>
          <div class="txt">
            <textarea class="txt-area" v-model="comment" ></textarea>
          </div>

          <app-btn
            @click="send"
            class="feedback-b">{{$ml.get('שלח תגובה')}}</app-btn>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import {TeacherAPI} from "@/api";

  export default {
    data() {
      return {
        index: 0,
        comment:'',
        flag: 'reports',
        loading: {
          next: false,
          sync: false
        },
      };
    },
    computed:{
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    components: {

      // GiftLine,
      SimpleHeader,
      SidebarToggler,
    },

    methods: {
      send(){
        TeacherAPI.comment({
          comment: this.comment,
          type: 'type',
        });
        this.$store.commit('modals/alert/open', {title: 'הודעתך נשלחה בהצלחה', text: 'תודה!'});
        this.$router.push({name: 'teacher-educational-games'});
      }
    }
  };
</script>
<style scoped lang="scss">
  .feedback-educational {

    display: flex;
    flex-direction: column;

    .app-loading {
      flex-grow: 1;
      min-height: 450px;
      background: #AAD9E7;
      .feedback-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .header{
          .title{
            margin: 16px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #000000;
          }

        }

        .txt{
          display: flex;
          flex-grow: 1;
          width: 100%;
          padding: 0 25px;
          .txt-area{
            width: 100%;
            padding: 10px;
            height: 400px;
            background: #FFFFFF;
            border: 1px solid #005D79;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }
        .feedback-b{
          margin: 25px auto 10px auto;
          background: #005D79;
          border-radius: 18px;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
</style>