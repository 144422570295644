<template>
  <div class="gift-question">
    <button class="show-b" @click="flag=!flag">
      <span      class="icon">
        <svg  :style="!flag? '    transform: rotate(0);top: 3px;' : '    transform: rotate(180deg);    '" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 7.65674L7.34315 1.99988L1.68629 7.65674" stroke="white" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div class="checkbox">
        <button @click="$emit('input', !value)" v-if="value" class="icon done">
          <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L4.45847 6.54153L1 3.07305" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </button>
        <button @click="$emit('input', !value)"  v-else class="icon cancel">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1L1 6.99999" stroke="#06364B" stroke-miterlimit="10"/>
            <path d="M1 1L7 6.99999" stroke="#06364B" stroke-miterlimit="10"/>
          </svg>
        </button>
      </div>
      <button @click="flag=!flag" >{{translated.question}}
      </button>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >
        {{translated.text}}
      </div>
    </transition>
  </div>
</template>
<script>

  export default {
    props:{
      question: {},
      value:{},
    },
    components:{

    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.question.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data
        }
      },
    },
    data() {
      return {
        flag: true,
        checkbox: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  //
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .gift-question{
    position: relative;
    margin: 2px 0;

    transition: all .4s ease-in-out;
    color: #828282;
    .question{
      display: flex;
      align-items: center;
      padding: 15px 41px 15px 22px;
      font-size: 16px;
      line-height: 23px;
      text-align: right;
      color: #FFFFFF;
      .checkbox{
        left: 12px;
        position: absolute;
        .icon{
          width: 20px;
          height: 20px;
          border-radius: 15px;
          border: 1px solid #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .done{
          background: #8CC63F;
        }
        .cancel{

        }
      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        width: 88%;
        text-align: start;
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;

      padding: 9px;
      top: 7px;
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      width: 90%;
      padding: 0 40px;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      color: #FFFCFE;
      transition: all 0.4s ease-in-out;
    }
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

