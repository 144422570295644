<template>
  <main id="app_l" class="game-inventors" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
       {{$ml.get("ממציאי משחקים")}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading   :loading="load" class="sm-cont">
      <template>
        <div class="inventors-wrapper">
          <InventorDroplist
            @input="listHeight"
            :questions="true"
            :open="true" id="user"
            :item="user"
            @customFilter="filterDataById" />
          <div id="searchHeader" class="search-page">

            <Search
              :placehold="$ml.get('חיפוש משתמש, בית ספר או עיר')"
              @input="onInput"
              v-model="search" />
            <div
              v-if="search.trim()!==''"
              class="user-search">
              <span>
                {{users.length}}
              </span>

              <span> {{$ml.get("תוצאות")}}</span></div>
          </div>
          <app-loading   :loading="loadd" class="sm-cont">
          <InventorDroplist
            style="border-radius: 10px;"
            class="users"
            :key="i*3"
            :item="item"
            v-for="(item, i) in users" />
          <div id="bottom" class="foot"></div>
          <div  v-if="search.trim()!=='' && users.length===0" class="no-results">

            <span> <span class="gray">{{$ml.get("אין תוצאות ל ")}}</span><span>{{search}}</span></span>

            <span class="gray"> {{$ml.get("נסו לחפש שם אחר")}}</span>

            <span class="gray">{{$ml.get("או חזרו לרשימת ממציאי המשחקים")}}</span>
          </div>
            <app-loading :loading="loadFlag" style="min-height: 30px;" >

            </app-loading>
          </app-loading>
          <AddRequest :playlist="$route.params.type==='playlist'"  class="fixed" />
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>
import CryptoJS from "crypto-js";
import { Base64 } from 'js-base64';
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import InventorDroplist from "@/components/playlist/playlist-requests/InventorDroplist";
import Search from "@/components/playlist/playlist-requests/Search";
import AddRequest from "@/components/playlist/AddRequest";

import throttle from "../../config/throttle";
import {UserAPI} from "@/api";
export default {
  name:'GameInventors',
  data() {
    return {
      index: 0,
      popup_showed: false,
      users: [],
      user: '',
      loadFlag: false,
      paginate: '',
      load: false,
      radio: '',
      inventor:    {name: 'User', playlists: [
          {name: 'Game', total: '22'},
          {name: 'Game2', total: '55'},
          {name: 'Game3', total: '6'},
          {name: 'Game4', total: '7'},
          {name: 'Game5', total: '33'},
        ],
        shootlist: [
          {name: 'sdfdsf dssdf sdfdsfsdf'},
          {name: 'sdfdsf dssdf sdfdsfsdf'},
          {name: 'sdfdsf dssdf sdfdsfsdf'},
          {name: 'sdfdsf dssdf sdfdsfsdf'},
        ]
      },
      height: '',
      lastHeight: '',
      search: '',
      loadd: false,
      loading: {
        next: false,
        sync: false
      },
    };
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    InventorDroplist,
    Search,
    AddRequest,
  },
  computed: {
    throttledSearch: function() {
      let DELAY = 500;
      return throttle(this.searchUsers, DELAY);
    },
    throttledNew: function() {
      this.loadFlag = true;

      let DELAY = 500;
      return throttle(this.infitinyScroll, DELAY);
    }
  },
  created() {
    window.fbq("track", "ממציאי משחקים");
    this.$root.$on('GameInventors', (res) => {
            this.filterDataById(res)
        })
  },
  methods: {
    decryptObject(res){
      var key = process.env["VUE_APP_SECURE_KEY"];
      var encrypted = res.payload;
      var encrypted_json  = JSON.parse(Base64.decode(encrypted));
      var decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(Base64.encode(key)),{
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
                        mode: CryptoJS.mode.CBC
                      });
      let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      console.log(data)
      return data;
    },
    filterDataById(search)
    {
      this.search = search;
      this.searchUsers();
    },
    onInput(){
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => { this.searchUsers() },500);
    },
    searchUsers(){
        this.loadd = true;
        UserAPI.getAuthors({page:1, take: 8, search: this.search}).then((result)=>  {
          let res = this.decryptObject(result.data);
          this.users = res.users;
          this.paginate =res.paginate;
          this.loadd = false;

        });
    },
    infitinyScroll(){
      this.loadFlag = true;

      UserAPI.getAuthors({page: (this.paginate.page+1) , take: 8, search: this.search}).then((result)=>  {
        let res = this.decryptObject(result.data);
        let users = this.users;
        this.users = users.concat(res.users);
        this.paginate =res.paginate;
        this.loadFlag = false;
      });
    },
    listHeight(){
      let t = this;
      window.addEventListener('scroll',()=> {
        if (this.$router.currentRoute.name==='requests-playlist'){
        myFunction();
        this.infinity()
      }});
      let header = document.getElementById("searchHeader");
      t.lastHeight = t.height!==''? t.height : 114;
      let sticky = header.offsetTop;
      t.height = sticky;
      function myFunction() {

        if (window.pageYOffset > t.lastHeight+40 ) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    },
    infinity(){
      var clientHeight = document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight;
      var documentHeight = document.documentElement.scrollHeight ? document.documentElement.scrollHeight : document.body.scrollHeight;
      var scrollTop = window.pageYOffset ? window.pageYOffset : (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
      if((documentHeight - clientHeight) <= scrollTop){
        if (this.paginate.page >= this.paginate.page+1){
          this.throttledNew();
        }
      }

    }
  },
  mounted() {
    this.load=true;
    UserAPI.getAuthors({page:1, take: 8}).then((result)=>{
      let res = this.decryptObject(result.data);
      this.load=false;
      this.users = res.users;
      this.user = res.this;
      this.paginate =res.paginate;
      let elem = document.body;
      let bottom = document.getElementById('bottom');



      setTimeout(()=>{

        window.addEventListener('scroll', ()=>{
          if (this.$router.currentRoute.name==='requests-playlist'){
            myFunction();
            this.infinity(elem)
          }
      });

        let header = document.getElementById("searchHeader");

        let sticky = header.offsetTop;
        this.lastHeight = sticky;
        let t = this;
        function myFunction() {
          if (window.pageYOffset > sticky+40 ) {
            header.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
          }

        }

      })
    },300);

  },
  destroyed() {

  },
};
</script>

<style lang="scss" scoped>

.game-inventors {
  background: white;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;


  .app-loading {
    background: #F2F2F2;
    margin-bottom: 40px;
    flex-grow: 1;
    min-height: 450px;
    .inventors-wrapper{
      .no-results{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span{
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #00465B;
        }
        .gray{

          color: #4D4D4D;
        }
      }
      padding-bottom: 76px;
      .users{
        margin: 10px;
      }
      .fixed{
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .search-page{
        background: #F2F2F2;
        margin: 20px 0 20px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .user-search{
          margin-top: 10px;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #4D4D4D;
        }
        &.sticky {
          height: 70px;
          position: fixed;
          top: 0;
          z-index: 20;
          margin: 0;
          width: 100%;
          max-width: 460px;
          padding: 0 !important;

        }

      }
    }
  }
}
</style>
