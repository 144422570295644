<template>

  <div class="like-btn-w" style="width:78px"> 
    <!-- <LikePopup v-if="directorFlag" :item="item" @liked="setted" />
    <button v-if="dots.sixth && muted" style="background: #00465B !important;position: absolute; z-index:30" class="like-button">
      <div v-if="dots.sixth && muted" class="help-popup-sixth fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 40px;">{{$ml.get("הוספת המשחק למועדפים")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons" style="margin-left: 60px !important;">
              <div class="dots" :class="!dots.sevens?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>
      <img style="z-index: 12; margin-left: 1.05px;" v-if="item.liked" width="22" height="20" src="./liked.png"/>
      <img style="z-index: 12; margin-left: 1.05px;" v-else width="22" height="20" src="./non-liked.png"/>
    </button>
    <button v-if="user.position==='director'" @click=" liked" class="like-button">
      <img style="margin-left: 1.05px;" v-if="item.liked" width="22" height="20" src="./liked.png"/>
      <img style="margin-left: 1.05px;" v-else width="22" height="20" src="./non-liked.png"/>
    </button>
    <button v-else @click="$emit('click')" class="like-button">
      <img style="margin-left: 1.05px;" v-if="item.liked" width="22" height="20" src="./liked.png"/>
      <img style="margin-left: 1.05px;" v-else width="22" height="20" src="./non-liked.png"/>
    </button>-->
    <button v-if="dots.sixth && muted" style="background: #00465B !important;position: absolute; z-index:30" class="like-button">
      <div v-if="dots.sixth && muted" class="help-popup-sixth fade-scale">
        <div class="info-wrapper ">
          <div class="info-element border">
            <span style="margin-top: 40px;">{{$ml.get("הוספת המשחק למועדפים")}}</span>
          </div>
          <div style="height: 100%; width:100%; direction: ltr !important; text-align: left;">
            <div class="controll-info">
              <button @click="handleChange" class="button-info">הבא</button>
            </div>
            <div class="buttons" style="margin-left: 60px !important;">
              <div class="dots" :class="!dots.sevens?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.fifth?'dots-simple':'dots-active'"></div>
              <div class="dots" :class="!dots.sixth?'dots-simple':'dots-active'"></div>
            </div>
          </div>
        </div>
      </div>
      <app-btn class="likeBtn" >
            <div style="width: 100%; position: relative;" >
              <span style="margin-right: 0px;">{{ $ml.get("שמור") }}</span>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-if="item.liked" width="16" height="14" src="./liked.png"/>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-else width="16" height="14" src="./non-liked.png"/>
            </div>
    </app-btn>
    </button>
    <app-btn class="likeBtn" v-if="user.position==='director'" @click=" liked">
            <div style="width: 100%; position: relative;" >
              <span style="margin-right: 0px;">{{ $ml.get("שמור") }}</span>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-if="item.liked" width="16" height="14" src="./liked.png"/>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-else width="16" height="14" src="./non-liked.png"/>
            </div>
    </app-btn>
    <app-btn v-else class="likeBtn" @click="$emit('click')">
            <div style="width: 100%; position: relative;" >
              <span style="margin-right: 0px;">{{ $ml.get("שמור") }}</span>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-if="item.liked" width="16" height="14" src="./liked.png"/>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-else width="16" height="14" src="./non-liked.png"/>
            </div>
    </app-btn>
    
  </div>

</template>

<script>
import LikePopup from "./LikePopup";
export default {
  props: {
    item: {},
    dots:null,
    muted: false,
  },
  data(){
    return{
      directorFlag: false,
      // dots:{
      //       first:false,
      //       second:false,
      //       third:false,
      //       fourh:false,
      //       fifth:false,
      //       sixth:false
      //   }
    }
  },
  computed:{
    user(){
      return this.$store.getters['user/data'];
    }
  },
  methods:{
    handleChange (event) {
       this.$emit("customChange")
    },
    setted(){
      this.directorFlag = false;
    },
    liked(){
      if (!this.item.liked) {
        axios.put(
            "/playlist/" + this.item.id_playlist + "/activity/lik"
        );
        this.directorFlag =true;
      }
      this.item.liked = !this.item.liked;
      axios.put("/playlist/" + this.item.id_playlist + "/like");

    }
  },
  components:{
    LikePopup
  },
}
</script>

<style lang="scss" scoped>
.like-button{
  border-radius: 20px !important;
}
.likeBtn{
  background: #FBB03B !important;
  width: 78px;
  height: 40px;
  border-radius: 20px;
  div span{
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    margin-right: -5px !important;
    color: #06364B;
  }
}
.dots{
    display: inline-block;
    box-sizing: content-box;
    width: 6px !important; 
    height: 6px !important;
    border-radius: 50%;
    margin: 6px 3px;
}
.dots-simple{
    background-color: #D9D9D9;
    cursor: pointer;
}
.dots-active{
  width: 8px !important;
    height: 8px !important;
    background-color: #3FBCD4;
    cursor: pointer;
    margin: 5px 3px !important;
}
.buttons{
  display: inline-block;
  margin-left: 9px;
}
.controll-info{
  display: inline-block;
  text-align: left;
  .button-info{
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 0px !important;
    width: 66px;
    height: 26px;
    background: #3FBCD4;
    border-radius: 2px;
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
  }
}
// .wrapper-popup{
//   position: fixed;
//   z-index: 9;
//   background: rgba(0, 0, 0, 0.25);
//   width: 100%;
//   height: 100vh;
//   right: 0;
//   top: 0;
// }
.help-popup-first {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -91px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-second {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-third {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-fourth {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 48px;
    left: -89px;
    width: 208px !important;
    height: 110px !important;
    border-radius: 10px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 45%;
    top: 101px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      max-width:150px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

.help-popup-sixth {
  pointer-events: all;
  position: absolute;
    z-index: 10;
    bottom: 55px;
    left: -111px;
    width: 300px !important;
    height: 160px !important;
    border-radius: 6px;
    background: #FFFFFF;
    // border: 2px solid #3FBCD4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    right: 47%;
    top: 150px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    padding-top: 10px;
    padding-bottom: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      width:100%;
      margin: auto;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      text-align: center;

      color: #00465B;

      color: #00465B !important;
     }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      // border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}
//
// .like-button {
//   width: 35px;
//   height: 35px;
//   border: 1px solid #fff;
//   border-radius: 100%;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     stroke: 1px #fff;
//   }
// }

</style>