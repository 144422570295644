import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    rtl: true,
    chat: {
      component: require('@/assets/img/visible.svg')
    }
}

export default new Vuetify(opts)