<template>
 <div class="game-activity-element">
   <div class="activity-wrapper" @click="goGame">
      <div class="image">
        <div v-if="game.details.isLike" class="favourite">
          <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9456 0.847595C6.5903 0.501715 6.10853 0.307405 5.60619 0.307405C5.10385 0.307405 4.62207 0.501715 4.26677 0.847595L3.82096 1.2818L3.37516 0.847595C3.20072 0.669987 2.99161 0.528107 2.76011 0.430284C2.5286 0.332462 2.27937 0.280672 2.02703 0.277946C1.7747 0.275219 1.52434 0.32161 1.29067 0.414407C1.057 0.507204 0.844713 0.644529 0.666274 0.818325C0.487834 0.99212 0.346834 1.19888 0.251558 1.42647C0.156282 1.65407 0.108646 1.89791 0.111445 2.14368C0.114244 2.38945 0.167423 2.63219 0.26786 2.85767C0.368296 3.08315 0.513966 3.28681 0.69632 3.45671L1.14213 3.89092L3.82096 6.50005L6.49979 3.89092L6.9456 3.45671C7.30072 3.11066 7.50022 2.64142 7.50022 2.15215C7.50022 1.66288 7.30072 1.19365 6.9456 0.847595Z" fill="#005D79"/>
          </svg>
        </div>
        <img :src="game.img" alt="">
      </div>
     <div class="info">
       <div class="name">{{translated ? translated : game.translation[0].data.name }}</div>
       <div class="date">
         <div class="d">{{data}}</div>

       </div>
     </div>
   </div>
   <div class="categories-wrapper">
     <div :key="i*2" v-for="(category, i) in gifts" class="category">
       <div class="round"
            :style="{background:$store.getters['playlist-sections/getColorById'](category.id_section)}"></div>
        <span>{{$store.getters['playlist-gifts/getTranslatedById'](category.id_category)}}</span>
     </div>
   </div>
 </div>
</template>

<script>
 export default {
  name: "GameActivityElement",
   props:{
    index:null,
    game:{
      src:{},
      categories: {},
      name: {},
      data: {},
      time: {},
      favourite: {},
      id_playlist:{},
    },
  },
  methods:{
    goGame(){
       this.$router.push({name: 'targeted-playlist', params: { id_playlist: this.game.id_playlist}});
    }
  },
  computed:{
    gifts(){
      if(this.game.details.gifts)
      {
        return this.game.details.gifts.sort((a,b)=>a.id_section<b.id_section?1:-1);
      }
      else
      {

      }
    },
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.game.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data.name
      }
    },
    data(){

      var date = new Date( this.game.details.played_date ? this.game.details.played_date * 1000 : this.game.date  * 1000 );

// извлекаем часы
      var hours = date.getHours()>9 ? date.getHours() : '0'+date.getHours();
// минуты
      var minutes = date.getMinutes()>9? date.getMinutes() : '0'+date.getMinutes();
// секунды


      var days = date.getDate() > 9 ?  date.getDate() : '0'+date.getDate();
      var month = date.getMonth()+1 > 9 ? date.getMonth()+1: '0'+(date.getMonth()+1) ;
      var year = date.getFullYear();
      var time = hours + ':' + minutes ;
      var data = days + ':' + month + ':' + year;
      return data+ ' ' + time;
// показываем в нужном формате: 11:17:23
    }
  },
}
</script>

<style scoped lang="scss">
  //
.game-activity-element {
  margin: 5px 0;
  background: white;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 3px;
  border-radius: 10px;
.activity-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  .info{
    .name{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #005D79;
    }
    .date{
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .d{
        margin: 0 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: #4D4D4D;
      }
      .t{

      }
    }
  }
 .image{
   position: relative;
   img{
     margin: 10px 10px 10px 15px;
     width: 45px;
     height: 45px;
   }
   .favourite{
     position: absolute;
     left: 17px;
     border-radius: 50%;
     width: 14px;
     height: 14px;
     display: flex;
     align-items: center;
     justify-content: center;
     background: #FFFFFF;
     border: 0.35px solid #005D79;
     svg{
       margin-top: 1px;
     }
   }
 }
}
.categories-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 170px;
  @media screen and (max-width: 375px){
    width: 120px;
  }
  .category{
    height: 14px;
    margin: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #000000;
    }
    .round{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-left: 3px;
    }
  }
}

}
</style>