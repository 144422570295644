<template>
  <main style="background:#005d79 !important" class="gift">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{nameTranslated ? nameTranslated : data.details.translation[0].data.name}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
      :loading="loading.sync"
      class="sm-cont">
      <template v-if="!loading.sync">
        <div class="gift-element">
          <div :style="{background: $store.getters['games-class-sections/getColorById'](data.id_type)}" class="header">
            <div class="stats-block">

              <div class="name">{{$ml.get('יעד חודשי')}}</div>
              <div class="numbers">12/23</div>
              <div class="status-bar">
                <div class="bg"></div>
              </div>
            </div>
            <div class="img">
              <img :src="data.details.img_url" alt="">
            </div>
            <div class="title-m">{{nameTranslated ? nameTranslated : data.details.translation[0].data.name}}</div>
            <div class="gift-info">
              {{secTranslated ? secTranslated : data.translation[0].data.sec_1 }}
            </div>
          </div>
          <div class="games-wrapper">
            <div class="games-header">

              <div :style="{direction:direction}" class="title">  {{$ml.get('משחקים')}}:</div>
              <div class="icons-info">
                <div class="icon-element">
                  <span >{{$ml.get('מתאים גם לבית')}}</span>
                  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 0L5.68565 0.297663L0 5.9262L0.628702 6.54859L1.18907 5.99385V11H5.12528V6.67036H6.87472V11H10.8109V5.99385L11.3713 6.54859L12 5.9262L6.31435 0.297663L6 0ZM6 1.23124L9.93622 5.12792V10.1341H7.74943V5.80443H4.25057V10.1341H2.06378V5.12792L6 1.23124Z" fill="#06364B"/>
                  </svg>

                </div>

                <div class="icon-element">

                  <span>{{$ml.get('מתאים גם לזום')}}</span>
                  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.25 3.09375C3.25 2.904 3.404 2.75 3.59375 2.75H5.65625C5.846 2.75 6 2.904 6 3.09375V4.46875C6 4.6585 5.846 4.8125 5.65625 4.8125H3.59375C3.404 4.8125 3.25 4.6585 3.25 4.46875V3.09375Z" fill="#06364B"/>
                    <path d="M9.15273 3.15273C9.41059 2.89487 9.76033 2.75 10.125 2.75C10.4897 2.75 10.8394 2.89487 11.0973 3.15273C11.3551 3.41059 11.5 3.76033 11.5 4.125C11.5 4.48967 11.3551 4.83941 11.0973 5.09727C10.8394 5.35513 10.4897 5.5 10.125 5.5C9.76033 5.5 9.41059 5.35513 9.15273 5.09727C8.89487 4.83941 8.75 4.48967 8.75 4.125C8.75 3.76033 8.89487 3.41059 9.15273 3.15273Z" fill="#06364B"/>
                    <path d="M3.25 6.53125C3.25 6.3415 3.404 6.1875 3.59375 6.1875H5.65625C5.846 6.1875 6 6.3415 6 6.53125V7.90625C6 8.096 5.846 8.25 5.65625 8.25H3.59375C3.404 8.25 3.25 8.096 3.25 7.90625V6.53125Z" fill="#06364B"/>
                    <path d="M7.375 7.71155C7.375 6.8123 8.62281 6.1875 10.125 6.1875C11.6272 6.1875 12.875 6.8123 12.875 7.71155V7.90625C12.875 8.096 12.721 8.25 12.5312 8.25H7.71875C7.529 8.25 7.375 8.096 7.375 7.90625V7.71155Z" fill="#06364B"/>
                    <rect x="1" y="0.5" width="13.6667" height="10" rx="1.5" stroke="#06364B"/>
                  </svg>

                </div>
                <div class="icon-element">
                  <span>{{$ml.get('מתאים גם להפסקה')}}</span>
                  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.78565 0.966797C2.67655 0.966797 0.966797 2.67655 0.966797 4.78565C0.966797 6.89474 2.67655 8.60449 4.78565 8.60449C6.89474 8.60449 8.60449 6.89474 8.60449 4.78565C8.60449 2.67655 6.89474 0.966797 4.78565 0.966797ZM0 4.78565C0 2.14261 2.14261 0 4.78565 0C7.42868 0 9.57129 2.14261 9.57129 4.78565C9.57129 7.42868 7.42868 9.57129 4.78565 9.57129C2.14261 9.57129 0 7.42868 0 4.78565Z" fill="#06364B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 4.78027C11.267 4.78027 11.4834 4.9967 11.4834 5.26367C11.4834 8.17079 9.12685 10.5273 6.21973 10.5273C5.95275 10.5273 5.73633 10.3109 5.73633 10.0439C5.73633 9.77697 5.95275 9.56055 6.21973 9.56055C8.5929 9.56055 10.5166 7.63684 10.5166 5.26367C10.5166 4.9967 10.733 4.78027 11 4.78027Z" fill="#06364B"/>
                    <path d="M4.78613 5.7417C5.31415 5.7417 5.74219 5.31366 5.74219 4.78564C5.74219 4.25763 5.31415 3.82959 4.78613 3.82959C4.25812 3.82959 3.83008 4.25763 3.83008 4.78564C3.83008 5.31366 4.25812 5.7417 4.78613 5.7417Z" fill="#06364B"/>
                    <path d="M4.78516 9.08789C2.87305 9.08789 0.960938 7.65381 0.960938 7.65381L1.32185 10.1792C1.38877 10.6501 1.79223 11 2.26834 11H7.30245C7.77809 11 8.18154 10.6501 8.24894 10.1792L8.60938 7.65381C8.60938 7.65381 6.69727 9.08789 4.78516 9.08789Z" fill="#06364B"/>
                  </svg>


                </div>
              </div>
            </div>
            <div   class="games">
              <GameElement  :key="i*2" v-for="(game, i) in data.stats.playlists" :gifts="[data.id_gift]"  :game="game" />
            </div>
<!--            <div style="padding: 10px 10px" class="empty">-->
<!--              No available gifts-->
<!--            </div>-->
          </div>
          <div class="feedback">
            <div class="icon">
              <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.62286 0.00127089C6.87472 0.0202695 6.22824 0.251902 5.76107 0.719068C5.63091 0.849229 5.5382 1.00472 5.47694 1.17891C4.9429 2.70351 3.6836 3.96281 2.159 4.49685C1.98481 4.5581 1.82932 4.65082 1.69916 4.78098C0.204228 6.27591 1.11353 9.60873 3.72918 12.2244L3.78152 12.2767L5.13487 13.6301L2.58145 16.1835C1.69234 17.0726 1.69234 18.5142 2.58145 19.4042L2.58332 19.4061C3.02836 19.8511 3.61178 20.0734 4.19463 20.0734C4.77748 20.0734 5.35903 19.8511 5.80407 19.4061L20.3881 4.8221C21.2772 3.93203 21.2772 2.49047 20.3881 1.60136L20.3862 1.59949C19.9412 1.15446 19.3577 0.932164 18.7749 0.932164C18.192 0.932164 17.6105 1.15446 17.1655 1.59949L14.6102 4.15478L8.54254 10.2224C8.16833 10.5966 7.5634 10.5966 7.18919 10.2224C6.81498 9.84819 6.81498 9.24327 7.18919 8.86906L13.2568 2.80143L13.2045 2.74909C11.4056 0.950169 9.26877 -0.0405264 7.62286 0.00127089ZM20.0983 12.4169C18.0677 12.4169 16.1202 13.2236 14.6844 14.6595C13.2485 16.0953 12.4418 18.0428 12.4418 20.0734C12.4418 22.1041 13.2485 24.0515 14.6844 25.4874C16.1202 26.9233 18.0677 27.7299 20.0983 27.7299C22.129 27.7299 24.0764 26.9233 25.5123 25.4874C26.9482 24.0515 27.7548 22.1041 27.7548 20.0734C27.7548 18.0428 26.9482 16.0953 25.5123 14.6595C24.0764 13.2236 22.129 12.4169 20.0983 12.4169ZM0.121502 18.75C0.0497224 19.0208 0 19.3011 0 19.5949V31.5582C0 34.7299 2.57067 37.3006 5.74238 37.3006H11.4848V44H31.5831V41.1288C31.5831 35.3147 26.8696 30.6011 21.0554 30.6011H6.69944V21.137C5.97877 21.6797 5.11437 21.9876 4.19463 21.9876C3.11028 21.9876 2.08015 21.5752 1.29727 20.8267L1.22811 20.7594C0.668225 20.1996 0.297602 19.5042 0.121502 18.75Z" fill="white"/>
              </svg>
            </div>

            <div class="text">
              {{$ml.get('בעזרתכם על ידי שליחת תגובות ורעיונות מהשטח')}}
              {{$ml.get('היחידות של שעת חינוך והוראה ממושחקת ישתכללו')}}
            </div>
            <app-btn
              style="min-width: 180px;height: 36px;margin-bottom: 23px"
              @click="feedback"
              class="save-b">{{$ml.get('שלח תגובה')}}</app-btn>
          </div>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GameElement from "@/components/teacher/GameElement.vue";
  import {TeacherAPI} from "@/api";

  export default {
    name: 'GamesGift',
    data() {
      return {
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        data:[],

      }
    },
    components: {
      SimpleHeader,
      SidebarToggler,
      GameElement,
    },
    computed:{
      playlists(){
        return this.data.stats.playlists.map(item=>{
          return item.id_playlist;
        })
      },
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
      secTranslated(){
        if (this.$store.getters['lang-store/data']){
          return this.data.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.sec_1
        }
      },
      nameTranslated(){
        if (this.$store.getters['lang-store/data']){
          return this.data.details.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.name
        }
      },
    },

    methods: {
      feedback(){
        this.$router.push({name: 'teacher-feedback-games'});
      }
    },
    created() {
      this.loading.sync = true;
      TeacherAPI.gamesByID(this.$route.params.id).then(res=>{
        this.data = res.data;
        this.loading.sync = false;
      })

    },
  };
</script>
<style scoped lang="scss">

.gift {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;
    background: #00465B;

    .gift-element{
  
      .header{
        position: relative;
        padding: 0 10px 30px 10px;
        background: #FF7384;
        display: flex;
        flex-direction: column;
        align-items: center;
        .stats-block{
          position: absolute;
          top: 10px;
          left: 37px;
          .name{
            font-size: 12px;
            text-align: center;
            color: #FFFFFF;
          }
          .numbers{
            font-size: 12px;
            text-align: center;
            color: #FFFFFF;
          }
          .status-bar{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 64px;
            height: 8px;
            background: #C4C4C4;
            border-radius: 4px;
            .bg{
              background: rgb(140, 198, 63);
              width: 50%;
              height: 100%;
              border-radius: 4px 0px 0px 5px;
            }
          }
        }
        .img{
            margin-top: 16px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 50%;
          img{
            padding: 5px;
            max-width: 50px;
          }
        }
        .title-m{
          margin: 5px 0;
          font-weight: bold;
          font-size: 27px;
          text-align: center;
          color: #FFFFFF;
        }
          .gift-info{
            font-weight: normal;
            font-size: 12px;
            text-align: center;
            padding: 0 20px;
            color: #FFFFFF;
          }
      }
      .games-wrapper{
        padding: 0 10px;
        background: #669EAF;
        .games-header{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          padding: 16px 20px 0 20px;
          .icons-info{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .icon-element{
              display: flex;
              align-items: center;
              svg{
               margin-right: 10px;
                width: 17px;
              }
              span{
                font-size: 12px;
                line-height: 18px;
                color: #06364B;
                margin-right: 5px;
              }
            }
          }
          .title{
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #FFFFFF;
          }
        }
        .games{
          padding: 10px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 auto;

          .game-element{
            margin: 0 16px;
            @media screen and (max-width: 420px){
              margin: 0;
            }
            .icon-wrapper{
              @media screen and (max-width: 330px){
                width: 83px!important;
              }
            }

          }
        }
      }
      .feedback{
        padding: 0 10px;
        background: #00465B;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .icon{
          padding: 24px 0 17px;
        }
        .text{
          width: 300px;
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
          padding-bottom: 22px;
        }
      }
    }

  }
}
</style>