<template>
  <div class="goal-element">
      <div class="title">{{title}}</div>
      <div class="info">
        <div class="arrs">


          <span class="name"><span>{{$ml.get('שאלות שנשאלו בכיתה')}}:</span><span>{{shootlists.length}}</span></span>
          <span class="name"><span>{{$ml.get('שאלות שנשאלו בבית')}}:</span><span>{{total}}</span></span>

        </div>
      </div>
  </div>
</template>
<script>

  export default {
    props:{
      title:{},
      total: {},
      goal:{
        name: {},
        arr: {},
      },
      shootlists: {},
    },
    components:{

    },
    data() {
      return {
        arr: [
          {name: ' השגריר', link: 'google.com'},
          {name: ' גולף עפרון', link: 'google.com'}
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
 .goal-element{
   padding: 10px 20px 10px 20px;
  .title{
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: #06364B;
  }
   .info{
     margin-top: 10px;
     display: flex;
     align-items: center;
     .name{
       margin-left: 7px;
       font-weight: normal;
       font-size: 14px;
       text-align: right;
       color: #4D4D4D;
     }
     .arrs{


       display: flex;
       flex-direction: column;
       align-items: center;
       flex-wrap: wrap;
        a{
          font-weight: normal;
          font-size: 14px;
          color: #3FBCD4;
        }
     }
   }
 }
</style>

