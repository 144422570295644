<template>
  <header class="simple-header" dir="ltr" :class="[{ inverse: inverse}, {inverseColor: inverseColor} ]">
    <div class="wrapper" :class="{ 'sm-cont': !$env.CORDOVA }">
      <button v-if="!button" class="back" @click="back">
        <i class="icon ion-md-arrow-back"></i>
      </button>
      <button class="custom-back"  dir="rtl" v-else @click="$emit('back')">
        {{ button }}
      </button>
      <slot dir="rtl" name="title"></slot>
      <slot name="additional"></slot>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    route: {},
    inverse: {
      default: false,
      type: Boolean
    },
    inverseColor: {
      default: false,
    },
    button: {}
  },
  methods: {
    back() {
      this.$store.commit("animation/back");
      this.$router.go(-1);
      // if (this.route) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.go(-1);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>

.simple-header {
  padding: 10px 10px;
  text-align: center;
  color: #fff;
  height: 50px;
  position: relative;
  background: #06364B;
  z-index: 3;
  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &.inverse {
    color: #005d7a;
    .back,
    .custom-back {

      color: #005d7a;
      i{
        &:before{
          color: #000000;
        }
      }
    }
  }
  .back,
  .custom-back {
    transform: rotate(180deg);
    width: 30px;
    height: 30px;
    position: absolute;
    font-size: 20px;
    right: 0;
    top: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

  }
  .custom-back {
    font-size: 16px;
    width: auto;
  }
}
  .inverseColor{
    color: #000000;
    height: 50px;
    position: relative;
    background: white;
    .back,
    .custom-back {
      transform: rotate(180deg);
      width: 30px;
      height: 30px;
      position: absolute;
      font-size: 20px;
      right: 0;
      top: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      i{
        &:before{
          color: #000000;
          font-weight: bold;
          /*font-size: 25px;*/
        }
      }
    }
  }
</style>
