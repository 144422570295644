<template>
  <div class="add-your-game">
    <div class="top-block">
      <div class="button-container">
        <svg width="35" height="41" viewBox="0 0 35 41" fill="#FDC671" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5631 10.4899L9.30926 5.92479M19.9165 9.52011L21.5858 4.59532M27.474 15.8829L32.0391 13.629" stroke="#FDC671" stroke-linecap="round"/>
          <path d="M10.3746 32.3685C10.2012 32.88 10.4055 33.3565 10.9523 33.5419C11.7544 33.8137 12.52 34.0732 13.322 34.3451C13.6866 34.4686 14.0402 34.3848 14.2734 34.0566C13.763 33.8836 13.2526 33.7106 12.7422 33.5376C12.6328 33.5005 12.5599 33.4758 12.4629 33.4022C12.293 33.3039 12.2332 33.1207 12.3075 32.9015C12.3694 32.7188 12.5283 32.6097 12.723 32.635C12.8083 32.6232 12.9176 32.6603 13.027 32.6973C13.5374 32.8703 14.0842 33.0557 14.6311 33.241L14.8416 32.62C14.2947 32.4346 13.7844 32.2616 13.274 32.0886C13.1646 32.0515 13.0917 32.0268 12.9823 31.9898C12.8124 31.8914 12.7161 31.6959 12.778 31.5132C12.8399 31.3305 13.0112 31.185 13.2059 31.2102C13.2912 31.1984 13.4006 31.2355 13.4735 31.2602C14.0203 31.4455 14.5307 31.6185 15.114 31.8162L15.2998 31.2682C13.827 31.1764 12.4658 30.7557 11.2042 29.9208L11.0556 30.3592C10.8204 31.0533 10.5975 31.7109 10.3746 32.3685ZM11.3203 35.2143C11.4531 35.4223 11.6718 35.4964 11.9401 35.4244C12.196 35.389 12.3796 35.2068 12.4298 34.9387L11.2268 34.531C11.1401 34.7867 11.1511 34.9941 11.3203 35.2143ZM13.3435 30.5644C14.2914 30.8857 15.3128 31.1097 16.2738 31.2725C16.225 31.2967 16.1637 31.3574 16.139 31.4305C15.8542 32.2708 15.5694 33.111 15.2845 33.9513C15.0988 34.4993 14.7555 34.9124 14.2431 35.1053C13.926 35.2015 13.5972 35.2122 13.2436 35.296C13.1452 35.4663 13.0345 35.6732 12.8508 35.8553C12.5075 36.2684 12.0322 36.3517 11.4723 36.3249L11.0713 36.1889C10.6345 35.9187 10.3201 35.527 10.2864 35.0268C10.2514 34.7706 10.3257 34.5514 10.3394 34.2709C10.303 34.2586 10.2665 34.2462 10.2424 34.1973C9.53876 33.7552 9.25057 33.0465 9.48654 32.2304C9.74727 31.3413 10.0321 30.501 10.3657 29.6366C10.4153 29.4904 10.4159 29.3685 10.2825 29.2825C10.222 29.2213 10.1614 29.16 10.0645 29.0864C11.0962 29.6398 12.1769 30.169 13.3435 30.5644Z" fill="#FDC671"/>
          <path d="M9.86688 16.3097L16.1428 21.7768C16.3244 21.9606 16.396 22.2292 16.3217 22.4484C16.235 22.7041 16.0149 22.8739 15.7714 22.8728L7.75695 23.3741C8.26381 26.3155 10.3562 28.7762 13.1999 29.74C17.5018 31.1981 22.1539 28.8649 23.6027 24.5905C25.0639 20.2796 22.7404 15.6226 18.475 14.1769C15.4126 13.1389 12.0351 13.9899 9.86688 16.3097ZM8.48083 15.9621C10.982 12.8998 15.1286 11.6985 18.8836 12.9713C23.8417 14.6518 26.4899 20.0297 24.8058 24.9982C23.1217 29.9668 17.7493 32.6261 12.7912 30.9456C9.25492 29.747 6.75255 26.5772 6.38397 22.868C6.36059 22.6971 6.4225 22.5144 6.54495 22.393C6.66739 22.2716 6.81391 22.1991 6.98451 22.1754L14.1219 21.7435L8.5489 16.8405C8.2944 16.6321 8.26002 16.2538 8.48083 15.9621Z" fill="#FDC671"/>
          <path d="M18.095 20.5985C17.8664 20.3382 17.8916 19.9575 18.1525 19.7285C18.5584 19.3722 18.5979 18.7741 18.2424 18.3691C17.8869 17.9641 17.2887 17.9257 16.8828 18.282C16.6219 18.511 16.2412 18.4867 16.0127 18.2263C15.7841 17.9659 15.8093 17.5853 16.0702 17.3563C16.9979 16.5419 18.3829 16.6579 19.1701 17.5548C19.9827 18.4805 19.8638 19.8654 18.9651 20.6543C18.7042 20.8833 18.3235 20.8589 18.095 20.5985Z" fill="#FDC671"/>
        </svg>

        <div class="centered">
          <app-btn v-if="playlist" to="/requests/playlist/add">
            {{$ml.get("הוספת רעיון למשחק")}}
          </app-btn>
          <app-btn v-else to="/requests/shootlist/add">
            {{$ml.get("הוספת רעיון למשחק")}}
          </app-btn>

        </div>

        <svg width="35" height="41" viewBox="0 0 35 41" fill="#FDC671" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.1459 10.4899L25.3997 5.92479M14.7925 9.52011L13.1232 4.59532M7.23502 15.8829L2.66991 13.629" stroke="#FDC671" stroke-linecap="round"/>
          <path d="M24.3344 32.3685C24.5078 32.88 24.3035 33.3565 23.7566 33.5419C22.9546 33.8137 22.189 34.0732 21.3869 34.3451C21.0224 34.4686 20.6688 34.3848 20.4356 34.0566C20.946 33.8836 21.4564 33.7106 21.9668 33.5376C22.0762 33.5005 22.1491 33.4758 22.2461 33.4022C22.416 33.3039 22.4758 33.1207 22.4015 32.9015C22.3396 32.7188 22.1807 32.6097 21.986 32.635C21.9007 32.6232 21.7914 32.6603 21.682 32.6973C21.1716 32.8703 20.6247 33.0557 20.0779 33.241L19.8674 32.62C20.4142 32.4346 20.9246 32.2616 21.435 32.0886C21.5444 32.0515 21.6173 32.0268 21.7267 31.9898C21.8966 31.8914 21.9929 31.6959 21.931 31.5132C21.869 31.3305 21.6978 31.185 21.5031 31.2102C21.4178 31.1984 21.3084 31.2355 21.2355 31.2602C20.6887 31.4455 20.1783 31.6185 19.595 31.8162L19.4092 31.2682C20.8819 31.1764 22.2432 30.7557 23.5048 29.9208L23.6534 30.3592C23.8886 31.0533 24.1115 31.7109 24.3344 32.3685ZM23.3887 35.2143C23.2559 35.4223 23.0372 35.4964 22.7689 35.4244C22.513 35.389 22.3294 35.2068 22.2791 34.9387L23.4822 34.531C23.5689 34.7867 23.5579 34.9941 23.3887 35.2143ZM21.3655 30.5644C20.4176 30.8857 19.3961 31.1097 18.4352 31.2725C18.484 31.2967 18.5453 31.3574 18.57 31.4305C18.8548 32.2708 19.1396 33.111 19.4244 33.9513C19.6102 34.4993 19.9534 34.9124 20.4659 35.1053C20.783 35.2015 21.1118 35.2122 21.4654 35.296C21.5638 35.4663 21.6745 35.6732 21.8582 35.8553C22.2015 36.2684 22.6768 36.3517 23.2367 36.3249L23.6377 36.1889C24.0745 35.9187 24.3889 35.527 24.4225 35.0268C24.4576 34.7706 24.3833 34.5514 24.3696 34.2709C24.406 34.2586 24.4425 34.2462 24.4665 34.1973C25.1702 33.7552 25.4584 33.0465 25.2224 32.2304C24.9617 31.3413 24.6769 30.501 24.3433 29.6366C24.2937 29.4904 24.293 29.3685 24.4265 29.2825C24.487 29.2213 24.5475 29.16 24.6445 29.0864C23.6127 29.6398 22.5321 30.169 21.3655 30.5644Z" fill="#FDC671"/>
          <path d="M24.8421 16.3097L18.5661 21.7768C18.3846 21.9606 18.313 22.2292 18.3873 22.4484C18.474 22.7041 18.6941 22.8739 18.9376 22.8728L26.952 23.3741C26.4452 26.3155 24.3528 28.7762 21.5091 29.74C17.2072 31.1981 12.5551 28.8649 11.1063 24.5905C9.64509 20.2796 11.9686 15.6226 16.234 14.1769C19.2964 13.1389 22.6739 13.9899 24.8421 16.3097ZM26.2282 15.9621C23.727 12.8998 19.5804 11.6985 15.8254 12.9713C10.8673 14.6518 8.21913 20.0297 9.9032 24.9982C11.5873 29.9668 16.9596 32.6261 21.9178 30.9456C25.4541 29.747 27.9564 26.5772 28.325 22.868C28.3484 22.6971 28.2865 22.5144 28.164 22.393C28.0416 22.2716 27.8951 22.1991 27.7245 22.1754L20.5871 21.7435L26.1601 16.8405C26.4146 16.6321 26.449 16.2538 26.2282 15.9621Z" fill="#FDC671"/>
          <path d="M16.614 20.5985C16.8426 20.3382 16.8174 19.9575 16.5565 19.7285C16.1506 19.3722 16.1111 18.7741 16.4666 18.3691C16.8221 17.9641 17.4203 17.9257 17.8262 18.282C18.0871 18.511 18.4678 18.4867 18.6963 18.2263C18.9249 17.9659 18.8997 17.5853 18.6388 17.3563C17.7111 16.5419 16.3261 16.6579 15.5389 17.5548C14.7263 18.4805 14.8452 19.8654 15.7439 20.6543C16.0048 20.8833 16.3855 20.8589 16.614 20.5985Z" fill="#FDC671"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>


export default {
props:{
  playlist:{
    default:true,
  }
}
}
</script>

<style lang="scss" scoped>

.add-your-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .top-block {
    background: #06364B;
    height: 70px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .centered {
        padding: 0 20px;
        position: relative;

        .text {
          font-size: 17px;
          color: #FFFFFF;

          position: absolute;
          bottom: calc(100% + 5px);
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
        }

        .app-btn {
          background: #FDC671;
          color: #06364B;
        }
      }
    }
  }


  .author {

    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 300px;
    padding: 10px 0;

    .prepend {
      color: #fff;
      font-size: 10px;

    }
    .user {

      display: flex;
      flex-direction: row;
      align-items: center;

      margin-right: 10px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #eee;
      }
      
      .no-photo {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #eee;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 30px;
          fill: #aaa;
        }
      }

      .names {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        font-size: 18px;
        color: #FBB03B;
        margin-right: 10px;
      }
    }
  }
}

</style>