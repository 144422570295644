<template>
  <main class="individual-plan" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get('תוכנית התפתחות אישית')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler/>
      </span>
    </SimpleHeader>
    <app-loading class="sm-cont" style="background: #fff; overflow:scroll;">
      <template>
        <div class="header">
            <div class="title">
              {{$ml.get('עריכת תלמידים')}}
            </div>
            
          </div>
        <div class="table-div" style="text-align: center; overflow-x:auto; direction: ltr;">
          <table class="table-responsive" id="customers">
            <tr>
              <th style="white-space: nowrap;">{{$ml.get('שֵׁם')}}</th>
              <th style="white-space: nowrap;">{{$ml.get('שֵׁם מִשׁפָּחָה')}}</th>
              <th style="white-space: nowrap;">{{$ml.get('אימייל')}}</th>
              <th style="white-space: nowrap;">{{$ml.get('פעולה')}}</th>
            </tr>
            <tr v-for="(s,index) in students" :key="index">
              <td>{{s.name}}</td>
              <td>{{s.surname}}</td>
              <td>{{s.email}}</td>
              <td>
                <app-btn v-if="s.blocked==0" @click="block(s.id_user)" class="block-btn">{{$ml.get('לַחסוֹם')}}</app-btn>
                <app-btn v-else class="unblock-btn" @click="unblock(s.id_user)">{{$ml.get('לבטל את החסימה')}}</app-btn>
                <app-btn class="unblock-btn" @click="changePass(s.id_user)">{{$ml.get('שנה סיסמא')}}</app-btn>
               </td>
            </tr>
            
          </table>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import ChangePass from "@/components/modals/ChangePass"
import {TeacherAPI} from "@/api";
import AppBtn from '@/components/ui/app-btn/AppBtn.vue';
import CryptoJS from "crypto-js";
import { Base64 } from 'js-base64';
export default {
  data() {
    return {
     students:[],
    };
  },
  components: {

    // GiftLine,
    SimpleHeader,
    SidebarToggler,
    AppBtn,
  },
  created() {
    window.fbq("track", "רשימת התלמידים");
    TeacherAPI.activities().then(res=>{
      let data = this.decryptObject(res.data);
      this.students = data;
    })
  },
  methods: {
    encryptObject(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env['VUE_APP_SECURE_KEY']);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

      const objectToEncryptString = JSON.stringify(data);

      const encrypted = CryptoJS.AES.encrypt(objectToEncryptString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 8,
      });

      const encryptedObject = {
        iv: ivBase64,
        value: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
      return encryptedObject;
    },
    decryptObject(res){
      var key = process.env["VUE_APP_SECURE_KEY"];
      var encrypted = res.payload;
      var encrypted_json  = JSON.parse(Base64.decode(encrypted));
      var decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(Base64.encode(key)),{
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
                        mode: CryptoJS.mode.CBC
                      });
      let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      
      return data;
    },
    changePass(id){
      this.$modal.show(
        ChangePass,
        {
         id_user:id
        },
        {
          clickToClose:true,
          adaptive: true,
          height: "300px",
          width: "345px",
        }
      );
    },
    block(id){
      TeacherAPI.block(this.encryptObject({id_user:id})).then(()=>{
        TeacherAPI.activities().then(res=>{
          let data = this.decryptObject(res.data);
          this.students = data;
        })
      }).catch(err=>{
      })
    },
    unblock(id){
      TeacherAPI.unblock(this.encryptObject({id_user:id})).then(()=>{
        TeacherAPI.activities().then(res=>{
          let data = this.decryptObject(res.data);
          this.students = data;
        })
    }).catch(err=>{
    })
    }
    // goRoute(){
    //   this.$router.push('https://docs.google.com/uc?export=download&id=1ET5Nx-SHaHCCORdSDhIWbqFGIU3R3KWL');
    // }
  },

};
</script>
<style scoped lang="scss">
.header {
          background: #00465B;
          padding: 5px 10px 8px 10px;

          .title {
            margin: 9px auto;
            font-size: 21px;
            line-height: 29px;
            text-align: center;
            color: #FFFFFF;
            @media screen and (max-width: 330px){
              width: unset;
            }
          }
          .undertitle {
            width: 310px;
            margin: 0 auto;
            @media screen and (max-width: 330px){
              width: unset;
            }
            .element {
              display: flex;
              align-items: center;
              font-size: 12px;
              line-height: 16px;
              text-align: right;
              color: #FFFFFF;
              font-style: italic;
              margin: 2px 0;
              .round {
                margin-left: 10px;
                min-width: 10px;
                max-width: 10px;
                height: 10px;
                border-radius: 50%;
                @media screen and (max-width: 330px){
                  margin-left: 4px;
                }
              }

              .green {
                background: #8CC63F;
              }

              .yellow {
                background: #FBB03B;
              }
            }
          }
        }
.table-div::-webkit-scrollbar {
  width: 5px !important;               /* ширина всей полосы прокрутки */
}

.table-div::-webkit-scrollbar-track {
  margin: 5px !important;
  background: transparent;        /* цвет зоны отслеживания */
}

.table-div::-webkit-scrollbar-thumb {
  background-color: lightgray;    /* цвет бегунка */
  border-radius: 20px;       /* округлось бегунка */
  border: 2px solid lightgray;  /* отступ вокруг бегунка */
}
.block-btn{
  height: 30px;
  margin: 3px 0px;
  background: #f2828f !important;
  border-radius: 18px;
  font-family: 'App';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

}
.unblock-btn{
  height: 30px;
  margin: 3px 0px;
  background: #FDC671 !important;
  border-radius: 18px;
  font-family: 'App';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 5px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2828f;
  color: white;
}
.individual-plan {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {
    flex-grow: 1;
    min-height: 450px;


    .individual-plan-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        margin: 20px 0 10px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        text-align: center;
        color: #00465B;
      }
      .header{
        .header-info{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .info-element{
            margin: 12px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            text-align: right;
            color: #00465B;
            display: flex;
            align-items: flex-start;
            svg{
              margin: 3px 5px 0 5px;
            }
            .element-d{
              .row{
                display: flex;
                align-items: center;
              }
            }
            .row-element{
              margin: 0 5px;
              padding: 0 12px;
              position: relative;
            }
            .green{
              &:after{
                position: absolute;
                width: 8px;
                height: 8px;
                content: '';
                border-radius: 50%;
                background: #8CC63F;
                top: 7px;
                right: 0;
              }
            }
            .blue{
              &:after{
                position: absolute;
                width: 8px;
                height: 8px;
                content: '';
                border-radius: 50%;
                background: #00465B;
                top: 7px;
                right: 0;
              }
            }
            .gray{
              &:after{
                position: absolute;
                width: 8px;
                height: 8px;
                content: '';
                border-radius: 50%;
                background: #C4C4C4;
                top: 7px;
                right: -5px;
              }
            }
          }
        }
      }
      .list{
        margin-top: 50px;
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}

</style>