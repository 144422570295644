<template>
  <main style="background:#005d79" class="educational-classes">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get($store.getters['pagestitle/data'].find(x=>x.name=='First_page').data[0]) }}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <!--:style="{background : gifts.known.length>0? '#FF7384' : '#B56576'}"-->
    <app-loading
    :style="{background : gifts.known.length>0? '#005d79' : '#005d79'}"
      class="sm-cont"
    >
      <template>
        <div class="educational">
          <div class="header">
            <div class="title" style="margin-top: 20px">
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 22 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.08651 0C5.04268 0 3.23876 0.590542 1.95617 1.84544C0.673588 3.10035 0 4.94579 0 7.0865C0 10.5052 1.94694 14.1776 4.28143 17.1257C5.44867 18.5975 6.70819 19.8662 7.97232 20.8166C8.78432 21.4256 9.58247 21.9054 10.4083 22.1822C10.3391 22.3345 10.2837 22.4867 10.1869 22.6621C9.91465 23.1511 9.44868 23.8247 9.44868 24.8028C9.44868 26.1684 10.4037 26.8881 10.9988 27.4602C11.2987 27.7463 11.5617 27.9815 11.7001 28.1615C11.8385 28.3414 11.8108 28.4106 11.8108 28.346C11.8108 29.1534 10.6667 29.8962 10.6667 29.8962L11.7739 32C11.7739 32 14.173 30.842 14.173 28.346C14.173 27.6632 13.8501 27.1188 13.5456 26.722C13.2411 26.3253 12.9135 26.0438 12.6228 25.7624C12.0369 25.1995 11.8108 24.7566 11.8108 24.8028C11.8108 24.6367 11.9354 24.4106 12.2538 23.8431C12.466 23.4648 12.6967 22.9343 12.8443 22.293C13.7762 22.0484 14.6897 21.504 15.6125 20.8166C16.8766 19.8754 18.173 18.6298 19.3403 17.1626C21.6794 14.2284 23.6217 10.5513 23.6217 7.0865C23.6217 4.94579 22.9481 3.10035 21.6655 1.84544C20.3829 0.590542 18.579 0 16.5352 0C14.1776 0 12.8351 1.11649 11.8478 1.99308C10.9758 1.14418 9.73933 0 7.08651 0ZM7.08651 2.36217C9.61477 2.36217 9.67013 3.31257 11.0727 4.46597L11.8108 5.05652L12.549 4.46597C13.8316 3.40946 14.5236 2.36217 16.5352 2.36217C18.1269 2.36217 19.2803 2.79585 20.0415 3.54325C20.8028 4.29066 21.2595 5.41638 21.2595 7.0865C21.2595 9.60092 19.6125 13.0288 17.4948 15.6863C16.4383 17.015 15.2618 18.1499 14.2099 18.9343C13.158 19.7186 12.203 20.0784 11.8108 20.0784C11.4279 20.0784 10.4637 19.7232 9.41177 18.9343C8.35986 18.1453 7.18801 16.9873 6.12688 15.6494C4.00923 12.9781 2.36217 9.53633 2.36217 7.0865C2.36217 5.41638 2.81892 4.29066 3.58016 3.54325C4.34141 2.79585 5.49481 2.36217 7.08651 2.36217Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <span class="title-style">{{ $ml.get($store.getters['pagestitle/data'].find(x=>x.name=='First_page').data[0]) }}</span>
              </div>
            </div>
            <div class="title">
              <span>{{
                $ml.get(
                  "בחרו את הערכים אותם אתם רוצים להנחיל לילדיכם, וקבלו מאגרי משחקים מתאימים"
                )
              }}</span>
            </div>
            <div class="undertitle">
              <div :style="{ direction: direction }" class="element">
                <!-- <div class="round green"></div>
                <span
                  :style="
                    direction === 'ltr' ? 'text-align:left;margin-left:5px' : ''
                  "
                  >{{ $ml.get("מספר הפעמים ששוחקו משחקים בקטגוריה זו") }}</span
                > -->
              </div>
            </div>
          </div>
          <div class="gifts-wrapper">
            <app-loading v-if="!isLoaded">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </app-loading>


            <app-loading v-else>
              <div
                v-if="unknown.length > 0"
                :style="{ background: '#a8b5b3' }"
                class="gifts-section"
              >
                <div class="title">{{ $ml.get("אין קטגוריה") }}</div>
                <div class="gifts" style="padding: 0 0 15px 0">
                  <GiftElement
                    :classType="'educational-parents'"
                    :key="i * 2"
                    v-for="(gift, i) in unknown"
                    :rec="true"
                    :gift="gift"
                  />
                </div>
              </div>
              <div class="cat-w">
                <div
                  v-for="elem in test"
                  v-if="elem[0] != null"
                  :style="{
                    background: $store.getters[
                      'educational-categories/getParentsColorById'
                    ](elem[0].id_category),
                  }"
                  class="gifts-section"
                >
                  <div class="title">
                    {{
                      $store.getters["educational-categories/geTitleById"](
                        elem[0].id_category
                      )
                    }}
                  </div>

                  <div class="gifts" style="padding: 0 0 15px 0">
                    <GiftElement
                      :classType="'educational-parents'"
                      :key="i * 2"
                      v-for="(gift, i) in elem"
                      v-if="gift.valuebleExpireancePage == true"
                      :rec="true"
                      :gift="gift"
                    />
                  </div>
                </div>
              </div>
            </app-loading>
          </div>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import GiftElement from "@/components/parents/GiftElement";
import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
import { TeacherAPI } from "@/api/teacher.api";
import store from "../../store";
import StudentFromMainFirstTime from "@/components/modals/StudentFromMainFirstTime";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      testData:[1,2,3,4,5,6],
      index: 0,
      popup_showed: false,
      radio: "",
      loading: {
        next: false,
        sync: false,
      },
      bifts: [
        {
          title: "הפופולריים ביותר ",
          color: "#B56576",
          arr: [
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
          ],
        },
        {
          title: "ערכים נוספים ",
          color: "#FF7384",
          arr: [
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
            { name: "חיוביות " },
          ],
        },
      ],
      gifts: {
        popular: [],
        known: [],
      },
      test: [],
      unknown: [],
      isLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      eduData: "educational-categories-page/data",
    }),
    sections() {
      return this.$store.getters["educational-categories/data"];
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    GiftElement,
    GiftElementSkeleton
  },
  watch:{
    eduData:function(){
      if(this.sections!=null)
      {
      this.ready()
      }
    },
    sections:function(){
      if(this.eduData!=null)
      {
      this.ready()
      }
    }
  },
  methods: {
    ready()
    {
      this.unknown = this.eduData.filter((item) => {
        return item.id_category === null;
      });
      let sectionFiltered = Array();
      this.sections.forEach((sec) => {
        if (sec.valuebleExpireancePage == true) {
          sectionFiltered.push(sec);
        }
      });

      sectionFiltered = sectionFiltered.sort(
        (a, b) => a.order_index_parents - b.order_index_parents
      );
      let sortArray = [];
        sectionFiltered.forEach((sec, i) => {
          sortArray[i] = this.eduData.filter((item) => {
            return +item.id_category === sec.id;
          });
        });

        sortArray.forEach(element=>{
        element.sort((a, b) => (a.order_index_parents != null ? a.order_index_parents : Infinity) - (b.order_index_parents != null ? b.order_index_parents : Infinity))
        });
        this.test = sortArray;

      this.gifts.popular = this.eduData.filter((item) => {
        return item.section === "popular";
      });
      this.gifts.known = this.eduData.filter((item) => {
        return item.section === "known";
      });
      this.isLoaded=true;
    }
  },
  mounted() {
    if (!this.$store.getters['educational-categories/isLoaded']){
      this.$store.dispatch('educational-categories/updateData');
    }
    if (!store.getters['educational-categories-page/isLoaded']){
      store.dispatch('educational-categories-page/updateData');
    }
    if (
      !this.$store.getters["tutorials/isUsed"]("student-educational") &&
      this.$route.params.student
    ) {
      this.$store.commit("tutorials/setTutorial", "student-educational");
      this.$modal.show(
        StudentFromMainFirstTime,
        {},
        {
          adaptive: true,
          height: "auto",
          width: 300,
        }
      );
    }
  },
  created() {
    window.fbq("track", "בול בשבילך");
    if (this.$store.getters['educational-categories-page/isLoaded']) {
      this.ready();
    }
    else
    {
      this.isLoaded = false;
    }
      
  },
};
</script>
<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  padding: 10px;
  position: absolute;
     height: 12px;
     left: 135px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:40% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.title-style {
  // font-family: 'Kartiv';
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.educational-classes {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {
    flex-grow: 1;
    min-height: 450px;

    .educational {
      .header {
        background: #00465b;
        padding: 5px 30px 25px 30px;

        .title {
          margin: 10px auto;
          width: 100%;
          font-family: "App";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          color: #ffffff;
          @media screen and (max-width: 330px) {
            width: unset;
          }
        }
        .undertitle {
          width: 310px;
          margin: 0 auto;
          @media screen and (max-width: 330px) {
            width: unset;
          }
          .element {
            display: flex;
            align-items: center;
            text-align: right;
            color: #ffffff;
            margin: 2px 0;
            font-family: "App";
            font-style: italic;
            font-weight: 200;
            font-size: 12px;
            line-height: 16px;
            text-align: right;

            color: #ffffff;
            .round {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              @media screen and (max-width: 330px) {
                margin-left: 4px;
              }
            }

            .green {
              background: #8cc63f;
            }

            .yellow {
              background: #fbb03b;
            }
          }
        }
      }
      .gifts-wrapper {
        .gifts-section {
          background: #b56576;
          padding: 0 10px;
          .title {
            font-weight: bold;
            padding: 10px 0 10px;
            font-size: 21px;
            text-align: center;
            color: #ffffff;
          }
          .gifts {
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .gift-element {
              margin: 0 16px;
              @media screen and (max-width: 420px) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
