<template>
  <div class="modal" dir="rtl">
    <button class="modal-close" @click="$emit('close')">x</button>

    <p>{{$ml.get('יש לך קוד קופון')}}?</p>
    <input
      v-model="code"
      class="theme-input"
      type="text"
      :placeholder="$ml.get('הכנסי את קוד הקופון')"
    />
    <p v-if="err" class="error">{{ err }}</p>

    <button @click="promo" class="use-promo theme-button-outline">{{$ml.get('שליחה')}}</button>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      err: null,
      sent: false,
      code: ""
    };
  },
  methods: {
    promo() {
      if (this.sent) return;
      this.sent = true;
      axios
        .post("/subscribe/promo", {
          promo: this.code
        })
        .then(res => {
          this.$store.dispatch("user/updateData");
          this.$router.replace("/account");
          this.$emit("close");
        })
        .catch(res => {
          this.err = res.data.err;
        })
        .finally(() => {
          this.sent = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 0;
  font-size: 20px;
}

.modal {
  padding: 20px;

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.promo {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.use-promo {
  margin-top: 30px;
  border-color: #005d7a;
  color: #005d7a;
  width: 100%;
}

.theme-input {
  width: 100%;
}

.error {
  color: red;
  margin: 0;
  font-size: 14px;
}
</style>
