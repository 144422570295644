const AuthAPI = {

  regWithPhone(data) {
    return config.API.post('/user/registrateByPhone', data);
  },
  checkFB(data){
    return config.API.post('/auth/checkFB', data);
  },
  checkGoogle(data){
    return config.API.post('/auth/checkGoogle', data);
  },
  reg(data) {
    return config.API.post('/auth/reg', data);
  },
  default() {
    return config.API.get('/auth/default');
  },
  regFB(data) {
    return config.API.post('/auth/regFB', data)
  },
  regGoogle(data) {
    return config.API.post('/auth/regGoogle', data)
  },
  checkCoupon(data) {
    return config.API.post('/promo/validate', data);
  },
  checkCouponBy(data) {
    return config.API.post('/promo/validateBy', data)
  },
  loginOld(data){
    return  config.API.post('/auth/loginOld', data);
  },
  login(data){
    return  config.API.post('/auth/login', data);
  },
  auth(data){
    return config.API.post('/multiple',data);
  },
  signInwithGoogle(data){
    return config.API.post('/auth/servicesg',data);
  },
  signInwithFacebook(data){
    return config.API.post('/auth/servicesf',data);
  },
  addMember(data){
    return config.API.post('/auth/addMember',data);
  },
  signInBack(data){
    return config.API.post('/auth/signInBack', data);
  },
  updatePass(data){
    return config.API.post('/updatePass', data);
  },
  updateEmail(data){
    return config.API.post('/updateEmail', data);
  },
  updatePhone(data){
    return config.API.post('/updatePhone', data);
  },
  confirmedEmail(data){
    return config.API.post('/confirmedEmail', data);
  },
  confirmedPhone(data){
    return config.API.post('/confirmedPhone', data);
  },
  updateUser(data){
    return config.API.post('/updateUserStatus', data);
  },
  regPagesTitle(data){
    return  config.API.get('/getTitlesForSoftware');
  },
  confirmSMS(data){
    return  config.API.post('/auth/sms/confirmPhone',data);
  },
  sendCode(data){
    return  config.API.post('/auth/sms/sendCode',data)
  },
  sendEmail(data){
    return config.API.post('/auth/mail/sendEmailCode',data);
  },
  checkEmail(data){
    return  config.API.post('/auth/mail/checkEmail',data);
  },
  forgotPassword(data){
    return config.API.post('/auth/restorePassword',data);
  },
  restore(data){
    return config.API.post('/auth/restore',data);
  },
  res(data){
    return config.API.get('/auth/restore/'+data);
  },
  presentation(){
    return config.API.get('/updatePresentationVisit/presentation');
  },
  sitelink(){
    return config.API.get('/updatePresentationVisit/sitelink');
  },
  logout(){
    return config.API.post('/logout');
  }
};

export { AuthAPI }