<template>
  <div class="educational-value">
    <img v-if="status" src="@/assets/img/icons/done.svg" alt="">
    <img v-else src="@/assets/img/icons/cancel.svg" alt="">

    <div class="text">{{value}}</div>
  </div>
</template>
<script>
   export default {
    props:{
      value: {},
      status: {},
    },
    components:{

    },
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>
  //
 .educational-value{
 display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   .text{
     font-size: 12px;
     color: #000000;
   }
   img{
     margin:0 6px;
   }
 }
</style>

