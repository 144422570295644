<template>
  <div
    class="app-number-input text-input"
    :class="{
      disabled: disabled
    }"
  >
    <input
      class="h-empty-input h-monrope"
      :value="value"
      :placeholder="placeholder"
      :required="required"
      :min="min"
      :max="max"
      :disabled="disabled"
      type="number"
      :readonly="readonly"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      required: true,
      default: null
    },
    placeholder: {},
    required: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    disabled: {
      default: false
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {},
  methods: {
    onInput(e) {
      let val = parseFloat(e.target.value);

      if (isNaN(val)) {
        this.$emit("input", null);
        return;
      }

      if (this.min && this.min > val) {
        val = this.min;
      }
      if (this.max && this.max < val) {
        val = this.max;
      }

      this.$emit("input", val);
    },
    onBlur() {
      this.focused = false;
    },
    onFocus() {
      this.focused = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-input {
  width: 100%;
  font-size: 14px;
  height: 42px;
  border: 1px solid #6f9c40;
  border-radius: 3px;
  position: relative;

  // .hint {
  //   position: absolute;
  //   top: 50%;
  //   right: 10px;
  //   transform: translateY(-50%);
  //   line-height: 0;
  //   font-size: 14px;
  //   pointer-events: none;
  // }

  input {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    border-width: 0px;
    border-style: solid;
    padding: 0 12px;
    background-color: #fff;
    outline: none;
    color: #6f9c40;
    font-size: 16px;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.1s ease;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      text-transform: capitalize;
      font-size: 18px;
      opacity: 0.65;
      color: #333;
    }
  }
}
</style>
