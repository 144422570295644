<template>
  <div class="title-block">
    <div class="img">
      <img alt="" :src="data.img">
    </div>
    <div class="title">
      <span class="font-cool">
        {{translated ? translated : data.translation[0].data.name }}
      </span>
      <div class="sub-title">

        <template v-if="data.level == 3">
          {{$ml.get("מאתגר")}}+
        </template>
        <template v-else-if="data.level == 1">
          {{$ml.get("קל")}}
        </template>
        <template v-else>
          {{$ml.get("מאתגר")}}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {}
  },
  computed:{
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.data.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.title-block {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0px 20px 20px 20px;
  padding-bottom: 10px;
  height: 65px;

  .img {
    height: 60px;
    width: 60px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 50px;
      max-width: 50px;
    }
  }
  .title {
    color: #FF7384;
    font-weight: 800;
    font-size: 36px !important;
    line-height: 1;

    .sub-title {
      color: #A0CCDA;
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    line-height: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    }
  }
}

</style>