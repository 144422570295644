<template>
  <div style="width: 100%; background: #ddf0f5; text-align: center;padding: 20px 0px; height: 100%;
    border-radius: 10px;">
    <div style="text-align: center; font-family: 'App';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 29px;
text-align: center;
margin-bottom: 20px;
color: #00465B;">
    {{ $ml.get('שנה סיסמא') }}</div>
    <div class="password-input">
        <v-text-field
          v-model="user.password"
          :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :type="show ? 'text' : 'password'"
          class="test"
          name="input-10-1"
          label="סיסמה"
          @click:append="show = !show"
          autocomplete="new-password"
          @input="validatePassword"
        ></v-text-field>
      </div>
      <div class="alerts-block">
        <div style="width: 100; text-align: start">
          <span class="title">{{ $ml.get("הסיסמה חייבת להכיל")+":" }}</span>
        </div>
        <div style="width: 100; text-align: start">
          <div style="display: inline-block">
            <ul class="hr">
              <li>
                <svg
                  v-if="user.password == ''"
                  style="margin-left: 3px"
                  width="4"
                  height="7"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="#00465b" />
                </svg>

                <svg
                  v-else-if="password.nums == false"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="3.16804"
                    y1="2.82805"
                    x2="8.8249"
                    y2="8.4849"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                  <line
                    x1="3.16789"
                    y1="8.48528"
                    x2="8.82475"
                    y2="2.82842"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                </svg>

                <svg
                  v-else-if="password.nums == true"
                  style="margin-left: 3px"
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.48468 0.828427L3.82782 6.48528L0.999396 3.65685"
                    stroke="#8CC63F"
                    stroke-width="2"
                  />
                </svg>

                <span style="margin-right: 2px">{{$ml.get("לפחות ספרה אחת")}}</span>
              </li>
              <li style="padding-right: 5px">
                <svg
                  v-if="user.password == ''"
                  style="margin-left: 3px"
                  width="4"
                  height="7"
                  viewBox="0 0 4 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="2" r="2" fill="#00465b" />
                </svg>

                <svg
                  v-else-if="password.length == false"
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="3.16804"
                    y1="2.82805"
                    x2="8.8249"
                    y2="8.4849"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                  <line
                    x1="3.16789"
                    y1="8.48528"
                    x2="8.82475"
                    y2="2.82842"
                    stroke="#E41E1E"
                    stroke-width="2"
                  />
                </svg>

                <svg
                  v-else-if="password.length == true"
                  style="margin-left: 3px"
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.48468 0.828427L3.82782 6.48528L0.999396 3.65685"
                    stroke="#8CC63F"
                    stroke-width="2"
                  />
                </svg>

                <span style="margin-right: 2px">{{ $ml.get("לפחות 8 תווים") }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btn" style="width: 100%; text-align: center;">
        <app-btn :class="disabled == true ? 'active' : 'disabled'"  @click="change()" class="block-btn">{{$ml.get('לַחסוֹם')}}</app-btn>
      </div>
  </div>
</template>

<script>
import { TeacherAPI } from '@/api';
import { mapGetters } from "vuex";
import CryptoJS from "crypto-js";
import { Base64 } from 'js-base64';
export default {
  data() {
    return {
      show: false,
      password: {
        length: false,
        nums: false,
        verified: false,
      },
      user:{
        password:''
      }
    };
  },
  props:{
    id_user:null,
  },
  computed:{
    ...mapGetters({
      promo:"promo-store/data",
      prices: "user/getPrices",
      settingsPlaylist: "playlist/settings",
      settingsShootlist: "shootlist/settings",
      email: "user/getEmail",
    }),
    disabled(){
      if(this.password.verified==true)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
  },
  methods:{
    encryptObject(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env['VUE_APP_SECURE_KEY']);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

      const objectToEncryptString = JSON.stringify(data);

      const encrypted = CryptoJS.AES.encrypt(objectToEncryptString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 8,
      });

      const encryptedObject = {
        iv: ivBase64,
        value: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
      return encryptedObject;
    },
    decryptObject(res){
      var key = process.env["VUE_APP_SECURE_KEY"];
      var encrypted = res.payload;
      var encrypted_json  = JSON.parse(Base64.decode(encrypted));
      var decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(Base64.encode(key)),{
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
                        mode: CryptoJS.mode.CBC
                      });
      let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      
      return data;
    },
    change(){
      TeacherAPI.changePass(this.encryptObject({id_user:this.id_user,password:this.user.password})).then(()=>{
        this.$emit('close');
      })
    },
    validatePassword() {
      this.password.verified = false;
      let lengthCheck = this.user.password.length;
      let numCheck = parseInt(this.user.password.replace(/\D/g, "")) || 0;

      let num = numCheck == 0 ? false : true;
      let length = lengthCheck >= 8 ? true : false;

      this.password.nums = num;
      this.password.length = length;

      if (length && num) {
        this.password.verified = true;
      }
    },
  },
  created() {
    
  },
  destroyed() {
  }
};
</script>

<style lang="scss" scoped>
ul.hr li {
  display: inline; /* Отображать как строчный элемент */
  margin-right: 5px; /* Отступ слева */
  padding: 3px 0px; /* Поля вокруг текста */
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #00465b;
}

.block-btn{
  margin: auto;
    left: 0;
    right: 0;
    margin-top: 20px;
  background: #FDC671 !important;
  border-radius: 18px;
  font-family: 'App';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

  &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
}
.password-input:hover {
  border: 2px solid #fdc671 !important;
}
.password-input {
  border: 1px solid #00465b;
  padding: 1px 17px 0px 12px;
  margin: auto;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}
.alerts-block {
  padding: 0px 17px;
  height: 65px;
  width: 304px;
  border: 1px solid #00465b;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;

  .title {
    padding: 3px 0px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    text-align: right;

    color: #00465b;
  }
}
.reg-b {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 54px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
.vue-tel-input ::v-deep .vti__dropdown {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 10px 20px 13px;
  margin-right: 10px;
}
.vue-tel-input ::v-deep .vti__input {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 20px 20px 27px;
  margin-right: 10px;
  width: 214px !important;
}

.vue-tel-input ::v-deep .vti__flag {
  scale: 1.2;
}
.vue-tel-input ::v-deep .vti__dropdown-arrow {
  margin-left: 10px;
}

.vue-tel-input {
  box-shadow: none;
}
.vue-tel-input {
  box-shadow: none;
}
.phone-block {
  direction: ltr;
  padding: 1px 17px 0px 12px;
  margin: auto;
  height: 66px !important;
  position: relative;
  width: 300px !important;
  // margin-top: 27px;
  margin-bottom: 10px;
  border-radius: 4px;
  .vue-tel-input {
    // margin: auto;
    position: absolute;
    left: -1%;
    border-style: none !important;
  }
}
.email-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: black;

  svg {
    position: absolute;
    top: -2px;
    right: 3px;
  }
}
.email-input:hover {
  border: 2px solid #fdc671;
}

.email-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.test ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
}

.test ::v-deep .v-input__slot::before {
  border-style: none;
}

.test ::v-deep .v-input__icon .mdi-check {
  color: #8cc63f;
}

.test ::v-deep .v-input__icon .mdi-close {
  color: #e41e1e;
}
.test ::v-deep .v-input__slot::after {
  border-style: none;
}

.test ::v-deep .v-label {
  margin-top: 2px;
}

.test ::v-deep .v-text-field__slot input {
  padding-top: 12px;
}

.test {
  margin: 0;
}
</style>
