<template>
  <main dir="rtl" style="background: #005d79 !important;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get('סדנאות משחק') }}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading class="sm-cont" style="background: #fff;">
      <template>

    <section class="sm-cont">
      <div class="items">
        <div class="item" v-for="item in items">
          <p>
            <NameTranslated class="title" :translate="item.translation" />
          </p>

           <div v-if="item.youtube_iframe!==null" class="iframe">
             <iframe class="bg-img" :src="item.youtube_iframe" frameborder="0"></iframe>
           </div>
          <div
            v-else
            class="bg-img"
            :style="{ backgroundImage: `url(${item.img})` }"
          ></div>
          <ContentTranslated class="content" :translate="item.translation" />
          <div class="btn-container">
              <button @click="openContacts" class="theme-button-outline enter">{{ $ml.get('לפרטים נוספים') }} <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-0.000453115 4.24264L4.24219 8.48528V0L-0.000453115 4.24264Z" fill="#1A92B8"/>
              </svg></button>


          </div>
          <hr class="style-six">
        </div>
      </div>
    </section>
      </template>
    </app-loading>
  </main>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import NameTranslated from "@/components/playlist/NameTranslated";
  import ContentTranslated from "@/components/translated/ContentTranslated";
export default {
  data() {
    return {
      items: []
    };
  },
  methods: {
    sync() {
      axios.get("/workshop/select").then(res => {
        res.data = res.data.map(n => {
          n.text = n.text.replace(/\n/g, "<br>");
          return n;
        });
        this.items = res.data;
      });
    },
    parseBr(text){
      return  text.replace(/\n/g, "<br>");
    },
    openContacts(){
      this.$store.commit('modals/contacts/open');
    }
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    NameTranslated,
    ContentTranslated
  },
  created() {
    window.fbq("track", "סדנאות");
    this.sync();
  }
};
</script>

<style lang="scss" scoped>
.style-six {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 5px 15px;
}
main {
  background: #DDF0F5;
  .items{
    .item {
      margin: 0px 5px;
      background: #FFFFFF;
      padding: 13px 10px;
      border-radius: 10px;
      .title {
        font-family: 'App';
        font-weight: bold;
        font-size: 24px;
        line-height: 21px;
        text-align: right;
        color: #FF7384;
        /* margin-right: 18px; */
        margin-bottom: 10px;
      }
      .content{
        // margin-right: 18px;
        padding: 5px;
        padding-top: 10px;
        font-family: 'App';
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        text-align: right;
        color: #00465B;
      }
      .bg-img {
        background-position: center;
        width: 100%;
        height: 160px;
        margin: 0 auto;
        border-radius: 10px;
      }
      .btn-container {
        text-align: left;
        // margin-left: 18px ;
        button{
          padding: 0;
          background: transparent;
          font-family: App;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #1A92B8;
        }
      }
      .enter {
        background: #e36574;
      }
    }
  }

}
</style>
