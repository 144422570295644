<template>
  <main>
    <img src="@/assets/img/white-logo.svg" alt="" />
    <div class="title fugaz" v-if="$ml.current == 'eng'" style="width: 75px !important;text-align: right;font-family: 'App';font-weight: bold;">
      טוען
      <span v-for="dot in dots">. </span>
    </div>
    <div class="title fugaz" v-else style="width: 130px !important; text-align: left;">
      <span v-for="dot in dots">. </span>
      LOADING
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      dots: []
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.dots.length >= 3) {
        this.dots = [];
      } else {
        this.dots.push(null);
      }
    }, 500);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
main {
  background: #005d7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  img {
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    color: #fff;
  }
}
</style>
