<template>
  <div class="input-register-code">
    <input :value="value"
           @input="$emit('input', $event.target.value)"
           :placeholder="placeholder"
           autocomplete="false"
           class="input-reg"
           id="promocode"
           type="text">
    <div v-if="checked!==false && value!=='' " class="icons-wrapper">
      <div v-if="status===false" class="icon">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.7898 14.0972L4.59766 4.90504" stroke="#ff9090" stroke-width="3"/>
          <path d="M4.59766 14.0959L13.7898 4.90381" stroke="#ff9090" stroke-width="3"/>
        </svg>
      </div>
      <div v-else-if="status===true" class="icon">
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09766 6L5.59766 10.5L14.0977 2" stroke="#8CC63F" stroke-width="3"/>
        </svg>
      </div>
    </div>
    <div class="message"  v-if="checked!==false && value!==''" :class="{success : status, error: !status}">
      <span v-if="(message.school!==null) && (message.limit != message.used)"> {{   status ?   ' מנוי עד ' + message.school.endDate + ' מטעם ' + message.client  :  ' קוד שגוי ' }}</span>
      <span v-else-if="message.type === 'month'"> {{   status ?   ' מנוי עד ' + subs(new Date()) + ' מטעם ' + message.client  :  ' קוד שגוי ' }}</span>
      <span v-else-if="message.type === 'six'"> {{   status ?   ' מנוי עד ' + subs(new Date()) + ' מטעם ' + message.client  :  ' קוד שגוי ' }}</span>
      <span v-else-if="message.type === 'year'"> {{   status ?   ' מנוי עד ' + subs(new Date()) + ' מטעם ' + message.client  :  ' קוד שגוי ' }}</span>
      <span v-else> {{   status ?   ' You have free '+ message.type  :  ' קוד שגוי ' }}</span>
    </div>
    <div class="message"  v-else-if="checked!==true && value!==''" :class="{error : !status, success: status}">
      <span v-if="message.limit == message.used"> {{   status ? '':'השימוש קוד קופון זה הגיע לגבול המשתמשים שהוגדר לו, ואין אפשרות להשתמש עוד בקוד קופון זה' }}</span>
    </div>


      
  </div>
</template>

<script>
export default {
  name: "InputRegisterCode",
  methods: {
    renderSubsLong(type, school) {

      if(school) {
        return school.endDate;
      }

      if(type === 'month') {
        return 'חודש אחד';
      }
      else if(type === 'six') {
        return 'חצי שנה';
      }
      else if(type === 'year') {
        return 'שנה';
      }
    },
    subs(date){
      if (this.message.type==='month'){
         // let d = date.getDate() +'-'+ (date.getMonth() + 1)+ '-' +  date.getFullYear();
       date.setMonth(date.getMonth() + 1);
       return (date.getDate() <10 ? '0'+date.getDate() : date.getDate()) +'-'+ ((date.getMonth() + 1) <10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1))+ '-' +  date.getFullYear();
      } else if (this.message.type==='six'){
        date.setMonth(date.getMonth() + 6);
        return (date.getDate() <10 ? '0'+date.getDate() : date.getDate()) +'-'+ ((date.getMonth() + 1) <10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1))+ '-' +  date.getFullYear();
      } else {
        date.setMonth(date.getMonth() + 12);
        return (date.getDate() <10 ? '0'+date.getDate() : date.getDate()) +'-'+ ((date.getMonth() + 1) <10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1))+ '-' +  date.getFullYear();
      }
    },
  },
  computed:{
    type(){
      return this.message.type==='month' ? 'חודש' : 'שנתי';
    },
    duration(){


    }
  },
  mounted() {

  },
  props:{
    value: {

    },
    placeholder:{

    },
    status:{

    },
    message:{

    },
    checked:{}
  }
}
</script>

<style scoped lang="scss">
:active, :hover, :focus {
 outline: 0;
 outline-offset: 0;
}
input:-webkit-autofill,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  font-size: 17px;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}
.input-register-code {
  width: 250px;
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    top: -5px;
  }

  .input-reg {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    border-bottom: 1px solid #FFFFFF;

    font-size: 17px;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
    line-height: 20px;
    text-align: right;
    color: #FFFFFF;
  }
  .message{
    position: relative;
    display: inline-block;
    &.success{
      color: #8CC63F;

    }
    &.error{
      color: #ff9090;
      border-radius: 5px;
    }
  }
}
</style>