import { GiftsAPI } from '@/api';
import store from "@/store/index";
const EducationalSections = {
  namespaced: true,
  state: {
    data: null,
    matchedGames:null,
    fromChores:null,
    sociallSkills:null,
    learningSkills:null,

    sevenPage:null,
    eightPage:null,
    ninePage:null,
    tenPage:null,
    elevenPage:null,
    twelvePage:null,



    loaded: false,
    loadedMatchedGamesData: false,
    loadedFromChores:false,
    loadedSociallSkills:false,
    loadedLearningSkills:false,

    loadedSevenPage:false,
    loadedEightPage:false,
    loadedNinePage:false,
    loadedTenPage:false,
    loadedElevenPage:false,
    loadedTwelvePage:false,



  },
  mutations: {

    setData(state, data) {
      state.data = data
    },
    setMatchedGamesData(state, data) {
      state.matchedGames = data
    },
    setFromChoresData(state, data) {
      state.fromChores = data
    },
    setSociallSkillsData(state, data) {
      state.sociallSkills = data
    },
    setLearningSkillsData(state, data) {
      state.learningSkills = data
    },

    setSevenPageData(state, data) {
      state.sevenPage = data
    },
    setEightPageData(state, data) {
      state.eightPage = data
    },
    setNinePageData(state, data) {
      state.ninePage = data
    },
    setTenPageData(state, data) {
      state.tenPage = data
    },
    setElevenPageData(state, data) {
      state.elevenPage = data
    },
    setTwelvePageData(state, data) {
      state.twelvePage = data
    },

    load(state) {
      state.loaded = true;
    },
    loadMatchedGames(state) {
      state.loadedMatchedGamesData = true;
    },
    loadFromChores(state) {
      state.loadedFromChores = true;
    },
    loadSociallSkills(state) {
      state.loadedSociallSkills = true;
    },
    loadLearningSkills(state) {
      state.loadedLearningSkills = true;
    },

    loadSevenPage(state) {
      state.loadedSevenPage = true;
    },
    loadEightPage(state) {
      state.loadEightPage = true;
    },
    loadNinePage(state) {
      state.loadNinePage = true;
    },
    loadTenPage(state) {
      state.loadTenPage = true;
    },
    loadElevenPage(state) {
      state.loadElevenPage = true;
    },
    loadTwelvePage(state) {
      state.loadTwelvePage = true;
    },
  },
  actions: {
    async updateFromChoresData({ commit }) {
      const sections = await GiftsAPI.fromChores()
        .then(res => res.data).catch(err=>console.log(err));
      commit('setFromChoresData', sections);
      commit('loadFromChores');
      return sections;
    },
    async updateMatchedGamesData({ commit }) {
      const sections = await GiftsAPI.matchedGames()
        .then(res => res.data).catch(err=>console.log(err));
      commit('setMatchedGamesData', sections);
      commit('loadMatchedGames');
      return sections;
    },
    async updateSociallSkillsData({ commit }) {
      const sections = await GiftsAPI.sociallSkills()
        .then(res => res.data).catch(err=>console.log(err));
      commit('setSociallSkillsData', sections);
      commit('loadSociallSkills');
      return sections;
    },
    async updateLearningSkillsData({ commit }) {
      const sections = await GiftsAPI.learningSkills()
        .then(res => res.data).catch(err=>console.log(err))
      commit('setLearningSkillsData', sections);
      commit('loadLearningSkills');
      return sections;
    },

    async updateSevenPageData({ commit }) {
      const sections = await GiftsAPI.sevenPage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setSevenPageData', sections);
        commit('loadSevenPage');
        return sections;
    },
    async updateEightPageData({ commit }) {
      const sections = await GiftsAPI.eightPage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setEightPageData', sections);
        commit('loadEightPage');
        return sections;
    },
    async updateNinePageData({ commit }) {
      const sections = await GiftsAPI.ninePage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setNinePageData', sections);
        commit('loadNinePage');
        return sections;
    },
    async updateTenPageData({ commit }) {
      const sections = await GiftsAPI.tenPage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setTenPageData', sections);
        commit('loadTenPage');
        return sections;
    },
    async updateElevenPageData({ commit }) {
      const sections = await GiftsAPI.elevenPage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setElevenPageData', sections);
        commit('loadElevenPage');
        return sections;
    },
    async updateTwelveData({ commit }) {
      const sections = await GiftsAPI.twelvePage()
        .then(res => res.data).catch(err=>console.log(err))
        commit('setTwelvePageData', sections);
        commit('loadTwelvePage');
        return sections;
    },

    async updateData({ commit }) {
      const sections = await GiftsAPI.educationCategories()
        .then(res => res.data).catch(err=>console.log(err));
      commit('setData', sections);
      commit('load');
      return sections;
    },

  },
  getters: {

    data(state) {
      return state.data;
    },
    dataMatchedGames(state) {
      return state.matchedGames;
    },
    dataFromChores(state) {
      return state.fromChores;
    },
    dataSociallSkills(state) {
      return state.sociallSkills;
    },
    dataLearningSkills(state) {
      return state.learningSkills;
    },

    dataSevenPage(state) {
      return state.sevenPage;
    },
    dataEightPage(state) {
      return state.eightPage;
    },
    dataNinePage(state) {
      return state.ninePage;
    },
    dataTenPage(state) {
      return state.tenPage;
    },
    dataElevenPage(state) {
      return state.elevenPage;
    },
    dataTwelvePage(state) {
      return state.twelvePage;
    },

    isLoaded(state) {
      return state.loaded;
    },
    isLoadedMatchedGames(state) {
      return state.loadedMatchedGamesData;
    },
    isLoadedFromChores(state) {
      return state.loadedFromChores;
    },
    isLoadedSociallSkills(state) {
      return state.loadedSociallSkills;
    },
    isLoadedLearningSkills(state) {
      return state.loadedLearningSkills;
    },

    isLoadedSevenPage(state) {
      return state.loadSevenPage;
    },
    isLoadedEightPage(state) {
      return state.loadEightPage;
    },
    isLoadedNinePage(state) {
      return state.loadNinePage;
    },
    isLoadedTenPage(state) {
      return state.loadTenPage;
    },
    isLoadedElevenPage(state) {
      return state.loadElevenPage;
    },
    isLoadedTwelvePage(state) {
      return state.loadTwelvePage;
    },

    getColorById: state => id => {
    return state.data.find(n => n.id == id).color;
    },
    getParentsColorById: state => id => {
      let color = state.data.find(n => n.id == id).colorParents;
      if(color=='')
      {
        color = state.data.find(n => n.id == id).color;
      }
      return color;

      },
      getSociallSkillsColorById: state => id => {
        let color = state.sociallSkills.find(n => n.id == id).color;
        if(color=='')
        {
          color = state.sociallSkills.find(n => n.id == id).color;
        }
        return color;
  
        },
        getLearningSkillsColorById: state => id => {
          let color = state.learningSkills.find(n => n.id == id).color;
          if(color=='')
          {
            color = state.learningSkills.find(n => n.id == id).color;
          }
          return color;
    
          },
      getMatchedGamesColorById: state => id => {
        let color = state.matchedGames.find(n => n.id == id).color;
        if(color=='')
        {
          color = state.matchedGames.find(n => n.id == id).color;
        }
        return color;
  
        },
        getFromChoresColorById: state => id => {
          let color = state.fromChores.find(n => n.id == id).color;
          if(color=='')
          {
            color = state.fromChores.find(n => n.id == id).color;
          }
          return color;
    
          },




          getSevenPageColorById: state => id => {
            let color = state.sevenPage.find(n => n.id == id).color;
            if(color=='')
            {
              color = state.sevenPage.find(n => n.id == id).color;
            }
            return color;
      
            },
            getEightPageColorById: state => id => {
              let color = state.eightPage.find(n => n.id == id).color;
              if(color=='')
              {
                color = state.eightPage.find(n => n.id == id).color;
              }
              return color;
        
              },
              getNinePageColorById: state => id => {
                let color = state.ninePage.find(n => n.id == id).color;
                if(color=='')
                {
                  color = state.ninePage.find(n => n.id == id).color;
                }
                return color;
          
                },
                getTenPageColorById: state => id => {
                  let color = state.tenPage.find(n => n.id == id).color;
                  if(color=='')
                  {
                    color = state.tenPage.find(n => n.id == id).color;
                  }
                  return color;
            
                  },
                  getElevenPageColorById: state => id => {
                    let color = state.elevenPage.find(n => n.id == id).color;
                    if(color=='')
                    {
                      color = state.elevenPage.find(n => n.id == id).color;
                    }
                    return color;
              
                    },
                    getTwelvePageColorById: state => id => {
                      let color = state.twelvePage.find(n => n.id == id).color;
                      if(color=='')
                      {
                        color = state.twelvePage.find(n => n.id == id).color;
                      }
                      return color;
                
                      },
    geTitleById: state => id => {
      return state.data.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.data.find(n => n.id == id).translation[0].data.name;
    },
    geTitleMatchedGamesById: state => id => {
      return state.matchedGames.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.matchedGames.find(n => n.id == id).translation[0].data.name;
    },
    geTitleFromChoresById: state => id => {
      return state.fromChores.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === store.getters['lang-store/data']
            }
      }).data.name || state.fromChores.find(n => n.id == id).translation[0].data.name;
    },
    geTitleSociallSkillsById: state => id => {
      return state.sociallSkills.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.sociallSkills.find(n => n.id == id).translation[0].data.name;
    },
    geTitleLearningSkillsById: state => id => {
      return state.learningSkills.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.learningSkills.find(n => n.id == id).translation[0].data.name;
    },

    geTitleSevenPageById: state => id => {
      return state.sevenPage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.sevenPage.find(n => n.id == id).translation[0].data.name;
    },
    geTitleEightPageById: state => id => {
      return state.eightPage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === store.getters['lang-store/data']
            }
      }).data.name || state.eightPage.find(n => n.id == id).translation[0].data.name;
    },
    geTitleNinePageById: state => id => {
      return state.ninePage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.ninePage.find(n => n.id == id).translation[0].data.name;
    },
    geTitleTenPageById: state => id => {
      return state.tenPage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === store.getters['lang-store/data']
            }
      }).data.name || state.tenPage.find(n => n.id == id).translation[0].data.name;
    },
    geTitleElevenPageById: state => id => {
      return state.elevenPage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name || state.elevenPage.find(n => n.id == id).translation[0].data.name;
    },
    geTitleTwelveById: state => id => {
      return state.twelvePage.find(n => n.id == id).translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === store.getters['lang-store/data']
            }
      }).data.name || state.twelvePage.find(n => n.id == id).translation[0].data.name;
    },

    
  }
};

export { EducationalSections };
