<template>
  <span class="n">
    {{translated!=='' && translated!=null ? translated : this.translate[0].data.name }}
  </span>
</template>

<script>
  export default {
    name: "NameTranslated",
    props:{
      translate: {}
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.translate.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.name
        }
      },
    }
  }
</script>

<style scoped>

</style>