<template>
  <div
    class="edit-field">
    <button @click.prevent="flag=!flag" class="edit-b">{{$ml.get('עריכה')}}</button>
    <img v-if="img" :src="user.img" alt="">
    <input
      :class="{'disabled': flag}"
      :style="img ? 'padding-right:50px' : 'padding-right:10px'"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      class="theme-input "
      >
  </div>
</template>

<script>
export default {
  name: "InputRegister",
  props:{
    value: {

    },
    img:{
      default: false,
    },
    placeholder:{
    default: '',
    },

  },
  computed:{
    user(){
      return this.$store.getters['user/data']
    }
  },
  data(){
    return{
      flag : true,
    }
  }
}
</script>

<style scoped lang="scss">
  //
:active, :hover, :focus {
 outline: 0;
 outline-offset: 0;
 border: none;
}
.edit-field{
  position: relative;
  .edit-b{
    position: absolute;
    left: 9px;
    top: 12px;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #149CC6;
    z-index: 1;
  }
  img{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    border-radius: 50%;
    top: 4px;
    z-index: 1;
  }
  .theme-input {
    border: none;
    border-radius: 10px;
    padding: 2px;
    height: 30px;
    width: 100%;
    padding-right: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    text-align: right;

    color: #00465B;
    &::placeholder{
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #A2A2A2;
    }
    &.disabled{
    pointer-events:none;
    opacity:0.8;
    }
  }
}
</style>