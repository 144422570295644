var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('simple-header',{attrs:{"inverse":""}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"fugaz"},[_vm._v("Playlist")]),_vm._v(" המשחקנים של")])]),_c('div',{staticClass:"sm-cont"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"top-cnt"},[_c('button',{staticClass:"schools",on:{"click":function($event){return _vm.$router.push('/schools')}}},[_vm._v(" בתי הספר המובילים ")]),_c('div',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],attrs:{"type":"text","placeholder":"שם המשתמש"},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing)return;_vm.query=$event.target.value},function($event){return _vm.sync(true)}]}}),_c('i',{staticClass:"icon ion-md-search"})])])]),_c('table',{attrs:{"dir":"rtl"}},[_vm._m(2),_vm._l((_vm.data),function(item){return _c('tr',[_c('td',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))]),_c('td',[_vm._v(_vm._s(item.age))]),_c('td',[(
            (item.points >= 25 &&
              Date.now() / 1000 > parseInt(item.date) + 2592000) ||
              item.had_donate
          )?_c('img',{attrs:{"src":require("@/assets/img/find-online.svg"),"alt":"He is prizer"}}):_c('img',{attrs:{"src":require("@/assets/img/find-online-empty.svg"),"alt":"He is not prizer"}})])])})],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"sub-title"},[_vm._v(" מתפרסמים כאן עם התואר משחקן "),_c('span',{staticClass:"fugaz"},[_vm._v("Playlist")]),_vm._v(" אחרי שמשחקים לפחות 25 משחקים ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"sub-sub-title"},[_vm._v(" ניתן להפוך לאלוף "),_c('span',{staticClass:"fugaz"},[_vm._v("Playlist")]),_vm._v(" אם מתקבלת הצעתכם למשחק חדש או לשיפור משחק, או אם אתם משחקים במשך 30 ימים במשחקי "),_c('span',{staticClass:"fugaz"},[_vm._v("Playlist")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"head"},[_c('td',[_vm._v("שם")]),_c('td',[_vm._v("גיל")]),_c('td',[_vm._v("אלופים")])])
}]

export { render, staticRenderFns }