<template>
 <div
   :class="{opened:open}"
   class="filter-search">
  <template>
   <div  class="small" dir="rtl">

    <div class="ability-wrapper"  >

     <div class="abilities">

      <CheckboxWrapper

        @input="check(selected.arr[0])"
        v-model="selected.section"
        :style="selected.section.includes(section.arr[2]) ? 'background: #FAFF00;border: 2px solid #FAFF00' : 'border: 2px solid #FAFF00' "
        :class="[selected.section.includes(section.arr[2]) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(section.arr[2])"
          class="slot-radio">
         <img src="@/assets/img/icons/childrens.svg" alt="">

         <div class="title">{{$ml.get('ילדים')}}</div>
        </div>
       </template>
      </CheckboxWrapper>



      <CheckboxWrapper

        @input="check(section.arr[1])"
        v-model="selected.section"
        :style="selected.section.includes(section.arr[1]) ? 'background: #FF7384;border: 2px solid #FF7384' : 'border: 2px solid #FF7384' "
        :class="[selected.section.includes(section.arr[1]) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(section.arr[1])"
          class="slot-radio">
         <img src="@/assets/img/icons/friends.svg" alt="">

         <div class="title">{{$ml.get('נוער ובוגרים')}}</div>
        </div>
       </template>
      </CheckboxWrapper>

      <CheckboxWrapper

        @input="check(section.arr[0])"
        v-model="selected.section"
        :style="selected.section.includes(section.arr[0]) ? 'background: #FFC700;border: 2px solid #FFC700' : 'border: 2px solid #FFC700' "
        :class="[selected.section.includes(section.arr[0]) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(section.arr[0])"
          class="slot-radio">
         <img src="@/assets/img/icons/family.svg" alt="">
         <div class="title">{{$ml.get('משפחה')}}</div>
        </div>
       </template>
      </CheckboxWrapper>

     </div>
    </div>
    <div class="level-wrapper ">

     <div class="level-title">{{$ml.get('משחקים אותם')}}: </div>
     <div class="levels-wrapper">
      <CheckboxWrapper
        v-model="selected.played"
        :class="[selected.played.includes(played.arr[0]) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(played.arr[0])"
          class="checkbox-slot">
         <div class="title">{{$ml.get('שחקתי')}}</div>
        </div>
       </template>
      </CheckboxWrapper>
      <CheckboxWrapper
        v-model="selected.played"
        :class="[selected.played.includes(played.arr[1]) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(played.arr[1])"
          class="checkbox-slot">

         <div class="title">{{$ml.get('לא שחקתי')}}</div>
        </div>
       </template>
      </CheckboxWrapper>
     </div>
    </div>
    <div v-if="selected.section.find((item=>item==='child'))" class="locations-wrapper">
     <div class="block-title"> {{$ml.get('מיקום וסיטואציה')}}: </div>
     <div class="title-m">{{$ml.get('ילדים')}}</div>
     <div :key="selected.section" class="locations">

      <CheckboxWrapper
         v-for="(item ) in $store.getters['playlist-locations/getByType']('child')"
        v-model="selected.locations"

        :class="[ selected.locations.find( (el) => el == item.id_setting)  ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(item.id_setting)"
          class="checkbox-slot">
         <img style="fill:#06364B!important;" :src="item.img" alt="">
         <div class="title">{{item.name}}</div>
        </div>
       </template>
      </CheckboxWrapper>

     </div>
    </div>

    <div v-if="selected.section.find((item=>item==='adult'))" class="locations-wrapper">

     <div class="title-m">{{$ml.get('נוער ובוגרים')}}</div>
     <div :key="selected.section" class="locations">

      <CheckboxWrapper
        v-for="(item ) in $store.getters['playlist-locations/getByType']('adult')"
        v-model="selected.locations"

        :class="[ selected.locations.find( (el) => el == item.id_setting)  ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(item.id_setting)"
          class="checkbox-slot">
         <img style="fill:#06364B!important;" :src="item.img" alt="">
         <div class="title">{{item.name}}</div>
        </div>
       </template>
      </CheckboxWrapper>

     </div>
    </div>

    <div v-if="selected.section.find((item=>item==='family'))" class="locations-wrapper">

     <div class="title-m"> {{$ml.get('משפחה')}}</div>
     <div :key="selected.section" class="locations">

      <CheckboxWrapper
       v-for="(item ) in $store.getters['playlist-locations/getByType']('family')"
        v-model="selected.locations"

        :class="[ selected.locations.find( (el) => el == item.id_setting)  ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(item.id_setting)"
          class="checkbox-slot">
         <img style="fill:#06364B!important;" :src="item.img" alt="">
         <div class="title">{{item.name}}</div>
        </div>
       </template>
      </CheckboxWrapper>

     </div>
    </div>

    <div style="margin-top: 5px" class="level-wrapper">

     <div class="level-title">{{$ml.get('רמת המשחק')}}: </div>
     <div class="levels-wrapper">
      <CheckboxWrapper
        v-for="(item) in levels.numbers"
        v-model="selected.levels"
        :class="[selected.levels.includes(item) ? 'active' : 'unactive' ]"
      >
       <template v-slot="{setValue}">
        <div
          @click="setValue(item)"
          class="checkbox-slot">
         <div class="title">{{levels.arr[item-1]}}</div>
        </div>
       </template>
      </CheckboxWrapper>
     </div>
    </div>
    <div  class="footer">


     <div class="info"> <span>{{$ml.get('באפשרותך להגדיר את הפרמטרים לחיפוש כקבועים בעמוד')}}</span><router-link :to="{name: 'playlist-options'}" class="blue"> {{$ml.get('הגדרות')}}  </router-link></div>
     <app-btn   :loading="loading" @click="update" class="save-b">{{$ml.get('שמור')}}</app-btn>

    </div>
   </div>
  </template>
 </div>
</template>
<script>
 import SimpleHeader from "@/components/SimpleHeader";
 import SidebarToggler from "@/components/SidebarToggler";
 import CheckboxWrapper from "@/components/CheckboxWrapper";
 import RadioWrapper from "@/components/playlist/playlist-options/RadioWrapper";
 import {PlaylistAPI} from "@/api";

 import { mapGetters } from 'vuex';
 import store from "../../store";
 export default {
  props:{
   open:{},
  },
  data() {
   return {
    index: 1,
    section : {
     arr: ['family', 'adult', 'child'],
    },
    played : {
     arr: ['Yes', 'No'],
    },
    selected:{
     section:['child'],
     played:['Yes', 'No'],
     locations: [],
     levels: [1,2,3],
    },
    activities : {
     arr:['family', 'adult', 'child'],
    },
    locations : {
     arr: [],
    },
    levels : {
     arr: [ 'בכיף', 'מאתגר ', 'מאתגר+' ],
     numbers: [1,2,3],
    },
    items: [],
    c: 0,
    start: '',
    loading: false,
   };
  },
  components: {
   SimpleHeader,
   SidebarToggler,
   CheckboxWrapper,
   RadioWrapper
  },
  computed: {
   ...mapGetters({
    settings: 'playlist/settings',
    locationsS : 'playlist-locations/data',
    }),
  },
  watch:{

  },
  methods:{
   check(section){
    if(!this.selected.section.includes(section))
    {
      let finished = [];
      let checked = [];
      this.$store.getters['playlist-locations/getByType'](section).map(item => {
      if(item.is_default==1)
      {
      checked.push(item.id_setting);
      return item.id_setting
      }
      });
      this.selected.locations.forEach(element=>{
        if(!checked.includes(element))
        {
          finished.push(element)
        }
      })
      this.selected.locations = finished;
    }
    else
    {
      let checked = [];

     this.$store.getters['playlist-locations/getByType'](section).map(item => {
      if(item.is_default==1)
      {
      checked.push(item.id_setting);
      return item.id_setting
      }
     });

  //  let c = this.selected.locations.filter(n => {
  //   return checked.indexOf(n) === -1
  //  });
    // this.selected.locations = c;
    this.selected.locations = this.selected.locations.concat(checked);
    }
   },
   update(){
    // let checked = [];
    // let sect = this.selected.section;
    // sect.forEach(item=> {
    //   this.$store.getters['playlist-locations/getByType'](item).map(item => {
    //   checked.push(item.id_setting);
    //   return item.id_setting
    //  });
    // });

    // this.selected.locations = this.unique(checked,this.selected.locations);
    this.$emit('input', this.selected);

   },
   unique(a1, a2) {
    return a1.filter(i=>!a2.includes(i))
      .concat(a2.filter(i=>!a1.includes(i)))
   },
   gifts(){
    this.$router.push({name : 'playlist-gifts'});
   }
  },
  created() {
  //  this.selected.locations =  this.$store.getters['playlist-locations/data'].map(item=>{return item.id_setting}) ;
   let arr  =  this.$store.getters['playlist-locations/data'].map(item=>{
      if(item.is_default==1 && item.type == 'child')
      {
        return item.id_setting;
      }
  });
  let finished = [];
  arr.forEach(element => {
    if(typeof element!='undefined' && element!=null)
    {
      finished.push(element)
    }
  })

  this.selected.locations = finished;
   if(Object.keys(this.$store.getters['search-store/data'].filter).length != 0){
    this.selected = this.$store.getters['search-store/data'].filter;
   }
  }
 };
</script>

<style scoped lang="scss">
 .filter-search{
  // max-height: calc(100vh - 145px);
  // min-height: calc(100vh - 145px);
  // overflow-y: auto;
    
  width: 400px;
  background: #F2F2F2;
  transition: all 0.3s ease-in-out;
  // top: -900px;

  // position: absolute;
  left: 0;
   z-index: 0;
   &.opened {
    // position: absolute;
    // top: 86px;
    overflow: auto;
    min-height: 383px;
    z-index:2;
   }
   .block-title {
    padding-right: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #4D4D4D;
   }
   .save-b {
    margin: 20px auto 10px;
    width: 180px;
    height: 36px;
   }
   .section-wrapper, .abilities {
    padding: 10px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    .radio-wrapper{
     z-index: 4;
     width: 114px;
     height: 96px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 4px;
     position: relative;
     .slot-radio{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .title{
       text-align: center;

       font-size: 14px;
      }
      img{
       width: 70px;
       height: 43px;
       margin-top: 16px;
      }
     }
     &.active{

      &:after{
       position: absolute;
       content: '';

       border: 1px solid #00465B;
       width: 12px;
       height: 12px;
       border-radius: 50%;
       top: 5px;
       right: 5px;
       background: white;
       z-index: -2;
      }
      &:before{
       position: absolute;
       content: '';
       background: #00465B;
       width:6px;
       height: 6px;
       border-radius: 50%;
       top: 8px;
       right: 8px;
       z-index: -1;
      }
     }
     &.unactive{
      &:after{
       top: 5px;
       right: 5px;
       position: absolute;
       content: '';
       background: #FFFFFF;
       border: 1px solid #00465B;
       width: 12px;
       height: 12px;
       border-radius: 50%;
       z-index: -2;
      }
     }
    }
   }
   .ability-wrapper {
    img {

     @media screen and (min-width: 375px) {
      height: 61px;
      width: 65%;
     }
    }
    .abilities{
    //  padding-top: 4px;
     padding-bottom: 15px;
     .checkbox-wrapper{
      z-index: 4;
      width: 114px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      position: relative;
      .slot-radio{
       align-items: center;
       justify-content: center;
       position: relative;
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       .title{
        text-align: center;

        font-size: 14px;
       }
       img{
        width: 70px;
        height: 43px;
        margin-top: 16px;
       }
      }
      &.active{
       &:after{
        position: absolute;
        content: '';

        background: white;
        border: 1px solid #00465B;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        z-index: 1;
       }
       &:before{
        position: absolute;
        content: '';
        height: 3px;
        z-index: 2;
        width: 6px;
        border-left: 1px solid #00465B;
        border-bottom: 1px solid #00465B;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 9px;
        right: 8px;
       }
      }
      &.unactive{
       &:after{
        top: 5px;
        right: 5px;
        position: absolute;
        content: '';
        background: #FFFFFF;
        border: 1px solid #00465B;
        width: 12px;
        height: 12px;
        border-radius: 50%;

       }
      }
     }
    }
    .title {
     margin-top: unset !important;
    }
   }

 }

 .locations-wrapper {
  .title-m{
   padding-right: 15px;
   font-style: normal;
   font-weight: normal;
   font-size: 10px;
   line-height: 14px;
   text-align: right;
   color: #4D4D4D;
  }
  .locations {
   padding: 0 7px 10px 7px;
   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: wrap;
   .checkbox-wrapper{
    display: inline-block;
    width: 67px;
    height: 67px;
    position: relative;
    margin: 3px;
    background: #3FBCD4;
    border-radius: 4px;
    .checkbox-slot{
     position: relative;
     z-index: 5;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     .title{
      @media screen and (min-width: 375px){
       font-size: 13px;
      }
      word-wrap: break-word;
      // margin: 5px 0;

      font-size: 12px;
      line-height: 1;
      min-height: 20px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: #06364B;
     }
    }
    &.active{
     border: 1px solid #3FBCD4;
     background:#3FBCD4 ;
     &:after{
      position: absolute;
      content: '';
      z-index: 0;
      border: 1px solid #00465B;
      background: #FFFFFF;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 5px;
      right: 5px;

     }
     &:before{
      position: absolute;
      content: '';
      z-index: 1;
      height: 3px;
      width: 6px;
      background: #FFFFFF;
      border-left: 1px solid #00465B;
      border-bottom: 1px solid #00465B;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 9px;
      right: 8px;
     }
    }
    &.unactive{
     border: 1px solid #3FBCD4;
     background: white;
     &:after{
      top: 5px;
      right: 5px;
      position: absolute;
      content: '';
      background: #FFFFFF;
      border: 1px solid #00465B;
      width: 12px;
      height: 12px;
      z-index: 0;
      border-radius: 50%;
     }
    }
   }
   img {
    // margin-top: 15px;
    max-width: 30px;
    height: 30px;
   }

  }
 }

 .gifts-wrapper {
  padding: 5px 10px 10px 10px;

  .gifts-title {

   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   color: #4D4D4D;
   padding-bottom: 5px;
  }
  .gifts-buttons{
   display: flex;
   flex-direction: row;
   button{
    padding: 10px 16px;
    margin-left: 5px;
    border-radius: 4px;
    font-size: 12px;
    background: #1A92B8;

    height: 38px;
    svg{
     margin-left: 10px;
    }
   }
  }
 }

 .level-wrapper {
  padding: 10px;
  padding-top: 0;
  padding-bottom: 15px;

  .level-title {
   font-style: normal;
   font-weight: normal;
   font-size: 12px;
   line-height: 16px;
   color: #4D4D4D;
  }

  .levels-wrapper {

   display: flex;
   flex-direction: row;
   align-items: center;
   flex-wrap: wrap;

   margin-top: 5px;

   .checkbox-wrapper {
    min-width: 72px;
    height: 28px;
    background: #A9DDF3;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    margin-left: 6px;

    font-size: 12px;
    text-align: center;
    color: #06364B;
    position: relative;
    .checkbox-slot{
     z-index: 4;
     position: relative;
     width: 100%;
     height: 100%;
     /* top: -3px; */
     /* right: 6px; */
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     .title{
      padding: 6px 0 5px 14px;
      margin-right: 24px;
      color: #06364B;
     }
    }
    &.active{
     border: 1px solid #A9DDF3;
     background: #A9DDF3;
     &:after{
      position: absolute;
      content: '';
      z-index: 1;
      border: 1px solid #00465B;
      background: #FFFFFF;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 7px;
      right: 5px;

     }
     &:before{
      position: absolute;
      content: '';
      z-index: 2;
      height: 3px;
      width: 6px;
      background: #FFFFFF;
      border-left: 1px solid #00465B;
      border-bottom: 1px solid #00465B;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 11px;
      right: 8px;
     }
    }
    &.unactive{
     border: 1px solid #A9DDF3;
     background: white;
     &:after{
      top: 8px;
      right: 5px;
      position: absolute;
      content: '';
      background: #FFFFFF;
      border: 1px solid #00465B;
      width: 12px;
      height: 12px;
      border-radius: 50%;
     }
    }
   }
  }
 }


 .small {
  
  max-width: 387px;
  @media screen and (max-width: 390px) {
   width: 100%;
  }
  position:relative;
  margin: 0 auto;
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 20px;
 }
 .footer{
  width: 100%;
  padding: 10px 0;
  .save-b{

  }
  .info{
   display: flex;
   align-items: center;
   justify-content: center;
    span{
     font-style: italic;
     font-weight: normal;
     font-size: 12px;
     line-height: 16px;
     text-align: center;
     color: #4D4D4D;

    }
   .blue{
    margin-right: 3px;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #1A92B8;
   }
  }
 }
 .app-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
 }
</style>