<template>
  <div
    style="
      width: 100%;
      background: #ddf0f5;
      height: 100%;
      border-radius: 6px;
      padding-top: 10px;
    "
  >
    <div style="width: 100%; text-align: center">
      {{ $ml.get("הזן נתונים לשליחה") }}
    </div>
    <div class="email-input">
      <v-text-field
        label="אימייל"
        class="test"
        v-model="email"
        autocomplete="new-password"
        @input="validateEmail"
      ></v-text-field>
    </div>
    <!-- <div
        style="width: 300px; height: 20px; margin: auto"
        class="email-alert"
        
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
        >
          <g id="surface1">
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(84.313725%, 15.686275%, 15.686275%);
                fill-opacity: 1;
              "
              d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
            />
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(90.196078%, 90.196078%, 90.196078%);
                fill-opacity: 1;
              "
              d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
            />
          </g>
        </svg>
        <span style="position: absolute; top: -5px; right: 20px">{{
          $ml.get("האימייל שגוי")
        }}</span>
      </div> -->
    <div class="phone-block">
      <vue-tel-input
        class="vue-tel-input some"
        id="phone-input"
        v-model="phone"
        v-bind="bindProps"
        @validate="validPhone"
        @input="clearValidation"
      ></vue-tel-input>
    </div>
    <div
      v-if="phoneError"
      style="width: 300px; height: 20px; margin: auto"
      class="email-alert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
      >
        <g id="surface1">
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(84.313725%, 15.686275%, 15.686275%);
              fill-opacity: 1;
            "
            d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
          />
          <path
            style="
              stroke: none;
              fill-rule: nonzero;
              fill: rgb(90.196078%, 90.196078%, 90.196078%);
              fill-opacity: 1;
            "
            d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
          />
        </g>
      </svg>
      <span style="position: absolute; top: -5px; right: 20px">{{
        $ml.get("מספר הטלפון שגוי")
      }}</span>
    </div>
    <div style="margin-top: 10px">
      <app-btn
        :loading="loadingSend"
        :class="disabled == true ? 'active' : 'disabled'"
        @click="send"
        class="reg-b"
      >
        {{ $ml.get("לִשְׁלוֹחַ") }}
      </app-btn>
    </div>
  </div>
</template>

<script>
import { SubscribeAPI } from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loadingSend: false,
      email: null,
      phone: null,
      timerPhone: null,
      phoneError: false,
      phoneVerified: false,
      bindProps: {
        autoFormat: true,
        autoDefaultCountry: false,
        defaultCountry: "IL",
        validCharactersOnly: true,
        mode: "international",
        disabled: false,
        preferredCountries: ["IL", "US", "UA"],
        dropdownOptions: {
          showDialCodeInList: true,
          showFlags: true,
          width: "250px",
        },
        inputOptions: {
          showDialCode: true,
          autocomplete: "off",
          placeholder: "הזן מספר טלפון",
          required: true,
          type: "tel",
          name: "phone",
          maxLen: 25,
        },
      },
    };
  },
  props: {
    sender: null,
    reciver: null,
    cause: null,
  },
  computed: {
    ...mapGetters({
      promo: "promo-store/data",
      prices: "user/getPrices",
      settingsPlaylist: "playlist/settings",
      settingsShootlist: "shootlist/settings",
      email: "user/getEmail",
    }),
    disabled() {
      if ((this.email != null && this.email != "") || this.phoneVerified) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    send() {
      this.loadingSend = true;
      SubscribeAPI.sendCode({
        sender: this.sender,
        reciver: this.reciver,
        cause: this.cause,
        email: this.email,
        phone: this.phone,
        promo: this.promo,
      })
        .then((res) => {
          this.$notify({
            title: "אִשׁוּר",
            text: "המתנה נשלחה בהצלחה",
            type: "success",
            duration: 5000,
          });
          this.$emit("close");
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingSend = false;
          this.$emit("close");
        });
    },
    validateEmail() {},
    validPhone(event) {
      this.phoneError = false;
      this.phoneVerified = false;
      clearTimeout(this.timerPhone);
      this.timerPhone = setTimeout(() => {
        if (!event.valid && this.phone != "+972") {
          this.phoneVerified = false;
          this.phoneError = true;
        } else if (event.valid) {
          this.phoneVerified = true;
          this.phoneError = false;
        }
      }, 100);
    },
    clearValidation() {},
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.reg-b {
  margin-left: auto;
  margin-right: auto;
  width: 304px !important;
  height: 54px !important;
  color: black;
  background: #fdc671 !important;
  border-radius: 30px;
  &.disabled {
    background: #c4c4c4 !important;
    color: #4d4d4d !important;
  }
}
.vue-tel-input ::v-deep .vti__dropdown {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 10px 20px 13px;
  margin-right: 10px;
}
.vue-tel-input ::v-deep .vti__input {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 20px 20px 27px;
  margin-right: 10px;
  width: 214px !important;
}

.vue-tel-input ::v-deep .vti__flag {
  scale: 1.2;
}
.vue-tel-input ::v-deep .vti__dropdown-arrow {
  margin-left: 10px;
}

.vue-tel-input {
  box-shadow: none;
}
.vue-tel-input {
  box-shadow: none;
}
.phone-block {
  direction: ltr;
  padding: 1px 17px 0px 12px;
  margin: auto;
  height: 66px !important;
  position: relative;
  width: 300px !important;
  // margin-top: 27px;
  margin-bottom: 10px;
  border-radius: 4px;
  .vue-tel-input {
    // margin: auto;
    position: absolute;
    left: -1%;
    border-style: none !important;
  }
}
.email-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: black;

  svg {
    position: absolute;
    top: -2px;
    right: 3px;
  }
}
.email-input:hover {
  border: 2px solid #fdc671;
}

.email-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.test ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
}

.test ::v-deep .v-input__slot::before {
  border-style: none;
}

.test ::v-deep .v-input__icon .mdi-check {
  color: #8cc63f;
}

.test ::v-deep .v-input__icon .mdi-close {
  color: #e41e1e;
}
.test ::v-deep .v-input__slot::after {
  border-style: none;
}

.test ::v-deep .v-label {
  margin-top: 2px;
}

.test ::v-deep .v-text-field__slot input {
  padding-top: 12px;
}

.test {
  margin: 0;
}
</style>
