<template lang="html">
  <transition name="fade-anim">
    <modal v-if="$store.getters['modals/confirm/isOpened']" @close="close">
      <div class="th-modal container">
        <div class=" title">
          {{$ml.get('האם אתה בטוח שאתה מעוניין')}}
          {{$ml.get('לבטל את המנוי')}}?
        </div>
       <div class="btn-w">
         <app-btn   @click="close" class="save-b no">{{$ml.get('ביטול')}} </app-btn>
         <app-btn :loading="load"   @click="cancel" class="save-b yes">{{$ml.get('אישור')}} </app-btn>
       </div>

      </div>
    </modal>
  </transition>
</template>

<script>
import Modal from './Modal'
import ModalClose from './ModalClose'
import { SubscribeAPI } from '@/api';

export default {
  components: {
    Modal,
    ModalClose,
  },
  data(){
    return{
      load: false,
    }
  },
  methods: {
    close() {
      this.$store.commit('modals/confirm/close');
    },
    cancel(){
      this.load =true;
      SubscribeAPI.cancel().then(() => {
        this.$store.dispatch("user/updateData").then(()=>{
          this.load =false;
        this.$store.dispatch("user/updateData");
        this.close();
        this.$router.push("/main");
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.modal {
  &.fade-anim {
    &-enter {
      .container {
        transform: translateY(-50px);
      }
    }
    &-enter-to {
      .container {
        transform: translateY(0);
      }
    }

    &-leave {
      .container {
        transform: translateY(0);
      }
    }
    &-leave-to {
      .container {
        transform: translateY(-50px);
      }
    }

    &-leave-active,
    &-enter-active {
      .container {
        transition: transform 0.3s ease;
      }
    }
  }
}

.container {
  max-width: 325px;
  width: 310px;

  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;
  border-radius: 10px;
  @media screen and (max-width: 360px){
    width: 260px;
  }
  .title {
    padding: 42px 35px 0 35px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    width: 270px;
    margin: 0 auto;
  }

  .text{

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }
  .btn-w{
    margin-top: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .save-b{
    margin: 20px auto;
    width: 120px;
    height: 36px;
    font-weight: 500;
    font-size: 15px;
    &.no{

    }
    &.yes{
      background: #FFFFFF;
      border: 1px solid #005D79;
      box-sizing: border-box;
      border-radius: 18px;
      color: #005D79;
    }
  }
}

</style>
