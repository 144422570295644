<template>
  <div class="faq-element">
    <button class="show-b" @click="flag=!flag">
      <span      class="icon">
        <svg :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div class="terms">

         <span>{{$ml.get('סך המשחקים ששוחקו')}}:</span> <span>{{total}}</span>
      </div>

      <button @click="flag=!flag" > {{$ml.get(' שעת חינוך')}}</button>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >
        <EducationalClassesBlock
          :educationClasses="popular"
          :key="1"
          />
        <EducationalClassesBlock

          :educationClasses="known"
          :key="2"
          />
      </div>
    </transition>
  </div>
</template>
<script>
 import EducationalClassesBlock from "./EducationalClassesBlock";
  export default {
    props:{
      open:{
        default:false,
      },
      goals:{

      },
      educationClass:{},
    },
    computed:{
      popular(){
        return this.educationClass.filter(item=>{
          return item.section === 'popular';
        })
      },
      total(){
        return this.educationClass.reduce((sum,current)=>{
          return sum+current.stats.played
        },0)
      },
      known(){
        return this.educationClass.filter(item=>{
          return item.section === 'known';
        })
      }
    },
    components:{
    EducationalClassesBlock,
    },
    data() {
      return {
        flag: false,
      }
    },
    created() {
      this.flag = this.open;
    }
  }
</script>

<style lang="scss" scoped>
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .faq-element{


    position: relative;
    margin: 2px 0;



    transition: all .4s ease-in-out;
    color: #828282;
    .question{
      background: #AAD9E7;
      padding: 15px 41px 15px 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #06364B;
      .terms{
        left: 30px;
        position: absolute;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: right;
        color: #FFFFFF;

      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        width: 88%;
        text-align: start;
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: 9px;
      top: 5px;
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      background: white;
      transition: all 0.4s ease-in-out;
      .footer{
        padding: 0 20px 20px 0;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name{
          font-weight: bold;
          font-size: 14px;
          text-align: right;
          color: #00465B;
        }
        .info{
          display: flex;
          margin-left: 20px;
        }
      }
        .text{
          width: 180px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

        }
      }
    }
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

