import { ShootlistAPI } from '@/api';
import { DefaultShootlistSettings } from './default-shootlist-settings.dto';

export default {
  namespaced: true,
  state: {
    data: [],
    played: [],
    order_index: 1,
    settings: {},
    isSubed:false,
    count: 1,
    random: false,
    defaultShootlistPassed:0
  },
  mutations: {
    clearStorage(state){
      localStorage.removeItem('shootlists-played');
    },
    setSubed(state)
    {
      state.isSubed = true;
    },
    setUnSubed(state)
    {
      state.isSubed = false;
    },
    increaseShootlistPassedCounter(state)
    {
      state.defaultShootlistPassed ++;
    },
    resetShootlistPassedCounter(state)
    {
      state.defaultShootlistPassed = 0;
    },
    reset(state) {
      state.data = [];
      state.order_index = 1;
      state.settings = [];

      state.count = 1;
       state.random = false;
    },
    resetSettings(state) {
      state.settings = [];
    },
    setViewed(state, data) {
      if(!state.isSubed)
      {
      let newArray = [];
      data.forEach(element => {
        if(!newArray.includes(element.toString()))
        {
          newArray.push(element.toString())
        }
      });
      state.settings.viewed_shootlist = newArray;
      }
      else
      {
        state.settings.viewed_shootlist = data;
      }
    },
    pushViewed(state, data) {
      state.settings.viewed_shootlist.push(data);
      if(!state.isSubed)
      {
      let array = localStorage.getItem('shootlists-played');
      if(!array)
      {
        let newArray = [];
        newArray.push(data);
        localStorage.setItem('shootlists-played',newArray);
      }
      else
      {
        let existsArray = localStorage.getItem('shootlists-played').split(',');
        if(!existsArray.includes(data.toString()))
        {
          existsArray.push(data);
          localStorage.setItem('shootlists-played',existsArray);
        }
      }
      }
    },
    resetGames(state) {
      state.data = [];
      state.played = [];
      state.order_index = 1;
    },

    deleteLastCard(state) {
      const _length = state.data.length;
      const item = state.data[_length - 1];
      state.data = state.data.filter((n, i) => i !== (_length - 1));

      state.played.push({
        ...item,
        played: true,
      });
    },

    incrementOrderIndex(state) {
      state.order_index++;
    },

    setOrderIndex(state, index) {
      state.order_index = index;
    },

    setSettings(state, data) {
      state.settings = data;
    },

    setCategories(state, data) {
      state.settings.categories = data;
    },

    setRandomMode(state, mode) {
      state.random = mode;
    },

    setCardsCount(state, count) {
      state.count = count;
    },

    addCard(state, data) {
      state.data.unshift({
        ...data,
        order_index: state.order_index,
        _uuid: Math.UUID(),
      });
    },
  },
  actions: {
    setDefaultSettings({  commit }) {
      const settings = new DefaultShootlistSettings();
      commit('setSettings', settings);
    },
    throwActivity({ getters, state }, type) {
      if(getters.currentItem.id_shootlist != undefined){
      return ShootlistAPI.addActivity(getters.currentItem.id_shootlist, type, {
        filters: state.settings,
        index: state.order_index,
      }).then(()=>{
        return true;
      }).catch(()=>{
        return false;
      });
    }
    },
    getState({ state, commit }) {
      return ShootlistAPI.getState(state.settings).then(res => {
        let data =  res.data!=='' ?  res.data.filter :  {};
        const settings = new DefaultShootlistSettings();
        state.settings = {...settings,...data};
        const index = res.data.order_index;
        if(!state.isSubed)
        {
          let array = localStorage.getItem('shootlists-played');
          if(array)
          {
            state.settings.viewed_shootlist = array.split(',');
          }
        }
        else
        {
          localStorage.removeItem('shootlists-played');
        }
        if (index && index > 1) {
          commit('setOrderIndex', index);
        } else {
          commit('setOrderIndex', 1);
        }
      })
    },
    // add new card
    async getRandom({ state, commit }, data) {
      data = {
        ...data,
        ...state.settings, 
        order_index: state.order_index,
      };
      return ShootlistAPI.getRandomGame(data)
        .then(res => {
          commit('addCard', res.data.result);
          commit('setRandomMode', res.data.meta.random);
          commit('setCardsCount', res.data.meta.count);
          commit('setViewed', res.data.meta.viewed_shootlist);
          commit('incrementOrderIndex');
          return res.data.result;
        })
        .catch(err => {
          if (err.response.status == 402) {
            commit('addCard', { state: "give-money" });
          }
          if (err.response.status == 400) {
            commit('addCard', { state: "give-money" });
          }
          if (err.response.status == 500) {
            commit('addCard', { state: "give-money" });
          }
        })
    }

    // remove last
  },
  getters: {
    getDefaultShootlistPassed(state)
    {
      return state.defaultShootlistPassed;
    },
    data(state) {
      return state.data;
    },
    orderIndex(state) {
      return state.order_index;
    },
    settings(state) {
      return state.settings;
    },
    isSettings(state){
      return Object.keys(state.settings).length > 1 && state.settings.constructor === Object
    },
    count(state) {
      return state.count;
    },
    random(state) {
      return state.random;
    },
    currentItem(state) {
      return state.data[state.data.length - 1];
    },
  }
};
