  <template>
  <div class="modal" dir="rtl">
    <div class="header">
      <div class="num">
        <!-- {{ $store.getters["user/data"].subscribe_total_games }} /
        {{
          $store.getters["user/data"].subscribe_total_games  
        }} -->
      </div>

      <div class="text">
        {{$ml.get('עד כה צפית ב')}}:
      </div>
    </div>
    <div class="main">

      <div class="head">
        {{$ml.get('על מנת לקבל גישה ליותר משחקים נדרש לרכוש מנוי המנוי מעניק גם')}}:
      </div>
      <div class="content">
        <div class="title"></div>
        <div class="items">
          <div class="item">
            <div class="img img-subscribe-1"></div>
            <div class="text">
              {{$ml.get('ש”ח שובר')}} 200  <br />
              {{$ml.get('לסדנת משחקים')}}
            </div>
          </div>

          <!-- <div class="item">
            <div class="img img-subscribe-2"></div>
            <div class="text">
              צמיד משחקים
            </div>
          </div> -->

          <div class="item">
            <div class="img img-subscribe-5"></div>

            <div class="text">
              {{$ml.get('גישה חופשית')}} <br />
              <!-- ל- <span class="fugaz">Shootlist</span> <br /> -->
              {{$ml.get(' לשיח משפחתי')}}
            </div>
          </div>

          <div class="item">
            <div class="img img-subscribe-6"></div>
            <div class="text">
              {{$ml.get('גישה חופשית למדברים בכיף')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="end">
      
      <button @click="go" class="theme-button-outline">{{$ml.get('רוצה מנוי')}}</button>
      <button @click="$emit('close')" class="no">{{$ml.get('לא תודה')}}</button>
    </div>
  </div>
</template>

<script>
import { SubscribeAPI } from '@/api';
import Payment from "@/components/modals/Payment";

  export default {
  props: {},
  data() {
    return {
      points: 0
    };
  },
  mounted(){
    
  },
  methods: {
    sync() {
      axios.get("/user/playlist/done").then(res => {
        this.points = res.points;
      });
      this.$router.push({name: 'main'});
      this.$store.dispatch("user/updateData");
      this.$store.commit('playlist/resetGames');
      
    },
    go() {
      this.$emit("close");
      this.$router.push({name: 'subsPlans'});
    },
    async subscribeSched() {
      let successfulPage=window.location.origin+'/#/successfulPayment';
      let errorPage=window.location.origin+'/#/errorPayment';
      const data = await SubscribeAPI.subscribe(successfulPage,errorPage)
          .then(res => res.data);

      this.$modal.show(
          Payment,
          {
            url: data.url,
            id_transaction: data.id_transaction,
            type: 'subs',
            ended: () => {
              this.$store.commit("playlist/reset");
              this.$store.dispatch("user/updateData");
              this.$router.replace("/account");
            }
          },
          {
            adaptive: true,
            height: "90%",
            width: "90%"
          }
      );
    },
    close() {}
  },
  created() {
    this.sync();
  }
};
</script>

<style lang="scss" scoped>
.modal {
  .header {
    padding: 20px;
    color: #f18171;
    position: relative;
    .num {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 37px;
      line-height: 100%;
    }
    .text {
      font-size: 27px;
    }
  }
  .main {
    padding: 0 20px;
    .head {
      font-size: 20px;
      padding-bottom: 10px;
    }
    .content {
      border-top: 1px solid #eb5757;
      padding-top: 10px;
      .title {
        font-size: 17px;
      }
      .items {
        padding-top: 10px;
        .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          min-height: 65px;
          padding: 10px 0;
          &:nth-child(1) .img {
            background-color: #87eca3;
          }
          &:nth-child(2) .img {
            background-color: #d2ec87;
          }
          &:nth-child(3) .img {
            background-color: #f5ed34;
          }
          &:nth-child(4) .img {
            background-color: #f18171;
          }
          &:nth-child(5) .img {
            background-color: #87d4ec;
          }
          &:nth-child(6) .img {
            background-color: #b987ec;
          }
          .img {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
          }
          .text {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .end {
    padding: 20px;
    button {
      display: block;
      margin: 0 auto;
      width: 200px;
      color: #005d7a;
      &.theme-button-outline {
        border-color: #eb5757;
        color: #005d7a;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
