<template>
  <div class="fullname">
    <div class="title">
      השם שלי
    </div>
    <app-text-input label="השם שלי" v-model="fullName" />
    <app-btn :loading="loading" @click="update">
      המשך
    </app-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: "",
      loading: false,
    };
  },
  computed: {
    fullNameData() {
      if (!this.fullName) {
        return null;
      }

      let arr = this.fullName.split(" ");

      return {
        name: arr[0],
        surname: arr[1] || null
      };
    }
  },
  methods: {
    update() {
      if (this.fullNameData) {
        this.loading = true;
        axios.put("/user/update", this.fullNameData).then(res => {
          this.$router.replace('code');
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.fullname {

  display: flex;
  flex-direction: column;
  align-content: stretch;

  background: #56bcdc;
  border-radius: 10px;
  padding: 20px;

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
  }

  .app-text-input {
    margin-bottom: 20px;
  }
}
</style>
