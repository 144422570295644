<template>
  <div class="main-header">
    <div class="wrapper" :class="{ 'sm-cont': !$env.CORDOVA }">
      <div
        v-if="main && !isDefaultUser"
        style="border-radius:14px; max-height:30px; min-width: 127px;background-color:rgba(6, 54, 75, 0.5); padding:0px 37px 0px 7px; position:absolute; left: 33px; "
      >
        <router-link class="title-link-rtl" :to="{ name: 'playlist-gifts' }">
          <div style="height: 30px; position: relative;">
            <span class="router-title">{{ $ml.get("מסלול אישי") }}</span>
            <!-- <svg class="router-svg" width="21" height="21" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.24 0C9.07984 0 0 9.07984 0 20.24C0 31.4002 9.07984 40.48 20.24 40.48C31.4002 40.48 40.48 31.4002 40.48 20.24C40.48 16.1946 39.2829 12.4251 37.2298 9.25891L36.4444 10.0444L35.9425 10.5463C37.6919 13.3684 38.72 16.6822 38.72 20.24C38.72 30.4304 30.4304 38.72 20.24 38.72C10.0496 38.72 1.76 30.4304 1.76 20.24C1.76 10.0496 10.0496 1.76 20.24 1.76C24.5637 1.76 28.5299 3.27135 31.68 5.76984V7.55563L29.5367 9.69891C27.0548 7.50411 23.8057 6.16 20.24 6.16C12.4766 6.16 6.16 12.4766 6.16 20.24C6.16 28.0034 12.4766 34.32 20.24 34.32C28.0034 34.32 34.32 28.0034 34.32 20.24C34.32 17.3105 33.4185 14.5875 31.8811 12.332L30.6058 13.6073C31.836 15.5249 32.56 17.7971 32.56 20.24C32.56 27.0336 27.0336 32.56 20.24 32.56C13.4464 32.56 7.92 27.0336 7.92 20.24C7.92 13.4464 13.4464 7.92 20.24 7.92C23.3211 7.92 26.1317 9.06553 28.2923 10.9433L25.1711 14.0645C23.8171 12.9785 22.107 12.32 20.24 12.32C15.8734 12.32 12.32 15.8734 12.32 20.24C12.32 24.6066 15.8734 28.16 20.24 28.16C24.6066 28.16 28.16 24.6066 28.16 20.24C28.16 19.0194 27.8742 17.8683 27.3797 16.8352L26.0373 18.1758C26.2679 18.8217 26.4 19.5158 26.4 20.24C26.4 23.6368 23.6368 26.4 20.24 26.4C16.8432 26.4 14.08 23.6368 14.08 20.24C14.08 16.8432 16.8432 14.08 20.24 14.08C21.6216 14.08 22.8933 14.5429 23.9216 15.3141L20.6938 18.5419C20.5458 18.5016 20.3933 18.4808 20.24 18.48C19.7732 18.48 19.3256 18.6654 18.9955 18.9955C18.6654 19.3256 18.48 19.7732 18.48 20.24C18.48 20.7068 18.6654 21.1544 18.9955 21.4845C19.3256 21.8146 19.7732 22 20.24 22C20.7068 22 21.1544 21.8146 21.4845 21.4845C21.8146 21.1544 22 20.7068 22 20.24C21.9999 20.0862 21.9797 19.9331 21.9398 19.7845L32.9244 8.8H35.2L39.6 4.4H36.08V0.88L32.6717 4.28828C29.2379 1.60542 24.9254 0 20.24 0Z" fill="#fff"/>
            </svg> -->
            <svg class="router-svg" width="22" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5.14258C4.02891 5.14258 -8.80545e-08 9.17148 -1.96701e-07 14.1426C-3.05348e-07 19.1137 4.02891 23.1426 9 23.1426C13.9711 23.1426 18 19.1137 18 14.1426C18 12.4234 17.5148 10.8133 16.6781 9.4457L15.3562 10.7957C15.8906 11.8012 16.2 12.9262 16.2 14.1426C16.2 18.1187 12.9762 21.3426 9 21.3426C5.02383 21.3426 1.8 18.1187 1.8 14.1426C1.8 10.1664 5.02383 6.94258 9 6.94258C10.227 6.94258 11.366 7.27305 12.375 7.81445L13.6969 6.49258C12.3258 5.64883 10.7297 5.14258 9 5.14258ZM16.65 5.22695L9.45 12.3988C9.30234 12.3602 9.16172 12.3426 9 12.3426C8.00508 12.3426 7.2 13.1477 7.2 14.1426C7.2 15.1375 8.00508 15.9426 9 15.9426C9.99492 15.9426 10.8 15.1375 10.8 14.1426C10.8 13.9809 10.7824 13.8402 10.7437 13.6926L17.9156 6.49258L16.65 5.22695ZM9 8.74258C6.01875 8.74258 3.6 11.1613 3.6 14.1426C3.6 17.1238 6.01875 19.5426 9 19.5426C11.9812 19.5426 14.4 17.1238 14.4 14.1426C14.4 13.4289 14.2594 12.768 14.0062 12.1457L12.5437 13.5801C12.5754 13.7594 12.6 13.9527 12.6 14.1426C12.6 16.1324 10.9898 17.7426 9 17.7426C7.01016 17.7426 5.4 16.1324 5.4 14.1426C5.4 12.1527 7.01016 10.5426 9 10.5426C9.20039 10.5426 9.40078 10.5637 9.59062 10.5988L11.025 9.16445C10.3957 8.9043 9.72422 8.74258 9 8.74258Z" fill="white"/>
                  <path d="M22.1428 5.24241H17.9002L15.7788 7.36373H20.0215L22.1428 5.24241Z" fill="white" stroke="white" stroke-linejoin="round"/>
                  <path d="M17.9002 0.999773V5.24241L15.7788 7.36373V3.12109L17.9002 0.999773Z" fill="white" stroke="white" stroke-linejoin="round"/>
                  </svg>
          </div>
        </router-link>
      </div>
      <SidebarToggler v-if="sidebar" />
      <MainSchool
        v-if="main && $store.getters['user/data'].id_school !== null"
        class="fixed"
      />
      <MainNonSchool v-else-if="main" />
    </div>
    <!-- <div
      v-if="sidebar"
      class="main-playlist"
      style="text-align:center;margin-top: 43px; margin-bottom: -23px;"
    >
      <svg
        width="140"
        height="45"
        viewBox="0 0 140 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M82.9191 43.8726C81.9844 43.8726 81.2453 43.605 80.7019 43.0699C80.1584 42.5348 79.8867 41.8071 79.8867 40.8867V24.2881C80.1258 24.2453 80.5062 24.1918 81.0279 24.1276C81.5496 24.042 82.0496 23.9992 82.5278 23.9992C83.0278 23.9992 83.4625 24.042 83.832 24.1276C84.2233 24.1918 84.5494 24.3202 84.8102 24.5128C85.0711 24.7055 85.2667 24.973 85.3971 25.3155C85.5275 25.6579 85.5928 26.1074 85.5928 26.6639V39.3457H93.0595C93.2117 39.5811 93.353 39.9022 93.4834 40.3088C93.6138 40.6941 93.679 41.1008 93.679 41.5288C93.679 42.385 93.4942 42.995 93.1247 43.3589C92.7552 43.7013 92.2661 43.8726 91.6575 43.8726H82.9191Z"
          fill="white"
        />
        <path
          d="M102.31 43.6478C102.071 43.712 101.69 43.7762 101.168 43.8405C100.668 43.9047 100.169 43.9368 99.6686 43.9368C99.1686 43.9368 98.723 43.894 98.3317 43.8083C97.9622 43.7441 97.647 43.6157 97.3862 43.4231C97.1253 43.2304 96.9297 42.9629 96.7992 42.6204C96.6688 42.278 96.6036 41.8285 96.6036 41.272V24.3202C96.8427 24.2774 97.2231 24.2239 97.7448 24.1597C98.2665 24.0741 98.7665 24.0313 99.2447 24.0313C99.7446 24.0313 100.179 24.0741 100.549 24.1597C100.94 24.2239 101.266 24.3523 101.527 24.545C101.788 24.7376 101.984 25.0051 102.114 25.3476C102.244 25.6901 102.31 26.1395 102.31 26.696V43.6478Z"
          fill="white"
        />
        <path
          d="M112.146 36.0388C111.19 35.7177 110.321 35.3967 109.538 35.0756C108.755 34.7331 108.082 34.3265 107.516 33.8556C106.951 33.3847 106.506 32.8282 106.18 32.1861C105.875 31.5226 105.723 30.7199 105.723 29.7782C105.723 27.9589 106.43 26.5034 107.842 25.4118C109.277 24.3202 111.277 23.7744 113.842 23.7744C114.777 23.7744 115.646 23.8386 116.45 23.967C117.255 24.0955 117.939 24.2988 118.505 24.5771C119.091 24.8339 119.548 25.1764 119.874 25.6044C120.2 26.0111 120.363 26.4927 120.363 27.0492C120.363 27.6057 120.233 28.0873 119.972 28.4939C119.711 28.8792 119.396 29.211 119.026 29.4892C118.548 29.1896 117.907 28.9327 117.103 28.7187C116.298 28.4832 115.418 28.3655 114.461 28.3655C113.483 28.3655 112.766 28.5046 112.309 28.7829C111.853 29.0397 111.625 29.3715 111.625 29.7782C111.625 30.0992 111.766 30.3668 112.049 30.5808C112.331 30.7734 112.755 30.9554 113.32 31.1266L115.048 31.6724C117.092 32.3145 118.657 33.1386 119.744 34.1445C120.852 35.1291 121.407 36.4776 121.407 38.1899C121.407 40.0092 120.678 41.486 119.222 42.6204C117.766 43.7334 115.624 44.2899 112.799 44.2899C111.799 44.2899 110.864 44.2043 109.994 44.0331C109.147 43.8833 108.397 43.6585 107.745 43.3589C107.114 43.0378 106.614 42.6525 106.245 42.2031C105.897 41.7322 105.723 41.1971 105.723 40.5978C105.723 39.9771 105.908 39.4527 106.277 39.0246C106.647 38.5751 107.049 38.2327 107.484 37.9972C108.092 38.4681 108.832 38.8748 109.701 39.2172C110.592 39.5597 111.56 39.7309 112.603 39.7309C113.668 39.7309 114.418 39.5704 114.853 39.2493C115.287 38.9283 115.505 38.5537 115.505 38.1256C115.505 37.6976 115.331 37.3765 114.983 37.1625C114.635 36.927 114.146 36.7023 113.516 36.4883L112.146 36.0388Z"
          fill="white"
        />
        <path
          d="M123.17 28.815C123.018 28.5796 122.877 28.2585 122.746 27.8518C122.616 27.4452 122.551 27.0171 122.551 26.5676C122.551 25.7329 122.735 25.1336 123.105 24.7697C123.496 24.4058 123.996 24.2239 124.605 24.2239H138.821C138.973 24.4593 139.114 24.7804 139.245 25.1871C139.375 25.5937 139.44 26.0218 139.44 26.4713C139.44 27.306 139.245 27.9053 138.853 28.2692C138.484 28.6331 137.995 28.815 137.386 28.815H133.767V43.6478C133.528 43.712 133.147 43.7762 132.626 43.8405C132.126 43.9047 131.626 43.9368 131.126 43.9368C130.626 43.9368 130.18 43.894 129.789 43.8083C129.419 43.7441 129.104 43.6157 128.843 43.4231C128.583 43.2304 128.387 42.9629 128.257 42.6204C128.126 42.278 128.061 41.8285 128.061 41.272V28.815H123.17Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M89.4827 13.396C84.6551 10.782 79.2138 9.08227 75.6845 8.32059L75.9009 7.36133C79.5027 8.13864 85.0392 9.86722 89.9692 12.5366C94.8743 15.1925 99.3082 18.8499 100.486 23.5394L99.5105 23.7737C98.4383 19.5043 94.3351 16.0234 89.4827 13.396ZM82.6947 12.8542C76.1953 10.5856 69.1247 9.45779 64.6055 9.11654L64.6828 8.13712C69.2699 8.4835 76.4313 9.62492 83.0323 11.929C89.6013 14.222 95.765 17.7176 98.1457 22.8647L97.2306 23.2696C95.0313 18.5148 89.2262 15.1341 82.6947 12.8542ZM102.62 17.9657C102.163 19.5073 101.794 21.0943 101.596 22.0814L100.61 21.8918C100.813 20.8815 101.188 19.266 101.655 17.692C102.116 16.1387 102.686 14.555 103.297 13.659L104.132 14.2039C103.618 14.9582 103.083 16.4034 102.62 17.9657Z"
          fill="white"
        />
        <path
          d="M8.19789 33.1911C9.10598 33.1911 9.79785 32.991 10.2735 32.5907C10.7708 32.1694 11.0194 31.5374 11.0194 30.6948C11.0194 29.8942 10.76 29.2833 10.2411 28.862C9.7438 28.4196 9.00868 28.1984 8.03573 28.1984C7.6898 28.1984 7.39792 28.2089 7.16008 28.23C6.94387 28.23 6.71685 28.251 6.47902 28.2932V33.1911H8.19789ZM6.51145 43.3346C6.27362 43.3978 5.89525 43.461 5.37635 43.5242C4.87907 43.5874 4.38178 43.619 3.8845 43.619C3.38722 43.619 2.94399 43.5769 2.55481 43.4926C2.18725 43.4294 1.87375 43.303 1.61429 43.1134C1.35484 42.9238 1.16025 42.6605 1.03053 42.3235C0.900801 41.9864 0.835938 41.544 0.835938 40.9963V26.1128C0.835938 25.6704 0.954853 25.3333 1.19268 25.1016C1.45214 24.8488 1.79807 24.6487 2.23049 24.5012C2.96561 24.2484 3.79802 24.0693 4.72772 23.964C5.67904 23.8376 6.63037 23.7744 7.58169 23.7744C10.5654 23.7744 12.8464 24.3959 14.4247 25.6388C16.0031 26.8817 16.7922 28.567 16.7922 30.6948C16.7922 31.727 16.6193 32.6645 16.2733 33.5071C15.949 34.3287 15.4517 35.045 14.7815 35.6559C14.1329 36.2458 13.3005 36.7092 12.2843 37.0463C11.2897 37.3623 10.133 37.5203 8.81409 37.5203H6.51145V43.3346Z"
          fill="white"
        />
        <path
          d="M22.6966 43.5558C21.7669 43.5558 21.0317 43.2925 20.4912 42.7659C19.9507 42.2392 19.6804 41.5229 19.6804 40.6171V24.28C19.9183 24.2379 20.2966 24.1852 20.8155 24.122C21.3344 24.0377 21.8317 23.9956 22.3074 23.9956C22.8047 23.9956 23.2371 24.0377 23.6046 24.122C23.9938 24.1852 24.3181 24.3116 24.5776 24.5012C24.837 24.6908 25.0316 24.9541 25.1614 25.2912C25.2911 25.6283 25.3559 26.0707 25.3559 26.6184V39.1003H32.7828C32.9341 39.332 33.0746 39.648 33.2044 40.0483C33.3341 40.4275 33.399 40.8277 33.399 41.2491C33.399 42.0917 33.2152 42.6921 32.8476 43.0502C32.4801 43.3873 31.9936 43.5558 31.3882 43.5558H22.6966Z"
          fill="white"
        />
        <path
          d="M40.8083 24.8804C41.1758 24.6697 41.7272 24.4696 42.4623 24.28C43.219 24.0904 43.9758 23.9956 44.7325 23.9956C45.8136 23.9956 46.7649 24.1536 47.5865 24.4696C48.4297 24.7645 48.9702 25.2385 49.2081 25.8916C49.5972 26.9871 50.0188 28.2616 50.4729 29.7152C50.9269 31.1687 51.381 32.675 51.835 34.2339C52.289 35.7718 52.7323 37.2991 53.1647 38.8159C53.5971 40.3116 53.9755 41.6388 54.2998 42.7974C54.0403 43.0924 53.6512 43.3241 53.1323 43.4926C52.6134 43.6822 51.9972 43.777 51.2837 43.777C50.7648 43.777 50.3215 43.7349 49.954 43.6506C49.608 43.5664 49.3162 43.44 49.0783 43.2714C48.8621 43.1029 48.6783 42.8817 48.527 42.6079C48.3973 42.334 48.2783 42.018 48.1702 41.6599L47.5865 39.4795H41.7164C41.5434 40.1115 41.3704 40.7645 41.1975 41.4387C41.0245 42.1128 40.8515 42.7659 40.6786 43.3978C40.3759 43.5032 40.0516 43.5874 39.7056 43.6506C39.3813 43.7349 38.9813 43.777 38.5057 43.777C37.403 43.777 36.5814 43.598 36.0409 43.2398C35.522 42.8606 35.2625 42.3445 35.2625 41.6915C35.2625 41.3965 35.3057 41.1016 35.3922 40.8067C35.4787 40.5117 35.576 40.1641 35.6841 39.7639C35.8355 39.1951 36.0409 38.4894 36.3003 37.6467C36.5814 36.804 36.8841 35.8982 37.2084 34.9291C37.5327 33.939 37.8786 32.9278 38.2462 31.8955C38.6138 30.8633 38.9597 29.8942 39.284 28.9884C39.6083 28.0614 39.9002 27.2399 40.1597 26.5236C40.4407 25.8073 40.6569 25.2596 40.8083 24.8804ZM44.7001 28.9568C44.3974 29.8416 44.0514 30.8317 43.6623 31.9271C43.2947 33.0015 42.9596 34.097 42.6569 35.2135H46.6784C46.3757 34.0759 46.0622 32.9699 45.7379 31.8955C45.4352 30.8001 45.1541 29.8205 44.8947 28.9568H44.7001Z"
          fill="white"
        />
        <path
          d="M65.814 43.3346C65.5762 43.3978 65.2086 43.461 64.7113 43.5242C64.214 43.5874 63.7276 43.619 63.2519 43.619C62.2574 43.619 61.5114 43.461 61.0141 43.145C60.5169 42.808 60.2682 42.1128 60.2682 41.0595V36.7935C59.7277 36.014 59.1439 35.1503 58.5169 34.2023C57.8899 33.2543 57.2737 32.2853 56.6683 31.2951C56.0629 30.305 55.5008 29.336 54.9819 28.388C54.463 27.4189 54.0414 26.5341 53.717 25.7336C53.9981 25.3544 54.3765 25.0068 54.8522 24.6908C55.3494 24.3748 55.9548 24.2168 56.6683 24.2168C57.5115 24.2168 58.1926 24.3853 58.7115 24.7224C59.252 25.0595 59.7601 25.6915 60.2358 26.6184L62.9276 31.8955H63.1222C63.4249 31.2425 63.6843 30.6526 63.9005 30.126C64.1384 29.5782 64.3654 29.041 64.5816 28.5144C64.7978 27.9666 65.0248 27.4084 65.2627 26.8396C65.5005 26.2497 65.7708 25.5756 66.0735 24.8172C66.4626 24.6276 66.8951 24.4801 67.3707 24.3748C67.8464 24.2695 68.3004 24.2168 68.7328 24.2168C69.4896 24.2168 70.1274 24.4169 70.6463 24.8172C71.1868 25.1964 71.4571 25.7757 71.4571 26.5552C71.4571 26.808 71.403 27.1135 71.2949 27.4716C71.1868 27.8297 70.9382 28.3774 70.549 29.1148C70.1598 29.831 69.5869 30.8106 68.8301 32.0535C68.095 33.2965 67.0896 34.9186 65.814 36.9199V43.3346Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M110.548 3.58435L107.825 6.94505C107.69 7.11216 107.675 7.34327 107.787 7.5259C107.899 7.70853 108.116 7.80473 108.331 7.76768L112.556 7.04083C112.576 7.75711 112.374 8.49378 111.977 9.21442C110.839 11.2772 108.285 12.0715 106.191 10.9776C104.354 10.0184 103.377 8.04041 103.83 6.23836C103.906 5.9351 104.023 5.6346 104.185 5.34197C104.19 5.33226 104.195 5.32239 104.2 5.31237C104.933 3.74819 106.308 3.11428 107.689 3.01078C108.384 2.95868 109.07 3.04355 109.651 3.21168C110.01 3.31536 110.313 3.44597 110.548 3.58435ZM103.248 4.87156C104.178 2.90244 105.943 2.11282 107.608 1.98804C108.437 1.92584 109.253 2.02633 109.951 2.22839C110.641 2.42778 111.261 2.73894 111.665 3.13275C111.853 3.31659 111.871 3.60872 111.706 3.81277L109.51 6.52323L112.93 5.93475C113.068 5.91106 113.209 5.94158 113.324 6.01958C113.438 6.09759 113.516 6.21669 113.541 6.35067C113.748 7.49529 113.486 8.64648 112.905 9.69946C111.505 12.2393 108.322 13.2549 105.692 11.8814C103.483 10.7278 102.225 8.30372 102.806 5.99445C102.902 5.61259 103.049 5.23601 103.248 4.87156ZM105.477 5.12215C105.427 5.47958 105.538 5.80427 105.664 6.02115C105.808 6.26744 106.13 6.35369 106.383 6.21378C106.636 6.07387 106.724 5.76079 106.581 5.5145C106.536 5.43826 106.51 5.3391 106.521 5.25897C106.526 5.22382 106.538 5.18992 106.563 5.1552C106.587 5.11998 106.635 5.06972 106.731 5.01599C106.813 4.96981 106.891 4.96756 106.97 4.99378C107.063 5.02411 107.137 5.08793 107.171 5.14013C107.326 5.38002 107.651 5.45239 107.897 5.30176C108.144 5.15113 108.218 4.83455 108.063 4.59466C107.759 4.12329 106.973 3.69425 106.202 4.12836C105.761 4.37696 105.53 4.73666 105.477 5.12215Z"
          fill="white"
        />
      </svg>

      <p class="logo-text" style="font-size: 18px; line-height:0px;">
        {{ $ml.get("משחקים מחוץ למסך") }}
      </p>
    </div> -->
    <!-- <div v-else class="auth">
      <div class="auth-title">
        <svg width="101" height="49" viewBox="0 0 101 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.3157 32.2574C58.6429 32.2574 58.1108 32.0607 57.7196 31.6673C57.3284 31.2739 57.1328 30.7388 57.1328 30.0621V17.8582C57.3049 17.8267 57.5788 17.7873 57.9543 17.7401C58.3299 17.6772 58.6898 17.6457 59.0341 17.6457C59.394 17.6457 59.707 17.6772 59.973 17.7401C60.2546 17.7873 60.4894 17.8818 60.6771 18.0234C60.8649 18.165 61.0058 18.3617 61.0997 18.6135C61.1935 18.8653 61.2405 19.1958 61.2405 19.605V28.9291H66.6157C66.7252 29.1022 66.8269 29.3382 66.9208 29.6372C67.0147 29.9205 67.0616 30.2195 67.0616 30.5342C67.0616 31.1637 66.9286 31.6122 66.6626 31.8797C66.3966 32.1315 66.0445 32.2574 65.6064 32.2574H59.3157Z" fill="white"/>
      <path d="M73.2747 32.0922C73.1025 32.1394 72.8287 32.1866 72.4531 32.2338C72.0932 32.281 71.7333 32.3046 71.3734 32.3046C71.0135 32.3046 70.6927 32.2732 70.411 32.2102C70.145 32.163 69.9181 32.0686 69.7303 31.927C69.5426 31.7853 69.4017 31.5886 69.3078 31.3368C69.214 31.085 69.167 30.7546 69.167 30.3454V17.8818C69.3391 17.8503 69.613 17.8109 69.9885 17.7637C70.3641 17.7008 70.724 17.6693 71.0683 17.6693C71.4282 17.6693 71.7411 17.7008 72.0072 17.7637C72.2888 17.8109 72.5236 17.9054 72.7113 18.047C72.8991 18.1886 73.04 18.3853 73.1338 18.6371C73.2277 18.8889 73.2747 19.2194 73.2747 19.6286V32.0922Z" fill="white"/>
      <path d="M80.356 26.4977C79.6675 26.2617 79.0416 26.0256 78.4782 25.7896C77.9149 25.5378 77.4298 25.2388 77.0229 24.8926C76.6161 24.5463 76.2953 24.1372 76.0606 23.6651C75.8415 23.1772 75.732 22.5871 75.732 21.8947C75.732 20.557 76.2405 19.4869 77.2577 18.6843C78.2904 17.8818 79.7301 17.4805 81.5766 17.4805C82.2495 17.4805 82.8754 17.5277 83.4544 17.6221C84.0334 17.7165 84.5263 17.866 84.9331 18.0706C85.3556 18.2594 85.6843 18.5112 85.919 18.826C86.1537 19.125 86.2711 19.4791 86.2711 19.8882C86.2711 20.2974 86.1772 20.6515 85.9894 20.9505C85.8016 21.2337 85.5747 21.4776 85.3087 21.6822C84.9644 21.4619 84.5028 21.2731 83.9238 21.1157C83.3448 20.9426 82.7111 20.856 82.0226 20.856C81.3184 20.856 80.802 20.9583 80.4734 21.1629C80.1448 21.3517 79.9805 21.5957 79.9805 21.8947C79.9805 22.1307 80.0822 22.3274 80.2856 22.4848C80.489 22.6264 80.7942 22.7602 81.201 22.8861L82.4451 23.2874C83.916 23.7595 85.0427 24.3654 85.8251 25.105C86.6232 25.8289 87.0222 26.8203 87.0222 28.0793C87.0222 29.4169 86.498 30.5028 85.4495 31.3368C84.4011 32.1551 82.8597 32.5643 80.8255 32.5643C80.1056 32.5643 79.4328 32.5013 78.8068 32.3755C78.1966 32.2653 77.6567 32.1001 77.1872 31.8797C76.7334 31.6437 76.3735 31.3604 76.1075 31.0299C75.8571 30.6837 75.732 30.2903 75.732 29.8497C75.732 29.3933 75.865 29.0078 76.131 28.693C76.397 28.3625 76.6865 28.1108 76.9995 27.9376C77.4376 28.2839 77.9697 28.5829 78.5956 28.8347C79.2372 29.0864 79.9335 29.2123 80.6846 29.2123C81.4514 29.2123 81.9913 29.0943 82.3042 28.8583C82.6172 28.6222 82.7737 28.3468 82.7737 28.0321C82.7737 27.7173 82.6485 27.4813 82.3981 27.3239C82.1477 27.1508 81.7957 26.9856 81.3419 26.8282L80.356 26.4977Z" fill="white"/>
      <path d="M88.2917 21.1865C88.1822 21.0134 88.0805 20.7774 87.9866 20.4784C87.8927 20.1793 87.8457 19.8646 87.8457 19.5341C87.8457 18.9204 87.9787 18.4798 88.2448 18.2122C88.5264 17.9447 88.8863 17.8109 89.3245 17.8109H99.5585C99.668 17.984 99.7697 18.2201 99.8636 18.5191C99.9575 18.8181 100.004 19.1328 100.004 19.4633C100.004 20.0771 99.8636 20.5177 99.5819 20.7852C99.3159 21.0527 98.9638 21.1865 98.5257 21.1865H95.9202V32.0922C95.7481 32.1394 95.4743 32.1866 95.0987 32.2338C94.7388 32.281 94.3789 32.3046 94.019 32.3046C93.6591 32.3046 93.3383 32.2732 93.0566 32.2102C92.7906 32.163 92.5637 32.0686 92.3759 31.927C92.1881 31.7853 92.0473 31.5886 91.9534 31.3368C91.8595 31.085 91.8126 30.7546 91.8126 30.3454V21.1865H88.2917Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M64.0415 9.85003C60.5662 7.92813 56.6491 6.67839 54.1084 6.11837L54.2642 5.41309C56.8571 5.9846 60.8427 7.25552 64.3917 9.21814C67.9228 11.1709 71.1147 13.86 71.9625 17.3079L71.2603 17.4801C70.4885 14.3411 67.5346 11.7818 64.0415 9.85003ZM59.155 9.45168C54.4761 7.78368 49.3861 6.95449 46.1328 6.70359L46.1885 5.98348C49.4907 6.23815 54.646 7.07737 59.398 8.77145C64.1268 10.4573 68.564 13.0275 70.2778 16.8118L69.6191 17.1095C68.0358 13.6135 63.8569 11.1279 59.155 9.45168ZM73.4987 13.2098C73.1695 14.3433 72.9044 15.5101 72.7615 16.2359L72.052 16.0965C72.1983 15.3537 72.4682 14.1659 72.8043 13.0086C73.1359 11.8665 73.5462 10.7022 73.9858 10.0434L74.5874 10.444C74.2173 10.9986 73.8322 12.0612 73.4987 13.2098Z" fill="white"/>
      <path d="M5.52631 24.404C6.18002 24.404 6.67808 24.2569 7.02051 23.9626C7.37849 23.6528 7.55748 23.1881 7.55748 22.5686C7.55748 21.98 7.37071 21.5308 6.99716 21.221C6.63917 20.8958 6.10998 20.7331 5.40957 20.7331C5.16054 20.7331 4.95042 20.7409 4.7792 20.7564C4.62356 20.7564 4.46013 20.7719 4.28892 20.8028V24.404H5.52631ZM4.31227 31.8619C4.14106 31.9084 3.86868 31.9549 3.49513 32.0013C3.13714 32.0478 2.77916 32.071 2.42117 32.071C2.06318 32.071 1.74411 32.0401 1.46395 31.9781C1.19935 31.9316 0.973663 31.8387 0.786888 31.6993C0.600113 31.5599 0.460031 31.3663 0.366644 31.1185C0.273256 30.8706 0.226562 30.5454 0.226562 30.1427V19.1997C0.226562 18.8745 0.312168 18.6266 0.483378 18.4563C0.670153 18.2704 0.919187 18.1233 1.23048 18.0148C1.75967 17.829 2.35891 17.6973 3.02819 17.6199C3.71303 17.5269 4.39787 17.4805 5.08271 17.4805C7.23063 17.4805 8.87269 17.9374 10.0089 18.8512C11.1451 19.7651 11.7132 21.0042 11.7132 22.5686C11.7132 23.3275 11.5887 24.0168 11.3397 24.6363C11.1062 25.2404 10.7482 25.767 10.2657 26.2162C9.79878 26.6499 9.19955 26.9907 8.46801 27.2385C7.75204 27.4708 6.91934 27.587 5.9699 27.587H4.31227V31.8619Z" fill="white"/>
      <path d="M15.9636 32.0246C15.2944 32.0246 14.7652 31.831 14.376 31.4437C13.9869 31.0565 13.7924 30.5299 13.7924 29.8639V17.8522C13.9636 17.8212 14.236 17.7825 14.6095 17.736C14.9831 17.6741 15.3411 17.6431 15.6835 17.6431C16.0415 17.6431 16.3528 17.6741 16.6174 17.736C16.8975 17.7825 17.131 17.8754 17.3178 18.0148C17.5045 18.1542 17.6446 18.3478 17.738 18.5957C17.8314 18.8435 17.8781 19.1688 17.8781 19.5715V28.7487H23.2245C23.3335 28.919 23.4346 29.1514 23.528 29.4457C23.6214 29.7245 23.6681 30.0187 23.6681 30.3285C23.6681 30.9481 23.5358 31.3895 23.2712 31.6528C23.0066 31.9007 22.6564 32.0246 22.2206 32.0246H15.9636Z" fill="white"/>
      <path d="M29.002 18.2936C29.2666 18.1387 29.6635 17.9916 30.1926 17.8522C30.7374 17.7128 31.2822 17.6431 31.8269 17.6431C32.6052 17.6431 33.29 17.7593 33.8815 17.9916C34.4885 18.2084 34.8776 18.5569 35.0488 19.0371C35.329 19.8425 35.6325 20.7796 35.9593 21.8483C36.2862 22.9171 36.613 24.0245 36.9399 25.1707C37.2668 26.3014 37.5858 27.4244 37.8971 28.5396C38.2084 29.6393 38.4808 30.6151 38.7143 31.467C38.5275 31.6838 38.2473 31.8542 37.8738 31.9781C37.5002 32.1175 37.0566 32.1872 36.543 32.1872C36.1695 32.1872 35.8504 32.1562 35.5858 32.0943C35.3367 32.0323 35.1266 31.9394 34.9554 31.8155C34.7998 31.6916 34.6675 31.5289 34.5585 31.3276C34.4651 31.1262 34.3795 30.8939 34.3017 30.6306L33.8815 29.0275H29.6557C29.5312 29.4921 29.4066 29.9723 29.2821 30.4679C29.1576 30.9636 29.0331 31.4437 28.9086 31.9084C28.6907 31.9858 28.4572 32.0478 28.2082 32.0943C27.9747 32.1562 27.6868 32.1872 27.3443 32.1872C26.5505 32.1872 25.9591 32.0555 25.57 31.7922C25.1964 31.5134 25.0096 31.134 25.0096 30.6538C25.0096 30.437 25.0408 30.2201 25.103 30.0033C25.1653 29.7864 25.2353 29.5308 25.3132 29.2366C25.4221 28.8184 25.57 28.2995 25.7567 27.6799C25.9591 27.0604 26.177 26.3943 26.4105 25.6819C26.6439 24.9539 26.893 24.2104 27.1576 23.4514C27.4222 22.6925 27.6712 21.98 27.9047 21.314C28.1381 20.6325 28.3482 20.0284 28.535 19.5018C28.7374 18.9751 28.893 18.5724 29.002 18.2936ZM31.8036 21.2907C31.5857 21.9413 31.3366 22.6693 31.0565 23.4747C30.7919 24.2646 30.5506 25.07 30.3327 25.891H33.2277C33.0098 25.0545 32.7842 24.2414 32.5507 23.4514C32.3328 22.646 32.1304 21.9258 31.9437 21.2907H31.8036Z" fill="white"/>
      <path d="M47.0031 31.8619C46.8319 31.9084 46.5673 31.9549 46.2093 32.0013C45.8514 32.0478 45.5012 32.071 45.1587 32.071C44.4428 32.071 43.9058 31.9549 43.5478 31.7225C43.1898 31.4747 43.0108 30.9636 43.0108 30.1891V27.0526C42.6217 26.4795 42.2015 25.8445 41.7501 25.1475C41.2987 24.4505 40.8551 23.738 40.4193 23.01C39.9835 22.282 39.5788 21.5695 39.2053 20.8725C38.8317 20.1601 38.5282 19.5095 38.2947 18.9209C38.4971 18.6421 38.7695 18.3866 39.1119 18.1542C39.4699 17.9219 39.9057 17.8057 40.4193 17.8057C41.0263 17.8057 41.5166 17.9296 41.8902 18.1775C42.2793 18.4253 42.645 18.89 42.9875 19.5715L44.9253 23.4514H45.0653C45.2832 22.9713 45.47 22.5376 45.6257 22.1504C45.7969 21.7477 45.9603 21.3527 46.116 20.9655C46.2716 20.5628 46.435 20.1523 46.6062 19.7341C46.7774 19.3004 46.972 18.8048 47.1899 18.2472C47.4701 18.1078 47.7814 17.9993 48.1238 17.9219C48.4662 17.8445 48.7931 17.8057 49.1044 17.8057C49.6491 17.8057 50.1083 17.9529 50.4818 18.2472C50.8709 18.526 51.0655 18.9519 51.0655 19.525C51.0655 19.7109 51.0266 19.9355 50.9488 20.1988C50.8709 20.4621 50.6919 20.8648 50.4118 21.4069C50.1316 21.9335 49.7192 22.6538 49.1744 23.5676C48.6452 24.4815 47.9214 25.6741 47.0031 27.1456V31.8619Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M79.2056 2.63536L77.2454 5.10628C77.1479 5.22914 77.1369 5.39907 77.2177 5.53334C77.2985 5.66762 77.4543 5.73835 77.6093 5.71111L80.6505 5.1767C80.6649 5.70334 80.5198 6.24496 80.2337 6.77481C79.4149 8.29142 77.5765 8.87544 76.0686 8.07114C74.7465 7.36593 74.0429 5.91163 74.3692 4.58669C74.4241 4.36372 74.5083 4.14278 74.6245 3.92763C74.6283 3.92049 74.632 3.91323 74.6353 3.90586C75.1633 2.75582 76.1529 2.28975 77.1469 2.21365C77.6472 2.17534 78.141 2.23774 78.5596 2.36136C78.8177 2.43759 79.0358 2.53362 79.2056 2.63536ZM73.9501 3.58176C74.6198 2.13399 75.8903 1.55343 77.0886 1.46168C77.6859 1.41595 78.2727 1.48984 78.7757 1.6384C79.2721 1.785 79.7182 2.01378 80.0092 2.30332C80.145 2.43849 80.1577 2.65328 80.0387 2.8033L78.4577 4.79614L80.9199 4.36347C81.019 4.34605 81.1211 4.36849 81.2035 4.42584C81.286 4.48319 81.3421 4.57076 81.3596 4.66927C81.509 5.51083 81.3203 6.35724 80.9023 7.13143C79.8941 8.99882 77.6031 9.74555 75.7098 8.73569C74.1197 7.88753 73.214 6.10523 73.6322 4.40736C73.7013 4.1266 73.8066 3.84972 73.9501 3.58176ZM75.5545 3.76601C75.5189 4.02881 75.5984 4.26753 75.6895 4.42699C75.793 4.60807 76.0246 4.67149 76.2068 4.56862C76.389 4.46575 76.4528 4.23556 76.3493 4.05448C76.3173 3.99843 76.2985 3.92552 76.3065 3.8666C76.31 3.84076 76.3186 3.81584 76.3362 3.79031C76.3542 3.76441 76.3887 3.72746 76.4573 3.68795C76.5163 3.654 76.5724 3.65235 76.6298 3.67162C76.6962 3.69393 76.7498 3.74085 76.7741 3.77923C76.8855 3.95561 77.1197 4.00881 77.2971 3.89806C77.4746 3.78732 77.5281 3.55456 77.4167 3.37818C77.1978 3.03161 76.6314 2.71616 76.077 3.03533C75.7595 3.21811 75.5928 3.48258 75.5545 3.76601Z" fill="white"/>
      <path d="M2.08804 38.3199C2.52606 38.3199 2.91583 38.3831 3.25734 38.5093C3.59885 38.6355 3.87539 38.8025 4.08698 39.0104C4.30228 39.2183 4.47861 39.467 4.61595 39.7565C4.86466 40.2799 4.98901 40.898 4.98901 41.6107V48.3537H3.28518V41.4381C3.28518 40.5657 3.04575 40.0089 2.56689 39.7677C2.37386 39.6749 2.14186 39.6284 1.87088 39.6284C1.39945 39.6284 0.775821 39.6693 0 39.7509V38.4424C0.660747 38.3608 1.35676 38.3199 2.08804 38.3199Z" fill="white"/>
      <path d="M9.38224 38.3199C10.3362 38.3199 11.0768 38.6448 11.6039 39.2944C12.1347 39.944 12.4001 40.8516 12.4001 42.0172C12.4001 43.1828 12.131 44.0922 11.5928 44.7455C11.0582 45.3989 10.3232 45.7255 9.38781 45.7255C8.45237 45.7255 7.71367 45.4007 7.17171 44.7511C6.63346 44.0978 6.36433 43.1883 6.36433 42.0227C6.36433 41.5736 6.45157 41.1337 6.62603 40.7031C6.8005 40.2725 6.99538 39.957 7.21068 39.7565V39.712L6.4757 39.7621V38.4759C7.33689 38.3719 8.30574 38.3199 9.38224 38.3199ZM8.09044 42.0227C8.09044 43.5892 8.52104 44.3725 9.38224 44.3725C10.2434 44.3725 10.674 43.5818 10.674 42.0005C10.674 40.4191 10.2434 39.6284 9.38224 39.6284L8.75305 39.6396C8.31131 40.2521 8.09044 41.0465 8.09044 42.0227Z" fill="white"/>
      <path d="M13.9202 40.5472C13.9202 39.8827 13.7847 39.1756 13.5138 38.4257H15.1397C15.1953 38.5705 15.251 38.7394 15.3067 38.9324L15.457 39.5282H15.5628C15.5777 39.4948 15.5999 39.4521 15.6296 39.4002C15.6593 39.3445 15.7336 39.248 15.8524 39.1106C15.9749 38.9696 16.1104 38.8471 16.2588 38.7431C16.4073 38.6355 16.6096 38.539 16.8658 38.4536C17.1256 38.3645 17.4151 38.3199 17.7344 38.3199C18.0573 38.3199 18.3506 38.3812 18.6141 38.5037C18.8777 38.6262 19.0893 38.7877 19.2489 38.9881C19.4122 39.1886 19.5459 39.4317 19.6498 39.7175C19.8317 40.2187 19.9226 40.8293 19.9226 41.5494V45.6142H16.8491V44.3168H18.1854V41.7443C18.1854 41.0836 18.1242 40.6177 18.0017 40.3467C17.946 40.2242 17.8754 40.1092 17.7901 40.0015C17.6193 39.7788 17.3483 39.6674 16.9771 39.6674C16.8064 39.6674 16.6393 39.7083 16.476 39.7899C16.3164 39.8679 16.1827 39.9644 16.0751 40.0795C15.8561 40.3133 15.7039 40.5212 15.6185 40.7031L15.5628 40.8256L15.1397 45.6142H13.4804L13.898 41.0539C13.9128 40.8831 13.9202 40.7142 13.9202 40.5472Z" fill="white"/>
      <path d="M22.8737 38.4313H26.6545V39.5338L25.6801 42.947L25.0787 45.6142H23.425L24.0263 42.947L24.9451 39.7287H21.1755V36.9502H22.8737V38.4313Z" fill="white"/>
      <path d="M35.9254 38.4313V41.0038C35.9254 41.7722 35.7082 42.3475 35.2739 42.7299C35.118 42.8672 34.9082 42.9786 34.6447 43.064C34.3848 43.1493 34.0842 43.192 33.7427 43.192C33.5905 43.192 33.4401 43.1828 33.2916 43.1642V48.3537H31.5934V42.4014L30.0065 38.4313H31.8662L33.0856 41.9336L33.1023 42.0172C33.2137 42.0283 33.3121 42.0339 33.3974 42.0339C33.6907 42.0339 33.9023 41.9559 34.0322 41.8C34.1621 41.6441 34.2271 41.3843 34.2271 41.0205V38.4313H35.9254Z" fill="white"/>
      <path d="M39.088 45.6142H37.3898V38.4313H39.088V45.6142Z" fill="white"/>
      <path d="M40.8754 45.6142V38.5594C41.1464 38.5 41.5603 38.4462 42.1171 38.3979C42.6776 38.3459 43.188 38.3199 43.6483 38.3199C44.1086 38.3199 44.4947 38.3459 44.8065 38.3979C45.1183 38.4499 45.38 38.5297 45.5916 38.6373C45.8032 38.745 45.9758 38.8693 46.1094 39.0104C46.2467 39.1477 46.3525 39.3203 46.4268 39.5282C46.501 39.7361 46.5511 39.9477 46.5771 40.163C46.6031 40.3746 46.6161 40.627 46.6161 40.9202V45.6142H44.9123V40.9147C44.9123 40.0572 44.4408 39.6284 43.498 39.6284C43.2418 39.6284 42.9356 39.6414 42.5792 39.6674V45.6142H40.8754Z" fill="white"/>
      <path d="M48.487 40.5472C48.487 39.8827 48.3515 39.1756 48.0805 38.4257H49.7064C49.7621 38.5705 49.8178 38.7394 49.8734 38.9324L50.0238 39.5282H50.1296C50.1444 39.4948 50.1667 39.4521 50.1964 39.4002C50.2261 39.3445 50.3003 39.248 50.4191 39.1106C50.5416 38.9696 50.6771 38.8471 50.8256 38.7431C50.9741 38.6355 51.1764 38.539 51.4325 38.4536C51.6923 38.3645 51.9819 38.3199 52.3011 38.3199C52.6241 38.3199 52.9173 38.3812 53.1809 38.5037C53.4444 38.6262 53.656 38.7877 53.8156 38.9881C53.979 39.1886 54.1126 39.4317 54.2166 39.7175C54.3984 40.2187 54.4894 40.8293 54.4894 41.5494V45.6142H51.4158V44.3168H52.7521V41.7443C52.7521 41.0836 52.6909 40.6177 52.5684 40.3467C52.5127 40.2242 52.4422 40.1092 52.3568 40.0015C52.1861 39.7788 51.9151 39.6674 51.5439 39.6674C51.3731 39.6674 51.2061 39.7083 51.0427 39.7899C50.8831 39.8679 50.7495 39.9644 50.6418 40.0795C50.4228 40.3133 50.2706 40.5212 50.1852 40.7031L50.1296 40.8256L49.7064 45.6142H48.0471L48.4647 41.0539C48.4796 40.8831 48.487 40.7142 48.487 40.5472Z" fill="white"/>
      <path d="M59.2056 45.6142V38.5594C59.4765 38.5 59.8904 38.4462 60.4472 38.3979C61.0078 38.3459 61.5405 38.3199 62.0453 38.3199C63.0772 38.3199 63.8178 38.487 64.267 38.8211C64.7198 39.1552 64.9463 39.6916 64.9463 40.4303V45.6142H59.2056ZM63.2424 44.3168V40.4303C63.2424 40.0925 63.1107 39.8716 62.8471 39.7677C62.617 39.6749 62.2773 39.6284 61.8281 39.6284C61.572 39.6284 61.2658 39.6414 60.9094 39.6674V44.3168H63.2424Z" fill="white"/>
      <path d="M66.6278 43.1865V38.4313H68.3261V41.7499L67.1958 43.1865H66.6278Z" fill="white"/>
      <path d="M74.2728 45.4248V45.6142H72.5857V45.4248C72.5857 45.1242 72.634 44.7103 72.7305 44.1832C72.827 43.6523 72.9458 43.192 73.0868 42.8023L74.2004 39.7287H69.9965V38.4313H76.0602V39.4558L74.8519 42.7354C74.4659 43.7822 74.2728 44.6787 74.2728 45.4248ZM71.5779 48.3592H70.0021V41.3156H71.5779V48.3592Z" fill="white"/>
      <path d="M77.2907 45.6142V38.5594C77.5617 38.5 77.9756 38.4462 78.5324 38.3979C79.0929 38.3459 79.6034 38.3199 80.0637 38.3199C80.5239 38.3199 80.91 38.3459 81.2218 38.3979C81.5336 38.4499 81.7953 38.5297 82.0069 38.6373C82.2185 38.745 82.3911 38.8693 82.5248 39.0104C82.6621 39.1477 82.7679 39.3203 82.8421 39.5282C82.9164 39.7361 82.9665 39.9477 82.9925 40.163C83.0185 40.3746 83.0314 40.627 83.0314 40.9202V45.6142H81.3276V40.9147C81.3276 40.0572 80.8562 39.6284 79.9133 39.6284C79.6572 39.6284 79.3509 39.6414 78.9946 39.6674V45.6142H77.2907Z" fill="white"/>
      <path d="M88.3211 45.6142H85.0694L84.4624 38.4313H86.0883L86.389 42.0283C86.4336 42.032 86.4892 42.0339 86.5561 42.0339C86.8493 42.0339 87.0665 41.9559 87.2075 41.8C87.3523 41.6441 87.4395 41.3843 87.4692 41.0205L87.6808 38.4313H89.2956L89.0895 41.0038C88.9745 42.4626 88.2135 43.192 86.8066 43.192C86.7287 43.192 86.6229 43.1883 86.4892 43.1809L86.5839 44.3168H88.3991C89.019 44.3168 89.496 44.0755 89.8301 43.5929C90.1642 43.1104 90.3665 42.4459 90.437 41.5996L90.7043 38.4313H92.3302L92.0629 41.555C92.0035 42.2306 91.9088 42.7726 91.7789 43.1809C91.2704 44.8031 90.1178 45.6142 88.3211 45.6142Z" fill="white"/>
      <path d="M93.8447 40.5472C93.8447 39.8827 93.7092 39.1756 93.4382 38.4257H95.0641C95.1198 38.5705 95.1755 38.7394 95.2311 38.9324L95.3815 39.5282H95.4873C95.5021 39.4948 95.5244 39.4521 95.5541 39.4002C95.5838 39.3445 95.658 39.248 95.7768 39.1106C95.8993 38.9696 96.0348 38.8471 96.1833 38.7431C96.3318 38.6355 96.5341 38.539 96.7902 38.4536C97.0501 38.3645 97.3396 38.3199 97.6588 38.3199C97.9818 38.3199 98.275 38.3812 98.5386 38.5037C98.8022 38.6262 99.0137 38.7877 99.1734 38.9881C99.3367 39.1886 99.4703 39.4317 99.5743 39.7175C99.7562 40.2187 99.8471 40.8293 99.8471 41.5494V45.6142H96.7735V44.3168H98.1099V41.7443C98.1099 41.0836 98.0486 40.6177 97.9261 40.3467C97.8704 40.2242 97.7999 40.1092 97.7145 40.0015C97.5438 39.7788 97.2728 39.6674 96.9016 39.6674C96.7308 39.6674 96.5638 39.7083 96.4004 39.7899C96.2408 39.8679 96.1072 39.9644 95.9995 40.0795C95.7805 40.3133 95.6283 40.5212 95.543 40.7031L95.4873 40.8256L95.0641 45.6142H93.4048L93.8224 41.0539C93.8373 40.8831 93.8447 40.7142 93.8447 40.5472Z" fill="white"/>
      </svg>

      </div>
    </div> -->
  </div>
</template>

<script>
import SidebarToggler from "../SidebarToggler";
import MainSchool from "./MainSchool";
import MainNonSchool from "./MainNonSchool";
import { mapGetters } from "vuex";
export default {
  name: "MainHeader",
  components: { SidebarToggler, MainSchool, MainNonSchool },
  props: {
    sidebar: {
      default: true,
    },
    main: {
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isDefaultUser: "user/getDefaultUser"
    }),
    loading() {
      return (
        !this.$store.getters["user/isLoaded"] ||
        !this.$store.getters["playlist-locations/isLoaded"] ||
        !this.$store.getters["playlist-sections/isLoaded"] ||
        !this.$store.getters["playlist-gifts/isLoaded"] ||
        !this.$store.getters["user/isSchoolLoaded"]
      );
    },
  },
};
</script>

<style scoped lang="scss">
.title-link-rtl {
  color: white !important;
  .router-svg {
    position: absolute;
    left: 84px;
    top: 2px;
  }
  .router-title {
    position: absolute;
    // font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 100px;
    top: 6px;
    left: -4px;
    /* identical to box height, or 129% */

    text-align: center;

    color: #ffffff;
  }
}
.title-link-ltr {
  color: white !important;
  .router-svg {
    position: absolute;
    left: 135px;
  }
  .router-title {
    position: absolute;
    // font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 140px;
    top: 6px;
    left: -4px;
    /* identical to box height, or 129% */

    text-align: center;

    color: #ffffff;
  }
}
//
.main-header {
  padding: 0 11px 10px 22px;
  text-align: center;
  p {
    font-size: 1.8rem; /*22px;*/
    margin: 0;
  }
  .logo-i {
  }
  .wrapper {
    position: relative;
    top: 15px;
    .fixed {
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }
  .auth {
    margin-top: 18px;
    margin-bottom: 17px;
  }
  .logo-text {
    font-size: 25px;
    margin-top: 0px;
    font-family: "Artishok";
  }
  .logo-text1 {
    font-size: 17px;
    margin-top: 0px;
    font-family: "Artishok";
  }
}
</style>
