<template>
  <button @click="goGame" class="searched-game">
    <span class="info">
     <img :src="item.img" alt="">
    <span class="name">{{translated.data.name}}</span>
    </span>
    <span style="color: #8CC63F;" v-if="item.played && !isDefaultUser" class="is-played">
    <span>
      <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999849 2.82797L2.41406 4.24219L5.24249 1.41376" stroke="#8CC63F"/>
      </svg>
    </span>

      <span> {{$ml.get('צפיתי')}}</span>

    </span>
    <span style="color: #FF5C5C;"  v-else-if="!isDefaultUser" class="is-played">
     <span>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1.76762" y1="1.76754" x2="5.30315" y2="5.30307" stroke="#FF5C5C"/>
          <line x1="1.76754" y1="5.30367" x2="5.30307" y2="1.76814" stroke="#FF5C5C"/>
        </svg>
      </span>
      <span>{{$ml.get('לא צפיתי')}}</span>
    </span>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GiftElement",
  props:{
    item:{},
    checked:{},
  },
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters({
      isDefaultUser: "user/getDefaultUser",
    }),
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.item.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        })
      }
    },
  },
  methods:{
    goGame(){
      this.$router.push({name: 'targeted-playlist', params: { id_playlist: this.item.id_playlist}});
    }
  },
}
</script>

<style scoped lang="scss">
.searched-game{
  padding: 6px 5px;

  margin-bottom: 7px;
  width: 84px;
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 4px;
  .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      margin-top: 4px;
      width: 50px;

    }
    .name{
      text-align: right;
      margin-top: 7px;
      font-family: 'Cool';
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 15px;

      color: #00465B;
    }
  }
  .is-played{

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
  }
}
</style>