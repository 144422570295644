<template>
  <div class="reports-status-element">
    <div class="header">
      <button class="show-b" @click="flag=!flag">
      <span  :style="{color: titleColor}" class="name">
        {{translated!=='' ? translated : translate[0].data.title }}
        {{goal.stats.thisYear.total}} </span>
        <svg :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
      </button>
      <div class="term">
        <div class="term-element">{{goal.stats.oldMonth.total}}/{{goal.stats.oldMonth.target}}</div>
        <div class="term-element">{{goal.stats.oldYear.total}}/{{goal.stats.oldYear.target}}</div>
      </div>
    </div>
    <div class="element-wrapper">
      <div class="row">
        <div class="date-value">{{$ml.get('יעד חודשי')}}: {{goal.stats.thisMonth.target}}</div>

        <div class="status">
          <div class="value">{{goal.stats.thisMonth.total}}/{{goal.stats.thisMonth.target}}</div>
          <div class="status-bar">
            <div :style="{width: goal.stats.thisMonth.total/goal.stats.thisMonth.target*100+'%',
            background:  goal.stats.thisMonth.total/goal.stats.thisMonth.target*100 > 100 ? '#FDC671' : '#8CC63F' }" class="bg"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="date-value">{{$ml.get('יעד שנתי')}}: {{goal.stats.thisYear.target}}</div>

        <div class="status">
          <div class="value">{{goal.stats.thisYear.total}}/{{goal.stats.thisYear.target}}</div>
          <div class="status-bar">
            <div :style="{width: goal.stats.thisYear.total/goal.stats.thisYear.target*100+'%',
            background:  goal.stats.thisYear.total/goal.stats.thisYear.target*100 > 100 ? '#FDC671' : '#8CC63F' }"
                 class="bg"></div>          </div>
        </div>
      </div>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >
        <div v-for="go in goal.gifts"  class="report-w">
          <ReportStatusElement
            :titleColor="titleColor"
            v-if="go.details"
            :title="go.details.name"
            :translate="go.details.translation"
            :data="go.stats"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ReportStatusElement from "./ReportStatusElement";
  export default {
    props:{
      report:{
        title:{},
        games: {},
      },
      title: {},
      goal: {},
      titleColor:{
        default: '#00465B'
      }
    },
    components:{
      ReportStatusElement,
    },
    data() {
      return {
        flag: false,
      }
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.goal.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.title
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
//
  .reports-status-element{
    width: 100%;
    padding: 0 20px;
    .report-status-element{
      padding: 0 20px 0 0;
    }
    .header{
      margin:15px 0 10px 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .name{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #00465B;
      }
      .term{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        padding: 0 20px;
        font-size: 12px;
        text-align: center;
        color: #00465B;
      }
    }
    .element-wrapper{
      background: white;
      padding: 5px 13px;
      border: 1px solid #AAD9E7;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 15px;
      .row{
        margin: 15px 0;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-around;
        .date-value{
          font-size: 14px;
          color: #000000;
        }
        .status{
          display: flex;
          align-items: center;
          flex-direction: column;
          .value{
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }
          .status-bar{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 160px;
            height: 8px;
            background: #C4C4C4;
            border-radius: 4px;
            .bg{
              background: rgb(140, 198, 63);

              height: 100%;
              border-radius: 4px 4px  4px  5px;
            }
          }
        }
      }
    }

  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

