import parent_cat_1 from "./parent-cat-1.svg";
import parent_cat_2 from "./parent-cat-2.svg";
import parent_cat_3 from "./parent-cat-3.svg";
import parent_cat_4 from "./parent-cat-4.svg";
import parent_cat_5 from "./parent-cat-5.svg";
import parent_cat_6 from "./parent-cat-6.svg";
import parent_cat_7 from "./parent-cat-7.svg";

const imgs = [
  parent_cat_1,
  parent_cat_2,
  parent_cat_3,
  parent_cat_4,
  parent_cat_5,
  parent_cat_6,
  parent_cat_7
];

export default imgs;
