import {TeacherAPI } from '@/api';
import store from './../index';

const PromoStore = {
  namespaced: true,
  state: {
    data:null
  },
  mutations: {
    saveData(state, data) {
      state.data = data;
    },
  },
  actions: {
    // updateData({ commit },id) {
    //     TeacherAPI.getAllPlayedGameDetails({id:id})
    //   .then(res=>{
    //     commit('saveData',res.data.playlist.playedPlaylist);
    //   }).catch();
    // },
  },
  getters: {
    data(state) {
      return state.data;
    },
  }
};

export { PromoStore }
