<template>
  <div class="shootlist-item">
    <div class="wrapper" dir="rtl">
      <template v-if="advertisment">
        
        <div class="advertisment img-shootlist-share-bg">
          <p>
            <span>{{ $ml.get("נהנים") }}?</span><br />
            {{ $ml.get("גם אחרים יהנו בזכותכם") }}! <br />
            {{ $ml.get("שתפו את אפליקצית פליי-ליסט") }}...
          </p>

          <button @click="share" class="yea theme-button-outline">
            {{ $ml.get("רוצה לשתף") }}
          </button>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>
          <button @click="show_adv = false" class="nope">
            {{ $ml.get("לא עכשיו") }}
          </button>
        </div>
      </template>
      <template v-else>
        <div
          v-if="question.category!=null && question.category.includes(1)"
          :style="{
            backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>

          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>
          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
        </div>

        <div
          v-else-if="question.category!=null && question.category == 999"
          :style="{
            backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>

          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
          <div class="variants">
            <div
              class="variant"
              :class="{ right: question.answers.right == 1 && indicate }"
            >
              <span>1.</span> {{ question.answers.first }}
            </div>
            <div
              class="variant"
              :class="{ right: question.answers.right == 2 && indicate }"
            >
              <span>2.</span> {{ question.answers.second }}
            </div>
            <div
              class="variant"
              :class="{ right: question.answers.right == 3 && indicate }"
            >
              <span>3.</span> {{ question.answers.third }}
            </div>
          </div>
          <button
            @click="indicateRightAnswer"
            class="right-answer theme-button-outline"
            v-if="first"
          >
            {{ $ml.get("גלה לי את התשובה") }}
          </button>
        </div>

        <div
          v-else-if="question.category!=null && question.category.includes(7)"
          :style="{
            backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="third body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>

          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
        </div>

        <div
          v-else-if="question.category!=null && question.category.includes(4)"
          :style="{
             backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>
          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
        </div>

        <div
          v-else-if="question.category!=null && question.category.includes(13)"
          :style="{
            backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>
          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
        </div>
        <div
          v-else
          :style="{
            backgroundColor: $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category) != null? $store.getters[
              'shootlist-categories/getCategoryById'
            ](question.category).color : '#3ecfb9',
          }"
          class="body img-shootlist-bg"
        >
          <div class="category">
            <span style="margin-right: 5px;" v-for="cat in question.category">{{$store.getters["shootlist-categories/getTranslatedNameById"](cat)}}</span>
          </div>
          <button class="reply" @click="reply">
            <span class="material-icons"> share </span>
            {{ $ml.get("שיתוף") }}
          </button>
          <span
            :style="{ direction: direction }"
            class="content"
            v-html="content"
          ></span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    question: {},
    gender: {},
    first: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      show_adv: false,
      indicate: false,
    };
  },
  methods: {
    indicateRightAnswer() {
      if (this.question.category == 2) {
        this.indicate = true;
      }
    },
    reply() {
      this.$store.dispatch("shareShootlist", this.question.id_shootlist);
    },
    share() {
      this.$store.dispatch("shareShootlist", this.question.id_shootlist);
    },
  },
  watch: {
    question() {
      this.indicate = false;
    },
  },
  computed: {
    ...mapGetters({
      settings: "shootlist/settings",
      categoriesShootlist: "shootlist-categories/data",
    }),
    translated() {
      if (this.$store.getters["lang-store/data"]) {
        return this.question.translation.find((item) => {
          return item.langKey === this.$store.getters["lang-store/data"];
        });
      }
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
    check() {
      return this.$store.getters["shootlist-categories/getCategoryById"](
        this.question.category
      );
    },
    content() {
      var content = "";
      var lang = this.$ml.current;
      switch(lang)
      {
        case 'heb':
          content = this.question.translation[0].data.content_man!=null && this.question.translation[0].data.content_man!=""?this.question.translation[0].data.content_man:this.question.content_man!=null&&this.question.content_man!=""?this.question.content_man:this.question.content_fem;
          break;
        case 'en':
          content = this.question.translation[1].data.content_man!=null && this.question.translation[1].data.content_man!=""?this.question.translation[1].data.content_man:this.question.content_man!=null&&this.question.content_man!=""?this.question.content_man:this.question.content_fem;
          break;
        case 'ru':
          content = this.question.translation[2].data.content_man!=null && this.question.translation[2].data.content_man!=""?this.question.translation[2].data.content_man:this.question.content_man!=null&&this.question.content_man!=""?this.question.content_man:this.question.content_fem;
          break;
        case 'ar':
          content = this.question.translation[3].data.content_man!=null && this.question.translation[3].data.content_man!=""?this.question.translation[3].data.content_man:this.question.content_man!=null&&this.question.content_man!=""?this.question.content_man:this.question.content_fem;
          break;
        default:
          content = this.question.translation[0].data.content_man!=null && this.question.translation[0].data.content_man!=""?this.question.translation[0].data.content_man:this.question.content_man!=null&&this.question.content_man!=""?this.question.content_man:this.question.content_fem;
          break;
      }
      return content;
    },
    advertisment() {
      return this.advCondition && this.show_adv;
    },
    advCondition() {
      return (
        !this.$store.getters.replyCounter &&
        this.question.index != 1 &&
        this.question.index % 5 == 0
      );
      // return true;
    },
  },
  created() {
    if (this.advCondition) {
      this.show_adv = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #005d7a;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    left:40px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
@keyframes rightBtnPop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shootlist-item {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &.swiping {
    transition: transform 1s ease, opacity 1s ease;
  }
  &.swipe-left {
    transform: translateX(150%) rotate(60deg) !important;
    opacity: 0;
  }
  &.swipe-right {
    transform: translateX(-150%) rotate(-60deg) !important;
    opacity: 0;
  }

  &.animate-right {
    transform: translateX(-150%) rotate(-60deg);
    opacity: 0;
  }
  &.animate-left {
    transform: translateX(150%) rotate(60deg);
    opacity: 0;
  }
  &.animate {
    transition: transform 2s ease, opacity 2s ease;
  }

  &.animate,
  &.grapped,
  &.swiping {
    .body {
      z-index: 1000;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    }
  }
  .reply {
    display: flex;
    align-items: center;
    position: absolute;
    left: 15px;
    bottom: 15px;
    color: white;
    span {
      font-size: 21px;
      margin-left: 5px;
    }
  }
  .wrapper {
    height: 100%;

    .body,
    .advertisment {
      width: 100%;
      height: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .content {
        font-family: Artishok;
        font-size: 36px;
        font-weight: bold;
        line-height: 1;
        Overflow-y: auto;
        
      }
    }

    .first {
      background-color: #b7abff;
    }
    .second {
      background-color: #73e1e8;
    }
    .third {
      background-color: #FFAA2B;
    }
    .fourth {
      background-color: #efb987;
      padding-top: 45px;
    }
    .fifth {
      background-color: #73e878;
    }

    .second {
      display: block;
      .content {
        position: absolute;
        display: block;
        font-size: 26px;
        padding: 0 10px;
        top: 45px;
        width: 100%;
        text-align: center;
        font-weight: normal;
        left: 0;
      }
      .pre-text {
        display: block;
        position: absolute;
        right: 30px;
        top: 10px;
        font-size: 19px;
        color: #332681;
      }
      .right-answer {
        animation: rightBtnPop 0.3s ease;
        position: absolute;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        left: 0;
        top: -40px;
      }
      .variants {
        position: absolute;
        right: 10px;
        bottom: 5px;
        font-size: 20px;
        line-height: 100%;
        .variant {
          &.right {
            color: #cf1c1c;
          }
          span {
            font-weight: bold;
          }
        }
      }
    }

    .first {
      .three-points {
        position: absolute;
        right: 20px;
        top: 10px;
        font-size: 19px;
      }
    }

    .advertisment {
      background-color: #cee7ef;
      flex-direction: column;
      background-position: center bottom;
      background-size: contain;
      padding: 0 20px;
      p {
        margin: 0;
        margin-top: 5px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #005d7a;
        font-size: 16px;
        span {
          font-size: 26px;
          line-height: 100%;
        }
      }

      .yea {
        margin-top: 10px;
        border-color: #005d7a;
        color: #005d7a;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
      }
      .nope {
        margin-top: 10px;
        color: #005d7a;
        font-size: 16px;
      }
    }

    .category {
      border-radius: 15px;
      position: absolute;
      left: 10px;
      top: 10px;

      font-size: 15px;
      padding: 0 15px;
      line-height: 30px;
      height: 30px;
      font-weight: bold;
      text-align: right;
      color: #01465b;
    }
  }
}
</style>
