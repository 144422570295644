import { TeacherAPI } from '@/api';
import store from "@/store/index";
const LearningSkills = {
  namespaced: true,
  state: {
    data: null,
    loaded: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },
    loaded(state)
    {
      state.loaded = true;
    }
   
  },
  actions: {
    async updateData({ commit }) {
      const popular = await TeacherAPI.learningSkills()
        .then(res => res.data).catch(err=>console.log(err));
      commit('setData', popular);
      setTimeout(()=>{
        commit('loaded');
      },1500)
    },
  },
  getters: {

    data(state) {
      return state.data;
    },
    isLoaded(state) {
      return state.loaded
    }
  }
};

export { LearningSkills };
