import { TeacherAPI } from '@/api';

const GamesClassSections = {
  namespaced: true,
  state: {
    data: [],
    loaded: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },

    load(state) {
      state.loaded = true;
    },
  },
  actions: {

    async updateData({ commit }) {
      const sections = await TeacherAPI.sections()
        .then(res => res.data);
      commit('setData', sections);
      commit('load');
    },

  },
  getters: {

    data(state) {
      return state.data;
    },

    isLoaded(state) {
      return state.loaded;
    },
    getColorById: state => id => {
    return state.data.find(n => n.id_type == id).color;
    }

  }
};

export { GamesClassSections };
