var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shootlist-item"},[_c('div',{staticClass:"wrapper",attrs:{"dir":"rtl"}},[(_vm.advertisment)?[_c('div',{staticClass:"advertisment img-shootlist-share-bg"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$ml.get("נהנים"))+"?")]),_c('br'),_vm._v(" "+_vm._s(_vm.$ml.get("גם אחרים יהנו בזכותכם"))+"! "),_c('br'),_vm._v(" "+_vm._s(_vm.$ml.get("שתפו את אפליקצית פליי-ליסט"))+"... ")]),_c('button',{staticClass:"yea theme-button-outline",on:{"click":_vm.share}},[_vm._v(" "+_vm._s(_vm.$ml.get("רוצה לשתף"))+" ")]),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('button',{staticClass:"nope",on:{"click":function($event){_vm.show_adv = false}}},[_vm._v(" "+_vm._s(_vm.$ml.get("לא עכשיו"))+" ")])])]:[(_vm.question.category!=null && _vm.question.category.includes(1))?_c('div',{staticClass:"body img-shootlist-bg",style:({
          backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}})]):(_vm.question.category!=null && _vm.question.category == 999)?_c('div',{staticClass:"body img-shootlist-bg",style:({
          backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('div',{staticClass:"variants"},[_c('div',{staticClass:"variant",class:{ right: _vm.question.answers.right == 1 && _vm.indicate }},[_c('span',[_vm._v("1.")]),_vm._v(" "+_vm._s(_vm.question.answers.first)+" ")]),_c('div',{staticClass:"variant",class:{ right: _vm.question.answers.right == 2 && _vm.indicate }},[_c('span',[_vm._v("2.")]),_vm._v(" "+_vm._s(_vm.question.answers.second)+" ")]),_c('div',{staticClass:"variant",class:{ right: _vm.question.answers.right == 3 && _vm.indicate }},[_c('span',[_vm._v("3.")]),_vm._v(" "+_vm._s(_vm.question.answers.third)+" ")])]),(_vm.first)?_c('button',{staticClass:"right-answer theme-button-outline",on:{"click":_vm.indicateRightAnswer}},[_vm._v(" "+_vm._s(_vm.$ml.get("גלה לי את התשובה"))+" ")]):_vm._e()]):(_vm.question.category!=null && _vm.question.category.includes(7))?_c('div',{staticClass:"third body img-shootlist-bg",style:({
          backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}})]):(_vm.question.category!=null && _vm.question.category.includes(4))?_c('div',{staticClass:"body img-shootlist-bg",style:({
           backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}})]):(_vm.question.category!=null && _vm.question.category.includes(13))?_c('div',{staticClass:"body img-shootlist-bg",style:({
          backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}})]):_c('div',{staticClass:"body img-shootlist-bg",style:({
          backgroundColor: _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category) != null? _vm.$store.getters[
            'shootlist-categories/getCategoryById'
          ](_vm.question.category).color : '#3ecfb9',
        })},[_c('div',{staticClass:"category"},_vm._l((_vm.question.category),function(cat){return _c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v(_vm._s(_vm.$store.getters["shootlist-categories/getTranslatedNameById"](cat)))])}),0),_c('button',{staticClass:"reply",on:{"click":_vm.reply}},[_c('span',{staticClass:"material-icons"},[_vm._v(" share ")]),_vm._v(" "+_vm._s(_vm.$ml.get("שיתוף"))+" ")]),_c('span',{staticClass:"content",style:({ direction: _vm.direction }),domProps:{"innerHTML":_vm._s(_vm.content)}})])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }