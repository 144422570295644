<template>
  <main class="games-classes" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
        {{$ml.get('משחקים לכיתה ')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading style="background: #fff;"
      :style="{background : loading.sync ? '' : Gifts[Gifts.length-1].color}"
      class="sm-cont">
      <template>
        <div class="games">
          <div class="header">
            <div class="title">
              {{$ml.get('בחרו בצורך החינוכי או הלימודי למישחוק')}}
            </div>
            <!-- <div class="undertitle">

              <div class="element">

                <div class="round green"></div>
                <span> {{$ml.get('סך הפעמים שתלמידים שיחקו בבית יוצג על הערך והמשחק')}}</span></div>
              <div class="element">
                <div class="round yellow"></div>

                <span>  {{$ml.get('הוצב יעד ע”י ההנהלה')}}  </span></div>

            </div> -->
          </div>
          <app-loading v-if="loading.sync">
            <div class="gifts-wrapper">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </div>
            </app-loading>


            <app-loading v-else>
          <div   :key="i*4"
                 v-for="(section, i) in Gifts" class="gifts-wrapper">
                 
            <div

              :style="{background: section.color}"
              class="gifts-section">
              <TitleTranslated class="title" :translate="section.translation" />

              <!-- <div class="stats-block">

                <div class="name">{{$ml.get('יעד חודשי')}}</div>
                <div class="numbers">12/23</div>
                <div class="status-bar">
                  <div class="bg"></div>
                </div>
              </div> -->
              <div class="gifts" >
                <GiftElement
                  :class="'games'"
                  :key="i*2" v-for="(gift, i) in section.gifts"
                  :rec="true"
                  :gift="gift" />
              </div>
            </div>
          </div>
        </app-loading>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GiftElement from "@/components/teacher/GiftElement";
  import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
  import {TeacherAPI} from "@/api";
  import TitleTranslated from "@/components/translated/TitleTranslated";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        testData:[1,2,3,4,5,6],
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        gifts:[
          {title: 'הפופולריים ביותר ',color:'#6D597A',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#52AA8A',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#FDC671',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '} ]},
        ],
        Gifts: [],
      };
    },
    components: {
      TitleTranslated,
      SimpleHeader,
      SidebarToggler,
      GiftElement,
      GiftElementSkeleton
    },
    computed:{
      ...mapGetters({
      eduData: "games-for-classes/data",
    }),
      teacherTargets(){
        return this.$store.getters['teacher-targets/targets'];
      }
    },
    methods: {
      alphabetically(ascending) {
      return function (a, b) {
        // equal items sort equally
        if (a === b) {
            return 0;
        }

        // nulls sort after anything else
        if (a === null) {
            return 1;
        }
        if (b === null) {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first
        if (ascending) {
            return a < b ? -1 : 1;
        }

        // if descending, highest sorts first
        return a < b ? 1 : -1;
      };
    }
    },
    created() {
      window.fbq("track", "משחקים לכיתה");
      if (this.$store.getters['games-for-classes/isLoaded']) {
        let gifts = this.eduData;
           gifts.forEach(element=>{
            let newArrayDataOfOjbect = Object.values(element.gifts)
            element.gifts = newArrayDataOfOjbect.sort((a, b) => (a.order_index != null ? a.order_index : Infinity) - (b.order_index != null ? b.order_index : Infinity))
          })
        this.Gifts = gifts.sort((a,b)=>a.orderIndex > b.orderIndex? 1: -1);
        this.loading.sync = false;
      }
      else
      {
      this.loading.sync = true;
      TeacherAPI.games().then(res=>{
        let gifts = res.data;
          gifts.forEach(element=>{
            let newArrayDataOfOjbect = Object.values(element.gifts)
            element.gifts = newArrayDataOfOjbect.sort((a, b) => (a.order_index != null ? a.order_index : Infinity) - (b.order_index != null ? b.order_index : Infinity))
          })
        this.Gifts = gifts.sort((a,b)=>a.orderIndex > b.orderIndex ? 1: -1);
        this.loading.sync = false;
      })
    }
    },
  };
</script>
<style scoped lang="scss">

  .games-classes {
    background: white;
    display: flex;
    flex-direction: column;

    .app-loading {

      flex-grow: 1;
      min-height: 450px;


      .games {
        .header {
          background: #00465B;
          padding: 5px 10px 8px 10px;

          .title {
            margin: 9px auto;
            font-size: 21px;
            line-height: 29px;
            text-align: center;
            color: #FFFFFF;
            @media screen and (max-width: 330px){
              width: unset;
            }
          }
          .undertitle {
            width: 310px;
            margin: 0 auto;
            @media screen and (max-width: 330px){
              width: unset;
            }
            .element {
              display: flex;
              align-items: center;
              font-size: 12px;
              line-height: 16px;
              text-align: right;
              color: #FFFFFF;
              font-style: italic;
              margin: 2px 0;
              .round {
                margin-left: 10px;
                min-width: 10px;
                max-width: 10px;
                height: 10px;
                border-radius: 50%;
                @media screen and (max-width: 330px){
                  margin-left: 4px;
                }
              }

              .green {
                background: #8CC63F;
              }

              .yellow {
                background: #FBB03B;
              }
            }
          }
        }
        .gifts-wrapper{
          .gifts-section{
            position: relative;
            background: #B56576;
            padding: 0 10px;
            .title{
              font-weight: bold;
              padding: 10px 0 10px;
              font-size: 21px;
              text-align: center;
              color: #FFFFFF;
            }
            .stats-block{
              position: absolute;
              top: 10px;
              left: 30px;
              .name{
                font-size: 12px;
                text-align: center;
                color: #FFFFFF;
              }
              .numbers{
                font-size: 12px;
                text-align: center;
                color: #FFFFFF;
              }
              .status-bar{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 64px;
                height: 8px;
                background: #C4C4C4;
                border-radius: 4px;
                .bg{
                  background: rgb(140, 198, 63);
                  width: 50%;
                  height: 100%;
                  border-radius: 4px 0px 0px 5px;
                }
              }
            }
            .gifts{
              padding: 15px 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              flex-wrap: wrap;
              .gift-element{
                margin: 0 16px;
                @media screen and (max-width: 420px){
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
</style>