<template>
  <div class="promocode">
    <div  v-if="promo.school!==null" class="promocode-content">
      <div v-if="promo.school!==''" class="title">
        <span class="code_element"> {{promo.school.name}}, </span>
        <span  class="code_element">{{promo.school.city}} </span>
        <div style="display: inline-block" v-if="promo.position" class="position ">{{position}}</div>
      </div>
      <div v-if="promo.position === 'student' || promo.position === 'teacher'" class="select_wrapper">
        <SelectRegister
          @change="$emit('input', value)"
          :required="true"
          :placeholder="$ml.get('כיתה')"
          :options="classArr.letter.split(' ')"
          v-model="value.letter"/>

        <SelectRegister
          @change="$emit('input', value)"
          :placeholder="$ml.get('מספר')"
          :required="true"
          :options="classArr.number"
          v-model="value.number"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Promocode",
  props: {
    value: {
      number:{},
      letter:{},
    },
    promo: {
      position:{},
      city:{},
      school:{},
    },
  },
  computed:{
    position(){

      switch (this.promo.position) {
        case "teacher":
         return this.$ml.get('מחנכת');
        case "prof-teacher":
          return this.$ml.get('מורה מקצועית');
        case "director":
          return this.$ml.get('מנהל');
        case "student":
          return '';
        default:
          return ''
      }

    }
  },
  data(){
    return{
      classArr:{
        number: [1,2,3,4,5,6,7],
        letter: ["’יב","יא’","י’","ט’","ח’","ז’","ו’","ה’","ד’","ג’","ב’","’א"].reverse().join(' '),
      },
      selected:{
        letter:'',
        number: '',
      }
    }
  },
}
</script>

<style scoped lang="scss">
.promocode {
  width: 290px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .promocode-content{
    width: 220px;
    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin-left: 7px;
      .position{
        color: white;
        margin-right: 7px;
        font-style: italic;
        font-weight: bold;
      }
      .code_element{
        padding: 0 2px;
        color: #FDC671;
      }
    }
    .select_wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .select-register{
        width: 100px ;
      }
    }
  }
}
  
</style>