const animations = {
  back: "slide-back",
  forward: "slide-front",
  default: "slide-front"
};

var callback = false;

export default {
  namespaced: true,
  state: {
    animation: "",
    enable_tick: false
  },
  mutations: {
    set(state, name) {
      state.animation = name;
    },
    back(state) {
      state.animation = animations.back;
    },
    forward(state) {
      state.animation = animations.forward;
    },
    default(state) {
      state.animation = animations.default;
    }
  },
  actions: {
    throwEvent(context) {
      ebus.triggerEvent("route-anim-end");
    },
    nextTick(context, func) {
      callback = func;
    },
    init(context) {
      window.addEventListener(
        "popstate",
        e => {
          context.commit("back");
          context.commit("default");
        },
        false
      );

      ebus.addEventListener(
        "route-anim-end",
        e => {
          if (callback) {
            callback();
            callback = false;
          }
        },
        false
      );
    }
  },
  getters: {
    current(state) {
      return state.animation;
    }
  }
};
