<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      <div class="selected" :class="{ open: open }" @click="open = !open">
        {{ selected }}
      </div>
      <div class="items" :class="{ selectHide: !open }">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('input', option);
          "
        >
          {{ option }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true,
      },
      default: {
        type: String,
        required: false,
        default: null,
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data() {
      return {
        selected: this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0]
          : null,
        open: false,
      };
    },
    mounted() {
      this.$emit("input", this.selected);
    },
  };
  </script>
  
  <style scoped>
  .custom-select {
    position: relative;
    min-width: 253px !important;
    max-width: 253px !important;
    text-align: right;
    outline: none;
    max-height: 46px !important;
    min-height: 46px !important;
    line-height: 47px;
  }
  
  .custom-select .selected {
    z-index: 12;
    direction: rtl;
    background-color: white;
    border-radius: 4px;
    max-height: 46px !important;
    min-height: 46px !important;
    border: 1px solid #000;
    /* color: black; */
    padding-right: 1em;
    cursor: pointer;
    user-select: none;
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 22px; */
    /* identical to box height */

    /* text-align: right; */

    color: #00465B;

  }
  
  .custom-select .selected.open {
    border: 1px solid #000;
    border-radius: 4px 4px 4px 4px;
  }
  
  .custom-select .selected:after {
    z-index: 12;
    position: absolute;
    content: "";
    top: 22px;
    left: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #000 transparent transparent transparent;
  }
  
  .custom-select .items {
    color: #000;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 43px;

    min-height: 500px;
    
    overflow-y: scroll;
    right: 0;
    z-index: 12;
  }
  
  .custom-select .items div {
    z-index: 12;
    padding-right: 1em;
    direction: rtl;
    cursor: pointer;
    user-select: none;
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 32px;
    /* line-height: 22px; */
    /* identical to box height */

    /* text-align: right; */

    color: #00465B;
  }
  
  .custom-select .items div:hover {
    background-color: #ad8225;
  }
  
  .selectHide {
    display: none;
  }
  </style>
  