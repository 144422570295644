<template>
   <main>
    <svg width="263" height="95" viewBox="0 0 263 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M155.754 94.1862C153.98 94.1862 152.578 93.6646 151.547 92.6213C150.516 91.578 150 90.1591 150 88.3646V56.0016C150.454 55.9181 151.175 55.8138 152.165 55.6886C153.155 55.5216 154.104 55.4382 155.011 55.4382C155.96 55.4382 156.785 55.5216 157.486 55.6886C158.228 55.8138 158.847 56.0642 159.342 56.4398C159.837 56.8153 160.208 57.337 160.456 58.0047C160.703 58.6724 160.827 59.5488 160.827 60.6338V85.3599H174.995C175.283 85.819 175.551 86.445 175.799 87.2379C176.046 87.989 176.17 88.782 176.17 89.6166C176.17 91.2859 175.82 92.4752 175.118 93.1847C174.417 93.8524 173.489 94.1862 172.334 94.1862H155.754Z" fill="white"/>
    <path d="M192.546 93.748C192.093 93.8732 191.371 93.9984 190.381 94.1236C189.432 94.2488 188.484 94.3114 187.535 94.3114C186.586 94.3114 185.741 94.228 184.998 94.061C184.297 93.9358 183.699 93.6854 183.204 93.3099C182.709 92.9343 182.338 92.4126 182.091 91.7449C181.843 91.0772 181.719 90.2008 181.719 89.1158V56.0642C182.173 55.9807 182.895 55.8764 183.885 55.7512C184.875 55.5842 185.823 55.5008 186.731 55.5008C187.679 55.5008 188.504 55.5842 189.205 55.7512C189.948 55.8764 190.567 56.1268 191.062 56.5023C191.556 56.8779 191.928 57.3996 192.175 58.0673C192.423 58.735 192.546 59.6114 192.546 60.6964V93.748Z" fill="white"/>
    <path d="M211.211 78.9124C209.396 78.2864 207.747 77.6604 206.262 77.0344C204.777 76.3667 203.498 75.5738 202.426 74.6557C201.354 73.7376 200.508 72.6526 199.889 71.4006C199.312 70.1069 199.023 68.542 199.023 66.7058C199.023 63.1586 200.364 60.3208 203.045 58.1925C205.767 56.0642 209.561 55 214.428 55C216.202 55 217.852 55.1252 219.378 55.3756C220.904 55.626 222.203 56.0224 223.275 56.5649C224.389 57.0657 225.255 57.7334 225.874 58.5681C226.493 59.361 226.802 60.2999 226.802 61.385C226.802 62.47 226.554 63.409 226.059 64.2019C225.565 64.9531 224.966 65.5999 224.265 66.1424C223.358 65.5582 222.141 65.0574 220.615 64.6401C219.089 64.181 217.419 63.9515 215.604 63.9515C213.748 63.9515 212.387 64.2227 211.52 64.7653C210.654 65.266 210.221 65.9129 210.221 66.7058C210.221 67.3318 210.489 67.8534 211.026 68.2707C211.562 68.6463 212.366 69.001 213.438 69.3349L216.717 70.3991C220.594 71.651 223.564 73.2577 225.626 75.2191C227.73 77.1388 228.782 79.7679 228.782 83.1064C228.782 86.6536 227.4 89.5331 224.637 91.7449C221.873 93.915 217.81 95 212.449 95C210.551 95 208.778 94.8331 207.128 94.4992C205.519 94.2071 204.096 93.7689 202.859 93.1847C201.663 92.5587 200.714 91.8075 200.013 90.9311C199.353 90.013 199.023 88.9698 199.023 87.8013C199.023 86.591 199.374 85.5686 200.075 84.734C200.776 83.8576 201.539 83.1899 202.364 82.7308C203.519 83.6489 204.921 84.4418 206.571 85.1096C208.262 85.7773 210.098 86.1111 212.077 86.1111C214.098 86.1111 215.521 85.7981 216.346 85.1721C217.171 84.5462 217.584 83.8159 217.584 82.9812C217.584 82.1466 217.254 81.5206 216.594 81.1033C215.934 80.6442 215.006 80.2061 213.81 79.7887L211.211 78.9124Z" fill="white"/>
    <path d="M232.128 64.8279C231.839 64.3688 231.571 63.7428 231.324 62.9499C231.076 62.157 230.952 61.3224 230.952 60.446C230.952 58.8185 231.303 57.65 232.004 56.9405C232.747 56.2311 233.695 55.8764 234.85 55.8764H261.825C262.113 56.3354 262.381 56.9614 262.629 57.7543C262.876 58.5472 263 59.3818 263 60.2582C263 61.8858 262.629 63.0543 261.886 63.7637C261.185 64.4731 260.257 64.8279 259.102 64.8279H252.235V93.748C251.781 93.8732 251.059 93.9984 250.07 94.1236C249.121 94.2488 248.172 94.3114 247.224 94.3114C246.275 94.3114 245.429 94.228 244.687 94.061C243.986 93.9358 243.388 93.6854 242.893 93.3099C242.398 92.9343 242.027 92.4126 241.779 91.7449C241.532 91.0772 241.408 90.2008 241.408 89.1158V64.8279H232.128Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M168.203 34.7661C159.043 29.6695 148.719 26.3554 142.022 24.8703L142.433 23C149.267 24.5156 159.772 27.8859 169.126 33.0904C178.434 38.2688 186.847 45.3998 189.081 54.5431L187.23 55C185.196 46.6758 177.41 39.8888 168.203 34.7661ZM155.323 33.7097C142.991 29.2864 129.575 27.0876 121 26.4222L121.147 24.5126C129.851 25.1879 143.439 27.4134 155.964 31.9059C168.428 36.3765 180.124 43.1921 184.641 53.2277L182.904 54.0171C178.731 44.7464 167.717 38.1549 155.323 33.7097ZM193.13 43.6758C192.263 46.6816 191.564 49.7757 191.187 51.7004L189.317 51.3307C189.703 49.3609 190.414 46.2111 191.3 43.1422C192.174 40.1136 193.255 37.0258 194.414 35.2788L196 36.3413C195.024 37.812 194.009 40.6298 193.13 43.6758Z" fill="white"/>
    <g filter="url(#filter0_d)">
    <path d="M207 28C207 31.866 203.866 35 200 35C196.134 35 193 31.866 193 28C193 24.134 196.134 21 200 21C203.866 21 207 24.134 207 28Z" fill="white"/>
    </g>
    <path d="M13.9689 73.3602C15.692 73.3602 17.0048 72.97 17.9073 72.1896C18.8509 71.3681 19.3227 70.1359 19.3227 68.4929C19.3227 66.9321 18.8304 65.7409 17.8458 64.9194C16.9022 64.0569 15.5074 63.6256 13.6612 63.6256C13.0048 63.6256 12.451 63.6461 11.9997 63.6872C11.5895 63.6872 11.1587 63.7283 10.7075 63.8104V73.3602H13.9689ZM10.769 93.1374C10.3177 93.2607 9.59979 93.3839 8.6152 93.5071C7.67163 93.6303 6.72806 93.6919 5.78449 93.6919C4.84092 93.6919 3.99991 93.6098 3.26147 93.4455C2.56405 93.3223 1.96919 93.0758 1.47689 92.7062C0.984594 92.3365 0.615371 91.8231 0.369223 91.1659C0.123074 90.5087 0 89.6461 0 88.5782V59.5592C0 58.6967 0.225636 58.0395 0.676908 57.5877C1.16921 57.0948 1.8256 56.7046 2.6461 56.4171C4.04094 55.9242 5.62039 55.575 7.38446 55.3697C9.18955 55.1232 10.9946 55 12.7997 55C18.4611 55 22.7893 56.2117 25.7841 58.6351C28.7789 61.0585 30.2763 64.3444 30.2763 68.4929C30.2763 70.5055 29.9481 72.3333 29.2917 73.9763C28.6763 75.5782 27.7327 76.9747 26.461 78.1659C25.2302 79.316 23.6508 80.2196 21.7226 80.8768C19.8355 81.4929 17.6406 81.8009 15.1381 81.8009H10.769V93.1374Z" fill="white"/>
    <path d="M41.4794 93.5687C39.7153 93.5687 38.3205 93.0553 37.2949 92.0284C36.2693 91.0016 35.7564 89.6051 35.7564 87.8389V55.9858C36.2077 55.9036 36.9256 55.8009 37.9102 55.6777C38.8948 55.5134 39.8384 55.4313 40.741 55.4313C41.6845 55.4313 42.505 55.5134 43.2024 55.6777C43.9409 55.8009 44.5563 56.0474 45.0486 56.4171C45.5409 56.7867 45.9101 57.3002 46.1562 57.9573C46.4024 58.6145 46.5254 59.4771 46.5254 60.545V84.8815H60.6175C60.9046 85.3333 61.1713 85.9494 61.4174 86.7299C61.6636 87.4692 61.7867 88.2496 61.7867 89.0711C61.7867 90.7141 61.4379 91.8847 60.7405 92.5829C60.0431 93.2401 59.12 93.5687 57.9714 93.5687H41.4794Z" fill="white"/>
    <path d="M75.8455 57.1564C76.5429 56.7457 77.589 56.3554 78.9839 55.9858C80.4197 55.6161 81.8556 55.4313 83.2915 55.4313C85.3427 55.4313 87.1478 55.7393 88.7067 56.3555C90.3067 56.9305 91.3323 57.8547 91.7836 59.128C92.522 61.2638 93.322 63.7488 94.1836 66.5829C95.0451 69.4171 95.9066 72.3539 96.7681 75.3934C97.6296 78.3918 98.4706 81.3697 99.2911 84.327C100.112 87.2433 100.83 89.831 101.445 92.09C100.953 92.6651 100.214 93.1169 99.2296 93.4455C98.245 93.8152 97.0758 94 95.722 94C94.7374 94 93.8964 93.9179 93.199 93.7536C92.5426 93.5893 91.9887 93.3428 91.5375 93.0142C91.1272 92.6856 90.7785 92.2543 90.4913 91.7204C90.2452 91.1864 90.0195 90.5703 89.8144 89.872L88.7067 85.6209H77.5685C77.2403 86.8531 76.9121 88.1264 76.5839 89.4408C76.2557 90.7551 75.9275 92.0284 75.5993 93.2607C75.025 93.466 74.4096 93.6303 73.7532 93.7536C73.1378 93.9179 72.3789 94 71.4763 94C69.3841 94 67.8251 93.6509 66.7995 92.9526C65.8149 92.2133 65.3226 91.207 65.3226 89.9337C65.3226 89.3586 65.4047 88.7836 65.5688 88.2085C65.7329 87.6335 65.9175 86.9558 66.1226 86.1754C66.4098 85.0663 66.7995 83.6904 67.2918 82.0474C67.8251 80.4044 68.3995 78.6382 69.0149 76.7488C69.6302 74.8183 70.2866 72.8468 70.9841 70.8341C71.6815 68.8215 72.3379 66.9321 72.9532 65.1659C73.5686 63.3586 74.1224 61.7567 74.6147 60.3602C75.1481 58.9637 75.5583 57.8957 75.8455 57.1564ZM83.2299 65.1043C82.6556 66.8294 81.9992 68.7599 81.2608 70.8957C80.5633 72.9905 79.9275 75.1264 79.3531 77.3033H86.9837C86.4094 75.0853 85.8145 72.9289 85.1991 70.8341C84.6248 68.6983 84.0915 66.7883 83.5992 65.1043H83.2299Z" fill="white"/>
    <path d="M123.293 93.1374C122.841 93.2607 122.144 93.3839 121.2 93.5071C120.257 93.6303 119.334 93.6919 118.431 93.6919C116.544 93.6919 115.129 93.3839 114.185 92.7678C113.241 92.1106 112.77 90.7551 112.77 88.7014V80.3839C111.744 78.8641 110.636 77.1801 109.447 75.3318C108.257 73.4834 107.088 71.594 105.939 69.6635C104.79 67.733 103.724 65.8436 102.739 63.9953C101.755 62.1058 100.955 60.3807 100.339 58.8199C100.873 58.0806 101.59 57.4028 102.493 56.7867C103.437 56.1706 104.585 55.8626 105.939 55.8626C107.539 55.8626 108.831 56.1912 109.816 56.8483C110.842 57.5055 111.806 58.7378 112.708 60.545L117.816 70.8341H118.185C118.759 69.5608 119.252 68.4107 119.662 67.3839C120.113 66.316 120.544 65.2686 120.954 64.2417C121.364 63.1738 121.795 62.0853 122.246 60.9763C122.698 59.8262 123.211 58.5118 123.785 57.0332C124.523 56.6635 125.344 56.376 126.246 56.1706C127.149 55.9652 128.01 55.8626 128.831 55.8626C130.267 55.8626 131.477 56.2528 132.462 57.0332C133.487 57.7725 134 58.9021 134 60.4218C134 60.9147 133.897 61.5103 133.692 62.2085C133.487 62.9068 133.015 63.9747 132.277 65.4123C131.539 66.8088 130.451 68.7188 129.016 71.1422C127.621 73.5656 125.713 76.7283 123.293 80.6303V93.1374Z" fill="white"/>
    <defs>
    <filter id="filter0_d" x="154" y="0" width="74" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dx="-9" dy="9"/>
    <feGaussianBlur stdDeviation="15"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.21 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>

    <div class="title fugaz" v-if="$ml.current == 'eng'" style="width: 75px !important;text-align: right;font-family: 'App';font-weight: bold;">
      טוען
      <span v-for="dot in dots">. </span>
    </div>
    <div class="title fugaz" v-else style="width: 130px !important; text-align: left;">
      <span v-for="dot in dots">. </span>
      LOADING
    </div>
  </main>
</template>

<script>
import Unloaded from "./roots/Unloaded.vue";
import { mapGetters } from "vuex";
export default {
  data(){
    return{
        interval: null,
      dots: []
    }
  },
  components: {
    Unloaded,
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.dots.length >= 3) {
        this.dots = [];
      } else {
        this.dots.push(null);
      }
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
main {
  background: #005d7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  img {
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    color: #fff;
  }
}
</style>

