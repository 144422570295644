const PlaylistAPI = {

  getRandomGame(data) {
    return config.API.post("/playlist/random", data)
  },

  getState(data) {
    return config.API.post("/playlist/filter-state/get", data)
  },

  addActivity(playlist_id, type, data) {
    return  config.API.put("/playlist/" + playlist_id + "/activity/" + type, data);
  },
  saveSettings(data) {
    return config.API.post('/user/saveFilter', data)
  },
  getLocations(section) {
    return config.API.get("/playlist/settings/" +  section)
  },

  selectGifts() {
    return config.API.get("/playlist/categories");
  },

  getAllLocations() {
    return  config.API.get("/playlist/settings/all" );
  },

  countByParam(data) {
    return  config.API.post("/playlist/countByParam",data);
  },

  selectSections() {
    return config.API.get("/playlist/category/sections");
  },
  updateGifts(data) {
    return config.API.post("/playlist/user/categories", data)
  },

};

export { PlaylistAPI }