<template>
 <div class="search-i">

   <input
     :style="{direction:direction}"
     :value="value"
     @input="$emit('input', $event.target.value)"
     @blur="$emit('blured', '')"
     :placeholder="placehold"
     type="text">
   <button @click="clear" v-if="icon" class="icon">
     <svg style="margin-left: 5px;
    margin-top: 2px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
       <line x1="4.95121" y1="4.54879" x2="14.1436" y2="13.7412" stroke="#00465B"/>
       <line x1="14.1426" y1="4.64262" x2="4.95023" y2="13.835" stroke="#00465B"/>
     </svg>
   </button>
   <button v-else class="icon">
     <svg style="margin-left: 5px;
    margin-top: 3px;" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
       <circle cx="8.7313" cy="8.23242" r="5" transform="rotate(-45 8.7313 8.23242)" stroke="black"/>
       <line x1="12.2677" y1="11.7676" x2="15.8032" y2="15.3031" stroke="black" stroke-linecap="round"/>
     </svg>
   </button>
 </div>
</template>

<script>
export default {
  props: {
    value: {},
    placehold:{},
  },
  methods:{
    clear(){
      this.$emit('input', '');
    }
  },
  computed:{
    icon(){
      return this.value.trim().length>0;
    },
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  },
}
</script>

<style lang="scss" scoped>
  //
 .search-i{
   position: relative;
   max-width: 150px;
    height: 30px;
   input{
     width: 150px;
     height: 30px;
     margin: 0 auto;
     background: #FFFFFF;
     border: 1px solid #A2A2A2;
     box-sizing: border-box;
     font-style: italic;
     border-radius: 15px;
     padding: 7px 12px 7px 40px;
     font-size: 14px;
     line-height: 19px;
     text-align: right;
     color: #00465B;
     &::placeholder{
      margin-right: 5px !important;
       font-weight: normal;
       font-size: 12px;
       line-height: 16px;
       color: #C4C4C4;
     }
   }
   .icon{
     position: absolute;
     top: 5px;
     left: 0;
   }
 }
</style>