<template>
  <div class="name">
    {{translated!=='' ? translated : this.translate[0].data.title }}
  </div>
</template>

<script>
  export default {
    name: "NameTranslated",
    props:{
      translate: {}
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.translate.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.title
        }
      },
    }
  }
</script>

<style scoped>

</style>