<template>
  <div class="shootlist" style="color:#63ADE6">
    {{getName}}
  </div>
</template>

<script>
  export default {
    name: "ShootEl",
    props:{
     item:{}
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.item.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          })
        }
      },
      getName(){
        if (this.$store.getters["user/data"].gender == "fem") {
          return  this.translated.data.content_fem ? this.translated.data.content_fem : this.question.translation[0].data.content_fem;
        } else {
          return  this.translated.data.content_man ? this.translated.data.content_man : this.question.translation[0].data.content_man;
        }
      }
    }
  }
</script>

<style scoped>

</style>