<template>
  <div class="modal">
    <p>
      {{$ml.get('קובעים עם הילדים זמנים קבועים בשבוע למשחקים מחוץ למסך עם פלייליסט')+'.' }}
    </p>
    <p>
      {{ $ml.get('פרק זמן זה נקרא:')+' ' }}<b>{{"Game changer"}}</b>
    </p>
    
    <app-btn @click="$emit('close')">
      הבנתי
    </app-btn>
  </div>
</template>

<script>
export default {
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  p {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;

    &.italic {
      font-style: italic;
    }
  }
  button {
    margin-top: 10px;
    background: #3FBCD4 !important;
  }
}
</style>
