<template >
  <transition name="fade-move-left" mode="out-in">
  <div style="margin-bottom: 20px;min-width:280px; color:white;" v-if="message.trim()!==''" class="validate" :class="{'center':centered}">
    <div v-if="bg==='black'" class="message">
      <svg width="29" height="26" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3656 3C13.1354 1.66667 15.0599 1.66667 15.8297 3L24.49 18C25.2598 19.3333 24.2975 21 22.7579 21H5.4374C3.8978 21 2.93555 19.3333 3.70535 18L12.3656 3Z" fill="white"/>
        <path d="M14.8525 14.4478H13.0654L12.6919 7.29199H15.2261L14.8525 14.4478ZM12.6626 16.9453C12.6626 16.54 12.7725 16.2324 12.9922 16.0225C13.2119 15.8125 13.5269 15.7075 13.937 15.7075C14.3521 15.7075 14.6694 15.8149 14.8892 16.0298C15.1138 16.2446 15.2261 16.5498 15.2261 16.9453C15.2261 17.3359 15.1113 17.6436 14.8818 17.8682C14.6572 18.0879 14.3423 18.1978 13.937 18.1978C13.5366 18.1978 13.2241 18.0903 12.9995 17.8755C12.7749 17.6558 12.6626 17.3457 12.6626 16.9453Z" fill="#01465B"/>
      </svg>
      {{message}}
    </div>
    <div v-else class="message red">
      <svg width="28" height="26" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2679 3C13.0377 1.66667 14.9623 1.66667 15.7321 3L24.3923 18C25.1621 19.3333 24.1999 21 22.6603 21H5.33975C3.80015 21 2.8379 19.3333 3.6077 18L12.2679 3Z" fill="#FDC671"/>
        <path d="M14.7549 14.4478H12.9678L12.5942 7.29199H15.1284L14.7549 14.4478ZM12.5649 16.9453C12.5649 16.54 12.6748 16.2324 12.8945 16.0225C13.1143 15.8125 13.4292 15.7075 13.8394 15.7075C14.2544 15.7075 14.5718 15.8149 14.7915 16.0298C15.0161 16.2446 15.1284 16.5498 15.1284 16.9453C15.1284 17.3359 15.0137 17.6436 14.7842 17.8682C14.5596 18.0879 14.2446 18.1978 13.8394 18.1978C13.439 18.1978 13.1265 18.0903 12.9019 17.8755C12.6772 17.6558 12.5649 17.3457 12.5649 16.9453Z" fill="white"/>
      </svg>
      {{message}}
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: "Validate",
  props: {
   message:{

   },
    bg:{
     default: 'black',
    },
    centered:{
     default: false,
    }
  },
  data(){
    return{


    }
  },
}
</script>

<style scoped lang="scss">
.validate{

  min-width: 200px;
  &.center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .message{
    &.red{
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      color: #FF5C5C;
    }
  }
}
</style>