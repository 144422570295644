<template>
  <button @click="goGift" class="gift-element">

    <div class="icon-wrapper">
      <div v-if="gift.preferable" class="recommended"></div>
      <img :src="gift.details.img_url" alt="">
      <div v-if="gift.stats.played>0" class="counter">{{gift.stats.played}}</div>
    </div>

    <div class="name">{{translated? translated : gift.details.translation[0].name}}</div>
  </button>
</template>

<script>
export default {
  name: "GiftElement",
  props:{
    type:'',
    rec:{
      default: true,
    },
    gift:{
      src:{},
      name:{},
    },
    counter:{
      default: 12
    },
    classType:{

    },
  },
  methods:{
    goGift(){
      if(this.classType === 'educational') {
        this.$router.push({ name: 'teacher-games-gift', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if (this.classType === 'educational-parents'){
        this.$router.push({ name: 'parents-educational-gift', params: { id: this.gift.id_gift, type: this.type }})
      } 
      else if(this.classType === 'educational-communicationSkills') {
        this.$router.push({ name: 'educational-communicationSkills', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-fromTheHassleGift') {
        this.$router.push({ name: 'educational-fromTheHassleGift', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-gamesForAllOccasionsGift') {
        this.$router.push({ name: 'educational-gamesForAllOccasionsGift', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-sevenPage') {
        this.$router.push({ name: 'educational-sevenPage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-eightPage') {
        this.$router.push({ name: 'educational-eightPage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-ninePage') {
        this.$router.push({ name: 'educational-ninePage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-tenPage') {
        this.$router.push({ name: 'educational-tenPage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-elevenPage') {
        this.$router.push({ name: 'educational-elevenPage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-twelvePage') {
        this.$router.push({ name: 'educational-twelvePage', params: {id: this.gift.id_gift, type: this.type }})
      }
      else if(this.classType === 'educational-learninSkills') {
        this.$router.push({ name: 'educational-learninSkills', params: {id: this.gift.id_gift, type: this.type }})
      }
    }
  },
  mounted(){
  },
  computed:{
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.gift.details.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data.name
      }
    },
  }
}
</script>

<style scoped lang="scss">
  //
.gift-element {
  position: relative;
  height: 120px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background: #FFFFFF;
    border-radius: 50%;
    img{
      max-width: 50px;
      min-width: 30px;
      max-height: 50px;

    }
  }
  .name{
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 7px;
    width: 126px;
  }
  .recommended{
    top: 0;
    right: 0;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FBB03B;
    border: 1px solid #FFFFFF;
  }
  .counter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #8CC63F;
    border: 1px solid #FFFFFF;
    bottom: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>