<template>
  <div class="modal-success" v-if="id_category==null">
    <i class="main-icon icon ion-md-sad"></i>

    <p class="main-text">{{$ml.get('לא נמצאו משחקים')}},{{$ml.get(' תקבלו משחקים אקראיים')}}</p>

    <button @click="$emit('close')" class="yes theme-button-outline">
      {{$ml.get('להמשיך')}}
    </button>
    <button @click="goBack" class="no theme-button-outline">
      {{$ml.get('שינוי הגדרות')}}
    </button>
  </div>
  <div class="modal-success" v-else>
    <i class="main-icon icon ion-md-sad"></i>

    <p class="main-text">{{$ml.get('צפית בכל המשחקים בקטגוריה זו')}},{{$ml.get('לאחר מכן, תקבלו את המשחקים שצפיתם בסדר אקראי')}}</p>

    <button @click="$emit('close')" class="yes theme-button-outline">
      {{$ml.get('להמשיך')}}
    </button>
    <button @click="goBack" class="no theme-button-outline">
      {{$ml.get('שינוי הגדרות')}}
    </button>
  </div>
</template>

<script>
export default {
  props:{
    id_category:null,
  },
  methods: {
    goBack() {
      this.$router.push({name:'playlist-options'});
      this.$emit('close');
    }
  },
  created(){
  }
};
</script>

<style lang="scss" scoped>
.no {
  color: #000;
  font-size: 15px;
  i::before {
    vertical-align: middle;
  }
}
.main-icon {
  font-size: 70px;
  line-height: 100%;
}
</style>
