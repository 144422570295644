<template>
  <div class="gift">
    <SimpleHeader style="position: fixed;width: 100%;z-index: 101;"   route="/main">
      <template v-slot:title>
        <span>
          {{$ml.get('מתנות מהמשחקים')}}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <div class="container sm-cont">
      <div class="head">
        <div class="name-t">{{user.name}}</div>

        <div class="title">
          {{$ml.get('מתנות מהמשחקים')}}
        </div>
        <div class="top-text">

          <span>{{$ml.get('בחרו את כישורי החיים שאתם רוצים לחזק')}}</span> <br>  <span>{{$ml.get('ויוצגו רק משחקים שמפתחים את המיומנויות שסימנתם')}}</span>
        </div>
        <div id="myHeader" class="head-wrapper header" >
          <div  class="sections ">
            <div class="item" v-for="(item, i) in sections">
              <button @click="check('head'+(i+1))"  >
                <div class="color" :style="{ backgroundColor: item.color }"></div>
                <div class="name">{{ item.name }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <app-loading :loading="loading.sync" class="list">
        <div class="items">
          <GiftsWrapper

            :section="section"
            :key="i*12"
            :allSection="selectedObj.filter((gift) => gift.id_section === section.id_section).length"
            v-for="(section, i) in sections"
            :id="'head'+(i+1)"
            @clear="clearBySection(section.id_section)"
            @select="allBySection(section.id_section)"
          >
            <div class="item"
                 :key="index*33"
                 v-for="(item, index) in gifts.filter((gift) => gift.id_section === section.id_section )"
            >
              <div
                class="dot"
                :style="{
                backgroundColor: `${$store.getters['playlist-sections/getColorById'](item.id_section)}`
              }"
              ></div>
              <CheckboxWrapper
                :style="selected.find( (el) => el == item.id_category)  ?
               'background: white' : 'background: #ffffff63' "
                v-model="selected"
                :class="[ selected.find( (el) => el == item.id_category)  ?
               'active' : 'unactive' ]"
              >
                <template v-slot="{setValue}">
                  <div
                    @click="setValue(+item.id_category)"
                    class="checkbox-slot">
                    <img :src="item.img_url" alt="">
                    <div class="title">{{item.name}}</div>
                    <div class="played">
                      {{ item.stats.played }}
                    </div>
                  </div>
                </template>
              </CheckboxWrapper>
            </div>
          </GiftsWrapper>
        </div>
      </app-loading>
    </div>
    <div class="bottom-fixed">
      <app-btn :loading="loading.save" @click="update" class="save-b">{{$ml.get('שמור')}}</app-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SimpleHeader from "@/components/SimpleHeader";
import SidebarToggler from "@/components/SidebarToggler";
import GiftsWrapper from '../playlist/GiftsWrapper'
import CheckboxWrapper from "../../components/CheckboxWrapper";
import { TeacherAPI, GiftsAPI } from '@/api';


export default {
  props: {

  },
  data(){
    return{
      selected: [],
      loading: {
        save: false,
        sync: false,
      },
      user: '',
      gifts: [],
    }
  },
  methods: {
    check(id){
      var d = document.getElementById(id);
      var topPos = d.offsetTop-120;
      window.scrollSmoothTo(topPos, 1000)
    },
    clearBySection(section){
      let chosen = this.gifts
          .filter(n => this.selected
          .find(z => z == n.id_category));

      this.selected =  chosen
          .filter((gift) => gift.id_section !== section )
          .map(gift=> gift.id_category);
    },
    allBySection(section){
      let chosen = this.gifts
          .filter((z)  =>
          z.id_section === section)
          .map(z=> z.id_category);

      this.clearBySection(section);
      this.selected = this.selected.concat(chosen) ;
    },
    update(){
      this.loading.save = true;
      TeacherAPI.saveCategories({
        categories: this.selected,
        id_user: this.$route.params.id,
      });
      // PlaylistAPI.updateGifts({ categories: this.selected }).then(() => {
      //   this.$store.dispatch('user/updateData');
      //   this.$router.push('/playlist/options')
      //
      // });
      this.$store.commit('modals/alert/open', {title: 'בחירתך נשמרה בהצלחה! ', text: ''});
      this.$router.back(-1);
      this.loading.save = false;
    }

  },
  computed: {
    ...mapGetters({
      sections: 'playlist-sections/data',
      user: 'user/data',
    }),
    selectedObj(){
      return  this.gifts
          .filter(n => this.selected
              .find(z => z == n.id_category));
    }
  },
  created() {
    this.loading.sync = true;
    GiftsAPI.selectAllWithStats().then(res => {
      this.gifts = res.data;
      TeacherAPI.getGiftsStudentId(this.$route.params.id).then(res=>{
        this.selected = _.cloneDeep(res.data.gifts) || [];
        this.user = res.data.user;
        this.loading.sync = false;
      }).catch();

    });
  },

  mounted() {

    window.onscroll = function() {myFunction()};
    let header = document.getElementById("myHeader");
    let sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky-50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

  },
  components: {
    CheckboxWrapper,
    SimpleHeader,
    SidebarToggler,
    GiftsWrapper
  },
}
</script>

<style lang="scss" scoped>

.gift {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .container {
    overflow-y: auto;
    height: calc(100% - 260px);
  }

  .list {
    flex-grow: 1;
    background: #AAD9E7;
    min-height: 200px;
  }

  .head {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;


    background: #fff;
    border-bottom: 1px solid #CCDFE4;
    height: 200px;
    .name-t{
      padding: 5px 10px 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #00465B;
    }
    .title{
      font-family: Heebo;
      font-style: normal;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #00465B;
    }
    .top-text{
      margin: 0 auto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
      color: #000000;
    }
    .head-wrapper{
      padding: 5px 10px;
      background: white;
    }
    .sections {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 5px;
        width: 125px;
        button{
          display: flex;
          align-items: center;
          .name {
            font-size: 12px;
            color: #149CC6;
          }
          .color {
            margin-left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }
        }
      }
    }

    .top-text {
      padding: 5px 10px;
      font-size: 14px;
      text-align: center;
      color: #000;
      padding-bottom: 10px;
      border-bottom: 1px solid #CCDFE4;

    }
    .bot-text {
      margin-top: 10px;
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
      text-align: left;
      color: #000000;
    }
  }

  .items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;
    margin-bottom: 53px;

    .item {

      border: 1px solid #005d7a;
      border-radius: 4px;
      margin: 5px;
      width: 78px;
      height: 100px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .checkbox-wrapper{

        position: relative;
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        color: #06364B;
        border-radius: 4px;
        .title{
          font-size: 12px;
          line-height: 1;
          margin-bottom: 5px;
        }
        .played {
          line-height: 1;
          font-size: 10px;
        }
        .checkbox-slot {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 4;
          width: 100%;
          height: 100%;
        }
        &.active{
          &:after{
            position: absolute;
            content: '';

            background: white;
            border: 1px solid #00465B;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            top: 3px;
            right: 5px;
            z-index: 1;
          }
          &:before{
            position: absolute;
            content: '';
            height: 3px;
            z-index: 2;
            width: 6px;
            border-left: 1px solid #00465B;
            border-bottom: 1px solid #00465B;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 7px;
            right: 8px;
          }
        }
        &.unactive{
          &:after{
            top: 3px;
            right: 5px;
            position: absolute;
            content: '';
            background: #FFFFFF;
            border: 1px solid #00465B;
            width: 12px;
            height: 12px;
            border-radius: 50%;

          }
        }
      }
      img {
        min-height: 30px;
        height: 30px;
        align-self: center;
        margin-bottom: 10px;
      }

      span {
        font-size: small;
        text-align: center;
        line-height: 1.2;
        padding: 0 10px;
      }

      .dot {
        top: 4px;
        left: 4px;
        z-index: 1;
        position: absolute;
        display: flex;
        align-self: flex-end;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        min-height: 10px;

      }
    }
  }

  .bottom-fixed {
    z-index: 99;
    position: fixed;
    height: 60px;
    bottom: 0;
    background: white;
    width: 100%;
    border-top: 1px solid #C4C4C4;
    button{
      width: 180px;
      height: 36px;
      border-radius: 18px;
      margin: 12px auto 12px auto;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

  .header{
    transition: all 0.3s ease-in-out;
    &.sticky {
      border-bottom: 1px solid #C4C4C4;
      position: fixed;
      top: 50px;
      z-index: 20;
      width: 460px;
      padding: 0!important;
      @media screen and (max-width: 430px){
        width: 100%;
      }
      .bot-text {
        padding: 5px 17px;
      }
    }
  }
</style>