<template>
  <div
    class="input-label h-no-sel"
    :class="{
      active: active,
      default: styling === 'default',
      'on-board': styling === 'on-board'
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    styling: {
      default: "default"
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.input-label {
  font-size: 16px;
  opacity: 0.65;
  color: #4c5056;
  position: absolute;
  bottom: 50%;
  right: 12px;
  padding-left: 1px;
  transform: translateY(50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  transition: font-size 0.3s ease, transform 0.3s ease, bottom 0.3s ease,
    left 0.3s ease;
  &.active {
    bottom: 100%;
    font-size: 14px;
    opacity: 1;
    left: 0;
    transform: translateY(-5px);
    pointer-events: all;
  }

  &.on-board {
    &.active {
      background: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      padding: 2px 4px;
      border-radius: 3px;
    }
  }
  &:hover {
    // pointer-events: none;
  }
}
</style>
