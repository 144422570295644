import {TeacherAPI} from "@/api";

const TeacherTargetsStore = {
  namespaced: true,
  state: {
    targets: {},
    details: {},
    loadedTargets: false,
    loadedDetails: false,
  },
  mutations: {
    saveTargets(state, data) {
      state.targets = data
    },
    saveDetails(state, data) {
      state.details = data
    },
    loadTargets(state) {
      state.loadedTargets = true;
    },
    loadDetails(state) {
      state.loadedDetails = true;
    },
  },
  actions: {
      updateTargets({ commit  }) {
        TeacherAPI.getTargets().then(res => {
            commit('saveTargets', res.data.data);
            commit('loadTargets')
          });
    },
    updateDetails({ commit  }) {
      TeacherAPI.getDetails().then(res => {
        commit('saveDetails', res.data);
        commit('loadDetails')
      });
    },
    updateInvention({commit}, payload){
      TeacherAPI.setInvention(payload).then((res)=>{
        commit('saveDetails', res.data);
        commit('loadDetails')
      }).catch()
    },
    updateGamesShifts({commit}, payload){
      TeacherAPI.setGamesShifts(payload).then((res)=>{
        commit('saveDetails', res.data);
        commit('loadDetails')
      }).catch()
    },
  },
  getters: {
    targets(state) {
      return state.targets;
    },
    showInventionCourse(state) {
      return state.targets.inventionCourse
    },
    showGamesShifts(state) {
      return state.targets.gamesShifts
    },
    inventionCourse(state) {
      return !!state.details.inventionCourse
    },
    з(state) {
      return !!state.details.gamesShifts
    },
    details(state) {
      return state.details
    },
    isLoadedDetails(state) {
      return state.loadedDetails;
    },
    isLoadedTargets(state) {
      return state.loadedTargets;
    },

  }
};

export { TeacherTargetsStore };
