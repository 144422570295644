<template>
  <div class="wrapper">
  <transition name="fade-scale">
    <div v-if="loaded" class="help-popup fade-scale">
      <div class="info-wrapper ">
        <div class="title">


          <span>{{$ml.get('המשחקים המועדפים יוצגו לתלמידים')}}</span> <br>
          <span>{{$ml.get('בראש רשימת השאלות  שלהם')}}</span>
        </div>
        <div class="fav-w">
          <span>{{$ml.get('ניתן לשנות את סדר השאלות ')}}</span>
          <router-link   :to="{name: 'liked', params: {type: 'shootlist'}}">
            {{$ml.get('בעמוד המועדפים')}}
          </router-link>
        </div>

        <div class="updesc">
          {{$ml.get('למי המשחק מיועד')}}:
        </div>
        <div class="options">
          <div class="level-wrapper ">

            <div class="levels-wrapper">
              <RadioWrapper
                :style="selected.levels.includes(levels.numbers[2]) ? 'background: #A9DDF3;border: 1px solid #A9DDF3' : 'border: 1px solid #A9DDF3' "
                v-model="selected.levels"
                :class="[selected.levels.includes(levels.numbers[2]) ? 'active' : 'unactive' ]"
              >
                <template v-slot="{setValue}">
                  <div
                    @click="setValue(levels.numbers[2])"
                    class="checkbox-slot">
                    <div class="title">{{levels.arr[2]}}</div>
                  </div>
                </template>
              </RadioWrapper>
              <RadioWrapper
                :style="selected.levels.includes(levels.numbers[1]) ? 'background: #A9DDF3;border: 1px solid #A9DDF3' : 'border: 1px solid #A9DDF3' "
                v-model="selected.levels"
                :class="[selected.levels.includes(levels.numbers[1]) ? 'active' : 'unactive' ]"
              >
                <template v-slot="{setValue}">
                  <div
                    @click="setValue(levels.numbers[1])"
                    class="checkbox-slot">
                    <div class="title">{{levels.arr[1]}}</div>
                  </div>
                </template>
              </RadioWrapper>
              <RadioWrapper

                :style="selected.levels.includes(levels.numbers[0]) ? 'background: #A9DDF3;border: 1px solid #A9DDF3' : 'border: 1px solid #A9DDF3' "
                v-model="selected.levels"
                :class="[selected.levels.includes(levels.numbers[0]) ? 'active' : 'unactive' ]"
              >
                <template v-slot="{setValue}">
                  <div
                    @click="setValue(levels.numbers[0])"
                    class="checkbox-slot">
                    <div class="title">{{$ml.get('כולם')}}</div>
                  </div>
                </template>
              </RadioWrapper>
            </div>
          </div>
        </div>
      </div>
      <app-btn class="close-b" styling="inline"   @click="setFavorite">
        {{$ml.get('שמור')}}
      </app-btn>
    </div>
  </transition>
    <div  class="wrapper-popup">
    </div>
  </div>
</template>

<script>
  import {DirectorAPI} from "@/api";
  import RadioWrapper from "../playlist/playlist-options/RadioWrapper";
  //todo animetion fade-scale
export default {
  data() {
    return {
      loaded: false,
      selected:{
        levels: 'all',
      },
      levels : {
        arr: [ 'כולם', 'א\'-ג\'', 'ד\'-ו\'' ],
        numbers: ['all','child','adult'],
      },
    }
  },
  components:{
    RadioWrapper,
  },
  props:{
    item:{

    }
  },
  methods: {
    setFavorite(data) {
      DirectorAPI.setShootlistFavorive({class_type: this.selected.levels, shootlist_id: this.item.id_shootlist}).then();
      this.$emit('liked');
    },
  },
  computed: {

  },
  mounted() {
    setTimeout(() => this.loaded = true, 200);
  }
}
</script>

<style lang="scss" scoped>
.wrapper-popup{
  position: fixed;
  z-index: 9;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  right: 0;
  top: 0;
}

.help-popup {
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 214px; left: 0;  right: 0;
  width: 260px;
  height: 247px;
  border-radius: 10px;
  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;

    /*right: 46%;*/
    right: 119px;
    top: 235px;

    border-left: 2px solid #3FBCD4;
    border-bottom: 2px solid #3FBCD4;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .info-wrapper{
    .title{
      margin-top: 33px;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #00465B;
    }
    .updesc{
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;

      color: #00465B;
    }
    .fav-w{
      margin-top: 6px;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      a{
        color: #149CC6;
      }
      span{
        color: #00465B;
      }
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{
      direction: rtl;
    }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #005D79;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}
.level-wrapper {
  padding: 10px;

  .level-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #4D4D4D;
  }

  .levels-wrapper {

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 5px;

    .radio-wrapper {
      min-width: 72px;
      height: 28px;
      background: #A9DDF3;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      margin-left: 6px;

      font-size: 12px;
      text-align: center;
      color: #06364B;
      position: relative;
      .checkbox-slot{
        z-index: 4;
        position: relative;
        width: 100%;
        height: 100%;
        /* top: -3px; */
        /* right: 6px; */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .title{
          margin:0;
          margin-right: 24px;
          color: #06364B;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: right;

        }
      }
      &.active{
        border: 1px solid #A9DDF3;
        background: #A9DDF3;
        &:after{
          position: absolute;
          content: '';
          z-index: 1;
          border: 1px solid white;
          background: #FFFFFF;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 7px;
          right: 5px;

        }
        &:before{
          position: absolute;
          content: '';
          background: #00465B;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 9px;
          right: 7px;
          z-index: 2;
        }
      }
      &.unactive{
        border: 1px solid #A9DDF3;;
        background: white;
        &:after{
          top: 5px;
          right: 5px;
          position: absolute;
          content: '';
          background: #FFFFFF;
          border: 1px solid #A9DDF3;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          z-index: -2;
        }
      }
    }
  }
}
</style>