import { UserAPI } from '@/api';
const ContentCounter = {
  namespaced: true,
  state: {
   data:null,
   isViewed:false,
   loaded:false,
  },
  mutations: {
    setData(state,data)
    {
      state.data = data;
    },
    setViewed(state)
    {
      state.isViewed = true;
    },
    load(state)
    {
      state.loaded = true;
    }
  },
  actions: {
    async updateData({ commit })
    {
      const counters = await UserAPI.countersContent().then(res => res.data);
      commit('setData', counters);
      commit('load');
      return counters;
    }
  },
  getters: {
    data(state)
    {
      return state.data;
    },
    isViewed(state)
    {
      return state.isViewed;
    },
    loaded(state)
    {
      return state.loaded;
    }
  }
};

export { ContentCounter };
