<template>
  <div class="modal">
    <button class="modal-close" @click="closeModal">
      <svg
        @click="closeModal"
        width="329pt"
        height="329pt"
        viewBox="0 0 329.27 329"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m194.8 164.77 128.21-128.21c8.3438-8.3398 8.3438-21.824 0-30.164-8.3398-8.3398-21.824-8.3398-30.164 0l-128.21 128.21-128.21-128.21c-8.3438-8.3398-21.824-8.3398-30.164 0-8.3438 8.3398-8.3438 21.824 0 30.164l128.21 128.21-128.21 128.21c-8.3438 8.3398-8.3438 21.824 0 30.164 4.1562 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25l128.21-128.21 128.21 128.21c4.1602 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25 8.3438-8.3398 8.3438-21.824 0-30.164z"
        />
      </svg>
    </button>
    <iframe v-if="url" :src="url" ref="frame"></iframe>
  </div>
</template>

<script>
import { SubscribeAPI } from "@/api";
export default {
  props: {
    present: null,
    url: {
      required: true,
      type: String,
    },
    id_transaction: {
      required: true,
    },
    type: {
      default: "payment",
      type: String,
    },
    reqType: null,
    ended: {
      type: Function,
    },
  },
  data() {
    return {
      clicked:false,
      interval: null,
    };
  },
  methods: {
    closeModal() {
      if(this.clicked==false)
      {
      let resultSuccess = null;
      // if(this.present==true)
      // {
      // const data = SubscribeAPI.getTokenPresent(this.id_transaction, this.reqType)
      //   .then((res) => {
      //     resultSuccess = res.data.successOfPayment;
      //     if (resultSuccess == true) {
      //       this.$notify({
      //         title: "אִשׁוּר",
      //         text: "תשלום מוצלח",
      //         type: "success",
      //         duration: 5000,
      //       });
      //         // this.$store.commit('promo-store/saveData',res.data.result.promo);
      //         this.$router.push({name: 'subsPlans-present', params: {type: this.reqType}})
      //     }
      //     this.$emit("close");
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       title: "שְׁגִיאָה",
      //       text: "נסה מאוחר יותר",
      //       type: "error",
      //       duration: 5000,
      //     });
      //     this.$emit("close");
      //   })
      //   .finally(() => {
      //     if (resultSuccess == true) {
      //       this.$store.dispatch("user/updateData").then(() => {
              
      //       });
      //     }
      //   });
      //   this.clicked = true;
      // }
      // else
      // {
        const data = SubscribeAPI.getToken(this.id_transaction, this.reqType)
        .then((res) => {
          resultSuccess = res.data.successOfPayment;
          if (resultSuccess == true) {
            this.$notify({
              title: "אִשׁוּר",
              text: "תשלום מוצלח",
              type: "success",
              duration: 5000,
            });
          }
          this.$emit("close");
        })
        .catch((err) => {
          this.$notify({
            title: "שְׁגִיאָה",
            text: "נסה מאוחר יותר",
            type: "error",
            duration: 5000,
          });
          this.$emit("close");
        })
        .finally(() => {
          if (resultSuccess == true) {
            this.$store.dispatch("user/updateData").then(() => {
            });
          }
        });
        this.clicked = true;
      }
    }
    // },
  },
  created() {
      let resultSuccess = null;
      // const data = SubscribeAPI.getToken(this.id_transaction, this.reqType)
      //   .then((res) => {
      //     resultSuccess = res.data.successOfPayment;
      //     if (resultSuccess == true) {
      //       this.$notify({
      //         title: "אִשׁוּר",
      //         text: "תשלום מוצלח",
      //         type: "success",
      //         duration: 5000,
      //       });
      //       this.$emit("close");
      //       // clearInterval(this.interval);
      //     }
      //   })
      //   .catch((err) => {
      //     this.$notify({
      //       title: "שְׁגִיאָה",
      //       text: "נסה מאוחר יותר",
      //       type: "error",
      //       duration: 5000,
      //     });
      //     // clearInterval(this.interval);
      //     this.$emit("close");
      //   })
      //   .finally(() => {
      //     if (resultSuccess == true) {
      //       // clearInterval(this.interval);
      //       this.$emit("close");
      //       this.$store.dispatch("user/updateData").then(() => {
      //         // this.$router.push({ path: "/main" });
      //       });
      //     }
      //   });
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.modal-close {
  z-index: 99999;
}
.modal {
  width: 100%;
  height: 100%;
  // padding: 10px;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
    box-shadow: rgba($color: #000000, $alpha: 0.2);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
    }
  }
}
</style>
