<template>
  <main>
    <simple-header inverse route="/welcome">
      <span slot="title">התחברות</span>
    </simple-header>
    <div class="sm-cont">
      <div class="additional" dir="rtl">
        <span>
          התחבר דרך
        </span>
        <div class="socials">
          <button class="facebook" @click="loginFB"></button>
        </div>
      </div>
      <!-- login by phone -->
      <!--form v-if="codeSent == false" @submit="loginByPhone">
        <div class="app-inputs" dir="rtl">
          <div class="app-section">
            <p>אנא רשום את מספר הטלפון שלך</p>
            <input required v-model="phone" type="tel" class="theme-input">
          </div>
        </div>
        <div class="end" dir="rtl">
          <button type="submit" class="theme-button">
            התחבר
          </button>
        </div>
      </form>
      <form v-if="codeSent == true" @submit="phoneCheck">
        <div class="app-inputs" dir="rtl">
          <div class="app-section">
              <p>Code from SMS</p>
              <input type="text" v-model="code" class="theme-input">
          </div>
        </div>
        <div class="end" dir="rtl">
          <button type="submit" class="theme-button">
            התחבר
          </button>
        </div>
      </form-->

      <!-- keep alive -->
      <form @submit="login">
        <div class="app-inputs" dir="rtl">
          <div class="app-section">
            <p>
              מייל
            </p>
            <input required v-model="email" type="email" class="theme-input" />
          </div>
          <div class="app-section">
            <p>
              סיסמה
            </p>
            <input
              required
              v-model="pass"
              type="password"
              class="theme-input"
            />
            <p class="recover">
              <router-link to="/recover">שכחתי ססמה</router-link>
            </p>
          </div>
        </div>
        <div class="end" dir="rtl">
          <button type="submit" class="theme-button">
            התחבר
          </button>
          <p>
            עדין לא נרשמת
            <router-link to="/welcome">הרשמה</router-link>
          </p>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import facebook from "@/config/facebook";

export default {
  name: "login",
  data() {
    return {
      email: "",
      pass: "",
      gender: "",
      name: "",
      surname: "",
      age: 0,
      codeSent: false,
      phone: "",
      code: "",
      hash: ""
    };
  },
  methods: {
    onAuthEnd() {
      var shared = this.$store.getters.shared;

      if (!shared) {
        this.$router.replace({ name: "main" });
      } else {
        var query = { name: shared.type, params: {} };
        if (shared.type == "playlist") {
          query.params.id_playlist = shared.id;
        } else {
          query.params.id_shootlist = shared.id;
        }
        this.$router.replace(query);
        this.$store.commit("removeShared");
      }
    },
    login(e) {
      e.preventDefault();
      axios
        .post("/user/login", {
          email: this.email,
          pass: this.pass
        })
        .then(res => {
          this.$store.commit("user/auth", res.data.auth);
          this.$store.commit("user/expires_at", res.data.expires_at);
          this.$store.dispatch("user/updateData").then(res => {
            this.onAuthEnd();
          });
        })
        .catch(res => {
          this.$store.commit("alert", res.data.error);
        });
    },
    loginByPhone(e) {
      e.preventDefault();
      axios
        .post("/user/login-by-phone", {
          phone: this.phone
        })
        .then(res => {
          this.hash = res.data.hash;
          this.codeSent = true;
        })
        .catch(err => {
          this.$store.commit("alert", res.data.error);
        });
    },
    phoneCheck(e) {
      e.preventDefault();
      axios
        .post("/user/check-login-by-phone", {
          phone: this.phone,
          hash: this.hash,
          code: this.code
        })
        .then(res => {
          this.$store.commit("user/auth", res.data.auth);
          this.$store.commit("user/expires_at", res.data.expires_at);
          this.$store.dispatch("user/updateData").then(res => {
            this.onAuthEnd();
          });
        })
        .catch(err => {
          this.$store.commit("alert", err);
        });
    },
    // loginFB() {
    //   facebook.enter().then(res => {
    //     axios
    //       .post("/user/facebook/enter", {
    //         email: res.data.email,
    //         name: res.data.first_name,
    //         surname: res.data.last_name,
    //         id_facebook: res.auth.authResponse.userID,
    //         access_token: res.auth.authResponse.accessToken,
    //         img: res.data.picture.data.url
    //       })
    //       .then(res => {
    //         this.$store.commit("user/auth", res.data.auth);
    //         this.$store.dispatch("user/updateData").then(res => {
    //           this.onAuthEnd();
    //         });
    //       })
    //       .catch(res => {
    //         this.$store.commit("alert", res.data.error);
    //       });
    //   });
    // }
  },
  components: {
    SimpleHeader
  }
};
</script>

<style lang="scss" scoped>
main {
  background: #eb5757;
  .additional {
    padding: 20px;
    padding-bottom: 0;
    line-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .socials {
      button {
        width: 40px;
        display: inline-block;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #fff;
        &.facebook {
          background: #005d7a;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMiAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEuMzY3MiAxMkg4VjI1SDNWMTJIMFY4SDNWNS41ODk4NEMzLjAwMzkxIDIuMDgyMDMgNC40NjA5NCAwIDguNTkzNzUgMEgxMlY0SDkuNzE0ODRDOC4xMDU0NyA0IDggNC42MDE1NiA4IDUuNzIyNjZWOEgxMkwxMS4zNjcyIDEyWiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=");
          background-position: 20px 10px;
        }
      }
    }
  }
  .app-inputs {
    .recover {
      text-align: left;
      text-decoration: underline;
      a {
        color: #005d7a;
      }
    }
  }
  .end {
    flex-grow: 1;
    padding: 20px;
    button {
      width: 100%;
    }
    p {
      margin: 0;
      font-size: 18px;
      text-align: center;
      a {
        color: #1a92b8;
        text-decoration: underline;
      }
    }
  }
}
</style>
