export default {
  namespaced: true,
  state: {
   loading:true
  },
  mutations: {
    changeLoadTrue(state) {
      state.loading = true;
    },
    changeLoadFalse(state) {
        state.loading = false;
      },
    
  },
  actions: {
   
  },
  getters: {
    data(state) {
      return state.loading;
    }
  }
};
