<template>
  <button @click="$emit('click')" type="button" class="share-button th-rc">
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 2.5V5.5V8.5L21 5.5L15 2.5Z" fill="#8AB4C2" />
      <path
        d="M15 5.5H5.5C3.66667 5.5 1 6.3 1 9.5C1 11.7 2.66667 13.1667 5 13H24M15 5.5V2.5L21 5.5L15 8.5V5.5Z"
        stroke="#8AB4C2"
        stroke-width="2"
      />
    </svg>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  //
.share-button {
  border: 2px solid #ffffff;
  border-radius: 15px;
  height: 30px;
  width: 60px;
  min-width: 60px;
  margin-left: 10px;
  margin-right: 20px;

  @media (max-width: 430px) {
    margin-left: 5px;
    margin-right: 10px;
  }
}
</style>
