<template>
  <div class="reports-status-element">
    <div class="header">
      <button class="show-b" @click="flag=!flag">
      <span  :style="{color: titleColor}" class="name"> {{title}}  </span> <span class="title">{{$ml.get('בחירת ערכים')}}</span>
        <svg :style="!flag? '    transform: rotate(0);margin-bottom:2px' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
      </button>
      <div class="term">
          <button @click="goal.enabled=!goal.enabled">{{!goal.enabled?     $ml.get('הפעלה') : $ml.get('ביטול')}}</button>
      </div>
    </div>
    <div class="element-wrapper" :class="!goal.enabled? 'unactive' : ''">
      <div class="row">

        <div class="date-value">{{$ml.get('יעד חודשי')}}</div>

        <div class="status">
           <div class="status-bar">
            <RangeSlider
              :uncative="!goal.enabled"
              class="r-slider"
              :max="goal.month.range"
              v-model="goal.month.target" />
          </div>
        </div>
      </div>
      <div class="row">

        <div class="date-value">{{$ml.get('יעד שנתי')}}</div>

        <div class="status">
           <div class="status-bar">
            <RangeSlider
              :uncative="!goal.enabled"
              class="r-slider"
              :max="goal.year.range"
              v-model="goal.year.target" />

          </div>
      </div>
    </div>

  </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >
        <div v-for="go in goal.gifts" class="report-w">
          <ReportStatusElement
            :arrayed="true"
            :title="(''+go.id_gift)"
            :data="go"
              />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ReportStatusElement from "./ReportStatusElement";
import RangeSlider from '@/components/ui/RangeSlider'

  export default {
    props:{
      title: {},
      goal: {},
      titleColor:{
        default: '#00465B'
      }
    },
    components:{
      ReportStatusElement,
      RangeSlider
    },
    computed:{
      targetData(){
        return this.$store.getters['director-targets/data'];
      },
    },
    data() {
      return {
        flag: false,
        value: 0,
        blockFlag: false,
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss" scoped>
  .show-b{
    .title{
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #00465B;
      margin-left: 5px;
    }
  }
  .reports-status-element{
    width: 100%;
    padding: 0 20px;
    .report-status-element{
      padding: 0 20px 0 0;
    }
    .header{
      margin:15px 0 10px 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .name{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #00465B;
      }
      .term{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 12px;
        text-align: center;
        color: #00465B;
        button{
          color: #149CC6;
        }
      }
    }
    .element-wrapper{
      background: white;
      padding: 5px 20px;

      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 15px;
      &.unactive{
        pointer-events: none;
        opacity: 0.95;
        .row{
          .date-value{
            color: #C4C4C4;
          }
        }
      }
      .row{
        margin: 15px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .date-value{
          font-size: 14px;
          color: #000000;
        }
        .status{
          display: flex;
          align-items: center;
          flex-direction: column;
          .value{
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }
          .status-bar{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 160px;
            height: 8px;
            background: #C4C4C4;
            border-radius: 4px;
            .bg{
              background: rgb(140, 198, 63);

              height: 100%;
              border-radius: 4px 4px  4px  5px;
            }
          }
        }
      }
    }

  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

