import {TranslatedDates} from "./translated-dates.dto";

const LangStore = {
  namespaced: true,
  state: {
    lang: 'heb',
    data: new TranslatedDates(),

  },
  mutations: {
    changeLang(state, data) {
      state.lang = data;
    },
    load(state) {
      state.loaded = true;
    },
  },
  actions: {
  },
  getters: {
    data(store){
      return store.lang
    },
    dates(store){
      return store.data.find(item=>{
        return item.langKey===store.lang
      })
    },
    direction(store){
      return (store.lang==='heb' || store.lang==='ar' )? 'rtl' : 'ltr';
    }
  }
};


export { LangStore };
