import Vue from "vue";
import Vuex from "vuex";
import Alert from "@/components/modals/Alert";
import WebSocials from "@/components/modals/WebSocials";
import dictionary from "@/dictionary";

import Sub from "./sub/sub.store";
import UserStore from "./user/user.store";
import UserDefaultStore from "./user/defaultUser.store";
import { ContentCounter } from "./counters/content-counters.store";
import { PlaylistStore } from "./playlist/playlist.store";
import ShootlistStore from "./shootlist/shootlist.store";
import AnimationStore from "./helpers/animation";
import { TutorialsStore } from './tutorials/tutorials.store';
import { PlaylistSectionsStore } from './playlist/playlist-sections.store';
import { PlaylistGiftsStore } from './playlist/playlist-gifts.store';
import { PlaylistLocationsStore } from './playlist/playlist-locations.store'
import {GamesClassSections} from './pages/games-class-sections'
import {SessionsStore} from "@/store/helpers/sessions.store";
import {DirectorTargetsStore} from '@/store/director/targets.store'
import {TeacherTargetsStore} from '@/store/teacher/targets.store'
import {FunkitStore} from '@/store/funkit/funkit.store'
import {ShootlistCategories} from "@/store/shootlist/shootlist-categories.store";
import {EducationalSections} from "@/store/pages/educational-sections";
import {EducationalCategories} from "@/store/pages/educational-categories";
import {MatchedGames} from "@/store/pages/matched-games";
import {FromTheHastle} from "@/store/pages/from-the-hastle";
import {LearningSkills} from "@/store/pages/learning-skills";
import {PromoStore} from "@/store/promo/promo.store";
import {SevenPage} from "@/store/pages/seven-page";
import {EightPage} from "@/store/pages/eight-page";
import {NinePage} from "@/store/pages/nine-page";
import {TenPage} from "@/store/pages/ten-page";
import {ElevenPage} from "@/store/pages/eleven-page";
import {TwelvePage} from "@/store/pages/twelve-page";


import {LangStore} from "@/store/helpers/lang.store";
import {CommunicationSkills} from "@/store/pages/communication-skills";
import modals from './modals/modals.store';
import {PagesTitle} from './titles/pagestitles.store';
import{CounterStore} from './counters/counter.store';
import{CounterGameStore} from './counters/counter-games.store';
import LoadingWindow from './helpers/loading.store';
import { GamesForClasses } from "./pages/games-for-classes";
import TrackStore from './trackUsers/track-users.store';
import {SearchPlaylistStore} from './playlist/search-playlist.store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    'sub':Sub,
    'track': TrackStore,
    'loadingWindow': LoadingWindow,
    'user': UserStore,
    'userDefault':UserDefaultStore,
    'counter':CounterStore,
    'counter-game':CounterGameStore,
    'animation': AnimationStore,
    'playlist': PlaylistStore,
    'shootlist': ShootlistStore,
    'tutorials': TutorialsStore,
    'playlist-sections': PlaylistSectionsStore,
    'playlist-gifts': PlaylistGiftsStore,
    'playlist-locations' : PlaylistLocationsStore,
    'games-class-sections' : GamesClassSections,
    'sessions': SessionsStore,
    'director-targets' : DirectorTargetsStore,
    'teacher-targets' : TeacherTargetsStore,
    'funkit' : FunkitStore,
    'content-counter' : ContentCounter,
    'pagestitle': PagesTitle,
    'modals' : modals,
    'promo-store':PromoStore,
    'shootlist-categories': ShootlistCategories,
    'educational-categories' : EducationalSections,
    'lang-store' : LangStore,
    'educational-categories-page' : EducationalCategories,
    'matched-games-page':MatchedGames,
    'from-the-hastle-page':FromTheHastle,
    'learning-skills-page':LearningSkills,

    'seven-page':SevenPage,
    'eight-page':EightPage,
    'nine-page':NinePage,
    'ten-page':TenPage,
    'eleven-page':ElevenPage,
    'twelve-page':TwelvePage,

    'communication-skills-page':CommunicationSkills,
    'games-for-classes':GamesForClasses,
    'search-store':SearchPlaylistStore,
  },
  state: {
    sidebar: {
      opened: false
    },
    education: null,
    alert: false,
    reply_counter: null,
    shared: {
      id: Number,
      type: String
    }
  },
  mutations: {
    setShared(state, data) {
      state.shared = data;
      localStorage.setItem("shared", JSON.stringify(data));
    },
    removeShared(state) {
      state.shared = null;
      localStorage.removeItem("shared");
    },
    closeSidebar(state) {
      state.sidebar.opened = false;
    },
    passEducation(state, data) {
      if (state.education.hasOwnProperty(data)) {
        state.education[data] = true;
        localStorage.setItem("education", JSON.stringify(state.education));
      } else {
        throw Error("No education type found");
      }
    },
    alert(state, text) {
      if (state.alert) return;
      state.alert = true;
      app.$modal.show(
        Alert,
        {
          text: text
        },
        {
          adaptive: true,
          height: "auto",
          width: 300
        },
        {
          closed: () => {
            state.alert = false;
          }
        }
      );
    },
    decreaseReplyCounter(state) {
      if (state.reply_counter > 0) {
        state.reply_counter--;
        localStorage.setItem("reply_counter", state.reply_counter);
      }
    },
    setReplyCounter(state, num) {
      state.reply_counter = num;
      localStorage.setItem("reply_counter", state.reply_counter);
    }
  },
  actions: {
    sharePlaylist(context, id) {
      if (window.cordova) {
        return new Promise(function(resolve, reject) {
          navigator.share(
            encodeURIComponent(dictionary.share.playlist(id, "playlist")),
            undefined,
            undefined,
            () => {
              context.commit("setReplyCounter", env.GAMES_FOR_REPLY);
              resolve();
            }
          );
        });
      } else {
        return context
          .dispatch("showWebSocials", {
            text: encodeURIComponent(dictionary.share.playlist(id, "playlist")),
            title: "Playlist"
          })
          .then(() => {
            context.commit("setReplyCounter", env.GAMES_FOR_REPLY);
          });
      }
    },
    shareShootlist(context, id) {
      if (window.cordova) {
        return new Promise(function(resolve, reject) {
          navigator.share(
            encodeURIComponent(dictionary.share.shootlist(id, "shootlist")),
            undefined,
            undefined,
            () => {
              context.commit("setReplyCounter", env.GAMES_FOR_REPLY);
              resolve();
            }
          );
        });
      } else {
        return context
          .dispatch("showWebSocials", {
            text: encodeURIComponent(
              dictionary.share.shootlist(id, "shootlist")
            ),
            title: "Playlist"
          })
          .then(() => {
            context.commit("setReplyCounter", env.GAMES_FOR_REPLY);
          });
      }
    },
    shareFunzone(context) {
      if (window.cordova) {
        return new Promise((resolve, reject) => {
          navigator.share(
            dictionary.share.funzone,
            undefined,
            undefined,
            resolve
          );
        });
      } else {
        return context.dispatch("showWebSocials", {
          text: dictionary.share.funzone,
          title: "Playlist"
        });
      }
    },
    showWebSocials(context, data) {
      return new Promise((resolve, reject) => {
        app.$modal.show(
          WebSocials,
          {
            text: data.text,
            title: data.title,
            onSuccess: resolve
          },
          {
            adaptive: true,
            height: "auto",
            width: 200,
            pivotY: 0.8
          }
        );
      });
    }
  },
  getters: {
    shared(state) {
      var data = localStorage.getItem("shared");

      if (data) {
        state.share = JSON.parse(data);
      } else {
        state.share = null;
      }

      return state.share;
    },
    replyCounter(state) {
      if (state.reply_counter) {
        return state.reply_counter;
      }

      state.reply_counter = parseInt(localStorage.getItem("reply_counter"));

      if (state.reply_counter == null) {
        localStorage.setItem("reply_counter", 0);
        state.reply_counter = 0;
      }

      return state.reply_counter;
    },

    education(state) {
      if (state.education) {
        return state.education;
      }

      state.education = localStorage.getItem("education");

      if (!state.education) {
        const default_state = {
          "playlist-family": false,
          "playlist-adult": false,
          "playlist-children": false,
          shootlist: false,
          parent: false
        };
        localStorage.setItem("education", JSON.stringify(default_state));
        state.education = default_state;
      } else {
        state.education = JSON.parse(state.education);
      }

      return state.education;
    }
  }
});

export default store;
