<template>
  <div
    class="mat-icon"
    :class="{
      'size-normal': size === 'normal',
      'size-big': size === 'big',
      'size-small': size === 'small',
    }"
  >
    <span style="padding-left: 5px;padding-bottom: 1px" v-if="icon==='close'">
      <svg style="transform: scale(1.3)" width="6" height="8" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.58984 1L1.58984 6.99999" stroke="#fff" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M1.58984 1L7.58984 6.99999" stroke="#fff" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </span>
    <span style="padding-left: 5px;padding-bottom: 1px" v-else-if="icon==='done'">
      <svg style="transform: scale(1.2)" width="8" height="8" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 1L4.45847 6.54153L1 3.07305" stroke="#fff" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </span>
    <i v-else class="material-icons">
      {{ icon }}
    </i>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      required: true
    },
    size: {
      default: "normal"
    }
  }
};
</script>

<style lang="scss" scoped>
.mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;


  padding-left: 3px;

  &.size-small {
    .material-icons {
      font-size: 14px;
    }
  }

  &.size-normal {
    .material-icons {
      font-size: 16px;
    }
  }

  &.size-big {
    .material-icons {
      font-size: 20px;
    }
  }
}
</style>
