<template>
  <div class="faq-wrapper">
    <div class="content-info">
<!--      <div class="questions-wrapper" v-bind:key="i*Math.floor(Math.random() * (99999 - 1 + 1)) + 1"  v-for="(report, i) in reports">-->
<!--        <ReportElement  :report="report" />-->
<!--      </div>-->

      <div class="question-wrapper">
        <FreeActivity/>
        <Creativity v-if="teacher" />
        <ClassroomGames  />
        <EducationTime v-if="teacher"  />
        <div class="footer">

          <div class="title">
            {{$ml.get('תמיד ניתן לעדכן את הגדרת היעדים')}}
            {{$ml.get('היעדים ישמרו וישלחו ליעד הנבחר')}}
          </div>
          <app-btn :loading="load" class="save-b" @click="save()">
            {{$ml.get('שליחה')}}
          </app-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FreeActivity from "./class-report/FreeActivity";
  import Creativity from "./class-report/Creativity";
  import ClassroomGames from "./class-report/ClassroomGames";
  import EducationTime from "./class-report/EducationTime";
export default {
 components:{
   EducationTime,
   ClassroomGames,
   Creativity,
   FreeActivity,
 },
  computed:{
   teacher(){
     return this.$store.getters['director-targets/position']!=='prof-teacher' && this.$store.getters['director-targets/position']!=='all_prof'
   }
  },
  props:{
   goals:{

   },
    targets:{

    },
  },
  created() {
  },
  methods:{
   save(){
     this.load= true;

     setTimeout(()=>{
       this.$emit('update', '');
       this.load= false;
     },500)
   }
  },
  data() {
    return {
      load: false,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.faq-wrapper{
  width: 100%;
  .content-info{

  }
  h2{
    font-weight: 600;
    font-size: 24px;
    color: #333333;
  }
}
.footer{
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    padding: 47px 0 29px 0;
    width: 220px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #005D7A;
  }
  .save-b{
    padding: 10px 45px;
    background: #149CC6;
    border-radius: 20px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
  }
}
</style>
