<template>
  <div class="" v-if="main && ($ml.current == 'heb' || $ml.current == 'ar')">
    <div class="main-wrapper sm-cont">
      <MainHeader :sidebar="false" />
      <div
        @click="pushBack"
        style="
          font-size: 16px;
          display: flex;
          justify-content: center;
          cursor: pointer;
        "
      >
        <span style="color: white; position: absolute; right: 47px; top: 107px"
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 1L12 7L6 13" stroke="white" stroke-width="2" />
            <path d="M0 7H12" stroke="white" stroke-width="2" /></svg
        ></span>
      </div>
      <div class="dropdown">
        <button @click="open" class="dropbtn">
          <svg
            height="30"
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            width="30"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              fill="#fff"
              d="M256,48c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0C140.8,48.3,48,141.3,48,256c0,114.7,92.8,207.7,207.5,208  c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c114.9,0,208-93.1,208-208C464,141.1,370.9,48,256,48z M264.3,172.5  c22.1-0.6,43.5-3.5,64.2-8.5c6.2,24.5,10.1,52.8,10.7,83.8h-74.9V172.5z M264.3,155.8V66c22.4,6.2,45.2,36.1,59.6,82  C304.7,152.6,284.8,155.2,264.3,155.8z M247.7,65.8v90.1c-20.7-0.6-40.8-3.3-60.1-8C202.2,101.7,225.1,71.6,247.7,65.8z   M247.7,172.5v75.2h-75.4c0.6-31,4.5-59.3,10.7-83.8C203.8,168.9,225.5,171.9,247.7,172.5z M155.5,247.7H64.9  c1.8-42.8,17.8-82,43.3-113c18.5,10.2,38.2,18.6,58.8,24.8C160.2,186,156.2,215.9,155.5,247.7z M155.5,264.3  c0.6,31.7,4.6,61.7,11.4,88.2c-20.6,6.3-40.2,14.6-58.8,24.8c-25.5-31-41.4-70.2-43.3-113H155.5z M172.3,264.3h75.4v75.1  c-22.2,0.6-43.9,3.6-64.7,8.7C176.8,323.6,172.9,295.3,172.3,264.3z M247.7,356.1v90.2c-22.6-5.9-45.5-35.9-60.1-82.1  C206.9,359.4,227,356.7,247.7,356.1z M264.3,446v-90c20.5,0.6,40.4,3.3,59.7,7.9C309.5,409.9,286.8,439.8,264.3,446z M264.3,339.4  v-75.1h74.9c-0.6,30.9-4.5,59.2-10.7,83.7C307.8,343,286.4,340,264.3,339.4z M355.9,264.3h91.2c-1.8,42.8-17.8,81.9-43.3,113  c-18.7-10.3-38.5-18.7-59.3-25C351.3,325.8,355.3,296,355.9,264.3z M355.9,247.7c-0.6-31.7-4.6-61.6-11.3-88.1  c20.8-6.3,40.6-14.7,59.2-24.9c25.5,31,41.5,70.2,43.3,113.1H355.9z M392.4,121.9c-16.6,8.8-34,16.1-52.3,21.6  c-9.7-31.3-23.4-56.8-39.5-73.6C336,78.4,367.6,96.8,392.4,121.9z M210.8,70.1c-16.1,16.7-29.7,42.2-39.3,73.3  c-18.1-5.5-35.4-12.7-51.8-21.5C144.2,96.9,175.6,78.6,210.8,70.1z M119.6,390c16.4-8.8,33.8-16,51.8-21.5  c9.7,31.2,23.3,56.6,39.4,73.4C175.6,433.4,144.2,415.1,119.6,390z M300.6,442.1c16.2-16.8,29.8-42.3,39.6-73.7  c18.3,5.5,35.7,12.8,52.3,21.6C367.7,415.2,336,433.6,300.6,442.1z"
            />
          </svg>
        </button>
        <div class="dropdown-content" v-if="opened == true">
          <a
            @click="changeLang('heb')"
            :class="$ml.current == 'heb' ? 'disabled-link' : ''"
            value="heb"
            href="#"
            ><img width="32px" src="../../assets/img/langs/heb.png" /> Hebrew</a
          >
          <a
            @click="changeLang('eng')"
            :class="$ml.current == 'eng' ? 'disabled-link' : ''"
            value="eng"
            href="#"
            ><img width="32px" src="../../assets/img/langs/eng.png" />
            English</a
          >
          <a
            @click="changeLang('ar')"
            :class="$ml.current == 'ar' ? 'disabled-link' : ''"
            value="ar"
            href="#"
            ><img
              width="32px"
              style="border-radius: 50%"
              src="../../assets/img/langs/ar.png"
            />
            Arabic</a
          >
          <a
            @click="changeLang('ru')"
            :class="$ml.current == 'ru' ? 'disabled-link' : ''"
            value="ru"
            href="#"
            ><img width="32px" src="../../assets/img/langs/ru.png" /> Russian</a
          >
        </div>
      </div>
      <div style="width: 100%; padding-top: 8px; padding-bottom: 24px">
        <svg
          style="margin: auto; left: 0; right: 0"
          width="101"
          height="49"
          viewBox="0 0 101 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M59.3157 32.2574C58.6429 32.2574 58.1108 32.0607 57.7196 31.6673C57.3284 31.2739 57.1328 30.7388 57.1328 30.0621V17.8582C57.3049 17.8267 57.5788 17.7873 57.9543 17.7401C58.3299 17.6772 58.6898 17.6457 59.0341 17.6457C59.394 17.6457 59.707 17.6772 59.973 17.7401C60.2546 17.7873 60.4894 17.8818 60.6771 18.0234C60.8649 18.165 61.0058 18.3617 61.0997 18.6135C61.1935 18.8653 61.2405 19.1958 61.2405 19.605V28.9291H66.6157C66.7252 29.1022 66.8269 29.3382 66.9208 29.6372C67.0147 29.9205 67.0616 30.2195 67.0616 30.5342C67.0616 31.1637 66.9286 31.6122 66.6626 31.8797C66.3966 32.1315 66.0445 32.2574 65.6064 32.2574H59.3157Z"
            fill="white"
          />
          <path
            d="M73.2747 32.0922C73.1025 32.1394 72.8287 32.1866 72.4531 32.2338C72.0932 32.281 71.7333 32.3046 71.3734 32.3046C71.0135 32.3046 70.6927 32.2732 70.411 32.2102C70.145 32.163 69.9181 32.0686 69.7303 31.927C69.5426 31.7853 69.4017 31.5886 69.3078 31.3368C69.214 31.085 69.167 30.7546 69.167 30.3454V17.8818C69.3391 17.8503 69.613 17.8109 69.9885 17.7637C70.3641 17.7008 70.724 17.6693 71.0683 17.6693C71.4282 17.6693 71.7411 17.7008 72.0072 17.7637C72.2888 17.8109 72.5236 17.9054 72.7113 18.047C72.8991 18.1886 73.04 18.3853 73.1338 18.6371C73.2277 18.8889 73.2747 19.2194 73.2747 19.6286V32.0922Z"
            fill="white"
          />
          <path
            d="M80.356 26.4977C79.6675 26.2617 79.0416 26.0256 78.4782 25.7896C77.9149 25.5378 77.4298 25.2388 77.0229 24.8926C76.6161 24.5463 76.2953 24.1372 76.0606 23.6651C75.8415 23.1772 75.732 22.5871 75.732 21.8947C75.732 20.557 76.2405 19.4869 77.2577 18.6843C78.2904 17.8818 79.7301 17.4805 81.5766 17.4805C82.2495 17.4805 82.8754 17.5277 83.4544 17.6221C84.0334 17.7165 84.5263 17.866 84.9331 18.0706C85.3556 18.2594 85.6843 18.5112 85.919 18.826C86.1537 19.125 86.2711 19.4791 86.2711 19.8882C86.2711 20.2974 86.1772 20.6515 85.9894 20.9505C85.8016 21.2337 85.5747 21.4776 85.3087 21.6822C84.9644 21.4619 84.5028 21.2731 83.9238 21.1157C83.3448 20.9426 82.7111 20.856 82.0226 20.856C81.3184 20.856 80.802 20.9583 80.4734 21.1629C80.1448 21.3517 79.9805 21.5957 79.9805 21.8947C79.9805 22.1307 80.0822 22.3274 80.2856 22.4848C80.489 22.6264 80.7942 22.7602 81.201 22.8861L82.4451 23.2874C83.916 23.7595 85.0427 24.3654 85.8251 25.105C86.6232 25.8289 87.0222 26.8203 87.0222 28.0793C87.0222 29.4169 86.498 30.5028 85.4495 31.3368C84.4011 32.1551 82.8597 32.5643 80.8255 32.5643C80.1056 32.5643 79.4328 32.5013 78.8068 32.3755C78.1966 32.2653 77.6567 32.1001 77.1872 31.8797C76.7334 31.6437 76.3735 31.3604 76.1075 31.0299C75.8571 30.6837 75.732 30.2903 75.732 29.8497C75.732 29.3933 75.865 29.0078 76.131 28.693C76.397 28.3625 76.6865 28.1108 76.9995 27.9376C77.4376 28.2839 77.9697 28.5829 78.5956 28.8347C79.2372 29.0864 79.9335 29.2123 80.6846 29.2123C81.4514 29.2123 81.9913 29.0943 82.3042 28.8583C82.6172 28.6222 82.7737 28.3468 82.7737 28.0321C82.7737 27.7173 82.6485 27.4813 82.3981 27.3239C82.1477 27.1508 81.7957 26.9856 81.3419 26.8282L80.356 26.4977Z"
            fill="white"
          />
          <path
            d="M88.2917 21.1865C88.1822 21.0134 88.0805 20.7774 87.9866 20.4784C87.8927 20.1793 87.8457 19.8646 87.8457 19.5341C87.8457 18.9204 87.9787 18.4798 88.2448 18.2122C88.5264 17.9447 88.8863 17.8109 89.3245 17.8109H99.5585C99.668 17.984 99.7697 18.2201 99.8636 18.5191C99.9575 18.8181 100.004 19.1328 100.004 19.4633C100.004 20.0771 99.8636 20.5177 99.5819 20.7852C99.3159 21.0527 98.9638 21.1865 98.5257 21.1865H95.9202V32.0922C95.7481 32.1394 95.4743 32.1866 95.0987 32.2338C94.7388 32.281 94.3789 32.3046 94.019 32.3046C93.6591 32.3046 93.3383 32.2732 93.0566 32.2102C92.7906 32.163 92.5637 32.0686 92.3759 31.927C92.1881 31.7853 92.0473 31.5886 91.9534 31.3368C91.8595 31.085 91.8126 30.7546 91.8126 30.3454V21.1865H88.2917Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.0415 9.85003C60.5662 7.92813 56.6491 6.67839 54.1084 6.11837L54.2642 5.41309C56.8571 5.9846 60.8427 7.25552 64.3917 9.21814C67.9228 11.1709 71.1147 13.86 71.9625 17.3079L71.2603 17.4801C70.4885 14.3411 67.5346 11.7818 64.0415 9.85003ZM59.155 9.45168C54.4761 7.78368 49.3861 6.95449 46.1328 6.70359L46.1885 5.98348C49.4907 6.23815 54.646 7.07737 59.398 8.77145C64.1268 10.4573 68.564 13.0275 70.2778 16.8118L69.6191 17.1095C68.0358 13.6135 63.8569 11.1279 59.155 9.45168ZM73.4987 13.2098C73.1695 14.3433 72.9044 15.5101 72.7615 16.2359L72.052 16.0965C72.1983 15.3537 72.4682 14.1659 72.8043 13.0086C73.1359 11.8665 73.5462 10.7022 73.9858 10.0434L74.5874 10.444C74.2173 10.9986 73.8322 12.0612 73.4987 13.2098Z"
            fill="white"
          />
          <path
            d="M5.52631 24.404C6.18002 24.404 6.67808 24.2569 7.02051 23.9626C7.37849 23.6528 7.55748 23.1881 7.55748 22.5686C7.55748 21.98 7.37071 21.5308 6.99716 21.221C6.63917 20.8958 6.10998 20.7331 5.40957 20.7331C5.16054 20.7331 4.95042 20.7409 4.7792 20.7564C4.62356 20.7564 4.46013 20.7719 4.28892 20.8028V24.404H5.52631ZM4.31227 31.8619C4.14106 31.9084 3.86868 31.9549 3.49513 32.0013C3.13714 32.0478 2.77916 32.071 2.42117 32.071C2.06318 32.071 1.74411 32.0401 1.46395 31.9781C1.19935 31.9316 0.973663 31.8387 0.786888 31.6993C0.600113 31.5599 0.460031 31.3663 0.366644 31.1185C0.273256 30.8706 0.226562 30.5454 0.226562 30.1427V19.1997C0.226562 18.8745 0.312168 18.6266 0.483378 18.4563C0.670153 18.2704 0.919187 18.1233 1.23048 18.0148C1.75967 17.829 2.35891 17.6973 3.02819 17.6199C3.71303 17.5269 4.39787 17.4805 5.08271 17.4805C7.23063 17.4805 8.87269 17.9374 10.0089 18.8512C11.1451 19.7651 11.7132 21.0042 11.7132 22.5686C11.7132 23.3275 11.5887 24.0168 11.3397 24.6363C11.1062 25.2404 10.7482 25.767 10.2657 26.2162C9.79878 26.6499 9.19955 26.9907 8.46801 27.2385C7.75204 27.4708 6.91934 27.587 5.9699 27.587H4.31227V31.8619Z"
            fill="white"
          />
          <path
            d="M15.9636 32.0246C15.2944 32.0246 14.7652 31.831 14.376 31.4437C13.9869 31.0565 13.7924 30.5299 13.7924 29.8639V17.8522C13.9636 17.8212 14.236 17.7825 14.6095 17.736C14.9831 17.6741 15.3411 17.6431 15.6835 17.6431C16.0415 17.6431 16.3528 17.6741 16.6174 17.736C16.8975 17.7825 17.131 17.8754 17.3178 18.0148C17.5045 18.1542 17.6446 18.3478 17.738 18.5957C17.8314 18.8435 17.8781 19.1688 17.8781 19.5715V28.7487H23.2245C23.3335 28.919 23.4346 29.1514 23.528 29.4457C23.6214 29.7245 23.6681 30.0187 23.6681 30.3285C23.6681 30.9481 23.5358 31.3895 23.2712 31.6528C23.0066 31.9007 22.6564 32.0246 22.2206 32.0246H15.9636Z"
            fill="white"
          />
          <path
            d="M29.002 18.2936C29.2666 18.1387 29.6635 17.9916 30.1926 17.8522C30.7374 17.7128 31.2822 17.6431 31.8269 17.6431C32.6052 17.6431 33.29 17.7593 33.8815 17.9916C34.4885 18.2084 34.8776 18.5569 35.0488 19.0371C35.329 19.8425 35.6325 20.7796 35.9593 21.8483C36.2862 22.9171 36.613 24.0245 36.9399 25.1707C37.2668 26.3014 37.5858 27.4244 37.8971 28.5396C38.2084 29.6393 38.4808 30.6151 38.7143 31.467C38.5275 31.6838 38.2473 31.8542 37.8738 31.9781C37.5002 32.1175 37.0566 32.1872 36.543 32.1872C36.1695 32.1872 35.8504 32.1562 35.5858 32.0943C35.3367 32.0323 35.1266 31.9394 34.9554 31.8155C34.7998 31.6916 34.6675 31.5289 34.5585 31.3276C34.4651 31.1262 34.3795 30.8939 34.3017 30.6306L33.8815 29.0275H29.6557C29.5312 29.4921 29.4066 29.9723 29.2821 30.4679C29.1576 30.9636 29.0331 31.4437 28.9086 31.9084C28.6907 31.9858 28.4572 32.0478 28.2082 32.0943C27.9747 32.1562 27.6868 32.1872 27.3443 32.1872C26.5505 32.1872 25.9591 32.0555 25.57 31.7922C25.1964 31.5134 25.0096 31.134 25.0096 30.6538C25.0096 30.437 25.0408 30.2201 25.103 30.0033C25.1653 29.7864 25.2353 29.5308 25.3132 29.2366C25.4221 28.8184 25.57 28.2995 25.7567 27.6799C25.9591 27.0604 26.177 26.3943 26.4105 25.6819C26.6439 24.9539 26.893 24.2104 27.1576 23.4514C27.4222 22.6925 27.6712 21.98 27.9047 21.314C28.1381 20.6325 28.3482 20.0284 28.535 19.5018C28.7374 18.9751 28.893 18.5724 29.002 18.2936ZM31.8036 21.2907C31.5857 21.9413 31.3366 22.6693 31.0565 23.4747C30.7919 24.2646 30.5506 25.07 30.3327 25.891H33.2277C33.0098 25.0545 32.7842 24.2414 32.5507 23.4514C32.3328 22.646 32.1304 21.9258 31.9437 21.2907H31.8036Z"
            fill="white"
          />
          <path
            d="M47.0031 31.8619C46.8319 31.9084 46.5673 31.9549 46.2093 32.0013C45.8514 32.0478 45.5012 32.071 45.1587 32.071C44.4428 32.071 43.9058 31.9549 43.5478 31.7225C43.1898 31.4747 43.0108 30.9636 43.0108 30.1891V27.0526C42.6217 26.4795 42.2015 25.8445 41.7501 25.1475C41.2987 24.4505 40.8551 23.738 40.4193 23.01C39.9835 22.282 39.5788 21.5695 39.2053 20.8725C38.8317 20.1601 38.5282 19.5095 38.2947 18.9209C38.4971 18.6421 38.7695 18.3866 39.1119 18.1542C39.4699 17.9219 39.9057 17.8057 40.4193 17.8057C41.0263 17.8057 41.5166 17.9296 41.8902 18.1775C42.2793 18.4253 42.6451 18.89 42.9875 19.5715L44.9253 23.4514H45.0653C45.2832 22.9713 45.47 22.5376 45.6257 22.1504C45.7969 21.7477 45.9603 21.3527 46.116 20.9655C46.2716 20.5628 46.435 20.1523 46.6062 19.7341C46.7774 19.3004 46.972 18.8048 47.1899 18.2472C47.4701 18.1078 47.7814 17.9993 48.1238 17.9219C48.4662 17.8445 48.7931 17.8057 49.1044 17.8057C49.6491 17.8057 50.1083 17.9529 50.4818 18.2472C50.8709 18.526 51.0655 18.9519 51.0655 19.525C51.0655 19.7109 51.0266 19.9355 50.9488 20.1988C50.8709 20.4621 50.6919 20.8648 50.4118 21.4069C50.1316 21.9335 49.7192 22.6538 49.1744 23.5676C48.6452 24.4815 47.9214 25.6741 47.0031 27.1456V31.8619Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.2056 2.63536L77.2454 5.10628C77.1479 5.22914 77.1369 5.39907 77.2177 5.53334C77.2985 5.66762 77.4543 5.73835 77.6093 5.71111L80.6505 5.1767C80.6649 5.70334 80.5198 6.24496 80.2337 6.77481C79.4149 8.29142 77.5765 8.87544 76.0686 8.07114C74.7465 7.36593 74.0429 5.91163 74.3692 4.58669C74.4241 4.36372 74.5083 4.14278 74.6245 3.92763C74.6283 3.92049 74.632 3.91323 74.6353 3.90586C75.1633 2.75582 76.1529 2.28975 77.1469 2.21365C77.6472 2.17534 78.141 2.23774 78.5596 2.36136C78.8177 2.43759 79.0358 2.53362 79.2056 2.63536ZM73.9501 3.58176C74.6198 2.13399 75.8903 1.55343 77.0886 1.46168C77.6859 1.41595 78.2727 1.48984 78.7757 1.6384C79.2721 1.785 79.7182 2.01378 80.0092 2.30332C80.145 2.43849 80.1577 2.65328 80.0387 2.8033L78.4577 4.79614L80.9199 4.36347C81.019 4.34605 81.1211 4.36849 81.2035 4.42584C81.286 4.48319 81.3421 4.57076 81.3596 4.66927C81.509 5.51084 81.3203 6.35724 80.9023 7.13143C79.8941 8.99882 77.6031 9.74555 75.7098 8.73569C74.1197 7.88753 73.214 6.10523 73.6322 4.40736C73.7013 4.1266 73.8066 3.84972 73.9501 3.58176ZM75.5545 3.76601C75.5189 4.02881 75.5984 4.26753 75.6895 4.42699C75.793 4.60807 76.0246 4.67149 76.2068 4.56862C76.389 4.46575 76.4528 4.23556 76.3493 4.05448C76.3173 3.99843 76.2985 3.92552 76.3065 3.8666C76.31 3.84076 76.3186 3.81584 76.3362 3.79031C76.3542 3.76441 76.3887 3.72746 76.4573 3.68795C76.5163 3.654 76.5724 3.65235 76.6298 3.67162C76.6962 3.69393 76.7498 3.74085 76.7741 3.77923C76.8855 3.95561 77.1197 4.00881 77.2971 3.89806C77.4746 3.78732 77.5281 3.55456 77.4167 3.37818C77.1978 3.03161 76.6314 2.71616 76.077 3.03533C75.7595 3.21811 75.5928 3.48258 75.5545 3.76601Z"
            fill="white"
          />
          <path
            d="M2.08804 38.3199C2.52606 38.3199 2.91583 38.3831 3.25734 38.5093C3.59885 38.6355 3.87539 38.8025 4.08698 39.0104C4.30228 39.2183 4.47861 39.467 4.61595 39.7565C4.86466 40.2799 4.98901 40.898 4.98901 41.6107V48.3537H3.28518V41.4381C3.28518 40.5657 3.04575 40.0089 2.56689 39.7677C2.37386 39.6749 2.14186 39.6284 1.87088 39.6284C1.39945 39.6284 0.775821 39.6693 0 39.7509V38.4424C0.660747 38.3608 1.35676 38.3199 2.08804 38.3199Z"
            fill="white"
          />
          <path
            d="M9.38224 38.3199C10.3362 38.3199 11.0768 38.6448 11.6039 39.2944C12.1347 39.944 12.4001 40.8516 12.4001 42.0172C12.4001 43.1828 12.131 44.0922 11.5928 44.7455C11.0582 45.3989 10.3232 45.7255 9.38781 45.7255C8.45237 45.7255 7.71367 45.4007 7.17171 44.7511C6.63346 44.0978 6.36433 43.1883 6.36433 42.0227C6.36433 41.5736 6.45157 41.1337 6.62603 40.7031C6.8005 40.2725 6.99538 39.957 7.21068 39.7565V39.712L6.4757 39.7621V38.4759C7.33689 38.3719 8.30574 38.3199 9.38224 38.3199ZM8.09044 42.0227C8.09044 43.5892 8.52104 44.3725 9.38224 44.3725C10.2434 44.3725 10.674 43.5818 10.674 42.0005C10.674 40.4191 10.2434 39.6284 9.38224 39.6284L8.75305 39.6396C8.31131 40.2521 8.09044 41.0465 8.09044 42.0227Z"
            fill="white"
          />
          <path
            d="M13.9202 40.5472C13.9202 39.8827 13.7847 39.1756 13.5138 38.4257H15.1397C15.1953 38.5705 15.251 38.7394 15.3067 38.9324L15.457 39.5282H15.5628C15.5777 39.4948 15.5999 39.4521 15.6296 39.4002C15.6593 39.3445 15.7336 39.248 15.8524 39.1106C15.9749 38.9696 16.1104 38.8471 16.2588 38.7431C16.4073 38.6355 16.6096 38.539 16.8658 38.4536C17.1256 38.3645 17.4151 38.3199 17.7344 38.3199C18.0573 38.3199 18.3506 38.3812 18.6141 38.5037C18.8777 38.6262 19.0893 38.7877 19.2489 38.9881C19.4122 39.1886 19.5459 39.4317 19.6498 39.7175C19.8317 40.2187 19.9226 40.8293 19.9226 41.5494V45.6142H16.8491V44.3168H18.1854V41.7443C18.1854 41.0836 18.1242 40.6177 18.0017 40.3467C17.946 40.2242 17.8754 40.1092 17.7901 40.0015C17.6193 39.7788 17.3483 39.6674 16.9771 39.6674C16.8064 39.6674 16.6393 39.7083 16.476 39.7899C16.3164 39.8679 16.1827 39.9644 16.0751 40.0795C15.8561 40.3133 15.7039 40.5212 15.6185 40.7031L15.5628 40.8256L15.1397 45.6142H13.4804L13.898 41.0539C13.9128 40.8831 13.9202 40.7142 13.9202 40.5472Z"
            fill="white"
          />
          <path
            d="M22.8737 38.4313H26.6545V39.5338L25.6801 42.947L25.0787 45.6142H23.425L24.0263 42.947L24.9451 39.7287H21.1755V36.9502H22.8737V38.4313Z"
            fill="white"
          />
          <path
            d="M35.9254 38.4313V41.0038C35.9254 41.7722 35.7082 42.3475 35.2739 42.7299C35.118 42.8672 34.9082 42.9786 34.6447 43.064C34.3848 43.1493 34.0842 43.192 33.7427 43.192C33.5905 43.192 33.4401 43.1828 33.2916 43.1642V48.3537H31.5934V42.4014L30.0065 38.4313H31.8662L33.0856 41.9336L33.1023 42.0172C33.2137 42.0283 33.3121 42.0339 33.3974 42.0339C33.6907 42.0339 33.9023 41.9559 34.0322 41.8C34.1621 41.6441 34.2271 41.3843 34.2271 41.0205V38.4313H35.9254Z"
            fill="white"
          />
          <path
            d="M39.088 45.6142H37.3898V38.4313H39.088V45.6142Z"
            fill="white"
          />
          <path
            d="M40.8754 45.6142V38.5594C41.1464 38.5 41.5603 38.4462 42.1171 38.3979C42.6776 38.3459 43.188 38.3199 43.6483 38.3199C44.1086 38.3199 44.4947 38.3459 44.8065 38.3979C45.1183 38.4499 45.38 38.5297 45.5916 38.6373C45.8032 38.745 45.9758 38.8693 46.1094 39.0104C46.2467 39.1477 46.3525 39.3203 46.4268 39.5282C46.501 39.7361 46.5511 39.9477 46.5771 40.163C46.6031 40.3746 46.6161 40.627 46.6161 40.9202V45.6142H44.9123V40.9147C44.9123 40.0572 44.4408 39.6284 43.498 39.6284C43.2418 39.6284 42.9356 39.6414 42.5792 39.6674V45.6142H40.8754Z"
            fill="white"
          />
          <path
            d="M48.487 40.5472C48.487 39.8827 48.3515 39.1756 48.0805 38.4257H49.7064C49.7621 38.5705 49.8178 38.7394 49.8734 38.9324L50.0238 39.5282H50.1296C50.1444 39.4948 50.1667 39.4521 50.1964 39.4002C50.2261 39.3445 50.3003 39.248 50.4191 39.1106C50.5416 38.9696 50.6771 38.8471 50.8256 38.7431C50.9741 38.6355 51.1764 38.539 51.4325 38.4536C51.6923 38.3645 51.9819 38.3199 52.3011 38.3199C52.6241 38.3199 52.9173 38.3812 53.1809 38.5037C53.4444 38.6262 53.656 38.7877 53.8156 38.9881C53.979 39.1886 54.1126 39.4317 54.2165 39.7175C54.3984 40.2187 54.4894 40.8293 54.4894 41.5494V45.6142H51.4158V44.3168H52.7521V41.7443C52.7521 41.0836 52.6909 40.6177 52.5684 40.3467C52.5127 40.2242 52.4422 40.1092 52.3568 40.0015C52.1861 39.7788 51.9151 39.6674 51.5439 39.6674C51.3731 39.6674 51.2061 39.7083 51.0427 39.7899C50.8831 39.8679 50.7495 39.9644 50.6418 40.0795C50.4228 40.3133 50.2706 40.5212 50.1852 40.7031L50.1296 40.8256L49.7064 45.6142H48.0471L48.4647 41.0539C48.4796 40.8831 48.487 40.7142 48.487 40.5472Z"
            fill="white"
          />
          <path
            d="M59.2056 45.6142V38.5594C59.4765 38.5 59.8904 38.4462 60.4473 38.3979C61.0078 38.3459 61.5405 38.3199 62.0453 38.3199C63.0772 38.3199 63.8178 38.487 64.267 38.8211C64.7198 39.1552 64.9463 39.6916 64.9463 40.4303V45.6142H59.2056ZM63.2424 44.3168V40.4303C63.2424 40.0925 63.1107 39.8716 62.8471 39.7677C62.617 39.6749 62.2773 39.6284 61.8281 39.6284C61.572 39.6284 61.2658 39.6414 60.9094 39.6674V44.3168H63.2424Z"
            fill="white"
          />
          <path
            d="M66.6278 43.1865V38.4313H68.3261V41.7499L67.1958 43.1865H66.6278Z"
            fill="white"
          />
          <path
            d="M74.2728 45.4248V45.6142H72.5857V45.4248C72.5857 45.1242 72.634 44.7103 72.7305 44.1832C72.827 43.6523 72.9458 43.192 73.0868 42.8023L74.2004 39.7287H69.9965V38.4313H76.0602V39.4558L74.8519 42.7354C74.4659 43.7822 74.2728 44.6787 74.2728 45.4248ZM71.5779 48.3592H70.0021V41.3156H71.5779V48.3592Z"
            fill="white"
          />
          <path
            d="M77.2907 45.6142V38.5594C77.5617 38.5 77.9756 38.4462 78.5324 38.3979C79.0929 38.3459 79.6034 38.3199 80.0637 38.3199C80.5239 38.3199 80.91 38.3459 81.2218 38.3979C81.5336 38.4499 81.7953 38.5297 82.0069 38.6373C82.2185 38.745 82.3911 38.8693 82.5247 39.0104C82.6621 39.1477 82.7679 39.3203 82.8421 39.5282C82.9164 39.7361 82.9665 39.9477 82.9925 40.163C83.0185 40.3746 83.0314 40.627 83.0314 40.9202V45.6142H81.3276V40.9147C81.3276 40.0572 80.8562 39.6284 79.9133 39.6284C79.6572 39.6284 79.3509 39.6414 78.9946 39.6674V45.6142H77.2907Z"
            fill="white"
          />
          <path
            d="M88.3211 45.6142H85.0694L84.4624 38.4313H86.0883L86.389 42.0283C86.4336 42.032 86.4892 42.0339 86.5561 42.0339C86.8493 42.0339 87.0665 41.9559 87.2075 41.8C87.3523 41.6441 87.4395 41.3843 87.4692 41.0205L87.6808 38.4313H89.2956L89.0895 41.0038C88.9745 42.4626 88.2135 43.192 86.8066 43.192C86.7287 43.192 86.6229 43.1883 86.4892 43.1809L86.5839 44.3168H88.3991C89.019 44.3168 89.496 44.0755 89.8301 43.5929C90.1642 43.1104 90.3665 42.4459 90.437 41.5996L90.7043 38.4313H92.3302L92.0629 41.555C92.0035 42.2306 91.9088 42.7726 91.7789 43.1809C91.2704 44.8031 90.1178 45.6142 88.3211 45.6142Z"
            fill="white"
          />
          <path
            d="M93.8447 40.5472C93.8447 39.8827 93.7092 39.1756 93.4382 38.4257H95.0641C95.1198 38.5705 95.1755 38.7394 95.2311 38.9324L95.3815 39.5282H95.4873C95.5021 39.4948 95.5244 39.4521 95.5541 39.4002C95.5838 39.3445 95.658 39.248 95.7768 39.1106C95.8993 38.9696 96.0348 38.8471 96.1833 38.7431C96.3318 38.6355 96.5341 38.539 96.7902 38.4536C97.0501 38.3645 97.3396 38.3199 97.6588 38.3199C97.9818 38.3199 98.275 38.3812 98.5386 38.5037C98.8021 38.6262 99.0137 38.7877 99.1734 38.9881C99.3367 39.1886 99.4703 39.4317 99.5743 39.7175C99.7561 40.2187 99.8471 40.8293 99.8471 41.5494V45.6142H96.7735V44.3168H98.1098V41.7443C98.1098 41.0836 98.0486 40.6177 97.9261 40.3467C97.8704 40.2242 97.7999 40.1092 97.7145 40.0015C97.5438 39.7788 97.2728 39.6674 96.9016 39.6674C96.7308 39.6674 96.5638 39.7083 96.4004 39.7899C96.2408 39.8679 96.1072 39.9644 95.9995 40.0795C95.7805 40.3133 95.6283 40.5212 95.543 40.7031L95.4873 40.8256L95.0641 45.6142H93.4048L93.8224 41.0539C93.8373 40.8831 93.8447 40.7142 93.8447 40.5472Z"
            fill="white"
          />
        </svg>
      </div>
      <label class="head-label">{{ $ml.get("התחברות") }}</label>

      <div class="services" v-if="!$store.getters['sub/getDisabled']">
        <div>
          <button class="button btn-google" @click="login">
            <img alt="" width="24px" src="@/assets/img/services/Google.png" />{{
              $ml.get("המשך עם חשבון")
            }}
            Google
          </button>
        </div>
        <div>
          <button class="button btn-facebook" @click="logInWithFacebook">
            <img
              alt=""
              width="24px"
              src="@/assets/img/services/Facebook.png"
            />{{ $ml.get("המשך עם חשבון") }}
            Facebook
          </button>
        </div>
      </div>
      <div class="dividers" v-if="!$store.getters['sub/getDisabled']">
        <hr />
        <div style="color: white">{{ $ml.get("או") }}</div>
      </div>
      <div class="email-input">
        <v-text-field
          :label="$ml.get('אימייל')"
          class="test"
          id="email"
          v-model="userNew.email"
          autocomplete="new-password"
        ></v-text-field>
      </div>

      <div class="password-input">
        <v-text-field
          v-model="userNew.password"
          :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :type="show ? 'text' : 'password'"
          class="test"
          id="pass"
          name="input-10-1"
          :label="$ml.get('סיסמה')"
          @click:append="show = !show"
          autocomplete="new-password"
        ></v-text-field>
      </div>
      <canvas
        style="border-radius: 6px; margin-top: 10px"
        ref="captchaCanvas"
        v-show="showCaptcha"
      ></canvas>
      <div class="captcha-input" v-show="showCaptcha">
        <v-text-field
          :label="$ml.get('קוד captcha')"
          class="test"
          id="captcha"
          v-model="enteredCaptcha"
          @input="verifyCaptcha"
          autocomplete="new-password"
        ></v-text-field>
      </div>
      <div class="forgot-input" v-show="showCaptcha" style="margin-bottom: 5px">
        <span class="link" @click="reinitCaptcha">{{
          $ml.get("רענן קוד captcha")
        }}</span>
      </div>
      <div class="forgot-input" style="margin-bottom: 20px">
        <span class="link" @click="redirectTo">{{
          $ml.get("שכחתי את הסיסמה")
        }}</span>
      </div>

      <div class="button" style="margin-top: 50px">
        <app-btn
          :class="disabledLogin ? 'disabled' : 'active'"
          @click="loginNew"
          :loading="loadingLogin"
          class="reg-b"
        >
          {{ $ml.get("המשך") }}</app-btn
        >
      </div>
      <div class="alert">
        <span
          >{{ $ml.get("אין לכם חשבון") + " ? " }}
          <router-link :to="{ name: 'auth-reg' }" class="link">{{
            $ml.get("הרשמו")
          }}</router-link></span
        >
        <span>
          <br />
          <router-link :to="{ name: 'auth-restore' }" class="link">{{
            $ml.get("לשחזר חשבון")
          }}</router-link></span
        >
      </div>
    </div>
  </div>
  <div
    class=""
    v-else-if="main && ($ml.current == 'eng' || $ml.current == 'ru')"
    style="direction: ltr !important"
  >
    <div class="main-wrapper sm-cont">
      <MainHeader :sidebar="false" />
      <div
        @click="pushBack"
        style="
          font-size: 16px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          transform: scaleX(-1);
        "
      >
        <span style="color: white; position: absolute; right: 50px; top: 100px"
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 1L12 7L6 13" stroke="white" stroke-width="2" />
            <path d="M0 7H12" stroke="white" stroke-width="2" /></svg
        ></span>
      </div>
      <div class="dropdown">
        <button @click="open" class="dropbtn">
          <svg
            height="30"
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            width="30"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              fill="#fff"
              d="M256,48c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0C140.8,48.3,48,141.3,48,256c0,114.7,92.8,207.7,207.5,208  c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c114.9,0,208-93.1,208-208C464,141.1,370.9,48,256,48z M264.3,172.5  c22.1-0.6,43.5-3.5,64.2-8.5c6.2,24.5,10.1,52.8,10.7,83.8h-74.9V172.5z M264.3,155.8V66c22.4,6.2,45.2,36.1,59.6,82  C304.7,152.6,284.8,155.2,264.3,155.8z M247.7,65.8v90.1c-20.7-0.6-40.8-3.3-60.1-8C202.2,101.7,225.1,71.6,247.7,65.8z   M247.7,172.5v75.2h-75.4c0.6-31,4.5-59.3,10.7-83.8C203.8,168.9,225.5,171.9,247.7,172.5z M155.5,247.7H64.9  c1.8-42.8,17.8-82,43.3-113c18.5,10.2,38.2,18.6,58.8,24.8C160.2,186,156.2,215.9,155.5,247.7z M155.5,264.3  c0.6,31.7,4.6,61.7,11.4,88.2c-20.6,6.3-40.2,14.6-58.8,24.8c-25.5-31-41.4-70.2-43.3-113H155.5z M172.3,264.3h75.4v75.1  c-22.2,0.6-43.9,3.6-64.7,8.7C176.8,323.6,172.9,295.3,172.3,264.3z M247.7,356.1v90.2c-22.6-5.9-45.5-35.9-60.1-82.1  C206.9,359.4,227,356.7,247.7,356.1z M264.3,446v-90c20.5,0.6,40.4,3.3,59.7,7.9C309.5,409.9,286.8,439.8,264.3,446z M264.3,339.4  v-75.1h74.9c-0.6,30.9-4.5,59.2-10.7,83.7C307.8,343,286.4,340,264.3,339.4z M355.9,264.3h91.2c-1.8,42.8-17.8,81.9-43.3,113  c-18.7-10.3-38.5-18.7-59.3-25C351.3,325.8,355.3,296,355.9,264.3z M355.9,247.7c-0.6-31.7-4.6-61.6-11.3-88.1  c20.8-6.3,40.6-14.7,59.2-24.9c25.5,31,41.5,70.2,43.3,113.1H355.9z M392.4,121.9c-16.6,8.8-34,16.1-52.3,21.6  c-9.7-31.3-23.4-56.8-39.5-73.6C336,78.4,367.6,96.8,392.4,121.9z M210.8,70.1c-16.1,16.7-29.7,42.2-39.3,73.3  c-18.1-5.5-35.4-12.7-51.8-21.5C144.2,96.9,175.6,78.6,210.8,70.1z M119.6,390c16.4-8.8,33.8-16,51.8-21.5  c9.7,31.2,23.3,56.6,39.4,73.4C175.6,433.4,144.2,415.1,119.6,390z M300.6,442.1c16.2-16.8,29.8-42.3,39.6-73.7  c18.3,5.5,35.7,12.8,52.3,21.6C367.7,415.2,336,433.6,300.6,442.1z"
            />
          </svg>
        </button>
        <div class="dropdown-content" v-if="opened == true">
          <a
            @click="changeLang('heb')"
            :class="$ml.current == 'heb' ? 'disabled-link' : ''"
            value="heb"
            href="#"
            ><img width="32px" src="../../assets/img/langs/heb.png" /> Hebrew</a
          >
          <a
            @click="changeLang('eng')"
            :class="$ml.current == 'eng' ? 'disabled-link' : ''"
            value="eng"
            href="#"
            ><img width="32px" src="../../assets/img/langs/eng.png" />
            English</a
          >
          <a
            @click="changeLang('ar')"
            :class="$ml.current == 'ar' ? 'disabled-link' : ''"
            value="ar"
            href="#"
            ><img
              width="32px"
              style="border-radius: 50%"
              src="../../assets/img/langs/ar.png"
            />
            Arabic</a
          >
          <a
            @click="changeLang('ru')"
            :class="$ml.current == 'ru' ? 'disabled-link' : ''"
            value="ru"
            href="#"
            ><img width="32px" src="../../assets/img/langs/ru.png" /> Russian</a
          >
        </div>
      </div>
      <div style="width: 100%; padding-top: 8px; padding-bottom: 24px">
        <svg
          style="margin: auto; left: 0; right: 0"
          width="101"
          height="49"
          viewBox="0 0 101 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M59.3157 32.2574C58.6429 32.2574 58.1108 32.0607 57.7196 31.6673C57.3284 31.2739 57.1328 30.7388 57.1328 30.0621V17.8582C57.3049 17.8267 57.5788 17.7873 57.9543 17.7401C58.3299 17.6772 58.6898 17.6457 59.0341 17.6457C59.394 17.6457 59.707 17.6772 59.973 17.7401C60.2546 17.7873 60.4894 17.8818 60.6771 18.0234C60.8649 18.165 61.0058 18.3617 61.0997 18.6135C61.1935 18.8653 61.2405 19.1958 61.2405 19.605V28.9291H66.6157C66.7252 29.1022 66.8269 29.3382 66.9208 29.6372C67.0147 29.9205 67.0616 30.2195 67.0616 30.5342C67.0616 31.1637 66.9286 31.6122 66.6626 31.8797C66.3966 32.1315 66.0445 32.2574 65.6064 32.2574H59.3157Z"
            fill="white"
          />
          <path
            d="M73.2747 32.0922C73.1025 32.1394 72.8287 32.1866 72.4531 32.2338C72.0932 32.281 71.7333 32.3046 71.3734 32.3046C71.0135 32.3046 70.6927 32.2732 70.411 32.2102C70.145 32.163 69.9181 32.0686 69.7303 31.927C69.5426 31.7853 69.4017 31.5886 69.3078 31.3368C69.214 31.085 69.167 30.7546 69.167 30.3454V17.8818C69.3391 17.8503 69.613 17.8109 69.9885 17.7637C70.3641 17.7008 70.724 17.6693 71.0683 17.6693C71.4282 17.6693 71.7411 17.7008 72.0072 17.7637C72.2888 17.8109 72.5236 17.9054 72.7113 18.047C72.8991 18.1886 73.04 18.3853 73.1338 18.6371C73.2277 18.8889 73.2747 19.2194 73.2747 19.6286V32.0922Z"
            fill="white"
          />
          <path
            d="M80.356 26.4977C79.6675 26.2617 79.0416 26.0256 78.4782 25.7896C77.9149 25.5378 77.4298 25.2388 77.0229 24.8926C76.6161 24.5463 76.2953 24.1372 76.0606 23.6651C75.8415 23.1772 75.732 22.5871 75.732 21.8947C75.732 20.557 76.2405 19.4869 77.2577 18.6843C78.2904 17.8818 79.7301 17.4805 81.5766 17.4805C82.2495 17.4805 82.8754 17.5277 83.4544 17.6221C84.0334 17.7165 84.5263 17.866 84.9331 18.0706C85.3556 18.2594 85.6843 18.5112 85.919 18.826C86.1537 19.125 86.2711 19.4791 86.2711 19.8882C86.2711 20.2974 86.1772 20.6515 85.9894 20.9505C85.8016 21.2337 85.5747 21.4776 85.3087 21.6822C84.9644 21.4619 84.5028 21.2731 83.9238 21.1157C83.3448 20.9426 82.7111 20.856 82.0226 20.856C81.3184 20.856 80.802 20.9583 80.4734 21.1629C80.1448 21.3517 79.9805 21.5957 79.9805 21.8947C79.9805 22.1307 80.0822 22.3274 80.2856 22.4848C80.489 22.6264 80.7942 22.7602 81.201 22.8861L82.4451 23.2874C83.916 23.7595 85.0427 24.3654 85.8251 25.105C86.6232 25.8289 87.0222 26.8203 87.0222 28.0793C87.0222 29.4169 86.498 30.5028 85.4495 31.3368C84.4011 32.1551 82.8597 32.5643 80.8255 32.5643C80.1056 32.5643 79.4328 32.5013 78.8068 32.3755C78.1966 32.2653 77.6567 32.1001 77.1872 31.8797C76.7334 31.6437 76.3735 31.3604 76.1075 31.0299C75.8571 30.6837 75.732 30.2903 75.732 29.8497C75.732 29.3933 75.865 29.0078 76.131 28.693C76.397 28.3625 76.6865 28.1108 76.9995 27.9376C77.4376 28.2839 77.9697 28.5829 78.5956 28.8347C79.2372 29.0864 79.9335 29.2123 80.6846 29.2123C81.4514 29.2123 81.9913 29.0943 82.3042 28.8583C82.6172 28.6222 82.7737 28.3468 82.7737 28.0321C82.7737 27.7173 82.6485 27.4813 82.3981 27.3239C82.1477 27.1508 81.7957 26.9856 81.3419 26.8282L80.356 26.4977Z"
            fill="white"
          />
          <path
            d="M88.2917 21.1865C88.1822 21.0134 88.0805 20.7774 87.9866 20.4784C87.8927 20.1793 87.8457 19.8646 87.8457 19.5341C87.8457 18.9204 87.9787 18.4798 88.2448 18.2122C88.5264 17.9447 88.8863 17.8109 89.3245 17.8109H99.5585C99.668 17.984 99.7697 18.2201 99.8636 18.5191C99.9575 18.8181 100.004 19.1328 100.004 19.4633C100.004 20.0771 99.8636 20.5177 99.5819 20.7852C99.3159 21.0527 98.9638 21.1865 98.5257 21.1865H95.9202V32.0922C95.7481 32.1394 95.4743 32.1866 95.0987 32.2338C94.7388 32.281 94.3789 32.3046 94.019 32.3046C93.6591 32.3046 93.3383 32.2732 93.0566 32.2102C92.7906 32.163 92.5637 32.0686 92.3759 31.927C92.1881 31.7853 92.0473 31.5886 91.9534 31.3368C91.8595 31.085 91.8126 30.7546 91.8126 30.3454V21.1865H88.2917Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.0415 9.85003C60.5662 7.92813 56.6491 6.67839 54.1084 6.11837L54.2642 5.41309C56.8571 5.9846 60.8427 7.25552 64.3917 9.21814C67.9228 11.1709 71.1147 13.86 71.9625 17.3079L71.2603 17.4801C70.4885 14.3411 67.5346 11.7818 64.0415 9.85003ZM59.155 9.45168C54.4761 7.78368 49.3861 6.95449 46.1328 6.70359L46.1885 5.98348C49.4907 6.23815 54.646 7.07737 59.398 8.77145C64.1268 10.4573 68.564 13.0275 70.2778 16.8118L69.6191 17.1095C68.0358 13.6135 63.8569 11.1279 59.155 9.45168ZM73.4987 13.2098C73.1695 14.3433 72.9044 15.5101 72.7615 16.2359L72.052 16.0965C72.1983 15.3537 72.4682 14.1659 72.8043 13.0086C73.1359 11.8665 73.5462 10.7022 73.9858 10.0434L74.5874 10.444C74.2173 10.9986 73.8322 12.0612 73.4987 13.2098Z"
            fill="white"
          />
          <path
            d="M5.52631 24.404C6.18002 24.404 6.67808 24.2569 7.02051 23.9626C7.37849 23.6528 7.55748 23.1881 7.55748 22.5686C7.55748 21.98 7.37071 21.5308 6.99716 21.221C6.63917 20.8958 6.10998 20.7331 5.40957 20.7331C5.16054 20.7331 4.95042 20.7409 4.7792 20.7564C4.62356 20.7564 4.46013 20.7719 4.28892 20.8028V24.404H5.52631ZM4.31227 31.8619C4.14106 31.9084 3.86868 31.9549 3.49513 32.0013C3.13714 32.0478 2.77916 32.071 2.42117 32.071C2.06318 32.071 1.74411 32.0401 1.46395 31.9781C1.19935 31.9316 0.973663 31.8387 0.786888 31.6993C0.600113 31.5599 0.460031 31.3663 0.366644 31.1185C0.273256 30.8706 0.226562 30.5454 0.226562 30.1427V19.1997C0.226562 18.8745 0.312168 18.6266 0.483378 18.4563C0.670153 18.2704 0.919187 18.1233 1.23048 18.0148C1.75967 17.829 2.35891 17.6973 3.02819 17.6199C3.71303 17.5269 4.39787 17.4805 5.08271 17.4805C7.23063 17.4805 8.87269 17.9374 10.0089 18.8512C11.1451 19.7651 11.7132 21.0042 11.7132 22.5686C11.7132 23.3275 11.5887 24.0168 11.3397 24.6363C11.1062 25.2404 10.7482 25.767 10.2657 26.2162C9.79878 26.6499 9.19955 26.9907 8.46801 27.2385C7.75204 27.4708 6.91934 27.587 5.9699 27.587H4.31227V31.8619Z"
            fill="white"
          />
          <path
            d="M15.9636 32.0246C15.2944 32.0246 14.7652 31.831 14.376 31.4437C13.9869 31.0565 13.7924 30.5299 13.7924 29.8639V17.8522C13.9636 17.8212 14.236 17.7825 14.6095 17.736C14.9831 17.6741 15.3411 17.6431 15.6835 17.6431C16.0415 17.6431 16.3528 17.6741 16.6174 17.736C16.8975 17.7825 17.131 17.8754 17.3178 18.0148C17.5045 18.1542 17.6446 18.3478 17.738 18.5957C17.8314 18.8435 17.8781 19.1688 17.8781 19.5715V28.7487H23.2245C23.3335 28.919 23.4346 29.1514 23.528 29.4457C23.6214 29.7245 23.6681 30.0187 23.6681 30.3285C23.6681 30.9481 23.5358 31.3895 23.2712 31.6528C23.0066 31.9007 22.6564 32.0246 22.2206 32.0246H15.9636Z"
            fill="white"
          />
          <path
            d="M29.002 18.2936C29.2666 18.1387 29.6635 17.9916 30.1926 17.8522C30.7374 17.7128 31.2822 17.6431 31.8269 17.6431C32.6052 17.6431 33.29 17.7593 33.8815 17.9916C34.4885 18.2084 34.8776 18.5569 35.0488 19.0371C35.329 19.8425 35.6325 20.7796 35.9593 21.8483C36.2862 22.9171 36.613 24.0245 36.9399 25.1707C37.2668 26.3014 37.5858 27.4244 37.8971 28.5396C38.2084 29.6393 38.4808 30.6151 38.7143 31.467C38.5275 31.6838 38.2473 31.8542 37.8738 31.9781C37.5002 32.1175 37.0566 32.1872 36.543 32.1872C36.1695 32.1872 35.8504 32.1562 35.5858 32.0943C35.3367 32.0323 35.1266 31.9394 34.9554 31.8155C34.7998 31.6916 34.6675 31.5289 34.5585 31.3276C34.4651 31.1262 34.3795 30.8939 34.3017 30.6306L33.8815 29.0275H29.6557C29.5312 29.4921 29.4066 29.9723 29.2821 30.4679C29.1576 30.9636 29.0331 31.4437 28.9086 31.9084C28.6907 31.9858 28.4572 32.0478 28.2082 32.0943C27.9747 32.1562 27.6868 32.1872 27.3443 32.1872C26.5505 32.1872 25.9591 32.0555 25.57 31.7922C25.1964 31.5134 25.0096 31.134 25.0096 30.6538C25.0096 30.437 25.0408 30.2201 25.103 30.0033C25.1653 29.7864 25.2353 29.5308 25.3132 29.2366C25.4221 28.8184 25.57 28.2995 25.7567 27.6799C25.9591 27.0604 26.177 26.3943 26.4105 25.6819C26.6439 24.9539 26.893 24.2104 27.1576 23.4514C27.4222 22.6925 27.6712 21.98 27.9047 21.314C28.1381 20.6325 28.3482 20.0284 28.535 19.5018C28.7374 18.9751 28.893 18.5724 29.002 18.2936ZM31.8036 21.2907C31.5857 21.9413 31.3366 22.6693 31.0565 23.4747C30.7919 24.2646 30.5506 25.07 30.3327 25.891H33.2277C33.0098 25.0545 32.7842 24.2414 32.5507 23.4514C32.3328 22.646 32.1304 21.9258 31.9437 21.2907H31.8036Z"
            fill="white"
          />
          <path
            d="M47.0031 31.8619C46.8319 31.9084 46.5673 31.9549 46.2093 32.0013C45.8514 32.0478 45.5012 32.071 45.1587 32.071C44.4428 32.071 43.9058 31.9549 43.5478 31.7225C43.1898 31.4747 43.0108 30.9636 43.0108 30.1891V27.0526C42.6217 26.4795 42.2015 25.8445 41.7501 25.1475C41.2987 24.4505 40.8551 23.738 40.4193 23.01C39.9835 22.282 39.5788 21.5695 39.2053 20.8725C38.8317 20.1601 38.5282 19.5095 38.2947 18.9209C38.4971 18.6421 38.7695 18.3866 39.1119 18.1542C39.4699 17.9219 39.9057 17.8057 40.4193 17.8057C41.0263 17.8057 41.5166 17.9296 41.8902 18.1775C42.2793 18.4253 42.6451 18.89 42.9875 19.5715L44.9253 23.4514H45.0653C45.2832 22.9713 45.47 22.5376 45.6257 22.1504C45.7969 21.7477 45.9603 21.3527 46.116 20.9655C46.2716 20.5628 46.435 20.1523 46.6062 19.7341C46.7774 19.3004 46.972 18.8048 47.1899 18.2472C47.4701 18.1078 47.7814 17.9993 48.1238 17.9219C48.4662 17.8445 48.7931 17.8057 49.1044 17.8057C49.6491 17.8057 50.1083 17.9529 50.4818 18.2472C50.8709 18.526 51.0655 18.9519 51.0655 19.525C51.0655 19.7109 51.0266 19.9355 50.9488 20.1988C50.8709 20.4621 50.6919 20.8648 50.4118 21.4069C50.1316 21.9335 49.7192 22.6538 49.1744 23.5676C48.6452 24.4815 47.9214 25.6741 47.0031 27.1456V31.8619Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.2056 2.63536L77.2454 5.10628C77.1479 5.22914 77.1369 5.39907 77.2177 5.53334C77.2985 5.66762 77.4543 5.73835 77.6093 5.71111L80.6505 5.1767C80.6649 5.70334 80.5198 6.24496 80.2337 6.77481C79.4149 8.29142 77.5765 8.87544 76.0686 8.07114C74.7465 7.36593 74.0429 5.91163 74.3692 4.58669C74.4241 4.36372 74.5083 4.14278 74.6245 3.92763C74.6283 3.92049 74.632 3.91323 74.6353 3.90586C75.1633 2.75582 76.1529 2.28975 77.1469 2.21365C77.6472 2.17534 78.141 2.23774 78.5596 2.36136C78.8177 2.43759 79.0358 2.53362 79.2056 2.63536ZM73.9501 3.58176C74.6198 2.13399 75.8903 1.55343 77.0886 1.46168C77.6859 1.41595 78.2727 1.48984 78.7757 1.6384C79.2721 1.785 79.7182 2.01378 80.0092 2.30332C80.145 2.43849 80.1577 2.65328 80.0387 2.8033L78.4577 4.79614L80.9199 4.36347C81.019 4.34605 81.1211 4.36849 81.2035 4.42584C81.286 4.48319 81.3421 4.57076 81.3596 4.66927C81.509 5.51084 81.3203 6.35724 80.9023 7.13143C79.8941 8.99882 77.6031 9.74555 75.7098 8.73569C74.1197 7.88753 73.214 6.10523 73.6322 4.40736C73.7013 4.1266 73.8066 3.84972 73.9501 3.58176ZM75.5545 3.76601C75.5189 4.02881 75.5984 4.26753 75.6895 4.42699C75.793 4.60807 76.0246 4.67149 76.2068 4.56862C76.389 4.46575 76.4528 4.23556 76.3493 4.05448C76.3173 3.99843 76.2985 3.92552 76.3065 3.8666C76.31 3.84076 76.3186 3.81584 76.3362 3.79031C76.3542 3.76441 76.3887 3.72746 76.4573 3.68795C76.5163 3.654 76.5724 3.65235 76.6298 3.67162C76.6962 3.69393 76.7498 3.74085 76.7741 3.77923C76.8855 3.95561 77.1197 4.00881 77.2971 3.89806C77.4746 3.78732 77.5281 3.55456 77.4167 3.37818C77.1978 3.03161 76.6314 2.71616 76.077 3.03533C75.7595 3.21811 75.5928 3.48258 75.5545 3.76601Z"
            fill="white"
          />
          <path
            d="M2.08804 38.3199C2.52606 38.3199 2.91583 38.3831 3.25734 38.5093C3.59885 38.6355 3.87539 38.8025 4.08698 39.0104C4.30228 39.2183 4.47861 39.467 4.61595 39.7565C4.86466 40.2799 4.98901 40.898 4.98901 41.6107V48.3537H3.28518V41.4381C3.28518 40.5657 3.04575 40.0089 2.56689 39.7677C2.37386 39.6749 2.14186 39.6284 1.87088 39.6284C1.39945 39.6284 0.775821 39.6693 0 39.7509V38.4424C0.660747 38.3608 1.35676 38.3199 2.08804 38.3199Z"
            fill="white"
          />
          <path
            d="M9.38224 38.3199C10.3362 38.3199 11.0768 38.6448 11.6039 39.2944C12.1347 39.944 12.4001 40.8516 12.4001 42.0172C12.4001 43.1828 12.131 44.0922 11.5928 44.7455C11.0582 45.3989 10.3232 45.7255 9.38781 45.7255C8.45237 45.7255 7.71367 45.4007 7.17171 44.7511C6.63346 44.0978 6.36433 43.1883 6.36433 42.0227C6.36433 41.5736 6.45157 41.1337 6.62603 40.7031C6.8005 40.2725 6.99538 39.957 7.21068 39.7565V39.712L6.4757 39.7621V38.4759C7.33689 38.3719 8.30574 38.3199 9.38224 38.3199ZM8.09044 42.0227C8.09044 43.5892 8.52104 44.3725 9.38224 44.3725C10.2434 44.3725 10.674 43.5818 10.674 42.0005C10.674 40.4191 10.2434 39.6284 9.38224 39.6284L8.75305 39.6396C8.31131 40.2521 8.09044 41.0465 8.09044 42.0227Z"
            fill="white"
          />
          <path
            d="M13.9202 40.5472C13.9202 39.8827 13.7847 39.1756 13.5138 38.4257H15.1397C15.1953 38.5705 15.251 38.7394 15.3067 38.9324L15.457 39.5282H15.5628C15.5777 39.4948 15.5999 39.4521 15.6296 39.4002C15.6593 39.3445 15.7336 39.248 15.8524 39.1106C15.9749 38.9696 16.1104 38.8471 16.2588 38.7431C16.4073 38.6355 16.6096 38.539 16.8658 38.4536C17.1256 38.3645 17.4151 38.3199 17.7344 38.3199C18.0573 38.3199 18.3506 38.3812 18.6141 38.5037C18.8777 38.6262 19.0893 38.7877 19.2489 38.9881C19.4122 39.1886 19.5459 39.4317 19.6498 39.7175C19.8317 40.2187 19.9226 40.8293 19.9226 41.5494V45.6142H16.8491V44.3168H18.1854V41.7443C18.1854 41.0836 18.1242 40.6177 18.0017 40.3467C17.946 40.2242 17.8754 40.1092 17.7901 40.0015C17.6193 39.7788 17.3483 39.6674 16.9771 39.6674C16.8064 39.6674 16.6393 39.7083 16.476 39.7899C16.3164 39.8679 16.1827 39.9644 16.0751 40.0795C15.8561 40.3133 15.7039 40.5212 15.6185 40.7031L15.5628 40.8256L15.1397 45.6142H13.4804L13.898 41.0539C13.9128 40.8831 13.9202 40.7142 13.9202 40.5472Z"
            fill="white"
          />
          <path
            d="M22.8737 38.4313H26.6545V39.5338L25.6801 42.947L25.0787 45.6142H23.425L24.0263 42.947L24.9451 39.7287H21.1755V36.9502H22.8737V38.4313Z"
            fill="white"
          />
          <path
            d="M35.9254 38.4313V41.0038C35.9254 41.7722 35.7082 42.3475 35.2739 42.7299C35.118 42.8672 34.9082 42.9786 34.6447 43.064C34.3848 43.1493 34.0842 43.192 33.7427 43.192C33.5905 43.192 33.4401 43.1828 33.2916 43.1642V48.3537H31.5934V42.4014L30.0065 38.4313H31.8662L33.0856 41.9336L33.1023 42.0172C33.2137 42.0283 33.3121 42.0339 33.3974 42.0339C33.6907 42.0339 33.9023 41.9559 34.0322 41.8C34.1621 41.6441 34.2271 41.3843 34.2271 41.0205V38.4313H35.9254Z"
            fill="white"
          />
          <path
            d="M39.088 45.6142H37.3898V38.4313H39.088V45.6142Z"
            fill="white"
          />
          <path
            d="M40.8754 45.6142V38.5594C41.1464 38.5 41.5603 38.4462 42.1171 38.3979C42.6776 38.3459 43.188 38.3199 43.6483 38.3199C44.1086 38.3199 44.4947 38.3459 44.8065 38.3979C45.1183 38.4499 45.38 38.5297 45.5916 38.6373C45.8032 38.745 45.9758 38.8693 46.1094 39.0104C46.2467 39.1477 46.3525 39.3203 46.4268 39.5282C46.501 39.7361 46.5511 39.9477 46.5771 40.163C46.6031 40.3746 46.6161 40.627 46.6161 40.9202V45.6142H44.9123V40.9147C44.9123 40.0572 44.4408 39.6284 43.498 39.6284C43.2418 39.6284 42.9356 39.6414 42.5792 39.6674V45.6142H40.8754Z"
            fill="white"
          />
          <path
            d="M48.487 40.5472C48.487 39.8827 48.3515 39.1756 48.0805 38.4257H49.7064C49.7621 38.5705 49.8178 38.7394 49.8734 38.9324L50.0238 39.5282H50.1296C50.1444 39.4948 50.1667 39.4521 50.1964 39.4002C50.2261 39.3445 50.3003 39.248 50.4191 39.1106C50.5416 38.9696 50.6771 38.8471 50.8256 38.7431C50.9741 38.6355 51.1764 38.539 51.4325 38.4536C51.6923 38.3645 51.9819 38.3199 52.3011 38.3199C52.6241 38.3199 52.9173 38.3812 53.1809 38.5037C53.4444 38.6262 53.656 38.7877 53.8156 38.9881C53.979 39.1886 54.1126 39.4317 54.2165 39.7175C54.3984 40.2187 54.4894 40.8293 54.4894 41.5494V45.6142H51.4158V44.3168H52.7521V41.7443C52.7521 41.0836 52.6909 40.6177 52.5684 40.3467C52.5127 40.2242 52.4422 40.1092 52.3568 40.0015C52.1861 39.7788 51.9151 39.6674 51.5439 39.6674C51.3731 39.6674 51.2061 39.7083 51.0427 39.7899C50.8831 39.8679 50.7495 39.9644 50.6418 40.0795C50.4228 40.3133 50.2706 40.5212 50.1852 40.7031L50.1296 40.8256L49.7064 45.6142H48.0471L48.4647 41.0539C48.4796 40.8831 48.487 40.7142 48.487 40.5472Z"
            fill="white"
          />
          <path
            d="M59.2056 45.6142V38.5594C59.4765 38.5 59.8904 38.4462 60.4473 38.3979C61.0078 38.3459 61.5405 38.3199 62.0453 38.3199C63.0772 38.3199 63.8178 38.487 64.267 38.8211C64.7198 39.1552 64.9463 39.6916 64.9463 40.4303V45.6142H59.2056ZM63.2424 44.3168V40.4303C63.2424 40.0925 63.1107 39.8716 62.8471 39.7677C62.617 39.6749 62.2773 39.6284 61.8281 39.6284C61.572 39.6284 61.2658 39.6414 60.9094 39.6674V44.3168H63.2424Z"
            fill="white"
          />
          <path
            d="M66.6278 43.1865V38.4313H68.3261V41.7499L67.1958 43.1865H66.6278Z"
            fill="white"
          />
          <path
            d="M74.2728 45.4248V45.6142H72.5857V45.4248C72.5857 45.1242 72.634 44.7103 72.7305 44.1832C72.827 43.6523 72.9458 43.192 73.0868 42.8023L74.2004 39.7287H69.9965V38.4313H76.0602V39.4558L74.8519 42.7354C74.4659 43.7822 74.2728 44.6787 74.2728 45.4248ZM71.5779 48.3592H70.0021V41.3156H71.5779V48.3592Z"
            fill="white"
          />
          <path
            d="M77.2907 45.6142V38.5594C77.5617 38.5 77.9756 38.4462 78.5324 38.3979C79.0929 38.3459 79.6034 38.3199 80.0637 38.3199C80.5239 38.3199 80.91 38.3459 81.2218 38.3979C81.5336 38.4499 81.7953 38.5297 82.0069 38.6373C82.2185 38.745 82.3911 38.8693 82.5247 39.0104C82.6621 39.1477 82.7679 39.3203 82.8421 39.5282C82.9164 39.7361 82.9665 39.9477 82.9925 40.163C83.0185 40.3746 83.0314 40.627 83.0314 40.9202V45.6142H81.3276V40.9147C81.3276 40.0572 80.8562 39.6284 79.9133 39.6284C79.6572 39.6284 79.3509 39.6414 78.9946 39.6674V45.6142H77.2907Z"
            fill="white"
          />
          <path
            d="M88.3211 45.6142H85.0694L84.4624 38.4313H86.0883L86.389 42.0283C86.4336 42.032 86.4892 42.0339 86.5561 42.0339C86.8493 42.0339 87.0665 41.9559 87.2075 41.8C87.3523 41.6441 87.4395 41.3843 87.4692 41.0205L87.6808 38.4313H89.2956L89.0895 41.0038C88.9745 42.4626 88.2135 43.192 86.8066 43.192C86.7287 43.192 86.6229 43.1883 86.4892 43.1809L86.5839 44.3168H88.3991C89.019 44.3168 89.496 44.0755 89.8301 43.5929C90.1642 43.1104 90.3665 42.4459 90.437 41.5996L90.7043 38.4313H92.3302L92.0629 41.555C92.0035 42.2306 91.9088 42.7726 91.7789 43.1809C91.2704 44.8031 90.1178 45.6142 88.3211 45.6142Z"
            fill="white"
          />
          <path
            d="M93.8447 40.5472C93.8447 39.8827 93.7092 39.1756 93.4382 38.4257H95.0641C95.1198 38.5705 95.1755 38.7394 95.2311 38.9324L95.3815 39.5282H95.4873C95.5021 39.4948 95.5244 39.4521 95.5541 39.4002C95.5838 39.3445 95.658 39.248 95.7768 39.1106C95.8993 38.9696 96.0348 38.8471 96.1833 38.7431C96.3318 38.6355 96.5341 38.539 96.7902 38.4536C97.0501 38.3645 97.3396 38.3199 97.6588 38.3199C97.9818 38.3199 98.275 38.3812 98.5386 38.5037C98.8021 38.6262 99.0137 38.7877 99.1734 38.9881C99.3367 39.1886 99.4703 39.4317 99.5743 39.7175C99.7561 40.2187 99.8471 40.8293 99.8471 41.5494V45.6142H96.7735V44.3168H98.1098V41.7443C98.1098 41.0836 98.0486 40.6177 97.9261 40.3467C97.8704 40.2242 97.7999 40.1092 97.7145 40.0015C97.5438 39.7788 97.2728 39.6674 96.9016 39.6674C96.7308 39.6674 96.5638 39.7083 96.4004 39.7899C96.2408 39.8679 96.1072 39.9644 95.9995 40.0795C95.7805 40.3133 95.6283 40.5212 95.543 40.7031L95.4873 40.8256L95.0641 45.6142H93.4048L93.8224 41.0539C93.8373 40.8831 93.8447 40.7142 93.8447 40.5472Z"
            fill="white"
          />
        </svg>
      </div>
      <label class="head-label">{{ $ml.get("התחברות") }}</label>

      <div class="services" v-if="!$store.getters['sub/getDisabled']">
        <div>
          <button
            style="
              padding-left: 60px;
              padding-right: 5px;
              text-align: left !important;
            "
            class="button btn-google"
            @click="login"
          >
            {{ $ml.get("המשך עם חשבון Google") }}
            <img
              style="left: 17px !important"
              alt=""
              width="24px"
              src="@/assets/img/services/Google.png"
            />
          </button>
        </div>
        <div>
          <button
            style="
              padding-left: 60px;
              padding-right: 5px;
              text-align: left !important;
            "
            class="button btn-facebook"
            @click="logInWithFacebook"
          >
            {{ $ml.get("המשך עם חשבון Facebook") }}
            <img
              style="left: 17px !important"
              alt=""
              width="24px"
              src="@/assets/img/services/Facebook.png"
            />
          </button>
        </div>
      </div>
      <div class="dividers" v-if="!$store.getters['sub/getDisabled']">
        <hr />
        <div style="color: white">{{ $ml.get("או") }}</div>
      </div>
      <div class="email-input">
        <v-text-field
          :label="$ml.get('אימייל')"
          class="test testLtr"
          id="email"
          v-model="userNew.email"
          autocomplete="new-password"
        ></v-text-field>
      </div>

      <div class="password-input">
        <v-text-field
          v-model="userNew.password"
          :append-icon="show ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :type="show ? 'text' : 'password'"
          class="test testLtr"
          id="pass"
          name="input-10-1"
          :label="$ml.get('סיסמה')"
          @click:append="show = !show"
          autocomplete="new-password"
        ></v-text-field>
      </div>
      <canvas
        style="border-radius: 6px; position: relative; margin-top: 10px"
        ref="captchaCanvas"
        v-show="showCaptcha"
      >
      </canvas>
      <div class="captcha-input" v-show="showCaptcha">
        <v-text-field
          :label="$ml.get('קוד captcha')"
          class="test testLtr"
          id="captcha"
          v-model="enteredCaptcha"
          @input="verifyCaptcha"
          autocomplete="new-password"
        ></v-text-field>
      </div>
      <div class="forgot-input" v-show="showCaptcha" style="margin-bottom: 5px">
        <span class="link" @click="reinitCaptcha">{{
          $ml.get("רענן קוד captcha")
        }}</span>
      </div>
      <div class="forgot-input" style="margin-bottom: 20px">
        <span class="link" @click="redirectTo">{{
          $ml.get("שכחתי את הסיסמה")
        }}</span>
      </div>
      <div class="button" style="margin-top: 50px">
        <app-btn
          :class="disabledLogin ? 'disabled' : 'active'"
          @click="loginNew"
          :loading="loadingLogin"
          class="reg-b"
        >
          {{ $ml.get("המשך") }}</app-btn
        >
      </div>
      <div class="alert">
        <span
          >{{ $ml.get("אין לכם חשבון") + " ? " }}
          <router-link :to="{ name: 'auth-reg' }" class="link">{{
            $ml.get("הרשמו")
          }}</router-link></span
        >
        <br />
        <span>
          <router-link :to="{ name: 'auth-restore' }" class="link">{{
            $ml.get("לשחזר חשבון")
          }}</router-link></span
        >
      </div>
      <!-- <div class="services">
        <div>
          <button class="button btn-google" @click="login">
            <img width="24px" src="@/assets/img/google.png" />המשך עם חשבון
            Google
          </button>
        </div>
        <div>
          <button class="button btn-facebook" @click="logInWithFacebook">
            <img width="22px" src="@/assets/img/facebook.png" />המשך עם חשבון
            Facebook
          </button>
        </div>
      </div> -->
    </div>
  </div>
  <div class="" v-else-if="main == false && addMember == false">
    <div class="main-wrapper sm-cont">
      <MainHeader :sidebar="false" />
      <div class="dropdown">
        <button @click="open" class="dropbtn">
          <svg
            height="30"
            id="Layer_1"
            style="enable-background: new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            width="30"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              fill="#fff"
              d="M256,48c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0C140.8,48.3,48,141.3,48,256c0,114.7,92.8,207.7,207.5,208  c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c114.9,0,208-93.1,208-208C464,141.1,370.9,48,256,48z M264.3,172.5  c22.1-0.6,43.5-3.5,64.2-8.5c6.2,24.5,10.1,52.8,10.7,83.8h-74.9V172.5z M264.3,155.8V66c22.4,6.2,45.2,36.1,59.6,82  C304.7,152.6,284.8,155.2,264.3,155.8z M247.7,65.8v90.1c-20.7-0.6-40.8-3.3-60.1-8C202.2,101.7,225.1,71.6,247.7,65.8z   M247.7,172.5v75.2h-75.4c0.6-31,4.5-59.3,10.7-83.8C203.8,168.9,225.5,171.9,247.7,172.5z M155.5,247.7H64.9  c1.8-42.8,17.8-82,43.3-113c18.5,10.2,38.2,18.6,58.8,24.8C160.2,186,156.2,215.9,155.5,247.7z M155.5,264.3  c0.6,31.7,4.6,61.7,11.4,88.2c-20.6,6.3-40.2,14.6-58.8,24.8c-25.5-31-41.4-70.2-43.3-113H155.5z M172.3,264.3h75.4v75.1  c-22.2,0.6-43.9,3.6-64.7,8.7C176.8,323.6,172.9,295.3,172.3,264.3z M247.7,356.1v90.2c-22.6-5.9-45.5-35.9-60.1-82.1  C206.9,359.4,227,356.7,247.7,356.1z M264.3,446v-90c20.5,0.6,40.4,3.3,59.7,7.9C309.5,409.9,286.8,439.8,264.3,446z M264.3,339.4  v-75.1h74.9c-0.6,30.9-4.5,59.2-10.7,83.7C307.8,343,286.4,340,264.3,339.4z M355.9,264.3h91.2c-1.8,42.8-17.8,81.9-43.3,113  c-18.7-10.3-38.5-18.7-59.3-25C351.3,325.8,355.3,296,355.9,264.3z M355.9,247.7c-0.6-31.7-4.6-61.6-11.3-88.1  c20.8-6.3,40.6-14.7,59.2-24.9c25.5,31,41.5,70.2,43.3,113.1H355.9z M392.4,121.9c-16.6,8.8-34,16.1-52.3,21.6  c-9.7-31.3-23.4-56.8-39.5-73.6C336,78.4,367.6,96.8,392.4,121.9z M210.8,70.1c-16.1,16.7-29.7,42.2-39.3,73.3  c-18.1-5.5-35.4-12.7-51.8-21.5C144.2,96.9,175.6,78.6,210.8,70.1z M119.6,390c16.4-8.8,33.8-16,51.8-21.5  c9.7,31.2,23.3,56.6,39.4,73.4C175.6,433.4,144.2,415.1,119.6,390z M300.6,442.1c16.2-16.8,29.8-42.3,39.6-73.7  c18.3,5.5,35.7,12.8,52.3,21.6C367.7,415.2,336,433.6,300.6,442.1z"
            />
          </svg>
        </button>
        <div class="dropdown-content" v-if="opened == true">
          <a
            @click="changeLang('heb')"
            :class="$ml.current == 'heb' ? 'disabled-link' : ''"
            value="heb"
            href="#"
            ><img width="32px" src="../../assets/img/langs/heb.png" /> Hebrew</a
          >
          <a
            @click="changeLang('eng')"
            :class="$ml.current == 'eng' ? 'disabled-link' : ''"
            value="eng"
            href="#"
            ><img width="32px" src="../../assets/img/langs/eng.png" />
            English</a
          >
          <a
            @click="changeLang('ar')"
            :class="$ml.current == 'ar' ? 'disabled-link' : ''"
            value="ar"
            href="#"
            ><img
              width="32px"
              style="border-radius: 50%"
              src="../../assets/img/langs/ar.png"
            />
            Arabic</a
          >
          <a
            @click="changeLang('ru')"
            :class="$ml.current == 'ru' ? 'disabled-link' : ''"
            value="ru"
            href="#"
            ><img width="32px" src="../../assets/img/langs/ru.png" /> Russian</a
          >
        </div>
      </div>
      <div style="width: 100%; padding-top: 8px; padding-bottom: 24px">
        <svg
          style="margin: auto; left: 0; right: 0"
          width="101"
          height="49"
          viewBox="0 0 101 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M59.3157 32.2574C58.6429 32.2574 58.1108 32.0607 57.7196 31.6673C57.3284 31.2739 57.1328 30.7388 57.1328 30.0621V17.8582C57.3049 17.8267 57.5788 17.7873 57.9543 17.7401C58.3299 17.6772 58.6898 17.6457 59.0341 17.6457C59.394 17.6457 59.707 17.6772 59.973 17.7401C60.2546 17.7873 60.4894 17.8818 60.6771 18.0234C60.8649 18.165 61.0058 18.3617 61.0997 18.6135C61.1935 18.8653 61.2405 19.1958 61.2405 19.605V28.9291H66.6157C66.7252 29.1022 66.8269 29.3382 66.9208 29.6372C67.0147 29.9205 67.0616 30.2195 67.0616 30.5342C67.0616 31.1637 66.9286 31.6122 66.6626 31.8797C66.3966 32.1315 66.0445 32.2574 65.6064 32.2574H59.3157Z"
            fill="white"
          />
          <path
            d="M73.2747 32.0922C73.1025 32.1394 72.8287 32.1866 72.4531 32.2338C72.0932 32.281 71.7333 32.3046 71.3734 32.3046C71.0135 32.3046 70.6927 32.2732 70.411 32.2102C70.145 32.163 69.9181 32.0686 69.7303 31.927C69.5426 31.7853 69.4017 31.5886 69.3078 31.3368C69.214 31.085 69.167 30.7546 69.167 30.3454V17.8818C69.3391 17.8503 69.613 17.8109 69.9885 17.7637C70.3641 17.7008 70.724 17.6693 71.0683 17.6693C71.4282 17.6693 71.7411 17.7008 72.0072 17.7637C72.2888 17.8109 72.5236 17.9054 72.7113 18.047C72.8991 18.1886 73.04 18.3853 73.1338 18.6371C73.2277 18.8889 73.2747 19.2194 73.2747 19.6286V32.0922Z"
            fill="white"
          />
          <path
            d="M80.356 26.4977C79.6675 26.2617 79.0416 26.0256 78.4782 25.7896C77.9149 25.5378 77.4298 25.2388 77.0229 24.8926C76.6161 24.5463 76.2953 24.1372 76.0606 23.6651C75.8415 23.1772 75.732 22.5871 75.732 21.8947C75.732 20.557 76.2405 19.4869 77.2577 18.6843C78.2904 17.8818 79.7301 17.4805 81.5766 17.4805C82.2495 17.4805 82.8754 17.5277 83.4544 17.6221C84.0334 17.7165 84.5263 17.866 84.9331 18.0706C85.3556 18.2594 85.6843 18.5112 85.919 18.826C86.1537 19.125 86.2711 19.4791 86.2711 19.8882C86.2711 20.2974 86.1772 20.6515 85.9894 20.9505C85.8016 21.2337 85.5747 21.4776 85.3087 21.6822C84.9644 21.4619 84.5028 21.2731 83.9238 21.1157C83.3448 20.9426 82.7111 20.856 82.0226 20.856C81.3184 20.856 80.802 20.9583 80.4734 21.1629C80.1448 21.3517 79.9805 21.5957 79.9805 21.8947C79.9805 22.1307 80.0822 22.3274 80.2856 22.4848C80.489 22.6264 80.7942 22.7602 81.201 22.8861L82.4451 23.2874C83.916 23.7595 85.0427 24.3654 85.8251 25.105C86.6232 25.8289 87.0222 26.8203 87.0222 28.0793C87.0222 29.4169 86.498 30.5028 85.4495 31.3368C84.4011 32.1551 82.8597 32.5643 80.8255 32.5643C80.1056 32.5643 79.4328 32.5013 78.8068 32.3755C78.1966 32.2653 77.6567 32.1001 77.1872 31.8797C76.7334 31.6437 76.3735 31.3604 76.1075 31.0299C75.8571 30.6837 75.732 30.2903 75.732 29.8497C75.732 29.3933 75.865 29.0078 76.131 28.693C76.397 28.3625 76.6865 28.1108 76.9995 27.9376C77.4376 28.2839 77.9697 28.5829 78.5956 28.8347C79.2372 29.0864 79.9335 29.2123 80.6846 29.2123C81.4514 29.2123 81.9913 29.0943 82.3042 28.8583C82.6172 28.6222 82.7737 28.3468 82.7737 28.0321C82.7737 27.7173 82.6485 27.4813 82.3981 27.3239C82.1477 27.1508 81.7957 26.9856 81.3419 26.8282L80.356 26.4977Z"
            fill="white"
          />
          <path
            d="M88.2917 21.1865C88.1822 21.0134 88.0805 20.7774 87.9866 20.4784C87.8927 20.1793 87.8457 19.8646 87.8457 19.5341C87.8457 18.9204 87.9787 18.4798 88.2448 18.2122C88.5264 17.9447 88.8863 17.8109 89.3245 17.8109H99.5585C99.668 17.984 99.7697 18.2201 99.8636 18.5191C99.9575 18.8181 100.004 19.1328 100.004 19.4633C100.004 20.0771 99.8636 20.5177 99.5819 20.7852C99.3159 21.0527 98.9638 21.1865 98.5257 21.1865H95.9202V32.0922C95.7481 32.1394 95.4743 32.1866 95.0987 32.2338C94.7388 32.281 94.3789 32.3046 94.019 32.3046C93.6591 32.3046 93.3383 32.2732 93.0566 32.2102C92.7906 32.163 92.5637 32.0686 92.3759 31.927C92.1881 31.7853 92.0473 31.5886 91.9534 31.3368C91.8595 31.085 91.8126 30.7546 91.8126 30.3454V21.1865H88.2917Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.0415 9.85003C60.5662 7.92813 56.6491 6.67839 54.1084 6.11837L54.2642 5.41309C56.8571 5.9846 60.8427 7.25552 64.3917 9.21814C67.9228 11.1709 71.1147 13.86 71.9625 17.3079L71.2603 17.4801C70.4885 14.3411 67.5346 11.7818 64.0415 9.85003ZM59.155 9.45168C54.4761 7.78368 49.3861 6.95449 46.1328 6.70359L46.1885 5.98348C49.4907 6.23815 54.646 7.07737 59.398 8.77145C64.1268 10.4573 68.564 13.0275 70.2778 16.8118L69.6191 17.1095C68.0358 13.6135 63.8569 11.1279 59.155 9.45168ZM73.4987 13.2098C73.1695 14.3433 72.9044 15.5101 72.7615 16.2359L72.052 16.0965C72.1983 15.3537 72.4682 14.1659 72.8043 13.0086C73.1359 11.8665 73.5462 10.7022 73.9858 10.0434L74.5874 10.444C74.2173 10.9986 73.8322 12.0612 73.4987 13.2098Z"
            fill="white"
          />
          <path
            d="M5.52631 24.404C6.18002 24.404 6.67808 24.2569 7.02051 23.9626C7.37849 23.6528 7.55748 23.1881 7.55748 22.5686C7.55748 21.98 7.37071 21.5308 6.99716 21.221C6.63917 20.8958 6.10998 20.7331 5.40957 20.7331C5.16054 20.7331 4.95042 20.7409 4.7792 20.7564C4.62356 20.7564 4.46013 20.7719 4.28892 20.8028V24.404H5.52631ZM4.31227 31.8619C4.14106 31.9084 3.86868 31.9549 3.49513 32.0013C3.13714 32.0478 2.77916 32.071 2.42117 32.071C2.06318 32.071 1.74411 32.0401 1.46395 31.9781C1.19935 31.9316 0.973663 31.8387 0.786888 31.6993C0.600113 31.5599 0.460031 31.3663 0.366644 31.1185C0.273256 30.8706 0.226562 30.5454 0.226562 30.1427V19.1997C0.226562 18.8745 0.312168 18.6266 0.483378 18.4563C0.670153 18.2704 0.919187 18.1233 1.23048 18.0148C1.75967 17.829 2.35891 17.6973 3.02819 17.6199C3.71303 17.5269 4.39787 17.4805 5.08271 17.4805C7.23063 17.4805 8.87269 17.9374 10.0089 18.8512C11.1451 19.7651 11.7132 21.0042 11.7132 22.5686C11.7132 23.3275 11.5887 24.0168 11.3397 24.6363C11.1062 25.2404 10.7482 25.767 10.2657 26.2162C9.79878 26.6499 9.19955 26.9907 8.46801 27.2385C7.75204 27.4708 6.91934 27.587 5.9699 27.587H4.31227V31.8619Z"
            fill="white"
          />
          <path
            d="M15.9636 32.0246C15.2944 32.0246 14.7652 31.831 14.376 31.4437C13.9869 31.0565 13.7924 30.5299 13.7924 29.8639V17.8522C13.9636 17.8212 14.236 17.7825 14.6095 17.736C14.9831 17.6741 15.3411 17.6431 15.6835 17.6431C16.0415 17.6431 16.3528 17.6741 16.6174 17.736C16.8975 17.7825 17.131 17.8754 17.3178 18.0148C17.5045 18.1542 17.6446 18.3478 17.738 18.5957C17.8314 18.8435 17.8781 19.1688 17.8781 19.5715V28.7487H23.2245C23.3335 28.919 23.4346 29.1514 23.528 29.4457C23.6214 29.7245 23.6681 30.0187 23.6681 30.3285C23.6681 30.9481 23.5358 31.3895 23.2712 31.6528C23.0066 31.9007 22.6564 32.0246 22.2206 32.0246H15.9636Z"
            fill="white"
          />
          <path
            d="M29.002 18.2936C29.2666 18.1387 29.6635 17.9916 30.1926 17.8522C30.7374 17.7128 31.2822 17.6431 31.8269 17.6431C32.6052 17.6431 33.29 17.7593 33.8815 17.9916C34.4885 18.2084 34.8776 18.5569 35.0488 19.0371C35.329 19.8425 35.6325 20.7796 35.9593 21.8483C36.2862 22.9171 36.613 24.0245 36.9399 25.1707C37.2668 26.3014 37.5858 27.4244 37.8971 28.5396C38.2084 29.6393 38.4808 30.6151 38.7143 31.467C38.5275 31.6838 38.2473 31.8542 37.8738 31.9781C37.5002 32.1175 37.0566 32.1872 36.543 32.1872C36.1695 32.1872 35.8504 32.1562 35.5858 32.0943C35.3367 32.0323 35.1266 31.9394 34.9554 31.8155C34.7998 31.6916 34.6675 31.5289 34.5585 31.3276C34.4651 31.1262 34.3795 30.8939 34.3017 30.6306L33.8815 29.0275H29.6557C29.5312 29.4921 29.4066 29.9723 29.2821 30.4679C29.1576 30.9636 29.0331 31.4437 28.9086 31.9084C28.6907 31.9858 28.4572 32.0478 28.2082 32.0943C27.9747 32.1562 27.6868 32.1872 27.3443 32.1872C26.5505 32.1872 25.9591 32.0555 25.57 31.7922C25.1964 31.5134 25.0096 31.134 25.0096 30.6538C25.0096 30.437 25.0408 30.2201 25.103 30.0033C25.1653 29.7864 25.2353 29.5308 25.3132 29.2366C25.4221 28.8184 25.57 28.2995 25.7567 27.6799C25.9591 27.0604 26.177 26.3943 26.4105 25.6819C26.6439 24.9539 26.893 24.2104 27.1576 23.4514C27.4222 22.6925 27.6712 21.98 27.9047 21.314C28.1381 20.6325 28.3482 20.0284 28.535 19.5018C28.7374 18.9751 28.893 18.5724 29.002 18.2936ZM31.8036 21.2907C31.5857 21.9413 31.3366 22.6693 31.0565 23.4747C30.7919 24.2646 30.5506 25.07 30.3327 25.891H33.2277C33.0098 25.0545 32.7842 24.2414 32.5507 23.4514C32.3328 22.646 32.1304 21.9258 31.9437 21.2907H31.8036Z"
            fill="white"
          />
          <path
            d="M47.0031 31.8619C46.8319 31.9084 46.5673 31.9549 46.2093 32.0013C45.8514 32.0478 45.5012 32.071 45.1587 32.071C44.4428 32.071 43.9058 31.9549 43.5478 31.7225C43.1898 31.4747 43.0108 30.9636 43.0108 30.1891V27.0526C42.6217 26.4795 42.2015 25.8445 41.7501 25.1475C41.2987 24.4505 40.8551 23.738 40.4193 23.01C39.9835 22.282 39.5788 21.5695 39.2053 20.8725C38.8317 20.1601 38.5282 19.5095 38.2947 18.9209C38.4971 18.6421 38.7695 18.3866 39.1119 18.1542C39.4699 17.9219 39.9057 17.8057 40.4193 17.8057C41.0263 17.8057 41.5166 17.9296 41.8902 18.1775C42.2793 18.4253 42.6451 18.89 42.9875 19.5715L44.9253 23.4514H45.0653C45.2832 22.9713 45.47 22.5376 45.6257 22.1504C45.7969 21.7477 45.9603 21.3527 46.116 20.9655C46.2716 20.5628 46.435 20.1523 46.6062 19.7341C46.7774 19.3004 46.972 18.8048 47.1899 18.2472C47.4701 18.1078 47.7814 17.9993 48.1238 17.9219C48.4662 17.8445 48.7931 17.8057 49.1044 17.8057C49.6491 17.8057 50.1083 17.9529 50.4818 18.2472C50.8709 18.526 51.0655 18.9519 51.0655 19.525C51.0655 19.7109 51.0266 19.9355 50.9488 20.1988C50.8709 20.4621 50.6919 20.8648 50.4118 21.4069C50.1316 21.9335 49.7192 22.6538 49.1744 23.5676C48.6452 24.4815 47.9214 25.6741 47.0031 27.1456V31.8619Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.2056 2.63536L77.2454 5.10628C77.1479 5.22914 77.1369 5.39907 77.2177 5.53334C77.2985 5.66762 77.4543 5.73835 77.6093 5.71111L80.6505 5.1767C80.6649 5.70334 80.5198 6.24496 80.2337 6.77481C79.4149 8.29142 77.5765 8.87544 76.0686 8.07114C74.7465 7.36593 74.0429 5.91163 74.3692 4.58669C74.4241 4.36372 74.5083 4.14278 74.6245 3.92763C74.6283 3.92049 74.632 3.91323 74.6353 3.90586C75.1633 2.75582 76.1529 2.28975 77.1469 2.21365C77.6472 2.17534 78.141 2.23774 78.5596 2.36136C78.8177 2.43759 79.0358 2.53362 79.2056 2.63536ZM73.9501 3.58176C74.6198 2.13399 75.8903 1.55343 77.0886 1.46168C77.6859 1.41595 78.2727 1.48984 78.7757 1.6384C79.2721 1.785 79.7182 2.01378 80.0092 2.30332C80.145 2.43849 80.1577 2.65328 80.0387 2.8033L78.4577 4.79614L80.9199 4.36347C81.019 4.34605 81.1211 4.36849 81.2035 4.42584C81.286 4.48319 81.3421 4.57076 81.3596 4.66927C81.509 5.51084 81.3203 6.35724 80.9023 7.13143C79.8941 8.99882 77.6031 9.74555 75.7098 8.73569C74.1197 7.88753 73.214 6.10523 73.6322 4.40736C73.7013 4.1266 73.8066 3.84972 73.9501 3.58176ZM75.5545 3.76601C75.5189 4.02881 75.5984 4.26753 75.6895 4.42699C75.793 4.60807 76.0246 4.67149 76.2068 4.56862C76.389 4.46575 76.4528 4.23556 76.3493 4.05448C76.3173 3.99843 76.2985 3.92552 76.3065 3.8666C76.31 3.84076 76.3186 3.81584 76.3362 3.79031C76.3542 3.76441 76.3887 3.72746 76.4573 3.68795C76.5163 3.654 76.5724 3.65235 76.6298 3.67162C76.6962 3.69393 76.7498 3.74085 76.7741 3.77923C76.8855 3.95561 77.1197 4.00881 77.2971 3.89806C77.4746 3.78732 77.5281 3.55456 77.4167 3.37818C77.1978 3.03161 76.6314 2.71616 76.077 3.03533C75.7595 3.21811 75.5928 3.48258 75.5545 3.76601Z"
            fill="white"
          />
          <path
            d="M2.08804 38.3199C2.52606 38.3199 2.91583 38.3831 3.25734 38.5093C3.59885 38.6355 3.87539 38.8025 4.08698 39.0104C4.30228 39.2183 4.47861 39.467 4.61595 39.7565C4.86466 40.2799 4.98901 40.898 4.98901 41.6107V48.3537H3.28518V41.4381C3.28518 40.5657 3.04575 40.0089 2.56689 39.7677C2.37386 39.6749 2.14186 39.6284 1.87088 39.6284C1.39945 39.6284 0.775821 39.6693 0 39.7509V38.4424C0.660747 38.3608 1.35676 38.3199 2.08804 38.3199Z"
            fill="white"
          />
          <path
            d="M9.38224 38.3199C10.3362 38.3199 11.0768 38.6448 11.6039 39.2944C12.1347 39.944 12.4001 40.8516 12.4001 42.0172C12.4001 43.1828 12.131 44.0922 11.5928 44.7455C11.0582 45.3989 10.3232 45.7255 9.38781 45.7255C8.45237 45.7255 7.71367 45.4007 7.17171 44.7511C6.63346 44.0978 6.36433 43.1883 6.36433 42.0227C6.36433 41.5736 6.45157 41.1337 6.62603 40.7031C6.8005 40.2725 6.99538 39.957 7.21068 39.7565V39.712L6.4757 39.7621V38.4759C7.33689 38.3719 8.30574 38.3199 9.38224 38.3199ZM8.09044 42.0227C8.09044 43.5892 8.52104 44.3725 9.38224 44.3725C10.2434 44.3725 10.674 43.5818 10.674 42.0005C10.674 40.4191 10.2434 39.6284 9.38224 39.6284L8.75305 39.6396C8.31131 40.2521 8.09044 41.0465 8.09044 42.0227Z"
            fill="white"
          />
          <path
            d="M13.9202 40.5472C13.9202 39.8827 13.7847 39.1756 13.5138 38.4257H15.1397C15.1953 38.5705 15.251 38.7394 15.3067 38.9324L15.457 39.5282H15.5628C15.5777 39.4948 15.5999 39.4521 15.6296 39.4002C15.6593 39.3445 15.7336 39.248 15.8524 39.1106C15.9749 38.9696 16.1104 38.8471 16.2588 38.7431C16.4073 38.6355 16.6096 38.539 16.8658 38.4536C17.1256 38.3645 17.4151 38.3199 17.7344 38.3199C18.0573 38.3199 18.3506 38.3812 18.6141 38.5037C18.8777 38.6262 19.0893 38.7877 19.2489 38.9881C19.4122 39.1886 19.5459 39.4317 19.6498 39.7175C19.8317 40.2187 19.9226 40.8293 19.9226 41.5494V45.6142H16.8491V44.3168H18.1854V41.7443C18.1854 41.0836 18.1242 40.6177 18.0017 40.3467C17.946 40.2242 17.8754 40.1092 17.7901 40.0015C17.6193 39.7788 17.3483 39.6674 16.9771 39.6674C16.8064 39.6674 16.6393 39.7083 16.476 39.7899C16.3164 39.8679 16.1827 39.9644 16.0751 40.0795C15.8561 40.3133 15.7039 40.5212 15.6185 40.7031L15.5628 40.8256L15.1397 45.6142H13.4804L13.898 41.0539C13.9128 40.8831 13.9202 40.7142 13.9202 40.5472Z"
            fill="white"
          />
          <path
            d="M22.8737 38.4313H26.6545V39.5338L25.6801 42.947L25.0787 45.6142H23.425L24.0263 42.947L24.9451 39.7287H21.1755V36.9502H22.8737V38.4313Z"
            fill="white"
          />
          <path
            d="M35.9254 38.4313V41.0038C35.9254 41.7722 35.7082 42.3475 35.2739 42.7299C35.118 42.8672 34.9082 42.9786 34.6447 43.064C34.3848 43.1493 34.0842 43.192 33.7427 43.192C33.5905 43.192 33.4401 43.1828 33.2916 43.1642V48.3537H31.5934V42.4014L30.0065 38.4313H31.8662L33.0856 41.9336L33.1023 42.0172C33.2137 42.0283 33.3121 42.0339 33.3974 42.0339C33.6907 42.0339 33.9023 41.9559 34.0322 41.8C34.1621 41.6441 34.2271 41.3843 34.2271 41.0205V38.4313H35.9254Z"
            fill="white"
          />
          <path
            d="M39.088 45.6142H37.3898V38.4313H39.088V45.6142Z"
            fill="white"
          />
          <path
            d="M40.8754 45.6142V38.5594C41.1464 38.5 41.5603 38.4462 42.1171 38.3979C42.6776 38.3459 43.188 38.3199 43.6483 38.3199C44.1086 38.3199 44.4947 38.3459 44.8065 38.3979C45.1183 38.4499 45.38 38.5297 45.5916 38.6373C45.8032 38.745 45.9758 38.8693 46.1094 39.0104C46.2467 39.1477 46.3525 39.3203 46.4268 39.5282C46.501 39.7361 46.5511 39.9477 46.5771 40.163C46.6031 40.3746 46.6161 40.627 46.6161 40.9202V45.6142H44.9123V40.9147C44.9123 40.0572 44.4408 39.6284 43.498 39.6284C43.2418 39.6284 42.9356 39.6414 42.5792 39.6674V45.6142H40.8754Z"
            fill="white"
          />
          <path
            d="M48.487 40.5472C48.487 39.8827 48.3515 39.1756 48.0805 38.4257H49.7064C49.7621 38.5705 49.8178 38.7394 49.8734 38.9324L50.0238 39.5282H50.1296C50.1444 39.4948 50.1667 39.4521 50.1964 39.4002C50.2261 39.3445 50.3003 39.248 50.4191 39.1106C50.5416 38.9696 50.6771 38.8471 50.8256 38.7431C50.9741 38.6355 51.1764 38.539 51.4325 38.4536C51.6923 38.3645 51.9819 38.3199 52.3011 38.3199C52.6241 38.3199 52.9173 38.3812 53.1809 38.5037C53.4444 38.6262 53.656 38.7877 53.8156 38.9881C53.979 39.1886 54.1126 39.4317 54.2165 39.7175C54.3984 40.2187 54.4894 40.8293 54.4894 41.5494V45.6142H51.4158V44.3168H52.7521V41.7443C52.7521 41.0836 52.6909 40.6177 52.5684 40.3467C52.5127 40.2242 52.4422 40.1092 52.3568 40.0015C52.1861 39.7788 51.9151 39.6674 51.5439 39.6674C51.3731 39.6674 51.2061 39.7083 51.0427 39.7899C50.8831 39.8679 50.7495 39.9644 50.6418 40.0795C50.4228 40.3133 50.2706 40.5212 50.1852 40.7031L50.1296 40.8256L49.7064 45.6142H48.0471L48.4647 41.0539C48.4796 40.8831 48.487 40.7142 48.487 40.5472Z"
            fill="white"
          />
          <path
            d="M59.2056 45.6142V38.5594C59.4765 38.5 59.8904 38.4462 60.4473 38.3979C61.0078 38.3459 61.5405 38.3199 62.0453 38.3199C63.0772 38.3199 63.8178 38.487 64.267 38.8211C64.7198 39.1552 64.9463 39.6916 64.9463 40.4303V45.6142H59.2056ZM63.2424 44.3168V40.4303C63.2424 40.0925 63.1107 39.8716 62.8471 39.7677C62.617 39.6749 62.2773 39.6284 61.8281 39.6284C61.572 39.6284 61.2658 39.6414 60.9094 39.6674V44.3168H63.2424Z"
            fill="white"
          />
          <path
            d="M66.6278 43.1865V38.4313H68.3261V41.7499L67.1958 43.1865H66.6278Z"
            fill="white"
          />
          <path
            d="M74.2728 45.4248V45.6142H72.5857V45.4248C72.5857 45.1242 72.634 44.7103 72.7305 44.1832C72.827 43.6523 72.9458 43.192 73.0868 42.8023L74.2004 39.7287H69.9965V38.4313H76.0602V39.4558L74.8519 42.7354C74.4659 43.7822 74.2728 44.6787 74.2728 45.4248ZM71.5779 48.3592H70.0021V41.3156H71.5779V48.3592Z"
            fill="white"
          />
          <path
            d="M77.2907 45.6142V38.5594C77.5617 38.5 77.9756 38.4462 78.5324 38.3979C79.0929 38.3459 79.6034 38.3199 80.0637 38.3199C80.5239 38.3199 80.91 38.3459 81.2218 38.3979C81.5336 38.4499 81.7953 38.5297 82.0069 38.6373C82.2185 38.745 82.3911 38.8693 82.5247 39.0104C82.6621 39.1477 82.7679 39.3203 82.8421 39.5282C82.9164 39.7361 82.9665 39.9477 82.9925 40.163C83.0185 40.3746 83.0314 40.627 83.0314 40.9202V45.6142H81.3276V40.9147C81.3276 40.0572 80.8562 39.6284 79.9133 39.6284C79.6572 39.6284 79.3509 39.6414 78.9946 39.6674V45.6142H77.2907Z"
            fill="white"
          />
          <path
            d="M88.3211 45.6142H85.0694L84.4624 38.4313H86.0883L86.389 42.0283C86.4336 42.032 86.4892 42.0339 86.5561 42.0339C86.8493 42.0339 87.0665 41.9559 87.2075 41.8C87.3523 41.6441 87.4395 41.3843 87.4692 41.0205L87.6808 38.4313H89.2956L89.0895 41.0038C88.9745 42.4626 88.2135 43.192 86.8066 43.192C86.7287 43.192 86.6229 43.1883 86.4892 43.1809L86.5839 44.3168H88.3991C89.019 44.3168 89.496 44.0755 89.8301 43.5929C90.1642 43.1104 90.3665 42.4459 90.437 41.5996L90.7043 38.4313H92.3302L92.0629 41.555C92.0035 42.2306 91.9088 42.7726 91.7789 43.1809C91.2704 44.8031 90.1178 45.6142 88.3211 45.6142Z"
            fill="white"
          />
          <path
            d="M93.8447 40.5472C93.8447 39.8827 93.7092 39.1756 93.4382 38.4257H95.0641C95.1198 38.5705 95.1755 38.7394 95.2311 38.9324L95.3815 39.5282H95.4873C95.5021 39.4948 95.5244 39.4521 95.5541 39.4002C95.5838 39.3445 95.658 39.248 95.7768 39.1106C95.8993 38.9696 96.0348 38.8471 96.1833 38.7431C96.3318 38.6355 96.5341 38.539 96.7902 38.4536C97.0501 38.3645 97.3396 38.3199 97.6588 38.3199C97.9818 38.3199 98.275 38.3812 98.5386 38.5037C98.8021 38.6262 99.0137 38.7877 99.1734 38.9881C99.3367 39.1886 99.4703 39.4317 99.5743 39.7175C99.7561 40.2187 99.8471 40.8293 99.8471 41.5494V45.6142H96.7735V44.3168H98.1098V41.7443C98.1098 41.0836 98.0486 40.6177 97.9261 40.3467C97.8704 40.2242 97.7999 40.1092 97.7145 40.0015C97.5438 39.7788 97.2728 39.6674 96.9016 39.6674C96.7308 39.6674 96.5638 39.7083 96.4004 39.7899C96.2408 39.8679 96.1072 39.9644 95.9995 40.0795C95.7805 40.3133 95.6283 40.5212 95.543 40.7031L95.4873 40.8256L95.0641 45.6142H93.4048L93.8224 41.0539C93.8373 40.8831 93.8447 40.7142 93.8447 40.5472Z"
            fill="white"
          />
        </svg>
      </div>
      <label class="head-label">{{ $ml.get("כניסה") }}</label>
      <div class="accounts">
        <div
          v-for="(user, index) in users"
          :key="index"
          class="account"
          @click="loginOn(user.id_user)"
        >
          <span
            ><div style="max-width: 100px; height: auto; margin: auto">
              {{ user.name + " " + user.surname }}
            </div>
            <img
              :src="user.img != null ? user.img : null"
              width="64"
              height="64"
            /><svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.07107 0.928932L1 8L8.07107 15.0711"
                stroke="black"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="buttons-sign-in">
        <app-btn
          @click="addFamilyUser"
          :class="disabledAdding ? 'disabled' : 'active'"
          class="reg-b"
        >
          {{ $ml.get("הוספת בן משפחה") }}</app-btn
        >
      </div>
    </div>
  </div>
  <div
    class="main-wrapper sm-cont"
    v-else-if="
      main == false &&
      addMember == true &&
      ($ml.current == 'heb' || $ml.current == 'ar')
    "
  >
    <div class="name-input">
      <v-text-field
        :label="$ml.get('שם פרטי')"
        class="test"
        v-model="userAdd.name"
        autocomplete="off"
      ></v-text-field>
    </div>
    <div class="surname-input">
      <v-text-field
        :label="$ml.get('שם משפחה')"
        class="test"
        v-model="userAdd.surname"
        autocomplete="off"
      ></v-text-field>
    </div>
    <div class="coupon-block-first" v-if="promoBlock.first == true">
      <div class="coupon-block">
        <v-text-field
          :append-icon="
            couponError
              ? 'mdi-close'
              : !couponError && userAdd.code.text == ''
              ? ''
              : 'mdi-check'
          "
          :label="$ml.get('קוד קופון')"
          class="test"
          v-model="userAdd.code.text"
          @input="checkCoupon"
          autocomplete="off"
        ></v-text-field>
      </div>
      <div
        style="width: 300px; height: 10px; margin: auto"
        class="promo-alert"
        v-if="couponError"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
        >
          <g id="surface1">
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(84.313725%, 15.686275%, 15.686275%);
                fill-opacity: 1;
              "
              d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
            />
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(90.196078%, 90.196078%, 90.196078%);
                fill-opacity: 1;
              "
              d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
            />
          </g>
        </svg>
        <span style="position: absolute; top: 2px; right: 20px">{{
          $ml.get(couponErrorMsg)
        }}</span>
      </div>
    </div>
    <div
      class="coupon-block-second"
      v-else-if="promoBlock.second == true && promoType.school"
    >
      <svg
        @click="promoBack"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.06066"
          y1="1.93934"
          x2="11.253"
          y2="11.1317"
          stroke="#E41E1E"
          stroke-width="3"
        />
        <line
          x1="1.93934"
          y1="11.1317"
          x2="11.1317"
          y2="1.93933"
          stroke="#E41E1E"
          stroke-width="3"
        />
      </svg>
      <span class="header" v-if="promo.type == 'month'">{{
        $ml.get("מנוי חודשי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'six'">{{
        $ml.get("מנוי חצי שנתי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'year'">{{
        $ml.get("מנוי שנתי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'discount'">{{
        $ml.get("הנחה מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else>{{
        $ml.get("הנחה על משחקים מטעם") + " " + promo.client
      }}</span>
      <div>
        <img
          :src="promo.img != null ? promo.img.url : null"
          class="img-coupon"
        />
      </div>
      <div style="text-align: center">
        <span class="school-info">{{
          $ml.get(promo.school.city + "," + promo.school.name)
        }}</span>
      </div>
      <div style="margin-top: 10px">
        <div style="display: inline-block; margin: 0px 10px">
          <CustomSelect
            v-model="userAdd.promocode.selected.letter"
            :options="letter"
            :default="$ml.get('כיתה')"
            class="select"
          />
        </div>
        <div style="display: inline-block; margin: 0px 10px">
          <CustomSelect
            v-model="userAdd.promocode.selected.number"
            :options="number"
            :default="$ml.get('מספר')"
            class="select"
          />
        </div>
      </div>
    </div>
    <div
      class="coupon-block-second"
      style="height: 140px !important"
      v-else-if="promoBlock.second == true && promoType.company"
    >
      <svg
        @click="promoBack"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.06066"
          y1="1.93934"
          x2="11.253"
          y2="11.1317"
          stroke="#E41E1E"
          stroke-width="3"
        />
        <line
          x1="1.93934"
          y1="11.1317"
          x2="11.1317"
          y2="1.93933"
          stroke="#E41E1E"
          stroke-width="3"
        />
      </svg>
      <span class="header" v-if="promo.type == 'month'">{{
        $ml.get("מנוי חודשי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'six'">{{
        $ml.get("מנוי חצי שנתי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'year'">{{
        $ml.get("מנוי שנתי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'discount'">{{
        $ml.get("הנחה מטעם") + ":"
      }}</span>
      <span class="header" v-else>{{
        $ml.get("הנחה על משחקים מטעם") + ":"
      }}</span>
      <div style="height: 65px !important">
        <img
          :src="promo.img != null ? promo.img.url : null"
          class="img-coupon"
        />
      </div>
      <div style="text-align: center">
        <span class="school-info">{{ $ml.get(promo.client) }}</span>
      </div>
    </div>
    <div class="button">
      <app-btn
        style="margin-top: 20px !important"
        :class="'active'"
        :loading="loadingReg"
        @click="reg"
        class="reg-d"
      >
        {{ $ml.get("המשך") }}</app-btn
      >
    </div>
  </div>
  <div
    class="main-wrapper sm-cont"
    v-else-if="
      main == false &&
      addMember == true &&
      ($ml.current == 'eng' || $ml.current == 'ru')
    "
    style="direction: ltr !important"
  >
    <div class="name-input">
      <v-text-field
        :label="$ml.get('שם פרטי')"
        class="test testLtr"
        v-model="userAdd.name"
        autocomplete="off"
      ></v-text-field>
    </div>
    <div class="surname-input">
      <v-text-field
        :label="$ml.get('שם משפחה')"
        class="test testLtr"
        v-model="userAdd.surname"
        autocomplete="off"
      ></v-text-field>
    </div>
    <div class="coupon-block-first" v-if="promoBlock.first == true">
      <div class="coupon-block">
        <v-text-field
          :append-icon="
            couponError
              ? 'mdi-close'
              : !couponError && userAdd.code.text == ''
              ? ''
              : 'mdi-check'
          "
          :label="$ml.get('קוד קופון')"
          class="test testLtr"
          v-model="userAdd.code.text"
          @input="checkCoupon"
          autocomplete="off"
        ></v-text-field>
      </div>
      <div
        style="width: 300px; height: 10px; margin: auto"
        class="promo-alert"
        v-if="couponError"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
        >
          <g id="surface1">
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(84.313725%, 15.686275%, 15.686275%);
                fill-opacity: 1;
              "
              d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
            />
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(90.196078%, 90.196078%, 90.196078%);
                fill-opacity: 1;
              "
              d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
            />
          </g>
        </svg>
        <span style="position: absolute; top: 2px; right: 20px">{{
          $ml.get(couponErrorMsg)
        }}</span>
      </div>
    </div>
    <div
      class="coupon-block-second"
      v-else-if="promoBlock.second == true && promoType.school"
    >
      <svg
        @click="promoBack"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.06066"
          y1="1.93934"
          x2="11.253"
          y2="11.1317"
          stroke="#E41E1E"
          stroke-width="3"
        />
        <line
          x1="1.93934"
          y1="11.1317"
          x2="11.1317"
          y2="1.93933"
          stroke="#E41E1E"
          stroke-width="3"
        />
      </svg>
      <span class="header" v-if="promo.type == 'month'">{{
        $ml.get("מנוי חודשי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'six'">{{
        $ml.get("מנוי חצי שנתי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'year'">{{
        $ml.get("מנוי שנתי מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else-if="promo.type == 'discount'">{{
        $ml.get("הנחה מטעם") + " " + promo.client
      }}</span>
      <span class="header" v-else>{{
        $ml.get("הנחה על משחקים מטעם") + " " + promo.client
      }}</span>
      <div>
        <img
          :src="promo.img != null ? promo.img.url : null"
          class="img-coupon"
        />
      </div>
      <div style="text-align: center">
        <span class="school-info">{{
          $ml.get(promo.school.city + "," + promo.school.name)
        }}</span>
      </div>
      <div style="margin-top: 10px">
        <div style="display: inline-block; margin: 0px 10px">
          <CustomSelect
            v-model="userAdd.promocode.selected.letter"
            :options="letter"
            :default="$ml.get('כיתה')"
            class="select"
          />
        </div>
        <div style="display: inline-block; margin: 0px 10px">
          <CustomSelect
            v-model="userAdd.promocode.selected.number"
            :options="number"
            :default="$ml.get('מספר')"
            class="select"
          />
        </div>
      </div>
    </div>
    <div
      class="coupon-block-second"
      style="height: 140px !important"
      v-else-if="promoBlock.second == true && promoType.company"
    >
      <svg
        @click="promoBack"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.06066"
          y1="1.93934"
          x2="11.253"
          y2="11.1317"
          stroke="#E41E1E"
          stroke-width="3"
        />
        <line
          x1="1.93934"
          y1="11.1317"
          x2="11.1317"
          y2="1.93933"
          stroke="#E41E1E"
          stroke-width="3"
        />
      </svg>
      <span class="header" v-if="promo.type == 'month'">{{
        $ml.get("מנוי חודשי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'six'">{{
        $ml.get("מנוי חצי שנתי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'year'">{{
        $ml.get("מנוי שנתי מטעם") + ":"
      }}</span>
      <span class="header" v-else-if="promo.type == 'discount'">{{
        $ml.get("הנחה מטעם") + ":"
      }}</span>
      <span class="header" v-else>{{
        $ml.get("הנחה על משחקים מטעם") + ":"
      }}</span>
      <div style="height: 65px !important">
        <img
          :src="promo.img != null ? promo.img.url : null"
          class="img-coupon"
        />
      </div>
      <div style="text-align: center">
        <span class="school-info">{{ $ml.get(promo.client) }}</span>
      </div>
    </div>
    <div class="button">
      <app-btn
        style="margin-top: 20px !important"
        :class="'active'"
        :loading="loadingReg"
        @click="reg"
        class="reg-d"
      >
        {{ $ml.get("המשך") }}</app-btn
      >
    </div>
  </div>
</template>
<script>
import store from "../../store";
import { UserAPI } from "@/api";

const regxp = /(^05|^9525|[+]9525)[0-9]{8}$/;

import MainHeader from "../../components/main/MainHeader";
import ValidateReg from "@/components/ValidateReg";
import { AuthAPI } from "@/api";
import icons from "@/store/helpers/icons.store";
import { mapGetters } from "vuex";
import CustomSelect from "@/components/ui/CustomSelect.vue";
import CryptoJS from "crypto-js";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      enteredCaptcha: "",
      et_fc: 0,
      showCaptcha: false,
      captchaVerified: true,
      captchaWidth: 0,
      captchaHeight: 0,
      captchaText: "",
      userAgent: null,
      loadingLogin: false,
      loadingReg: false,
      couponErrorMsg: "",
      number: [1, 2, 3, 4, 5, 6, 7],
      letter: [
        "’יב",
        "יא’",
        "י’",
        "ט’",
        "ח’",
        "ז’",
        "ו’",
        "ה’",
        "ד’",
        "ג’",
        "ב’",
        "’א",
      ],
      main: true,
      addMember: false,
      users: [],
      show: false,
      validationPhoneInputText: "",
      validationPhoneInputTextDefault: "הזן מספר טלפון",
      validPhoneNum: false,
      userIndicator: null,
      userAdd: {
        name: "",
        surname: "",
        code: {
          text: "",
          status: false,
          checked: false,
        },
        question: {
          options: ["אף פעם ", "יש כזו גלידה? ", " מה זה קשור להרשמה??"],
          selected: "",
        },
        promocode: {
          selected: {
            letter: "",
            number: "",
          },
          title: {
            position: "",
            city: "",
            school: "",
          },
        },
      },
      coupon_timeout: null,
      couponError: false,
      promoBlock: {
        first: true,
        second: false,
      },
      promoType: {
        school: true,
        company: false,
      },
      promo: {},
      bindProps: {
        autoFormat: true,
        // autoDefaultCountry: true,
        defaultCountry: "IL",
        validCharactersOnly: true,
        mode: "international",
        disabled: false,
        preferredCountries: ["IL", "US", "UA"],
        dropdownOptions: {
          showDialCodeInList: true,
          showFlags: true,
          width: "250px",
        },
        inputOptions: {
          showDialCode: true,
          autocomplete: "off",
          placeholder: "הזן מספר טלפון",
          required: true,
          type: "tel",
          name: "phone",
          maxLen: 25,
        },
      },
      newForm: true,
      oldForm: false,
      timer: null,
      isValidPhone: false,
      mainuser_id: null,
      user: {
        name: "",
        phone: "",
        surname: "",
      },
      userNew: {
        phone: "",
        password: "",
        email: "",
      },
      error: "",
      loading: false,
      loadingSMS: false,
      entered: false,
      opened: false,
    };
  },
  created() {
    this.getVisitorData();
    this.userAgent = window.navigator.userAgent;
    this.validationPhoneInputText = this.validationPhoneInputTextDefault;
    this.parseLink();
    this.userAgent = window.navigator.userAgent;
    this.userAgent = window.navigator.userAgent;
    let expo = this.userAgent.includes('ExpoLogin');
    let ios = this.userAgent.includes('ExpoIOS');
    if(expo && !ios) {
      document.addEventListener("message", this.handleEvent);
    }else if(expo && ios){
      window.addEventListener("message", this.handleEventIOS);
    }
  },
  computed: {
    ...mapGetters({
      titles: "pagestitle/dataSingInPage",
    }),
    disabled() {
      return this.user.name && this.user.phone;
    },
    disabledNew() {
      return this.userNew.phone && this.userNew.password && this.isValidPhone;
    },
    disabledLogin() {
      if (
        this.userNew.email != "" &&
        this.userNew.email != null &&
        this.userNew.password != "" &&
        this.userNew.password != null
      ) {
        if (this.captchaVerified == true) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    disabledAdding() {
      if (this.users.length < 3) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
     handleEventIOS(event) {
      if (this.entered == false) {
        this.expoLogin(event.data);
        this.entered = true;
        
        window.removeEventListener("message", this.handleEvent);
        setTimeout(() => {}, 1000);
      }
    },
    handleEvent(event) {
      if (this.entered == false) {
        this.expoLogin(event.data);
        this.entered = true;
        
        document.removeEventListener("message", this.handleEvent);
        setTimeout(() => {}, 1000);
      }
    },
    captchaSync() {
      const et_fc = localStorage.getItem("et_fc");
      if (et_fc !== null) {
        this.et_fc = Buffer.from(et_fc, "base64");
        if (this.et_fc >= 3) {
          this.showCaptcha = true;
          this.captchaVerified = false;
          this.initCaptcha();
          this.drawCaptcha();
        }
      } else {
        localStorage.setItem("et_fc", Buffer.from("0").toString("base64"));
      }
    },
    verifyCaptcha() {
      if (this.enteredCaptcha == this.captchaText) {
        this.captchaVerified = true;
      } else {
        this.captchaVerified = false;
      }
    },
    initCaptcha() {
      const canvas = this.$refs.captchaCanvas;
      this.captchaWidth = Math.min(canvas.width, 300);
      this.captchaHeight = Math.min(canvas.height, 150);
      this.captchaText = this.generateCaptchaText();
    },
    reinitCaptcha() {
      this.captchaText = this.generateCaptchaText();
      this.drawCaptcha();
    },
    drawCaptcha() {
      const canvas = this.$refs.captchaCanvas;
      const ctx = canvas.getContext("2d");

      // Очистите холст
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Задайте фон
      ctx.fillStyle = "#F5F5F5";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Нарисуйте текст капчи
      ctx.font = "28px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = this.generateRandomColor();
      const textX =
        Math.random() *
          (this.captchaWidth - ctx.measureText(this.captchaText).width) +
        ctx.measureText(this.captchaText).width / 2;
      const textY = Math.random() * (this.captchaHeight - 48) + 48 / 2;
      ctx.fillText(this.captchaText, textX, textY);

      // Нарисуйте линии
      ctx.strokeStyle = this.generateRandomColor();
      for (let i = 0; i < 10; i++) {
        ctx.beginPath();
        ctx.moveTo(
          Math.random() * this.captchaWidth,
          Math.random() * this.captchaHeight
        );
        ctx.lineTo(
          Math.random() * this.captchaWidth,
          Math.random() * this.captchaHeight
        );
        ctx.stroke();
      }
    },
    generateCaptchaText() {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let captchaText = "";
      for (let i = 0; i < 6; i++) {
        captchaText += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return captchaText;
    },
    generateRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    encryptObject(data) {
      const key = CryptoJS.enc.Utf8.parse(process.env["VUE_APP_SECURE_KEY"]);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ivBase64 = CryptoJS.enc.Base64.stringify(iv);

      const objectToEncryptString = JSON.stringify(data);

      const encrypted = CryptoJS.AES.encrypt(objectToEncryptString, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        keySize: 256 / 8,
      });

      const encryptedObject = {
        iv: ivBase64,
        value: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      };
      return encryptedObject;
    },
    pushBack() {
      this.$router.push("/auth/reg");
    },
    open() {
      this.opened = !this.opened;
    },
    changeLang(lang) {
      this.$ml.change(lang);
      this.$store.commit("lang-store/changeLang", lang);
      this.opened = false;
    },
    async getVisitorData() {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true,
      });
      this.userIndicator = visitorData;
    },
    async getVisitorData() {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true,
      });
      this.userIndicator = visitorData;
      // Use visitorData
    },
    expoLogin(dataExpo) {
      let data = JSON.parse(dataExpo);
      let type = data.type;

      if (type == "google") {
        this.loadingLogin = true;
        let parsedData = JSON.parse(data.data);
        AuthAPI.signInwithGoogle(this.encryptObject({
          auth: parsedData.id,
          indicators: this.userIndicator,
        }))
          .then((res) => {
            if ((res.data.success == true)) {
              if (res.data.multiple == true) {
                this.loadingLogin = false;
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.$store.commit('user/setDefaultUser',false);
                this.main = false;
                this.users = res.data.users;
                this.mainuser_id = res.data.id_user;
              } else {
                this.$store.commit("playlist/clearStorage");
                this.$store.commit("shootlist/clearStorage");
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.$store.commit("playlist/reset");
                this.$store.dispatch("modals/inventedGames/updateData");
                this.$store.dispatch("user/updateData").then(() => {
                  this.$store.dispatch(
                    "user/updateSchool",
                    this.$store.getters["user/data"].id_school
                  );
                  this.loadingLogin = false;
                  this.$store.dispatch("playlist/loadState");
                  this.$store.dispatch("shootlist/getState");
                  this.$store.commit('user/setDefaultUser',false);
                  this.$router.replace({
                    name: "main",
                    params: {
                      attent: true,
                    },
                  });
                });
              }
            } else {
              alert("error");
            }
          })
          .catch((error) => {
            this.loadingLogin = false;
            this.$store.commit("loadingWindow/changeLoadTrue");
            let er = error.response.data.message;
            if (er === "Max num of users devises.") {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get(
                  "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
                ),
                type: "error",
                duration: 5000,
              });
            } else if (er === "Wrong phone") {
              this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("אימייל או סיסמה שגויים"),
                type: "error",
                duration: 5000,
              });
            } else {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("אימייל או סיסמה שגויים"),
                type: "error",
                duration: 5000,
              });
            }
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      } else if (type == "facebook") {
        this.loadingLogin = true;
        let parsedData = JSON.parse(data.data);
        AuthAPI.signInwithFacebook(this.encryptObject({
          auth: parsedData.id,
          indicators: this.userIndicator,
        }))
          .then((res) => {
            if ((res.data.success == true)) {
              if (res.data.multiple == true) {
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.$store.commit('user/setDefaultUser',false);
                this.main = false;
                this.users = res.data.users;
                this.mainuser_id = res.data.id_user;
                this.loadingLogin = false;
              } else {
                this.$store.commit("playlist/clearStorage");
                this.$store.commit("shootlist/clearStorage");
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.$store.commit("playlist/reset");
                this.$store.dispatch("modals/inventedGames/updateData");
                this.$store.dispatch("user/updateData").then(() => {
                  this.$store.dispatch(
                    "user/updateSchool",
                    this.$store.getters["user/data"].id_school
                  );
                  this.$store.dispatch("playlist/loadState");
                  this.$store.dispatch("shootlist/getState");
                  this.loadingLogin = false;
                  this.$store.commit('user/setDefaultUser',false);
                  this.$router.replace({
                    name: "main",
                    params: {
                      attent: true,
                    },
                  });
                });
              }
            } else {
              alert("error");
              this.loadingLogin = false;
            }
          })
          .catch((error) => {
            this.$store.commit("loadingWindow/changeLoadTrue");
            this.loadingLogin = false;
            let er = error.response.data.message;
            if (er === "Max num of users devises.") {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get(
                  "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה."
                ),
                type: "error",
                duration: 5000,
              });
            } else if (er === "Wrong phone") {
              this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("אימייל או סיסמה שגויים"),
                type: "error",
                duration: 5000,
              });
            } else {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("אימייל או סיסמה שגויים"),
                type: "error",
                duration: 5000,
              });
            }
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
    parseLink() {
      const link = window.location.href.replace("/#/", "/");
      if (link != null && link != "") {
        const url = new URL(link);

        const param = url.searchParams.get("d");
        url.searchParams.delete("d");
        if (param != null && param != "") {
          this.loadingLogin = true;
          setTimeout(() => {
            AuthAPI.res(param)
              .then((res) => {
                if (res.data.success == true) {
                  this.loadingLogin = false;
                  this.$notify({
                    title: this.$ml.get("שְׁגִיאָה"),
                    text: this.$ml.get(
                      "חשבונך שוחזר בהצלחה, אנא השתמש בנתונים הקודמים כדי להתחבר"
                    ),
                    type: "success",
                    duration: 5000,
                  });
                } else {
                  this.loadingLogin = false;
                  this.$notify({
                    title: this.$ml.get("שְׁגִיאָה"),
                    text: this.$ml.get(
                      "המשתמש לא נמצא או שחלפו יותר מ-30 יום מאז המחיקה"
                    ),
                    type: "error",
                    duration: 5000,
                  });
                }
              })
              .catch((err) => {
                this.loadingLogin = false;
                this.$notify({
                  title: this.$ml.get("שְׁגִיאָה"),
                  text: this.$ml.get(
                    "המשתמש לא נמצא או שחלפו יותר מ-30 יום מאז המחיקה"
                  ),
                  type: "error",
                  duration: 5000,
                });
              });
            this.loadingLogin = false;
          }, 1000);
        }
      }
    },
    addFamilyUser() {
      (this.main = false), (this.addMember = true);
    },
    checkCoupon() {
      this.couponError = false;
      this.couponErrorMsg = "";
      clearTimeout(this.coupon_timeout);
      this.coupon_timeout = setTimeout(() => {
        if (this.userAdd.code.text.trim() !== "") {
          AuthAPI.checkCoupon({ coupon: this.userAdd.code.text })
            .then((res) => {
              if (res.data.limit === res.data.used) {
                this.$notify({
                  title: this.$ml.get("אַזהָרָה"),
                  text: this.$ml.get("חרגה ממגבלות הקופון"),
                  type: "error",
                  duration: 5000,
                });
                this.couponError = true;
                this.couponErrorMsg = $ml.get("קופון זה עבר את מכסת השימוש");
                this.userAdd.promocode.title = res.data;
                this.userAdd.code.checked = false;
                this.userAdd.code.status = false;
              } else {
                if (res.data.school != null) {
                  this.promoBlock.second = true;
                  this.promoBlock.first = false;
                  this.promoType.school = true;
                  this.promoType.company = false;
                  this.promo = res.data;
                } else {
                  this.promoBlock.second = true;
                  this.promoBlock.first = false;
                  this.promoType.school = false;
                  this.promoType.company = true;
                  this.promo = res.data;
                }
                this.userAdd.code.checked = true;
                this.userAdd.code.status = true;
                this.userAdd.promocode.title = res.data;
              }
            })
            .catch((err) => {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("קופון לא נמצא"),
                type: "error",
                duration: 5000,
              });
              this.couponErrorMsg = $ml.get("קוד הקופון שגוי");
              this.couponError = true;
            });
        }
      }, 1000);
    },
    promoBack() {
      this.promoBlock.first = true;
      this.promoBlock.second = false;

      this.userAdd.promocode = {
        selected: {
          letter: "",
          number: "",
        },
        title: {
          position: "",
          city: "",
          school: "",
        },
      };

      this.userAdd.code = {
        text: "",
        status: false,
        checked: false,
      };
    },
    loginOn(val) {
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      AuthAPI.auth({ auth: val, indicators: this.userIndicator })
        .then((res) => {
          if ((res.data.success == true)) {
            this.$store.commit("playlist/clearStorage");
            this.$store.commit("shootlist/clearStorage");
            this.$store.commit("user/auth", res.data.auth);
            this.$store.commit("user/expires_at", res.data.expires_at);
            this.$store.commit("playlist/reset");
            this.$store.dispatch("modals/inventedGames/updateData");
            this.$store.dispatch("user/updateData").then(() => {
              this.$store.dispatch(
                "user/updateSchool",
                this.$store.getters["user/data"].id_school
              );
              this.$store.dispatch("playlist/loadState");
              this.$store.dispatch("shootlist/getState");

              this.$router.replace({
                name: "main",
                params: {
                  attent: true,
                },
              });
            });
          } else {
            alert("error");
          }
        })
        .catch((error) => {
          this.$store.commit("loadingWindow/changeLoadTrue");
          this.loading = false;
          let er = error.response.data.message;
          if (er === "Max num of users devises.") {
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get(
                "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
              ),
              type: "error",
              duration: 5000,
            });
          } else if (er === "Wrong phone") {
            this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get("אימייל או סיסמה שגויים"),
              type: "error",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get("אימייל או סיסמה שגויים"),
              type: "error",
              duration: 5000,
            });
          }
        })
        .finally(() => {});
    },
    async login() {
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      if(this.userAgent.includes('ExpoLogin')) {
        this.entered = false;
        window.ReactNativeWebView.postMessage("signIn_google");
      } else {
        this.loadingLogin = true;
        const googleUser = await this.$gAuth.signIn();
        this.user.email = googleUser.getBasicProfile().getEmail();
        let google_id = googleUser.getBasicProfile().getId();
        let auth_token = googleUser.getAuthResponse().access_token;

        AuthAPI.signInwithGoogle(
          this.encryptObject({
            auth: google_id,
            token: auth_token,
            indicators: this.userIndicator,
          })
        )
          .then((res) => {
            if ((res.data.success == true)) {
              if (res.data.multiple == true) {
                this.loadingLogin = false;
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.main = false;
                this.users = res.data.users;
                this.mainuser_id = res.data.id_user;
              } else {
                this.$store.commit("playlist/clearStorage");
                this.$store.commit("shootlist/clearStorage");
                this.$store.commit("user/auth", res.data.auth);
                this.$store.commit("user/expires_at", res.data.expires_at);
                this.$store.commit("playlist/reset");
                this.$store.dispatch("modals/inventedGames/updateData");
                this.$store.dispatch("user/updateData").then(() => {
                  this.$store.dispatch(
                    "user/updateSchool",
                    this.$store.getters["user/data"].id_school
                  );
                  this.loadingLogin = false;
                  this.$store.dispatch("playlist/loadState");
                  this.$store.dispatch("shootlist/getState");
                  if (this.$store.getters["user/data"].id_user == 1) {
                    this.$store.commit("user/setDefaultUser", true);
                  } else {
                    this.$store.commit("user/setDefaultUser", false);
                  }

                  this.$router.replace({
                    name: "main",
                    params: {
                      attent: true,
                    },
                  });
                });
              }
            } else {
              alert("error");
            }
          })
          .catch((error) => {
            this.loadingLogin = false;
            this.$store.commit("loadingWindow/changeLoadTrue");
            let er = error.response.data.message;
            if (er === "Max num of users devises.") {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get(
                  "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
                ),
                type: "error",
                duration: 5000,
              });
            } else if (er === "Wrong phone") {
              this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("החשבון לא נמצא"),
                type: "error",
                duration: 5000,
              });
            } else {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get("החשבון לא נמצא"),
                type: "error",
                duration: 5000,
              });
            }
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
    async logInWithFacebook() {
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      if(this.userAgent.includes('ExpoLogin')) {
        this.entered = false;
        window.ReactNativeWebView.postMessage("signIn_facebook");
      } else {
        // window.ReactNativeWebView.postMessage('signIn_facebook');
        let id = "";
        this.loadingLogin = true;
        const login = new Promise((resolve, error) => {
          FB.login(
            function (response) {
              resolve(response);
            },
            { scope: "public_profile,email" }
          );
        });

        login.then((value) => {
          if (value.status === "connected") {
            const user = new Promise((resolve, error) => {
              FB.api("/me", function (response) {
                resolve(response);
                FB.logout(function (response) {
                  // Person is now logged out
                });
              });
            });

            user.then((val) => {
              let facebook_id = val.id;
              AuthAPI.signInwithFacebook(
                this.encryptObject({
                  auth: facebook_id,
                  token: value.authResponse.accessToken,
                  indicators: this.userIndicator,
                })
              )
                .then((res) => {
                  if ((res.data.success == true)) {
                    if (res.data.multiple == true) {
                      this.$store.commit("user/auth", res.data.auth);
                      this.$store.commit("user/expires_at", res.data.expires_at);
                      this.main = false;
                      this.users = res.data.users;
                      this.mainuser_id = res.data.id_user;
                      this.loadingLogin = false;
                    } else {
                      this.$store.commit("playlist/clearStorage");
                      this.$store.commit("shootlist/clearStorage");
                      this.$store.commit("user/auth", res.data.auth);
                      this.$store.commit("user/expires_at", res.data.expires_at);
                      this.$store.commit("playlist/reset");
                      this.$store.dispatch("modals/inventedGames/updateData");
                      this.$store.dispatch("user/updateData").then(() => {
                        this.$store.dispatch(
                          "user/updateSchool",
                          this.$store.getters["user/data"].id_school
                        );
                        this.$store.dispatch("playlist/loadState");
                        this.$store.dispatch("shootlist/getState");
                        this.loadingLogin = false;
                        if (this.$store.getters["user/data"].id_user == 1) {
                          this.$store.commit("user/setDefaultUser", true);
                        } else {
                          this.$store.commit("user/setDefaultUser", false);
                        }

                        this.$router.replace({
                          name: "main",
                          params: {
                            attent: true,
                          },
                        });
                      });
                    }
                  } else {
                    alert("error");
                    this.loadingLogin = false;
                  }
                })
                .catch((error) => {
                  this.$store.commit("loadingWindow/changeLoadTrue");
                  this.loadingLogin = false;
                  let er = error.response.data.message;
                  if (er === "Max num of users devises.") {
                    this.$notify({
                      title: this.$ml.get("אַזהָרָה"),
                      text: this.$ml.get(
                        "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
                      ),
                      type: "error",
                      duration: 5000,
                    });
                  } else if (er === "Wrong phone") {
                    this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
                    this.$notify({
                      title: this.$ml.get("אַזהָרָה"),
                      text: this.$ml.get("החשבון לא נמצא"),
                      type: "error",
                      duration: 5000,
                    });
                  } else {
                    this.$notify({
                      title: this.$ml.get("אַזהָרָה"),
                      text: this.$ml.get("החשבון לא נמצא"),
                      type: "error",
                      duration: 5000,
                    });
                  }
                })
                .finally(() => {
                  this.loadingLogin = false;
                });

              // if (val.email) {
              //   this.user.email = val.email;
              // } else {
              //   this.$notify({
              //     title: "אַזהָרָה",
              //     text: "נכשל קבלת הדואר האלקטרוני שלך בעת הכניסה לפייסבוק. אנא מלא את הטופס למעלה.",
              //     type: "error",
              //     duration: 10000,
              //   });
              // }
              // this.services.facebook = true;
            });
          }
        });
        this.loadingLogin = false;
        return false;
      }
    },
    redirectTo() {
      this.$router.push({ name: "auth-forgot" });
    },
    clearValidation() {
      this.validationPhoneInputText = this.validationPhoneInputTextDefault;
    },
    validPhone(event) {
      this.isValidPhone = false;
      this.validationPhoneInputText = this.validationPhoneInputTextDefault;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.validPhoneNum = event.valid;

        if (event.valid) {
          this.isValidPhone = true;
          this.validationPhoneInputText = "";
        } else if (!event.valid) {
          this.validationPhoneInputText = $ml.get("המספר לא נכון");
        }
      }, 1000);
    },
    setAuthFormOld() {
      this.newForm = false;
      this.oldForm = true;
    },
    setAuthFormNew() {
      this.oldForm = false;
      this.newForm = true;
    },
    loginNew() {
      let et_fc = localStorage.getItem("et_fc");
      if (et_fc != null) {
        this.et_fc = parseInt(Buffer.from(et_fc, "base64"));
      } else {
        this.et_fc = 0;
      }
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      this.$store.commit("loadingWindow/changeLoadFalse");
      this.loadingLogin = true;
      AuthAPI.login(
        this.encryptObject({
          email: this.userNew.email,
          pass: this.userNew.password,
          indicators: this.userIndicator,
        })
      )
        .then((res) => {
          if ((res.data.success == true)) {
            localStorage.setItem("et_fc", Buffer.from("0").toString("base64"));
            this.et_fc = 0;
            this.showCaptcha = false;
            this.captchaVerified = true;
            if (res.data.multiple == true) {
              this.$store.commit("user/auth", res.data.auth);
              this.$store.commit("user/expires_at", res.data.expires_at);
              this.main = false;
              this.users = res.data.users;
              this.mainuser_id = res.data.id_user;
              this.loadingLogin = false;
            } else {
              this.$store.commit("playlist/clearStorage");
              this.$store.commit("shootlist/clearStorage");
              this.$store.commit("user/auth", res.data.auth);
              this.$store.commit("user/expires_at", res.data.expires_at);
              this.$store.commit("playlist/reset");
              this.$store.dispatch("modals/inventedGames/updateData");
              this.$store.dispatch("user/updateData").then(() => {
                this.$store.dispatch(
                  "user/updateSchool",
                  this.$store.getters["user/data"].id_school
                );
                this.$store.dispatch("playlist/loadState");
                this.$store.dispatch("shootlist/getState");
                this.loadingLogin = false;
                if (this.$store.getters["user/data"].id_user == 1) {
                  this.$store.commit("user/setDefaultUser", true);
                } else {
                  this.$store.commit("user/setDefaultUser", false);
                }

                this.$router.replace({
                  name: "main",
                  params: {
                    attent: true,
                  },
                });
              });
            }
          } else {
            alert("error");
            this.loadingLogin = false;
            this.et_fc++;
            localStorage.setItem(
              "et_fc",
              Buffer.from(this.et_fc.toString()).toString("base64")
            );
            if (this.et_fc >= 3) {
              this.showCaptcha = true;
              this.captchaVerified = false;
              this.initCaptcha();
              this.drawCaptcha();
            }
          }
        })
        .catch((error) => {
          this.et_fc++;
          localStorage.setItem(
            "et_fc",
            Buffer.from(this.et_fc.toString()).toString("base64")
          );

          if (this.et_fc >= 3) {
            this.showCaptcha = true;
            this.captchaVerified = false;
            this.initCaptcha();
            this.drawCaptcha();
          }
          this.$store.commit("loadingWindow/changeLoadTrue");
          this.loadingLogin = false;
          let er = error.response.data.message;
          if (er === "Max num of users devises.") {
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get(
                "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
              ),
              type: "error",
              duration: 5000,
            });
          } else if (er === "Wrong phone") {
            this.error = this.$ml.get("הטלפון או הסיסמה שגויים");
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get("אימייל או סיסמה שגויים"),
              type: "error",
              duration: 5000,
            });
          } else {
            this.$notify({
              title: this.$ml.get("אַזהָרָה"),
              text: this.$ml.get("אימייל או סיסמה שגויים"),
              type: "error",
              duration: 5000,
            });
          }
        })
        .finally(() => {
          this.loadingLogin = false;
        });
    },
    reg() {
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      this.loadingReg = true;
      let rand = (0 - 0.5 + Math.random() * (30 - 0 + 1)).toFixed();
      let newImage64 = icons[rand];
      this.error = "";
      if (this.userAdd.name.trim() === "") {
        this.loadingReg = false;
        this.$notify({
          title: this.$ml.get("שְׁגִיאָה"),
          text: this.$ml.get("נא לרשום שם"),
          type: "error",
          duration: 5000,
        });
      } else if (
        this.userAdd.code.text !== "" &&
        (this.userAdd.promocode.title.position === "student" ||
          this.userAdd.promocode.title.position === "teacher") &&
        (this.userAdd.promocode.selected.letter === "" ||
          this.userAdd.promocode.selected.number === "")
      ) {
        this.loadingReg = false;
        this.$notify({
          title: this.$ml.get("שְׁגִיאָה"),
          text: this.$ml.get("נא לבחור כיתה ומספר כיתה"),
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        this.$store.commit("loadingWindow/changeLoadFalse");
        AuthAPI.addMember(
          this.encryptObject({
            main_user: this.mainuser_id,
            name: this.userAdd.name,
            surname: this.userAdd.surname,
            coupon: this.userAdd.code.text,
            class: this.userAdd.promocode.selected.letter,
            class_number: this.userAdd.promocode.selected.number,
            image64: newImage64,
            track_data: this.$store.getters["track/data"],
          })
        )
          .then((res) => {
            if (res.data.error) {
              this.loadingReg = false;
              this.$notify({
                title: this.$ml.get("שְׁגִיאָה"),
                text: this.$ml.get("אופס! משהו השתבש!"),
                type: "error",
                duration: 10000,
              });
            } else {
              this.loadingReg = false;
              let users = this.users;
              users.push(res.data.user);
              this.users = users;
              this.addMember = false;
            }
          })
          .catch((error) => {
            this.loadingReg = false;
            this.$store.commit("loadingWindow/changeLoadTrue");
            if (error.response.data.message === "Max num of users devises.") {
              this.$notify({
                title: this.$ml.get("אַזהָרָה"),
                text: this.$ml.get(
                  "נעשה שימוש במספר המרבי של התקני כניסה צור קשר עם תמיכה"
                ),
                type: "error",
                duration: 5000,
              });
            } else if (error.response.data.message === "phone-busy") {
              this.$notify({
                title: this.$ml.get("שְׁגִיאָה"),
                text: this.$ml.get("אופס! משהו השתבש!"),
                type: "error",
                duration: 50000,
              });
            } else {
              this.$notify({
                title: this.$ml.get("שְׁגִיאָה"),
                text: this.$ml.get("אופס! משהו השתבש!"),
                type: "error",
                duration: 50000,
              });
            }
            this.loadingReg = false;
          });
      }
    },
  },
  components: {
    MainHeader,
    ValidateReg,
    CustomSelect,
  },
  mounted() {
    this.captchaSync();
    // this.$store.dispatch('pagestitle/updateDataSignInPage');
    $("#pass").focus(function () {
      $(".email-input").removeClass("focused");
      $(".password-input").addClass("focused");
      $(".captcha-input").removeClass("focused");
    });
    $("#email").focus(function () {
      $(".email-input").addClass("focused");
      $(".password-input").removeClass("focused");
      $(".captcha-input").removeClass("focused");
    });
    $("#captcha").focus(function () {
      $(".captcha-input").addClass("focused");
      $(".password-input").removeClass("focused");
      $(".email-input").removeClass("focused");
    });
    $("#pass").focusout(function () {
      $(".password-input").removeClass("focused");
    });
    $("#email").focusout(function () {
      $(".email-input").removeClass("focused");
    });
    $("#captcha").focusout(function () {
      $(".captcha-input").removeClass("focused");
    });
  },
};
</script>

<style lang="scss" scoped>
.focused {
  border: 2px solid #fdc671;
}
.disabled-link {
  pointer-events: none;
  background: #cecdcd;
  border-radius: 6px;
}
.dropbtn {
  background-color: transparent;
  color: white;
  // padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: absolute;
  // position: relative;
  right: 40px;
  top: 30px;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  border-radius: 6px;
  display: block;
  right: 0px !important;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  display: flex;
  justify-content: space-between;
  color: black;
  padding: 3px 10px;
  text-decoration: none;
  // display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 6px;
}

/* Show the dropdown menu on hover */
// .dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: transparent;
}
.buttons-sign-in {
  width: 300px;
  margin: auto;
  text-align: center;
  margin-top: 75px;

  .reg-b {
    background: #a9ddf3;
    border-radius: 24px;
    width: 100%;
    height: 48px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #000000;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
}
.accounts {
  width: 300px;
  margin: auto;
  margin-top: 27px;
  max-height: 340px !important;
  min-height: 340px !important;

  .account {
    display: table;
    margin-bottom: 20px;
    height: 100px;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;

    span {
      position: relative;
      padding-right: 10px;
      display: table-cell;
      vertical-align: middle;
      font-family: "App";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: right;

      color: #00465b;
      img {
        position: absolute;
        background: gray;
        border-radius: 50%;
        top: 18px;
        right: 20px;
      }

      svg {
        position: absolute;
        top: 43px;
        left: 18px;
      }
    }
  }
}
.services {
  margin: auto;
  margin-top: 27px;
  margin-bottom: 100px;
  height: 60px;
  width: 300px;

  div .btn-google {
    position: relative;
    background: #f2f2f2;
    border-radius: 4px;
    height: 60px;
    width: 100%;
    margin-top: 0px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    padding-right: 55px;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: right;

    color: #000000;
    img {
      position: absolute;
      right: 17px;
      top: 18px;
    }
  }

  div .btn-facebook {
    position: relative;
    margin-top: 10px;
    background: #f2f2f2;
    border-radius: 4px;
    padding-right: 55px;
    height: 60px;
    width: 100%;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: right;

    color: #000000;
    img {
      position: absolute;
      right: 17px;
      top: 18px;
    }
  }
}
.email-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  svg {
    position: absolute;
    top: -2px;
    right: 3px;
  }
}
.phone-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  svg {
    position: absolute;
    top: 61px;
    right: 3px;
  }
}
.code-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  svg {
    position: absolute;
    top: -1px;
    right: 3px;
  }
}
.promo-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;

  svg {
    position: absolute;
    top: 6px;
    right: 3px;
  }
}
.testLtr ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
  left: 0px !important;
  right: auto !important;
}
.test ::v-deep .v-label {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  text-align: right;

  color: #a2a2a2;
}
// hr {
//   height: 1px;
//   background-color: #fff;
//   border: none;
//   margin-top: 34px;
//   text-align: center;
//   padding: 0px 36px;
// }
// hr::before {
//   content: "או";
//   position: relative;
//   top: -12px;
//   background-color: #005d7a;
//   padding: 2px 13px;
//   color: #fff;
// }
.coupon-selects ::v-deep .v-select__selection {
  margin: 7px 30px 7px 0;
}
.coupon-selects ::v-deep .v-select__slot label {
  right: 25px !important;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  top: auto !important;
  /* identical to box height */

  color: #000000;
}
.coupon-selects ::v-deep .v-list-item .v-list-item__title {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
ul.hr li {
  display: inline; /* Отображать как строчный элемент */
  margin-right: 5px; /* Отступ слева */
  padding: 3px 0px; /* Поля вокруг текста */
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  color: #ffffff;
}

.button {
  margin: auto;
  margin-top: 20px;
  text-align: center;

  .reg-b {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 54px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }

  .reg-c {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 48px !important;
    color: black;
    background: #a9ddf3 !important;
    border-radius: 30px;
  }
  .reg-d {
    margin-left: auto;
    margin-right: auto;
    width: 304px !important;
    height: 48px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
  }
}
.divider {
  margin: auto;
  padding: 0px 34px;
}
.dividers {
  position: relative;
  margin: auto;
  padding: 2px 47px;
  hr {
    height: 1px;
    background-color: #fff;
    border: none;
    // margin-top: 34px;
    text-align: center;
    padding: 0px 36px;
  }

  div {
    color: white;
    width: 40px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -13px;
    background: #005d7a;
  }
  // hr::before {
  //   content: "או";
  //   position: relative;
  //   top: -12px;
  //   background-color: #005d7a;
  //   padding: 2px 13px;
  //   color: #fff;
  // }
}
.alert {
  margin: auto;

  span {
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    .link {
      text-decoration: underline;
      color: #fff;
    }
  }
}
.alert-b {
  margin: auto;
  margin-top: 5px;
  span {
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #ffffff;
    .link {
      text-decoration: underline;
      color: #fff;
    }
  }
}
.alerts-block {
  padding: 0px 17px;
  height: 65px;
  width: 304px;
  border: 1px solid #00465b;
  border-radius: 4px;
  margin: auto;
  margin-top: 10px;

  .title {
    padding: 3px 0px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    text-align: right;

    color: #ffffff;
  }
}
.test ::v-deep .v-input__slot::before {
  border-style: none;
}
.test ::v-deep .v-input__icon .mdi-check {
  color: #8cc63f;
}
.test ::v-deep .v-input__icon .mdi-close {
  color: #e41e1e;
}
.test ::v-deep .v-input__slot::after {
  border-style: none;
}
.test ::v-deep .v-label {
  margin-top: 2px;
}
.test ::v-deep .v-text-field__slot input {
  padding-top: 12px;
}
.test {
  margin: 0;
}
.back-arrow {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 25px;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;
}
.vue-tel-input ::v-deep .vti__dropdown {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 10px 20px 13px;
  margin-right: 10px;
}
.vue-tel-input ::v-deep .vti__input {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  padding: 20px 20px 20px 27px;
  margin-right: 10px;
  width: 224px !important;
}
.vue-tel-input ::v-deep .vti__flag {
  scale: 1.2;
}
.vue-tel-input ::v-deep .vti__dropdown-arrow {
  margin-left: 10px;
}
.vue-tel-input {
  box-shadow: none;
}
.vue-tel-input {
  box-shadow: none;
}
.refresh-code {
  text-decoration: none;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.code-block {
  width: 324px !important;
  margin: auto;
  margin-top: 27px;
  margin-bottom: 10px;
  border-radius: 4px;

  .code-num {
    display: inline;
    width: 44px !important;
    height: 60px !important;
    background: #fff;
    margin: 0px 5px;
    text-align: center;
    border-radius: 4px;
  }

  .code-num::-webkit-outer-spin-button,
  .code-num::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .code-num:focus {
    border: 2px solid #fdc671;
  }
}
// .coupon-block:hover {
//   border: 2px solid #fdc671;
// }

// .surname-input:hover {
//   border: 2px solid #fdc671;
// }
// .email-input:hover {
//   border: 2px solid #fdc671;
// }
// .password-input:hover {
//   border: 2px solid #fdc671;
// }
// .name-input:hover {
//   border: 2px solid #fdc671;
// }

.some::v-deep .vti__input:hover {
  border: 2px solid #fdc671;
}
.phone-block {
  padding: 1px 17px 0px 12px;
  margin: auto;
  position: relative;
  width: 304px !important;
  margin-top: 27px;
  margin-bottom: 10px;
  border-radius: 4px;
  .vue-tel-input {
    // margin: auto;
    position: absolute;
    left: -1%;
    border-style: none !important;
  }
}

.email-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 27px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.captcha-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  // margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.coupon-block-first {
  margin: auto;
  padding: 2px;
  width: 300px;
  height: 78px;
  border: 1px dashed #ffffff;
  border-radius: 4px;
  margin-top: 33px;
  .coupon-block {
    padding: 1px 17px 0px 12px;
    margin: 6px 7px 10px 7px;
    height: 60px;
    width: 280px;
    background: #fff;
    border-radius: 4px;
  }
}

.coupon-block-second {
  position: relative;
  margin: auto;
  width: 300px;
  height: 210px;
  background: #00465b;
  border-radius: 4px;
  margin-top: 30px;
  padding-top: 10px;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .school-info {
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #ffffff;
  }
  .img-coupon {
    width: 54px !important;
    height: 54px !important;
    background: #d9d9d9;
    border-radius: 50%;
    margin-top: 7px;
  }
  .coupon-selects {
    width: 122px;
    height: 48px;

    background: #ffffff;
    border-radius: 4px;
  }
  .header {
    margin-top: 13px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #8cc63f;
  }
}

.name-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 27px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}
.surname-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}

.password-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}
.forgot-input {
  margin: auto;
  height: 20px;
  width: 300px;
  text-align: end;

  .link {
    cursor: pointer;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
}
.head-label {
  font-family: "App";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  text-align: center;

  color: #ffffff;
}

.head-sublabel {
  padding-top: 10px;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.voerro-notification-theme-red {
  font-weight: bold;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.vti__input {
  background: transparent !important;
}
.vue-tel-input {
  direction: ltr;
  max-width: 250px;
}

.title-list {
  padding: 16px 8px 16px 8px;
  text-align: right;
  background-color: #005069;
  border-radius: 10px;
}
.title-element::before {
  content: "* ";
  font-size: 10px;
}
.title-element {
  font-family: "Kartiv";
  letter-spacing: 1px;

  font-weight: 550 !important;
  font-size: 16px;
  line-height: 20px;

  /* or 100% */
  text-align: right;

  color: #fdc671;
}
.select-register {
  width: 250px;
  position: relative;
  &.active {
    &:after {
      position: absolute;
      content: "*";
      color: #ff5c5c;
      right: 0;
    }
  }
  .select-reg {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid #ffffff;
    padding: 4px 4px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url("../../assets/img/icons/select-arrow.svg"); /* Add custom arrow */
    background-position: left;
    background-repeat: no-repeat;
    font-size: 17px;
    padding-right: 15px;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
    option {
      color: black;
      padding-left: 20px;
      span {
        color: red;
      }
    }
  }
}
.main {
  position: relative;
  flex-grow: 1;
  background: rgb(0, 93, 121);
  color: #fff;
  overflow: hidden;
  display: flex;
  .main-wrapper {
    text-align: center;
    z-index: 2;
    position: relative;
    flex-grow: 1;
    .title-wrapper {
      margin-top: 12px;
      .title {
        font-family: App;
        // width: 355px;
        margin: 0 11px;
        letter-spacing: 0.02px;
        font-size: 16px;
        text-align: center;
        color: #fdc671;
        font-weight: 700;
        @media screen and (max-width: 360px) {
          font-size: 14px;
          width: 311px;
        }
      }
      .login-l {
        a {
          color: #ffffff;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          text-decoration-line: underline;
          margin-left: 14px;
        }

        width: 311px;
        margin: 5px auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .form-wrapper {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-field {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 45px;
        .sub-title {
          position: absolute;
          bottom: -20px;
          left: 0;
          font-style: italic;
          font-weight: normal;
          font-size: 12px;
          text-align: right;
          color: #ffffff;
        }
        .input-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .image {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          margin-top: 1px;

          img {
            object-fit: contain;
            object-position: 50% 50%;
          }
        }
      }

      .sms-b {
        width: 180px;
        height: 40px;
        background: #fdc671;
        border-radius: 28px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        &.disabled {
          background: #c4c4c4 !important;
          color: #4d4d4d !important;
        }
      }
      .reg-b {
        margin-top: 5px;

        width: 180px;
        height: 40px;
        background: #fdc671;
        border-radius: 28px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        &.disabled {
          background: #c4c4c4 !important;
          color: #4d4d4d !important;
        }
      }
    }

    .bg-img {
      /*@media screen and (min-width: 600px) {*/
      /*  border-left: 2px solid #005D79;*/
      /*  border-right: 2px solid #005D79;*/
      /*  border-radius: 3px;*/
      /*}*/
      left: 0;
      right: 0;
      height: 100%;
      top: 0;
      bottom: 0;
      mix-blend-mode: overlay;
      z-index: -1;
      width: 100%;
      opacity: 0.5;
      position: absolute;
      background-color: unset;

      object-fit: cover;
    }

    .color-img {
      left: 0;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      position: absolute;
      background-color: unset;
      object-fit: cover;
    }
  }
  .sel {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid #ffffff;
    padding: 4px 4px;
    line-height: 20px;
    text-align: right;
    color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // background-image: url(img/select-arrow.f5040d4d.svg);
    background-position: left;
    background-repeat: no-repeat;
    font-size: 17px;
    padding-right: 15px;
  }
}
.promo-wrapper {
  position: relative;
  .wrapper {
    transition: all 0.5s ease-in-out;
    position: absolute;
    right: -138px;
    top: -30px;
  }
}
.terms {
  margin-top: 5px;
  margin-bottom: 26px;
  span {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #ffffff;
  }
  a {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #149cc6;
  }
}
.forgot-password {
  font-size: 14px;
  color: white;
  text-decoration-line: underline;
}
.vti__input {
  background: transparent !important;
}
.vue-tel-input {
  direction: ltr;
  max-width: 280px;
}
.switcher-lazy {
  position: relative;
  height: 40px;
  label {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /* &:focus-within {
		 outline: .125em solid $primary-color;
	}
	 */
  }
  label .switcher {
    display: inline-block;
  }
  label input {
    display: none;
  }
  label input:checked + span {
    background-color: #a9ddf3;
  }
  label input:checked + span:before {
    box-shadow: inset 0 0 0 0.6em #000;
    border: 3px solid #fff;
  }
  label span {
    color: #000;
    direction: rtl;
    width: 116px;
    height: 35px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border: 1px solid #a9ddf3;
    border-radius: 4px;
    background-color: #fff;
    transition: 0.25s ease;
  }
  label span:hover {
    background-color: #fff;
  }
  label span:before {
    content: "";
    background-color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #a9ddf3;
    margin-left: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #fff;
  }
  .container {
    position: absolute;
    top: 0px;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}
.title-list {
  padding: 16px 8px 16px 8px;
  text-align: right;
  background-color: #005069;
  border-radius: 10px;
}
.title-element::before {
  content: "● ";
  font-size: 10px;
}
.title-element {
  font-family: "Kartiv";
  letter-spacing: 1px;

  font-weight: 550 !important;
  font-size: 16px;
  line-height: 20px;

  /* or 100% */
  text-align: right;

  color: #fdc671;
}
.main {
  position: relative;
  flex-grow: 1;
  background: rgb(0, 93, 121);
  color: #fff;
  overflow: hidden;
  display: flex;
  .main-wrapper {
    z-index: 2;
    position: relative;
    flex-grow: 1;
    .title-wrapper {
      margin-top: 12px;
      .title {
        // width: 311px;
        margin: 0 11px;
        font-size: 16px;
        text-align: center;
        color: #fdc671;
        font-weight: bold;
      }
      .login-l {
        a {
          color: #ffffff;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          text-decoration-line: underline;
          margin-left: 14px;
        }

        width: 311px;
        margin: 5px auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .form-wrapper {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-field {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 45px;
        .input-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .image {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          margin-top: 1px;

          img {
            object-fit: contain;
            object-position: 50% 50%;
          }
        }
      }

      .reg-b {
        margin-top: 30px;
        margin-bottom: 26px;
        width: 180px;
        height: 40px;
        background: #fdc671;
        border-radius: 28px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        &.disabled {
          background: #c4c4c4 !important;
          color: #4d4d4d !important;
        }
      }
    }

    .bg-img {
      /*@media screen and (min-width: 600px) {*/
      /*  border-left: 2px solid #005D79;*/
      /*  border-right: 2px solid #005D79;*/
      /*  border-radius: 3px;*/
      /*}*/
      left: 0;
      right: 0;
      height: 100%;
      top: 0;
      bottom: 0;
      mix-blend-mode: overlay;
      z-index: -1;
      width: 100%;
      opacity: 0.5;
      position: absolute;
      background-color: unset;

      object-fit: cover;
    }

    .color-img {
      left: 0;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      position: absolute;
      background-color: unset;
      object-fit: cover;
    }
  }
}
.validation-wrapper {
  position: relative;

  margin-bottom: 10px;
  .wrapper {
    transition: all 0.5s ease-in-out;
    position: absolute;
    right: -138px;
  }
}
</style>
