import { PlaylistAPI } from '@/api';

const PlaylistSectionsStore = {
  namespaced: true,
  state: {
    data: [],
    loaded: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },

    load(state) {
      state.loaded = true;
    },
  },
  actions: {

    async updateData({ commit }) {
      const sections = await PlaylistAPI.selectSections()
        .then(res => res.data);

      commit('setData', sections);
      commit('load')
    }
  },
  getters: {

    data(state) {
      return state.data;
    },

    isLoaded(state) {
      return state.loaded;
    },
    
    getColorById: state => section_id => {
      const section = state.data.find(n => n.id_section == section_id);
      if(section) {
        return section.color;
      } else {
        return null;
      }
    },

  }
};

export { PlaylistSectionsStore };
