<template>
 <div class="eye">
    <div v-if="!state" class="closed">
      <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 17.118C8.6 17.118 3.85071 14.2369 1.16205 9.88275C3.85071 5.52863 8.6 2.64746 14 2.64746C19.4 2.64746 24.1493 5.52863 26.838 9.88275C24.1493 14.2369 19.4 17.118 14 17.118Z" stroke="#A2A2A2" stroke-width="2"/>
        <circle cx="13.9926" cy="9.88181" r="4.11765" fill="#A2A2A2"/>
        <circle cx="11.5221" cy="7.41122" r="1.64706" fill="white"/>
        <line x1="26.3516" y1="2.12132" x2="8.70818" y2="19.7647" stroke="white" stroke-width="3" stroke-linecap="round"/>
        <line x1="23.8867" y1="2.12132" x2="6.24333" y2="19.7647" stroke="#A2A2A2" stroke-width="3" stroke-linecap="round"/>
      </svg>
    </div>
   <div v-else class="opened">
     <svg width="29" height="22" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M14 16.118C8.6 16.118 3.85071 13.2369 1.16205 8.88275C3.85071 4.52863 8.6 1.64746 14 1.64746C19.4 1.64746 24.1493 4.52863 26.838 8.88275C24.1493 13.2369 19.4 16.118 14 16.118Z" stroke="#A2A2A2" stroke-width="2"/>
       <circle cx="13.9926" cy="8.88181" r="4.11765" fill="#A2A2A2"/>
       <circle cx="11.5221" cy="6.41122" r="1.64706" fill="white"/>
     </svg>
   </div>
 </div>
</template>

<script>
export default {
  props: {
    state:{

    }
  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {

  },
  created() {}
};
</script>

<style lang="scss" scoped>
  .eye{

  }
</style>
