<template>
  <main dir="rtl" style="background: #005d79">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get("ערכות משחקים") }}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white">
          <svg
            width="22"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="position: absolute; top: 4px; right: 24px"
          >
            <path
              d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
              fill="white"
              stroke="white"
              stroke-width="0.2"
            />
          </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading style="background: white" class="sm-cont">
      <template class="plans">
        <div
          class="disc-temp"
          style="background: #f2f2f2; display: flex; align-items: center"
        >
          <div class="disc" v-if="!isDefaultUser">
            <template
              v-if="
                !$store.getters['user/hasSubs'] &&
                user.subscribe == null &&
                user.subscribe_games == 0
              "
            >
              <div class="bordered" v-if="simple && is_school == false">
                <div class="column">
                  <span class="new-span" style="padding-right: 16px"
                    >{{ " " + $ml.get("צפית") + " " }}
                    {{
                      user.subscribe_games != null && user.subscribe_games != 0
                        ? user.subscribe_games
                        : playlistCounter + "/" + user.subscribe_total_games
                    }}
                    {{ " " + $ml.get("משחקים") + " " }}
                    {{
                      user.subscribe_shootlist != null &&
                      user.subscribe_shootlist != 0
                        ? user.subscribe_shootlist
                        : shootlistCounter +
                          "/" +
                          user.subscribe_total_shootlist
                    }}
                    {{ " " + $ml.get("שאלות") + " " }}</span
                  >
                </div>
              </div>
              <div
                v-else-if="!simple && is_school == false"
                class="bordered"
                style="padding: 0 !important"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("צפית ב") }}
                    {{
                      user.subscribe_total_games +
                      "/" +
                      user.subscribe_total_games
                    }}
                    {{ $ml.get("משחקים") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #ff7384;
                    "
                    >{{ $ml.get("להמשך צפייה נדרש מנוי") }}</span
                  >
                </div>
              </div>
              <div
                v-else-if="couponData != null && couponData.id_school != null"
                class="bordered"
                style="padding: 0 !important"
              >
                <div class="" style="position: relative">
                  <span
                    v-if="couponData.type == 'month'"
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי חודשי") }} |
                    {{ user.class != null ? user.class : "" }}
                    {{ user.class_number != null ? user.class_number : "" }},{{
                      " "
                    }}{{
                      couponData.school != null ? couponData.school.name : ""
                    }},{{ " "
                    }}{{
                      couponData.school != null ? couponData.school.city : ""
                    }}
                  </span>
                  <span
                    v-else-if="couponData.type == 'six'"
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי חצי שנתי") }} |
                    {{ user.class != null ? user.class : "" }}
                    {{ user.class_number != null ? user.class_number : "" }},{{
                      " "
                    }}{{
                      couponData.school != null ? couponData.school.name : ""
                    }},{{ " "
                    }}{{
                      couponData.school != null ? couponData.school.city : ""
                    }}
                  </span>
                  <span
                    v-else-if="couponData.type == 'year'"
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי שנתי") }} |
                    {{ user.class != null ? user.class : "" }}
                    {{ user.class_number != null ? user.class_number : "" }},{{
                      " "
                    }}{{
                      couponData.school != null ? couponData.school.name : ""
                    }},{{ " "
                    }}{{
                      couponData.school != null ? couponData.school.city : ""
                    }}
                  </span>
                  <span
                    v-else
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("הרשמה מטעם") }} | {{ couponData.school.name }}
                  </span>
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #424242;
                    "
                    >{{ $ml.get("עד ל-") + " "
                    }}{{ couponData.school.endDate | moment("DD.MM.YY") }}</span
                  >
                </div>
              </div>
            </template>
            <template
              v-else-if="
                !$store.getters['user/hasSubs'] &&
                user.subscribe != null &&
                user.subscribe_games == 0
              "
            >
              <div
                class="bordered"
                style="padding: 0 !important"
                v-if="user.subscribe_interval == 2600000"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("צפית ב") }}
                    {{
                      user.subscribe_total_games +
                      "/" +
                      user.subscribe_total_games
                    }}
                    {{ $ml.get("משחקים") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #ff7384;
                    "
                    >{{ $ml.get("להמשך צפייה נדרש מנוי") }}</span
                  >
                </div>
              </div>
            </template>
            <template
              v-else-if="$store.getters['user/hasSubs'] && is_subscribe"
            >
              <div
                class="bordered"
                style="padding: 0 !important"
                v-if="user.subscribe_interval == 2620000"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי חודשי") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ $ml.get("עד ל-") + " "
                    }}{{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | moment("DD.MM.YY")
                    }}</span
                  >
                </div>
              </div>
              <div
                class="bordered"
                style="padding: 0 !important"
                v-else-if="user.subscribe_interval == 2620000 * 6"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("יתנש יצח יונמ") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ $ml.get("עד ל-") + " "
                    }}{{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | moment("DD.MM.YY")
                    }}</span
                  >
                </div>
              </div>
              <div
                class="bordered"
                style="padding: 0 !important"
                v-else-if="user.subscribe_games > 0"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי ל- 30 משחקים") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ " " + $ml.get("משחקים זמינים לצפייה")
                    }}{{ user.subscribe_games }}</span
                  >
                </div>
              </div>
              <div class="column">
                <button
                  class="cancel"
                  style="width: 100px"
                  @click="cancel"
                  v-if="
                    $store.getters['user/hasSubs'] &&
                    (user.subscribe_interval == 2620000 * 6 ||
                      user.subscribe_interval == 2620000)
                  "
                >
                  {{ $ml.get("בטל את הרישום") }}
                </button>
              </div>
            </template>
            <template
              v-else-if="$store.getters['user/hasSubs'] && !is_subscribe"
            >
              <div
                class="bordered"
                style="padding: 0 !important"
                v-if="user.subscribe_interval == 2620000"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי חודשי") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ $ml.get("עד ל-") + " "
                    }}{{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | moment("DD.MM.YY")
                    }}</span
                  >
                </div>
              </div>
              <div
                class="bordered"
                style="padding: 0 !important"
                v-else-if="user.subscribe_interval == 2620000 * 6"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("יתנש יצח יונמ") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ $ml.get("עד ל-") + " "
                    }}{{
                      new Date(
                        (user.subscribe + user.subscribe_interval) * 1000
                      ) | moment("DD.MM.YY")
                    }}</span
                  >
                </div>
              </div>
              <div
                class="bordered"
                style="padding: 0 !important"
                v-else-if="user.subscribe_games > 0"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי ל- 30 משחקים") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ user.subscribe_games
                    }}{{ " " + $ml.get("משחקים זמינים לצפייה") }}</span
                  >
                </div>
              </div>
            </template>
            <template
              v-else-if="!$store.getters['user/hasSubs'] && !is_subscribe"
            >
              <div
                class="bordered"
                style="padding: 0 !important"
                v-if="user.subscribe_games > 0"
              >
                <div class="" style="position: relative">
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 7px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #00465b;
                    "
                    >{{ $ml.get("מנוי ל- 30 משחקים") }}</span
                  >
                  <span
                    style="
                      width: 250px;
                      position: absolute;
                      right: 26px;
                      top: 29px;
                      font-family: 'App';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      text-align: right;
                      color: #4d4d4d;
                    "
                    >{{ user.subscribe_games
                    }}{{ " " + $ml.get("משחקים זמינים לצפייה") }}</span
                  >
                </div>
              </div>
            </template>
          </div>
          <div class="disc" v-else-if="isDefaultUser">
            <template>
              <div class="bordered">
                <div class="column">
                  <span class="new-span" style="padding-right: 16px"
                    >{{ " " + $ml.get("צפית") + " " }}
                    {{
                      playlistDefaultCount -
                      playlistDefaultCurrentCount +
                      "/" +
                      playlistDefaultCount
                    }}

                    {{ " " + $ml.get("משחקים") + " " }}

                    {{
                      shootlistDefaultCount -
                      shootlistDefaultCurrentCount +
                      "/" +
                      shootlistDefaultCount
                    }}

                    {{ " " + $ml.get("שאלות") + " " }}</span
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="positions">
          <div class="positions-list">
            <div class="positions-header">
              <h1 class="positions-title" v-if="simpleSec">
                {{ $ml.get("ברכישת מנוי מקבלים") }}
              </h1>
              <h1 class="positions-title" v-else-if="!simpleSec">
                {{ $ml.get("להמשך משחק נדרש מנוי") }}
              </h1>
            </div>
            <div
              class=""
              style="
                text-align: right;
                padding: 6px 0px 0px 26px;
                margin: 0px 30px 0px 0px;
              "
            >
              <ul style="white-space: nowrap" class="title-list">
                <li class="title-element">
                  {{ $ml.get("משחקים מחוץ למסך ללא אביזרים") }}
                </li>
                <li class="title-element">
                  {{ $ml.get("אין מפסידים - משחקים עם החבר ולא נגדו") }}
                </li>
              </ul>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/first.png" />
              </div>
              <div class="block-right">
                <span>{{ $ml.get("כ-1,000 משחקים לפנאי חברתי ומהנה") }}</span>
              </div>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/second.png" />
              </div>
              <div class="block-right">
                <span>{{ $ml.get("כ-500 שאלות לשיחה משפחתית וזוגית") }}</span>
              </div>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/third.png" />
              </div>
              <div class="block-right">
                <span>{{
                  $ml.get("משחקים שגורמים לילד לבצע מטלות בכיף")
                }}</span>
              </div>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/fourth.png" />
              </div>
              <div class="block-right">
                <span>{{ $ml.get("פיתוח כישורי חיים ולמידה לפי בחירה") }}</span>
              </div>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/fifth.png" />
              </div>
              <div class="block-right">
                <span>{{
                  $ml.get("הגדרת מסלול משחקים אישי שמותאם לילד")
                }}</span>
              </div>
            </div>

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/eighth.png" />
              </div>
              <div class="block-right">
                <span>{{ $ml.get("מעקב אלו משחקים הילד שיחק ומתי") }}</span>
              </div>
            </div>

            <!-- <div class="block">
            <div class="block-left"><img src="@/assets/img/six.png" /></div>
            <div class="block-right">
              <span>{{ $ml.get('אפשרות לילד לזכות בתעודת ממציאן') }}</span>
            </div>
          </div> -->

            <div class="block">
              <div class="block-left">
                <img alt="" src="@/assets/img/seven.png" />
              </div>
              <div class="block-right">
                <span>{{ $ml.get('שובר 500 ש"ח לסדנת משחקים') }}</span>
                <router-link
                  style="cursor: pointer"
                  to="/workshop"
                  class="title-link"
                  >{{ $ml.get("לפירוט הסדנאות") }}</router-link
                >
              </div>
            </div>
          </div>
          <div
            style="
              background: #ddf0f5;
              margin-top: 24px;
              height: 550px;
              position: relative;
            "
          >
            <div style="padding-top: 19px">
              <span class="span-plans"
                >{{
                  $ml.get("המנוי תקף לכל בני המשפחה בחשבון ללא תוספת תשלום")
                }}.<br />{{ $ml.get("ניתן להוסיף עד שלושה בני משפחה") }}</span
              >
            </div>
            <div class="blocks-container">
              <div
                :class="plans.games ? 'active' : null"
                class="plan right"
                @click="changePlans('games')"
              >
                <div class="radio radio-btn">
                  <input
                    id="games"
                    name="games"
                    type="radio"
                    :checked="plans.games ? true : false"
                  />
                  <label for="games" class="radio-label"></label>
                </div>
                <!-- <input class="radio-btn" type="radio" id="games" value="games" :checked="plans.games?true:false"> -->
                <div class="plan-header">
                  {{ $ml.get("מנוי") }}<br /><span style="font-size: 16px">{{
                    $ml.get("ל-30 משחקים")
                  }}</span>
                </div>
                <div class="under-header">
                  <!-- <span>{{ 'הלבגה אלל םיקחשמ' }}</span> -->
                </div>
                <div class="prices-block" style="position: relative">
                  <span
                    >{{ $ml.get("₪")
                    }}<span style="font-size: 28px">{{
                      user.promo.discount != 0
                        ? (
                            prices.games -
                            prices.games * (user.promo.discount / 100)
                          ).toFixed()
                        : prices.games
                    }}</span></span
                  >
                  <span class="bottom-span" v-if="user.promo.discount != 0">{{
                    $ml.get(" במקום ") + prices.games + " ₪ "
                  }}</span>
                </div>
              </div>

              <div
                :class="plans.six ? 'active' : null"
                class="plan center"
                @click="changePlans('six')"
              >
                <div class="radio radio-btn">
                  <input
                    id="six"
                    name="six"
                    type="radio"
                    :checked="plans.six ? true : false"
                  />
                  <label for="six" class="radio-label"></label>
                </div>
                <!-- <input class="radio-btn" type="radio" id="six" value="six" :checked="plans.six?true:false"> -->
                <div class="popular-label">
                  <span>{{ $ml.get("הכי פופולרי") }}</span>
                </div>
                <div class="plan-header">
                  {{ $ml.get("מנוי") }}<br /><span style="font-size: 16px">{{
                    $ml.get("ל-6 חודשים")
                  }}</span>
                </div>
                <div class="under-header">
                  <span>{{ $ml.get("משחקים ללא הגבלה") }}</span>
                </div>
                <div class="prices-block" style="position: relative">
                  <span
                    >{{ $ml.get("₪")
                    }}<span style="font-size: 28px">{{
                      user.promo.discount != 0
                        ? (
                            prices.six / 6 -
                            (prices.six / 6) * (user.promo.discount / 100)
                          ).toFixed()
                        : prices.six / 6
                    }}</span>
                    {{ $ml.get("לחודש") }}</span
                  >
                  <span class="bottom-span" v-if="user.promo.discount != 0">{{
                    $ml.get(" במקום ") + prices.six / 6 + " ₪ "
                  }}</span>
                </div>
              </div>

              <div
                :class="plans.month ? 'active' : null"
                class="plan left"
                @click="changePlans('month')"
              >
                <div class="radio radio-btn">
                  <input
                    id="month"
                    name="six"
                    type="radio"
                    :checked="plans.month ? true : false"
                  />
                  <label for="month" class="radio-label"></label>
                </div>
                <!-- <input class="radio-btn" type="radio" id="month" value="month" :checked="plans.month?true:false"> -->
                <div class="plan-header">
                  {{ $ml.get("מנוי") }}<br /><span style="font-size: 16px">{{
                    $ml.get("חודשי")
                  }}</span>
                </div>
                <div class="under-header">
                  <span>{{ $ml.get("משחקים ללא הגבלה") }}</span>
                </div>
                <div class="prices-block" style="position: relative">
                  <span
                    >{{ $ml.get("₪")
                    }}<span style="font-size: 28px">{{
                      user.promo.discount != 0
                        ? (
                            prices.month -
                            prices.month * (user.promo.discount / 100)
                          ).toFixed()
                        : prices.month
                    }}</span></span
                  >
                  <span class="bottom-span" v-if="user.promo.discount != 0">{{
                    $ml.get(" במקום ") + prices.month + " ₪ "
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="btns-block"
              style="width: 100%; z-index: 10; position: relative"
            >
              <app-btn
                class="btn-a"
                :disabled="
                  $store.getters['user/hasSubs'] || is_school || isDefaultUser
                    ? true
                    : false
                "
                @click="subscribeType(false)"
              >
                {{ $ml.get("לרכישה") }}
              </app-btn>

              <app-btn
                class="btn-b"
                :disabled="isDefaultUser ? true : false"
                @click="subscribeType(true)"
              >
                {{ $ml.get("לרכישה כמתנה") }}
              </app-btn>
            </div>
            <div class="buttom-text" style="z-index: 10; position: relative">
              <span
                >{{ $ml.get("אפשר לרכוש מנוי כמתנה לילד") }}.{{
                  $ml.get("ה ממשפחה אחרת")
                }}:</span
              ><br />
              <span>{{ $ml.get("ליום הולדת, לחג, לביקור או סתם לפנק") }}</span>
            </div>
            <img
              alt=""
              style="
                position: absolute;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                width: 100%;
                height: 20%;
                bottom: 0;
                z-index: 1;
              "
              src="@/assets/img/mask_group.png"
            />
            <div class="bottom-text">
              <span
                >{{ $ml.get("מנויים מתחדשים") }}.
                {{ $ml.get("ניתן לבטלם בכל עת") }}</span
              ><br />
              <span>{{ $ml.get("מנוי שנרכש כמתנה אינו מתחדש") }}</span>
            </div>
          </div>
        </div>
        <div class="coupons" v-if="promo.first">
          <div class="coupons-label p-2">
            <span>{{ $ml.get("יש לכם קוד קופון?") }}</span>
          </div>
          <div class="coupon-block">
            <v-text-field
              :disabled="isDefaultUser ? true : false"
              :append-icon="
                couponError
                  ? 'mdi-close'
                  : !couponError && (coupon == '' || coupon == null)
                  ? ''
                  : 'mdi-check'
              "
              :label="$ml.get('הקלידו קוד קופון')"
              class="test"
              v-model="coupon"
              @input="onInputCoupon"
              autocomplete="new-password"
            ></v-text-field>
          </div>
          <div
            style="
              width: 300px;
              height: 10px;
              margin: auto;
              margin-bottom: 10px;
            "
            class="promo-alert"
            v-if="couponError"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="14px"
              height="14px"
              viewBox="0 0 14 14"
              version="1.1"
            >
              <g id="surface1">
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(84.313725%, 15.686275%, 15.686275%);
                    fill-opacity: 1;
                  "
                  d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
                />
                <path
                  style="
                    stroke: none;
                    fill-rule: nonzero;
                    fill: rgb(90.196078%, 90.196078%, 90.196078%);
                    fill-opacity: 1;
                  "
                  d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
                />
              </g>
            </svg>
            <span style="position: absolute; top: -33px; right: 122px">{{
              $ml.get(couponErrorMsg)
            }}</span>
          </div>
          <!-- <div class="btn-block" style="margin:auto; margin-bottom: 26px;">
          <app-btn v-if="brnPresent"
          :loading="loadingCoupon"
          :class="couponError == false && (coupon != null && coupon != '') ? 'active' : 'disabled'"
          @click="onInputCoupon"
          class="reg-b"
        >
          {{ $ml.get("המשך") }}</app-btn
        >
        </div> -->
        </div>
        <div class="coupons" v-else-if="promo.second">
          <div
            v-if="$store.getters['user/data'].id_promo != null"
            class="coupon-block-second"
            style="min-height: 285px !important"
          >
            +
            <span class="header" v-if="couponDataRR.type == 'month'">{{
              $ml.get("מנוי חודשי מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'six'">{{
              $ml.get("מנוי חצי שנתי מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'year'">{{
              $ml.get("מנוי לשנה מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'discount'">{{
              $ml.get("הנחה מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else>{{
              $ml.get("הנחה על משחקים מטעם") + " " + couponDataRR.client
            }}</span>
            <div>
              <img
                :src="couponDataRR.img != null ? couponDataRR.img.url : null"
                class="img-coupon"
              />
            </div>
            <div style="text-align: center">
              <span class="school-info">{{
                $ml.get(
                  couponDataRR.school.city + "," + couponDataRR.school.name
                )
              }}</span>
            </div>
            <div style="margin-top: 10px" v-if="$ml.current == 'heb'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="letters" class="select" />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="numbers" class="select" />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'eng'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="letters" class="select" />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="numbers" class="select" />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'ar'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="letters" class="select" />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="numbers" class="select" />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'ru'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="letters" class="select" />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect :options="numbers" class="select" />ss="select" />
              </div>
            </div>

            <app-btn
              class="btn-c"
              :disabled="
                class_num == 'מספר' || class_name == 'כיתה' ? true : false
              "
              @click="redirect"
            >
              {{ $ml.get("יאללה לשחק") }}
            </app-btn>
          </div>
          <div
            v-else
            class="coupon-block-second"
            style="min-height: 285px !important"
          >
            <span class="header" v-if="couponDataRR.type == 'month'">{{
              $ml.get("מנוי חודשי מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'six'">{{
              $ml.get("מנוי חצי שנתי מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'year'">{{
              $ml.get("מנוי לשנה מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else-if="couponDataRR.type == 'discount'">{{
              $ml.get("הנחה מטעם") + " " + couponDataRR.client
            }}</span>
            <span class="header" v-else>{{
              $ml.get("הנחה על משחקים מטעם") + " " + couponDataRR.client
            }}</span>
            <div>
              <img
                :src="couponDataRR.img != null ? couponDataRR.img.url : null"
                class="img-coupon"
              />
            </div>
            <div style="text-align: center">
              <span class="school-info">{{
                $ml.get(
                  couponDataRR.school.city + "," + couponDataRR.school.name
                )
              }}</span>
            </div>
            <div style="margin-top: 10px" v-if="$ml.current == 'heb'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_name"
                  :options="letter"
                  :default="$ml.get('כיתה')"
                  class="select"
                />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_num"
                  :options="number"
                  :default="$ml.get('מספר')"
                  class="select"
                />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'eng'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_name"
                  :options="letterEn"
                  :default="$ml.get('Class')"
                  class="select"
                />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_num"
                  :options="numberEn"
                  :default="$ml.get('Number')"
                  class="select"
                />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'ar'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_name"
                  :options="letterAr"
                  :default="$ml.get('فصل')"
                  class="select"
                />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_num"
                  :options="numberAr"
                  :default="$ml.get('رقم')"
                  class="select"
                />
              </div>
            </div>
            <div style="margin-top: 10px" v-else-if="$ml.current == 'ru'">
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_name"
                  :options="letterRu"
                  :default="$ml.get('Класс')"
                  class="select"
                />
              </div>
              <div style="display: inline-block; margin: 0px 10px">
                <CustomSelect
                  v-model="class_num"
                  :options="numberRu"
                  :default="$ml.get('Цыфра')"
                  class="select"
                />
              </div>
            </div>

            <app-btn
              class="btn-c"
              :disabled="
                class_num == 'מספר' || class_name == 'כיתה' ? true : false
              "
              @click="redirectSchool"
            >
              {{ $ml.get("יאללה לשחק") }}
            </app-btn>
          </div>
        </div>
        <div class="coupons" v-else-if="promo.third">
          <div class="coupon-block-second" style="min-height: 230px !important">
            <span class="header" v-if="couponData.type == 'month'">{{
              $ml.get("מנוי חודשי מטעם:")
            }}</span>
            <span class="header" v-else-if="couponData.type == 'six'">{{
              $ml.get("מנוי חצי שנתי מטעם:")
            }}</span>
            <span class="header" v-else-if="couponData.type == 'year'">{{
              $ml.get("מנוי שנתי מטעם:")
            }}</span>
            <span class="header" v-else-if="couponData.type == 'discount'">{{
              $ml.get("הנחה מטעם:")
            }}</span>
            <span class="header" v-else>{{
              $ml.get("הנחה על משחקים מטעם:")
            }}</span>
            <div style="height: 65px !important">
              <img
                :src="couponData.img != null ? couponData.img.url : null"
                class="img-coupon"
              />
            </div>
            <div style="text-align: center">
              <span class="school-info">{{ $ml.get(couponData.client) }}</span>
            </div>

            <app-btn class="btn-c" @click="redirect">
              {{ $ml.get("יאללה לשחק") }}
            </app-btn>
          </div>
        </div>
        <div class="unsubed-btn-block">
          <app-btn
            class="btn-g"
            :disabled="isDefaultUser ? true : false"
            @click="openShare"
          >
            {{ $ml.get("שיתוף תמורת 20 משחקים ושאלות") }}
          </app-btn>
        </div>
      </template>
      <Confirm />
    </app-loading>
  </main>
</template>
<script>
import Confirm from "@/components/modals/modal/Confirm";
import SimpleHeader from "@/components/SimpleHeader.vue";
import Payment from "@/components/modals/Payment";
import ReferalShare from "@/components/modals/ReferalShare";
import { SubscribeAPI, AuthAPI, UserAPI } from "@/api";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Btn from "@/components/ui/app-btn/AppBtn";
import { mapGetters } from "vuex";
import CustomSelect from "@/components/ui/CustomSelectReverse.vue";
import PaymentPresentVue from "@/components/modals/PaymentPresent.vue";
import AppBtn from "@/components/ui/app-btn/AppBtn.vue";
export default {
  data() {
    return {
      enteredCoupon: false,
      is_subscribe: false,
      simple: true,
      is_school: false,
      simpleSec: true,
      plans: {
        month: true,
        six: false,
        games: false,
      },
      optNum: "מספר",
      optName: "כיתה",
      optNumDef: "מספר",
      optNameDef: "כיתה",
      numbers: [],
      letters: [],
      number: [1, 2, 3, 4, 5, 6, 7, "מורה מקצועי"],
      letter: ["א", "ב", "ג", "ד", "ה", "ו", "ז", "ח", "ט", "מורה מקצועי"],
      numberEn: [1, 2, 3, 4, 5, 6, 7, "no class"],
      letterEn: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "no class"],
      numberAr: [1, 2, 3, 4, 5, 6, 7, "مدرس محترف"],
      letterAr: [
        "و",
        "ب",
        "ثالث",
        "رابع",
        "ال",
        "و",
        "g",
        "ح",
        "تاسع",
        "مدرس محترف",
      ],
      numberRu: [1, 2, 3, 4, 5, 6, 7, "без класса"],
      letterRu: ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "без класса"],
      emailTransfer: "",
      mainpromo: true,
      subsType: "month",
      coupon: null,
      timer: null,
      disabled: false,
      failedCoupon: false,
      failedCouponText: null,
      couponData: null,
      couponDataRR: null,
      class_name: null,
      class_nameCompare: null,
      presentType: null,
      presTyp: null,
      present: false,
      class_num: null,
      class_numCompare: null,
      couponError: false,
      couponErrorMsg: "קוד הקופון שגוי",
      brnPresent: true,
      loadingCoupon: false,
      promo: {
        first: true,
        second: false,
        third: false,
      },
    };
  },
  watch: {},
  async created() {},
  methods: {
    openShare() {
      let userAgent = window.navigator.userAgent;
      let type = "";
      const myString = this.$store.getters["user/data"].id_user.toString();
      const base64String = Buffer.from(myString).toString("base64");
      let encrypted = base64String;
      if(userAgent.includes('ExpoLogin')) {
        window.ReactNativeWebView.postMessage(
          "www.playlistgame.com/#/auth/reg?referal_id" + encrypted
        );
      } else if (userAgent.includes("Mobile")) {
        navigator.share({
          title: this.$ml.get("שובר מתנה Playlist"),
          text: "https://playlistgame.com/#/auth/reg?referal_id=" + encrypted,
        });
      } else {
        this.$modal.show(
          ReferalShare,
          {},
          {
            clickToClose: true,
            adaptive: true,
            height: "450px",
            width: "400px",
          }
        );
      }
    },
    redirect() {
      this.$router.push("/main");
    },
    changePlans(val) {
      switch (val) {
        case "month":
          this.plans.month = true;
          this.plans.six = false;
          this.plans.games = false;
          this.subsType = "month";
          break;
        case "six":
          this.plans.month = false;
          this.plans.six = true;
          this.plans.games = false;
          this.subsType = "six";
          break;
        case "games":
          this.plans.month = false;
          this.plans.six = false;
          this.plans.games = true;
          this.subsType = "games";
          break;
        default:
          this.plans.month = false;
          this.plans.six = false;
          this.plans.games = false;
          this.subsType = "";
          break;
      }
    },
    clearValidation() {
      this.couponError = false;
    },
    redirectSchool() {
      SubscribeAPI.subscribeViaSchoolCoupon(
        this.couponDataRR,
        this.class_name,
        this.class_num
      )
        .then((res) => {
          this.$store.commit("playlist/reset");
          this.$store.dispatch("user/updateData").then((res) => {
            this.$router.push("/main");
          });
        })
        .catch((err) => {
          this.$notify({
            title: "שְׁגִיאָה",
            text: "קוד ההטבה לא נמצא או מחוץ לגבולות!",
            type: "error",
            duration: 10000,
          });
          this.promo.first = true;
          this.promo.second = false;
          this.promo.third = false;
          this.disabled = false;
          this.failedCoupon = true;
          this.failedCouponText = this.$ml.get(err.response.data.error);
        });
    },
    onInputCoupon() {
      this.couponError = false;
      this.couponData = null;
      this.loadingCoupon = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.coupon != "" && this.coupon != null) {
          SubscribeAPI.checkCoupon(this.coupon).then((res) => {
            if (res.data.success == true) {
              this.brnPresent = false;
              if (res.data.data.id_school == null) {
                this.couponData = res.data.data;
                this.promo.first = false;
                this.promo.second = false;
                this.promo.third = true;
                this.couponError = false;
                this.loadingCoupon = false;
                this.subscribeViaCoupon();
              } else {
                // this.is_school=true;
                this.promo.first = false;
                this.promo.second = true;
                this.promo.third = false;
                this.loadingCoupon = false;
                this.couponDataRR = res.data.data;
                this.couponError = false;
                // this.subscribeViaCoupon();
              }
            } else {
              this.loadingCoupon = false;
              this.couponError = true;
            }
          });
        }
      }, 5000);
    },
    subscribeViaSchoolCoupon() {
      SubscribeAPI.subscribeViaSchoolCoupon(
        this.couponDataRR,
        this.class_name,
        this.class_num
      )
        .then((res) => {
          this.$store.commit("playlist/reset");
          this.$store.dispatch("user/updateData").then((res) => {
            // this.$router.push("/main");
          });
        })
        .catch((err) => {
          this.$notify({
            title: "שְׁגִיאָה",
            text: "קוד ההטבה לא נמצא או מחוץ לגבולות!",
            type: "error",
            duration: 10000,
          });
          this.promo.first = true;
          this.promo.second = false;
          this.promo.third = false;
          this.disabled = false;
          this.failedCoupon = true;
          this.failedCouponText = this.$ml.get(err.response.data.error);
        });
    },
    subscribeViaCoupon() {
      SubscribeAPI.subscribeViaCoupon(this.couponData)
        .then((res) => {
          $("html, body").animate({ scrollTop: 0 }, 500);
          this.enteredCoupon = true;
          if (this.simpleSec == false) {
            this.simpleSec = true;
          }
          this.$store.commit("playlist/reset");
          this.$store.dispatch("user/updateData").then((res) => {
            // this.$router.push("/main");
          });
        })
        .catch((err) => {
          this.is_school = false;
          this.$notify({
            title: "שְׁגִיאָה",
            text: "קוד ההטבה לא נמצא או מחוץ לגבולות!",
            type: "error",
            duration: 10000,
          });
          this.promo.first = true;
          this.promo.second = false;
          this.promo.third = false;
          this.loadingCoupon = false;
          this.couponError = true;
          this.disabled = false;
          this.failedCoupon = true;
          this.failedCouponText = this.$ml.get(err.response.data.error);
        });
    },
    subscribeType(present) {
      this.present = present;
      this.subscribeSched();
    },
    async subscribeSchedPresent() {
      let successfulPage =
        window.location.origin + "/#/successfulPaymentPresent";
      let errorPage = window.location.origin + "/#/errorPayment";
      const data = await SubscribeAPI.subscribe(
        successfulPage,
        errorPage,
        this.subsType
      ).then((res) => res.data);

      this.$modal.show(
        Payment,
        {
          present: this.present,
          url: data.url,
          id_transaction: data.id_transaction,
          type: "subs",
          reqType: this.subsType,
          ended: () => {
            this.$store.commit("playlist/reset");
            this.$store.dispatch("user/updateData");
            this.$router.push("/");
            this.$modal.hide(Payment);
          },
        },
        {
          clickToClose: false,
          adaptive: true,
          height: "90%",
          width: "90%",
        }
      );
    },
    async subscribeSched() {
      if (this.present == false) {
        let type = this.subsType;
        switch (type) {
          case "month":
            this.subsType = "month";
            break;
          case "six":
            this.subsType = "six";
            break;
          case "games":
            this.subsType = "games";
            break;
          case "month_present":
            this.subsType = "month";
            break;
          case "six_present":
            this.subsType = "six";
            break;
          case "games_present":
            this.subsType = "games";
            break;
          default:
            break;
        }
        let successfulPage = window.location.origin + "/#/successfulPayment"; //"/#/successfulPaymentPresent";
        let errorPage = window.location.origin + "/#/errorPayment";
        const data = await SubscribeAPI.subscribe(
          successfulPage,
          errorPage,
          this.subsType
        ).then((res) => res.data);

        this.$modal.show(
          Payment,
          {
            url: data.url,
            id_transaction: data.id_transaction,
            type: "subs",
            reqType: this.subsType,
            ended: () => {
              this.$store.commit("playlist/reset");
              this.$store.dispatch("user/updateData");
              this.$router.push("/");
              this.$modal.hide(Payment);
            },
          },
          {
            clickToClose: false,
            adaptive: true,
            height: "90%",
            width: "90%",
          }
        );
      } else {
        let type = this.subsType;
        switch (type) {
          case "month":
            this.subsType = "month_present";
            break;
          case "six":
            this.subsType = "six_present";
            break;
          case "games":
            this.subsType = "games_present";
            break;
          case "month_present":
            this.subsType = "month_present";
            break;
          case "six_present":
            this.subsType = "six_present";
            break;
          case "games_present":
            this.subsType = "games_present";
            break;
          default:
            break;
        }
        // this.$router.push({name: 'subsPlans-present', params: {type: this.reqType}})
        this.$router.push({
          name: "subsPlans-present",
          params: { type: this.subsType },
        });
        // this.$store.commit('user/setEmail',null);
        //   this.$modal.show(
        //   PaymentPresentVue,
        //   {},
        //   {
        //     clickToClose:false,
        //     adaptive: true,
        //     height: "200px",
        //     width: "345px",
        //   }
        // );
        this.subscribeSchedPresent();
      }
    },
    unsubcribe() {
      this.$store.commit("modals");
    },
    cancel() {
      this.$store.commit("modals/confirm/open", {
        title:
          this.$ml.get("האם אתה בטוח שאתה מעוניין") +
          "\n" +
          this.$ml.get("לבטל את המנוי") +
          "?",
        status: "false",
      });
    },
  },
  components: {
    Btn,
    Confirm,
    SimpleHeader,
    SidebarToggler,
    CustomSelect,
    AppBtn,
  },
  computed: {
    ...mapGetters({
      prices: "user/getPrices",
      settingsPlaylist: "playlist/settings",
      settingsShootlist: "shootlist/settings",
      email: "user/getEmail",
      playlistCounter: "user/getPlaylistCounter",
      shootlistCounter: "user/getShootlistCounter",
      isDefaultUser: "user/getDefaultUser",
      playlistDefaultCount: "userDefault/getDefaultUserTotalPL",
      playlistDefaultCurrentCount: "userDefault/getDefaultUserCurrentPL",
      shootlistDefaultCount: "userDefault/getDefaultUserTotalSL",
      shootlistDefaultCurrentCount: "userDefault/getDefaultUserCurrentSL",
    }),
    user() {
      return this.$store.getters["user/data"];
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
    school() {
      return this.$store.getters["user/school"];
    },
    disabledBtn() {
      if (this.class_name == "כיתה" || this.class_num == "מספר") {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    let simple_temp = !this.$store.getters["user/getSub"];
    this.simple = simple_temp;
    this.simpleSec = simple_temp;
    this.$store.commit("user/setSub", false);
    await SubscribeAPI.checkExists().then((res) => {
      this.is_subscribe = res.data.data;
    });
    if (this.$store.getters["user/data"].class != null) {
      this.optName = this.$store.getters["user/data"].class;
    }
    if (this.$store.getters["user/data"].class_number != null) {
      this.optNum = this.$store.getters["user/data"].class_number;
    }
    if (this.$store.getters["user/data"].id_promo != null) {
      try {
        AuthAPI.checkCouponBy({
          id: this.$store.getters["user/data"].id_promo,
        }).then((res) => {
          if (res.data.success == true) {
            this.brnPresent = false;
            if (res.data.data.id_school == null) {
              this.couponData = res.data.data;
              this.promo.first = false;
              this.promo.second = false;
              this.promo.third = true;
              this.couponError = false;
              this.loadingCoupon = false;
            } else {
              this.promo.first = false;
              this.promo.second = true;
              this.is_school = true;
              this.promo.third = false;
              this.loadingCoupon = false;
              this.couponData = res.data.data;
              this.couponDataRR = res.data.data;
              this.couponError = false;
            }
          } else {
            this.loadingCoupon = false;
            this.couponError = true;
            this.$notify({
              title: "שְׁגִיאָה",
              text: "קופון לא נמצא",
              type: "error",
              duration: 10000,
            });
          }
        });
      } catch (err) {}
    } else {
      this.promo.first = true;
    }
    let arrLet = [];
    let arrNum = [];
    arrLet.push(this.$store.getters["user/data"].class);
    this.letters = arrLet;
    arrNum.push(this.$store.getters["user/data"].class_number);
    this.numbers = arrNum;
  },
};
</script>
<style lang="scss" scoped>
.cancel {
  width: 99px;
  height: 19px;
  position: absolute;
  top: 40px;
  left: 27px;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #149cc6;
}
.bottom-text {
  left: 12px;
  bottom: 14px;
  text-align: end;
  position: absolute;
  font-family: "App";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #669eaf;
}
.bottom-span {
  width: 100px;
  left: 0;
  right: 0;
  margin: auto;
  top: 29px;
  position: absolute;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #669eaf;
}
$color1: #f4f4f4;
$color2: #00465b;
.radio {
  // margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken(#56a5c7, 1%);
        display: inline-block;
        width: 12px;
        height: 12px;
        position: relative;
        // top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 2px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.buttom-text {
  margin-top: 16px;
  text-align: center;
  font-family: "Kartiv";
  // font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */

  text-align: center;

  color: #00465b;
}
.btn-a {
  width: 355px;
  height: 48px;

  background: #a9ddf3 !important;
  border-radius: 24px;
  margin: auto;
  left: 0;
  right: 0;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #00465b;

  &.disabled {
    background: #c4c4c4 !important;
    color: #4d4d4d;
  }
}

.btn-g {
  width: 355px;
  height: 48px;
  background: #00465b !important;
  border-radius: 24px;
  margin: auto;
  margin-top: 26px;
  left: 0;
  right: 0;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  &.disabled {
    background: #c4c4c4 !important;
    color: #4d4d4d;
  }
}
.btn-b {
  width: 355px;
  height: 48px;
  background: #fdc671 !important;
  border-radius: 24px;
  margin: auto;
  margin-top: 20px;
  left: 0;
  right: 0;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #00465b;

  &.disabled {
    background: #c4c4c4 !important;
    color: #4d4d4d;
  }
}

.btn-c {
  width: 355px;
  height: 48px;
  background: #ff7384 !important;
  border-radius: 24px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  left: 0;
  right: 0;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #00465b;

  &.disabled {
    background: #c4c4c4 !important;
    color: #4d4d4d;
  }
}
.coupon-block-second {
  position: relative;
  margin: auto;
  width: 100%;
  height: 210px;
  background: #00465b;
  padding-top: 10px;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .school-info {
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #ffffff;
  }
  .img-coupon {
    width: 54px !important;
    height: 54px !important;
    background: #d9d9d9;
    border-radius: 50%;
    margin-top: 7px;
  }
  .coupon-selects {
    width: 122px;
    height: 48px;

    background: #ffffff;
    border-radius: 4px;
  }
  .header {
    margin-top: 13px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #8cc63f;
  }
}
.promo-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #000000;
  svg {
    position: absolute;
    top: -33px;
    right: 103px;
  }
}
.test ::v-deep .v-input__slot::before {
  border-style: none;
}
.test ::v-deep .v-input__icon .mdi-check {
  color: #8cc63f;
}
.test ::v-deep .v-input__icon .mdi-close {
  color: #e41e1e;
}
.test ::v-deep .v-input__slot::after {
  border-style: none;
}
.test ::v-deep .v-label {
  // margin-top: 2px;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
}
.test {
  padding-top: 0px !important;
  margin-top: 0px !important;
  width: 250px;
}
.test ::v-deep .v-text-field__slot input {
  text-align: center;
}
.test::v-deep .v-input__slot {
  background: #fff;
  border: 1px solid #06364b !important;
  border-radius: 18px;
  padding: 0px 15px;
  margin-bottom: 0px !important;
}
.test::v-deep .v-text-field__details {
  display: none;
}
.test::v-deep .v-label--active {
  display: none;
}
.test::v-deep .v-label {
  text-align: center;
}
.coupon-block {
  background: transparent !important;
  padding: 1px 17px 0px 12px;
  // margin: 6px 7px 10px 7px;
  margin: auto;
  margin-bottom: 40px;
  // height: 60px;
  width: 280px;
  background: #fff;
  border-radius: 4px;
}
.btn-block {
  width: 100%;
  height: 26px;
  .reg-b {
    width: 250px;
    height: 36px;

    background: #fbb03b;
    border-radius: 18px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
.buttons-block {
  text-align: center;
  width: 90px;
  height: 24px;
  margin: auto;
  left: 0;
  top: 44px;
  right: 0;
  background: #fdc671;
  border-radius: 2px;
  .buttons-sale {
    margin: auto;
    left: 0;
    right: 0;
    top: 2px;
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    text-align: center;

    color: #00465b;
  }
}
.span-plans {
  margin-top: 26px;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  color: #00465b;
}
.positions-list {
  margin-bottom: 30px;
  margin-top: 10px;
}
.column {
  span {
    font-family: "App";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: right;

    color: #00465b;
  }
}
.disc {
  background: #ffffff;
  box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.15);
  border-radius: 10px;
  margin: 16px 10px;
  padding: 5px;
  height: 67px;
  width: 100%;

  .bordered {
    padding: 13px 10px;
    // border: 1px solid #fff;
    display: flex;
    position: relative;

    align-items: flex-start;

    color: #00465b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-size: 14px;
      line-height: 19px;
      color: #149cc6;
    }
    p {
      &.gray {
        color: #4d4d4d;
      }
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #00465b;
      margin: 0;
    }
    &.no-subs {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      a {
        font-size: 14px;
        line-height: 19px;
        color: #149cc6;
      }
    }
  }
}
.new-span {
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #00465b;
}
.plan:hover {
  cursor: pointer;
}
.success-alert {
  color: #8cc63f !important;
}
.school-inputs {
  display: inline-block;
}
.no-subs {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  /* identical to box height */

  text-align: right !important;

  color: #ff7384 !important;
}
.alert {
  color: #ff7384 !important;
}
.alert-top {
  color: #ff7384 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;
}
.title-list {
  list-style: none;
  text-align: right;
}
.title-element {
  padding-bottom: 9px;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #ff7384;
}
.title-element::before {
  content: "* ";
}
.popular-label {
  position: absolute;
  border-bottom: 18px solid #ff7384;
  border-left: 19px solid transparent;
  border-right: 18px solid transparent;
  height: 0;
  width: 74px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 9px;
  left: -20px;
  span {
    font-family: "Kartiv";
    font-style: normal;
    font-weight: 100;
    font-size: 11px;
    line-height: 10px;
    position: absolute;
    /* line-height: 10px; */
    text-align: center;
    color: #ffffff;
    width: 100px;
    top: 3px;
    left: -32px;
  }
}
.unsubed-btn-block {
  background: #ddf0f5;
  width: 100%;
  min-height: 100px;
  max-height: 100px;
  height: 100%;
}
.coupons {
  background: #00465b;
  width: 100%;
  height: 100%;
  text-align: center;
  .coupons-label {
    text-align: center;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;

      color: white;
    }
  }

  .coupon-input {
    text-align: center;
    width: 180px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #00465b;
    border-radius: 18px;
  }
  .coupons-btn {
    button {
      text-align: center;
      width: 180px;
      height: 36px;
      background: #00465b;
      border-radius: 18px;
      color: #fff;
    }
    button:disabled,
    button[disabled] {
      border: 1px solid #cccccc;
      background-color: #cccccc;
      color: #fff;
    }
  }
  .p-2 {
    padding: 20px 10px 5px 10px;
  }
  .mb {
    margin-bottom: 10px;
  }
}
.positions {
  background: #ffffff;
  // margin: 10px 26px 0px 10px;
  text-align: center;
  .positions-title {
    font-family: "Kartiv";
    //font-style: italic;
    font-weight: 700;
    font-size: 28px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #ff7384;
  }
  .title-last {
    margin-bottom: 0px;
  }
  .title-link {
    position: absolute;
    top: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    right: 58px;
    color: #149cc6;
  }
  .block-left {
    text-align: center;
    display: inline-block;
    width: 10%;
    img {
      width: 36px;
      margin-top: 8px;
    }
  }
  .block-right {
    text-align: right;
    display: inline-block;
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 0 20px;
    /* identical to box height */

    color: #06364b;

    span {
      position: absolute;
      top: 14px;
      right: 58px;
      font-family: "App";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: right;

      color: #00465b;
    }
  }
  .block {
    margin: 0px 30px 0px 0px;
    position: relative;
    height: 46px;
    // padding: 10px 16px 0px 10px;
  }
  .blocks-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    position: relative;
    min-height: 192px !important;
    width: 375px;
    max-width: 375px;
    margin: auto;
    margin-top: 16px;
    left: 0;
    right: 0;
    .block-plans-footer {
      position: absolute;
      top: 187px;
      left: 13px;
      /* padding: 20px 20px 0px 0px; */
      font-family: "App";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #669eaf;
      .block-plans-footer-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;

        color: #669eaf;
      }
    }
    .popular {
      position: relative;
      // transform: scale(1.2);
      border: 2px solid #ff7384 !important;
    }

    .plan {
      max-width: 114px;
      max-height: 170px;
      min-width: 114px;
      min-height: 170px;
      position: relative;
      background: #ffffff;
      border-radius: 6px;

      &.active {
        border: 2px solid #06364b;
        border-bottom: 4px solid #06364b;
      }

      .radio-btn {
        position: absolute;
        top: 6px;
        right: 6px;
      }
      .plan-header {
        font-family: "App";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-top: 30px;
        text-align: center;
        color: #00465b;
      }

      .under-header {
        min-height: 16px;
        margin-top: 5px;
        font-family: "App";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        color: #00465b;
      }

      .prices-block {
        margin-top: 24px;
        font-family: "App";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;

        color: #00465b;
      }
    }
  }
}
.items-wrapper {
  .info {
    margin-top: 14px;
    .sent {
      font-size: 12px;
      line-height: 16px;
      text-align: right;

      color: #787878;
    }
  }
}
.footer {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .purchase-b {
    margin: 10px auto;
    background: #fdc671;
    border-radius: 18px;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
}
.cpn-inpt::placeholder {
  font-family: "App";
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  color: #c4c4c4;
}
</style>
