<template>
  <div class="main">
    <div class="main-wrapper sm-cont">
      <MainHeader :main="true"  />
      <MainBody class="mainbody" />

      <div class="foot-w">
      <div class="footer" v-if="position!=='parent'"  >
          <MainFooter
            :position="position"
            :isProfteacher="isProfteacher" />
      </div>
      </div>
      <InventedGames/>
      <img class="color-img" src="@/assets/img/main-page/bck.png" alt="">
      <!-- <img class="bg-img" src="@/assets/img/main-page/method-draw-image.svg" alt=""> -->
    </div>
  </div>
</template>

<script>
import Tutorial from "@/components/modals/Tutorial";

import MainHeader from '../components/main/MainHeader'
import MainBody from '../components/main/MainBody'
import MainFooter from '../components/main/MainFooter'
// import PlaylistComments from "@/components/modals/PlaylistComments";
import InventedGames from "@/components/modals/modal/InventedGames";
import {ShootlistAPI, UserAPI} from "../api";
import { mapGetters } from 'vuex';
export default {
  data() {
    return {

    };
  },
  created() {
    window.fbq("track", "דף בית מורים");
    if (this.$store.getters['modals/inventedGames/data']){
      if (this.$store.getters['modals/inventedGames/data'].new_playlists.length || this.$store.getters['modals/inventedGames/data'].new_shootlists.length)
      this.$store.commit('modals/inventedGames/open');
    }
  },
  computed:{
    ...mapGetters({
      counter: 'content-counter/data'
    }),
    position(){
      return this.$store.getters['user/position'];
    },
    isProfteacher(){
      return this.$store.getters['user/isProfTeacher'];
    }
  },
  methods: {
    openTutorial() {
      this.$modal.show(
        Tutorial,
        {},
        {
          height: "auto",
          width: "90%",
          height:"100%"
        }
      );
    },
  },
  components: {
    MainHeader,
    MainBody,
    MainFooter,
    InventedGames,
  },
  mounted() {
    // this.$store.dispatch('shootlist/getState');
    // axios.get('/user/fillTranslationColumn')
    // this.$store.commit('modals/error/open');
    // if(this.$route.params.attent) {
    //   setTimeout(() => {
    //     this.$attent('#playlist_plate');
    //   }, 1000);
    // }
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  position: relative;
  flex-grow: 1;
  background: rgb(0, 93, 121);
  color: #fff;
  overflow: hidden;
  .main-wrapper{
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    .mainbody{
      // margin: 20px 0;

      @media screen and (min-height: 860px) {
        // margin: 20px 0px 100px 0;
      }
    }
    .footer{
      width: 100%;
    }
    .bg-img{
      @media screen and (min-width: 600px) {
        border-left:2px solid #005D79;
        border-right:2px solid #005D79;
        border-radius: 3px;
      }

      left:0;
      right:0;
      height: 100%;
      top:0;
      bottom:0;
      mix-blend-mode: overlay;
      z-index: -1;
      width: 100%;
      opacity: 0.5;
      position: absolute;
      background-color: unset;
      object-fit: cover;
    }
    .color-img{

      left:0;
      height: 100%;
      right:0;
      top:0;
      bottom:0;
      z-index: -1;
      width: 100%;
      position: absolute;
      background-color: unset;
      object-fit: cover;
    }
  }
}
</style>
