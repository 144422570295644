<template>
  <main class="main-search">
    <simple-header route="/main">
      <span slot="title">חיפוש משחק</span>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </simple-header>
    <app-loading  style="position: relative;"   class="sm-cont">
      <template style="position: relative;     position: relative;
    max-height: calc(100vh - 51px);
    overflow: hidden;">
    <div style="z-index: 20; background: #fff;" class="search">
      <button @click="goTo" class="filter">
        <!-- <svg :class="{'open' :flag}" class="arrow" width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5.65625L6.65685 11.3131L12.3137 5.65625" stroke="#005D79" stroke-width="2"/>
        </svg> -->

        <div class="sear">
          {{$ml.get("התאמת משחקים")}}
        </div>
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="2.5" x2="17.5" y2="2.5" stroke="white" stroke-linecap="round"/>
        <line x1="0.5" y1="8.5" x2="17.5" y2="8.5" stroke="white" stroke-linecap="round"/>
        <line x1="0.5" y1="14.5" x2="17.5" y2="14.5" stroke="white" stroke-linecap="round"/>
        <circle cx="4.5" cy="2.5" r="2" fill="#005D7A" stroke="white"/>
        <circle cx="13.5" cy="8.5" r="2" fill="#005D7A" stroke="white"/>
        <circle cx="8.5" cy="14.5" r="2" fill="#005D7A" stroke="white"/>
        </svg>

      </button>

      <Search

        @input="onSearch"
        v-model="query"
        :placehold="$ml.get('חיפוש משחק')"
      />
    </div>
    <div style="background: #fff;width: 100%; padding: 0 10px; font-family: 'App';font-style: normal;font-weight: 400;font-size: 14px;text-align: right;color: #00465B;">
       <div style="background: #F2F2F2;border-radius: 4px;padding: 8px 11px;"> 
        <b>{{ $ml.get('הבחירות שלך:')+" " }}</b>
        <span v-if="selected.section=='child'"><span>{{ $ml.get('ילדים')+' - ' }}</span><span v-for="(item,index) in list" :key="index"><NameTranslated class="title" :translate="item.translation"/><span v-if="index!=list.length-1">{{', '}}</span><span v-else>{{'. '}}</span></span></span>
        <span v-else-if="selected.section=='adult'"><span>{{ $ml.get('נוער ובוגרים')+' - ' }}</span><span v-for="(item,index) in list" :key="index"><NameTranslated class="title" :translate="item.translation"/><span v-if="index!=list.length-1">{{', '}}</span><span v-else>{{'. '}}</span></span></span>
        <span v-else-if="selected.section=='family'"><span>{{ $ml.get('משפחה')+' - ' }}</span><span v-for="(item,index) in list" :key="index"><NameTranslated class="title" :translate="item.translation"/><span v-if="index!=list.length-1">{{', '}}</span><span v-else>{{'. '}}</span></span></span>
        </div>
      </div>
      <div style="background: #fff;width: 100%; padding: 0px 20px 5px 20px;; text-align: center;">
        <span style="position: relative;margin-left: 35px;" class="selected-span" >{{ $ml.get('משחקים שמצאנו לבחירות שלך:') }}<div class="counter" id="count">{{counterNum}}</div></span>
      </div>
    
      <!-- <FilterSearch
          @input="change"
          :open="true"
          v-if="flag"
        /> -->
        <div class="games" v-if="data.length>0">
      <SearchedGame
        :checked="checked(item.id_playlist)"
        :key="i+4"
        :item="item"
        v-for="(item,i) in data" />
    </div>

      <app-loading style="height: 100px;min-height: 100px;max-height: 100px;" v-if="loading && data.length<=0">
        <div class="games" style="width: 100% !important;padding: 2px !important; min-height: calc(100vh - 197px);">
          <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

      </app-loading>
      <div class="games" v-else-if="!loading && data.length<=0">
        <!-- <FilterSearch
            @input="change"
            :open="true"
            v-if="flag"
          />
          <div style="width: 100%; text-align: center;">
            {{ $ml.get('לא נמצאו תוצאות לחיפש שלך') }}
          </div> -->
      </div>
    
      </template>
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import SearchedGame from "@/components/search/SearchedGame";
import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
import Search from "@/components/playlist/playlist-requests/Search";
import FilterSearch from "@/components/search/FilterSearch";
import NameTranslated from "../../components/director/director-reports/NameTranslated";
import { throws } from "assert";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      testData:[1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
      data: [],
      query: "",
      flag: false,
      filter:{

      },
      timeout:null,
      list:[],
      per_page:45,
      loading: false,
      ended: false,
      page: 0,
      selected:{
        section:['child'],
        played:['Yes', 'No'],
        locations: [],
        levels: [1,2,3],
      },
      counterNum:0,
      isRunning:false,
      intervalId: null,
    };
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    SearchedGame,
    Search,
    FilterSearch,
    GiftElementSkeleton,
    NameTranslated
  },
  
  computed:{
    ...mapGetters({
      counters: "counter-game/data",
      sett: "playlist/settings",
    }),
    settings(){
      return this.$store.getters['playlist/settings'].viewed_playlist;
    }
  },
  methods: {
    goTo(){
      this.$router.push({name: 'playlist-options'})
    },
    countersRun(){
      let count = this.counters;
      let timeout = 50;

      if(count>50 && count<=100)
      {
        timeout = 30;
      }
      else if(count>100 && count<=500)
      {
        timeout = 10;
      }
      else if(count>500)
      {
        timeout = 5;
      }

      
      if (this.isRunning) {
        clearInterval(this.intervalId);
        this.counterNum=0;
        this.intervalId = setInterval(() => {
          if(count>0)
          {
          this.counterNum++;
          }
          if(this.counterNum==count)
          {
            clearInterval(this.intervalId);
          }
        }, timeout);
      } else {
        clearInterval(this.intervalId);
        this.counterNum=0;
        this.intervalId = setInterval(() => {
          if(count>0)
          {
          this.counterNum++;
          }
          if(this.counterNum==count)
          {
            clearInterval(this.intervalId);
          }
        }, timeout);
      }
      this.isRunning = !this.isRunning;
    },
    updateList(){
      let arr = [];
      this.$store.getters['playlist-locations/data'].forEach(item=>{
        if(this.selected.locations.includes(item.id_setting))
        {
          arr.push(item)
        }
      });
      this.list = arr;
    },
    changeFlag(){
      this.flag=!this.flag;
      if(this.flag==true)
      {
        $(".games").scrollTop(0);
      }
    },
    change(data){
      this.flag=!this.flag;
      this.filter =data;
      this.page = 0;
      this.syncUpdate();
      this.$store.commit('counter-game/clearData');
      let arrChild  = [];
      let arrAdult  = [];
      let arrFamily  = [];
      if(data.section.includes('child'))
      {
        this.$store.getters['playlist-locations/data'].forEach(item=>{
          if(item.type == 'child')
          {
            if(data.locations.includes(item.id_setting))
            {
              arrChild.push(item.name)
            }
          }
        });
      }
      if(data.section.includes('adult'))
      {
        this.$store.getters['playlist-locations/data'].forEach(item=>{
          if(item.type == 'adult')
          {
            if(data.locations.includes(item.id_setting))
            {
              arrAdult.push(item.name)
            }
          }
        });
      }
      if(data.section.includes('family'))
      {
        this.$store.getters['playlist-locations/data'].forEach(item=>{
          if(item.type == 'family')
          {
            if(data.locations.includes(item.id_setting))
            {
              arrFamily.push(item.name)
            }
          }
        });
      }
      this.list = arrChild;
      this.listAdult = arrAdult;
      this.listFamily = arrFamily;
    },
    clear(){
      this.query = '';
      this.filter = '';
    },
    syncUpdate(){
      let obj = {
        query: this.query,
        filter: this.filter,
      };

      this.$store.commit('search-store/saveData',obj);
      axios
          .post("/playlist/search", {
            str: this.query,
            filters: this.filter,
            page: this.page,
            per_page: this.per_page
          })
          .then(res => {
            this.loading = false;
              if(res.data.locationIndex==true)
              {
                this.data = res.data.result.sort((a, b) => (a.index != null && a.index != 0 ? a.index : Infinity) - (b.index != null && b.index != 0  ? b.index : Infinity));
              }
              else
              {
                this.data = res.data.result;
              }
              this.$store.commit('counter-game/saveData',res.data.total)
              this.countersRun();
          });
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    checked(id){
      return this.settings.find((n)=>{return n==id})
    },
    sync(update = false) {
      this.loading = true;
      let obj = {
        query: this.query,
        filter: this.filter,
      };

      this.$store.commit('search-store/saveData',obj);
      axios
        .post("/playlist/search", {
          str: this.query,
          filters: this.filter,
          page: this.page,
          per_page: this.per_page
        })
        .then(res => {
          
          this.loading = false;
          if (update) {
            if(res.data.locationIndex==true)
            {
              this.data = res.data.result.sort((a, b) => (a.index != null && a.index != 0 ? a.index : Infinity) - (b.index != null && b.index != 0 ? b.index : Infinity));
            }
            else
            {
              this.data = res.data.result;
            }
            this.$store.commit('counter-game/saveData',res.data.total)
            this.countersRun();
          } else {
            if(res.data.locationIndex==true)
            {
              this.data.push(...res.data.result.sort((a, b) => (a.index != null && a.index != 0 ? a.index : Infinity) - (b.index != null && b.index != 0 ? b.index : Infinity)));
            }
            else
            {
              this.data.push(...res.data.result);
            }
            this.$store.commit('counter-game/saveData',res.data.total)
            // this.countersRun();
          }
          this.ended = res.data.ended;
        });
    },
    onSearch() {
      this.page = 0;
      this.sync(true);
    },
    
    onScroll() {
      let div = $('.games');
         let div_sh = $(div)[0].scrollHeight;
         let div_h = div.height();
         let div_t = div.scrollTop();
      if(div_t >= div_sh - div_h-20)
      {
        this.page++;
        this.sync();
      }      
    }
  },
  async created() {
    if(!this.$store.getters['playlist/isSettings']){
      this.loading = true;
      await this.$store.dispatch('playlist/loadState');
    }
    if(!this.$store.getters['shootlist/isSettings']){
      this.loading = true;
      await this.$store.dispatch('shootlist/getState');;
    }
    this.$store.commit('counter-game/clearData');
    if (!Object.keys(this.sett).length) {
      let setts = {
        gender: "child",
        activities: ["1", "2", "3"],
        locations: this.$store.getters["playlist-locations/getByType"]("child")
          .filter((item) => item.is_default)
          .map((item) => {
            return item.id_setting;
          }),
        show_teacher_favourite: false,
        show_school_inventors: false,
        show_director_favorite: false,
        levels: [3, 2, 1],
        seen:[1,2]
      };
      if (
        store.getters["user/data"].position !== null &&
        store.getters["user/data"].position !== "director"
      ) {
        setts.show_teacher_favourite = true;
        setts.show_school_inventors = true;
        setts.show_director_favorite = true;
      } else {
        setts.show_teacher_favourite = false;
        setts.show_school_inventors = false;
        setts.show_director_favorite = false;
      }
      this.$store.commit("playlist/setSettings", setts);
    }
    this.selected.life_skills = _.cloneDeep(this.sett.life_skills)|| false;
    this.selected.section = _.cloneDeep(this.sett.section) || [];
    this.selected.locations = _.cloneDeep(this.sett.locations) || [];
    this.selected.levels = _.cloneDeep(this.sett.levels) || [];
    let arr = [];
    if(this.sett.seen.includes(1))
    {
     arr.push('Yes');
    }
    if(this.sett.seen.includes(2))
    {
      arr.push('No');
    }
    this.selected.played = arr;
    if (this.selected.section.length === 0) {
      if(this.sectionsPromo!=null)
      {
        if(this.sectionsPromo.sections!=null && this.sectionsPromo.sections.length>0)
        {
          this.selected.section = this.sectionsPromo.sections[0];
        }
        else
        {
        this.selected.section = "child";
        }
      }
      else
      {
        this.selected.section = "child";
      }
    }

    this.filter = this.selected;
    window.fbq("track", "חיפוש");
    
    this.sync(true);
    this.updateList();
    document.addEventListener("scroll", this.onScroll, true)
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll);
  }
};
</script>

<style lang="scss" scoped>
.counter{
  position: absolute;
  width: 40px !important;
  height: 24px !important;
  border-radius: 12px;
  font-family: 'App';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  top: -3px;
  left: -34px;
  text-align: center;
  padding: 2px;
  color: #00465B;
}
.selected-span{
  font-family: 'App';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 0px;
text-align: right;

color: #00465B;
}
.circle {
     min-height: 115px;
     max-height: 115px;
     min-width: 84px;
     max-width: 84px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  
  position: absolute;
     height: 12px;
     left: 22px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:60% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
  border-radius: 4px !important;
     height: 115px !important;
     width: 84px !important;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
  .app-loading{
    flex-grow: 1;
    min-height: 450px;
    background: #F2F2F2;
  }
main {
  background: #005d79;
  .games{
    background: #F2F2F2;
    max-height: calc(100vh - 197px);
    // min-height: calc(100vh - 136px);
    overflow-y: auto;
    padding: 0 4px;
    margin: 0 auto;
    width: 400px;
    padding: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 420px){
      width: 100%;
      padding: 8px !important;
    }
  }
  .search {
    padding: 22px 10px 16px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 420px){
      // margin: 0 0 32px 0;
    }
    @media screen and (max-width: 400px){
      // margin: 0 0 10px 0;
      padding: 22px 12px 16px 12px;
    }
    .search-i{
      width: 200px;
      @media screen and (max-width: 357px){
        width: 150px;
        ::v-deep {
          input{
            width: 150px;
          }
        }
      }
    }
    .filter{
      width: 190px;
      height: 30px;
      margin-top: 3px;
      background: #005D7A;
      border-radius: 15px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: center;
      .arrow{
        transition: all 0.3s ease-in-out;
        &.open{
          margin-top: 5px;
          transform: rotateX(180deg);

        }
      }
      .sear{
        margin: 0 7px;
        font-family: 'App';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        text-align: right;

        color: #FFFFFF;

      }
    }
  }
.empty{

  margin:0 auto;
  .searched{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #4D4D4D;
    .search-v{
      color: #00465B;
    }
  }

.under-search{
  margin-top: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #4D4D4D;
  .link{
    color: #1A92B8;
  }
}
}

}
</style>
