<template>
  <div class="shootlist-element">
    <div  class="game-i">
       <div  class="name">{{item.content_man}}</div>
    </div>
    <div class="author-i">
      <img :src="item.author.avatar" alt="">
      <div class="name-w">
        <div class="name">{{item.author.name +' '+ (item.author.surname ? item.author.surname : '') }}</div>
        <div class="school">{{item.author.class+''+ item.author.class_number}}</div>

      </div>
    </div>

  </div>
</template>

<script>
  import {DirectorAPI} from "@/api";

  export default {
    props:{
      item: {},
      isDirector:{
        default: false,
      },
    },
    created() {

    },
    computed:{
      getName(){
        if (this.$store.getters["user/data"].gender == "fem") {
          return this.item.content_fem;
        } else {
          return this.item.content_man;
        }
      },
      user() {
        return this.$store.getters['user/data'];
      },
    },
    data(){
      return{
        value: 'כולם',
        filter:[
          {name: 'כולם', value: 'all'},
          {name: 'א\'-ג\'', value: 'child'},
          {name: 'ד\'-ו\'', value: 'adult'},
        ]
      }
    },

    methods:{
      go(){
        this.$router.push({
          name: "shootlist",
          params: {
            id_shootlist: this.item.id_shootlist
          }
        });
      },

      getType(val){
        if (val==='ד\'-ו\''){
          return 'adult';
        } else if(val==='א\'-ג\''){
          return 'child'
        } else {
          return 'all'
        }
      },

      deleteGame(){
        this.$store.commit('modals/deleteLiked/open', {title: 'shootlist', id: this.item.id_shootlist })
      }
    }
  };
</script>

<style lang="scss" scoped>
  //
  .shootlist-element{
    padding: 7px 10px 7px 10px;
    height: 70px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l-options{
      display: flex;
      flex-direction: row;
      align-items: center;
      .select-filter{
        margin-left: 15px;
        border: none;
      }
    }
    .icon{
      margin-left: 10px;
    }
    .game-i{
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      img{
        width: 51px;
        height: 55px;
      }
      .category{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
      .name{
        max-width: 150px;
        color: #00465B;
        font-family: Cool;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 16px;
      }
    }
    .author-i{
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img{
        border-radius: 50%;
        object-fit: contain;
        width: 42px;
        height: 42px;
      }
      .name-w{
        margin-right: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }
      .name{
        font-family: Cool;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #00465B;
      }
      .school{
        font-size: 12px;
        color: #4D4D4D;
      }
    }
    .close-b{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border: 1px solid #F2F2F2;
      border-radius: 15px;
    }
  }
</style>
