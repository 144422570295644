<template>
  <div class="helper-question-mark" :class="{ disabled: disabled }">
    <div class="alert-wrapper" v-if="alert">
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @mouseover="mouse === false ? (flag = true) : ''"
        @mouseleave="mouse === false ? (flag = false) : ''"
      >
        <path
          d="M14.8571 11.96C14.8571 12.298 14.7348 12.5558 14.4901 12.7335C14.2453 12.9112 13.945 13
                13.589 13H1.26813C0.912164 13 0.611817 12.909 0.36709 12.727C0.122363 12.545 0 12.2893 0 11.96C0 11.7 0.0800924 11.4357 0.240277
                11.167L6.39404 0.715C6.68772 0.238333 7.03478 0 7.43525 0C7.83571 0 8.17388 0.238333 8.44975 0.715L14.6169 11.18C14.7771 11.4573
                14.8571 11.7173 14.8571 11.96ZM8.35631 5.317V3.419H6.50083V5.317C6.50083 5.43833 6.50973 5.55317 6.52753 5.6615C6.54533 5.76983 6.5698
                5.89117 6.60095 6.0255C6.6321 6.15983 6.65657 6.27467 6.67437 6.37L7.02143 8.476H7.80901L8.16943 6.37C8.18722 6.28333 8.21392 6.17067
                8.24952 6.032C8.28512 5.89333 8.31181 5.76983 8.32961 5.6615C8.34741 5.55317 8.35631 5.43833 8.35631 5.317ZM8.35631 10.348C8.35631
                10.0967 8.26509 9.88433 8.08266 9.711C7.90023 9.53767 7.67997 9.451 7.4219 9.451C7.17272 9.451 6.95692 9.53767 6.77448 9.711C6.59205
                9.88433 6.50083 10.0967 6.50083 10.348C6.50083 10.5993 6.59205 10.8138 6.77448 10.9915C6.95692 11.1692 7.17272 11.258 7.4219 11.258C7.67997
                11.258 7.90023 11.1692 8.08266 10.9915C8.26509 10.8138 8.35631 10.5993 8.35631 10.348Z"
          fill="#E8282F"
        />
      </svg>

      <div
        class="info-text alert-text"
        v-show="flag"
        :style="{ width: width + 'px' }"
      >
        <p
          class="alert-wrapper-item"
          v-for="(alert, idx) in alertRender"
          :key="idx"
        >
          -
          {{ alert }}
        </p>
      </div>
    </div>
    <!-- <svg
          viewBox="0 0 10 10"
          fill="none"
          class="info-img"
          @click="flag = !flag"
          :style="{ width: size + 'px', height: size + 'px' }"
        >
          <path d="M5.00026 0C2.23843 0 2.71306e-08 2.23843 2.71306e-08 4.99974C-6.83788e-05 5.6564 0.129221 6.30665 0.380483 6.91334C0.631745 7.52003 1.00006 8.07128 1.46439 8.53561C1.92872 8.99994 2.47997 9.36826 3.08666 9.61952C3.69335 9.87078 4.3436 10.0001 5.00026 10C7.76105 10 10 7.76157 10 4.99974C10 2.23843 7.76105 0 5.00026 0ZM5.4669 1.80511C5.95438 1.80511 6.0976 2.08791 6.0976 2.41133C6.0976 2.81496 5.7747 3.18838 5.22317 3.18838C4.76173 3.18838 4.54195 2.95662 4.55549 2.57278C4.55549 2.24936 4.82579 1.80511 5.4669 1.80511ZM4.21801 7.99438C3.88469 7.99438 3.64148 7.79178 3.87428 6.90381L4.25603 5.32837C4.32217 5.0763 4.33311 4.97526 4.25603 4.97526C4.15655 4.97526 3.72376 5.14921 3.46857 5.32108L3.30243 5.0487C4.11229 4.37217 5.04349 3.97531 5.4419 3.97531C5.77522 3.97531 5.83043 4.36904 5.66429 4.97526L5.22681 6.63143C5.14921 6.92412 5.18254 7.02516 5.26014 7.02516C5.36014 7.02516 5.6872 6.90433 6.00906 6.6507L6.19759 6.90381C5.40961 7.69127 4.5508 7.99438 4.21801 7.99438Z" fill="url(#paint0_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7EAD4C"/>
          <stop offset="1" stop-color="#6F9C40"/>
          </linearGradient>
          </defs>
        </svg> -->
    <div v-else>
      <transition name="cool">
        <div class="info-text" v-show="flag" :style="{ width: width + 'px' }">
          {{ title }}
        </div>
      </transition>
      <div
        class="info-img"
        @click="mouseChange()"
        :style="{
          width: size + 'px',
          height: size + 'px',
          fontSize: size * 0.7 + 'px'
        }"
        @mouseover="mouse == false ? (flag = true) : ''"
        @mouseleave="mouse == false ? (flag = false) : ''"
      >
        ?
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      // required: true,
    },
    disabled: {
      required: false,
      default: false
    },
    width: {
      required: false,
      default: 200
    },
    size: {
      required: false,
      default: 20
    },
    alert: {
      type: Boolean,
      default: false
    },
    alerts: {
      type: Object
    }
  },
  data() {
    return {
      flag: false,
      mouse: false,
      alertsArr: []
    };
  },
  methods: {
    mouseChange() {
      if (this.mouse) {
        this.flag = false;
        this.mouse = false;
      } else {
        this.flag = true;
        this.mouse = true;
      }
    },
    check(item) {
      if (item) {
        return this.alertsArr.push(item.Message);
      }
    }
  },
  computed: {
    alertRender() {
      this.check(this.alerts.alerts.AcidAlert.Alert[0]);
      this.check(this.alerts.alerts.FriendlyAlerts.Alert[0]);
      this.check(this.alerts.alerts.N_FormsAlert.Alert[0]);
      return this.alertsArr;
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.alert-wrapper {
  &-img {
    width: 14.86px;
    height: 13px;
  }

  &-item {
    color: #fff;
    font-size: 13px;
  }
}

.helper-question-mark {
  justify-content: center;
  align-items: center;
  cursor: help;
  display: flex;
  margin-left: 12px;
  position: relative;
  box-sizing: border-box;

  &.disabled {
    svg path {
      fill: #ccc;
    }
  }

  svg {
    height: 100%;

    path {
      width: 100%;
      height: 100%;
    }
  }

  .info-img {
    background: #7ead4c;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 13px;
  }

  .info-text {
    position: absolute;
    padding: 10px;
    background: #4c5056;
    color: white;
    border-radius: 5px;
    top: calc(100% + 15px);
    z-index: 20;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-transform: initial;
    font-weight: normal;
    font-size: 16px;

    b {
      font-weight: bold;
    }

    &::before {
      content: "";
      position: absolute;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #4c5056;
      bottom: 100%;
      left: calc(50% - 5px);
    }

    &.cool {
      &-enter {
        transform: translateY(10px);
        opacity: 0;
      }

      &-enter-to {
        transform: translateY(0);
        opacity: 1;
      }

      &-leave {
        transform: translateY(0);
        opacity: 1;
      }

      &-leave-to {
        transform: translateY(10px);
        opacity: 0;
      }

      &-leave-active,
      &-enter-active {
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    }
  }

  .alert-text {
    text-align: left;
  }
}
</style>
