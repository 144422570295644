<template>
  <div class="report-status-element">
    <div class="header">
      <div :style="{color: titleColor}"  class="name">
        <span v-if="!!translate">
         {{translated!=='' ? translated : translate[0].data.name }}
        </span>
        <span v-else >
         {{title }}
        </span>
       <span> {{data.thisYear.total}}</span>
      </div>
      <div class="term">
        <div class="term-element">{{data.oldMonth.total}}/{{data.oldMonth.target}}</div>
        <div class="term-element">{{data.oldYear.total}}/{{data.oldYear.target}}</div>
      </div>
    </div>
    <div class="element-wrapper">
      <div class="row">

        <div class="date-value">{{$ml.get('יעד חודשי')}}: {{data.thisMonth.target}}</div>

        <div class="status">
          <div class="value">{{data.thisMonth.total}}/{{data.thisMonth.target}}</div>
          <div class="status-bar">
            <div :style="{width: data.thisMonth.total/data.thisMonth.target*100+'%',
            background:  data.thisMonth.total/data.thisMonth.target*100 > 100 ? '#FDC671' : '#8CC63F' }" class="bg"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="date-value">{{$ml.get('יעד שנתי')}}: {{data.thisYear.target}}</div>

        <div class="status">
          <div class="value">{{data.thisYear.total}}/{{data.thisYear.target}}</div>
          <div class="status-bar">
            <div :style="{width: data.thisYear.total/data.thisYear.target*100+'%',
            background:  data.thisYear.total/data.thisYear.target*100 > 100 ? '#FDC671' : '#8CC63F' }"
                 class="bg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    props:{
      report:{
        title:{},
        games: {},


      },
      translate:{},
      data:{

      },
      title:{

      },
      titleColor:{
        default: '#00465B'
      }
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.translate.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.name
        }
      },
    },
    data() {
      return {
        flag: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
//
  .report-status-element{
    width: 100%;
    padding: 0 20px;
    .header{
      padding: 15px 0 10px 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .name{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #00465B;
      }
      .term{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 160px;
        padding: 0 20px;
        font-size: 12px;
        text-align: center;
        color: #00465B;
      }
    }
    .element-wrapper{
      padding: 5px 13px;
      border: 1px solid #AAD9E7;
      box-sizing: border-box;
      border-radius: 4px;
      background: white;
      .row{
        margin: 15px 0;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-around;
        .date-value{
          font-size: 14px;
          color: #000000;
        }
        .status{
          display: flex;
          align-items: center;
          flex-direction: column;
          .value{
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }
          .status-bar{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 160px;
            height: 8px;
            background: #C4C4C4;
            border-radius: 4px;
            .bg{
              background: rgb(140, 198, 63);

              height: 100%;
              border-radius: 4px 4px  4px  5px;
            }
          }
        }
      }
    }

  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

