<template>
  <div class="auth">
    <transition :name="transition" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {

  data() {
    return {
      transition: 'fade-move-left'
    }
  },

  watch: {
    '$route': function (to, from) {
      const list = [
        'auth-reg',
        'auth-class',
        'auth-fullname',
        'auth-code',
      ];

      const to_index = list.findIndex(n => n === to.name);
      const from_index = list.findIndex(n => n === from.name);

      if(to_index > from_index) {
        this.transition = 'fade-move-left';
      } else {
        this.transition = 'fade-move-right';
      }
    }
  } 
}
</script>

<style lang="scss" scoped>

.auth {
  flex-grow: 1;
  width: 100%;
  background: #005d7a;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
    @media screen and (600px){
      max-width: 300px;
    }
  }
}

</style>