<template>
  <div class="funkit-basket">
    <router-link :to="{name: 'funkit-basket'}" class="basket-b">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.6L4.2 12C4.1 12.3 4 12.7 4 13C4 14.1 4.9 15 6 15H18V13H6.4C6.3 13 6.2 12.9 6.2 12.8V12.7L7.1 11H14.5C15.3 11 15.9 10.6 16.2 10L19.8 3.5C20 3.3 20 3.2 20 3C20 2.4 19.6 2 19 2H4.2L3.3 0H0ZM16 16C14.9 16 14 16.9 14 18C14 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill="white"/>
      </svg>
        <span>{{$ml.get('לסל הקניות')}}</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "FunkitBasketFixed",

  }
</script>

<style scoped lang="scss">
  .funkit-basket{
    padding: 10px 0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .basket-b{
      width: 180px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #005D79;
      border-radius: 18px;
      span{
        font-size: 15px;
        line-height: 22px;
        /* identical to box height */

        text-align: center;

        color: #FFFFFF;
      }
    }
  }
</style>