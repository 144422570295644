<template>
  <div class="gift-section">

    <div class="head">
      <div class="first-line">
        <!-- <div class="title">
          <span>
         {{$ml.get("כישורי חיים שהמשחק מפתח")}}
          </span>
        </div> -->
        <div class="header-title" style="width: 100%; text-align: center; font-family: 'App';font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;text-align: center;color: #06364B;">
          {{$ml.get('כישורי החיים שהמשחק מפתח')}}
        </div>
        <button style="position: absolute;left: 5px" @click="close" class="close">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="5.05086" y1="5.05071" x2="14.9504" y2="14.9502" stroke="#00465B" stroke-width="2"/>
            <line x1="14.9493" y1="5.04988" x2="5.0498" y2="14.9494" stroke="#00465B" stroke-width="2"/>
          </svg>
        </button>
      </div>

      <div class="sections">
        <div   class="item" v-for="item in sections">
          <div class="color" :style="{ backgroundColor: item.color }"></div>
          <NameTranslated class="name" :translate="item.translation" />
        </div>
      </div>
      <div style="width: 100%; text-align: center;">
        <app-btn class="btn-gift" :class="isDefaultUser?'disabled':'active'" @click="pushTo" style="width: 180px;height: 30px;background: #005D7A;border-radius: 15px;margin: auto; color: white;position: relative; font-family: 'App';font-style: normal;font-weight: 400;font-size: 12px;line-height: 120%;text-align: right;color: #FFFFFF;">
            <span style="margin-right: 15px;">{{ $ml.get('מסלול משחקים אישי') }}</span>
            <svg style="position: absolute; position: absolute;right: 17px;top: 2px;" width="22" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5.14258C4.02891 5.14258 -8.80545e-08 9.17148 -1.96701e-07 14.1426C-3.05348e-07 19.1137 4.02891 23.1426 9 23.1426C13.9711 23.1426 18 19.1137 18 14.1426C18 12.4234 17.5148 10.8133 16.6781 9.4457L15.3562 10.7957C15.8906 11.8012 16.2 12.9262 16.2 14.1426C16.2 18.1187 12.9762 21.3426 9 21.3426C5.02383 21.3426 1.8 18.1187 1.8 14.1426C1.8 10.1664 5.02383 6.94258 9 6.94258C10.227 6.94258 11.366 7.27305 12.375 7.81445L13.6969 6.49258C12.3258 5.64883 10.7297 5.14258 9 5.14258ZM16.65 5.22695L9.45 12.3988C9.30234 12.3602 9.16172 12.3426 9 12.3426C8.00508 12.3426 7.2 13.1477 7.2 14.1426C7.2 15.1375 8.00508 15.9426 9 15.9426C9.99492 15.9426 10.8 15.1375 10.8 14.1426C10.8 13.9809 10.7824 13.8402 10.7437 13.6926L17.9156 6.49258L16.65 5.22695ZM9 8.74258C6.01875 8.74258 3.6 11.1613 3.6 14.1426C3.6 17.1238 6.01875 19.5426 9 19.5426C11.9812 19.5426 14.4 17.1238 14.4 14.1426C14.4 13.4289 14.2594 12.768 14.0062 12.1457L12.5437 13.5801C12.5754 13.7594 12.6 13.9527 12.6 14.1426C12.6 16.1324 10.9898 17.7426 9 17.7426C7.01016 17.7426 5.4 16.1324 5.4 14.1426C5.4 12.1527 7.01016 10.5426 9 10.5426C9.20039 10.5426 9.40078 10.5637 9.59062 10.5988L11.025 9.16445C10.3957 8.9043 9.72422 8.74258 9 8.74258Z" fill="white"/>
            <path d="M22.1428 5.24241H17.9002L15.7788 7.36373H20.0215L22.1428 5.24241Z" fill="white" stroke="white" stroke-linejoin="round"/>
            <path d="M17.9002 0.999773V5.24241L15.7788 7.36373V3.12109L17.9002 0.999773Z" fill="white" stroke="white" stroke-linejoin="round"/>
            </svg>
            <!-- <svg
            style="position: absolute;right: 17px;top: 4px;"
            data-v-ceadb808="" width="21" height="21" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" class="router-svg">
            <path data-v-ceadb808="" d="M20.24 0C9.07984 0 0 9.07984 0 20.24C0 31.4002 9.07984 40.48 20.24 40.48C31.4002 40.48 40.48 31.4002 40.48 20.24C40.48 16.1946 39.2829 12.4251 37.2298 9.25891L36.4444 10.0444L35.9425 10.5463C37.6919 13.3684 38.72 16.6822 38.72 20.24C38.72 30.4304 30.4304 38.72 20.24 38.72C10.0496 38.72 1.76 30.4304 1.76 20.24C1.76 10.0496 10.0496 1.76 20.24 1.76C24.5637 1.76 28.5299 3.27135 31.68 5.76984V7.55563L29.5367 9.69891C27.0548 7.50411 23.8057 6.16 20.24 6.16C12.4766 6.16 6.16 12.4766 6.16 20.24C6.16 28.0034 12.4766 34.32 20.24 34.32C28.0034 34.32 34.32 28.0034 34.32 20.24C34.32 17.3105 33.4185 14.5875 31.8811 12.332L30.6058 13.6073C31.836 15.5249 32.56 17.7971 32.56 20.24C32.56 27.0336 27.0336 32.56 20.24 32.56C13.4464 32.56 7.92 27.0336 7.92 20.24C7.92 13.4464 13.4464 7.92 20.24 7.92C23.3211 7.92 26.1317 9.06553 28.2923 10.9433L25.1711 14.0645C23.8171 12.9785 22.107 12.32 20.24 12.32C15.8734 12.32 12.32 15.8734 12.32 20.24C12.32 24.6066 15.8734 28.16 20.24 28.16C24.6066 28.16 28.16 24.6066 28.16 20.24C28.16 19.0194 27.8742 17.8683 27.3797 16.8352L26.0373 18.1758C26.2679 18.8217 26.4 19.5158 26.4 20.24C26.4 23.6368 23.6368 26.4 20.24 26.4C16.8432 26.4 14.08 23.6368 14.08 20.24C14.08 16.8432 16.8432 14.08 20.24 14.08C21.6216 14.08 22.8933 14.5429 23.9216 15.3141L20.6938 18.5419C20.5458 18.5016 20.3933 18.4808 20.24 18.48C19.7732 18.48 19.3256 18.6654 18.9955 18.9955C18.6654 19.3256 18.48 19.7732 18.48 20.24C18.48 20.7068 18.6654 21.1544 18.9955 21.4845C19.3256 21.8146 19.7732 22 20.24 22C20.7068 22 21.1544 21.8146 21.4845 21.4845C21.8146 21.1544 22 20.7068 22 20.24C21.9999 20.0862 21.9797 19.9331 21.9398 19.7845L32.9244 8.8H35.2L39.6 4.4H36.08V0.88L32.6717 4.28828C29.2379 1.60542 24.9254 0 20.24 0Z" fill="#fff">
            </path></svg> -->
        </app-btn>
      </div>



    </div>
    <div class="list">
      <div class="items">
        <div class="item" v-for="(gift,index) in gifts" :style="
              gift.id_section!=null ? 'backgroundColor:'+$store.getters['playlist-sections/getColorById'](gift.id_section): 'border:1px solid #669EAF'
            " :key="index">
          <!-- <div 
            class="dot"
            :style="{
              backgroundColor: `${$store.getters['playlist-sections/getColorById'](gift.id_section)}`
            }"
          ></div> -->
          <img
            :src="gift.img_url"
            :style="gift.name!=null?gift.name.length>9?'margin-bottom:5px !important':'margin-bottom:15px !important':''"
          />
          <!-- <span>{{ gift.name.length }}</span> -->
          <span>
          <NameTranslated :translate="gift.translation" />
          </span>
        </div>
        <div class="space" style="width: 100%; min-height: 10px !important;">

        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import AppBtn from '@/components/ui/app-btn/AppBtn.vue';
import { mapGetters } from 'vuex'
import NameTranslated from "../NameTranslated";
export default {
  props: {
    data: {}
  },
  components:{
    NameTranslated,
    AppBtn
  },
  methods: {
    pushTo()
    {
      this.$router.push({ name: 'playlist-gifts' });
    },
    close(){
      this.$emit('close');
      
    }
  
  },
  computed: {
    ...mapGetters({
      sections: 'playlist-sections/data',
      isDefaultUser: "user/getDefaultUser",
    }),
    gifts()
    {
      return this.data.categories.filter(x=>x.name!=null);
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-gift{
  &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
}

.gift-section {

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // border-top: 1px solid #AAD9E7;
  // border-bottom: 4px solid #AAD9E7;
  background: #f2f2f2;

  .list {
    flex-grow: 1;
    background: #F2F2F2;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .head {
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    padding: 0px 10px;
    padding-top: 7px;
    margin-bottom: 6px;


    .first-line {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      .link{
        margin-left: 30px;
        a{
          font-size: 12px;
          text-align: right;
          color: #149CC6;
        }
      }
    }

    .sections {
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      margin: 3px 0 8px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 5px;
        width: 108px;

        .name {
          color: #000000;
          font-size: 12px;
        }
        .color {
          margin-left: 5px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }
    }

    .title {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      color: #00465B;
      margin-top: 5px;
      padding-bottom: 5px;
      span {
        font-weight: 500;
        font-size: 14px;
      }

      svg {
        margin-left: 10px;
      }
    }

    .close {
      padding: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      a {
        font-size: 11px;
        color: #149CC6;
      }
    }
  }

  .items {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    // justify-content: space-between;
    min-height: 100px;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    padding-top: 0px;

    .item {
      padding-top: 18px;
        color: black;
      // background: #fff;
      // border: 1px solid #669EAF;
      border-radius: 4px;
      margin: 3px;
      width: 76px;
      height: 94px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      img {
        min-height: 37px;
        height: 37px;
        align-self: center;
        margin-bottom: 8px;
      }

      span {

        text-align: center;
        line-height: 1.2;
        font-size: 12px;
        padding: 0 10px;
      }

      .dot {

        display: flex;
        align-self: flex-end;
        position: relative;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        min-height: 8px;
        margin: 5px;
      }
    }


  }
}

</style>