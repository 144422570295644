import store from '@/store';

const Middleware = {


  isLogged(to, from, next) {
    if(store.getters['user/isLogged']) {
      next();
    } else {
      to.name === 'targeted-playlist' ? next({name: 'auth-reg', params: to.params}) : next({name: 'auth-reg'})
    }
  },

  isTeacher(to, from, next) {
    if(store.getters['user/position']) {
      next();
    } else {
      next('/main');
    }
  },
  // checkPage(to, from, next) {
  //   if(to.name!==null) {
  //     next();
  //   } else {
  //     next('/main');
  //   }
  // },
  isUnLogged(to, from, next) {
    if(!store.getters['user/isLogged']) {
      next();
    } else {
      next('/main');
    }
  }
};

export { Middleware }
