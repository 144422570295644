import {PlaylistAPI} from "@/api";

const PlaylistLocationsStore = {
  namespaced: true,
  state: {
    data: [],
    dataPromo:[],
    loaded: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },

    setDataPromo(state, data) {
      state.dataPromo = data
    },

    load(state) {
      state.loaded = true;
    },
  },
  actions: {
      updateData({ commit }) {
       PlaylistAPI.getAllLocations().then(res => {
            commit('setData', res.data.settings);
            commit('setDataPromo', res.data.promo);
            commit('load')
          });
    }
  },
  getters: {
    data(state) {
    
        return state.data;
    },
    dataPromo(state) {
      return state.dataPromo;
    },
    isLoaded(state) {
      return state.loaded;
    },
    getByType: state => type => {
     let data = state.data ? state.data.filter((item)=> {
        return item.type === type
      }) : [];
      if(state.dataPromo!=null)
      {
          if(state.dataPromo.sections!=null && state.dataPromo.sections.length>0)
          {
            if(state.dataPromo.locations != null && state.dataPromo.locations.length>0)
            {
              let array = [];

              state.dataPromo.locations.forEach(element => {
                array.push(element.id_location);
              });

              return data.filter(x=>array.includes(x.id_setting));
            }
            else
            {
              return data;
            }
        }
        else{
          return data;
        }
      }
      else
      {
        return data;
      }
    },

  }
};

export { PlaylistLocationsStore };
