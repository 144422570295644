<template>
  <div class="inventor-droplist">
    <button class="show-b" :style="(item.playlist.length>0 || item.shootlist.length>0)? '':'pointer-events:none'" @click="changeFlag">
      <span v-if="item.playlist.length>0 || item.shootlist.length>0 " class="icon">
        <svg :style="!flag?
        'transform: rotate(0)' : 'transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div v-if="item.playlist.length>0 || item.shootlist.length>0 " class="terms">

        <div class="term"><span>{{item.playlist.length}}</span> <span class="gray">{{$ml.get("משחקים")}}</span></div>
        <div class="term"><span>{{item.played}}</span> <span class="gray">{{$ml.get("פעמים שוחקו")}}</span></div>
      </div>
      <div v-else :style="direction==='ltr'? 'max-width: 193px;' : ''" class="empty">
        {{$ml.get("עדיין לא הצטרפת")}}
        {{$ml.get("לרשימת ממציאי המשחקים")}}
      </div>
      <div>
      <div style="display: inline-block;">
      <button :style="(item.playlist.length>0 || item.shootlist.length>0) ? '':'pointer-events:none'" @click="changeFlag">
        <img v-if="item.img" :src="item.img" alt="">
        <div v-else class="no-photo">
          <svg enable-background="new 0 0 512.004 512.004" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m433.52 326.58c44.063-3.841 78.483-34.029 78.483-70.583 0-36.555-34.42-66.743-78.483-70.584-17.083-42.921-49.394-78.429-90.567-99.51-1.099-46.983-39.391-85.906-86.95-85.906-47.523 0-85.85 38.88-86.949 85.907-41.174 21.081-73.485 56.59-90.567 99.51-44.064 3.84-78.484 34.028-78.484 70.583 0 36.558 34.425 66.747 78.493 70.584 14.207 35.609 39.197 66.452 70.952 87.912-25.778 20.27-44.952 47.234-54.979 77.833-2.58 7.872 1.711 16.345 9.583 18.925 7.872 2.579 16.345-1.712 18.925-9.583 9.468-28.892 29.034-53.882 55.478-71.09 51.076 22.764 108.9 21.406 157.84-1.26 27.456 17.228 47.723 42.665 57.448 72.349 2.58 7.873 11.051 12.162 18.925 9.584 7.873-2.58 12.164-11.052 9.584-18.925-10.334-31.54-30.275-59.103-57.157-79.532 29.671-20.653 54.171-50.322 68.42-86.214zm48.483-70.583c0 17.523-16.517 32.894-38.914 38.617 5.214-25.362 5.225-51.818 0-77.233 22.397 5.722 38.914 21.093 38.914 38.616zm-226-226c28.873 0 52.8 21.955 56.502 50.321-35.872 3.083-65.589 27.873-75.757 61.366-22.231-7.906-37.745-29.065-37.745-53.688 0-31.98 25.57-57.999 57-57.999zm-226 226c0-17.523 16.517-32.894 38.914-38.617-5.214 25.362-5.225 51.818 0 77.233-22.397-5.722-38.914-21.093-38.914-38.616zm300.08 142.99c-86.299 44.823-193.69 3.849-226.8-91.923-24.054-71.962 5.502-151 70.809-189.68 10.948 30.681 38.599 53.534 72.726 57.126 8.262 0.869 15.65-5.143 16.493-13.393 3.125-30.584 29.847-53.289 61.013-50.936 73.315 34.395 110.51 118.83 84.416 196.88-14.501 42.042-43.611 73.733-78.652 91.924z"/>
            <path
              d="m219.18 238.32c5.858-5.858 5.858-15.355 0-21.213-21.494-21.494-56.285-21.497-77.782 0-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0 9.771-9.77 25.584-9.771 35.355 0 5.858 5.857 15.355 5.858 21.214 0z"/>
            <path
              d="m371.18 217.11c-21.494-21.494-56.285-21.497-77.782 0-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0 9.771-9.77 25.584-9.771 35.355 0 5.857 5.857 15.354 5.859 21.213 0 5.859-5.857 5.859-15.355 1e-3 -21.213z"/>
            <path
              d="m284.99 317.39c-15.986 15.986-41.997 15.986-57.983 0-5.857-5.857-15.355-5.858-21.213 0-5.857 5.858-5.857 15.355 0 21.213 27.683 27.682 72.726 27.682 100.41 0 5.858-5.858 5.858-15.355 0-21.213-5.859-5.857-15.355-5.857-21.213 0z"/>
          </svg>
        </div>
      </button>
    </div>
    <div style="display: inline-block; position: absolute;">
      <button :style="(item.playlist.length>0 || item.shootlist.length>0) ? '':'pointer-events:none'">
        <span class="name"><span>{{item.name +' '+ (item.surname ? item.surname : '')}} </span><br/>
          <span v-if="item.id_school!==null && translated!==null" class="class">{{
            translated.data.city + (item.class ? ', ' : '') +((item.class ? item.class : '') + (item.class_number ? item.class_number : '') +', ' )}}
            <span :id="item.id_school" style="color:#63ade6" @click="filterData">{{translated.data.name }}</span>
        </span>
       </span>
      </button>
    </div>
  </div>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag && (item.playlist.length || item.shootlist.length)">
        <div class="games-w">
          <GameElement :key="i*2" :game="item" v-for="(item,i) in item.playlist"/>
        </div>
        <div v-if="item.shootlist.length>0" class="questions">


          <div class="title">{{$ml.get("שאלות שהמצאתי")}}</div>
          <button @click="go(shoot)" class="q" :key="i*5" v-for="(shoot,i) in item.shootlist">
            <ShootEl :item="shoot" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import GameElement from "./GameElement";
  import ShootEl from "./ShootEl";
export default {
  props: {
    item: {
    },
    questions: {
      default: false,
    },
    open:{
      default: false,
    }
  },
  computed:{
    user(){
      return this.$store.getters['user/data'];
    },
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.item.school.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        })
      }
    },
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
    total(){
     return  this.item.playlist.reduce(function(sum, current) {
        return sum + current.played;
      }, 0);
    },
    userSchool(){
      return this.$store.getters['user/school'];
    }
  },
  components:{
    GameElement,
    ShootEl,
  },
  methods:{
    filterData(e){
      this.$root.$emit('GameInventors',e.target.outerText)
    },
    changeFlag(){
      this.flag = !this.flag;
      this.$emit('input', this.flag);
    },
    go(shoot){
      this.$router.push({
        name: "shootlist",
        params: {
          id_shootlist: shoot.id_shootlist
        }
      });
    }
  },
  data(){
    return{
      flag: false,
    }
  },
  created() {
    this.flag = this.open;
  }
}
</script>

<style lang="scss" scoped>
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .inventor-droplist{
    position: relative;
    margin: 2px 0;
    padding: 10px;
    transition: all .4s ease-in-out;
    color: #828282;
    background: white;
    .question{
      padding: 15px 30px 9px 0px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #06364B;

      @media screen and (max-width: 340px){
        padding: 15px 20px 9px 0px;
      }
      .terms{
        left: 20px;
        position: absolute;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        top: 17px;
        text-align: right;
        /*max-width: 145px;*/
        .term{
          display: flex;
          align-items: flex-end;
        }
        .gray{
          margin-right:3px;
          font-size: 12px;
          color: #A2A2A2;
        }
      }
      .empty{
        left: 10px;
        position: absolute;
        max-width: 155px;
        height: 42px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        color: #00465B;

        background: #DDF0F5;
        border-radius: 4px;
        padding: 6px 15px;
      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        width: 88%;
        text-align: start;
        display: flex;
        align-items: center;
        img{
          width: 42px;
          height: 42px;
          border-radius: 50%;
          object-fit: cover;
          &.no-img{
            width: 42px;
            height: 42px;
            opacity: 0;
          }
        }
        span{

         &.name{
           max-width: 90px;
           font-size: 14px;
           margin-right: 10px;
           @media screen and (max-width: 340px){
             max-width: 70px;
           }
         }
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #00465B;
          .blue{
            text-align: right;
            color: #1A92B8;
          }
          .class{
            font-size: 12px;
          }
          .gray{
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            color: #4D4D4D;
          }
        }
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: 9px;
      top: 22px;
      @media screen and (max-width: 340px){
        right: 0;
      }
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      max-height: 310px;
      overflow: auto;
      transition: all 0.4s ease-in-out;
      background: #DDF0F5;
      border-radius: 5px;
      .games-w{
        width: 282px;
        margin: 5px auto 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .questions{
        padding: 17px 24px;
        display: flex;
        flex-direction: column;
        .title{
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: #4D4D4D;
        }
        .q{
          margin: 8px 0;
          position: relative;
          font-size: 17px;
          font-family: 'Cool';
          font-style: normal;
          font-weight: normal;
          line-height: 15px;
            text-align: right;

          color: #00465B;
          &:after{
            position: absolute;
            content: '';
            right: -10px;
            top: 8px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #00465B;
          }
        }
      }
    }
  }
  .no-photo {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: #eee;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 28px;
      fill: #aaa;
    }
  }
</style>