<template>
  <div class="educational-classes-block"  >
    <div class="list" :key="i*2" v-for="(educationClass, i) in educationClasses">
      <div class="list-element">
        <div
          class="title">
          <div
            class="round"
            style="background: #AAD9E7"
          ></div>
          {{educationClass.details.name}}
        </div>
        <div class="info">
          <span class="name"><span> {{$ml.get('שאלות לדיון שנשאלו בכיתה')}}: </span><span  >{{educationClass.questions.filter(item=>{return !!item.stats.select}).length}}</span></span>
<!--          <a :key="i*2" v-for="(el,i) in educationClass.stats.playlists" :href="el.link">{{el.details.name}} <span v-if="i<educationClass.stats.playlists.length-1"> ,</span></a>-->
        </div>
        <div class="block-w">
          <div class="list-t"> {{$ml.get('סיכום הפעילות')}}:</div>
        <div v-if="educationClass.review !==null" class="games">
          <div class="wrapper-g"  :key="i" v-for="(question, i) in questionsLocal">
            <GamesValue :value="question.title" :status="educationClass.review.columns[i] === i" />
          </div>

        </div>
          <div class="list-t"><span>{{$ml.get('דירוג השיעור')}}:</span> <span v-if="educationClass.review" >{{educationClass.review.score}}</span> </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import GamesValue from "./GamesValue";
  export default {
    props:{
      goal:{

      },
      educationClasses: {},
    },
    components:{
      GamesValue,
    },
    data() {
      return {
        arr: [
          {name: ' השגריר', link: 'google.com'},
          {name: ' גולף עפרון', link: 'google.com'}
        ],
        questionsLocal:[
          {title: 'שיתוף', description:  'מבקשים מהילדים לשחק בבית בשניים מהמשחקים ממערך\n' +
                ' ושומעים מכל ילד איזה משחק הוא הכי אהב ולמה'},
          {title: 'אתגר בית', description:    ' מבקשים מהילדים לשחק בבית בשניים מהמשחקים ממערך השיעור - מתוך אלו ששוחקו בכיתה ובעיקר מאלו שעדיין לא שוחקו בכיתה\n' +
                '(מדגישים שהמורה רואה בחשבונה מה הם משחקים בבית!)' },
          {title: 'אתגר הפסקות', description: 'מבקשים מהילדים לשחק בהפסקות  \n' +
                'ב2- משחקים ששוחקו בשיעור '},
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>

 .educational-classes-block{
   padding: 10px 20px 10px 20px;
   .head{
     display: flex;
     align-items: center;
     justify-content: space-between;
     .name{
       font-size: 14px;
       text-align: right;

     }
     .title{
       display: flex;
       align-items: center;
       font-weight: bold;
       font-size: 14px;
       text-align: right;

       .color{
         margin-left: 3px;
         width: 12px;
         height: 12px;
         border-radius: 2px;
       }
     }
   }
   .list{
     padding-bottom: 10px;
     .list-element{

       margin-top: 20px;
       .title{
         display: flex;
         align-items: center;
         font-weight: bold;
         font-size: 14px;
         color: #06364B;
         .round{
           margin-left: 3px;
           width: 6px;
           height: 6px;
            border-radius: 50%;
         }
       }
       .info{
         margin-top: 10px;
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         .name{
           margin-top: -4px;
           margin-left: 7px;
           font-size: 14px;
           color: #4D4D4D;
         }
         a{
           font-weight: normal;
           font-size: 14px;
           color: #3FBCD4;
         }
       }
       .list-t{
         margin: 6px 0;
         font-size: 14px;
         text-align: right;
         color: #4D4D4D;
       }
       .games{
         display: flex;
         flex-direction: row;
         justify-content: space-between;
       }
     }
   }
 }
  .unplayed{
    margin-top: 26px;
    padding: 10px 0;
    padding-left: 25px;
    border-top: 1px solid #A9DDF3;
    display:inline-block;
    .title{
      display: flex;
      align-items: center;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      color: #4D4D4D;
      .round{
        margin-left: 3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    .info{
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .name{
        margin-left: 7px;
        font-size: 14px;
        color: #4D4D4D;
      }
      a{
        font-weight: normal;
        font-size: 14px;
        color: #3FBCD4;
      }
    }
  }
 .block-w{
   margin: 5px 0;
   padding: 10px 15px;
   background: #F2F2F2;
   border-radius: 10px;
 }
</style>

