<template>

    <div class="cont" v-html="translated.text? translated.text.replace(/\n/g, '<br>') : item.translation[0].data.text "></div>



</template>

<script>
  export default {
    name: "NameTranslated",
    props:{
      translate: {}
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.translate.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data
        }
      },
    }
  }
</script>

<style scoped>

</style>