<template>
    <div class="main">
        <span class="main-question">{{ $ml.get('האם אתה בטוח שברצונך למחוק את חשבונך?') }}</span>
        <br/>
        <span style="margin-top:10px; font-size: 16px;">{{ $ml.get('אתה יכול לשחזר את חשבונך תוך 30 יום') }}</span>
        <app-btn
            @click="cancele"
          class="reg-a"
        >
          {{ $ml.get("לְבַטֵל") }}</app-btn
        >
        <app-btn
            @click="deleteThis"
          class="reg-b"
        >
          {{ $ml.get("לִמְחוֹק") }}</app-btn
        >
    </div>
</template>
<script>
import { UserAPI } from "@/api";
export default {
    methods: {
        deleteThis(){
            UserAPI.deleteUser().then(()=>{
                this.$store.commit("user/logout");
                this.$store.commit("playlist/reset");
                this.$emit("close");
                this.$router.push("/");
            });
        },
        cancele(){
            this.$emit('close')
        },
    },
}
</script>
<style lang="scss" scoped>
.reg-a {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 250px !important;
    height: 54px !important;
    color: black;
    background: #cac9c9 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
.reg-b {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 250px !important;
    height: 54px !important;
    color: black;
    background: #fdc671 !important;
    border-radius: 30px;
    &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
  }
.main{
    min-width: 295px !important;
    min-height: 320px !important;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0px 20px;
    padding-top: 20px;
    .main-question{
        font-size: 24px;
    }
}
</style>