<template>
  <button
    class="toggler"
    @click="openBar"
    :class="{ inverse: inverse , inverseColor: inverseColor  }"
  >
    <i class="icon ion-md-menu"></i>
  </button>
</template>

<script>
export default {
  props: {
    inverse: {
      default: false,
      type: Boolean
    },
    inverseColor: {
      default: false
    }
  },
  methods:{
    openBar(){
      window.fbq("track", "תפריט");
      if (this.$route.name==='playlist'){
        this.$store.dispatch("user/updateData");
      }
      this.$store.state.sidebar.opened = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.toggler {

  color: #fff;
  top: 0;
  height: 30px;
  left: 0;
  width: 30px;
  position: absolute;
  font-size: 26px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.inverse {
    color: #005d7a;
  }
}
.inverseColor{
  i{
    color: #000000;
  }
}
</style>
