<template>
  <div class="modal" dir="rtl">
    <p class="big">

      <span> {{$ml.get('תודה')}} </span>
      <span><span>{{(user.surname ? user.surname : '')}}</span>
      <span>{{  ' ' +user.name}}</span></span>
    </p>


    <p>
      {{$ml.get('המשחק שלך נשלח ונמצא בבדיקה')}}
      {{$ml.get('נחזור אליך בהקדם')}}
    </p>
    <p>
      {{$ml.get('ניתן לראות את סטטוס המשחק')}}

      {{$ml.get('בעמוד')}} <button @click="openInvent" class="b"> {{$ml.get('ממציאי המשחקים')}} </button>

    </p>

    <button @click="$emit('close')" class="theme-button-outline">
      {{$ml.get('חזרה למשחקים')}}
    </button>
  </div>
</template>

<script>
export default {
  computed:{
    user(){
      return this.$store.getters['user/data'];
    },
  },
  methods:{
    openInvent(){
      this.$emit('close');
      this.$router.push({name: 'requests-playlist', params: {type: 'playlist'}});
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    font-size: 50px;
  }
  p {
    max-width: 190px;
    margin: 5px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    .b{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #149CC6;
      margin-top: -2px;
      display: inline-block;
    }
    &.big{
      font-size: 16px;
    }
  }
  .theme-button-outline {
    margin-top: 20px;
    margin-bottom: 5px;
    background: #005D79;
    border-radius: 18px;
    color: white;
    font-size: 14px;
  }
  .nope {
    color: #005d7a;
    font-size: 16px;
  }
}
</style>
