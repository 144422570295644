const TranslatedDates = function() {

  return [
    {
      langKey: 'heb',
      dates:[
        {name: 'שנה', str: 'Year', index: 0},
        {name: 'ינואר', str: 'Jan', index: 1},
        {name: 'פברואר', str: 'Feb', index: 2},
        {name: 'מרץ', str: 'Mar', index: 3},
        {name: 'אפריל', str: 'Apr', index: 4},
        {name: 'מאי', str: 'May', index: 5},
        {name: 'יוני', str: 'Jun', index: 6},
        {name: 'יולי', str: 'Jul', index: 7},
        {name: 'אוגוסט', str: 'Aug', index: 8},
        {name: 'ספטמבר', str: 'Sep', index: 9},
        {name: 'אוקטובר', str: 'Oct', index: 10},
        {name: 'נובמבר', str: 'Nov', index: 11},
        {name: 'דצמבר', str: 'Dec', index: 12},
      ],
      selected: 'שנה',
    },
    {
      langKey: 'en',
      dates:[
        {name: 'Year', str: 'Year', index: 0},
        {name: 'Jan', str: 'Jan', index: 1},
        {name: 'Feb', str: 'Feb', index: 2},
        {name: 'Mar', str: 'Mar', index: 3},
        {name: 'Apr', str: 'Apr', index: 4},
        {name: 'May', str: 'May', index: 5},
        {name: 'Jun', str: 'Jun', index: 6},
        {name: 'Jul', str: 'Jul', index: 7},
        {name: 'Aug', str: 'Aug', index: 8},
        {name: 'Sep', str: 'Sep', index: 9},
        {name: 'Oct', str: 'Oct', index: 10},
        {name: 'Nov', str: 'Nov', index: 11},
        {name: 'Dec', str: 'Dec', index: 12},
      ],
      selected: 'Year',
    },
    {
      langKey: 'ru',
      dates:[
        {name: 'Год', str: 'Year', index: 0},
        {name: 'Январь', str: 'Jan', index: 1},
        {name: 'Февраль', str: 'Feb', index: 2},
        {name: 'Март', str: 'Mar', index: 3},
        {name: 'Апрель', str: 'Apr', index: 4},
        {name: 'Май', str: 'May', index: 5},
        {name: 'Июнь', str: 'Jun', index: 6},
        {name: 'Июль', str: 'Jul', index: 7},
        {name: 'Август', str: 'Aug', index: 8},
        {name: 'Сентябрь', str: 'Sep', index: 9},
        {name: 'Октябрь', str: 'Oct', index: 10},
        {name: 'Ноябрь', str: 'Nov', index: 11},
        {name: 'Декабрь', str: 'Dec', index: 12},
      ],
      selected: 'Год',
    },
    {
      langKey: 'ar',
      dates:[
        {name: 'سنة', str: 'Year', index: 0},
        {name: 'يناير', str: 'Jan', index: 1},
        {name: 'شهر فبراير', str: 'Feb', index: 2},
        {name: 'مارس', str: 'Mar', index: 3},
        {name: 'ابريل', str: 'Apr', index: 4},
        {name: 'مايو', str: 'May', index: 5},
        {name: 'يونيو', str: 'Jun', index: 6},
        {name: 'يوليو', str: 'Jul', index: 7},
        {name: 'أغسطس', str: 'Aug', index: 8},
        {name: 'شهر سبتمبر', str: 'Sep', index: 9},
        {name: 'تشرين أول', str: 'Oct', index: 10},
        {name: 'نوفمبر', str: 'Nov', index: 11},
        {name: 'ديسمبر', str: 'Dec', index: 12},
      ],
      selected: 'سنة',
    }
  ]
};

export { TranslatedDates }