import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterMultiguard from "vue-router-multiguard";

import Auth from "@/views/auth/Auth";
import Forgot from "@/views/auth/Forgot";
import Restore from "@/views/auth/Restore"
import Reg from "@/views/auth/Reg";
import Class from "@/views/auth/Class";
import Fullname from "@/views/auth/Fullname";
import Code from "@/views/auth/Code";
import RegTest from "@/views/auth/RegTest";
// import SignBack from "@/views/auth/SignBack";
import SignIn from "@/views/auth/SignIn";

import { Middleware } from "./middleware";

const AuthRouter = {
  path: "/auth",
  component: Auth,
  name: 'auth',
  // beforeEnter: VueRouterMultiguard([
  //   Middleware.isUnLogged,
  // ]),
  children: [
    {
      path: "test",
      component: Reg,
      name: 'auth-reg-test',
    },
    {
      path: "forgot",
      component: Forgot,
      name: 'auth-forgot',
    },
    {
      path: "restore",
      component: Restore,
      name: 'auth-restore',
    },
    {
      path: "reg",
      component: RegTest,
      name: 'auth-reg',
    },
    {
      path: "sign-in",
      component: SignIn,
      name: 'auth-sign-in',
    },
    {
      path: "class",
      component: Class,
      name: 'auth-class',
    },
    {
      path: "fullname",
      component: Fullname,
      name: 'auth-fullname',
    },
    {
      path: "code",
      component: Code,
      name: 'auth-code',
    },
  ]
}

export { AuthRouter }
