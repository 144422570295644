<template>
  <main class="index">
    <img src="@/assets/img/white-logo.svg" alt="" />
    <div class="title fugaz">
      LOADING
      <span v-for="dot in dots">. </span>
    </div>
    <!-- <div class="logo-container">
      <img src="@/assets/img/white-logo.svg" alt="" width="220" height="80" />
      <div class="bottom">
        {{$ml.get("משחקים מחוץ למסך")}}
      </div>
    </div>
    <router-link to="/auth/sign-back">
      <button class="theme-button">{{$ml.get("מתחילים לשחק")}}</button>
    </router-link> -->
  </main>
</template>

<script>
export default {
  data()
  {
    return{
      interval: null,
      dots: [],
      trackData:{
        utm_source:null,
        utm_medium:null,
        utm_campaign:null,
        utm_content:null,
        utm_term:null
      }
    }
  },
  created() {
    this.parseLink();
    if (this.$route.name == "shared") {
      this.$store.commit("setShared", {
        id: this.$route.params.id,
        type: this.$route.params.type
      });
    }
  },
  computed:{
    checkUser(){

    },
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.dots.length >= 3) {
        this.dots = [];
      } else {
        this.dots.push(null);
      }
    }, 100);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    parseLink(){
      const link = window.location.href.replace('/#/','/');
      if(link!=null && link !='')
      {
        const url = new URL(link)

        const utm_source = url.searchParams.get('utm_source');
        this.trackData.utm_source = utm_source;

        const utm_medium = url.searchParams.get('utm_medium');
        this.trackData.utm_medium = utm_medium;

        const utm_campaign = url.searchParams.get('utm_campaign');
        this.trackData.utm_campaign = utm_campaign;

        const utm_content = url.searchParams.get('utm_content');
        this.trackData.utm_content = utm_content;
        
        const utm_term = url.searchParams.get('utm_term');
        this.trackData.utm_term = utm_term;
      }
      this.$store.commit('track/saveData', this.trackData);
      this.$router.push({name:'auth-reg'})
    }
  },
};
</script>

<style lang="scss" scoped>
// main {
//   flex-grow: 1;
//   background: #005d7a;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   // background-image: url($funny-bg);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;

//   .logo-container {
//     position: relative;
//     background: #067091;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 10px;
//     border: 3px solid #fff;
//     width: 300px;
//     height: 120px;
//     margin-bottom: 30px;

//     img {
//       width: 220px;
//       margin-top: -20px;
//     }

//     .bottom {
//       text-align: center;
//       min-width: 180px;
//       height: 10px;
//       position: absolute;
//       bottom: -3px;
//       left: 50%;
//       padding: 0 5px;
//       transform: translateX(-50%);
//       background: #067091;
//       color: #fff;
//       line-height: 90%;
//     }
//   }
  
//   button {
//     width: 280px;
//     background: #fff;
//     border-color: #118188;
//   }
// }
main {
  background: #005d7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  img {
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    color: #fff;
  }
}
</style>
