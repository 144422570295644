<template>
  <div class="goal-element">
      <div class="title">{{title}}</div>
      <div class="info">
        <span style="margin-bottom: 10px" class="name"><span>{{naga}}: </span><span>{{total}}</span></span>
        <div class="arrs">

          <div class="name"><span>  {{$ml.get('ממציאי')}} {{aga}} {{$ml.get('שהתקבלו')}} : </span><span>{{users.length}}</span></div>
          <div class="link-wrap">
            <a :key="i*2" v-for="(el,i) in users" :href="el.link">{{el.name}} <span v-if="i<users.length-1"> ,</span></a>
          </div>
        </div>
      </div>
  </div>
</template>
<script>

  export default {
    props:{
      title:{},
      total:{},
      users: {},
      aga:{},
      naga:{},
    },
    components:{

    },
    data() {
      return {
        arr: [
          {name: ' השגריר', link: 'google.com'},
          {name: ' גולף עפרון', link: 'google.com'}
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
 .goal-element{
   padding: 10px 20px 10px 20px;
  .title{
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: #06364B;
  }
   .info{
     margin-top: 10px;
     display: flex;
     align-items: flex-start;

     flex-direction: column;
     .name{
       margin-left: 7px;
       font-weight: normal;
       font-size: 14px;
       text-align: right;
       color: #4D4D4D;
     }
     .arrs{


       display: flex;
       align-items: flex-start;
       flex-direction: column;
       flex-wrap: wrap;
      .link-wrap{
        display: flex;
        flex-wrap: wrap;
        a{
          font-weight: normal;
          font-size: 14px;
          color: #3FBCD4;
        }
      }
     }
   }
 }
</style>

