<template>
  <div class="gift" style="background: #005d79;">
    <SimpleHeader style="position: fixed;width: 100%;z-index: 10;"   route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get("מסלול משחקים אישי")}}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <div class="container sm-cont" style="width:100%; max-width:460px !important;">
      <div class="head">
        <div class="top-text" style="text-align: center; width: 100%; padding: 26px 0px 0px 0px;">
          <span style="font-family: 'App';font-style: normal;font-weight: 500;font-size: 18px;line-height: 20px;text-align: center;color: #00465B;">
            {{ $ml.get('בחרו את כישורי החיים שבהם אתם רוצים') }}<br>{{ $ml.get('שהילד יתחזק, ויוצגו לו רק משחקים תואמים') }}
          </span>
        </div>
        <div id="myHeader" class="head-wrapper header" >
        <div  class="sections ">
          <div class="item" v-for="(item, i) in sections">
            <button @click="check('head'+(i+1))"  >
            <div class="color" :style="{ backgroundColor: item.color }"></div>
              <NameTranslated  class="name" :translate="item.translation" />
            </button>
          </div>
        </div>
        <div style="width: 100%; text-align: center;">
          <app-btn @click="pushTo" style="margin: auto; position: relative;width: 340px;height: 40px;background: #FFFFFF;border: 1px solid #06364B;border-radius: 20px;font-family: 'App';font-style: normal;font-weight: 400;font-size: 14px;line-height: 18px;text-align: right;color: #00465B;">
            <span style="margin-right: 40px;">{{ $ml.get('מעקב התקדמות') }}</span>
            <svg style="position: absolute;right: 111px;" width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2V23C0.5 23.8284 1.17157 24.5 2 24.5H18C18.8284 24.5 19.5 23.8284 19.5 23V6.41421C19.5 6.01639 19.342 5.63486 19.0607 5.35355L14.6464 0.93934C14.3651 0.658035 13.9836 0.5 13.5858 0.5H2Z" fill="white" stroke="#00465B"/>
            <path d="M14 1.20711C14 0.761654 14.5386 0.538571 14.8536 0.853553L16.7692 2.76923L19.1464 5.14645C19.4614 5.46143 19.2383 6 18.7929 6H14.5C14.2239 6 14 5.77614 14 5.5V1.20711Z" fill="#00465B"/>
            </svg>
            <!-- <span class="elem-position" id="counterPos" >{{countLoaded?counterFunc():''?0:'-'}}</span> -->
            <span style="font-size: 10px; color: black; position: absolute;" class="elem-position" id="counterPos" >{{countLoaded?counterFunc():''?0:'-'}}</span>
          </app-btn>
        </div>

        <div class="bot-text">
          {{$ml.get("המספר מציג את כמות המשחקים ששוחקו בקטגוריה")}}
        </div>
      </div>
      </div>

      <app-loading v-if="loading.sync" class="list">
        <div class="items">
          <div style="max-width:460px; width:100%; min-height:280px; background: lightgray; position: relative;">
            <div style="position: absolute;right: 13%;width: 100px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="position: absolute;left: 13%;width: 110px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto; margin-top: 50px;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style="max-width:460px; width:100%; min-height:280px; background: #f5f0f0; position: relative;">
            <div style="position: absolute;right: 13%;width: 100px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="position: absolute;left: 13%;width: 110px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto; margin-top: 50px;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style="max-width:460px; width:100%; min-height:280px; background: lightgray; position: relative;">
            <div style="position: absolute;right: 13%;width: 100px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="position: absolute;left: 13%;width: 110px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto; margin-top: 50px;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style="max-width:460px; width:100%; min-height:280px; background: #f5f0f0; position: relative;">
            <div style="position: absolute;right: 13%;width: 100px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="position: absolute;left: 13%;width: 110px !important;top: 16px;z-index: 9999;" class="line h17 w40 m10"></div>
            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto; margin-top: 50px;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

            <div style="display:flex; flex-direction:row; justify-content:space-between; width: 350px; margin-left: auto;margin-right: auto;">
              <div style="position:relative; padding: 3px 5px; border-radius: 4px;" v-for="i in testData" :key="i+1">
                <div class="">
                  <div class="icon-wrapper">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </app-loading>
      <app-loading v-else class="list">
        <div class="items">
          <GiftsWrapper
            :section="section"
            :key="i*12"
            :giftsLength="gifts.filter((gift) => gift.id_section === section.id_section ).length"
            :allSection="selectedObj.filter((gift) => gift.id_section === section.id_section).length"
            v-for="(section, i) in sections"
            :id="'head'+(i+1)"
            @clear="clearBySection(section.id_section)"
            @select="allBySection(section.id_section)"
            v-if="gifts.filter((gift) => gift.id_section === section.id_section ).length>0"
          >
            <div class="item"
            :style="'border: 1px solid '+$store.getters['playlist-sections/getColorById'](item.id_section)"
                 :key="index*33"
                 v-for="(item, index) in gifts.filter((gift) => gift.id_section === section.id_section ).sort((a, b) => (a.order_index != null && a.order_index != 0 ? a.order_index : Infinity) - (b.order_index != null && b.order_index != 0 ? b.order_index : Infinity))"
            >
              <!-- <div
                class="dot"
                :style="{
                backgroundColor: `${$store.getters['playlist-sections/getColorById'](item.id_section)}`
              }"
              ></div> -->
              <CheckboxWrapper
                :style="selected.find( (el) => el == item.id_category)?'background:'+$store.getters['playlist-sections/getColorById'](item.id_section)+';' : 'background: white;'"
                v-model="selected"
                :class="[ selected.find( (el) => el == item.id_category)  ?
               'active' : 'unactive' ]"
              >
                <template v-slot="{setValue}">
                  <div
                    @click="setValue(item.id_category)"
                    class="checkbox-slot">
                    <img :src="item.img_url" alt="">

                    <div class="title" style="min-height: 24px;">
                      <NameTranslated :style="item.name.length<=14?'margin-top: 5px;':''" :translate="item.translation" />
                    </div>
                    <div class="played">
                      {{ item.stats.played }}
                    </div>
                  </div>
                </template>
              </CheckboxWrapper>
            </div>
          </GiftsWrapper>
        </div>
      </app-loading>
    </div>
    <div class="bottom-fixed">
      <app-btn :loading="loading.save" @click="update" class="save-b">{{$ml.get('שמור')}}</app-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SimpleHeader from "@/components/SimpleHeader";
import SidebarToggler from "@/components/SidebarToggler";
import GiftsWrapper from "./GiftsWrapper";
import { PlaylistAPI, GiftsAPI, TeacherAPI } from '@/api';
import CheckboxWrapper from "../../components/CheckboxWrapper";
import NameTranslated from "@/components/playlist/NameTranslated";
export default {
  props: {

  },
  data(){
    return{
      testData:[1,2,3,4],
      countOfGames: null,
      countLoaded: false,
      selected: [],
      loading: {
        save: false,
        sync: false,
      },
      gifts: [],
    }
  },
  methods: {
    pushTo(){
      this.$router.push({name: 'teacher-games-activity', params:{id:this.$store.getters['user/data'].id_user}});
    },
    counterFunc(){
      let count = String(this.countOfGames).length;
      switch(count)
      {
        case 1:
          document.querySelector('#counterPos').style.fontSize = '12px';
          document.querySelector('#counterPos').style.top = '10px';
          document.querySelector('#counterPos').style.left = '0px';
          document.querySelector('#counterPos').style.right = '118px';
          break;
        case 2:
          document.querySelector('#counterPos').style.fontSize = '10px';
          document.querySelector('#counterPos').style.top = '10px';
          document.querySelector('#counterPos').style.left = '0px';
          document.querySelector('#counterPos').style.right = '115px';
          break;
        case 3:
          document.querySelector('#counterPos').style.fontSize = '8px';
          document.querySelector('#counterPos').style.top = '11px';
          document.querySelector('#counterPos').style.left = '0px';
          document.querySelector('#counterPos').style.right = '114px';
          break;
        case 4:
          document.querySelector('#counterPos').style.fontSize = '7px';
          document.querySelector('#counterPos').style.top = '11px';
          document.querySelector('#counterPos').style.left = '0px';
          document.querySelector('#counterPos').style.right = '113px';
          break;
          default:
            break;
      }
      return this.countOfGames;
    },
    check(id){
      var d = document.getElementById(id);
      var topPos = d.offsetTop + 90;
      window.scrollSmoothTo(topPos, 1000)
    },
    clearBySection(section){
      let chosen = this.gifts
          .filter(n => this.selected
          .find(z => z == n.id_category));

      this.selected =  chosen
          .filter((gift) => gift.id_section !== section )
          .map(gift=> gift.id_category);

    },
    allBySection(section){
      let chosen = this.gifts
          .filter((z)  =>
          z.id_section === section)
          .map(z=> z.id_category);

      this.clearBySection(section);
      this.selected = this.selected.concat(chosen) ;
    },
    update(){
      // this.loading.save = true;
      
      PlaylistAPI.updateGifts({ categories: this.selected }).then(() => {
        this.$store.dispatch('user/updateData');
        this.$router.push('/playlist/options');
        this.loading.save = false;
      });
    }
  
  },
  computed: {
    ...mapGetters({
      sections: 'playlist-sections/data',
      user: 'user/data',
    }),
    selectedObj(){
      return  this.gifts
          .filter(n => this.selected
              .find(z => z == n.id_category));
    }
  },
  async created() {
    window.fbq("track", "תוכנית משחקים אישית");
     TeacherAPI.getAllPlayedGameDetails({id:this.user.id_user})
      .then(res=>{
        this.countOfGames = res.data.playlist.playedPlaylist;
        this.countLoaded = true;
      }).catch();

    this.loading.sync = true;
    GiftsAPI.selectAllWithStats().then(res => {
      this.gifts = res.data;
      this.selected = _.cloneDeep(this.user.gifts) || [];
      this.loading.sync = false;
    });
    
  },

  mounted() {


    window.onscroll = function() {myFunction()};
    let header = document.getElementById("myHeader");
    let sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky-50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }

  },
  components: {
    CheckboxWrapper,
    SimpleHeader,
    SidebarToggler,
    GiftsWrapper,
    NameTranslated
  },
}
</script>

<style lang="scss" scoped>
.counterPos{
    margin-left: auto;
    margin-right: auto;
}
.circle {
     min-height: 98px;
     max-height: 98px;
     min-width: 76px;
     max-width: 76px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  
  position: absolute;
     height: 12px;
     left: 22px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:60% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 4px !important;
     height: 98px !important;
     width: 76px !important;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.elem-position{

position: absolute;
// left: 85px;
// top: 10px;
color: white;
font-weight: bold;
}
.text-clor{
font-style: normal;
font-weight: 400;
font-size: 12px;
/* or 117% */

text-align: center;

color: #3FBCD4;
}
.gift {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .container {
    padding: 0px !important;
    overflow-y: auto;
    height: calc(100% - 260px);
  }

  .list {
    flex-grow: 1;
    background: #fff;
    min-height: 200px;
  }

  .head {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;


    background: #fff;
    border-bottom: 1px solid #CCDFE4;
    min-height: 170px;

    //   @media (max-width: 380px) {
    //   min-height: 190px;
    // }

    .head-wrapper{
      padding: 2px 10px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sections {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      .item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px;
        width: 115px;
        margin-bottom: 8px;
        button{
          display: flex;
          align-items: center;
          .name {
            font-family: 'App';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            /* identical to box height */

            text-align: right;

            color: #149CC6;
            min-height: 24px;

          }
          .color {
            margin-left: 3px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }
        }
      }
    }

    .top-text {
      padding: 5px 10px;
      font-size: 14px;
      text-align: center;
      color: #000;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      // &::after {
      //   width: calc(100% - 30px);
      //   content: '';
      //   height: 1px;
      //   bottom: 0;
      //   left: 15px;
      //   background: #CCDFE4;
      //   position: absolute;
      // }
    }
    .bot-text {
      margin-top: 12px;
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
      text-align: left;
      color: #000000;
      max-width: 360px;
      width: 100%;
      padding: 0 5px;
      padding-bottom: 5px;
    }
  }

  .items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    flex-wrap: wrap;
    margin-bottom: 53px;

    .item {

      border: 1px solid #669EAF;
      border-radius: 4px;
      margin: 5px;
      width: 78px;
      height: 100px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .checkbox-wrapper{

        position: relative;
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        color: #06364B;
        border-radius: 4px;
        .title{

          font-size: 12px;
          line-height: 1;
          margin-bottom: 5px;
        }
        .played {
          line-height: 1;
          font-size: 10px;
        }
        .checkbox-slot {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 4;
          width: 100%;
          height: 100%;
        }
        
        &.active {
        &:after {
          position: absolute;
          content: "";
          z-index: 0;
          border: 1px solid white;
          background: #ffffff;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 5px;
          right: 5px;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #00465b;
          border-bottom: 3px solid #00465b;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          scale: -0.9;
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
      }
      &.unactive {
        &:after {
          top: 5px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
      }
      }
      img {
        min-height: 36px;
        height: 36px;
        align-self: center;
        margin-bottom: 10px;
      }

      span {
        margin-top: 10px;
        font-size: small;
        text-align: center;
        line-height: 1.2;
        padding: 0 10px;
      }

      .dot {
        top: 4px;
        left: 4px;
        z-index: 1;
        position: absolute;
        display: flex;
        align-self: flex-end;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        min-height: 10px;

      }
    }
  }

  .bottom-fixed {
    z-index: 99;
    position: fixed;
    height: 61px;
    bottom: 0;
    max-width: 436px;
    // width: 460px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #C4C4C4;
    button{
      width: 180px;
      height: 36px;
      border-radius: 18px;
      margin: 12px auto 12px auto;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

  .header{
    transition: all 0.3s ease-in-out;

    &.sticky {
      border-bottom: 1px solid #C4C4C4;
      position: fixed;
      top: 50px;
      z-index: 20;
      width: 100%;
      max-width: 459px;
      padding: 0 !important;

      .bot-text {
        padding: 5px 17px;
      }
    }
  }

.grid-container {
  max-width: 380px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>