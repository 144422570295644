export default {
  namespaced: true,
  state: {
    opened: false,
    title: '',
    id: '',
   },
  mutations: {
    close(state) {
      state.opened = false;
      state.title = '';
      state.id = '';
     },
    open(state, data) {
      state.opened = true;
      state.title = data.title;
      state.id = data.id;
     },
    switch(state) {
      state.opened = !state.opened;
    },
  },
  actions: {

  },
  getters: {
    isOpened(state) {
      return state.opened;
    },
    title(state){
      return state.title;
    },
    id(state){
      return state.id;
    },
  }
};