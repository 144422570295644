import Vue from 'vue';
import Axios from 'axios';
import router from '@/router';

const config = {};
//   GOOGLE_PLAY_URL: "https://play.google.com/store/apps/details?id=coelix.bytesbay.playlist",
//   APPSTORE_URL: "https://itunes.apple.com/us/app/playlist-beta/id1457977139?l=ru&ls=1&mt=8",

//   GAMES_FOR_REPLY: 50,
//   GAMES_SHARE_PLAYLIST: 15,
//   GAMES_SHARE_SHOOTLIST: 20,

//   CORDOVA: !!window.cordova,
//   cordova: !!window.cordova,
// };

// if (!config.cordova && FB) {
//   FB.init({
//     appId: process.env['VUE_APP_FACEBOOK_APP_ID'],
//     status: true,
//     xfbml: true,
//     version: "v3.3"
//   });
// }

window.axios = Axios;
axios.defaults.baseURL = process.env["VUE_APP_API_URL"];

let refreshingToken = false;

axios.interceptors.response.use(
   response => {
    const accessToken = localStorage.getItem('auth');
    const accessTokenExpiresAt = localStorage.getItem('expires_at');
    if (accessToken && accessTokenExpiresAt) {
      const now = new Date();
      const expires = accessTokenExpiresAt;
      const expiresDate = Math.floor(now.getTime() / 1000) + (10 * 60);
      if (expiresDate > expires && !refreshingToken) {
        refreshingToken = true;
        axios.post('/refresh', {
          headers: {
            Authorization: `${accessToken}`
          }
        }).then(res=>{
          console.log(res.data);
          axios.defaults.headers.common["Authorization"] = res.data.auth;
          config.API.defaults.headers.common["Authorization"] = res.data.auth;
          localStorage.setItem("auth", res.data.auth);
          localStorage.setItem("expires_at", res.data.expires_at);
          refreshingToken = false;
        }).catch(err => {
          refreshingToken = false;
          console.error('Error refreshing access token:', err);
        });
      }
    }

    return response;
  },
  error => {
    if (error.response.status === 401) {
      // Удаление просроченного токена из localStorage
      localStorage.removeItem('auth');
      localStorage.removeItem('expires_at');
      // Перенаправление на страницу входа
      router.push({ name: 'auth-sign-in' });
    }
    return Promise.reject(error);
  }
);

config.API = axios.create({
  baseURL: process.env['VUE_APP_API_URL'],
});

window.env = config;
window.config = config;
Vue.prototype.$env = config;
Vue.prototype.$config = config;
