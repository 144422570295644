<template>
  <main class="director-profile" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get('קביעת יעדים')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading class="sm-cont" style="background: #fff;">
      <template>
        <div class="head">

          <div :style="{direction:direction}" class="text">
            {{$ml.get('קביעת היעדים נועדה לעודד את המורים להגברת')}}
            {{$ml.get('השימוש במשחקים')}}, {{$ml.get('תוך ידיעה שרואים ומעריכים את')}}
            ,{{$ml.get('מאמציהם בכיתה')}}. {{$ml.get('ניתן לבחור יעדים לכל בית הספר')}}
            {{$ml.get('למחנכות שכבה או למורה מקצועית')}}
          </div>

          <div style="padding:0 0 20px 0" class="select-wrapper">
            <SelectTeacher
              style="margin: 0 auto;width: 149px;"
              @input="filter"
              class="select-i"
              placeholder="Chose option"
              :options="array"
              v-model="selected"/>
          </div>
        </div>
        <Report @update="save(selected)" :targets="targets"  />
        <TargetSave />
      </template>
    </app-loading>
  </main>
</template>

<script>
import {TeacherAPI, DirectorAPI} from "@/api";
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Report from '@/components/director/targets/Report'
import SelectTeacher from "@/components/teacher/SelectTeacher";
import TargetSave from "@/components/modals/modal/TargetSave";
export default {
  data() {
    return {
      index: 0,
      popup_showed: false,
      goals: [],
      targets: {},
      selected: 'כל מחנכות בית הספר',
      loading: {
        next: false,
        sync: false
      },
      array: [],
      value: 0,
    };
  },
  components: {

    TargetSave,
    SimpleHeader,
    SidebarToggler,
    SelectTeacher,
    Report,
  },
  computed: {
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
    link() {
      return this.$store.getters['tutorials/isUsed']('teacherTutorial') ? 'teacher-educational-classes' : 'teacher-tutorials';
    }
  },


  methods: {
    openTutorial() {
      this.$store.commit('modals/teacher-tutorial/open');
    },
    save(){
      this.$store.dispatch('director-targets/updateData',this.check(this.selected));
      this.$store.commit('modals/target-save/open', {title: this.selected, content:'sdfsdf'});
    },
    filter(value) {
        this.$store.commit('director-targets/savePosition',this.check(value).filter);
        DirectorAPI.getTargets(this.check(value)).then(res=>{
          this.$store.commit('director-targets/saveData', res.data);
          this.targets = res.data.data;
        }).catch();

    },
    check(value){
      if (value === 'כל המורים המקצועים'){
        return {filter: 'all_prof'}

      } else if(value === 'כל מחנכות בית הספר'){
        return {filter: 'all_teacher'}
      } else {
        let user =  this.array.filter((item)=>{
          return  item.name === value
        });
       return {filter: user[0].position, id : user[0].id_user }
      }
    },
  },
  created() {

    this.loading.sync = true;
    DirectorAPI.getTargets({filter: 'all_teacher'}).then(res=>{
      this.$store.commit('director-targets/savePosition', 'all_teacher');
      this.$store.commit('director-targets/saveData', res.data);
      this.targets = res.data.data;
    }).catch();


    DirectorAPI.getTeachers().then(res=>{
      this.array.push({position: 'all_teacher',name: 'כל מחנכות בית הספר', id_user:'' });

      res.data.forEach(item=>{
        this.array.push(item);
      });
      DirectorAPI.getProf().then(res=>{
        this.array.push( {position: 'all_prof',name: 'כל המורים המקצועים', id_user:'' });
        res.data.forEach(item=>{
          this.array.push(item);
        });
      }).catch();
    }).catch();

    TeacherAPI.getReportData().then(res=>{
      this.goals = res.data;
      this.loading.sync = false;
    });
    this.loading.sync = false;


  },
};
</script>

<style lang="scss" scoped>

.director-profile {

  background: white;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;


  .app-loading {
    flex-grow: 1;
    min-height: 450px;
    background: #FFFFFF;
    .head{
      margin: 35px 0;
      .text{
        width: 285px;
        margin: 0 auto 12px auto;
        text-align: center;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

      }
    }
    .director-links {
      padding: 50px 10px 50px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      .link-element {
        margin: 5px auto;
        padding: 10px 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        width: 320px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width: 340px){
          width: 100%;
        }
        span {
          font-style: italic;
          font-weight: bold;
          font-size: 21px;
          text-align: right;
          color: #06364B;
        }

        svg {

        }
      }
    }

    .footer {
      width: 100%;

      .treatment {
        height: 80px;
      }

      .report {
        height: 80px;
      }
    }
    .router-links-wrapper{
      width: 100%;
      .row{
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        font-weight: bold;
        .solo{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background: #00465B;
          height: 60px;
          color: #FFFFFF;
        }
        .separate{
          height: 60px;
          width: 50%;
          font-weight: bold;
          color: #00465B;
          display: flex;
          align-items: center;
          justify-content: center;
          &.orange{
            background: #FF984D;
          }
          &.yellow{
            background: #FFC700;
          }
        }
      }
    }
  }
}
</style>
