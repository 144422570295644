<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    @mousedown="onMouseDown"
    @click="onClick"
    class="btn h-btn app-btn"
    type="button"
    :class="{
      disabled: disabled,
      outline: styling === 'outline',
      'styling-inline': styling === 'inline',
      default: styling === 'default',
      'size-small': size === 'small',
      'size-normal': size === 'normal',
      'size-big': size === 'big',
      loading: loading
    }"
  >
    <div class="content">
      <MatIcon :size="size" :icon="icon" v-if="icon" />
      <slot />
      <transition name="fade">
        <LoadingSVG v-if="loading" />
      </transition>
    </div>
  </component>
</template>

<script>
import LoadingSVG from "./../LoadingSVG";
import MatIcon from "./MatIcon";

export default {
  name: "btn",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    icon: {},
    to: {},
    wAuto: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styling: {
      default: "default"
    },
    size: {
      default: "normal"
    }
  },
  methods: {

    onMouseDown(e) {
      let el = this.$el;
      let wave = document.createElement("div");
      wave.classList.add("wave");

      let offset = e.relativeOffsetByEvent(el);
      let rect = el.getBoundingClientRect();

      wave.style.top = offset.y + "px";
      wave.style.left = offset.x + "px";
      wave.style.width = rect.width + "px";
      wave.style.height = rect.width + "px";

      el.appendChild(wave);
      setTimeout(() => {
        wave.remove();
      }, 480);
    },

    onClick(e) {
      if (this.loading) {
        return;
      }

      this.$emit('click');
    }
  },
  components: {
    LoadingSVG,
    MatIcon
  }
};
</script>

<style lang="scss" scoped>
@keyframes waving {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

::v-deep {
  .wave {
    animation: waving 0.5s ease;
    background: #fff;
    border-radius: 50%;
    position: absolute;
  }
}

.app-btn {
  height: 42px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  border: 0;
  display: flex;

  width: auto;
  white-space: nowrap;
  padding-left: 40px;
  padding-right: 40px;
  min-width: 0;
  appearance: none;
  color: #fff;

  .content {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .loading-svg {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -7px;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.margin-around {
    margin-right: 8px;
    margin-left: 8px;
  }

  &.size-big {
    padding: 0 20px;
    min-width: 0;
    height: 48px;
    border-radius: 5px;

    .content {
      letter-spacing: 1px;
      font-size: 17px;
    }
  }

  &.size-small {
    padding: 0 20px;
    min-width: 0;
    height: 36px;
  }

  &.default {
    background: #005d7a;
    transition: box-shadow 0.3s ease, padding 0.3s ease, transform 0.3s ease;
    border-radius: 9999px;

    svg {
      fill: #fff;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 0 0 rgba(0, 0, 0, 0.2) inset,
        0px 2px 10px rgba(#005d7a, 0.795);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
      box-shadow: 0px 0 30px rgba(0, 0, 0, 0.3) inset, 0px 0 0 transparent;
    }

    &.disabled {
      background: #8b8d91;
      border: rgb(124, 126, 129);
    }
  }

  &.outline {
    transition: padding 0.3s ease, background 0.3s ease;

    // TESTIRUЮ
    // background: #ffffff;
    background: transparent;
    background: none;

    border: 1px solid rgb(111, 156, 64);

    .content {
      color: #6f9c40;
    }

    svg {
      fill: #6f9c40;
    }

    &:hover {
      background: rgba($color: rgb(146, 204, 83), $alpha: 0.2);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
    }

    &.disabled {
      .content {
        color: #8b8d91;
      }
    }
  }

  /** INLINE */
  &.styling-inline {
    transition: padding 0.3s ease, background 0.3s ease;
    border-radius: 9999px;

    background: transparent;
    background: none;

    .content {
      color: #fff;
    }

    svg {
      fill: #fff;
    }

    &:hover {
      background: rgba($color: #005d7a, $alpha: 0.2);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
    }

    &.disabled {
      .content {
        color: #8b8d91;
      }
    }
  }

  &.loading {
    padding-right: 60px;
    padding-left: 20px;
    pointer-events: none;
  }
}
</style>
