import {TeacherAPI } from '@/api';
import store from './../index';

const CounterGameStore = {
  namespaced: true,
  state: {
    count: 0,
    countGames: 0,
    loaded: false,
    playlistCount: 0,
    shootlistCount: 0
  },
  mutations: {
    saveData(state, data) {
      state.count = data;
    },
    saveDataGames(state, data) {
      state.countGames = data;
    },
    clearData(state){
      state.count = 0;
    },
    clearDataGames(state){
      state.countGames = 0;
    },
    clearPlaylistCount(state){
      state.playlistCount = 0;
    },
    increasePlaylist(state){
      state.playlistCount = state.playlistCount + 1;
    },
    clearShootlistCount(state){
      state.shootlistCount = 0;
    },
    increaseShootlist(state){
      state.shootlistCount = state.shootlistCount + 1;
    }
    
  },
  actions: {
    
  },
  getters: {
    data(state) {
      return state.count;
    },
    dataGames(state) {
      return state.countGames;
    },
    getPlaylistCount(state) {
      return state.playlistCount;
    },
    getShootlistCount(state) {
      return state.shootlistCount;
    }
  }
};

export { CounterGameStore }
