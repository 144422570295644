<template>
  <button @click="$emit('input', !value)" :class="value? 'on' : 'off'" class="checkbox-director">
    <div class="round">
      <div v-if="value" class="flag">
        <svg  width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.999773 4.24245L3.12109 6.36377L7.36373 2.12113" stroke="#00465B"/>
        </svg>
      </div>
    </div>
    <div class="title">
      {{title}}
    </div>

  </button>
</template>
<script>
   export default {
    props:{
      value:{},
      title:{},
    },
    components:{
     },
    data() {
      return {
        flag: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  //
 .checkbox-director{
   width: 221px;
   height: 40px;
   transition: all 0.2s ease-in-out;
   display: flex;
   align-items: center;
   justify-content: center;
   .title{
     font-size: 14px;
     text-align: right;
   }
   &.on{
     color: #FFFFFF;
     background: #8CC63F;
     border: 1px solid #8CC63F;
     border-radius: 4px;
   }
   &.off{
     color: #00465B;
     background: #FFFFFF;
     border: 1px solid #00465B;
     box-sizing: border-box;
     border-radius: 4px;
   }
   .round{
     margin: 2px 5px 0 5px;
     position: relative;
     border-radius: 50%;
     width: 12px;
     height: 12px;
     background: #FFFFFF;
     border: 1px solid #00465B;
     .flag{
       top: -3px;
       left: 1px;
       position: absolute;
     }
   }
 }
  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

