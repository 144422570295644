<template>
  <main style="background: #005d79">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get("ניהול מכשירים") }}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white">
          <svg
            width="22"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="position: absolute; top: 4px; right: 24px"
          >
            <path
              d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
              fill="white"
              stroke="white"
              stroke-width="0.2"
            />
          </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
      :loading="load"
      class="sm-cont"
      style="background: #f2f2f2; height: 100%"
    > 
      <div style="width: 100%; text-align: center; margin-top: 20px; margin-bottom: 30px;" v-if="userData.data.length>0 && userData!=null">
        <div class="head" style="margin-bottom: 20px">
          <span
            style="
              font-size: 18px;
              font-family: App;
              font-weight: 500;
              line-height: 19px;
            "
            >{{ $ml.get("המכשירים שלך") }}</span
          >
          <br />
          <span
            style="
              font-size: 16px;
              font-family: App;
              font-weight: 500;
              line-height: 19px;
            "
            >{{
              $ml.get("זה מפרט את המכשירים שבהם אתה משתמש כעת בחשבון שלך")
            }}</span
          >
        </div>
        <div
          v-for="(device, index) in userData.data"
          :key="index"
          class="devices"
          style="
             padding: 10px 0px;
            direction: ltr;
            display: flex;
            width: 95%;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            margin: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          "
        >
        <hr class="style-six">
        <div style="padding: 10px 20px;
            direction: ltr;
            flex-direction: row;
            justify-content: space-between;">
          <div style="padding:6px 0px">
            <svg
              enable-background="new 0 0 24 24"
              height="60px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 24 24"
              width="50px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill="#005d79"
                d="M23,9h-3V5c0-0.6-0.4-1-1-1H1C0.4,4,0,4.4,0,5v13c0,0.6,0.4,1,1,1h8v2H6c-0.6,0-1,0.4-1,1v2h18c0.6,0,1-0.4,1-1V10  
                C24,9.4,23.6,9,23,9z M14,23H6v-1h8V23z M14,21h-3v-2h3V21z M14,17H2v-1h12V17z M14,10v5H2V6h16v3h-3C14.4,9,14,9.4,14,10z M22,22  
                h-6v-1h6V22z M22,20h-6v-7h6V20z M22,12h-6v-1h6V12z"
              />
            </svg>
          </div>
          <div
            style="
              direction: ltr;
              display: flex;
              width: 100%;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div
              style="
                direction: ltr;
                display: flex;
                width: 100%;
                padding: 0px 5px;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>{{ device.browserName + "/" + device.os }}</div>
              <div>
                {{
                  device.device != "Other"
                    ? device.device
                    : "Device " + (index + 1)
                }}
              </div>
            </div>
            <div
              style="
                direction: ltr;
                display: flex;
                width: 100%;
                padding: 0px 5px;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <div>
                {{
                  device.ipLocation.country.name +
                  "/" +
                  device.ipLocation.city.name
                }}
              </div>
              <div>
                {{ device.lastSeenAt.global | moment("DD.MM.YY hh:mm:ss") }}
              </div>
            </div>
          </div>
        </div>
        <div style="justify-content: center;">
            <app-btn :loading="loading" :class="device.visitorId==objectCurr.visitorId?'disabled':false" @click="deleteDevice(index)" class="dev-btn" style="margin:auto;width: 140px; height: 36px; font-size: 12px; background-color: rgb(255, 170, 43);">{{ $ml.get('מחק מכשיר') }}</app-btn>
        </div>
        <hr class="style-six" style="margin-top: 20px;">
        </div>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px; margin-bottom: 30px;" v-else>
        <div class="head" style="margin-bottom: 20px">
          <span
            style="
              font-size: 18px;
              font-family: App;
              font-weight: 500;
              line-height: 19px;
            "
            >{{ $ml.get("המכשירים שלך") }}</span
          >
          <br />
          <span
            style="
              font-size: 16px;
              font-family: App;
              font-weight: 500;
              line-height: 19px;
            "
            >{{
              $ml.get("זה מפרט את המכשירים שבהם אתה משתמש כעת בחשבון שלך")
            }}</span
          >
        </div>
        <div style="width: 100%; text-align: center;">
          <span
            style="
              font-size: 16px;
              font-family: App;
              font-weight: 500;
              line-height: 19px;
            "
            >{{
              $ml.get("לא נמצאו מכשירים בחשבון שלך")
            }}</span>
        </div>
        
      </div>
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Switcher from "@/components/Switcher.vue";
import IconsTab from "@/components/IconsTab";
import Btn from "@/components/ui/app-btn/AppBtn";
import Confirm from "@/components/modals/modal/Confirm";
import { UserAPI } from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      load: false,
      userData: null,
      loading: false,
      objectCurr:null,
    };
  },
  created() {
    this.getVisitorData();
    this.sync();
  },
  computed: {
    ...mapGetters({}),
  },

  methods: {
    async getVisitorData() {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true
      });
      this.objectCurr = visitorData;
    },
    deleteDevice(index)
    {
      this.loading = true;
      let arr = this.userData;
      arr.data.splice(index, 1);
      // this.userData.data = arr;
      UserAPI.updateDevices(arr).then(res=>{
        this.userData = res.data.data;
        this.loading = false;
      }).catch(err=>{
        this.loading.false;
      });
    },
    sync() {
      this.load = true;
      UserAPI.getDevices()
        .then((res) => {
          this.userData = res.data.data;
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
        });
    },
  },
  components: {
    Confirm,
    Btn,
    Switcher,
    IconsTab,
    SimpleHeader,
    SidebarToggler,
  },
};
</script>

<style lang="scss" scoped>
.dev-btn{
  &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
}
.style-six {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.titles-delete {
  font-size: 14px;
}
.titles-delete:hover {
  cursor: pointer;
}
.vti__input {
  background: transparent !important;
}
.vue-tel-input {
  direction: ltr;
  max-width: 250px;
}
main {
  overflow: hidden;
  padding-bottom: 20px;
  background: #f2f2f2;
  header {
    padding: 15px 10px;
    padding-bottom: 0;
    button {
      color: #fff;
    }
  }
  .cancel {
  }

  .disc {
    background: #ffffff;
    border-radius: 10px;
    margin: 0 10px;
    padding: 5px;
    padding-bottom: 0;

    .bordered {
      padding: 13px 20px;
      border: 1px solid #fff;
      display: flex;
      position: relative;

      align-items: flex-start;

      color: #00465b;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a {
        font-size: 14px;
        line-height: 19px;
        color: #149cc6;
      }
      p {
        &.gray {
          color: #4d4d4d;
        }
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465b;
        margin: 0;
      }
      &.no-subs {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a {
          font-size: 14px;
          line-height: 19px;
          color: #149cc6;
        }
      }
    }
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #dbdbdb;
    margin: 0 auto;
    position: relative;
    color: #fff;
    text-align: center;
    line-height: 100px;
    font-size: 38px;
    input {
      position: absolute;
      top: 0;
      left: -99999px;
      opacity: 0;
    }
    .edit {
      padding: 0;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #00465b;
      color: #dbdbdb;
      left: 0;
      bottom: 0;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNDMUMxQzEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDE2IDI3Ny4zMzNIMjc3LjMzM1Y0MTZoLTQyLjY2NlYyNzcuMzMzSDk2di00Mi42NjZoMTM4LjY2N1Y5Nmg0Mi42NjZ2MTM4LjY2N0g0MTZ2NDIuNjY2eiIvPjwvc3ZnPg==);*/
      /*background-position: center;*/
      /*background-repeat: no-repeat;*/
      background-size: 30px;
      &:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        top: 50%;
        left: 50%;
      }
    }
  }
  .inputs {
    padding-bottom: 20px;
    &.pasww {
      p {
        margin: 0;
        margin-top: 7px;

        width: unset !important;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465b;
      }
      .input-r {
        width: 53% !important;
        @media screen and (max-width: 360px) {
          width: 50% !important;
        }
      }
      .section {
        padding: 0 15px;
        @media screen and (max-width: 360px) {
          padding: 0 !important;
        }
      }
    }
    .first {
      background: #ffffff;
      border-radius: 10px;
      margin: 0 10px;
      padding: 17px 20px;
      .section {
        margin-top: 10px;

        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
        &.old {
          &:after {
            content: "מספר הטלפון";
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            bottom: -16px;
            color: #a2a2a2;
          }
        }
        &.new {
          &:after {
            content: "אישור הסיסמה";
            position: absolute;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            bottom: -16px;
            line-height: 16px;
            text-align: right;
            color: #a2a2a2;
          }
        }
        p {
          margin: 0;
          margin-top: 7px;
          width: 70px;

          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #00465b;
        }
        .input-r {
          width: 140px;
          margin-top: 10px;
          margin-right: 9px;
          font-size: 16px !important;
          position: relative;
        }
        .input-register {
          .input-reg {
            line-height: 22px;
            text-align: right;
            color: #00465b;
          }
        }
        input {
          width: 100%;
        }
      }
    }
  }
  .pre-bot-text,
  .bot-text {
    display: block;
    color: #fff;
    padding: 0 20px;
  }
  .bot-text {
    margin-top: 20px;
  }
}
.icons-tab-w {
  margin-top: 25px;
}
.logo-wrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;

  margin: 10px 10px 20px 10px;
  /*.no-photo {*/
  /*  width: 70px;*/
  /*  height: 70px;*/
  /*  border-radius: 100%;*/
  /*  background: #eee;*/

  /*  display: flex;*/
  /*  align-items: center;*/
  /*  justify-content: center;*/

  /*  svg {*/
  /*    width: 50px;*/
  /*    fill: #aaa;*/
  /*  }*/
  /*}*/
}
button {
  &.cancel {
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    background-color: white;
    border: none;
    box-shadow: none;
    color: #149cc6;
  }
}
//
.isActive {
  font-size: 14px;
  opacity: 1;
  margin-top: 17px;
  display: block;
  transition: all 0.4s ease-in-out;
}
.unActive {
  transition: all 0.4s ease-in-out;
  font-size: 0 !important;
  opacity: 0;
  margin-top: 0 !important;
  display: none;
}
.password {
  position: relative;
  margin: 2px 0;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  color: #828282;
  .question {
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #00465b;
    .terms {
      left: 30px;
      position: absolute;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: right;
      color: #ffffff;
      max-width: 145px;
    }
    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      width: 88%;
      text-align: start;
    }
  }
  .show-b {
    cursor: pointer;
    position: absolute;
    right: 63px;
    padding: 9px;
    top: -13px;
    margin-right: 5px;
    span {
      svg {
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }
  .answer {
    background: white;
    transition: all 0.4s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .error-v {
      margin-top: 20px;
      position: absolute;
      left: 68px;
    }
  }
}
.save-b {
  width: 180px;
  margin: 30px auto 30px;
}
.button-subcr {
  font-size: 14px;
  line-height: 19px;
  color: #149cc6;
}
</style>
