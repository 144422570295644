<template>
  <div class="report-status-element">
    <div class="header">
      <div  class="name">{{arrayed ?  $store.getters['playlist-gifts/getById'](title).name : title  }}</div>
      <div class="term">
        <button @click="data.enabled=!data.enabled">{{!data.enabled?      $ml.get('הפעלה') : $ml.get('ביטול') }}</button>

      </div>
    </div>
    <div class="element-wrapper" :class="!data.enabled? 'unactive' : ''">
      <div class="row">
        <div class="date-value">{{$ml.get('יעד חודשי')}}</div>
        <div class="status">
          <div class="status-bar">
            <RangeSlider
              :uncative="!data.enabled"
              class="r-slider"
              :key="rerenderM"
              :max="data.month.range"
              v-model="data.month.target" />
          </div>
        </div>
      </div>
      <div class="row">

        <div class="date-value">{{$ml.get('יעד שנתי')}}</div>

        <div class="status">

          <div class="status-bar">
            <RangeSlider
              :uncative="!data.enabled"
              class="r-slider"
              :key="rerenderY"
              :max="data.year.range"
              v-model="data.year.target" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RangeSlider from '../../ui/RangeSlider'
  export default {
    props:{
      data:{

      },
      arrayed:{
        default:false,
      },
      title:{

      },
      titleColor:{
        default: '#00465B'
      }
    },
    mounted() {
        this.$store.getters['playlist-gifts/getById']('152');
      },
    components:{
      RangeSlider
    },

    data() {
      return {
        flag: false,
        rerenderM: '',
        rerenderY: '',

      }
    },
  }
</script>

<style lang="scss" scoped>

  .report-status-element{
    width: 100%;
    padding: 0 20px;
    .header{
      padding: 15px 0 10px 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .name{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #00465B;
      }
      .term{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        font-size: 12px;
        text-align: center;
        color: #00465B;
        button{
          font-size: 12px;
          line-height: 16px;
          color: #149CC6;
        }
      }
    }
    .element-wrapper{
      padding: 5px 20px;
      box-sizing: border-box;
      border-radius: 4px;
      background: white;
      &.unactive{
        pointer-events: none;
        opacity: 0.95;
        .row{
          .date-value{
            color: #C4C4C4;
          }
        }
      }
      .row{
        margin: 15px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .date-value{
          font-size: 14px;
          color: #000000;
        }
        .status{
          display: flex;
          align-items: center;
          flex-direction: column;
          .value{
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }
          .status-bar{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 160px;
            height: 8px;
            background: #C4C4C4;
            border-radius: 4px;
            .bg{
              background: rgb(140, 198, 63);

              height: 100%;
              border-radius: 4px 4px  4px  5px;
            }
          }
        }
      }
    }

  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

