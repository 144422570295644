<template>
  <div class="reg">
    <div class="title">...ברוכים הבאים</div>
    <form @submit="onSubmit">
      <app-text-input
        type="tel"
        v-model="phone"
        maxlength="10"
        label="הקלד מספר טלפון"
      />
      <app-btn :loading="loading" type="submit">שלח</app-btn>
    </form>

    <!-- <transition name="modal-fade-scale">
      <PhoneConfirm 
        v-if="show_modal" 
        :phone="phone"
        @confirm="regByPhone" 
        @close="show_modal = false"
      />
    </transition> -->
  </div>
</template>

<script>
// import PhoneConfirm from "@/components/modals/PhoneConfirm";
import { AuthAPI } from '@/api';

export default {
  data: () => ({
    phone: "", 
    prefix: "972",
    loading: false,
    // show_modal: false,
  }),
  methods: {

    onSubmit(e) {
      e.preventDefault();
      // this.show_modal = true;
      this.loading = true;
      this.regByPhone().finally(() => {
        this.loading = false;
      });
    },

    async regByPhone() {
      const data = await AuthAPI.regWithPhone({ phone: this.phone })
        .then(res => res.data);

      this.$store.commit("user/auth", data.auth);
      await this.$store.dispatch("user/updateData");

      if (data.is_new) {
        this.$router.push('class');
      } else {
        this.$router.push('/main');
      }
    },
  },
  components: {
    // PhoneConfirm,
  }
};
</script>

<style lang="scss" scoped>

.reg {

  display: flex;
  flex-direction: column;
  align-content: center;

  background: #56bcdc;
  border-radius: 10px;
  padding: 20px;

  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-content: center;

    > * {
      margin-bottom: 20px;
    }
  }
}

</style>
