<template>
  <div class="goal-section">
     <div  class="head">
       <div  :style="{color: gameClass.color}" class="title">
         <div
           class="color"
           :style="{background: gameClass.color}"
         ></div>
         <TitleTranslated :translate="gameClass.translation" />
       </div>
       <div  :style="{color: gameClass.color}" class="name">   <span>{{ $ml.get('סך המשחקים ששוחקו')}}:</span><span>{{gameClass.total}}</span></div>
     </div>
    <div class="list">
      <div class="list-element" :key="i*11" v-for="(elem, i) in gameClass.gifts">
        <div  v-if="elem.stats.played>0" class="played">
        <div
          :style="{color: gameClass.color}"
          class="title">
          <div
            class="round"
            :style="{background: gameClass.color}"
          ></div>
          <NameTranslated :translate="elem.details.translation" />
        </div>
        <div v-if="Object.values(elem.stats.playlists).length>0" class="info">

          <span class="name"><span> משחקים ששוחקו בכיתה: </span><span>{{Object.values(elem.stats.playlists).length}}</span></span>
            <div  style="margin-bottom:15px;max-height: 20px"   :key="index*45" v-for="(el,index) in Object.values(elem.stats.playlists)" class="links-w">
              <span  style="color: #149CC6;" v-if="index<Object.values(elem.stats.playlists).length && index>0">, </span>
              <a style="max-height: 20px; height: 20px" :href="el.link">
                <NameTranslated :translate="el.details.translation" />
              </a>
            </div>
        </div>
      </div>
      </div>
      <div  >
        <div v-if="gameClass.gifts.filter(item=>item.stats.played===0).length>0" class="unplayed">

        <div class="title">
        <div
          class="round"
          style="background: #C4C4C4 "
        ></div>
          {{ $ml.get('ערכים שלא שוחקו')}}:
        </div>
      <div class="info">
        <a :key="i*2" v-for="(el,i) in gameClass.gifts.filter(item=>item.stats.played===0)" :href="el.link">
          <NameTranslated :translate="el.details.translation" />
          <span v-if="i< gameClass.gifts.filter(item=>item.stats.played===0).length-1"> ,</span></a>
      </div>

        </div>
      </div>
      </div>
    </div>
</template>
<script>
import TitleTranslated from "@/components/translated/TitleTranslated";
import NameTranslated from "@/components/director/director-reports/NameTranslated";
  export default {
    props:{
      goal:{

      },
      gameClass:{},
    },
    components:{
      TitleTranslated,
      NameTranslated
    },
    data() {
      return {
        arr: [
          {name: ' השגריר', link: 'google.com'},
          {name: ' גולף עפרון', link: 'google.com'}
        ]
      }
    },
    mounted() {

    }
  }
</script>

<style lang="scss" scoped>
 .goal-section{
   padding: 10px 20px 10px 20px;
   .head{
     display: flex;
     align-items: center;
     justify-content: space-between;
     .name{
       font-size: 14px;
       text-align: right;

     }
     .title{
       display: flex;
       align-items: center;
       font-weight: bold;
       font-size: 14px;
       text-align: right;

       .color{
         margin-left: 3px;
         width: 12px;
         height: 12px;
         border-radius: 2px;
       }
     }
   }
   .list{
     padding-bottom: 10px;
     .list-element{

       margin-top: 20px;
       .title{
         display: flex;
         align-items: center;
         font-size: 14px;
         color: #6D597A;
         .round{
           margin-left: 6px;
           padding: 3px;
           width: 6px;
           height: 6px;
            border-radius: 50%;
         }
       }
       .info{
         margin-top: 10px;
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         .name{
           margin-top: -4px;
           margin-left: 7px;
           font-size: 14px;
           color: #4D4D4D;
         }
         a{
           font-weight: normal;
           font-size: 14px;
           color: #3FBCD4;
         }
       }
     }
   }
 }
  .unplayed{
    margin-top: 26px;
    padding: 10px 0;
    padding-left: 25px;
    margin: 5px 0;
    padding: 10px 15px;
    background: #F2F2F2;
    border-radius: 10px;
    display:inline-block;
    .title{
      display: flex;
      align-items: center;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      color: #4D4D4D;
      .round{
        margin-top: 3px;
        margin-left: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    .info{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .name{
        margin-left: 7px;
        font-size: 14px;
        color: #4D4D4D;
      }
      a{
        font-weight: normal;
        font-size: 14px;
        color: #3FBCD4;
      }
    }
  }
</style>

