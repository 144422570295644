<template>
  <main class="img-about-bg" dir="rtl">
    <simple-header >
      <template v-slot:title>
        <span>{{$ml.get('אודות')}}</span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </simple-header>
    <section :style="{direction:direction}" style="background: white" class="sm-cont">

      <span class="fugaz">Playlist</span>
      {{$ml.get("מכניס אתכם לעולם של משחקים יצירתיים")}}
      {{$ml.get("ופשוטים, משחקים ללא ציוד וללא מפסידים") +"!"}}
      <br />
      <br />
      {{$ml.get("מאות רעיונות למשחקים חברתיים מחוץ למסך הם האלטרנטיבה למסכים")}}.
      <br />



      <br />
      {{$ml.get("המשחקים מחברים את הילדים לילדות חברתית בריאה, ואת המבוגרים לילד שבהם,למשפחה, ולכיף הפשוט של הביחד")}}.
      <br />

      <br />
      {{$ml.get("ב")}} <span class="fugaz">Shootlist</span>
      {{$ml.get("אתם מקבלים מגוון של מאות שאלות לשיחות זורמות ומחברות")}}.
      <br />
      <br />
      <span class="fugaz">Playlist</span>
      {{$ml.get("מאפשרת לכם למצוא חברים חדשים לשחק איתם, וליצור או להצטרף לקהילה משחקת בשכונותכם")}}.
      <br />

      <br />
      {{$ml.get("כהורים אתם מקבלים אפשרות לקבוע אלו תכונות אופי יחוזקו אצל ילדיכם באמצעות המשחקים ב")}}
      <span class="fugaz">Playlist</span> <br />
      <br />
      <span @click="pushToPresentationLink" style="font-family: 'Kartiv'; font-size: 18px; font-weight: bold;">{{$ml.get("מצגת פלייליסט")}}</span>
      <br/>
      <span @click="pushToSiteLink" style="font-family: 'Kartiv'; font-size: 18px; font-weight: bold;">{{$ml.get("אתר פלייליסט")}}</span>
      <br/>
      <br/>
      <span class="version">PlayList v. {{ version }}</span>
    </section>
  </main>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import { AuthAPI } from "@/api";
  export default {
    data() {
      return {
        version: ""
      };
    },
    components: {
      SimpleHeader,
      SidebarToggler
    },
    computed:{
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    methods:{
      pushToSiteLink(){
        AuthAPI.sitelink().then(()=>{
           window.location.href = 'https://bit.ly/3pqFdez';
        })
      },
      pushToPresentationLink(){
        AuthAPI.presentation().then(()=>{
          if(this.$ml.current=='heb')
          {
            window.location.href = 'https://bit.ly/44NrNZZ';
          }
          else
          {
            window.location.href = 'https://bit.ly/3I1XK7d';
          }
        })
      }
    },
    created() {
      window.fbq("track", "אודות");
      if (cordova) {
        cordova.getAppVersion.getVersionNumber().then(version => {
          this.version = version;
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  main {
    background-color: #eb5757;
    background-position: bottom -40px right -40px;
    background-repeat: no-repeat;
    background-size: auto;
    line-height: 110%;
    section {
      padding: 20px;
    }
    .version {
      font-size: 14px;
    }
  }
</style>
