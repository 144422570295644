import {UserAPI, SubscribeAPI} from "@/api";
import store from "@/store";
import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';
export default {
  namespaced: true,
  state: {
    isDefaultUser:false,
    email:null,
    letsSub:false,
    prices: null,
    data: {},
    loaded: false,
    logged: false,
    pricesLoaded: false,
    no_pin: false,
    parent: false,
    school: {},
    loadedMain: false,
    loadSchool: false,
    currentPosition: null,
    setDefaultPosition: null,
    playlist_counter:null,
    shootlist_counter:null
  },
  mutations: {
    setDefaultUser(state,data)
    {
      state.isDefaultUser=data;
    },
    decreaseGamesCounter(state)
    {
      state.playlist_counter--;
    },
    decreaseShootlistCounter(state)
    {
      state.shootlist_counter--;
    },
    increaseGamesCounter(state)
    {
      state.playlist_counter++
    },
    increaseShootlistCounter(state)
    {
      state.shootlist_counter++
    },
    updateGameCounter(state,data)
    {
      state.playlist_counter = data;
    },
    updateShootlistCounter(state,data)
    {
      state.shootlist_counter = data;
    },
    setSub(state,data)
    {
      state.letsSub = data;
    },
    setEmail(state,data)
    {
      state.email = data;
    },
    resetTotalGames(state,data)
    {
      state.data.subscribe_total_games = (state.data.subscribe_total_games-data);
    },
    loadedMain(state)
    {
      state.loadedMain = true;
    },
    decreaseSubsShootlistCounter(state) {
      if (state.data.subscribe_shootlist > 0) {
        state.data.subscribe_shootlist--;
      }
    },
    decreaseSubsGamesCounter(state) {
      if (state.data.subscribe_games > 0) {
        state.data.subscribe_games--;
      }
    },
    increaseSubsShootlistCounter(state) {
      if (state.data.subscribe_shootlist > 0) {
        state.data.subscribe_shootlist++;
      }
    },
    increaseSubsGamesCounter(state) {
      if (state.data.subscribe_games > 0) {
        state.data.subscribe_games++;
      }
    },
    increaseGameCounter(state) {
      state.data.games_summary++;
    },
    authParent(state, code) {
      state.parent = true;
      axios.defaults.headers.common["Parent-Authorization"] = code;
    },
    setPinState(state, flag) {
      state.no_pin = flag;
    },
    loaded(state) {
      state.loaded = true;
    },
    loadPrices(state)
    {
      state.pricesLoaded = true;
    },
    auth(state, token) {
      state.logged = true;
      localStorage.setItem("auth", token);
      axios.defaults.headers.common["Authorization"] = token;
      config.API.defaults.headers.common["Authorization"] = token;
    },
    expires_at(state, expires_at) {
      localStorage.setItem("expires_at", expires_at);
    },
    clearSessions(state,data){
      let sessions = localStorage.getItem('sessions');
      sessions = JSON.parse(sessions)
      if(sessions!=null && sessions.length>0)
      {
      let newSessions = [];
      sessions.forEach(element=>{
        if(element.surname!=state.data.surname && element.name != state.data.name)
        {
          newSessions.push(element);
        }
        else
        {
          if(element.phone!=state.data.phone)
          {
            newSessions.push(element);
          }
        }
      })
      localStorage.setItem('sessions', JSON.stringify(newSessions))
    }
    },
    saveData(state, res) {

      var key = process.env["VUE_APP_SECURE_KEY"];
      var encrypted = res.payload;
      var encrypted_json  = JSON.parse(Base64.decode(encrypted));
      var decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(Base64.encode(key)),{
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv),
                        mode: CryptoJS.mode.CBC
                      });
      let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      if(data.id_user==1)
      {
        state.isDefaultUser=true;
      }
      else
      {
        state.isDefaultUser=false;
      }
      state.data = data;
      state.setDefaultPosition = state.data.position;
      state.playlist_counter = data.playlist_counter;
      state.shootlist_counter = data.shootlist_counter;
      state.currentPosition = state.data.position;
    },
    setCurrentPosition(state, pos)
    {
      state.currentPosition = pos;
      state.data.position = pos;
    },  
    savePrices(state, data) {
      state.prices = data;
    },
    saveSchool(state, data) {
      state.school = data;
      state.loadSchool = true;
      
    },
    logout(state, data) {
      state.data = {};
      state.logged = false;
      delete axios.defaults.headers.common["Authorization"];
      delete config.API.defaults.headers.common["Authorization"];
      localStorage.removeItem("auth");
      localStorage.removeItem("expires_at");
    }
  },
  actions: {
    decreaseGamesCounter({state,commit}) {
      if(!state.isDefaultUser)
      {
        commit('decreaseGamesCounter')
        UserAPI.decreaseGamesCounter().then(res=>{
          // commit('updateGameCounter',res.data.count)
        }).catch(err=>{
          commit('increaseGamesCounter')
        })
      }
      else
      {
        store.commit('userDefault/decreaseGamesCounter')
      }
    },
    decreaseShootlistCounter({state,commit}){
      if(!state.isDefaultUser)
      {
        commit('decreaseShootlistCounter')
        UserAPI.decreaseShootlistCounter().then(res=>{
          // commit('updateShootlistCounter',res.data.count)
        }).catch(err=>{
          commit('increaseShootlistCounter')
        })
      }
      else
      {
        store.commit('userDefault/decreaseShootlistCounter')
      }
    },
    defaultGamesCounter({commit}) {
      UserAPI.defaultGamesCounter().then(res=>{
        commit('updateGameCounter',res.data.count)
      }).catch(err=>{
      })
    },
    defaultShootlistCounter({commit}){
      UserAPI.defaultShootlistCounter().then(res=>{
        commit('updateShootlistCounter',res.data.count)
      }).catch(err=>{
      })
    },
    updateData(context) {
      return axios.get("/user/info").then(res => {
        context.commit("saveData", res.data);
        context.commit("loadedMain");
        return res.data;
      });
    },
    updatePrices({commit}){
      return SubscribeAPI.getPrices().then(res=>{
        commit('savePrices',res.data.data);
        commit('loadPrices')
        return res.data.data;
      })
    },
    updateVars({commit}, payload){
      return UserAPI.updateVars(payload).then(res=>{
        commit('saveData',res.data);
      }).catch(()=>{
        return undefined;
      })
    },
    updateSchool({commit}, payload){
      return UserAPI.getSchool(payload).then(res=>{
        commit('saveSchool',res.data);
        return res.data;
      }).catch(()=>{
        commit('saveSchool',undefined);
        return undefined;
      })
    }
  },
  getters: {
    getDefaultUser(state)
    {
      return state.isDefaultUser;
    },
    getLoadedMain(state)
    {
      return state.loadedMain;
    },
    getPlaylistCounter(state)
    {
      return state.playlist_counter;
    },
    getShootlistCounter(state)
    {
      return state.shootlist_counter;
    },
    getSub(state)
    {
      return state.letsSub;
    },
    getEmail(state){
      return state.email;
    },
    getPrices(state){
      return state.prices;
    },
    getDefaultPos(state)
    {
      return state.setDefaultPosition;
    },
    getCurrentPos(state)
    {
      return state.currentPosition;
    },
    token(state) {
      return localStorage.getItem("auth");
    },
    auth(state, getters) {
      return getters.token;
    },
    expires_at(state)
    {
      return localStorage.getItem("expires_at");
    },
    auth_expires_at(state, getters) {
      return getters.expires_at;
    },
    isLogged(state) {
      return state.logged;
    },
    isPricesLoaded(state) {
      return state.pricesLoaded;
    },
    isSchoolLoaded(state) {
      return state.loadSchool;
    },
    school(state){
      return state.school;
    },
    translatedSchool(state){
      if (store.getters['lang-store/data']){
        return state.school.translation.find(item=>{
          if(store.getters['lang-store/data']=='eng')
          {
            return item.langKey === "en"
          }
          else
          {
          return item.langKey === store.getters['lang-store/data']
          }
        }).data
      }
    },
    isTeacher(state){
     return state.data.position === 'teacher' || state.data.position==='prof-teacher'
    },
    playlistDidntSeeSettings(state){
      return state.data.playlistDidntSeeSettings
    },
    playlistFirstTime(state){
      return state.data.playlistFirstTime
    },
    shootlistFirstTime(state){
      return state.data.shootlistFirstTime
    },
    studentEducational(state){
      return state.data.studentEducational
    },
    position(state){
      return state.data.position
    },
    isProfTeacher(state){
      return  state.data.position==='prof-teacher'
    },
    isLoaded(state) {
      return state.loaded;
    },
    data(state) {
      return state.data;
    },
    hasSubs(state) {
      if (!state.data.subscribe) return false;
      var time = Math.floor(Date.now() / 1000);
      return state.data.subscribe + state.data.subscribe_interval > time;
    },
    checkSubs(state){
      var time = Math.floor(Date.now() / 1000);
     if (state.data.id_school){
      return  new Date(state.school.endDate) > time
     } else {
       if (!state.data.subscribe) return false;

       return state.data.subscribe + state.data.subscribe_interval > time;
     }
    },
    validTIme(state){
        var time = Math.floor(Date.now() / 1000);
        if (state.data.id_school){
          return  new Date(state.school.endDate) > time
        }
    },
    canAccessGames(state, getters) {
      return getters.hasSubs || state.data.subscribe_games != 0;
    },
    noPin(state) {
      return state.no_pin;
    },
    isParent(state) {
      return state.parent;
    }
  }
};
