import store from "@/store/index";
const DefaultPlaylistSettings = function() {

  this.gender = 'child';
  this.seen = [1,2];
  this.activities =  ['1', '2', '3'];
  if (store.getters['playlist-locations/isLoaded']){
    this.locations =store.getters['playlist-locations/getByType']('child')
        .filter(item => item.is_default)
        .map(item => {
          return item.id_setting;
        });
  }
  this.levels = [3,2,1];
  if (store.getters['user/data'].position!==null  && store.getters['user/data'].position!=='director' ){
    this.show_teacher_favourite= true;
    this.show_school_inventors= true;
    this.show_director_favorite = true;
  } else {
    this.show_teacher_favourite= false;
    this.show_school_inventors= false;
    this.show_director_favorite = false;
  }
  this.previous_game = false;
  this.viewed_playlist = [];
  this.life_skills = false;
  // this.viewed_playlist_seen = [];
};

export { DefaultPlaylistSettings }