<template>
  <div class="card-game" :id="`#card_game_${data._uuid}`">
    <!-- <GiftSwitch :section="section" @select="setSection"/> -->
    <TitleBlock :data="data"/>
    <AudioPlayer v-model="showed" v-if="showed && data.audio!=='' && $ml.current == 'heb'" :file="data.audio"/>
    <AudioPlayer v-model="showed" v-else-if="showed && data.audio!=='' && $ml.current == 'ar'" :file="data.audioAr!=null?data.audioAr:data.audio"/>
    <AudioPlayer v-model="showed" v-else-if="showed && data.audio!=='' && $ml.current == 'eng'" :file="data.audioEng!=null?data.audioEng:data.audio"/>
    <AudioPlayer v-model="showed" v-else-if="showed && data.audio!=='' && $ml.current == 'ru'" :file="data.audioRu!=null?data.audioRu:data.audio"/>
    <div v-if="section == 'main'" class="section-container">
      <transition name="fade" mode="out-in">
        <MainSection style="max-height: 627px !important;" :data="data"/>
      </transition>
    </div>
    <div v-else-if="section == 'gift'" class="section-container" style="background: #F2F2F2;">
      <transition name="fade" mode="out-in">
        <GiftSection :section="section" style="max-height: 627px !important;" :data="data" @close="section = 'main'"/>
      </transition>
    </div>
    <ControlsPanel :index="indexs" :uuid="data._uuid" @customChange="handleChange" :dots="dots" :muted="muted" :section="section" style="height: 45px !important;"/>
    <div class="back-placeholder">
      {{$ml.get("לא שחקתי")}}
    </div>
    <div class="next-placeholder">
      {{$ml.get("שחקתי")}}
    </div>
  </div>
</template>

<script>
import MainSection from './MainSection';
import GiftSection from './GiftSection';
import GiftSwitch from './GiftSwitch';
import TitleBlock from './TitleBlock';
import AudioPlayer from "../AudioPlayer";
import ControlsPanel from './ControlsPanel.vue';
import { mapGetters } from "vuex";
export default {
  data() {
    return  {
      section: 'main',
      transition: 'fade-move-left',
      showed: false,
      uiid: null,
    }
  },
  computed: {
    ...mapGetters({
      currentItem: "playlist/currentItem",
      sectiona:"playlist/prop"
    }),
  },
  watch: {
    'section': function (to, from) {
      if(to == 'main') {
        this.transition = 'fade-move-left';
      } else {
        this.transition = 'fade-move-right';
      }
    },
    // currentItem:{
    //   handler(newValue, oldValue){
    //     this.section = 'main';
    //   },
    //   deep:true
    // }
  },
  mounted(){
    this.$root.$on('CardGame', (res) => {
        this.uiid=res;
        if(this.data._uuid==this.uiid)
        {
          this.setSections()
        }
        })
        this.$root.$on('CardGameClose', () => {
            this.section='main';
        })
        if(this.data._uuid==this.uiid)
        {
          this.section = this.sectiona;
        }
  },
  methods: {
    handleChange (event) {
       this.$emit("customChange")
    },
    setSection(section) {
      this.section = section;
    },
    setSections() {
      if(this.section=='main')
      {
        this.section = 'gift'
        // this.$store.commit('playlist/setProp','gift');
      }
      else
      {
        this.section = 'main'
        // this.$store.commit('playlist/setProp','main');
      }
    },
  },
  components: {
    MainSection,
    GiftSection,
    GiftSwitch,
    TitleBlock,
    AudioPlayer,
    ControlsPanel
},
  props: {
    data: {},
    dots:null,
    muted:true,
    indexs:null
  },
}
</script>

<style lang="scss" scoped>

.card-game {
  border-radius: 14px;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 15 !important;

  .back-placeholder,
  .next-placeholder {
    white-space: nowrap;
    height: 80px;
    font-size: 36px;
    line-height: 1;
    position: absolute;
    left: 50%;
    top: 20%;
    padding: 0 40px;
    transform: translate(-50%, -50%);
    color: #fff;
    border-radius: 9999px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    visibility: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .next-placeholder {
    background: transparentize(#73cd49, 0.2);
  }
  .back-placeholder {
    background: transparentize(#fd7384, 0.2);
  }

  &.swiping {
    transition: transform 1s ease, opacity 1s ease;
  }
  &.swipe-left {
    transform: translateX(150%) rotate(60deg) !important;
    opacity: 0;
  }
  &.swipe-right {
    transform: translateX(-150%) rotate(-60deg) !important;
    opacity: 0;
  }

  &.animate,
  &.grapped,
  &.swiping {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);

    .main-section::v-deep {
      // display: none;
      .video .container {
        display: none;
      }
    }
  }

  &.animate {
    transition: transform 2s ease, opacity 2s ease;
  }
  &.animate-left {
    transform: translateX(150%) rotate(60deg) !important;
    opacity: 0;
    .next-placeholder {
      visibility: visible;
    }
  }
  &.animate-right {
    transform: translateX(-150%) rotate(-60deg) !important;
    opacity: 0;
    .back-placeholder {
      visibility: visible;
    }
  }

  .section-container {
    background: #F2F2F2;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    // border-radius: 0 0 14px 14px;
    overflow: hidden;
    
    > * {
      height: 100%;
      width: 100%;
    }

      .footer{
        position: fixed;
        background: white;
        border-radius: 2px;
        bottom: 0px;
        width: 96%;
        overflow: hidden;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }

  }
}

</style>