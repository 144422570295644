import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import _middleware from "./DEPRECATED_middleware";

Vue.use(VueRouter);

import Index from "@/views/Index";
import SubscriptionPlans from "@/views/subs/SubscriptionPlans";
import SubscriptionPlansPresent from "@/views/subs/SubscriptionPlansPresent";
import Slider from "@/views/components-layout/Slider";
import Login from "@/views/auth/Login";
import Welcome from "@/views/Welcome";
import Confirm from "@/views/notifications/Confirm";
import Main from "@/views/Main";
import Shootlist from "@/views/shootlist/Shootlist";
import ShootlistOptions from "@/views/shootlist/ShootlistOptions";
// import TeacherLessons from "@/views/TeacherLessons";
import StudentsList from "@/views/teacher/StudentsList";
import Funkit from "@/views/funkit/Funkit";
import FunkitBasket from "@/views/funkit/FunkitBasket";

import Event from "@/views/event/Event";
import Events from "@/views/event/Events";
import EventCreate from "@/views/event/EventCreate";
import EventJoin from "@/views/event/EventJoin";
import EventSlider from "@/views/event/EventSlider";
import Parent from "@/views/parents/Parent";
import ParentLikes from "@/views/parents/ParentLikes";
import ParentCategories from "@/views/parents/ParentCategories";
import Account from "@/views/user/Account";
import DeviceManager from "@/views/devices/DeviceManager";
import SuccessfulPayment from "@/views/subs/SuccessfulPayment";
import SuccessfulPaymentPresent from "@/views/subs/SuccessfulPaymentPresent";
import ErrorPayment from "@/views/subs/ErrorPayment";
import Liked from "@/views/like/Liked";
import Mish from "@/views/additional/Mish";
import Search from "@/views/games/Search";
import RequestsPlaylistAdd from "@/views/requests/RequestsPlaylistAdd";
import RequestsPlaylist from "@/views/requests/RequestsPlaylist";
import GameInventors from "@/views/playlist/GameInventors";

import RequestsShootlistAdd from "@/views/requests/RequestsShootlistAdd";
import RequestsShootlist from "@/views/requests/RequestsShootlist";
import NewPin from "@/views/auth/NewPin";
import About from "@/views/info/About";
import Terms from "@/views/info/Terms";
import Notifications from "@/views/notifications/Notifications";
import RestoreParentCode from "@/views/auth/RestoreParentCode";
import Workshop from "@/views/additional/Workshop";
import WorkshopSingle from "@/views/additional/WorkshopSingle";
import Contact from "@/views/info/Contact";
import Recover from "@/views/auth/Recover";
import RecoverCheckCode from "@/views/auth/RecoverCheckCode";
import RecoverNewPass from "@/views/auth/RecoverNewPass";
import FunKitDelivery from "@/views/funkit/FunKitDelivery";
import Subscribe from "@/views/subs/Subscribe";
import Video from "@/views/components-layout/Video";
import Delivery from "@/views/additional/Delivery";
import FunZoneConclusion from "@/views/additional/FunZoneConclusion";
import Schools from "@/views/school/Schools";
import TeachersProfile from "@/views/teacher/teacherAdditionalViews/TeachersProfile";
import EducationalClasses from "@/views/teacher/EducationalClasses";
import ParentsEducationalClasses from "@/views/parents/ParentsEducationalClasses";
import EducationalGift from "@/views/teacher/Gift";
import ParentsEducationalGift from "@/views/parents/Gift";

import CommunicationSkillsGift from "@/views/mainMenuPages/Gifts/CommunicationSkillsGift";
import FromTheHassleGift from "@/views/mainMenuPages/Gifts/FromTheHassleGift";
import GamesForAllOccasionsGift from "@/views/mainMenuPages/Gifts/GamesForAllOccasionsGift";
import LearningSkillsGift from "@/views/mainMenuPages/Gifts/LearningSkillsGift";

import SevenPageGift from "@/views/mainMenuPages/Gifts/SevenPage";
import EightPageGift from "@/views/mainMenuPages/Gifts/EightPage";
import NinePageGift from "@/views/mainMenuPages/Gifts/NinePage";
import TenPageGift from "@/views/mainMenuPages/Gifts/TenPage";
import ElevenPageGift from "@/views/mainMenuPages/Gifts/ElevenPage";
import TwelvePageGift from "@/views/mainMenuPages/Gifts/TwelvePage";

import GamesForClasses from "@/views/teacher/GamesForClasses";
import TeacherTutorials from "@/views/teacher/Tutorials";
import GamesGift from "@/views/teacher/GamesGift";
import InventingGames from "@/views/teacher/InventingGames";
import IndividualPlan from "@/views/teacher/IndividualPlan";
import GamesActivity from "@/views/teacher/GamesActivity";
import GiftsTeacher from "@/views/teacher/GiftsTeacher";
import TeacherReports from "@/views/teacher/TeacherReports";
import FeedbackEducational from "@/views/teacher/FeedbackEducational";
import FeedbackGames from "@/views/teacher/FeedbackGames";

import VideoShootlist from "@/views/video/Shootlist";
import VideoPlaylistAdult from "@/views/video/PlaylistAdult";
import VideoPlaylistChildren from "@/views/video/PlaylistChildren";
import VideoPlaylistFamily from "@/views/video/PlaylistFamily";
import VideoParent from "@/views/video/Parent";
import PrivacyPolicy from "@/views/info/PrivacyPolicy";
import CommunicationSkills from "@/views/mainMenuPages/CommunicationSkills";
import LearningSkills from "@/views/mainMenuPages/LearningSkills";
import GamesForAllOccasions from "@/views/mainMenuPages/GamesForAllOccasions";
import FromTheHassle from "@/views/mainMenuPages/FromTheHassle";

import SevenPage from "@/views/mainMenuPages/SevenPage";
import EightPage from "@/views/mainMenuPages/EightPage";
import NinePage from "@/views/mainMenuPages/NinePage";
import TenPage from "@/views/mainMenuPages/TenPage";
import ElevenPage from "@/views/mainMenuPages/ElevenPage";
import TwelvePage from "@/views/mainMenuPages/TwelvePage";

// import TeacherLessonsCategories from "@/views/TeacherLessonsCategories";
// import TeacherLesson from "@/views/TeacherLesson";

//Director
import Director from '@/views/director/Director.vue'
import DirectorGoals from '@/views/director/Goals.vue'
import DirectorReports from '@/views/director/DirectorReports.vue'
//

import { AuthRouter } from "./auth.router";
import { PlaylistRouter } from "./playlist.router";

import {UserAPI} from "@/api";

import multiguard from 'vue-router-multiguard'
import middleware from "./middleware";

const router = new VueRouter({
  routes: [
    AuthRouter,
    ...PlaylistRouter,
    {
      path: "/",
      component: Index,
      name: "index",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/slider",
      component: Slider,
      name: "slider",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/confirm",
      component: Confirm,
      name: "confirm",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/login",
      component: Login,
      name: "login",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/main",
      component: Main,
      name: "main",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/subsPlans",
      component: SubscriptionPlans,
      name: "subsPlans",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/subsPlans/present/:type",
      component: SubscriptionPlansPresent,
      name: "subsPlans-present",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/teacher/students-list",
      component: StudentsList,
      name: "teacher-students-list",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/privacy-policy",
      component: PrivacyPolicy,
      name: "privacy-policy",
      // meta: {
      //   middleware: "auth"
      // }
    },
    //Teacher
    {
      path: "/teacher/profile",
      component: TeachersProfile,
      name: "teacher-profile",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/teacher/reports",
      component: TeacherReports,
      name: "teacher-reports",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/teacher/gifts/:id",
      component: GiftsTeacher,
      name: "teacher-gifts",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/lifeSkills",
      component: EducationalClasses,
      name: "teacher-educational-classes",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/lifeSkillsDublicate",
      component: ParentsEducationalClasses,
      name: "parents-educational-classes",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/communicationSkills",
      component: CommunicationSkills,
      name: "communicationSkills",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/learningSkills",
      component: LearningSkills,
      name: "learningSkills",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/matched-games",
      component: GamesForAllOccasions,
      name: "matched-games",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/seven-page",
      component: SevenPage,
      name: "seven-page",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/eight-page",
      component: EightPage,
      name: "eight-page",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/nine-page",
      component: NinePage,
      name: "nine-page",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/ten-page",
      component: TenPage,
      name: "ten-page",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/eleven-page",
      component: ElevenPage,
      name: "eleven-page",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/twelve-page",
      component: TwelvePage,
      name: "twelve-page",
      meta: {
        middleware: "auth"
      },
    },
    


    {
      path: "/fromTheHassle",
      component: FromTheHassle,
      name: "fromTheHassle",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/teacher/feedback-educational",
      component: FeedbackEducational,
      name: "teacher-feedback-educational",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/teacher/feedback-games",
      component: FeedbackGames,
      name: "teacher-feedback-games",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: '/teacher/games-activity/:id',
      component: GamesActivity,
      name: 'teacher-games-activity',
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/teacher/individual-plan",
      component: IndividualPlan,
      name: "teacher-individual-plan",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/lifeSkills/gift/:id",
      component: EducationalGift,
      name: "teacher-educational-gift",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/lifeSkillsDublicate/gift/:id",
      component: ParentsEducationalGift,
      name: "parents-educational-gift",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/parents/classes/educational-communicationSkills/gift/:id",
      component: CommunicationSkillsGift,
      name: "educational-communicationSkills",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-fromTheHassleGift/gift/:id",
      component: FromTheHassleGift,
      name: "educational-fromTheHassleGift",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-gamesForAllOccasionsGift/gift/:id",
      component: GamesForAllOccasionsGift,
      name: "educational-gamesForAllOccasionsGift",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-learninSkills/gift/:id",
      component: LearningSkillsGift,
      name: "educational-learninSkills",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-sevenPage/gift/:id",
      component: SevenPageGift,
      name: "educational-sevenPage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-eightPage/gift/:id",
      component: EightPageGift,
      name: "educational-eightPage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-ninePage/gift/:id",
      component: NinePageGift,
      name: "educational-ninePage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-tenPage/gift/:id",
      component: TenPageGift,
      name: "educational-tenPage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-elevenPage/gift/:id",
      component: ElevenPageGift,
      name: "educational-elevenPage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/parents/classes/educational-twelvePage/gift/:id",
      component: TwelvePageGift,
      name: "educational-twelvePage",
      meta: {
        middleware: "auth"
      },
    },

    {
      path: "/teacher/inventing-games",
      component: InventingGames,
      name: "teacher-inventing-games",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/teacher/classes/games/gift/:id",
      component: GamesGift,
      name: "teacher-games-gift",
      meta: {
        middleware: "auth"
      },
    },
    {
      path: "/teacher/classes/games",
      component: GamesForClasses,
      name: "teacher-educational-games",
      meta: {
        middleware: "auth"
      },

    },
    {
      path: "/teacher/tutorials",
      component: TeacherTutorials,
      name: "teacher-tutorials",
      meta: {
        middleware: "auth"
      }
    },
    //Teacher

    //Director
    {
      path: "/director/profile",
      component: Director,
      name: "director-profile",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/director/goals",
      component: DirectorGoals,
      name: "director-goals",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/director/reports",
      component: DirectorReports,
      name: "director-reports",
      meta: {
        middleware: "auth"
      }
    },
    //Director
    {
      path: "/shootlist",
      component: Shootlist,
      name: "shootlist",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/shootlist-options",
      component: ShootlistOptions,
      name: "shootlist-options",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/funkit",
      component: Funkit,
      name: "funkit",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/funkit/basket",
      component: FunkitBasket,
      name: "funkit-basket",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/event/slider",
      component: EventSlider,
      name: "event-slider",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/event/create",
      component: EventCreate,
      name: "event-create",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/event/:id_event/join",
      component: EventJoin,
      name: "event-join",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/event/:id_event",
      component: Event,
      name: "event",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/events",
      component: Events,
      name: "events",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/parent",
      component: Parent,
      name: "parent",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/parent-likes",
      component: ParentLikes,
      name: "parent-likes",
      meta: {
        middleware: "parent"
      }
    },
    {
      path: "/parent-categories",
      component: ParentCategories,
      name: "parent-categories",
      meta: {
        middleware: "parent"
      }
    },
    {
      path: "/account",
      component: Account,
      name: "account",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/device-manager",
      component: DeviceManager,
      name: "device-manager",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/successfulPayment",
      component: SuccessfulPayment,
      name: "successfulPayment",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/successfulPaymentPresent",
      component: SuccessfulPaymentPresent,
      name: "successfulPaymentPresent",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/errorPayment",
      component: ErrorPayment,
      name: "errorPayment",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/liked",
      component: Liked,
      name: "liked",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/requests/playlist/add",
      component: RequestsPlaylistAdd,
      name: "requests-playlist-add",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/requests/inventors/:type",
      component: GameInventors,    //RequestsPlaylist
      name: "requests-playlist",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/requests/shootlist/add",
      component: RequestsShootlistAdd,
      name: "requests-shootlist-add",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/search",
      component: Search,
      name: "search",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/mish",
      component: Mish,
      name: "mish",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/new-pin",
      component: NewPin,
      name: "new-pin",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/about",
      component: About,
      name: "about",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/terms",
      component: Terms,
      name: "terms"
    },
    {
      path: "/restore-parent-code",
      component: RestoreParentCode,
      name: "restore-parent-code",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/notifications",
      component: Notifications,
      name: "notifications",
      meta: {
        middleware: "parent"
      }
    },
    {
      path: "/workshop",
      component: Workshop,
      name: "workshop",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/workshop/:id_workshop",
      component: WorkshopSingle,
      name: "workshop-single",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/contact",
      component: Contact,
      name: "contact",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/recover",
      component: Recover,
      name: "recover",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/recover/code",
      component: RecoverCheckCode,
      name: "recover-code",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/recover/:code/pass",
      component: RecoverNewPass,
      name: "recover-new-pass",
      meta: {
        middleware: "guest"
      }
    },
    {
      path: "/funkit/delivery/:type",
      component: FunKitDelivery,
      name: "funkit-delivery",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/subscribe",
      component: Subscribe,
      name: "subscribe",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/shootlist/video",
      component: VideoShootlist,
      name: "video-shootlist",
      meta: {
        middleware: "auth"
      }
    },
    // {
    //   path: "/playlist/gifts",
    //   component: VideoPlaylistFamily,
    //   name: "playlist-gifts",
    //   meta: {
    //     middleware: "auth"
    //   }
    // },
    {
      path: "/playlist/family/video",
      component: VideoPlaylistFamily,
      name: "video-playlist-family",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/playlist/children/video",
      component: VideoPlaylistChildren,
      name: "video-playlist-children",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/playlist/adult/video",
      component: VideoPlaylistAdult,
      name: "video-playlist-adult",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/parent/video",
      component: VideoParent,
      name: "video-parent",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/video",
      component: Video,
      name: "video",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/delivery",
      component: Delivery,
      name: "delivery",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/funzone/:id_event/conclusion",
      component: FunZoneConclusion,
      name: "funzone-conclusion",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/schools",
      component: Schools,
      name: "schools",
      meta: {
        middleware: "auth"
      }
    },
    {
      path: "/share/:type/:id",
      component: Index,
      name: "shared",
      meta: {
        middleware: "guest"
      }
    }
  ]
});
router.afterEach((to, from) => {
  localStorage.setItem('LS_ROUTE_KEY', from.name);
});
router.beforeEach(async (to, from, next) => {

  window.location.href="https://playlist-game.app";
   
});

export default router;
