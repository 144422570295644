const TeacherAPI = {

  popular() {
    return config.API.get('/popular')
  },
  matchedGames(){
    return config.API.get('/matched-games')
  },
  fromChores(){
    return config.API.get('/from-chores')
  },
  sociallSkills(){
    return config.API.get('/sociall-skills')
  },
  learningSkills(){
    return config.API.get('/learning-skills')
  },
  sevenPage(){
    return config.API.get('/seven-page')
  },
  eightPage(){
    return config.API.get('/eight-page')
  },
  ninePage(){
    return config.API.get('/nine-page')
  },
  tenPage(){
    return config.API.get('/ten-page')
  },
  elevenPage(){
    return config.API.get('/eleven-page')
  },
  twelvePage(){
    return config.API.get('/twelve-page')
  },
  popularByID(id) {
    return config.API.get('/popular/'+id)
  },
  matchedGamesByID(id) {
    return config.API.get('/matched-games/'+id)
  },
  learningSkillsByID(id) {
    return config.API.get('/learning-skills/'+id)
  },
  communicationSkillsByID(id) {
    return config.API.get('/sociall-skills/'+id)
  },
  fromTheHastleByID(id) {
    return config.API.get('/from-chores/'+id)
  },

  sevenPageByID(id) {
    return config.API.get('/seven-page/'+id)
  },
  eightPageByID(id) {
    return config.API.get('/eight-page/'+id)
  },
  ninePageByID(id) {
    return config.API.get('/nine-page/'+id)
  },
  tenPageByID(id) {
    return config.API.get('/ten-page/'+id)
  },
  elevenPageByID(id) {
    return config.API.get('/eleven-page/'+id)
  },
  twelveByID(id) {
    return config.API.get('/twelve-page/'+id)
  },


  games() {
    return config.API.get('/type')
  },

  gamesByID(id) {
    return config.API.get('/type/'+id)
  },

  sections() {
    return config.API.get('/types')
  },

  saveCategories(data){
    return config.API.post('/playlist/teacher/categories', data)
  },

  comment(data){
    return config.API.post('/user/createComment', data)
  },
  addReview(data){
    return config.API.post('/gifts/createReview', data);
  },

  reportPlaylist(){
    return config.API.get('/playlist/teacher/requestGames')
  },

  reportShootlist(){
    return config.API.get('/shootlist/teacher/requestGames')
  },

  gameQuestions(data){
    return config.API.put('/popular/'+data.id+'/question',{select : data.select} )
  },

  review(id){
    return config.API.get('/gifts/getReview/'+id )
  },

  getGiftsStudentId(id){
    return config.API.get('user/'+id+'/playlists')

  },

  getPLaylistData(index){
    return config.API.post('/teacher/report/full', {filter: index});
  },


  getPLaylistDataT(index){
    return config.API.post('/teacher/report/fullreport', {filter: index});
  },



  getReportData(){
    return config.API.get('/teacher/report/total');
  },

  gamesDetails(data){
    return config.API.post('user/'+data.id+'/gamesDetails', {filter: data.filter})

  },
  getAllPlayedGameDetails(data){
    return config.API.post('user/'+data.id+'/getAllPlayedGameDetails')
  },

  activities() {
    return config.API.get('/user/playlists')
  },

  getTargets() {
    return config.API.get('/teacher/targets')
  },
  getDetails() {
    return config.API.get('/teacher/details')
  },
  setInvention(data){
    return config.API.post('/teacher/details/invention-course', {inventionCourse : data} )
  },
  setGamesShifts(data){
    return config.API.post('/teacher/details/games-shifts', {gamesShifts : data} )
  },
  getFavoritePlaylist(){
    return config.API.get('/teacher/favorite/playlist' )
  },
  getFavoriteShootlist(){
    return config.API.get('/teacher/favorite/shootlist' )
  },
  setPlaylistOrder(data){
    return config.API.post('/teacher/favorite/playlist/setOrder', data)
  },
  setShootlistOrder(data){
    return config.API.post('/teacher/favorite/shootlist/setOrder', data)
  },
  deletePlaylist(data){
    return config.API.delete('/teacher/favorite/playlist/'+data)
  },
  deleteShootlist(data){
    return config.API.delete('/teacher/favorite/shootlist/'+data)
  },
  block(data){
    return config.API.post('/user/block',data);
  },
  changePass(data){
    return config.API.post('/user/changePass',data);
  },
  unblock(data){
    return config.API.post('/user/unblock',data);
  },

};

export { TeacherAPI }