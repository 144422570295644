<template lang="html">
  <div class="app-modal">
    <div class="wrapper">
      <div class="bg">
        <div class="container" v-click-outside="close">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transition: {}
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  .wrapper {
    overflow-y: auto;
    width: 100%;
    height: 100%;

    .bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      overflow: auto;
      width: 100%;
      min-height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
    }

    .container {
      min-width: 300px;
    }
  }

  // &.fade-enter {
  //   opacity: 0;
  // }
  // &.fade-enter-to {
  //   opacity: 1;
  // }

  // &.fade-leave {
  //   opacity: 1;
  // }
  // &.fade-leave-to {
  //   opacity: 0;
  // }

  // &.fade-leave-active,
  // &.fade-enter-active {
  //   transition: opacity 0.3s ease;
  // }

  @media (max-width: 768px) {
    .wrapper {
      .bg {
        .container {
          width: 100%;
          .app-modal-default {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
