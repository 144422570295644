<template>
  <keep-alive>
    <div class="main">
      <div class="main-wrapper sm-cont">
        <simple-header route="/main">
          <template v-slot:title>
            {{$ml.get("התאמת משחקים")}}
          </template>
          <span slot="additional">
            <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
          </span>
        </simple-header>

        <app-loading :loading="loadingTitle" class="sm-cont" style="background: #fff;">
          <template>
            <div
              :style="{direction:direction}"
              class="small"
              dir="rtl"
              >
              <div
                class="section-wrapper"
                v-if="
                  sectionsPromo != null &&
                  sectionsPromo.sections != null &&
                  sectionsPromo.sections.length > 0
                "
              >
                <RadioWrapper
                  v-if="sectionsPromo.sections.includes('child')"
                  class="yell"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[2])
                      ? 'background: #FAFF00;border: 1px solid #FAFF00'
                      : 'border: 1px solid #FAFF00'
                  "
                  :class="[
                    selected.section.includes(section.arr[2])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                      
                        setValue(section.arr[2]);
                        checkIt();
                        setEvent(section.arr[2]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/childrens.svg" alt="" />
                      <div class="title">{{ $ml.get("ילדים") }}</div>
                    </div>
                  </template>
                </RadioWrapper>

                <RadioWrapper
                  v-if="sectionsPromo.sections.includes('adult')"
                  class="red"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[1])
                      ? 'background: #FF7384;border: 1px solid #FF7384'
                      : 'border: 1px solid #FF7384'
                  "
                  :class="[
                    selected.section.includes(section.arr[1])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                        setValue(section.arr[1]);
                        checkIt();
                        setEvent(section.arr[1]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/friends.svg" alt="" />
                      <div class="title">{{ $ml.get("נוער ובוגרים") }}</div>
                    </div>
                  </template>
                </RadioWrapper>

                <RadioWrapper
                  v-if="sectionsPromo.sections.includes('family')"
                  class="oran"
                  @click="checkIt"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[0])
                      ? 'background: #FFC700;border: 1px solid #FFC700'
                      : 'border: 1px solid #FFC700'
                  "
                  :class="[
                    selected.section.includes(section.arr[0])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                        setValue(section.arr[0]);
                        checkIt();
                        setEvent(section.arr[0]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/family.svg" alt="" />
                      <div class="title">{{ $ml.get("משפחה") }}</div>
                    </div>
                  </template>
                </RadioWrapper>
              </div>
              <div
                class="section-wrapper"
                v-else
              >
                <RadioWrapper
                  class="yell"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[2])
                      ? 'background: #FAFF00;border: 1px solid #FAFF00'
                      : 'border: 1px solid #FAFF00'
                  "
                  :class="[
                    selected.section.includes(section.arr[2])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                        setValue(section.arr[2]);
                        checkIt();
                        setEvent(section.arr[2]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/childrens.svg" alt="" />
                      <div class="title">{{ $ml.get("ילדים") }}</div>
                    </div>
                  </template>
                </RadioWrapper>

                <RadioWrapper
                  class="red"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[1])
                      ? 'background: #FF7384;border: 1px solid #FF7384'
                      : 'border: 1px solid #FF7384'
                  "
                  :class="[
                    selected.section.includes(section.arr[1])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                        setValue(section.arr[1]);
                        checkIt();
                        setEvent(section.arr[1]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/friends.svg" alt="" />
                      <div class="title">{{ $ml.get("נוער ובוגרים") }}</div>
                    </div>
                  </template>
                </RadioWrapper>

                <RadioWrapper
                  class="oran"
                  @click="checkIt"
                  v-model="selected.section"
                  :style="
                    selected.section.includes(section.arr[0])
                      ? 'background: #FFC700;border: 1px solid #FFC700'
                      : 'border: 1px solid #FFC700'
                  "
                  :class="[
                    selected.section.includes(section.arr[0])
                      ? 'active'
                      : 'unactive',
                    direction === 'rtl' ? '' : 'ltrHeight',
                  ]"
                >
                  <template v-slot="{ setValue }">
                    <div
                      @click="
                        setValue(section.arr[0]);
                        checkIt();
                        setEvent(section.arr[0]);
                      "
                      class="slot-radio"
                    >
                      <img src="@/assets/img/icons/family.svg" alt="" />
                      <div class="title">{{ $ml.get("משפחה") }}</div>
                    </div>
                  </template>
                </RadioWrapper>
              </div>
            </div>
            <div
              :style="{ direction: direction }"
              style="position: relative"
              class="small"
              dir="rtl"
            >
              <div
                :style="{ direction: direction }"
                v-if="
                  selected.section === 'child' &&
                  user.position !== null &&
                  user.position !== 'director'
                "
                class="ability-wrapper"
              >
              </div>

              <div :style="{ direction: direction }" class="locations-wrapper">
                <div
                  :style="direction === 'ltr' ? 'padding-left: 10px;' : ''"
                  class="block-title"
                >
                  {{ $ml.get("מיקום וסיטואציה") }}:
                </div>
                <div :key="selected.section" class="locations">
                  <CheckboxWrapper
                    :key="index * 33"
                    v-for="(item, index) in locations.arr"
                    v-model="selected.locations"
                    :class="[
                      selected.locations.find((el) => el == item.id_setting)
                        ? 'active'
                        : 'unactive',
                    ]"
                  >
                    <template v-slot="{ setValue }">
                      <div
                        @click="setValue(item.id_setting)"
                        class="checkbox-slot"
                      >
                        <img
                          style="fill: #06364b !important"
                          :src="item.img"
                          alt=""
                        />
                        <NameTranslated class="title" :translate="item.translation"/>
                      </div>
                    </template>
                  </CheckboxWrapper>
                </div>
              </div>
              <div class="gifts-wrapper">
                <div class="gifts-title">{{ $ml.get("בחירת כישורי חיים לפיתוח") }}:</div>
                <div class="gifts-buttons" style="position: relative; text-align: center;">
                  
                  <app-btn
                    :style="direction === 'ltr' ? 'padding-left: 0' : ''"
                    :class="isDefaultUser?'disabled':'active'"
                    @click="gifts"
                  >
                  <!-- <svg style="position: absolute; left: 5px;" height="15" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="352,115.4 331.3,96 160,256 331.3,416 352,396.7 201.5,256 "/></svg> -->
                  <svg
                  style="position: absolute; right: 16px;bottom: 9px;"
                  :style="
                        direction === 'ltr'
                          ? 'margin-right:10px;'
                          : 'margin-left:10px;'
                      " width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5.14258C4.02891 5.14258 -8.80545e-08 9.17148 -1.96701e-07 14.1426C-3.05348e-07 19.1137 4.02891 23.1426 9 23.1426C13.9711 23.1426 18 19.1137 18 14.1426C18 12.4234 17.5148 10.8133 16.6781 9.4457L15.3562 10.7957C15.8906 11.8012 16.2 12.9262 16.2 14.1426C16.2 18.1187 12.9762 21.3426 9 21.3426C5.02383 21.3426 1.8 18.1187 1.8 14.1426C1.8 10.1664 5.02383 6.94258 9 6.94258C10.227 6.94258 11.366 7.27305 12.375 7.81445L13.6969 6.49258C12.3258 5.64883 10.7297 5.14258 9 5.14258ZM16.65 5.22695L9.45 12.3988C9.30234 12.3602 9.16172 12.3426 9 12.3426C8.00508 12.3426 7.2 13.1477 7.2 14.1426C7.2 15.1375 8.00508 15.9426 9 15.9426C9.99492 15.9426 10.8 15.1375 10.8 14.1426C10.8 13.9809 10.7824 13.8402 10.7437 13.6926L17.9156 6.49258L16.65 5.22695ZM9 8.74258C6.01875 8.74258 3.6 11.1613 3.6 14.1426C3.6 17.1238 6.01875 19.5426 9 19.5426C11.9812 19.5426 14.4 17.1238 14.4 14.1426C14.4 13.4289 14.2594 12.768 14.0062 12.1457L12.5437 13.5801C12.5754 13.7594 12.6 13.9527 12.6 14.1426C12.6 16.1324 10.9898 17.7426 9 17.7426C7.01016 17.7426 5.4 16.1324 5.4 14.1426C5.4 12.1527 7.01016 10.5426 9 10.5426C9.20039 10.5426 9.40078 10.5637 9.59062 10.5988L11.025 9.16445C10.3957 8.9043 9.72422 8.74258 9 8.74258Z" fill="white"/>
                  <path d="M22.1428 5.24241H17.9002L15.7788 7.36373H20.0215L22.1428 5.24241Z" fill="white" stroke="white" stroke-linejoin="round"/>
                  <path d="M17.9002 0.999773V5.24241L15.7788 7.36373V3.12109L17.9002 0.999773Z" fill="white" stroke="white" stroke-linejoin="round"/>
                  </svg>

                    <span style="margin-right: 24px; padding: 0px 0px 3px 0px;;">{{ $ml.get("מסלול משחקים אישי") }}</span>
                  </app-btn>
                  <label class="switch">
                    <input :disabled="isDefaultUser?true:false" type="checkbox" @click="changeLifeSkills" :checked="selected.life_skills==true?true:false">
                    <span class="slider round"></span>
                  </label>
                  <span style="padding: 9px 5px; font-family: 'App';font-style: normal;font-weight: 400;font-size: 16px;line-height: 19px;text-align: right;color: #4D4D4D;padding-bottom: 5px;">{{ selected.life_skills?$ml.get("מופעל"):$ml.get("כבוי")}}</span>
                </div>
              </div>
              <div class="level-wrapper">
                <div class="level-title">{{ $ml.get("רמת המשחק") }}:</div>
                <div class="levels-wrapper">
                  <CheckboxWrapper
                    :key="index * 4"
                    v-for="(item, index) in levels.numbers"
                    v-model="selected.levels"
                    :class="[
                      selected.levels.includes(item) ? 'active' : 'unactive',
                    ]"
                    :style="
                      direction === 'ltr' && item === 1 ? 'margin-left: 0' : ''
                    "
                  >
                    <template v-slot="{ setValue }">
                      <div @click="setValue(item)" class="checkbox-slot">
                        <div v-if="item === 1" class="title">
                          {{ $ml.get("קל") }}
                        </div>
                        <div v-else-if="item === 2" class="title">
                          {{ $ml.get("מאתגר") }}
                        </div>
                        <div v-else-if="item === 3" class="title">
                          {{ $ml.get("מאתגר") }}+
                        </div>
                      </div>
                    </template>
                  </CheckboxWrapper>
                </div>
              </div>
              <div class="level-wrapper">
                <div class="level-title">{{ $ml.get("משחקים בהם:") }}</div>
                <div class="levels-wrapper">
                  <CheckboxWrapper
                    :key="index * 4"
                    v-for="(item, index) in seen.numbers"
                    v-model="selected.seen"
                    :class="[
                    isDefaultUser==true?'disabled': selected.seen.includes(item) ? 'active' : 'unactive',
                    ]"
                    :style="
                      direction === 'ltr' && item === 1 ? 'margin-left: 0' : ''
                    "
                  >
                    <template v-slot="{ setValue }">
                      <div @click="setValue(item)" class="checkbox-slot">
                        <div v-if="item === 1" class="title">
                          {{ $ml.get("צפיתי") }}
                        </div>
                        <div v-else-if="item === 2" class="title">
                          {{ $ml.get("לא צפיתי") }}
                        </div>
                      </div>
                    </template>
                  </CheckboxWrapper>
                </div>
              </div>
              <div
                style=" text-align: center;
                  position: relative;
                  z-index: 111111;
                  width: 100%;
                  right: 0;
                  padding: 32px 0px 20px 0px;
                "
                class="footer"
              >
              <span style="padding-bottom:15px;font-family: 'App';font-style: normal;font-weight: 400;font-size: 14px;line-height: 19px;text-align: center;color: #00465B;">{{$ml.get('משחקים מותאמים לבחירות שלך:')+' '}}<span id="count">{{ counterNum }}</span></span>
                <app-btn
                  style="margin: 0 auto; margin-top: 10px;"
                  :loading="loading"
                  @click="update"
                  class="save-b"
                  >{{ $ml.get("הצג את המשחקים") }}</app-btn
                >
              </div>
            </div>
          </template>
        </app-loading>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader";
import SidebarToggler from "@/components/SidebarToggler";
import CheckboxWrapper from "@/components/CheckboxWrapper";
import RadioWrapper from "@/components/playlist/playlist-options/RadioWrapper";
import { PlaylistAPI } from "@/api";

import { mapGetters } from "vuex";
import store from "../../store";
import NameTranslated from "../../components/director/director-reports/NameTranslated";
export default {
  data() {
    return {
      loadingTitle:false,
      index: 1,
      section: {
        arr: ["family", "adult", "child"],
      },
      selected: {
        section: "child",
        show_director_favorite: true,
        show_teacher_favourite: true,
        show_school_inventors: true,
        life_skills:false,
        activities: [],
        locations: [],
        levels: [],
        seen: []
      },
      activities: {
        arr: ["1", "2", "3"],
      },
      locations: {
        arr: [],
      },
      levels: {
        arr: ["קל", "מאתגר ", "מאתגר+"],
        numbers: [1, 2, 3],
      },
      seen: {
        arr: ["צפיתי","לא צפיתי"],
        numbers: [1, 2],
      },
      items: [],
      c: 0,
      start: "",
      loading: false,
      counterNum:0,
      isRunning:false,
      intervalId: null,
    };
  },
  components: {
    NameTranslated,
    SimpleHeader,
    SidebarToggler,
    CheckboxWrapper,
    RadioWrapper,
  },
  computed: {
    ...mapGetters({
      sectionsPromo: "playlist-locations/dataPromo",
      settings: "playlist/settings",
      school: "user/school",
      user: "user/data",
      counters: "counter-game/dataGames",
      isDefaultUser: "user/getDefaultUser"
    }),
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
  },
  watch: {
    selected:{
      handler(val, oldVal) {
          this.updateCount();
      },
    deep: true
    }
  },
  methods: {
    changeLifeSkills()
    {
      this.selected.life_skills = !this.selected.life_skills;
      this.$store.commit('playlist/setLifeSettings',this.selected.life_skills);
    },
    counterRun()
    {
      let count = this.counters;
      let timeout = 50;

      if(count>50 && count<=100)
      {
        timeout = 30;
      }
      else if(count>100 && count<=500)
      {
        timeout = 10;
      }
      else if(count>500)
      {
        timeout = 5;
      }

      
      if (this.isRunning) {
        clearInterval(this.intervalId);
        this.counterNum=0;
        this.intervalId = setInterval(() => {
          if(count>0)
          {
          this.counterNum++;
          }
          if(this.counterNum==count)
          {
            clearInterval(this.intervalId);
          }
        }, timeout);
      } else {
        clearInterval(this.intervalId);
        this.counterNum=0;
        this.intervalId = setInterval(() => {
          if(count>0)
          {
          this.counterNum++;
          }
          if(this.counterNum==count)
          {
            clearInterval(this.intervalId);
          }
        }, timeout);
      }
      this.isRunning = !this.isRunning;
    },
    updateCount()
    {
      PlaylistAPI.countByParam(this.selected).then((res)=>{
        this.$store.commit('counter-game/saveDataGames',res.data.data);
        setTimeout(()=>{
          this.counterRun();
        },500)
      })
    },
    setEvent(event)
    {
      window.fbq("track", "הגדרות משחק"+" - "+event);
    },
    checkIt() {
      this.locations.arr = this.$store.getters["playlist-locations/getByType"](
        this.selected.section
      );
      this.selected.locations = [];
      this.selected.locations = this.$store.getters[
        "playlist-locations/getByType"
      ](this.selected.section)
        .filter((item) => item.is_default)
        .map((item) => {
          return item.id_setting;
        });
    },
    update() {
      this.loading = true;
      let filters = this.selected;
      // if(filters.seen.includes(1)&&filters.seen.length==1)
      // {
        filters.viewed_playlist = [];
      // }
      this.$store.commit("playlist/setSettings", filters);
      if(this.isDefaultUser!=true)
      {
        PlaylistAPI.saveSettings(filters).then(() => {
          this.loading = false;
          this.$store.commit("playlist/resetGames");
          this.$router.push("/search");
        });
      }
      else
      {
        this.loading = false;
          this.$store.commit("playlist/resetGames");
          this.$router.push("/search");
      }
    },
    gifts() {
        this.$store.commit("playlist/setSettings", this.selected);
        this.$router.push({ name: "playlist-gifts" });
    },
  },
  mounted() {
    this.updateCount();
  },
  async created() {
    if(!this.$store.getters['playlist/isSettings']){
      this.loadingTitle = true;
      await this.$store.dispatch('playlist/loadState');
    }
    this.loadingTitle = false;
    window.fbq("track", "הגדרות משחק");
    
    if (!Object.keys(this.settings).length) {
      
      let sett = {
        gender: "child",
        activities: ["1", "2", "3"],
        locations: this.$store.getters["playlist-locations/getByType"]("child")
          .filter((item) => item.is_default)
          .map((item) => {
            return item.id_setting;
          }),
        show_teacher_favourite: false,
        show_school_inventors: false,
        show_director_favorite: false,
        levels: [3, 2, 1],
        seen:[1,2]
      };
      if (
        store.getters["user/data"].position !== null &&
        store.getters["user/data"].position !== "director"
      ) {
        sett.show_teacher_favourite = true;
        sett.show_school_inventors = true;
        sett.show_director_favorite = true;
      } else {
        sett.show_teacher_favourite = false;
        sett.show_school_inventors = false;
        sett.show_director_favorite = false;
      }
      this.$store.commit("playlist/setSettings", sett);
    }
    if (this.settings.locations==null) {
      let sett = this.settings;
      sett.locations = this.$store.getters["playlist-locations/getByType"]("child")
          .filter((item) => item.is_default)
          .map((item) => {
            return item.id_setting;
          });
          this.$store.commit("playlist/setSettings", sett);
    }
    if (!this.$store.getters["tutorials/isUsed"]("playlistDidntSeeSettings")) {
      this.$store.commit("tutorials/setTutorial", "playlistDidntSeeSettings");
    }
    this.selected.life_skills = _.cloneDeep(this.settings.life_skills) || false;
    this.selected.section = _.cloneDeep(this.settings.section) || [];
    this.selected.activities = _.cloneDeep(this.settings.activities) || [];
    this.selected.locations = _.cloneDeep(this.settings.locations) || [];
    this.selected.levels = _.cloneDeep(this.settings.levels) || [];
    this.selected.seen = _.cloneDeep(this.settings.seen) || [];
    this.selected.viewed_playlist = this.settings.viewed_playlist || [];
    this.selected.show_teacher_favourite =
      _.cloneDeep(this.settings.show_teacher_favourite) || false;
    this.selected.show_school_inventors =
      _.cloneDeep(this.settings.show_school_inventors) || false;
    this.selected.show_director_favorite =
      _.cloneDeep(this.settings.show_director_favorite) || false;

    //}
    if (this.selected.section.length === 0) {
      if(this.sectionsPromo!=null)
      {
        if(this.sectionsPromo.sections!=null && this.sectionsPromo.sections.length>0)
        {
          this.selected.section = this.sectionsPromo.sections[0];
        }
        else
        {
        this.selected.section = "child";
        }
      }
      else
      {
        this.selected.section = "child";
      }
    }
    this.locations.arr = this.$store.getters["playlist-locations/getByType"](
      this.selected.section
    );
  },
};
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 38px;
  margin: 0px 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #005d7a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #005d7a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
@media screen and (min-height:740px)
{
  .footer{
  position: fixed !important;
  bottom: 0 !important;
  padding: 20px 0 !important;
  }
}
.main {
  display: flex;
  position: relative;
  flex-grow: 1;
  background: #005D79;
  color: #4d4d4d;
  overflow: hidden;
  min-height: 665px;
  .main-wrapper {
    min-height: 700px;
    z-index: 2;
    position: relative;
    flex-grow: 1;

    .block-title {
      padding-right: 10px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: right;

      color: #4D4D4D;
    }
    .save-b {
      margin: 20px auto;
      width: 304px;
      height: 48px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;

      color: #FFFFFF;
    }

    .section-wrapper,
    .abilities {
      padding: 17px 5px 10px 5px;
      padding-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 4;
      .radio-wrapper {
        margin: 3px;
        z-index: 4;
        width: 114px !important;
        min-width: 114px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        position: relative;
        .slot-radio {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .title {
            text-align: center;
            color: #06364b;
            font-size: 14px;
          }
          img {
            height: 48px;
            margin-top: 16px;
          }
        }
        &.yell {
          border: 1px solid #FAFF00 !important;
          &.active {
            &:after {
              border: 1px solid #FAFF00;
            }
          }
        }
        &.oran {
          border: 1px solid #FFC700 !important;
          &.active {
            &:after {
              border: 1px solid #FFC700;
            }
          }
        }
        &.red {
          border: 1px solid #ff7384 !important;
          &.active {
            &:after {
              border: 1px solid #ff7384;
            }
          }
        }
        &.active {
          &:after {
            position: absolute;
            content: "";

            border: 1px solid #00465b;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            top: 5px;
            right: 5px;
            background: white;
            z-index: -2;
          }
          &:before {
            position: absolute;
            content: "";
            background: #00465b;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            top: 8px;
            right: 8px;
            z-index: -1;
          }
        }
        &.unactive {
          &:after {
            top: 5px;
            right: 5px;
            position: absolute;
            content: "";
            background: #ffffff;
            border: 1px solid #C4C4C4;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            z-index: -2;
          }
        }
      }
    }
    .ability-wrapper {
      img {
        @media screen and (min-width: 375px) {
          height: 61px;
          width: 65%;
        }
      }
      .abilities {
        padding-top: 4px;
        padding-bottom: 15px;
        .checkbox-wrapper {
          position: relative;
          width: 32%;
          height: 76px;
          font-style: normal;
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: center;

          text-align: center;
          color: #06364b;
          border-radius: 4px;
          .checkbox-slot {
            .class-round {
              width: 40px;
              height: 40px;
              margin: 0 auto;
              margin-top: 6px;
              font-style: normal;
              font-weight: bold;
              color: #fbb03b;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #ffffff;
              border-radius: 50%;
              .class {
                font-size: 22px;
                line-height: 27px;
                margin-top: -5px;
              }
              .num {
                margin-right: 3px;
                font-size: 16px;
                line-height: 22px;
              }
            }
            img {
              min-height: 35px;
              max-height: 42px;
              margin-top: 6px;
            }
            .title {
              color: #06364b;
              font-size: 14px;
            }
          }
          &.active {
            &:after {
              position: absolute;
              content: "";

              background: white;
              border: 1px solid white;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              top: 5px;
              right: 5px;
              z-index: 1;
            }
            &:before {
              position: absolute;
              content: "";
              height: 3px;
              z-index: 2;
              width: 6px;
              border-left: 1px solid #00465b;
              border-bottom: 1px solid #00465b;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 9px;
              right: 8px;
            }
          }
          &.unactive {
            &:after {
              top: 5px;
              right: 5px;
              position: absolute;
              content: "";
              background: #ffffff;
              border: 1px solid #a9ddf3;
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
          }

          &.disabled {
        pointer-events: none;
        border: 1px solid gray;
        background: white;
        &:after {
          top: 5px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
          }
        }
      }
      .title {
        margin-top: unset !important;
      }
    }
  }
}

.locations-wrapper {
  .locations {
    padding: 0 10px 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .checkbox-wrapper {
      display: inline-block;
      width: 67px;
      height: 67px;
      position: relative;
      margin: 2px;
      background: #3FBCD4;
      border-radius: 4px;
      .checkbox-slot {
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          @media screen and (min-width: 375px) {
            font-size: 13px;
          }
          word-wrap: break-word;
          // margin: 5px 0;
          padding: 0 5px;
          font-size: 12px;
          line-height: 1;
          min-height: 20px;
          text-align: center;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          color: #06364b;
        }
      }
      &.active {
        border: 1px solid white;
        background: #3FBCD4;
        &:after {
          position: absolute;
          content: "";
          z-index: 0;
          border: 1px solid white;
          background: #ffffff;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 5px;
          right: 5px;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #00465b;
          border-bottom: 3px solid #00465b;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          scale: -0.9;
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
      }
      &.unactive {
        border: 1px solid #3FBCD4;
        background: white;
        &:after {
          top: 5px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
      }

      &.disabled {
        pointer-events: none;
        border: 1px solid gray;
        background: white;
        &:after {
          top: 5px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 8px;
          right: 7px;
        }
      }
    }
    img {
      // margin-top: 15px;
      max-width: 30px;
      height: 30px;
    }
  }
}

.gifts-wrapper {
  padding: 5px 10px 16px 10px;

  .gifts-title {
    
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;

    color: #4D4D4D;
    padding-bottom: 5px;
  }
  .gifts-buttons {
    display: flex;
    flex-direction: row;
    button {
      width: 180px;
      height: 40px;
      padding: 10px 16px;
      margin-left: 5px;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: right;

      color: #FFFFFF;

      background: rgb(0, 93, 122);
      border-radius: 20px;

      height: 38px;
      svg {
        margin-left: 10px;
      }
    }
  }
}

.level-wrapper {
  padding: 10px;

  .level-title {
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: right;

    color: #4D4D4D;
  }

  .levels-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 5px;

    .checkbox-wrapper {
      min-width: 90px;
      height: 32px;
      background: #a9ddf3;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      margin-left: 6px;

      font-size: 12px;
      text-align: center;
      color: #06364b;
      position: relative;
      .checkbox-slot {
        z-index: 4;
        position: relative;
        width: 100%;
        height: 100%;
        /* top: -3px; */
        /* right: 6px; */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .title {
          margin-right: 24px;
          color: #06364b;
        }
      }
      &.active {
        border: 1px solid white;
        background: #3FBCD4;
        &:after {
          position: absolute;
          content: "";
          z-index: 0;
          border: 1px solid white;
          background: #ffffff;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 9px;
          right: 5px;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #00465b;
          border-bottom: 3px solid #00465b;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          scale: -0.9;
          transform: rotate(135deg);
          top: 12px;
          right: 7px;
        }
      }
      &.unactive {
        border: 1px solid #3FBCD4;
        background: white;
        &:after {
          top: 9px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 12px;
          right: 7px;
        }
      }

      &.disabled {
        pointer-events: none;
        border: 1px solid lightgray;
        background: lightgray;
        &:after {
          top: 9px;
          right: 5px;
          position: absolute;
          content: "";
          background: #ffffff;
          border: 1px solid #c4c4c4;
          width: 12px;
          height: 12px;
          z-index: 0;
          border-radius: 50%;
        }
        &:before {
          position: absolute;
          content: "";
          z-index: 1;
          height: 5px;
          width: 8px;
          background: #ffffff;
          border-left: 3px solid #c2c2c2;
          border-bottom: 3px solid #c2c2c2;
          opacity: 0.7;
          border-bottom-left-radius: 2px;
          transform: scale(-1.2);
          scale: -0.9;
          transform: revert;
          border-top-left-radius: 1px;
          border-bottom-right-radius: 1px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(135deg);
          top: 12px;
          right: 7px;
        }
      }
    }
  }
}

.ltrHeight {
  height: 112px !important;
}
.small {
  max-width: 380px;
}

.app-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
