import {UserAPI, SubscribeAPI} from "@/api";
import store from "@/store";

export default {
  namespaced: true,
  state: {
   disabled:false,
  },
  mutations: {
    setDisabled(state,data)
    {
        state.disabled = data;
    }
  },
  actions: {
   
  },
  getters: {
    getDisabled(state)
    {
        return state.disabled;
    },
   
  }
};
