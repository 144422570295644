import VCheck from "./VCheck";
import AppTextInput from "./AppTextInput";
import AppLoading from "./AppLoading";
import InfoTip from "./InfoTip";

import AppBtn from "./app-btn/AppBtn";

import AppModal from "./AppModal";
import AppModalDefault from "./AppModalDefault";

import AppTextarea from "./AppTextarea";
import AppNumberInput from "./AppNumberInput";

import InputCustom from "@/components/ui/InputCustom";
import InputRegister from "@/components/ui/InputRegister";
import InputRegisterCode from "@/components/ui/InputRegisterCode";
import SelectRegister from "@/components/ui/SelectRegister";
export default {
  install(Vue, opts) {
    Vue.component("app-text-input", AppTextInput);
    Vue.component("app-textarea", AppTextarea);
    Vue.component("app-number-input", AppNumberInput);
    Vue.component("app-btn", AppBtn);
    Vue.component("app-checkbox", VCheck);
    Vue.component("info-tip", InfoTip);
    Vue.component("app-modal", AppModal);
    Vue.component("app-modal-default", AppModalDefault);
    Vue.component("app-loading", AppLoading);
    Vue.component("InputRegister", InputRegister );
    Vue.component("InputCustom",InputCustom);
    Vue.component("InputRegisterCode", InputRegisterCode );
    Vue.component("SelectRegister", SelectRegister )
  }
};
