import { PlaylistAPI } from '@/api';
import store from "@/store/index";

const PlaylistGiftsStore = {
  namespaced: true,
  state: {
    data: [],
    loaded: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },

    load(state) {
      state.loaded = true;
    },
  },
  actions: {

    async updateData({ commit }) {
      const sections = await PlaylistAPI.selectGifts()
        .then(res => res.data);

      commit('setData', sections);
      commit('load')
    }
  },
  getters: {

    data(state) {
      return state.data;
    },

    isLoaded(state) {
      return state.loaded;
    },
    getById: state => gift_id => {
      return state.data.find(n => n.id_category === gift_id);
    },
    getTranslatedById: state => gift_id => {
      const gift = state.data.find(n => n.id_category == gift_id);
      let name = gift.translation.find(item=>{
        if(store.getters['lang-store/data']=='eng')
        {
          return item.langKey === "en"
        }
        else
        {
        return item.langKey === store.getters['lang-store/data']
        }
      }).data.name;
      return name!=="" ? name : gift.translation[0].data.name;
    },
    getBySectionId: state => gift_id => {
      return state.data.filter(n => n.id_section=== gift_id).length;
    },
  }
};

export { PlaylistGiftsStore };
