<template :key="$ml.current">
  <div
    class="main-section"
    :class="{ 'show-shadow': show_shadow }"
  >
    <div class="scroller" ref="scroll" @scroll="onScroll">
      <div  :style="{direction: direction}"  class="questions">
        <div class="item first">
          <div class="num">
            1
          </div>
          <div class="text">
            {{ translated.data.question_1 !=='' ? translated.data.question_1 : data.translation[0].data.question_1 }}
          </div>
        </div>
        <div class="item second">
          <div class="num">
            2
          </div>
          <div class="text">
            {{ translated.data.question_2 !=='' ? translated.data.question_2 : data.translation[0].data.question_2 }}
          </div>
        </div>
        <div class="item third">
          <div class="num">
            3
          </div>
          <div class="text">
            {{ translated.data.question_3 !=='' ? translated.data.question_3 : data.translation[0].data.question_3 }}
          </div>
        </div>
      </div>

      <div class="video">
        <div class="main-wrapper">
          <div class="wrapper">
            <svg class="svg" height="682pt" viewBox="-21 -117 682.66672 682" width="682pt" xmlns="http://www.w3.org/2000/svg">
              <path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0"/>
            </svg>
            <div 
              class="container" 
              v-if="data._video && data._video.length" 
            >
              <video v-if="$ml.current=='heb'" @play="onPlay" controls :poster="data.preview" :src="data._video"></video>
              <video v-else-if="$ml.current=='ar'" @play="onPlay" controls :poster="data.previewAr!=null?data.previewAr:data.preview" :src="data._videoAr!=null?data._videoAr:data._video"></video>
              <video v-else-if="$ml.current=='eng'" @play="onPlay" controls :poster="data.previewEng!=null?data.previewEng:data.preview" :src="data._videoEng!=null?data._videoEng:data._video"></video>
              <video v-else-if="$ml.current=='ru'" @play="onPlay" controls :poster="data.previewRu!=null?data.previewRu:data.preview" :src="data._videoRu!=null?data._videoRu:data._video"></video>
            </div>
            <div 
              class="container" 
              v-else-if="data.video && data.video.length"
              v-html="data.video"
            ></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { PlaylistAPI } from '@/api';
export default {
  props: {
    data: {}
  },
  watch:{

  },
  components:{
  },
  data() {
    return {
      show_shadow: false,
      video_played: false,
      current: '',
    }
  },
  computed:{
    translated(){
       if (this.$store.getters['lang-store/data']){
         return this.data.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
         })
       }
    },
    direction(){
      return this.$store.getters['lang-store/direction'];
    }
  },
  methods: {
    onPlay(e) {
      if(!this.video_played) {
        PlaylistAPI.addActivity(this.data.id_playlist, 'vid');
        this.video_played = true;
      }
    },

    onScroll(e) {
      this.show_shadow = !!e.target.scrollTop;

    }
  },
  created() {
    this.current = this.data.translation.find(item=>{
      return item.langKey === this.$ml.current
    })
    // setTimeout(()=>{
    //
    //   if ((this.data.video && this.data.video.length) || (this.data._video && this.data._video.length)){
    //     this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
    //   }
    // },400);
  }
}
</script>

<style lang="scss" scoped>
//
.video::v-deep iframe {
  width: 100% !important;
  height: 100% !important;
  vertical-align: middle;
}

.main-section {

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  flex-grow: 1;

  .footer{
    background: white;
    border-radius: 2px;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 30px;
    min-height: 30px;
    position: absolute;
  }

  &.show-shadow {
    &::after {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 0 15px 15px #fff;
      position: absolute;
    }
  }

  .scroller {
    flex-grow: 1;
    /*overflow-y: auto;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

  }
  
  .questions {
    flex-grow: 1;
    padding: 0 11px;
    overflow: auto;
    max-height: 400px;
    min-height: 150px;
    .item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      padding: 5px 0;

      .num {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 18px;
        height: 18px;
        border-radius: 100%;
        margin-left: 10px;
        margin-top: 6px;
        background: #A0CCDA;
        color: #fff;
        font-size: 12px;
        min-width: 18px;
        font-style: italic;
        // SORRY
        letter-spacing: 1.8px;
      }
      .text {
        
        font-family: 'App';
        font-style: normal;
        text-size-adjust: none;
        flex-grow: 1;
        font-weight: normal;
        font-size: 18px;
        padding-left: 20px;
        color: #1A2D4A;
      }
    }
  }

  .video {
    padding: 10px 10px 0 10px;
    // margin-bottom: 10px;
    .main-wrapper {
      padding-bottom: 50%;
      width: 100%;
      position: relative;

      .wrapper {
        width: 100%;
        height: 100%;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        
        .svg {
          position: absolute;
          width: 73px;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          fill: #ccc;
        }
      }
      .container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        video {
          height: 100%;
          width: 100%;
          background: #000;
        }
      }
    }
  }
}

</style>