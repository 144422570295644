<template>
  <div class="modal" style="position: relative;">
    <div style="position: absolute; left: 10px; top: 8px;">
      <svg @click="closeModal" style="cursor: pointer;" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="5.84781" y1="6.39844" x2="14.3331" y2="14.8837" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <line x1="14.332" y1="6.57437" x2="5.84675" y2="15.0597" stroke="black" stroke-width="2" stroke-linecap="round"/>
      </svg>

    </div>
      <div>
          <svg class="icon-suggest" width="58" height="67" viewBox="0 0 58 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6523 65.4865L14.0606 59.3748L7.55409 61.0186C5.79485 61.4629 4.32086 59.6638 5.13013 58.0607L13.4591 41.5566L28.8353 49.1084L20.5064 65.6125C19.6971 67.2143 17.3532 67.1379 16.6523 65.4865Z" fill="#FD3C4F"/>
          <path d="M39.6283 65.4428L42.2201 59.3311L48.7266 60.9749C50.4858 61.4193 51.9598 59.6201 51.1505 58.0171L42.8215 41.5117L27.4453 49.0635L35.7743 65.5676C36.5823 67.1706 38.9275 67.0942 39.6283 65.4428Z" fill="#FD3C4F"/>
          <path d="M57.0374 29.0393C57.0374 31.4279 54.061 33.3989 53.4634 35.6017C52.8451 37.8808 54.4069 41.0525 53.2362 43.0478C52.0488 45.0723 48.4735 45.3167 46.8072 46.9592C45.1422 48.6018 44.8931 52.1287 42.8421 53.3001C40.8196 54.455 37.6044 52.9156 35.2941 53.5242C33.0611 54.115 31.0631 57.0512 28.6417 57.0512C26.2204 57.0512 24.2223 54.115 21.9894 53.5255C19.679 52.9156 16.4639 54.4562 14.4413 53.3014C12.3891 52.13 12.1413 48.603 10.4763 46.9592C8.81125 45.3167 5.23598 45.071 4.04853 43.0478C2.87785 41.0525 4.43832 37.8808 3.82136 35.6017C3.22247 33.3989 0.246094 31.4279 0.246094 29.0393C0.246094 26.6506 3.22247 24.6796 3.82007 22.4768C4.43832 20.1977 2.87656 17.026 4.04724 15.0308C5.23469 13.0063 8.80996 12.7618 10.4763 11.1193C12.1413 9.47676 12.3904 5.9498 14.4413 4.77839C16.4639 3.62354 19.679 5.16292 21.9894 4.5543C24.2223 3.9635 26.2204 1.02734 28.6417 1.02734C31.0631 1.02734 33.0611 3.9635 35.2941 4.55303C37.6044 5.16292 40.8196 3.62227 42.8421 4.77712C44.8944 5.94853 45.1422 9.47548 46.8072 11.1193C48.4722 12.7618 52.0475 13.0075 53.2349 15.0308C54.4056 17.026 52.8451 20.1977 53.4621 22.4768C54.061 24.6796 57.0374 26.6506 57.0374 29.0393Z" fill="#FFA500"/>
          <path opacity="0.3" d="M24.0072 9.90082C26.9268 8.68485 28.5815 5.5246 27.74 2.41782C27.5773 1.81684 27.3089 1.27697 26.9978 0.771484C25.0759 1.45905 23.3709 3.57013 21.5045 4.06288C19.1942 4.67278 15.979 3.13212 13.9565 4.28698C11.9042 5.45839 11.6564 8.98534 9.99139 10.6279C8.32638 12.2704 4.74981 12.5161 3.56236 14.5393C2.39169 16.5346 3.95216 19.7063 3.3352 21.9854C2.83698 23.8266 0.696984 25.5085 0 27.4032C0.512412 27.71 1.05967 27.9749 1.66889 28.1353C2.22777 28.2817 2.78794 28.353 3.33907 28.353C5.85079 28.353 8.17149 26.8926 9.21568 24.5994C10.7774 17.2718 16.5985 11.4988 24.0072 9.90082Z" fill="white"/>
          <path opacity="0.15" d="M52.9754 35.1113C53.573 32.9085 56.5494 30.9375 56.5494 28.5489C56.5494 26.1602 53.573 24.1892 52.9754 21.9864C52.4617 20.0918 53.4336 17.5898 53.1109 15.6507C52.5159 15.6379 51.9131 15.6774 51.3091 15.8366C48.1688 16.6655 46.2185 19.6411 46.5928 22.7351C47.1879 24.5686 47.5144 26.5206 47.5144 28.5489C47.5144 37.6909 41.0002 45.3216 32.3034 47.1972C29.3838 48.4131 27.7291 51.5734 28.5706 54.6802C28.7333 55.2811 29.0017 55.821 29.3128 56.3265C31.2347 55.6389 32.9397 53.5279 34.8061 53.0364C37.1164 52.4277 40.3316 53.9671 42.3541 52.8123C44.4064 51.6409 44.6542 48.1139 46.3192 46.4714C47.9842 44.8289 51.5608 44.5844 52.7482 42.5599C53.9189 40.5622 52.3584 37.3904 52.9754 35.1113Z" fill="black"/>
          <path d="M28.1575 47.6492C38.8501 47.6492 47.5182 39.0983 47.5182 28.5502C47.5182 18.0021 38.8501 9.45117 28.1575 9.45117C17.4649 9.45117 8.79688 18.0021 8.79688 28.5502C8.79688 39.0983 17.4649 47.6492 28.1575 47.6492Z" fill="#FFCE29"/>
          <path d="M28.1549 42.5568C35.9961 42.5568 42.3527 36.2862 42.3527 28.5509C42.3527 20.8156 35.9961 14.5449 28.1549 14.5449C20.3136 14.5449 13.957 20.8156 13.957 28.5509C13.957 36.2862 20.3136 42.5568 28.1549 42.5568Z" fill="#FFA500"/>
          <path d="M24.0782 37.3752C22.9708 37.3752 22.4158 36.8455 22.4158 35.7849C22.4158 34.7421 22.9708 34.2201 24.0782 34.2201H26.6222V24.132L24.7842 25.2244C24.2989 25.5071 23.8614 25.5809 23.4742 25.4485C23.0882 25.3161 22.7953 25.0678 22.5926 24.7024C22.3913 24.3382 22.3319 23.9448 22.4158 23.5221C22.4997 23.0993 22.7759 22.739 23.2483 22.4411L26.7487 20.3529C27.1011 20.139 27.4573 19.9645 27.8187 19.8308C28.1801 19.701 28.5363 19.6348 28.89 19.6348C29.3947 19.6348 29.7922 19.7672 30.0865 20.032C30.3795 20.2969 30.5266 20.6865 30.5266 21.1996V34.2188H32.8176C33.9096 34.2188 34.4555 34.7408 34.4555 35.7836C34.4555 36.8443 33.9109 37.3739 32.8176 37.3739H24.0782V37.3752Z" fill="#FFCE29"/>
          </svg>
      </div>
      <div>
          <span class="headline-suggest">{{ $ml.get('רוצים להמציא שאלה?') }}</span>
      </div>
      <div style="margin: 7px 16px 0px 16px !important">
          <span class="secondheadline-suggest">{{ $ml.get('השאלות שתמציאו יפורסמו עם שמכם') }}</span>
      </div>
      <div style="text-align: start; margin-top: 38px;">
          <span class="li-headline-suggest" >{{ $ml.get('השלבים להמצאת שאלה:') }}</span>
      </div>
      <div>
          <div class="questions">
              <div class="item first">
                  <div class="num">
                      1
                  </div>
                  <div class="text">
                      {{$ml.get('חושבים על שאלה שהייתם רוצים שישאלו אתכם') }}
                  </div>
              </div>
              <div class="item second">
                  <div class="num">
                      2
                  </div>
                  <div class="text">
                      {{ $ml.get('בודקים את תגובתם של שני אנשים לשאלה') }}
                  </div>
              </div>
              <div class="item third">
                  <div class="num">
                      3
                  </div>
                  <div class="text">
                      {{ $ml.get('רצוי שאלה מקורית שכנראה עדיין לא בפלייליסט') }}
                  </div>
              </div>
          </div>
      </div>
      <div style="margin-top:46px">
          <button @click="redirect" style="width: 260px;height: 36px;background: #FDC671;border-radius: 18px;">{{$ml.get('הוספת רעיון לשאלה')}}</button>
      </div>
      <div style="margin-top:15px">
          <button @click="closeModal" style="width: 260px;height: 36px;background: #FFFFFF;border: 1px solid #FDC671;border-radius: 18px;">{{ $ml.get('לא עכשיו') }}</button>
      </div>
      <div>
        <div style="display: inline-block;">
          <span class="footer-suggest">{{$ml.get('תמיד ניתן להמציא שאלה חדשה דרך התפריט הראשי') }}</span>
        </div>
        <div style="display: inline-block;margin-right: 5px;margin-top: 18px;">
          <svg width="10" height="7" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="0.5" x2="9.5" y2="0.5" stroke="black" stroke-linecap="round"/>
          <line x1="0.5" y1="3.5" x2="9.5" y2="3.5" stroke="black" stroke-linecap="round"/>
          <line x1="0.5" y1="6.64258" x2="9.5" y2="6.64258" stroke="black" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
  </div>
</template>

<script>
import icons from "@/store/helpers/icons.store";
import { mapGetters } from "vuex";
export default {
  props: {
   
    ended: {
      type: Function
    }
  },
  watch: {
   
  },
  computed: {
   
  },
  
  data() {
    return {
    
    };
  },
  methods:{
   
    closeModal()
    {
      this.$emit('close');
    },
    redirect(){
      this.$emit('close');
      this.$router.push({name: 'requests-shootlist-add'});
    }
  },
  created() {
 
  },
};
</script>

<style lang="scss" scoped>
.footer-suggest{
  font-family: 'App';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #000000;
}
.questions{
  padding: 0px 20px 0px 0px;
}
.li-headline-suggest{
  font-family: 'App';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 170%;
  /* identical to box height, or 26px */

  text-align: right;

  color: #06364B;
  margin-right: 28px;
}
.item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    padding: 5px 0;

    .num {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-left: 10px;
      margin-top: 6px;
      background: #A0CCDA;
      color: #fff;
      font-size: 12px;
      min-width: 18px;
      font-style: italic;
      // SORRY
      letter-spacing: 1.8px;
    }
    .text {
      text-size-adjust: none;
      flex-grow: 1;
      padding-left: 20px;
      color: #1A2D4A;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      /* or 30px */

      text-align: right;

      color: #06364B;
    }
  }
.secondheadline-suggest{
  font-family: 'Kartiv';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */

  text-align: center;

  color: #06364B;
}
.icon-suggest{
  margin-top: 45px;
}

.headline-suggest{
  font-family: 'Kartiv';
  font-style: italic;
  font-weight: 400;
  font-size: 37px;
  line-height: 50px;
  /* identical to box height, or 135% */

  text-align: center;

  color: #FF7384;

  text-shadow: 
  2px 2px 2px #fff, 
  0 2px 2px #fff, 
  -2px 0 2px #fff, 
  0 -2px 2px #fff,
  3px 3px 2px #fff, 
  0 3px 2px #fff, 
  -3px 0 2px #fff, 
  0 -3px 2px #fff
}

.modal {
  text-align: center;
  max-width: 360px;
  max-height: 580px;
  min-width: 360px;
  min-height: 580px;
  width: 360px;
  height: 580px;
  // padding: 10px;

background: #DDF0F5;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 12px;

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
    box-shadow: rgba($color: #000000, $alpha: 0.2);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
    }
  }
}
</style>
