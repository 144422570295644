<template>
  <main style="background: #005d79;">
    <SimpleHeader>
      <template v-slot:title>
        <span>
          {{ $ml.get("הצעת משחק חדש / שיפור משחק") }}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
      :style="load ? 'height: 100vh;' : ''"
      :loading="load"
      class="sm-cont"
    >
      <div class="form-w">
        <div class="header-f">
          <div :style="{ direction: direction }" class="title">
            {{ $ml.get("יש לכם רעיון למשחק חדש") }}?
          </div>
          <div :style="{ direction: direction }" class="description">
            <span> {{ $ml.get("נהדר") }}! {{ $ml.get("אז קדימה") }}...</span>
            <br />
            <span>{{ $ml.get("מצטרפים לממציאנים ועוזרים להמון ילדים") }}</span>
            <br />
            <span>{{
              $ml.get("בעמוד ממציאי המשחקים תראו כמה שיחקו במשחק שלכם")
            }}</span>
          </div>
          <div class="background"></div>
        </div>
        <ul style="list-style: disc;margin-right: 20px; font-family: 'App';font-style: normal;font-weight: 700;font-size: 15px;line-height: 170%;text-align: right;color: #06364B;">
          {{$ml.get('השלבים להמצאת משחק:')}}
          <li style="margin-right: 20px;font-family: 'App';font-style: normal;font-weight: 400;font-size: 15px;line-height: 170%;text-align: right;color: #06364B;">{{$ml.get('ממציאים שם מעניין למשחק, כמו: המכשפה הצולעת')}}</li>
          <li style="margin-right: 20px;font-family: 'App';font-style: normal;font-weight: 400;font-size: 15px;line-height: 170%;text-align: right;color: #06364B;">{{$ml.get('ממציאים חוקים למשחק')}}</li>
          <li style="margin-right: 20px;font-family: 'App';font-style: normal;font-weight: 400;font-size: 15px;line-height: 170%;text-align: right;color: #06364B;">{{$ml.get('אפשרות: ממציאים חוקים חדשים למשחק קיים')}}</li>
        </ul>
        <form
          @submit="add"
          :class="{ 'disabled-b': disable }"
          class="inputs"
          dir="rtl"
        >
          <div class="section">
            <p style="font-family: 'App';font-style: normal;font-weight: 700;font-size: 14px;line-height: 19px;text-align: right;color: #06364B;">{{ $ml.get("שם המשחק") }}</p>
            <input
              required
              v-model.trim="game"
              class="theme-input"
              :placeholder="$ml.get('שם המשחק')"
            />
          </div>
          <div class="section first">
            <p style="font-family: 'App';font-style: normal;font-weight: 700;font-size: 14px;line-height: 19px;text-align: right;color: #06364B;">{{ $ml.get("שלב") }} 1</p>

            <textarea
              required
              v-model.trim="question_1"
              class="theme-input"
              :placeholder="$ml.get('תיאור השלב הראשון במשחק')"
            ></textarea>
          </div>
          <div class="section second">
            <p style="font-family: 'App';font-style: normal;font-weight: 700;font-size: 14px;line-height: 19px;text-align: right;color: #06364B;">{{ $ml.get("שלב") }} 2</p>
            <textarea
              required
              v-model.trim="question_2"
              class="theme-input"
              :placeholder="$ml.get('תיאור השלב השני במשחק')"
            ></textarea>
          </div>
          <div class="section third">
            <p style="font-family: 'App';font-style: normal;font-weight: 700;font-size: 14px;line-height: 19px;text-align: right;color: #06364B;">{{ $ml.get("שלב") }} 3</p>
            <textarea
              required
              v-model.trim="question_3"
              class="theme-input"
              :placeholder="$ml.get('תיאור השלב השלישי במשחק')"
            ></textarea>
          </div>
          <div class="user-info">
            <div style="font-family: 'App';font-style: normal;font-weight: 700;font-size: 14px;line-height: 19px;text-align: right;color: #06364B;" class="title">{{ $ml.get("פרטי ממציא המשחק") }}</div>
            <div :style="{ direction: direction }" class="description">
              <span>
                {{
                  $ml.get(
                    "עם פרטי הקשר נודיע לכם האם המשחק התקבל או שנחוצים בו שיפורים"
                  )
                }}.</span
              >
              <br />
              <span>{{
                $ml.get("שמכם יפורסם עם המשחק במידה והוא יתקבל")
              }}</span>
            </div>
            <div class="section">
              <input
                required
                type="text"
                v-model.trim="name"
                class="theme-input"
                :placeholder="$ml.get('מסירות לוהטות')"
              />
            </div>
            <!-- <div class="section">
              <edit-field
                :placeholder="$ml.get('מסירות לוהטות')"
                v-model.trim="phone"
              />
            </div> -->
            <div class="section">
              <input
                required
                type="email"
                v-model.trim="email"
                class="theme-input"
                :placeholder="$ml.get('כתובת דוא”ל')"
              />
              <span class="floating-placeholder" style="color: red;" v-if="msg.email">{{$ml.get(msg.email)}}</span>
            </div>
          </div>
          <div class="school">
            <div v-if="user.id_school === null" class="no-sub-w">
              <div class="title">
                {{ $ml.get("שם בית הספר והכיתה יפורסמו לצד שמכם (לא חובה)") }}
              </div>
              <div class="nosub">
                <div class="col">
                  <div class="section">
                    <input
                      style="width: 118px"
                      v-model.trim="student_class"
                      class="theme-input"
                      :placeholder="$ml.get('כיתה')"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="section">
                    <input
                      style="width: 218px"
                      v-model.trim="city"
                      class="theme-input"
                      :placeholder="$ml.get('עיר')"
                    />
                  </div>
                  <div class="section">
                    <input
                      style="width: 218px"
                      v-model.trim="school"
                      class="theme-input"
                      :placeholder="$ml.get('בית-ספר')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="sub">
              <div class="section">
                <input
                  style="width: 100%"
                  :value="school"
                  class="theme-input"
                  :placeholder="$ml.get('עיר')"
                />
              </div>
            </div>
          </div>
          <!--        <div class="section additional">-->
          <!--          <div class="section">-->
          <!--            <p>בית ספר <span class="small">(לא חובה)</span></p>-->
          <!--            <school-selector v-model="school" />-->
          <!--          </div>-->
          <!--          <div class="section city">-->
          <!--            <p>עיר / ישוב <span class="small">(לא חובה)</span></p>-->
          <!--            <input v-model="city" class="theme-input" />-->
          <!--          </div>-->
          <!--          <div class="section credit">-->
          <!--            <label class="heebo">-->
          <!--              <input-->
          <!--                v-model="credit"-->
          <!--                class="theme-checkbox-big"-->
          <!--                type="checkbox"-->
          <!--              />-->
          <!--              רוצה קרדיט-->
          <!--            </label>-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="checkboxes">
            <div class="section credit">
              <label>
                <input
                  v-model="hideName"
                  class="theme-checkbox-big"
                  type="checkbox"
                />
                {{ $ml.get("אני לא רוצה ששמי יפורסם עם המשחק") }}
              </label>
            </div>
            <div
              v-if="user.id_school !== null"
              :style="hideName ? 'opacity:0.5;pointer-events:none' : ''"
              class="section credit"
            >
              <label>
                <input
                  v-model="hideSchool"
                  class="theme-checkbox-big"
                  type="checkbox"
                />
                {{
                  $ml.get("אני לא רוצה שהכיתה ושם בית הספר יפורסמו עם המשחק")
                }}
              </label>
            </div>
          </div>

          <button
            v-if="!disable"
            :class="{ 'disabled-b': disable }"
            class="theme-button-outline send-b"
          >
            {{ $ml.get("שליחה") }}
          </button>
          <app-btn
            v-else
            :loading="disable"
            :class="disabledBtn?'disabled':'active'"
            class="theme-button-outline send-b"
            >{{ $ml.get("שליחה") }}</app-btn
          >
        </form>
      </div>
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import RequestPlaylistCreated from "@/components/modals/RequestPlaylistCreated.vue";
import SchoolSelector from "@/components/SchoolSelector.vue";
import EditField from "@/components/playlist/EditField";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      school: "",
      city: "",
      name: "",
      userImg: true,
      game: "",
      // phone: "",
      email: "",
      question_1: "",
      question_2: "",
      question_3: "",
      student_class: "",
      additional: false,
      comment: "",
      hideName: false,
      hideSchool: false,
      load: false,
      disable: false,
      msg: [],
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
  },
  computed: {
     ...mapGetters({
      isDefaultUser: "user/getDefaultUser",
     }),
    user() {
      return this.$store.getters["user/data"];
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
    userSchool() {
      return this.$store.getters["user/school"];
    },
    disabledBtn(){
      return true;
    }
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "נא להזין אימייל חוקי";
      }
    },
    add(e) {
      e.preventDefault();
      if(this.msg["email"] == ''){
        this.disable = true;
      axios
        .post("/playlist/request", {
          question_1: this.question_1,
          question_2: this.question_2,
          question_3: this.question_3,
          city: this.city,
          school: this.school,
          // phone: this.phone,
          name: this.name,
          game: this.game,
          email: this.email,
          student_class: this.student_class,
          hideName: this.hideName,
          hideSchool: this.hideSchool,
        })
        .then((res) => {
          this.$modal.show(
            RequestPlaylistCreated,
            {},
            {
              adaptive: true,
              height: "auto",
              width: 300,
            }
          );
          this.disable = false;
          this.$router.replace("/playlist");
        })
        .catch((res) => {
          this.disable = false;
          this.$store.commit("alert", res.data.error);
        });
      }else{
         this.msg["email"] ="נא להזין אימייל חוקי";
      }
    },
  },
  components: {
    SimpleHeader,
    SchoolSelector,
    SidebarToggler,
    EditField,
  },
  created() {
    window.fbq("track", "המצאת משחק");
    this.name = !this.isDefaultUser ?
      _.cloneDeep(
        this.user.name + " " + (this.user.surname ? this.user.surname : "")
      ) || "" : null;
    // this.phone = _.cloneDeep(this.user.phone) || "";
    if(!this.isDefaultUser)
    {
    this.email = !this.isDefaultUser ? _.cloneDeep(this.user.email) || "" : null;
    }
    this.userImg = this.user.img !== null;
    if (this.user.id_school !== null) {
      this.school =
        this.userSchool.city +
        (this.user.class ? ", " : "") +
        ((this.user.class ? this.user.class : "") +
          (this.user.class_number ? this.user.class_number : "") +
          ", ") +
        this.userSchool.name;
    }

    this.load = true;
    setTimeout(() => {
      this.load = false;
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.disabled-b {
  pointer-events: none;
}
main {
  .theme-input {
    padding: 2px;
    padding-right: 13px;
    height: 30px;
    width: 100%;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: right;

    color: #00465b;
    &.main {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .theme-checkbox-big {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    &:before {
      position: absolute;
      left: 1px;
      top: 1px;
      width: 9px;
      height: 9px;
      content: "";
    }
  }
  .form-w {
    background: #ddf0f5;
    min-height: 180px;
    .header-f {
      .background {
        top: 0;
        background-image: url("../../assets/img/add-playlist.png");
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.15;
        z-index: 0;
      }

      .title {
        font-weight: bold;
        font-size: 28px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        padding: 27px 0 20px 0;
      }
      .description {
        text-align: center;
        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #000000;
        }
      }
      background: #f2828f;
      position: relative;
      width: 100%;
      height: 180px !important;
    }
  }
  .inputs {
    padding: 0 20px;
    padding-bottom: 30px;
    .send-b {
      &.disabled-b {
        pointer-events: none;
        background: rgba(0, 60, 72, 0.9);
      }
    }
    .small {
      font-size: 14px;
    }
    .section {
      position: relative;
      margin: 12px 0;
      &.first {
        &:after {
          font-size: 12px;
          text-align: center;
          position: absolute;
          color: white;
          top: 41px;
          right: 10px;
          content: "1";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #a0ccda;
        }
      }
      &.second {
        &:after {
          font-size: 12px;
          text-align: center;
          position: absolute;
          color: white;
          top: 41px;
          right: 10px;
          content: "2";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #a0ccda;
        }
      }
      &.third {
        &:after {
          font-size: 12px;
          text-align: center;
          position: absolute;
          top: 41px;
          right: 10px;
          content: "3";
          color: white;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #a0ccda;
        }
      }
      p {
        margin: 0 0 10px 0;

        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #00465b;
      }
      input[typer="text"],
      textarea {
        font-size: 16px;
      }
      .theme-input {
        border: none;
      }
      textarea {
        padding: 10px 40px 10px 25px;
        height: 80px;
        resize: none;
        vertical-align: middle;
      }
      .inp-wrapper {
        position: relative;
        span {
          position: absolute;
          left: 10px;
          bottom: 0;
          font-size: 12px;
        }
      }
    }
    .additional {
      margin: 0 -20px;
      margin-top: 15px;
      padding: 0 20px;
      padding-bottom: 20px;
      .sec {
        margin-top: 15px;
      }
    }
    .check-credit {
      input {
        vertical-align: middle;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .theme-button-outline {
    background: #005d79;
    border-radius: 18px;
    border: none;
    width: 180px;
    display: block;
    margin: 0 auto;
  }
  .checkboxes {
    margin-bottom: 30px;
    .credit {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      input {
        margin-left: 10px;
      }
    }
  }

  .new-task {
    margin-top: 0;
    font-size: 14px;
  }
}
.user-info {
  margin: 30px 0 10px 0;
  .title {
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #00465b;
  }
  .description {
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #4d4d4d;
  }
}
.school {
  .title {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #4d4d4d;
  }
  .nosub {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    .col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
</style>
