<template>
  <button class="gift-element">

    <div class="icon-wrapper">
      <div class="circle"></div>
    </div>

    <div class="name"><div class="line h17 w40 m10"></div></div>
  </button>
</template>

<script>
export default {
  name: "GiftElementSkeleton",
}
</script>

<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  
  position: absolute;
     height: 12px;
     left: 22px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:60% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

.gift-element {
  position: relative;
  height: 120px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background: #FFFFFF;
    border-radius: 50%;
    img{
      max-width: 50px;
      min-width: 30px;
      max-height: 50px;

    }
  }
  .name{
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 7px;
    width: 126px;
  }
  .recommended{
    top: 0;
    right: 0;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FBB03B;
    border: 1px solid #FFFFFF;
  }
  .counter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #8CC63F;
    border: 1px solid #FFFFFF;
    bottom: 0;
    left: 0;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>