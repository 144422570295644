<template>
  <button @click="goGame" class="game-element">
    <div class="icon-wrapper">
      <div class="info">
      <div v-if="game.details.isHome=== 1" style="margin: 0 2px"  class="home">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="20" viewBox="0 0 240.000000 20.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(-2.000000,150.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M742 2377 c-70 -29 -113 -66 -148 -124 -24 -41 -29 -61 -32 -140 -4
                    -80 -1 -98 18 -140 13 -26 37 -63 54 -81 l31 -33 -30 -25 c-63 -53 -68 -54
                    -110 -43 -100 28 -256 -20 -330 -102 -63 -70 -89 -140 -90 -239 0 -76 3 -92
                    33 -152 18 -36 50 -82 72 -102 22 -20 40 -38 40 -40 0 -2 -20 -16 -44 -30 -51
                    -30 -119 -104 -148 -161 -49 -95 -58 -141 -58 -296 0 -81 3 -155 6 -164 7 -18
                    73 -54 124 -67 201 -49 416 -50 638 -3 l32 8 0 -116 c0 -140 4 -146 113 -182
                    108 -36 227 -48 397 -42 176 6 278 28 358 77 l32 20 0 121 0 121 33 -6 c187
                    -40 314 -45 502 -21 110 14 144 21 200 46 65 28 65 28 65 194 0 161 -10 216
                    -55 305 -21 42 -137 162 -178 185 -17 9 -14 15 29 59 72 75 99 143 99 246 0
                    98 -26 168 -89 237 -75 84 -213 128 -324 104 -51 -11 -53 -10 -102 25 -28 20
                    -50 38 -50 41 0 3 13 21 30 39 58 66 74 111 73 204 0 71 -4 93 -26 135 -49 97
                    -129 149 -247 160 -94 10 -162 -17 -233 -94 -112 -122 -114 -283 -4 -408 17
                    -19 26 -37 21 -42 -5 -4 -28 -22 -52 -40 -51 -40 -96 -103 -127 -179 l-23 -56
                    -13 44 c-24 83 -89 169 -169 221 l-24 16 36 44 c51 62 72 120 72 204 1 86 -25
                    147 -90 210 -83 82 -207 106 -312 62z m184 -93 c52 -21 81 -50 105 -103 63
                    -144 -47 -296 -202 -278 -77 9 -129 47 -160 114 -75 164 90 336 257 267z m790
                    0 c53 -22 83 -51 105 -102 64 -147 -49 -297 -210 -279 -115 13 -202 153 -162
                    260 13 33 69 96 106 119 37 22 109 23 161 2z m-723 -522 c61 -33 119 -104 142
                    -171 25 -77 18 -100 -37 -131 -218 -119 -261 -379 -93 -559 l47 -50 -29 -14
                    c-15 -8 -49 -34 -75 -57 l-48 -42 0 46 c0 26 -4 54 -9 64 -5 9 -17 38 -26 65
                    -31 86 -113 182 -200 233 -17 10 -15 14 23 49 54 49 100 142 108 221 10 92
                    -32 208 -100 282 -27 28 -28 34 -15 47 8 8 39 24 69 35 75 28 173 21 243 -18z
                    m795 -2 c23 -13 42 -27 42 -30 0 -4 -12 -17 -26 -31 -14 -13 -42 -53 -62 -89
                    -34 -61 -36 -70 -36 -155 -1 -78 3 -98 28 -152 17 -38 47 -79 74 -105 41 -38
                    44 -43 27 -52 -49 -29 -119 -92 -150 -134 -37 -51 -85 -187 -85 -240 l0 -34
                    -47 42 c-27 23 -61 49 -76 57 l-29 14 47 50 c73 78 100 146 100 249 0 67 -5
                    96 -24 137 -28 64 -122 155 -186 183 l-47 20 7 48 c14 100 79 196 155 229 82
                    36 218 33 288 -7z m-1231 -86 c63 -35 78 -49 108 -100 21 -36 25 -55 25 -121
                    0 -114 -36 -178 -128 -223 -72 -37 -149 -39 -217 -6 -128 63 -181 210 -119
                    334 62 125 216 179 331 116z m1588 7 c63 -28 106 -71 132 -128 31 -72 30 -139
                    -3 -208 -59 -127 -200 -178 -328 -120 -95 43 -136 111 -136 225 0 39 6 85 13
                    103 50 118 207 181 322 128z m-790 -303 c83 -44 145 -137 145 -219 0 -139
                    -101 -246 -242 -257 -46 -3 -66 1 -111 22 -193 91 -194 361 -2 453 44 21 59
                    23 120 18 38 -4 79 -11 90 -17z m-803 -295 c106 -34 188 -113 227 -218 17 -45
                    21 -79 21 -179 0 -87 -4 -126 -12 -133 -33 -25 -148 -44 -296 -49 -137 -5
                    -170 -3 -262 16 -57 12 -111 26 -118 33 -11 8 -13 41 -10 146 3 121 6 142 29
                    191 77 165 256 247 421 193z m1656 -23 c74 -39 125 -92 161 -169 23 -50 26
                    -70 29 -192 4 -150 5 -148 -64 -167 -159 -46 -557 -32 -621 20 -9 7 -13 46
                    -13 129 0 94 4 132 22 184 64 197 303 293 486 195z m-877 -271 c66 -14 151
                    -69 195 -126 58 -75 74 -137 74 -278 l0 -124 -27 -14 c-16 -8 -61 -22 -102
                    -31 -142 -31 -458 -14 -543 30 l-29 15 3 137 c3 127 5 141 32 196 36 73 76
                    119 134 153 84 49 164 62 263 42z"/>
                    </g>
                    </svg>
      </div>
      </div>
      <img :src="game.details.img" alt="">
      <div v-if="game.stats.played>0" class="counter">{{game.stats.played}}</div>
    </div>
    <div class="name">{{translated ? translated : game.details.translation[0].data.name}}</div>
  </button>
</template>

<script>
export default {
  name: "GiftElement",
  props:{
    game: {},
    gifts:{},
    id_category:null,
  },
  data(){
    return{

    }
  },
  computed:{
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.game.details.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data.name
      }
    },
  },
  methods:{
    goGame(){
      this.$router.push({name: 'targeted-playlist', params: { id_playlist: this.game.id_playlist , gifts: this.gifts, infinity: true, id_category:this.game.id_category }});
    }
  },
  created(){
  }
}
</script>

<style scoped lang="scss">
  //
.game-element {
  margin: 8px 0!important;
  position: relative;
  height: 120px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;

    background: #FFFFFF;
    border-radius: 5px;
  }
  .name{
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 7px;
    width: 126px;
  }
  img{
    width: 50px;
    height: 50px;
  }
  .info{
    top: 0;
    left: 4px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    .home{
      margin-right: 4px;
    }
  }
  .counter{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #8CC63F;
    border: 1px solid #FFFFFF;
    bottom: 4px;
    left: 4px;
    font-size: 10px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>