<template>
  <div class="main-nonschool">
    <div class="logo">
      <img v-if="promo.img" :src="promo.img ? promo.img.url : ''" alt="" />
      <img v-else-if="user.img" :src="user.img ? user.img : ''" alt="" />
      <svg v-else
        enable-background="new 0 0 512.004 512.004"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m433.52 326.58c44.063-3.841 78.483-34.029 78.483-70.583 0-36.555-34.42-66.743-78.483-70.584-17.083-42.921-49.394-78.429-90.567-99.51-1.099-46.983-39.391-85.906-86.95-85.906-47.523 0-85.85 38.88-86.949 85.907-41.174 21.081-73.485 56.59-90.567 99.51-44.064 3.84-78.484 34.028-78.484 70.583 0 36.558 34.425 66.747 78.493 70.584 14.207 35.609 39.197 66.452 70.952 87.912-25.778 20.27-44.952 47.234-54.979 77.833-2.58 7.872 1.711 16.345 9.583 18.925 7.872 2.579 16.345-1.712 18.925-9.583 9.468-28.892 29.034-53.882 55.478-71.09 51.076 22.764 108.9 21.406 157.84-1.26 27.456 17.228 47.723 42.665 57.448 72.349 2.58 7.873 11.051 12.162 18.925 9.584 7.873-2.58 12.164-11.052 9.584-18.925-10.334-31.54-30.275-59.103-57.157-79.532 29.671-20.653 54.171-50.322 68.42-86.214zm48.483-70.583c0 17.523-16.517 32.894-38.914 38.617 5.214-25.362 5.225-51.818 0-77.233 22.397 5.722 38.914 21.093 38.914 38.616zm-226-226c28.873 0 52.8 21.955 56.502 50.321-35.872 3.083-65.589 27.873-75.757 61.366-22.231-7.906-37.745-29.065-37.745-53.688 0-31.98 25.57-57.999 57-57.999zm-226 226c0-17.523 16.517-32.894 38.914-38.617-5.214 25.362-5.225 51.818 0 77.233-22.397-5.722-38.914-21.093-38.914-38.616zm300.08 142.99c-86.299 44.823-193.69 3.849-226.8-91.923-24.054-71.962 5.502-151 70.809-189.68 10.948 30.681 38.599 53.534 72.726 57.126 8.262 0.869 15.65-5.143 16.493-13.393 3.125-30.584 29.847-53.289 61.013-50.936 73.315 34.395 110.51 118.83 84.416 196.88-14.501 42.042-43.611 73.733-78.652 91.924z"
        />
        <path
          d="m219.18 238.32c5.858-5.858 5.858-15.355 0-21.213-21.494-21.494-56.285-21.497-77.782 0-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0 9.771-9.77 25.584-9.771 35.355 0 5.858 5.857 15.355 5.858 21.214 0z"
        />
        <path
          d="m371.18 217.11c-21.494-21.494-56.285-21.497-77.782 0-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0 9.771-9.77 25.584-9.771 35.355 0 5.857 5.857 15.354 5.859 21.213 0 5.859-5.857 5.859-15.355 1e-3 -21.213z"
        />
        <path
          d="m284.99 317.39c-15.986 15.986-41.997 15.986-57.983 0-5.857-5.857-15.355-5.858-21.213 0-5.857 5.858-5.857 15.355 0 21.213 27.683 27.682 72.726 27.682 100.41 0 5.858-5.858 5.858-15.355 0-21.213-5.859-5.857-15.355-5.857-21.213 0z"
        />
      </svg>
    </div>
    
    <div class="info">
      <div class="first">
        <div class="f-el">{{ $ml.get('שלום')}}</div>
        <div class="f-el" v-if="!isDefaultUser">{{ user.name }}</div>
        <!-- <div class="f-el">{{ user.surname }}</div> -->
        
      </div>
      <!-- <div class="second">
        <div class="f-el">{{ promo.client }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MainHeader",
  components: {},
  computed: {
    ...mapGetters({
      isDefaultUser: "user/getDefaultUser"
    }),
    school() {
      return this.$store.getters["user/school"];
    },
    user() {
      return this.$store.getters["user/data"];
    },
    promo() {
      return this.$store.getters["user/data"].promo;
    },
    position() {
      switch (this.user.position) {
        case "teacher":
          return "מחנכת";
        case "prof-teacher":
          return "מורה מקצועית";
        case "director":
          return "מנהל ";
        case "student":
          return "";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
          float: right;
          min-width: 64px;
          min-height: 64px;
          border-radius: 35px;
          border: 1px solid #fff;
          display: block;
        }
.main-nonschool {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .info {
    padding-top:6px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent !important;

    .f-el {
      margin: 0 3px;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      color: #FFFFFF;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
    }
    .first {
      display: flex;
      flex-direction: row;
      align-items: center;
      
    }
    .second {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    padding: 0px 5px;
    width: 34px;
    height: 34px;
    img {
      width: 34px;
      max-width: 34px;
      max-height: 34px;
      border-radius: 16px;
      object-fit: contain;
    }
  }
  text-align: center;
  p {
    font-size: 1.8rem; /*22px;*/
    margin: 0;
  }
  .wrapper {
    position: relative;
    top: 1rem;
  }
}
</style>