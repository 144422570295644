const DirectorAPI = {


  getTargets(data) {
    return config.API.get(`/director/settings/targets?filter=${data.filter==='prof-teacher'? 'prof' : data.filter }` + (data.id!=undefined ?  `&id_teacher=${data.id}` : '') )
  },

  setTargets(data){
    return config.API.post('/director/settings/targets', data)
  },

  getTeachers() {
    return config.API.get('/director/settings/teachers')
  },

  getClasses() {
    return config.API.get('/director/settings/classes')
  },

  getProf() {
    return config.API.get('/director/settings/prof-teachers')
  },

  getOrigin() {
    return config.API.get('director/settings/origin')
  },

  getTotal(data){
    return config.API.post('/director/report/total', data)
  },
  getFull(data){
    return config.API.post('/director/report/full', data)
  },
  setPlaylistFavorive(data){
    return config.API.post('/director/favorite/playlist/setClasses', data)
  },
  setShootlistFavorive(data){
    return config.API.post('/director/favorite/shootlist/setClasses', data)
  },
  setPlaylistOrder(data){
    return config.API.post('/director/favorite/playlist/setOrder', data)
  },
  setShootlistOrder(data){
    return config.API.post('/director/favorite/shootlist/setOrder', data)
  },
   deletePlaylist(data){
    return config.API.delete('/director/favorite/playlist/'+data)
  },
  deleteShootlist(data){
    return config.API.delete('/director/favorite/shootlist/'+data)
  },
};

export { DirectorAPI }