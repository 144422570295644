<template>
  <main>
    <simple-header >
      <template v-slot:title>
        <span>{{$ml.get("המועדפים שלי")}}</span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </simple-header>
    <div class="buttons">
      <button
        @click="selected = 'playlist'"
        :class="{ selected: selected == 'playlist' }"
      >
        {{$ml.get("משחקים")}}
      </button>
      <button
        @click="selected = 'shootlist'"
        :class="{ selected: selected == 'shootlist' }"
      >
        {{$ml.get("שאלות")}}
      </button>
    </div>
    <div class="table">
      <div v-if="user.position==='director'" class="info">

        <div class="i-row">
          <span>{{$ml.get("המשחקים המועדפים יכנסו לקטגורית 'מועצת התלמידים' בהגדרות")}}</span> <br/>
          <span> {{$ml.get("המשחקים ויוצגו לתלמידים בראש רשימת המשחקים שלהם")}}.</span>
        </div>

        <div class="i-row">
          <span>{{$ml.get("מועצת התלמידים תעביר אליך מעת לעת את רשימת השאלות")}}</span> <br/>
          <span> {{$ml.get("המועדפות שלהם")}}.{{$ml.get("גם אותן תסמני כמועדפים כדי שיופיעו בקטגורית")}} </span> <br/>
          <span> {{$ml.get("'מועצת התלמידים'")}}</span>
        </div>

        <div class="i-row">
          <span>{{$ml.get("ניתן לשנות בגרירה את סדר השאלות כפי שיוצגו לתלמידים ואת")}}</span> <br/>
          <span> {{$ml.get("קבוצת הגיל")}}</span>
        </div>
      </div>

      <div v-else-if="user.position==='teacher'" class="info-all">
       <span>{{$ml.get("המשחקים המועדפים יופיעו לתלמידים בראש רשימת")}}</span>
       <span> {{$ml.get("ניתן לשנות את סדר המשחקים")}}.{{$ml.get("המשחקים שלהם")}}</span>
      </div>
      <div v-if="selected == 'playlist'" class="selected" style="padding-top: 20px;">
        <app-loading style="height: 100px;min-height: 100px;max-height: 100px;}" v-if="!loaded.playlist" :loading="!loaded.playlist">

        </app-loading>
        <div v-else-if="data.playlist.length>0 && loaded.playlist"  :key="index" class="selected-row">
          <draggable
            group="playlist"
            @change="updateD"
            class="drag-g"
            :list="data.playlist"
            ghost-class="ghost"
            :move="checkMove"
            handle=".handle"
            @start="dragging = true"
            @end="dragging = false"
             >

            <div   :key="i*9" v-for="(item, i) in data.playlist"  class="row" >
              <PlaylistElement style="width: 100%;margin: 0 5px;" :isDirector="user.position==='director'" @update="sync" :item="item"  />
            </div>

          </draggable>

        </div>

        <div v-else class="empty">

          <h3>
            <span style="line-height: 45px;">{{$ml.get("עדיין לא נבחרו שאלות לרשימת המועדפים")}} </span> <br>
            <span>{{$ml.get("מסמנים")}}</span>

            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05889 28.9411 1 19 1C9.05887 1 1 9.05889 1 19C1 28.9411 9.05887 37 19 37Z" stroke="#4D4D4D" stroke-miterlimit="10"/>
              <path d="M18.1902 14.9398L18.5391 15.2796L18.8879 14.9398L20.0342 13.8234C20.0342 13.8233 20.0343 13.8233 20.0343 13.8233C20.8532 13.0261 21.9663 12.576 23.1296 12.576C24.2929 12.576 25.406 13.0261 26.2249 13.8232C27.0429 14.6204 27.5 15.6984 27.5 16.8197C27.5 17.9409 27.0429 19.019 26.2249 19.8162L25.0786 20.9326L18.5391 27.302L11.9995 20.9326L11.6506 21.2908L11.9995 20.9326L10.8531 19.8161L10.8532 19.816L10.8451 19.8084C10.4248 19.4169 10.0899 18.9483 9.85925 18.4304C9.6286 17.9126 9.50669 17.3557 9.50027 16.7922C9.49385 16.2287 9.60305 15.6693 9.82181 15.1467C10.0406 14.6241 10.3647 14.1485 10.7759 13.748C11.187 13.3475 11.6769 13.0304 12.2171 12.8159C12.7574 12.6014 13.3366 12.494 13.9207 12.5003C14.5048 12.5066 15.0814 12.6265 15.6165 12.8526C16.1516 13.0787 16.6341 13.4063 17.036 13.8155L17.0359 13.8155L17.0438 13.8233L18.1902 14.9398Z" stroke="#4D4D4D"/>
            </svg>

            <span>{{$ml.get("לשאלה אותה רוצים לצרף למועדפים")}}</span>
          </h3>
        </div>
      </div>
      <div v-else class="selected" style="padding-top: 20px;">
        <app-loading style="height: 100px;min-height: 100px;max-height: 100px;}" v-if="!loaded.shootlist" :loading="!loaded.shootlist">

        </app-loading>
        <div v-else-if="data.shootlist.length>0 && loaded.shootlist" :key="index" class="selected-row">
          <draggable
            @change="updateD"
            group="shootlist"
            class="drag-g"
            :list="data.shootlist"
            ghost-class="ghost"
            handle=".handle"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
          >
          <div class="row" :key="i*2" v-for="(item, i) in data.shootlist" >
            <ShootlistElement style="width: 100%;margin: 0 5px;" :isDirector="user.position==='director'"  :item="item" />
          </div>
          </draggable>
        </div>

        <div v-else class="empty">
          <h3>

            <span style="line-height: 45px;">{{$ml.get("עדיין לא נבחרו שאלות לרשימת המועדפים")}}</span> <br>
            <span>{{$ml.get("מסמנים")}}</span>
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 37C28.9411 37 37 28.9411 37 19C37 9.05889 28.9411 1 19 1C9.05887 1 1 9.05889 1 19C1 28.9411 9.05887 37 19 37Z" stroke="#4D4D4D" stroke-miterlimit="10"/>
              <path d="M18.1902 14.9398L18.5391 15.2796L18.8879 14.9398L20.0342 13.8234C20.0342 13.8233 20.0343 13.8233 20.0343 13.8233C20.8532 13.0261 21.9663 12.576 23.1296 12.576C24.2929 12.576 25.406 13.0261 26.2249 13.8232C27.0429 14.6204 27.5 15.6984 27.5 16.8197C27.5 17.9409 27.0429 19.019 26.2249 19.8162L25.0786 20.9326L18.5391 27.302L11.9995 20.9326L11.6506 21.2908L11.9995 20.9326L10.8531 19.8161L10.8532 19.816L10.8451 19.8084C10.4248 19.4169 10.0899 18.9483 9.85925 18.4304C9.6286 17.9126 9.50669 17.3557 9.50027 16.7922C9.49385 16.2287 9.60305 15.6693 9.82181 15.1467C10.0406 14.6241 10.3647 14.1485 10.7759 13.748C11.187 13.3475 11.6769 13.0304 12.2171 12.8159C12.7574 12.6014 13.3366 12.494 13.9207 12.5003C14.5048 12.5066 15.0814 12.6265 15.6165 12.8526C16.1516 13.0787 16.6341 13.4063 17.036 13.8155L17.0359 13.8155L17.0438 13.8233L18.1902 14.9398Z" stroke="#4D4D4D"/>
            </svg>

            <span>{{$ml.get("לשאלה אותה רוצים לצרף למועדפים")}}</span>

          </h3>
        </div>
      </div>
<!--      <div class="row" v-for="item in data[selected]">-->
<!--        <button class="delete" @click="dislike(item)">-->
<!--          <i class="icon ion-md-close-circle-outline"></i>-->
<!--        </button>-->
<!--        <span @click="goTo(item)">{{ getName(item) }}</span>-->
<!--      </div>-->

      <DeleteLiked @update="sync" />
    </div>
  </main>
</template>

<script>
import draggable from 'vuedraggable'
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import ShootlistElement from "@/components/liked/ShootlistElement";
import PlaylistElement from "@/components/liked/PlaylistElement";
import DeleteLiked from "@/components/modals/modal/DeleteLiked";
import {DirectorAPI, TeacherAPI} from "@/api";

export default {
  data() {
    return {
      selected: "playlist",
      loaded:{
        playlist: false,
        shootlist : false,
      },
      index: 0,
      data: {
        playlist: [],
        shootlist: [],
      },
      dragging: false
    };
  },
  computed:{
    user(){
      return this.$store.getters['user/data'];
    },

    draggingInfo() {
      return this.dragging ? "under drag" : "";
    }
  },
  components: {
    draggable,
    SimpleHeader,
    SidebarToggler,
    ShootlistElement,
    PlaylistElement,
    DeleteLiked,
  },
  methods: {
    updateD(){
      this.index++;
      let filtered = [];
      if (this.$store.getters['user/data'].position === 'director') {
        if (this.selected === 'playlist') {
          filtered = this.data.playlist.map(item => {
            return {id_playlist: item.id_playlist, order_index: item.order}
          });
          DirectorAPI.setPlaylistOrder(filtered);
        } else {
          filtered = this.data.shootlist.map(item => {
            return {id_shootlist: item.id_shootlist, order_index: item.order}
          });
          DirectorAPI.setShootlistOrder(filtered);
        }
      } else if (this.$store.getters['user/data'].position === 'teacher') {
        if (this.selected === 'playlist') {
          filtered = this.data.playlist.map(item => {
            return {id_playlist: item.id_playlist, order_index: item.order}
          });
          TeacherAPI.setPlaylistOrder(filtered);
        } else {
          filtered = this.data.shootlist.map(item => {
            return {id_shootlist: item.id_shootlist, order_index: item.order}
          });
          TeacherAPI.setShootlistOrder(filtered);
        }
      }
    },
    checkMove: function(e) {
    },
    getName(item) {
      if (this.selected == "shootlist") {
        if (this.$store.getters["user/data"].gender == "fem") {
          return item.content_fem;
        } else {
          return item.content_man;
        }
      } else {
        return item.name;
      }
    },
    goTo(item) {
      if (this.selected == "shootlist") {
        this.$router.push({
          name: "shootlist",
          params: {
            id_shootlist: item.id_shootlist
          }
        });
      } else {
        this.$router.push({
          name: "playlist",
          params: {
            id_playlist: item.id_playlist
          }
        });
      }
    },

    sync() {
      this.loaded.playlist = false;
      this.loaded.shootlist = false;
      if (this.$store.getters['user/data'].position === 'director') {
        axios.get("director/favorite/shootlist").then(res => {
          if (res.data>0 && +res.data[0].order === 0 ){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.shootlist = res.data;
          } else {
            this.data.shootlist = res.data;
          }
          this.loaded.shootlist = true;
        });
        axios.get("director/favorite/playlist").then(res => {
          if (res.data>0 && +res.data[0].order === 0 ){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.playlist = res.data;
          } else {
            this.data.playlist = res.data;
          }

          this.loaded.playlist = true;
        });
      } else if(this.$store.getters['user/data'].position === 'teacher'){
        axios.get("teacher/favorite/shootlist").then(res => {
          if (res.data>0 && +res.data[0].order === 0  ){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.shootlist = res.data;
          } else {
            this.data.shootlist = res.data;
          }
          this.loaded.shootlist = true;
        });
        axios.get("teacher/favorite/playlist").then(res => {
          if (res.data>0 && +res.data[0].order === 0){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.playlist = res.data;
          } else {
            this.data.playlist = res.data;
          }
          this.loaded.playlist = true;
        });
      } else {
        axios.get("shootlist/liked").then(res => {
          if (res.data>0 && +res.data[0].order === 0 ){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.shootlist = res.data;
          } else {
            this.data.shootlist = res.data;
          }
          this.loaded.shootlist = true;
        });
        axios.get("playlist/liked").then(res => {
          if (res.data>0 && +res.data[0].order === 0  ){
            res.data.forEach((item,i)=>{
              item.order = i+1;
            });
            this.data.playlist = res.data;
          } else {
            this.data.playlist = res.data;
          }
          this.loaded.playlist = true;
        });
      }
    },
    dislike(item) {
      if (this.$store.getters['user/data'].position === 'director') {
        if (this.selected === "playlist") {
          this.data.playlist = this.data.playlist.filter(
              n => n.id_playlist !== item.id_playlist
          );
          DirectorAPI.deletePlaylist({playlist_id: item.id_playlist});
        } else {
          this.data.shootlist = this.data.shootlist.filter(
              n => n.id_shootlist !== item.id_shootlist
          );
          DirectorAPI.deleteShootlist({playlist_id: item.id_shootlist});
        }

      } else {
        if (this.selected === "playlist") {
          this.data.playlist = this.data.playlist.filter(
              n => n.id_playlist !== item.id_playlist
          );
          axios.delete("playlist/" + item.id_playlist + "/dislike");
        } else {
          this.data.shootlist = this.data.shootlist.filter(
              n => n.id_shootlist !== item.id_shootlist
          );
          axios.delete("shootlist/" + item.id_shootlist + "/dislike");
        }
      }
    }
  },
  created() {
    window.fbq("track", "המועדפים שלי");
    this.selected = this.$route.params.type || 'playlist';
    this.sync();
  }
};
</script>

<style lang="scss" scoped>
main {
  background: #F2F2F2!important;
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .empty{
    margin: 0 10px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
      text-align: center;
      svg{
        margin: 0 10px;
      }
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;

        color: #4D4D4D;
      }
    }
  }
  .info{
    margin: 21px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .i-row{
      width: 315px;
      position: relative;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      margin: 6px 0;
      color: #4D4D4D;
      &:after{
        position: absolute;
        content: '';
        background: #A2A2A2;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 6px;
        right: -15px;
      }
    }
  }
  .info-all{
    margin: 21px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    span{
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #4D4D4D;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      flex-grow: 1;
      height: 40px;
      font-style: normal;
      font-weight: normal;
      color: #4D4D4D;

      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;

      padding: 0;
      display: inline-block;
      margin: 0;
      &.selected {
        font-weight: bold;
        color: #FF7384;
        border-bottom: 4px solid #FF7384;
      }
    }
  }
  .drag-g{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row {
    min-height: 60px;
    max-width: 500px;
    position: relative;
    padding: 5px 10px;
    direction: rtl;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    @media screen and (max-width: 500px) {
      width: 350px;
    }
    @media screen and (max-width: 700px) {
      width: 500px;
    }
    @media screen and (max-width: 500px) {
      width: 385px;
    }
    @media screen and (max-width: 373px) {
      width: 100%;
    }
    button {
      position: absolute;
      left: 30px;
      width: 40px;
      height: 40px;
      color: #fff;
      padding: 0;
      i {
        vertical-align: middle;
        font-size: 40px;
      }
    }
  }
}
</style>
