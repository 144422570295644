<template>
  <main class="educational-classes" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Fourth_page').data[0])}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <!--:style="{background : gifts.known.length>0? '#FF7384' : '#B56576'}"-->
    <app-loading
    :style="{background : gifts.known.length>0? '#005d79' : '#005d79'}"
      class="sm-cont">
      <template>
        <div class="educational">
          <div class="header">
            <div class="title" style="margin-top:20px">
              <div>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.9999 16.8C20.7885 16.8 20.5799 16.7818 20.3713 16.7678C19.3143 18.3372 18.1789 20.3756 18.1789 24.136V25.2H11.1789V21H5.57891V14.9716L3.86671 14.2492L3.52931 14.1078L5.21071 11.4702L5.56211 10.92L5.63351 10.2718C6.02831 6.6794 8.65191 3.857 12.0343 3.0534C12.5397 1.9068 13.2565 0.875 14.1399 0.00840006C14.0853 0.00700006 14.0321 0 13.9789 0C8.21091 0 3.46491 4.361 2.85171 9.9652L0.201512 14.1218C-0.225488 14.7896 0.0531116 15.6814 0.782512 15.988L2.77891 16.8294V21C2.77891 22.547 4.03191 23.8 5.57891 23.8H8.37891V28H20.9789V24.136C20.9789 19.754 22.7541 18.5556 23.9959 16.3296C23.0509 16.6334 22.0457 16.8 20.9999 16.8Z" fill="white"/>
              <path d="M26.4619 8.064C26.5277 7.7182 26.5683 7.364 26.5683 7C26.5683 6.636 26.5277 6.2818 26.4605 5.936L27.5889 5.0974C27.7331 4.9896 27.7751 4.7908 27.6841 4.6354L26.3737 2.366C26.2841 2.2092 26.0909 2.1462 25.9257 2.2176L24.6153 2.7846C24.0847 2.3254 23.4715 1.967 22.7939 1.7318L22.6315 0.3136C22.6105 0.1344 22.4593 0 22.2787 0H19.6579C19.4773 0 19.3261 0.1344 19.3051 0.3136L19.1413 1.7304C18.4637 1.9656 17.8519 2.3226 17.3199 2.7832L16.0095 2.2162C15.8443 2.1448 15.6511 2.2078 15.5615 2.3646L14.2511 4.634C14.1615 4.7908 14.2021 4.9896 14.3463 5.096L15.4747 5.9346C15.4089 6.279 15.3669 6.6346 15.3669 6.9986C15.3669 7.3626 15.4075 7.7168 15.4747 8.0626L14.3463 8.9012C14.2021 9.009 14.1601 9.2078 14.2511 9.3632L15.5615 11.6326C15.6511 11.7894 15.8443 11.8524 16.0095 11.781L17.3199 11.214C17.8505 11.6732 18.4637 12.0316 19.1413 12.2668L19.3051 13.6836C19.3261 13.8656 19.4787 14 19.6579 14H22.2787C22.4593 14 22.6105 13.8656 22.6315 13.6864L22.7953 12.2696C23.4729 12.0344 24.0847 11.6774 24.6167 11.2168L25.9271 11.7838C26.0923 11.8552 26.2855 11.7908 26.3751 11.6354L27.6855 9.366C27.7751 9.2092 27.7345 9.0104 27.5903 8.904L26.4619 8.064ZM20.9683 9.45C19.6159 9.45 18.5183 8.3524 18.5183 7C18.5183 5.6462 19.6159 4.55 20.9683 4.55C22.3207 4.55 23.4183 5.6462 23.4183 7C23.4183 8.3524 22.3221 9.45 20.9683 9.45Z" fill="white"/>
              </svg>


              </div>
              <div>
              <span class="title-style">{{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Fourth_page').data[0])}}</span>
              </div>
            </div>
            <div class="title">
              <span>{{$ml.get('בחרו את כישורי הלמידה והמקצועות שאתם רוצים לחזק בהם את ילדיכם, וקבלו מאגר משחקים מתאימים')}}</span>
            </div>
            <div class="undertitle">

              <div :style="{direction: direction}" class="element">
                <!-- <div   class="round green"></div>
                <span :style="direction==='ltr'? 'text-align:left;margin-left:5px':''">{{$ml.get('מספר הפעמים ששוחקו משחקים בקטגוריה זו')}}</span>-->
              </div>
            </div>
          </div>
          <div class="gifts-wrapper">
            <app-loading v-if="!isLoaded">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </app-loading>


            <app-loading v-else>

            <div
              v-if="unknown.length>0"
              :style="{background:  '#a8b5b3'}"
              class="gifts-section">
              <div class="title">{{$ml.get('אין קטגוריה')}}</div>
              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-learninSkills'"
                  :key="i*2" v-for="(gift, i) in unknown"
                  :rec="true"
                  :gift="gift"
                  :type="'learningSkills'"  />
              </div>
            </div>
            <div class="cat-w">


            <div
              v-for="elem in test"
              v-if="elem.length>0"
              :style="{background:  $store.getters['educational-categories/getLearningSkillsColorById'](elem[0].id_category)}"
              class="gifts-section">
              <div class="title">{{$store.getters['educational-categories/geTitleLearningSkillsById'](elem[0].id_category)}}</div>

              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-learninSkills'"
                  :key="i*2" 
                  v-for="(gift, i) in elem"
                  :rec="true"
                  :gift="gift"
                  :type="'learningSkills'"  />
              </div>
            </div>
          </div>

            </app-loading>
          </div>

        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GiftElement from "@/components/parents/GiftElement";
  import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
  import {TeacherAPI} from "@/api/teacher.api";
  import store from "../../store";
  import StudentFromMainFirstTime from "@/components/modals/StudentFromMainFirstTime";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        testData:[1,2,3,4,5,6],
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        bifts:[
          {title: 'הפופולריים ביותר ',color:'#B56576',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#FF7384',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]}
        ],
        gifts: {
          popular: [],
          known: [],
        },
        test: [],
        unknown: [],
        isLoaded:false
      };
    },
    computed:{
      ...mapGetters({
        eduData:"learning-skills-page/data"
      }),
      sections(){
        return this.$store.getters['educational-categories/dataLearningSkills'];
      },
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    components: {
      SimpleHeader,
      SidebarToggler,
      GiftElement,
      GiftElementSkeleton
    },
    watch:{
    eduData:function(){
      if(this.sections!=null)
      {
      this.ready()
      }
    },
    sections:function(){
      if(this.eduData!=null)
      {
      this.ready()
      }
    }
    },
    methods: {
      ready(){
        this.unknown = this.eduData.filter(item=>{
          return item.id_category===null;
        });
        let sectionFiltered = Array();
        this.sections.forEach((sec)=>{
            // if(sec.valuebleExpireancePage==true)
            // {
              sectionFiltered.push(sec);
            // }
          })
        
        sectionFiltered = sectionFiltered.sort((a,b)=>a.order_index-b.order_index)

        let sortArray = [];
        sectionFiltered.forEach((sec, i) => {
          sortArray[i] = this.eduData.filter((item) => {
            return +item.id_category === sec.id;
          });
        });

        sortArray.forEach(element=>{
        element.sort((a, b) => (a.order_index != null ? a.order_index : Infinity) - (b.order_index != null ? b.order_index : Infinity))
        });
        this.test = sortArray;
        
       this.gifts.popular = this.eduData.filter(item=>{
          return item.section==='popular';
        });
        this.gifts.known = this.eduData.filter(item=>{
          return item.section==='known';
        });
        this.isLoaded = true;
      }
    },
    mounted() {
      if (!this.$store.getters['learning-skills-page/isLoaded']){
        this.$store.dispatch('learning-skills-page/updateData');
      }
      if (!this.$store.getters['educational-categories/isLoadedLearningSkills']){
        this.$store.dispatch('educational-categories/updateLearningSkillsData');
      }
      if(!this.$store.getters['tutorials/isUsed']('student-educational') && this.$route.params.student) {
        this.$store.commit('tutorials/setTutorial', 'student-educational');
        this.$modal.show(
          StudentFromMainFirstTime,
          {},
          {
            adaptive: true,
            height: "auto",
            width: 300
          }
        );
      }
    },
    async created() {
      window.fbq("track", "כישורי למידה");
      if (this.$store.getters['learning-skills-page/isLoaded']) {
       this.ready();
      }
      else
      {
        this.isLoaded = false;
      }

      // if (!this.$store.getters['teacher-educational/isLoaded']){
      //   this.$store.dispatch('teacher-educational/updateData');
      // }

    },
  };
</script>
<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  padding: 10px;
  position: absolute;
     height: 12px;
     left: 135px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:40% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.title-style{
  // font-family: 'Kartiv';
font-style: italic;
font-weight: 700;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.educational-classes {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;


    .educational {
      .header {
        background: #00465B;
        padding: 5px 30px 25px 30px;

        .title {
          margin: 0 auto 9px auto;
          width: 316px;
          font-family: 'App';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          color: #FFFFFF;
          @media screen and (max-width: 330px){
            width: unset;
          }
        }
        .undertitle {
          width: 310px;
          margin: 0 auto;
          @media screen and (max-width: 330px){
            width: unset;
          }
          .element {
            display: flex;
            align-items: center;
            text-align: right;
            color: #FFFFFF;
            margin: 2px 0;
            font-family: 'App';
            font-style: italic;
            font-weight: 200;
            font-size: 12px;
            line-height: 16px;
            text-align: right;

            color: #FFFFFF;
            .round {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              @media screen and (max-width: 330px){
                margin-left: 4px;
              }
            }

            .green {
              background: #8CC63F;
            }

            .yellow {
              background: #FBB03B;
            }
          }
        }
      }
      .gifts-wrapper{
        .gifts-section{
          background: #B56576;
          padding: 0 10px;
          .title{
            font-weight: bold;
            padding: 10px 0 10px;
            font-size: 21px;
            text-align: center;
            color: #FFFFFF;
          }
          .gifts{
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .gift-element{
              margin: 0 16px;
              @media screen and (max-width: 420px){
                margin: 0;
              }
            }
          }
        }
      }

    }
  }
}
</style>