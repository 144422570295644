<template>
    <div class="modal" style="text-align: center; padding: 20px; border-radius: 6px;">
     <span style="font-size: 22px;font-weight: bold;">{{ $ml.get('לא רשום') }}</span>
     <br>
     <span>{{ $ml.get('כדי להמשיך לשחק, נא להירשם!') }}</span>
     
      <app-btn
          style="width:85%; margin:auto;margin-top: 10px;"
          class="reg-c"
          @click="pushToReg"
          :loading="loadingSend"
        >
          {{ $ml.get("הַרשָׁמָה") }}</app-btn
        >

        <app-btn
          style="width:85%; margin:auto; margin-top: 10px;"
          class="reg-cd"
          @click="close"
          :loading="loadingSend"
        >
          {{ $ml.get("לְבַטֵל") }}</app-btn
        >
    </div>
  </template>
  
  <script>
  import { SubscribeAPI,AuthAPI,UserAPI } from "@/api";
  export default {
    data(){
        return{
            email:null,
            emailError:false,
            emailMsg:'',
            timerEmail:null,
            loadingSend:false,
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },

        pushToReg(){
            this.$emit('close');
            this.$router.push('/auth/reg');
        }

    },
  };
  </script>
  
  <style lang="scss" scoped>
  .reg-c{
    background: #FDC671 !important;
  }
  .email-input {
  padding: 1px 17px 0px 12px;
  margin: auto;
  margin-top: 27px;
  margin-bottom: 10px;
  height: 60px;
  width: 300px;
  background: #fff;
  border-radius: 4px;
}
.email-alert {
  position: relative;
  font-family: "App";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  color: #005d7a;

  svg {
    position: absolute;
    top: -2px;
    right: 3px;
  }
}
  .modal {
    background: #ddf0f5;
  width: 100%;
  height: 100%;
  // padding: 10px;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
    box-shadow: rgba($color: #000000, $alpha: 0.2);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
    }
  }
}
  </style>
  