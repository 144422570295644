import { render, staticRenderFns } from "./MainSection.vue?vue&type=template&id=5efdb423&scoped=true&%3Akey=%24ml.current&"
import script from "./MainSection.vue?vue&type=script&lang=js&"
export * from "./MainSection.vue?vue&type=script&lang=js&"
import style0 from "./MainSection.vue?vue&type=style&index=0&id=5efdb423&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efdb423",
  null
  
)

export default component.exports