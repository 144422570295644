<template>
  <main class="video-education">
    <simple-header inverse></simple-header>
    <div class="video-education-frame">
      <iframe
        src="https://www.youtube.com/embed/bLHMSHNBF9g"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="sm-cont">
      <p class="video-education-text">
        <span class="fugaz">Playlist</span> מספקת מגוון רעיונות למשחקים יצירתיים
        ופשוטים שאינם דורשים ציוד, שמאפשרים מישחוק (gamification) יצירתי של חיי
        העבודה, המשפחה והחברה.
      </p>
      <div class="video-education-spacer"></div>
      <div class="video-education-button">
        <button
          @click="$router.push('/playlist/options/adult')"
          class="theme-button-outline"
        >
          התחל
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import SimpleHeader from "./../../components/SimpleHeader.vue";
export default {
  components: {
    SimpleHeader
  }
};
</script>

<style lang="scss" scoped>
main {
}
</style>
