<template>
  <main class="educational-classes" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Nine_page').data[0])}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <!--:style="{background : gifts.known.length>0? '#FF7384' : '#B56576'}"-->
    <app-loading
    :style="{background : gifts.known.length>0? '#005d79' : '#005d79'}"
      class="sm-cont">
      <template>
        <div class="educational">
          <div class="header">
            <div class="title" style="margin-top:20px">
              <div>
                <svg width="40" height="40" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.75 0C2.50912 0 1.5 1.00912 1.5 2.25V4.25391C0.627 4.56328 0 5.39738 0 6.375V9.375C0 10.4825 0.604781 11.4495 1.5 11.9692V12.375C1.5 12.582 1.668 12.75 1.875 12.75H3.75C3.957 12.75 4.125 12.582 4.125 12.375H13.875C13.875 12.582 14.043 12.75 14.25 12.75H16.125C16.332 12.75 16.5 12.582 16.5 12.375V11.9692C17.3952 11.4495 18 10.4825 18 9.375V6.375C18 5.39738 17.373 4.56328 16.5 4.25391V2.25C16.5 1.00912 15.4909 0 14.25 0H3.75ZM3.75 1.5H14.25C14.664 1.5 15 1.83562 15 2.25V4.25317C14.1269 4.56267 13.5 5.39719 13.5 6.375V7.6875C13.5 7.791 13.416 7.875 13.3125 7.875H4.6875C4.584 7.875 4.5 7.791 4.5 7.6875V6.375C4.5 5.39719 3.87313 4.56247 3 4.25317V2.25C3 1.83562 3.336 1.5 3.75 1.5ZM15.7449 5.62427H15.7463C15.7476 5.62427 15.7487 5.625 15.75 5.625C16.164 5.625 16.5 5.96063 16.5 6.375V9.375C16.5 10.2034 15.8284 10.875 15 10.875H3C2.17162 10.875 1.5 10.2034 1.5 9.375V6.375C1.5 5.96063 1.836 5.625 2.25 5.625C2.25124 5.625 2.25242 5.62501 2.25366 5.625C2.66654 5.62275 3 5.96212 3 6.375V8.625C3 9.03938 3.33562 9.375 3.75 9.375H14.25C14.6644 9.375 15 9.03938 15 8.625V6.375C15 5.96262 15.3327 5.62321 15.7449 5.62427Z" fill="white"/>
                  </svg>


              </div>
              <div>
              <span class="title-style">{{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Nine_page').data[0])}}</span>
              </div>
            </div>
            <div class="title">
              <span>{{$ml.get('בחרו את כישורי הלמידה והמקצועות שאתם רוצים לחזק בהם את ילדיכם, וקבלו מאגר משחקים מתאימים')}}</span>
            </div>
            <div class="undertitle">

              <div :style="{direction: direction}" class="element">
                <!-- <div   class="round green"></div>
                <span :style="direction==='ltr'? 'text-align:left;margin-left:5px':''">{{$ml.get('מספר הפעמים ששוחקו משחקים בקטגוריה זו')}}</span>-->
              </div>
            </div>
          </div>
          <div class="gifts-wrapper">
            <app-loading v-if="!isLoaded">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </app-loading>


            <app-loading v-else>

            <div
              v-if="unknown.length>0"
              :style="{background:  '#a8b5b3'}"
              class="gifts-section">
              <div class="title">{{$ml.get('אין קטגוריה')}}</div>
              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-learninSkills'"
                  :key="i*2" v-for="(gift, i) in unknown"
                  :rec="true"
                  :gift="gift"
                  :type="'learningSkills'"  />
              </div>
            </div>
            <div class="cat-w">


            <div
              v-for="elem in test"
              v-if="elem.length>0"
              :style="{background:  $store.getters['educational-categories/getNinePageColorById'](elem[0].id_category)}"
              class="gifts-section">
              <div class="title">{{$store.getters['educational-categories/geTitleNinePageById'](elem[0].id_category)}}</div>

              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-ninePage'"
                  :key="i*2" 
                  v-for="(gift, i) in elem"
                  :rec="true"
                  :gift="gift"
                  :type="'ninePage'"  />
              </div>
            </div>
          </div>

            </app-loading>
          </div>

        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GiftElement from "@/components/parents/GiftElement";
  import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
  import {TeacherAPI} from "@/api/teacher.api";
  import store from "../../store";
  import StudentFromMainFirstTime from "@/components/modals/StudentFromMainFirstTime";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        testData:[1,2,3,4,5,6],
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        bifts:[
          {title: 'הפופולריים ביותר ',color:'#B56576',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#FF7384',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]}
        ],
        gifts: {
          popular: [],
          known: [],
        },
        test: [],
        unknown: [],
        isLoaded:false
      };
    },
    computed:{
      ...mapGetters({
        eduData:"nine-page/data"
      }),
      sections(){
        return this.$store.getters['educational-categories/dataNinePage'];
      },
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    components: {
      SimpleHeader,
      SidebarToggler,
      GiftElement,
      GiftElementSkeleton
    },
    watch:{
    eduData:function(){
      if(this.sections!=null)
      {
      this.ready()
      }
    },
    sections:function(){
      if(this.eduData!=null)
      {
      this.ready()
      }
    }
    },
    methods: {
      ready(){
        this.unknown = this.eduData.filter(item=>{
          return item.id_category===null;
        });
        let sectionFiltered = Array();
        this.sections.forEach((sec)=>{
            // if(sec.valuebleExpireancePage==true)
            // {
              sectionFiltered.push(sec);
            // }
          })
        
        sectionFiltered = sectionFiltered.sort((a,b)=>a.order_index-b.order_index)

        let sortArray = [];
        sectionFiltered.forEach((sec, i) => {
          sortArray[i] = this.eduData.filter((item) => {
            return +item.id_category === sec.id;
          });
        });

        sortArray.forEach(element=>{
        element.sort((a, b) => (a.order_index != null ? a.order_index : Infinity) - (b.order_index != null ? b.order_index : Infinity))
        });
        this.test = sortArray;
        
       this.gifts.popular = this.eduData.filter(item=>{
          return item.section==='popular';
        });
        this.gifts.known = this.eduData.filter(item=>{
          return item.section==='known';
        });
        this.isLoaded = true;
      }
    },
    mounted() {
      if (!this.$store.getters['nine-page/isLoaded']){
        this.$store.dispatch('nine-page/updateData');
      }
      if (!this.$store.getters['educational-categories/isLoadedNinePage']){
        this.$store.dispatch('educational-categories/updateNinePageData');
      }
      if(!this.$store.getters['tutorials/isUsed']('student-educational') && this.$route.params.student) {
        this.$store.commit('tutorials/setTutorial', 'student-educational');
        this.$modal.show(
          StudentFromMainFirstTime,
          {},
          {
            adaptive: true,
            height: "auto",
            width: 300
          }
        );
      }
    },
    async created() {
      window.fbq("track", "מטפלים");
      if (this.$store.getters['nine-page/isLoaded']) {
       this.ready();
      }
      else
      {
        this.isLoaded = false;
      }

      // if (!this.$store.getters['teacher-educational/isLoaded']){
      //   this.$store.dispatch('teacher-educational/updateData');
      // }

    },
  };
</script>
<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  padding: 10px;
  position: absolute;
     height: 12px;
     left: 135px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:40% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.title-style{
  // font-family: 'Kartiv';
font-style: italic;
font-weight: 700;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.educational-classes {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;


    .educational {
      .header {
        background: #00465B;
        padding: 5px 30px 25px 30px;

        .title {
          margin: 0 auto 9px auto;
          width: 316px;
          font-family: 'App';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          color: #FFFFFF;
          @media screen and (max-width: 330px){
            width: unset;
          }
        }
        .undertitle {
          width: 310px;
          margin: 0 auto;
          @media screen and (max-width: 330px){
            width: unset;
          }
          .element {
            display: flex;
            align-items: center;
            text-align: right;
            color: #FFFFFF;
            margin: 2px 0;
            font-family: 'App';
            font-style: italic;
            font-weight: 200;
            font-size: 12px;
            line-height: 16px;
            text-align: right;

            color: #FFFFFF;
            .round {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              @media screen and (max-width: 330px){
                margin-left: 4px;
              }
            }

            .green {
              background: #8CC63F;
            }

            .yellow {
              background: #FBB03B;
            }
          }
        }
      }
      .gifts-wrapper{
        .gifts-section{
          background: #B56576;
          padding: 0 10px;
          .title{
            font-weight: bold;
            padding: 10px 0 10px;
            font-size: 21px;
            text-align: center;
            color: #FFFFFF;
          }
          .gifts{
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .gift-element{
              margin: 0 16px;
              @media screen and (max-width: 420px){
                margin: 0;
              }
            }
          }
        }
      }

    }
  }
}
</style>