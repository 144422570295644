<template>
<div class="playlist-element">
  <div :style="userPosition? '' : 'opacity:0;pointer-events:none'" class="handle">
    <svg class="icon " width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="4" height="4" fill="#C4C4C4"/>
      <rect y="6" width="4" height="4" fill="#C4C4C4"/>
      <rect x="6" width="4" height="4" fill="#C4C4C4"/>
      <rect x="6" y="6" width="4" height="4" fill="#C4C4C4"/>
    </svg>
  </div>

  <div @click="go" style="cursor: pointer" class="game-i">
    <img :src="item.img" alt="">
    <div class="name">{{translated.data.name}}</div>
  </div>
  <div class="l-options">
    <select
      v-if="isDirector"
      style="direction: rtl"
      class="select-filter"
      :value="value"
      @input="setFavorite($event.target.value)" >
      <option
        @change="setFavorite(option)"
        class="opt-v"
        :key="i*3"
        v-for="(option, i) in filter">
        {{option.name}}</option>
    </select>
    <button @click="deleteGame" class="close-b">
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1L1 6.99999" stroke="#00465B" stroke-miterlimit="10"/>
        <path d="M1 1L7 6.99999" stroke="#00465B" stroke-miterlimit="10"/>
      </svg>
    </button>

  </div>


</div>
</template>

<script>
  import {DirectorAPI} from "@/api";

  export default {
    props: {
      item: {},
      isDirector: {
        default: false,
      },
    },
    data() {
      return {
        value: 'כולם',
        filter: [
          {name: 'כולם', value: 'all'},
          {name: 'א\'-ג\'', value: 'child'},
          {name: 'ד\'-ו\'', value: 'adult'},
        ]
      }
    },
    created() {
      this.value = this.getValue(this.item.class_type);

    },
    computed: {
      userPosition() {
        return (this.user.position==='director' || this.user.position==='teacher');
      },
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.item.translation.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en" && item.data.name!==''
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data'] && item.data.name!==''
            }
          })
        }
      },
      user() {
        return this.$store.getters['user/data'];
      },
    },
    methods: {
      go() {
        this.$router.push({
          name: "playlist",
          params: {
            id_playlist: this.item.id_playlist
          }
        });
      },
      setFavorite(data) {
        DirectorAPI.setPlaylistFavorive({class_type: this.getType(data), playlist_id: this.item.id_playlist}).then();
      },
      getType(val) {
        if (val === 'ד\'-ו\'') {
          return 'adult';
        } else if (val === 'א\'-ג\'') {
          return 'child'
        } else {
          return 'all'
        }
      },
      getValue(val) {
        return this.filter.filter(item => val === item.value)[0].name;
      },
      deleteGame() {
        this.$store.commit('modals/deleteLiked/open', {title: 'playlist', id: this.item.id_playlist})
        // axios.delete("playlist/" + this.item.id_playlist + "/dislike").then((res)=>{
        //   this.$emit('update', '');
        //
        // });
      }
    }
  };
</script>

<style lang="scss" scoped>
.playlist-element{
  padding: 7px 20px;
  height: 70px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .l-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    .select-filter{
      margin-left: 15px;
      border: none;
    }
  }
  .icon{
    margin-left: 10px;
  }
  .game-i{
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img{
       width: 51px;
       height: 55px;
     }
    .name{
      margin-left: 20px;
      font-family: Cool;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 16px;

       margin-right: 20px;
      color: #00465B;
    }
  }

  .close-b{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #F2F2F2;
    border-radius: 15px;
  }
}
</style>
