<template>
  <div class="rate">

    <div :style="{direction:direction}" class="title">{{$ml.get('עד כמה השיעור היה מוצלח')}}?</div>
      <div :style="{direction:direction}" class="numbers">
        <div class="side">{{$ml.get('קצת')}}</div>
        <button
          class="number"
          :class="{active: value===item}"
          @click="setValue(item)"
          :key="i*4"
          v-for="(item,i) in 5">{{item}}</button>
        <div class="side">{{$ml.get('מאוד')}}</div>

      </div>
  </div>
</template>
<script>

  export default {
    props:{
      value:{

      }
    },
    components:{

    },
    computed:{
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    methods:{
      setValue(item){
        this.$emit('input', item);
      }
    },
    data() {
      return {
        flag: false,
        checkbox: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
 .rate{
   padding: 30px 0;
   background: #06364B;
   border-radius: 4px;
   .title{
     font-size: 16px;
     line-height: 23px;
     text-align: center;
     color: #FFFFFF;
   }
   .numbers{
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-direction: row;
     padding: 0 25px;
     margin: 30px auto 30px auto;
    .side{
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
    }
     .number{
       border-radius: 50%;
       width: 30px;
       height: 30px;
       border: 1px solid #FFFFFF;
       font-weight: normal;
       font-size: 18px;
       line-height: 25px;
       text-align: center;
       color: #FFFFFF;
       &.active{
         background: #8CC63F;
       }
     }
   }
 }
</style>

