import App from "./App";
import Vue from "vue";
import VueModal from "vue-js-modal";
import VueFilterDateFormat from "vue-filter-date-format";
import VueMask from "v-mask";
import VueTelInput from "vue-tel-input";
import VueClickOutside from 'v-click-outside'
import UI from "@/components/ui";
import Notifications from 'vue-notification';
import VueCarousel from 'vue-carousel';
import vuetify from "./plugins/vuetify";
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2';
import { Buffer } from 'buffer';

import "./styles/main.scss";
import "./config/config";
import "./config/prefs";
import "./assets/js/jquery.min.js";
import 'vue-tel-input/dist/vue-tel-input.css';
window._ = require('lodash');
// import VueAnalytics from "vue-analytics";

// YELLOW BRICK WATERMARK
console.log('%c Made by YELLOW BRICK TECH. (UK) 🧱', 'padding: 10px; background: #f5d448; color: #333; font-weight: bold; border-left: 3px solid #333; border-radius: 3px;');
import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '74713164387-8s4e4uu8n5l3r6do4q9e4p1agiibhllr.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
  fetch_basic_profile: true,
  plugin_name:'VueJSGoogleAuth'
}

Vue.use(fpjsPlugin, {
  loadOptions: {
    apiKey: "hTKI6SKGWYrafcdAA1OH"
  },
});

Vue.use(Buffer);
Vue.use(require('vue-moment'));
Vue.use(GAuth, gauthOption)
Vue.use(VueModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueFilterDateFormat);
Vue.use(VueMask);
Vue.use(UI);
Vue.use(VueClickOutside);
Vue.use(VueTelInput);
Vue.use(Notifications)
Vue.use(VueCarousel);

import store from './store';
import router from './router/'
import './lang/lang';

window.app = new Vue({
  router: router,
  store: store,
  vuetify,
  render: h => h(App),
  data: {},
  methods: {
  }
}).$mount("#app");
