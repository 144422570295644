<template>
  <div class="like-btn-w">
    <LikePopup v-if="directorFlag" :item="item" @liked="setted" />
    <!-- <button v-if="user.position==='director'" @click="liked" class="like-button">
      <img style="margin-left: 1.05px;" v-if="$store.getters['shootlist/currentItem'].liked" width="22" height="20" src="./liked.png"/>
      <img style="margin-left: 1.05px;" v-else width="22" height="20" src="./non-liked.png"/>
    </button>
    <button v-else @click="$emit('click')" class="like-button">
      <img style="margin-left: 1.05px;" v-if="$store.getters['shootlist/currentItem'].liked" width="22" height="20" src="./liked.png"/>
      <img style="margin-left: 1.05px;" v-else width="22" height="20" src="./non-liked.png"/>
    </button> -->
    <app-btn class="likeBtn" v-if="user.position==='director'" @click="liked">
            <div style="width: 100%; position: relative;" >
              <span style="margin-right: 0px;">{{ $ml.get("שמור") }}</span>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-if="item.liked" width="16" height="14" src="./liked.png"/>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-else width="16" height="14" src="./non-liked.png"/>
            </div>
    </app-btn>
    <app-btn v-else @click="$emit('click')" class="likeBtn">
            <div style="width: 100%; position: relative;" >
              <span style="margin-right: 0px;">{{ $ml.get("שמור") }}</span>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-if="item.liked" width="16" height="14" src="./liked.png"/>
              <img alt="" style="margin-left: 1.05px; position: absolute; top: 4px; right: -28px;" v-else width="16" height="14" src="./non-liked.png"/>
            </div>
    </app-btn>
  </div>

</template>

<script>
import LikePopup from "./LikePopup";
export default {
  props: {
    item: {}
  },
  data(){
    return{
      directorFlag: false,
    }
  },
  methods:{
    setted(){
      this.directorFlag = false;
    },
    liked(){
      if (!this.item.liked) {
        axios.put(
            "/shootlist/" + this.item.id_shootlist + "/activity/lik"
        );
        this.directorFlag =true;
      }
      this.item.liked = !this.item.liked;
      axios.put("/shootlist/" + this.item.id_shootlist + "/like");
    }
  },
  computed:{
    user(){
      return this.$store.getters['user/data'];
    }
  },
  components:{
    LikePopup
  },
}
</script>

<style lang="scss" scoped>
//
.likeBtn{
  background: #FBB03B !important;
  width: 78px;
  height: 40px;
  border-radius: 20px;
  div span{
    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    margin-right: -5px !important;
    color: #06364B;
  }
}
.like-button {
  width: 37px;
  height: 37px;
  border: 1px solid #fff;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke: 1px #fff;
  }
}

</style>