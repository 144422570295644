<template>
  <div class="modal">
    <button class="modal-close" @click="closeThis">
      <svg @click="closeThis" width="329pt" height="329pt" viewBox="0 0 329.27 329" xmlns="http://www.w3.org/2000/svg">
        <path d="m194.8 164.77 128.21-128.21c8.3438-8.3398 8.3438-21.824 0-30.164-8.3398-8.3398-21.824-8.3398-30.164 0l-128.21 128.21-128.21-128.21c-8.3438-8.3398-21.824-8.3398-30.164 0-8.3438 8.3398-8.3438 21.824 0 30.164l128.21 128.21-128.21 128.21c-8.3438 8.3398-8.3438 21.824 0 30.164 4.1562 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25l128.21-128.21 128.21 128.21c4.1602 4.1602 9.6211 6.25 15.082 6.25 5.4609 0 10.922-2.0898 15.082-6.25 8.3438-8.3398 8.3438-21.824 0-30.164z"/>
      </svg>
    </button>
    <div style="width: 100%; padding-top: 80px; position: relative;">
      <span style="position: absolute; font-family: 'App';font-style: normal; width:250px; font-weight: bold;font-size: 16px;line-height: 19px;text-align: right;color: #000000; left:0; right:0; top: 20px;margin: auto;">{{ $ml.get('הזן את הדואר האלקטרוני של הנמען') }}</span>
      <app-text-input
        @input="inputEmail"
        style="margin:auto; width:250px; margin-bottom: 20px;"
        type="text"
        v-model="email"
        label="אימייל"
      />
      <div v-if="emailVerified==false" style="height:10px !important;font-family: App;font-style: normal;font-weight: bold;font-size: 16px;width: 250px;line-height: 19px;text-align: right;color: rgb(0, 0, 0);left: 0px;right: 0px;top: 22px;margin: auto;">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
          style="position: absolute;right: 54px;top: 130px;"
        >
          <g id="surface1">
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(84.313725%, 15.686275%, 15.686275%);
                fill-opacity: 1;
              "
              d="M 14 7 C 14 10.867188 10.867188 14 7 14 C 3.132812 14 0 10.867188 0 7 C 0 3.132812 3.132812 0 7 0 C 10.867188 0 14 3.132812 14 7 Z M 14 7 "
            />
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(90.196078%, 90.196078%, 90.196078%);
                fill-opacity: 1;
              "
              d="M 6.34375 10.9375 L 7.65625 10.9375 L 7.65625 9.625 L 6.34375 9.625 Z M 6.34375 2.625 L 6.34375 8.3125 L 7.65625 8.3125 L 7.65625 2.625 Z M 6.34375 2.625 "
            />
          </g>
        </svg>
        <span style="position: absolute;top: 127px;right: 71px; font-weight: 400 !important;">{{
          $ml.get("האימייל שגוי")
        }}</span>
      </div>
      <span></span>
      <app-btn class="reg-b" @click="closeModal" :disabled="emailVerified==false || emailVerified==null?true:false">
        אשר דואר
      </app-btn>
    </div>
  </div>
</template>

<script>
import { SubscribeAPI } from '@/api';
export default {
  data() {
    return {
      interval: null,
      email:"",
      emailVerified:null,
      timerEmail:null
    };
  },
  methods:{
    inputEmail(val){
      this.email = val;
      this.validateEmail();
    },
    closeThis(){
      this.$emit('close');
    },
    closeModal()
    {
      if(this.$store.getters['user/getEmail']==this.email)
      {
        this.$notify({
              title: "שְׁגִיאָה",
              text: "הקופון כבר נשלח למייל זה",
              type: "error",
              duration: 5000,
            });
      }
      else
      {
        this.$store.commit('user/setEmail',this.email);
        this.$emit('close');
      }
    },
    validateEmail() {
      this.emailVerified = null;
      clearTimeout(this.timerEmail);
      this.timerEmail = setTimeout(() => {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,30})+$/.test(this.email)
        ) {
          this.emailVerified = true;
          // this.msg['email'] = 'Please enter valid email';
        } else {
          this.emailVerified = false;
        }
      }, 1000);
    },
  },
  created() {
    
  },
  destroyed() {
  }
};
</script>

<style lang="scss" scoped>
 .reg-b{
    width: 250px;
    height: 36px;

    background: #FBB03B !important;
    border-radius: 18px;
    margin: auto;
    left: 0;
    right: 0;
    &.disabled {
          background: #c4c4c4 !important;
          color: #4d4d4d;
        }
  }
.modal-close{
  z-index: 99999;
}
.modal {
  width: 100%;
  height: 100%;
  // padding: 10px;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #fff;
    box-shadow: rgba($color: #000000, $alpha: 0.2);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
    }
  }
}
</style>
