import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterMultiguard from "vue-router-multiguard";

import Gifts from '@/views/playlist/Gifts';
import Options from '@/views/playlist/Options';
import Playlist from '@/views/playlist/Playlist';

import { Middleware } from "./middleware";

const PlaylistRouter = [
  {
    path: "/playlist",
    component: Playlist,
    name: 'playlist',
  },
  {
    path: "/playlist/options",
    component: Options,
    name: 'playlist-options',
  },
  {
    path: "/playlist/gifts",
    component: Gifts,
    name: 'playlist-gifts',
  },
  {
    path: "/playlist/:id_playlist",
    component: Playlist,
    name: 'targeted-playlist',
  },
].map(n => {
  return {
    ...n,
    beforeEnter: VueRouterMultiguard([
      Middleware.isLogged,
    ]),
  }
});

export { PlaylistRouter }
