<template>
  <main dir="rtl">

    <SimpleHeader route="/funkit">
      <template v-slot:title>
        <span>
         {{$ml.get('כתובת למשלוח')}}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>

    <form class="sm-cont" @submit="send">
      <div class="app-inputs">
        <div class="app-section">

          <p>
            * {{$ml.get("שם מלא")}}
          </p>
          <input required v-model="full_name" type="text" class="theme-input" />
        </div>
        <div class="app-section">

          <p>
            * {{$ml.get("עיר")}}
          </p>
          <input required v-model="city" type="text" class="theme-input" />
        </div>
        <div class="row">
          <div style="width: 237px;" class="app-section">

            <p>
              * {{$ml.get("רחוב")}}
            </p>
            <input required v-model="street" type="text" class="theme-input" />
          </div>
          <div style="width: 73px;" class="app-section">
            <p>
              *  {{$ml.get("מספר")}}
            </p>
            <input required v-model="num" type="text" class="theme-input" />
          </div>
        </div>
        <div class="row">
          <div style="width: 155px;" class="app-section">
            <p>
              *  {{$ml.get("מיקוד")}}
            </p>
            <input required v-model="postal" type="text" class="theme-input" />
          </div>
          <div style="width: 155px;" class="app-section">

            <p>
              {{$ml.get("תא דואר")}}
            </p>
            <input   v-model="mail" type="text" class="theme-input" />
          </div>

        </div>

        <div class="app-section">

          <p>
            {{$ml.get("הערות")}}
          </p>
          <textarea v-model="comment" class="theme-input"></textarea>
        </div>
      </div>
      <div class="end">
        <button  @click="send">
        <btn
          :loading="loading"
          class="theme-button-outline">
          {{$ml.get("שליחה")}}
        </btn>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import FunKitBought from "@/components/modals/FunKitBought.vue";
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Payment from "@/components/modals/Payment.vue";
import Btn from "@/components/ui/app-btn/AppBtn";
export default {
  data() {
    return {
      loading: false,
      city: "",
      street: "",
      full_name: '',
      num: "",
      mail: "",
      postal: "",
      comment: ""
    };
  },
  computed:{
    getData(){
      return this.$route.params.type==='solo' ? this.$store.getters['funkit/item'] : this.$store.getters['funkit/data']
    }
  },
  methods: {
    send(e) {
      e.preventDefault();
      var form = document.querySelector('form');
       this.loading = true;
        if (this.full_name.trim()==='' || this.city.trim()==='' || this.street.trim()==='' || this.num.trim()===''  || this.postal.trim()===''){
          form.reportValidity();
          this.loading = false;
          return
        }


      axios
        .post("/funkit/several_buy", {
          full_name: this.full_name,
          city: this.city,
          street: this.street,
          num: this.num,
          mail: this.mail!=='' ? this.mail : 'unfillled',
          postal: this.postal,
          comment: this.comment,
          items: this.getData,
        })
        .then(res => {
          this.loading = false;
          this.$modal.show(
            Payment,
            {
              url: res.data.url,
              id_transaction: res.data.id_transaction,
              ended: () => {
                this.$modal.show(
                  FunKitBought,
                  {},
                  {
                    adaptive: true,
                    width: 300,
                    height: "auto"
                  }
                );
                this.$router.push("/funkit");
              }
            },
            {
              adaptive: true,
              height: "90%",
              width: "90%"
            }
          );
        }).catch(()=>{
        this.loading = false;
      });
    }
  },
  components: {
    Btn,
    SimpleHeader,
    SidebarToggler,
  },
  created() {

  }
};
</script>

<style lang="scss" scoped>
main {
  min-height: 700px;
  .end {
    margin-top: 58px ;
    padding: 20px 0 40px 0;
    width: 100%;

    text-align: center;
    background: white;

    .theme-button-outline {
      background: #FDC671;
      border-radius: 18px;
      width: 180px;
      height: 36px;
      font-size: 15px;
      line-height: 22px;
      /* identical to box height */
      margin: 0 auto;
      text-align: center;

      color: #FFFFFF;
    }


  }
}
  .app-inputs{
    .row{
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }
</style>
