export default {
  share: {
    funzone: `היי, אני רוצה להזמין אותך לאירוע FUN ZONE שגם אני אהיה בו. זה מפגש חברתי מדליק עם משחקים חדשים ומיוחדים. פרטים מתי ואיפה באפליקציה PlayList בכפתור הקהילה שלי: \n ${process.env['VUE_APP_WEB_URL']}`,
    shootlist(id, type) {
      var web_url = process.env['VUE_APP_WEB_URL'] + "/#/" + type + "/" + id;
      return `היי, אני רוצה לשתף אותך במשחק חברתי מדהים שהלהיב אותי. זה מתוך אפליקצית PlayList שמציגה מאות שאלות לשיחה זורמת וכיפית, ורעיונות למשחקים מחוץ למסך לילדים ולמבוגרים:\n ${web_url}`;
    },
    playlist(id, type) {
      var web_url = process.env['VUE_APP_WEB_URL'] + "/#/" + type + "/" + id;
      return ` היי, אני רוצה לשתף אותך באפליקציה של משחקים מחוץ למסך, שבעיני היא מדהימה ממש. יש שם מאות רעיונות למשחקים חברתיים ללא ציוד, לילדים ולמבוגרים. \n   \n ${web_url}`;
    }
  }
};
