<template lang="html">
  <transition name="fade-anim">
    <modal v-if="$store.getters['modals/teacher-playlist-first/isOpened']" @close="close">
      <div class="th-modal container">
        <div class="text">
          {{$ml.get('כל המשחקים שיסומנו אצלך שיחקתי')}}
          {{$ml.get('יתועדו בדו"ח הכיתתי')}}
        </div>
        <app-btn   @click="close" class="save-b">{{$ml.get('הבנתי')}}</app-btn>

      </div>
    </modal>
  </transition>
</template>

<script>
import Modal from './Modal'
import ModalClose from './ModalClose'

export default {
  components: {
    Modal,
    ModalClose,
  },
  methods: {
    close() {
      this.$store.commit('modals/teacher-playlist-first/close');
    }
  }
}
</script>

<style lang="scss" scoped>

.modal {
  &.fade-anim {
    &-enter {
      .container {
        transform: translateY(-50px);
      }
    }
    &-enter-to {
      .container {
        transform: translateY(0);
      }
    }

    &-leave {
      .container {
        transform: translateY(0);
      }
    }
    &-leave-to {
      .container {
        transform: translateY(-50px);
      }
    }

    &-leave-active,
    &-enter-active {
      .container {
        transition: transform 0.3s ease;
      }
    }
  }
}

.container {
  max-width: 325px;
  width: 310px;

  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;
  border-radius: 10px;
  @media screen and (max-width: 360px){
    width: 260px;
  }
  .title {
    padding: 50px 35px 0 35px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #000000;
  }

  .text{
    width: 206px;
    margin: 0 auto;
    padding-top: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #00465B;
  }
  .save-b{
    margin: 20px auto;
    width: 180px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
  }
}

</style>
