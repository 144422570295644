<template>
  <div class="players-names">
      <div class="player">

        <!-- <div class="player_count">  <span>{{$ml.get("שם משתתף")}}</span> {{counter+1}}  </div> -->
        <!-- <input class="input-field" :value="value" @input="$emit('input', $event.target.value)" type="text"> -->
        <input
          class="input-shootlist"
          :placeholder="$ml.get('שם משתתף')+' '+(counter+1)"
          :value="value"
          @input="$emit('input', $event.target.value)"
          :class="value!=null&&value!=''?'active':''"
          />
      </div>
  </div>
</template>
<script>
export default {
  name: "PlayersNames",
  props:{
    counter: {},
    value : {
      default: '',
    },
  }
}
</script>

<style scoped lang="scss">
.input-shootlist{
  padding: 17px;
  width: 215px;
  height: 40px;

  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 4px;

  &.active{
    border: 1px solid #06364B !important;
    color: #06364B !important;
  }
}
.input-shootlist:focus{
  border: 1px solid #FDC671 !important;

}
.input-shootlist::-webkit-input-placeholder {
    color: #999;
}
.input-shootlist:focus::-webkit-input-placeholder {
    color: transparent;
}
/* Firefox < 19 */
.input-shootlist:-moz-placeholder {
    color: #999;
}
.input-shootlist:focus:-moz-placeholder {
    color: transparent;
}
/* Firefox > 19 */
.input-shootlist::-moz-placeholder {
    color: #999;
}
.input-shootlist:focus::-moz-placeholder {
    color: transparent;
}

/* Internet Explorer 10 */
.input-shootlist:-ms-input-placeholder {
    color: #999;
}
.input-shootlist:focus:-ms-input-placeholder {
    color: transparent;
}
.players-names{
  .player{
    margin: 10px 0;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .input-field{
      border: none;
      outline:none;
      border-bottom: 1px solid #000000;
      font-size: 14px;
    }
    .player_count{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 8px;
      margin-left: 10px;
 
      text-align: right;

      color: #005D7A;
    }
  }
}




</style>