<template>
  <div
    :class="[ value==='' && important ? 'active' : 'unactive']"
    class="input-register">
    <input :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      :style="{ 'border-bottom': '1px solid '+border+'' , color : textColor}"
      class="input-reg"
      :type="type"
      :name="name"
      autocomplete="new-password">
  </div>
</template>

<script>
export default {
  name: "InputRegister",
  props:{
    value: {

    },
    border:{
      default: '#FFFFFF'
    },
    type:{
      default: 'text',
    },
    textColor:{
      default: '#FFFFFF'
    },
    placeholder:{
    default: '',
    },
    important:{
      default: false,
    },
    name:{
      
    }
  }
}
</script>

<style scoped lang="scss">
:active, :hover, :focus {
 outline: 0;
 outline-offset: 0;
}
.input-register{
  width: 250px;
  position: relative;
  .input-reg{
    width: 100%;
    outline: none;
    border: none;
    background: transparent;


    font-size: 17px;
    @media screen and (max-width: 600px){
      font-size: 15px;
    }
    line-height: 20px;
    text-align: right;
    color: #FFFFFF;
  }
  &.active{
    .input-reg {
      padding-right: 11px;
    }
    &:after{
      position: absolute;
      content: '*';
      color: #ff5c5c;
      right: 0;
    }
  }
}
</style>