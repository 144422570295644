<template>
  <div  class="main-school">
    <div class="logo">
      <img  :src="school ? school.logo : ''" alt="">
    </div>
    <div class="info">
      <div :style="{direction:direction}" class="first">
        <div class="f-el">{{ $ml.get('שלום')}}</div>
        <div class="f-el">{{user.name}}</div>
        <!-- <div class="f-el">{{user.surname}}</div> -->
      </div>
      <!-- <div :style="{direction:direction}"  class="second">
      
        <div class="f-el">{{translation ? translation : ''}}</div>
        <div class="f-el"> {{((this.user.class ? this.user.class : '')+ (this.user.class_number ? this.user.class_number+', ' : '')  ) }}</div>
        <div class="f-el">{{translatedSchool.name ? translatedSchool.name : school.translation[0].data.name}} {{school.city? ',' : ''}}</div>
        <div class="f-el">{{translatedSchool.city ? translatedSchool.city : ''}}</div>
      </div> -->
    </div>

  </div>
</template>

<script>

export default {
  name: "MainHeader",
  components:{

  },
  computed:{
    school(){
      return this.$store.getters['user/school'];
    },
    translatedSchool(){
      return this.$store.getters['user/translatedSchool'];
    },
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
    user(){
      return this.$store.getters['user/data']
    },
    position(){
      switch (this.user.position) {
        case "teacher":
          return [
            {langKey: 'heb', data:{name: 'מחנכת'}},
            {langKey: 'en', data:{name: 'teacher'}},
            {langKey: 'ru', data:{name: 'учитель'}},
            {langKey: 'ar', data:{name: 'المربي'}},
          ];
        case "prof-teacher":
          return [
            {langKey: 'heb', data:{name: 'מורה מקצועית'}},
            {langKey: 'en', data:{name: 'professional teacher'}},
            {langKey: 'ru', data:{name: 'профессиональный учитель'}},
            {langKey: 'ar', data:{name: 'مدرس محترف'}},
          ];
        case "director":
          return [
            {langKey: 'heb', data:{name: 'מנהל'}},
            {langKey: 'en', data:{name: 'director'}},
            {langKey: 'ru', data:{name: 'директор'}},
            {langKey: 'ar', data:{name: 'مدير'}},
          ];
        case "student":
           return [
            {langKey: 'heb', data:{name: ''}},
            {langKey: 'en', data:{name: ''}},
            {langKey: 'ru', data:{name: ''}},
            {langKey: 'ar', data:{name: ''}},
          ];
        default:
          return ''
      }

      

    },
    translation(){
      if (this.$store.getters['lang-store/data']){
        return this.position.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data.name
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-school {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent !important;

    .f-el{
      margin: 0 3px;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      color: #FFFFFF;
      font-family: 'App';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      /* identical to box height, or 154% */

      text-align: right;

      color: #FFFFFF;
    }
    .first{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 10px;
    }
    .span-el{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .second{
      // font-style: italic;
      padding-top: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .logo{
    padding: 0px 5px;
    img{
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }
    text-align: center;
  p {
    font-size: 1.8rem; /*22px;*/
    margin: 0;
  }
  .wrapper {
    position: relative;
    top: 1rem;
  }
}
</style>