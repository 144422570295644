<template>
  <div  class="radio-wrapper">
    <slot   :setValue="changeValue"/>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      default: [],
    },
  },
  methods: {
    changeValue(value) {

      if(this.value.includes(value)) {

      } else {
        this.$emit('input', value);
      }
    }
  },
  computed:{

  }
}
</script>

<style scoped lang="scss">
//
</style>