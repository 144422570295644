<template>
  <main style="background:#005d79 !important" class="gift">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{data.details.name}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
      :loading="loading.sync"
      class="sm-cont">
      <template  v-if="!loading.sync">
        <div class="gift-element">
          <div class="header">
            <div class="img">
              <img :src="data.details.img_url" alt="">
            </div>
            <div class="title-m">{{data.details.name}}</div>
            <div class="gift-info">
              <!-- <div class="info-element">
                <div style="font-weight: bold" class="title"> {{$ml.get('רציונל')}}</div>
                <div class="description">{{translated.sec_1}}</div>
              </div>
              <div class="info-element">
                <div style="font-weight: bold" class="title"> {{$ml.get('מטרות')}}</div>
                <div class="description">{{translated.sec_2}}</div>
              </div> -->
            </div>
          </div>
          <div style="position: relative" class="games-wrapper" :style="{background:  $store.getters['educational-categories/getSevenPageColorById'](data.id_category)}">
            <div class="games-header">

              <!-- <div :style="{direction:direction}" class="title">  {{$ml.get('משחקים')}}:</div> -->
              <div class="icons-info">
                <div class="icon-element">
                  <span>{{$ml.get('מתאים רק לקבוצה')}}</span>
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="250.000000pt" height="20.000000pt" viewBox="0 0 250.000000 20.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M742 2377 c-70 -29 -113 -66 -148 -124 -24 -41 -29 -61 -32 -140 -4
                    -80 -1 -98 18 -140 13 -26 37 -63 54 -81 l31 -33 -30 -25 c-63 -53 -68 -54
                    -110 -43 -100 28 -256 -20 -330 -102 -63 -70 -89 -140 -90 -239 0 -76 3 -92
                    33 -152 18 -36 50 -82 72 -102 22 -20 40 -38 40 -40 0 -2 -20 -16 -44 -30 -51
                    -30 -119 -104 -148 -161 -49 -95 -58 -141 -58 -296 0 -81 3 -155 6 -164 7 -18
                    73 -54 124 -67 201 -49 416 -50 638 -3 l32 8 0 -116 c0 -140 4 -146 113 -182
                    108 -36 227 -48 397 -42 176 6 278 28 358 77 l32 20 0 121 0 121 33 -6 c187
                    -40 314 -45 502 -21 110 14 144 21 200 46 65 28 65 28 65 194 0 161 -10 216
                    -55 305 -21 42 -137 162 -178 185 -17 9 -14 15 29 59 72 75 99 143 99 246 0
                    98 -26 168 -89 237 -75 84 -213 128 -324 104 -51 -11 -53 -10 -102 25 -28 20
                    -50 38 -50 41 0 3 13 21 30 39 58 66 74 111 73 204 0 71 -4 93 -26 135 -49 97
                    -129 149 -247 160 -94 10 -162 -17 -233 -94 -112 -122 -114 -283 -4 -408 17
                    -19 26 -37 21 -42 -5 -4 -28 -22 -52 -40 -51 -40 -96 -103 -127 -179 l-23 -56
                    -13 44 c-24 83 -89 169 -169 221 l-24 16 36 44 c51 62 72 120 72 204 1 86 -25
                    147 -90 210 -83 82 -207 106 -312 62z m184 -93 c52 -21 81 -50 105 -103 63
                    -144 -47 -296 -202 -278 -77 9 -129 47 -160 114 -75 164 90 336 257 267z m790
                    0 c53 -22 83 -51 105 -102 64 -147 -49 -297 -210 -279 -115 13 -202 153 -162
                    260 13 33 69 96 106 119 37 22 109 23 161 2z m-723 -522 c61 -33 119 -104 142
                    -171 25 -77 18 -100 -37 -131 -218 -119 -261 -379 -93 -559 l47 -50 -29 -14
                    c-15 -8 -49 -34 -75 -57 l-48 -42 0 46 c0 26 -4 54 -9 64 -5 9 -17 38 -26 65
                    -31 86 -113 182 -200 233 -17 10 -15 14 23 49 54 49 100 142 108 221 10 92
                    -32 208 -100 282 -27 28 -28 34 -15 47 8 8 39 24 69 35 75 28 173 21 243 -18z
                    m795 -2 c23 -13 42 -27 42 -30 0 -4 -12 -17 -26 -31 -14 -13 -42 -53 -62 -89
                    -34 -61 -36 -70 -36 -155 -1 -78 3 -98 28 -152 17 -38 47 -79 74 -105 41 -38
                    44 -43 27 -52 -49 -29 -119 -92 -150 -134 -37 -51 -85 -187 -85 -240 l0 -34
                    -47 42 c-27 23 -61 49 -76 57 l-29 14 47 50 c73 78 100 146 100 249 0 67 -5
                    96 -24 137 -28 64 -122 155 -186 183 l-47 20 7 48 c14 100 79 196 155 229 82
                    36 218 33 288 -7z m-1231 -86 c63 -35 78 -49 108 -100 21 -36 25 -55 25 -121
                    0 -114 -36 -178 -128 -223 -72 -37 -149 -39 -217 -6 -128 63 -181 210 -119
                    334 62 125 216 179 331 116z m1588 7 c63 -28 106 -71 132 -128 31 -72 30 -139
                    -3 -208 -59 -127 -200 -178 -328 -120 -95 43 -136 111 -136 225 0 39 6 85 13
                    103 50 118 207 181 322 128z m-790 -303 c83 -44 145 -137 145 -219 0 -139
                    -101 -246 -242 -257 -46 -3 -66 1 -111 22 -193 91 -194 361 -2 453 44 21 59
                    23 120 18 38 -4 79 -11 90 -17z m-803 -295 c106 -34 188 -113 227 -218 17 -45
                    21 -79 21 -179 0 -87 -4 -126 -12 -133 -33 -25 -148 -44 -296 -49 -137 -5
                    -170 -3 -262 16 -57 12 -111 26 -118 33 -11 8 -13 41 -10 146 3 121 6 142 29
                    191 77 165 256 247 421 193z m1656 -23 c74 -39 125 -92 161 -169 23 -50 26
                    -70 29 -192 4 -150 5 -148 -64 -167 -159 -46 -557 -32 -621 20 -9 7 -13 46
                    -13 129 0 94 4 132 22 184 64 197 303 293 486 195z m-877 -271 c66 -14 151
                    -69 195 -126 58 -75 74 -137 74 -278 l0 -124 -27 -14 c-16 -8 -61 -22 -102
                    -31 -142 -31 -458 -14 -543 30 l-29 15 3 137 c3 127 5 141 32 196 36 73 76
                    119 134 153 84 49 164 62 263 42z"/>
                    </g>
                    </svg>
                </div>
              </div>
            </div>
            <div :class="{ collapsed }" :style="collapsed? ''  :'overflow-y:auto;'"  class="games">
              <GameElement :key="i*2" v-for="(game, i) in data.stats.playlists" :gifts="[data.id_gift]" :game="game"/>

            </div>
            <!-- <CollapseBottom v-model="collapsed" :backgroundColor="$store.getters['educational-categories/getFromChoresColorById'](data.id_category)" /> -->
          </div>
          <div v-if="data.movies.length>0" class="headline-tutorial">

            <div  :style="{direction:direction}" class="title-m">{{$ml.get('סרטונים')}}:</div>
            <div v-for="(el,index) in data.movies" :key="index" class="tutorial-elems" v-if="$ml.current==el.lang">
              <div
                class="container"
              >
                <iframe  v-if='el.yt_url'  :src="el.yt_url" frameborder="0"></iframe>
                <video v-else @play="onPlay" controls :src="el._video"></video>

              </div>

              <div class="info">
                <div class="title">
                 {{el.title}}
                </div>
                <div class="description">
                 {{el.description}}
                </div>
              </div>
              <div v-if="el.questions.length>0" class="movie-questions">

                <div :style="{direction:direction}" class="title">{{$ml.get('שאלות לדיון על הסרטון')}}:</div>
                <div :key="q.id_question" v-for="q in el.questions" class="mov-q">{{q.question}}</div>
              </div>
            </div>
            <div v-for="(el,ind) in data.movies" :key="ind" class="tutorial-elems" v-if="el.lang==null">
              
              <div
                class="container"
              >
                <iframe  v-if='el.yt_url'  :src="el.yt_url" frameborder="0"></iframe>
                <video v-else @play="onPlay" controls :src="url_movie"></video>

              </div>

              <div class="info">
                <div class="title">
                 {{el.title}}
                </div>
                <div class="description">
                 {{el.description}}
                </div>
              </div>
              <div v-if="el.questions.length>0" class="movie-questions">

                <div :style="{direction:direction}" class="title">{{$ml.get('שאלות לדיון על הסרטון')}}:</div>
                <div :key="q.id_question" v-for="q in el.questions" class="mov-q">{{q.question}}</div>
              </div>
            </div>
          </div>
          <!-- <div class="questions">
            <div
              v-if=" data.questions!==null "
              class="question-wrapper">
            <div class="header">

              <div :style="{direction:direction}" class="title">{{$ml.get('שאלות')}}:</div>
              <div class="info">{{$ml.get('סמנו את השאלות שנשאלו')}}</div>
            </div>

            <div
              v-for="question in questionsArr"
              class="questions-admin">
              <GiftQuestion
                @input="update(question)"
                v-model="question.stats.select"
                :question="question"
                v-if="question.text.trim()!==''" />
              <EmptyGiftQuestion
                @input="update(question)"
                v-model="question.stats.select"
                :question="question"
                v-else />
            </div>

            </div>
            <div class="checkboxes">
              <div class="title">{{$ml.get('סיכום הפעילות')}}</div>
              <div class="checkbox-wrapper dark">
                <div class="descrip">
                  {{$ml.get('סמנו את המשימות שבוצעו')}}
                </div>
              <GiftCheckbox
                @input="updateReview"
                v-model="checkboxLocal[i]"
                :question="question"
                :key="i*4"
                :index="i"
                v-for="(question,i) in questionsLocal"  />

              <Rate
                @input="updateReview"
                style="margin-top: 30px;border-radius: 14px;"
                v-model="rate" />
            </div>
            </div>
          </div> -->
          <div class="feedback">
            <div class="icon">
              <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.62286 0.00127089C6.87472 0.0202695 6.22824 0.251902 5.76107 0.719068C5.63091 0.849229 5.5382 1.00472 5.47694 1.17891C4.9429 2.70351 3.6836 3.96281 2.159 4.49685C1.98481 4.5581 1.82932 4.65082 1.69916 4.78098C0.204228 6.27591 1.11353 9.60873 3.72918 12.2244L3.78152 12.2767L5.13487 13.6301L2.58145 16.1835C1.69234 17.0726 1.69234 18.5142 2.58145 19.4042L2.58332 19.4061C3.02836 19.8511 3.61178 20.0734 4.19463 20.0734C4.77748 20.0734 5.35903 19.8511 5.80407 19.4061L20.3881 4.8221C21.2772 3.93203 21.2772 2.49047 20.3881 1.60136L20.3862 1.59949C19.9412 1.15446 19.3577 0.932164 18.7749 0.932164C18.192 0.932164 17.6105 1.15446 17.1655 1.59949L14.6102 4.15478L8.54254 10.2224C8.16833 10.5966 7.5634 10.5966 7.18919 10.2224C6.81498 9.84819 6.81498 9.24327 7.18919 8.86906L13.2568 2.80143L13.2045 2.74909C11.4056 0.950169 9.26877 -0.0405264 7.62286 0.00127089ZM20.0983 12.4169C18.0677 12.4169 16.1202 13.2236 14.6844 14.6595C13.2485 16.0953 12.4418 18.0428 12.4418 20.0734C12.4418 22.1041 13.2485 24.0515 14.6844 25.4874C16.1202 26.9233 18.0677 27.7299 20.0983 27.7299C22.129 27.7299 24.0764 26.9233 25.5123 25.4874C26.9482 24.0515 27.7548 22.1041 27.7548 20.0734C27.7548 18.0428 26.9482 16.0953 25.5123 14.6595C24.0764 13.2236 22.129 12.4169 20.0983 12.4169ZM0.121502 18.75C0.0497224 19.0208 0 19.3011 0 19.5949V31.5582C0 34.7299 2.57067 37.3006 5.74238 37.3006H11.4848V44H31.5831V41.1288C31.5831 35.3147 26.8696 30.6011 21.0554 30.6011H6.69944V21.137C5.97877 21.6797 5.11437 21.9876 4.19463 21.9876C3.11028 21.9876 2.08015 21.5752 1.29727 20.8267L1.22811 20.7594C0.668225 20.1996 0.297602 19.5042 0.121502 18.75Z"
                  fill="white"/>
              </svg>
            </div>

            <div class="text">
              {{$ml.get('נשמח לקבל ממך משוב ורעיונות למשחקים נוספים בקטגוריה זו')}}
            </div>


            <app-btn
              @click="feedback"
              style="width: 180px;height: 36px;margin-bottom: 23px"
              class="save-b">{{$ml.get('שלח תגובה')}}
            </app-btn>
          </div>
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>
import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import GameElement from "@/components/teacher/GameElementReloaded.vue";
import GiftQuestion from '@/components/teacher/GiftQuestion';
import EmptyGiftQuestion from '@/components/teacher/EmptyGiftQuestion';
import GiftCheckbox from '@/components/teacher/educational-gifts/GiftCheckbox';
import Rate from '@/components/teacher/educational-gifts/Rate';
import CollapseBottom from './CollapseBottom';
import {TeacherAPI} from "@/api";

export default {
  name: 'EducationalGift',
  data() {
    return {
      show_shadow: false,
      video_played: false,
      current: '',
      defaultType:"sevenPage",
      index: 0,
      questionsArr:[],
      popup_showed: false,
      radio: '',
      checkboxLocal: ['','',''],
      rate: '',
      collapsed: true,
      loading: {
        next: false,
        sync: false
      },
      questionsLocal:[
        {title: 'שיתוף', description:  'מזכירים את המשחקים והשאלות מהשיעור,\n' +
              ' ושומעים מכל ילד איזה משחק הוא הכי אהב ולמה'},
        {title: 'אתגר בית', description:   ' מבקשים מהילדים לשחק בבית בשניים מהמשחקים ממערך השיעור - מתוך אלו ששוחקו בכיתה ובעיקר מאלו שעדיין לא שוחקו בכיתה\n' +
              '(מדגישים שהמורה רואה בחשבונה מה הם משחקים בבית!)'
                },
        {title: 'אתגר הפסקות', description: 'מבקשים מהילדים לשחק בהפסקות  \n' +
              'בשניים מהמשחקים ששוחקו בשיעור\n ' },
      ],
      data: [],

    }
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    GameElement,
    GiftQuestion,
    EmptyGiftQuestion,
    GiftCheckbox,
    Rate,
    CollapseBottom,
  },
  computed:{
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.data.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        }).data
      }
    },
  },
  methods: {
    onPlay(e) {
      if(!this.video_played) {
        // PlaylistAPI.addActivity(this.data.id_playlist, 'vid');
        this.video_played = true;
      }
    },
    feedback() {
      this.$router.push({name: 'teacher-feedback-educational'});
    },
    update(question){
      TeacherAPI.gameQuestions({
        select: question.stats.select,
        id: question.id_question,
      })
    },
    updateReview(){
      TeacherAPI.addReview({
        columns: this.checkboxLocal,
        score: this.rate,
        id_gift: this.data.id_gift,  //id_gift
      })
    }
  },
  async created() {
    this.loading.sync = true;
    let type = this.$route.params.type!=null? this.$route.params.type : this.defaultType;
    switch(type)
    {
        case 'gameForAllOcasions':
        {
          if(!this.$store.getters['educational-categories/isLoadedMatchedGames'])
          {
            await this.$store.dispatch('educational-categories/updateMatchedGamesData');
          }
          TeacherAPI.matchedGamesByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'learningSkills':
        {
          if(!this.$store.getters['educational-categories/isLoadedLearningSkills'])
          {
            await this.$store.dispatch('educational-categories/updateSociallSkillsData');
          }
          TeacherAPI.learningSkillsByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'fromTheHastle':
        {
          if(!this.$store.getters['educational-categories/isLoadedFromChores'])
          {
            await this.$store.dispatch('educational-categories/updateFromChoresData');
          }

          TeacherAPI.fromTheHastleByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'communicationSkills':
        {
          if(!this.$store.getters['educational-categories/isLoadedSociallSkills'])
          {
            await this.$store.dispatch('educational-categories/updateSociallSkillsData');
          }
          TeacherAPI.communicationSkillsByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'sevenPage':
        {
          if(!this.$store.getters['educational-categories/isLoadedSevenPage'])
          {
            await this.$store.dispatch('educational-categories/updateSevenPageData');
          }
          TeacherAPI.sevenPageByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'eightPage':
        {
          if(!this.$store.getters['educational-categories/isLoadedEightPage'])
          {
            await this.$store.dispatch('educational-categories/updateEightPageData');
          }
          TeacherAPI.eightPageByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'ninePage':
        {
          if(!this.$store.getters['educational-categories/isLoadedNinePage'])
          {
            await this.$store.dispatch('educational-categories/updateNinePageData');
          }
          TeacherAPI.ninePageByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'tenPage':
        {
          if(!this.$store.getters['educational-categories/isLoadedTenPage'])
          {
            await this.$store.dispatch('educational-categories/updateTenPageData');
          }
          TeacherAPI.tenPageByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'elevenPage':
        {
          if(!this.$store.getters['educational-categories/isLoadedElevenPage'])
          {
            await this.$store.dispatch('educational-categories/updateElevenPageData');
          }
          TeacherAPI.elevenPageByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        case 'twelvePage':
        {
          if(!this.$store.getters['educational-categories/isLoadedTwelvePage'])
          {
            await this.$store.dispatch('educational-categories/updateTwelveData');
          }
          TeacherAPI.twelveByID(this.$route.params.id).then(res=>{
          this.data = res.data;
          this.questionsArr = res.data.questions.filter(item=>{
            return item.text!=='' || item.question!=='';
          });
          TeacherAPI.review(this.data.id_gift).then(res=>{
            if (res.data){
              this.rate = res.data.score;
              if (!res.data.columns){
                this.checkboxLocal.length=3;
              } else {
                this.checkboxLocal = res.data.columns;
              }
            }else {
              this.checkboxLocal.length=3;
            }
            this.loading.sync = false;
          })
        });
        break;
        }
        default:
          {
            if(!this.$store.getters['educational-categories/isLoaded'])
          {
            await this.$store.dispatch('educational-categories/updateData');
          }
            TeacherAPI.popularByID(this.$route.params.id).then(res=>{
            this.data = res.data;
            this.questionsArr = res.data.questions.filter(item=>{
              return item.text!=='' || item.question!=='';
            });
            TeacherAPI.review(this.data.id_gift).then(res=>{
              if (res.data){
                this.rate = res.data.score;
                if (!res.data.columns){
                  this.checkboxLocal.length=3;
                } else {
                  this.checkboxLocal = res.data.columns;
                }
              }else {
                this.checkboxLocal.length=3;
              }
              this.loading.sync = false;
            })
          });
          break;
          }
    }
    
  },
};
</script>
<style scoped lang="scss">

.gift {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;
    background: #00465B;

    .gift-element{
  
      .header{  
        padding: 0 10px 30px 10px;
        background: #00465B;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img{
            margin-top: 23px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 50%;
          img{
            padding: 5px;
            max-width: 50px;
          }
        }
        .title-m{
          margin: 10px 0 30px 0;
          font-weight: bold;
          font-size: 28px;
          line-height: 19px;
          font-family: Artishok;
          text-align: center;
          color: #FFFFFF;
        }
          .gift-info{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            .info-element{
              width: 158px;
              margin: 0 10px;
              @media screen and (max-width: 400px){
                width: unset;
              }
              .title{
                font-weight: 500;
                font-size: 16px;
                text-align: right;
                color: #FFFFFF;
              }
              .description{
                font-size: 12px;
                line-height: 18px;
                text-align: right;
                color: #FFFFFF;
              }
            }
          }
      }
      .games-wrapper{
        padding: 0 10px;
        .games-header{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: end;
          padding: 16px 10px 10px 10px;
          .icons-info{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .icon-element{
              display: flex;
              align-items: center;
              svg{
                margin-right: 10px;
                width: 17px;
              }
              span{
                font-size: 14px;
                line-height: 18px;
                text-align: right;
                color: #06364B;
                margin-right: 5px;
              }
            }
          }
          .title{
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #FFFFFF;
          }
        }
        .games{

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0 auto;
          max-height: 1000px;
          overflow: hidden;
          position: relative;
          transition: max-height 1s ease;
          padding-bottom: 60px;

          &.collapsed {
            max-height: 100%;
            overflow-y: auto;
          }

          .game-element{
            margin: 0 16px;
            @media screen and (max-width: 420px){
              margin: 0;
            }
            .icon-wrapper{
              @media screen and (max-width: 330px){
                width: 83px!important;
              }
            }

          }
        }
      }
      .questions{
        background: #005D7A;

        .question-wrapper{
          padding: 20px;
          .header{
            padding: 0 0 30px 10px;
            background: #005D7A;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            color: #FFFFFF;
            .title{
              font-weight: 500;
              font-size: 16px;
              line-height: 23px;
              text-align: right;

            }
            .info{
              font-weight: 300;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      .checkboxes{
        .descrip{
          padding-left: 15px;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          color: #FFFFFF;
        }
        .title{
          padding: 13px 0;
          background: #FF7384;
          font-weight: bold;
          font-size: 24px;
          line-height: 35px;
          text-align: center;
          color: #FFFFFF;
        }
        .checkbox-wrapper{
          &.dark{
            background: #00465B;
          }
          .empty-gift-question{
            .question{
              padding: 15px 0 15px 22px!important;
            }
          }
          padding: 20px;
        }
      }

      }
      .feedback{
        padding: 0 10px;
        background: #00465B;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .icon{
          padding: 24px 0 17px;
        }
        .text{
          width: 300px;
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
          padding-bottom: 22px;
        }
      }
    }

  }
}
  .headline-tutorial{
    margin-bottom: 30px;
    .title-m{
      font-weight: 500;
      margin: 23px 20px 0 20px;
      font-size: 16px;
      line-height: 22px;
      text-align: right;
      color: #FFFFFF;
    }
    .tutorial-elems{
      padding: 10px 0;
      .container {
        width: 100%;
        height: 188.89px;
        padding: 0 20px 10px 20px;
        top: 0;
        left: 0;

        video {
          height: 100%;
          width: 100%;
          background: #000;
        }
        iframe{
          height: 100%;
          width: 100%;

        }
      }
      .info{
        padding: 0 20px;
        .title{
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          text-align: right;
          color: #FFFFFF;
        }
        .description{
          margin-top: 5px;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;
          text-align: right;
          color: #FFFCFE;
        }
      }
      .movie-questions{
        margin-top: 23px;
        padding: 0 20px;
        .title{
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          text-align: right;
          color: #FFFFFF;
        }
        .mov-q{
          position: relative;
          margin: 11px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          text-align: right;
          color: #FFFFFF;
          padding: 0 14px;
          &:after{
            position: absolute;
            content: '';
            width: 6px;
            top: 9px;
            right: 0;
            height: 6px;
            border-radius: 50%;

            background: #FFFFFF;
          }
        }
      }
    }
  }
</style>