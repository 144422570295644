<template>
  <div class="code" ref="container">
    <input
      type="number"
      maxlength="1"
      class="theme-input"
      v-for="(digit, index) in digits"
      v-bind:key="index"
      v-model="digit.value"
      @input="onValueChange"
    />
  </div>
</template>

<script>
export default {
  computed: {
    digits: function() {

      return [null, null, null, null, null, null].map((_, i) => ({
        value: this.value[i]
      }));
    }
  },
  props: { value: String, onChange: Function },
  methods: { onValueChange }
};

function onValueChange(e) {
  const inputs = Array.from(this.$refs.container.querySelectorAll("input"));
  const index = inputs.indexOf(e.target);

  const isFirst = index === 0;
  const isLast = index + 1 === inputs.length;
  const hasValue = Boolean(e.target.value);

  if (!hasValue) return;

  if (isLast) e.target.blur();
  else inputs[index + 1].focus();

  const code = this.digits.map(({ value } = {}) => value).join("");
  if (code.length === 6) this.$props.onChange(code);
}
</script>

<style lang="scss" scoped>
.code {
  display: flex;
  justify-content: center;
}

input {
  width: 50px;
  height: 47px;
  text-align: center;
  font-family: sans-serif;
  border: 1px solid #60aac0;
}

input + input {
  margin-left: 10px;
}
</style>
