import {DirectorAPI} from "@/api";

const DirectorTargetsStore = {
  namespaced: true,
  state: {
    data: [],
    position: '',
    loaded: false,
  },
  mutations: {
    saveData(state, data) {
      state.data = data
    },
    savePosition(state, data) {
      state.position = data
    },
    saveInvention(state, data) {
      state.data.inventionCourse = data
    },
    gamesShifts(state, data) {
      state.data.gamesShifts = data
    },
    load(state) {
      state.loaded = true;
    },
  },
  actions: {
      updateData({ commit, state } , payload) {
        DirectorAPI.setTargets({
          data: state.data.data,
          filter: payload.filter ==='prof-teacher'? 'prof' : payload.filter,
          id_teacher: payload.id,
        }).then(res => {
            commit('saveData', res.data);
            commit('load')
          });
    },
  },
  getters: {
    data(state) {
      return state.data.data;
    },
    position(state){
      return state.position;
    },
    gifts(state) {
      return state.data.data.educationClass
    },
    isLoaded(state) {
      return state.loaded;
    },
  }
};

export { DirectorTargetsStore };
