<template>
  <!-- СОРЯН ЧТО ТУТ СОЗДАЛ КОМПОНЕНТ ПЕРЕНЕСИ ЕГО -->
  <button @click="$emit('input', !value)" :class="{ collapsed: value }" class="collapse-bottom" :style="backgroundColor!=null?{background:backgroundColor}:'background: #669EAF; '">
    <div class="text">
      <span v-if="!value">{{$ml.get('פחות משחקים')}}</span>
      <span v-else>{{$ml.get('יותר משחקים')}}</span>
    </div>
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.65674L6.65685 11.3136L12.3137 5.65674" stroke="#06364B" stroke-width="2"/>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    backgroundColor:null,
    value: {
      type: Boolean,
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>

.collapse-bottom {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 60px;

  .text {
    color: #06364B;
  }

  svg {
    transform: rotate(180deg);
    transition: transform 1s ease;
  }


  &.collapsed {
    svg {
      
      transform: rotate(0deg);
    }
  }
}

</style>