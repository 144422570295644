<template lang="html">

  <button v-if="iconSmall" class="modal-close" type="button" @click="$emit('click', $event)">
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="11.9473" y1="0.353553" x2="1.3407" y2="10.9602" stroke="black"/>
      <line x1="11.9472" y1="10.961" x2="1.34063" y2="0.354372" stroke="black"/>
    </svg>
  </button>

  <button v-else class="modal-close" type="button" @click="$emit('click', $event)">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 1.15689L18.8406 0L10 8.82369L1.15941 0L0 1.15689L8.84059 9.98065L0 18.8037L1.15941 19.9606L10 11.1375L18.8406 19.9606L20 18.8037L11.1588 9.98065L20 1.15689Z" :fill="color ? color : '#BDBDBD'"/>
    </svg>
  </button>






</template>

<script>
export default {
  props: {
    iconSmall:{
      default: false,
    },
    color: {
      
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-close {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
}

</style>
