<template>
  <main class="educational-classes"  style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Sixth_page').data[0])}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
    :style="{background : gifts.known.length>0? '#005d79' : '#005d79'}"
      class="sm-cont">
      <template>
        <div class="educational">
          <div class="header">
            <div class="title" style="margin-top:20px">
              <div>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.19723 0V2.33333H7.43253L0 9.67513L1.67013 11.3249L9.10265 3.98307V8.16667H11.4648V0H3.19723ZM22.0946 0C20.1517 0 18.5513 1.58087 18.5513 3.5C18.5513 5.41913 20.1517 7 22.0946 7C24.0374 7 25.6378 5.41913 25.6378 3.5C25.6378 1.58087 24.0374 0 22.0946 0ZM22.0946 2.33333C22.7619 2.33333 23.2757 2.8408 23.2757 3.5C23.2757 4.1592 22.7619 4.66667 22.0946 4.66667C21.4272 4.66667 20.9135 4.1592 20.9135 3.5C20.9135 2.8408 21.4272 2.33333 22.0946 2.33333ZM20.9135 8.16667C18.308 8.16667 16.1892 10.2597 16.1892 12.8333V18.9561L18.5513 20.1227V28H20.9135V18.6826L18.5513 17.5159V12.8333C18.5513 11.5465 19.6108 10.5 20.9135 10.5H23.2757C24.5784 10.5 25.6378 11.5465 25.6378 12.8333V17.5159L23.2757 18.6826V28H25.6378V20.1227L28 18.9561V12.8333C28 10.2597 25.8811 8.16667 23.2757 8.16667H20.9135ZM11.4648 14V28H13.827V14H11.4648ZM6.74048 18.6667V28H9.10265V18.6667H6.74048ZM2.01615 23.3333V28H4.37832V23.3333H2.01615Z" fill="white"/>
              </svg>
              </div>
              <div>
              <span class="title-style">{{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Sixth_page').data[0])}}</span>
              </div>
            </div>
            <div class="title">
              <span>{{$ml.get('בחרו את כישורי החיים אותם אתם רוצים לחזק אצל ילדיכם, וקבלו משחקים מתאימים')}}</span>
            </div>
            <div class="undertitle">

              <div :style="{direction: direction}" class="element">
                <!-- <div   class="round green"></div>
                <span :style="direction==='ltr'? 'text-align:left;margin-left:5px':''">{{$ml.get('מספר הפעמים ששוחקו משחקים בקטגוריה זו')}}</span> -->
              </div>
            </div>
          </div>
          <div class="gifts-wrapper">
            <app-loading v-if="!isLoaded">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </app-loading>


            <app-loading v-else>

            <div
              v-if="unknown.length>0"
              :style="{background:  '#a8b5b3'}"
              class="gifts-section">
              <div class="title">{{$ml.get('אין קטגוריה')}}</div>
              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational'"
                  :key="i*2" v-for="(gift, i) in unknown"
                  :rec="true"
                  :gift="gift"  />
              </div>
            </div>
            <div class="cat-w">

            <div
              v-for="elem in test"
              v-if="elem[0]!=null"
              :style="{background:  $store.getters['educational-categories/getColorById'](elem[0].id_category)}"
              class="gifts-section">
              <div class="title">{{$store.getters['educational-categories/geTitleById'](elem[0].id_category)}}</div>

              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational'"
                  :key="i*2" 
                  v-for="(gift, i) in elem"
                  v-if="gift.lifeSkillsPage==true"
                  :rec="true"
                  :gift="gift"  />
              </div>
            </div>
          </div>

            </app-loading>
          </div>

        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GiftElement from "@/components/teacher/GiftElement";
  import {TeacherAPI} from "@/api/teacher.api";
  import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
  import store from "../../store";
  import StudentFromMainFirstTime from "@/components/modals/StudentFromMainFirstTime";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        testData:[1,2,3,4,5,6],
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        bifts:[
          {title: 'הפופולריים ביותר ',color:'#B56576',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#FF7384',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]}
        ],
        gifts: {
          popular: [],
          known: [],
        },
        test: [],
        unknown: [],
        isLoaded: false,
      };
    },
    computed:{
      ...mapGetters({
        eduData:"educational-categories-page/data"
      }),
      sections(){
        return this.$store.getters['educational-categories/data'];
      },
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    components: {
      SimpleHeader,
      SidebarToggler,
      GiftElement,
      GiftElementSkeleton
    },
    watch:{
    eduData:function(){
      if(this.sections!=null)
      {
      this.ready()
      }
    },
    sections:function(){
      if(this.eduData!=null)
      {
      this.ready()
      }
    }
    },
    methods: {
      ready(){
        this.unknown = this.eduData.filter(item=>{
          return item.id_category===null;
        });
        let sectionFiltered = Array();
        this.sections.forEach((sec)=>{
            if(sec.lifeSkillsPage==true)
            {
              sectionFiltered.push(sec);
            }
          })
          sectionFiltered = sectionFiltered.sort((a,b)=>a.order_index_teachers-b.order_index_teachers)
          let sortArray = [];
        sectionFiltered.forEach((sec, i) => {
          sortArray[i] = this.eduData.filter((item) => {
            return +item.id_category === sec.id;
          });
        });

        sortArray.forEach(element=>{
        element.sort((a, b) => (a.order_index_teachers != null ? a.order_index_teachers : Infinity) - (b.order_index_teachers != null ? b.order_index_teachers : Infinity))
        });
        this.test = sortArray;
          
       this.gifts.popular = this.eduData.filter(item=>{
          return item.section==='popular';
        });
        this.gifts.known = this.eduData.filter(item=>{
          return item.section==='known';
        });

        this.isLoaded = true;
      }
    },
    mounted() {
    if (!this.$store.getters['educational-categories/isLoaded']){
      this.$store.dispatch('educational-categories/updateData');
    }
    if (!store.getters['educational-categories-page/isLoaded']){
      store.dispatch('educational-categories-page/updateData');
    }
      if(!this.$store.getters['tutorials/isUsed']('student-educational') && this.$route.params.student) {
        this.$store.commit('tutorials/setTutorial', 'student-educational');
        this.$modal.show(
          StudentFromMainFirstTime,
          {},
          {
            adaptive: true,
            height: "auto",
            width: 300
          }
        );
      }
    },
    created() {
      window.fbq("track", "כישורי חיים");
      if (this.$store.getters['educational-categories-page/isLoaded']) {
        this.ready();
      }
      else
      {
        this.isLoaded= false;
      }
      // if (!this.$store.getters['teacher-educational/isLoaded']){
      //   this.$store.dispatch('teacher-educational/updateData');
      // }

    },
  };
</script>
<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  padding: 10px;
  position: absolute;
     height: 12px;
     left: 135px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:40% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.title-style{
  // font-family: 'Kartiv';
font-style: italic;
font-weight: 700;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.educational-classes {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;


    .educational {
      .header {
        background: #00465B;
        padding: 5px 30px 25px 30px;

        .title {
          margin: 10px auto;
          width: 100%;
          font-family: 'App';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          color: #FFFFFF;
          @media screen and (max-width: 330px){
            width: unset;
          }
        }
        .undertitle {
          width: 310px;
          margin: 0 auto;
          @media screen and (max-width: 330px){
            width: unset;
          }
          .element {
            display: flex;
            align-items: center;
            text-align: right;
            color: #FFFFFF;
            margin: 2px 0;
            font-family: 'App';
            font-style: italic;
            font-weight: 200;
            font-size: 12px;
            line-height: 16px;
            text-align: right;

            color: #FFFFFF;
            .round {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              @media screen and (max-width: 330px){
                margin-left: 4px;
              }
            }

            .green {
              background: #8CC63F;
            }

            .yellow {
              background: #FBB03B;
            }
          }
        }
      }
      .gifts-wrapper{
        .gifts-section{
          background: #B56576;
          padding: 0 10px;
          .title{
            font-weight: bold;
            padding: 10px 0 10px;
            font-size: 21px;
            text-align: center;
            color: #FFFFFF;
          }
          .gifts{
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .gift-element{
              margin: 0 16px;
              @media screen and (max-width: 420px){
                margin: 0;
              }
            }
          }
        }
      }

    }
  }
}
</style>