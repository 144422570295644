<template>
  <main class="educational-classes" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Fifth_page').data[0])}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <!--:style="{background : gifts.known.length>0? '#FF7384' : '#B56576'}"-->
    <app-loading
    :style="{background : gifts.known.length>0? '#005d79' : '#005d79'}"
      class="sm-cont">
      <template>
        <div class="educational">
          <div class="header">
            <div class="title" style="margin-top:20px">
              <div>
            <svg 
                  width="40" height="30" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.5529 0C31.2555 0 30.1051 0.542498 29.3739 1.37274C28.6427 2.20299 28.3068 3.25391 28.3068 4.28564C28.3068 4.99131 28.4767 5.70346 28.81 6.35034C28.5988 6.36184 28.3929 6.42095 28.2073 6.52332L19.378 11.4284H15.5688C15.1934 11.4284 14.8334 11.5789 14.568 11.8468C14.3026 12.1147 14.1535 12.4781 14.1534 12.8569V17.1426H0V19.9997H19.8148C20.0313 20.0001 20.245 19.9505 20.4395 19.8546L29.9212 15.1616L31.414 19.0845C31.4931 19.292 31.6191 19.4779 31.7819 19.6276C31.9448 19.7773 32.14 19.8866 32.352 19.9467C32.5641 20.0069 32.7871 20.0162 33.0034 19.9741C33.2196 19.9319 33.4232 19.8394 33.5978 19.7039L40 14.7151L38.2695 12.4496L33.3711 16.2665L32.0829 12.8737C32.0726 12.8246 32.0596 12.7762 32.0442 12.7286L30.4686 8.03557C31.0781 8.36455 31.7828 8.57128 32.5529 8.57128C33.8503 8.57128 35.0007 8.02878 35.7319 7.19854C36.463 6.36829 36.7989 5.31737 36.7989 4.28564C36.7989 3.25391 36.463 2.20299 35.7319 1.37274C35.0007 0.542498 33.8503 0 32.5529 0ZM32.5529 2.85709C33.1426 2.85709 33.4075 3.02887 33.6199 3.27003C33.8323 3.5112 33.9682 3.88882 33.9682 4.28564C33.9682 4.68246 33.8323 5.06008 33.6199 5.30125C33.4075 5.54241 33.1426 5.71419 32.5529 5.71419C31.9631 5.71419 31.6982 5.54241 31.4858 5.30125C31.2734 5.06008 31.1375 4.68246 31.1375 4.28564C31.1375 3.88882 31.2734 3.5112 31.4858 3.27003C31.6982 3.02887 31.9631 2.85709 32.5529 2.85709ZM4.66068 8.39829L2.74223 10.502L6.80581 14.2855H10.9744L4.66068 8.39829ZM28.0912 9.84916L28.9648 12.4551L19.4886 17.1426H16.9841V14.2855H19.7429C19.9809 14.2846 20.2147 14.2232 20.4229 14.1069L28.0912 9.84916Z" fill="white"/>
                    </svg>


              </div>
              <div>
              <span class="title-style">{{$ml.get($store.getters['pagestitle/data'].find(x=>x.name=='Fifth_page').data[0])}}</span>
              </div>
            </div>
            <div class="title">
              <span>{{$ml.get('בחרו כישורים חברתיים אותם אתם רוצים לחזק אצל ילדיכם, וקבלו משחקים מתאימים')}}</span>
            </div>
            <div class="undertitle">

              <div :style="{direction: direction}" class="element">
                <!-- <div   class="round green"></div>
                <span :style="direction==='ltr'? 'text-align:left;margin-left:5px':''">{{$ml.get('מספר הפעמים ששוחקו משחקים בקטגוריה זו')}}</span>-->
              </div>
            </div>
          </div>
          <div class="gifts-wrapper">
            <app-loading v-if="!isLoaded">
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
              <div
                :style="{ background: '#f5f0f0' }"
                class="gifts-section"
              >
                <div class="title"><div class="line h17 w40 m10"></div></div>
                <div class="gifts" style="padding: 15px 0 15px 0">
                  <GiftElementSkeleton
                    :classType="'educational-parents'"
                    v-for="i in testData"
                    :key="i+1"
                  />
                </div>
              </div>
            </app-loading>


            <app-loading v-else>
            <div
              v-if="unknown.length>0"
              :style="{background:  '#a8b5b3'}"
              class="gifts-section">
              <div class="title">{{$ml.get('אין קטגוריה')}}</div>
              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-communicationSkills'"
                  :key="i*2" v-for="(gift, i) in unknown"
                  :rec="true"
                  :gift="gift"
                  :type="'communicationSkills'"  />
              </div>
            </div>
            <div class="cat-w">


            <div
              v-for="elem in test"
              v-if="elem.length>0"
              :style="{background:  $store.getters['educational-categories/getSociallSkillsColorById'](elem[0].id_category)}"
              class="gifts-section">
              <div class="title">{{$store.getters['educational-categories/geTitleSociallSkillsById'](elem[0].id_category)}}</div>

              <div class="gifts" style="padding: 0 0 15px 0;" >
                <GiftElement
                  :classType="'educational-communicationSkills'"
                  :key="i*2" 
                  v-for="(gift, i) in elem"
                  :rec="true"
                  :gift="gift"
                  :type="'communicationSkills'"  />
              </div>
            </div>
          </div>

            </app-loading>
          </div>

        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>

  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import GiftElement from "@/components/parents/GiftElement";
  import {TeacherAPI} from "@/api/teacher.api";
  import GiftElementSkeleton from "@/components/common/GiftElementSkeleton";
  import store from "../../store";
  import StudentFromMainFirstTime from "@/components/modals/StudentFromMainFirstTime";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        testData:[1,2,3,4,5,6],
        index: 0,
        popup_showed: false,
        radio: '',
        loading: {
          next: false,
          sync: false
        },
        bifts:[
          {title: 'הפופולריים ביותר ',color:'#B56576',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]},
          {title: 'ערכים נוספים ',color:'#FF7384',arr:[{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '},{name: 'חיוביות '}]}
        ],
        gifts: {
          popular: [],
          known: [],
        },
        test: [],
        unknown: [],
        isLoaded: false
      };
    },
    computed:{
      ...mapGetters({
        eduData:"communication-skills-page/data"
      }),
      sections(){
        return this.$store.getters['educational-categories/dataSociallSkills'];
      },
      direction(){
        return this.$store.getters['lang-store/direction'];
      },
    },
    components: {
      SimpleHeader,
      SidebarToggler,
      GiftElement,
      GiftElementSkeleton
    },
    watch:{
    eduData:function(){
      if(this.sections!=null)
      {
      this.ready()
      }
    },
    sections:function(){
      if(this.eduData!=null)
      {
      this.ready()
      }
    }
    },
    methods: {
      ready()
      {
        this.unknown = this.eduData.filter(item=>{
          return item.id_category===null;
        });
        let sectionFiltered = Array();
        this.sections.forEach((sec)=>{
            // if(sec.valuebleExpireancePage==true)
            // {
              sectionFiltered.push(sec);
            // }
          })
        
        sectionFiltered = sectionFiltered.sort((a,b)=>a.order_index-b.order_index)

        let sortArray = [];
        sectionFiltered.forEach((sec, i) => {
          sortArray[i] = this.eduData.filter((item) => {
            return +item.id_category === sec.id;
          });
        });

        sortArray.forEach(element=>{
        element.sort((a, b) => (a.order_index != null ? a.order_index : Infinity) - (b.order_index != null ? b.order_index : Infinity))
        });
        this.test = sortArray;
        
       this.gifts.popular = this.eduData.filter(item=>{
          return item.section==='popular';
        });
        this.gifts.known = this.eduData.filter(item=>{
          return item.section==='known';
        });
        this.isLoaded = true;
      }
    },
    mounted() {
      if (!this.$store.getters['communication-skills-page/isLoaded']){
        this.$store.dispatch('communication-skills-page/updateData');
      }
      if (!this.$store.getters['educational-categories/isLoadedSociallSkills']){
        this.$store.dispatch('educational-categories/updateSociallSkillsData');
      }
      if(!this.$store.getters['tutorials/isUsed']('student-educational') && this.$route.params.student) {
        this.$store.commit('tutorials/setTutorial', 'student-educational');
        this.$modal.show(
          StudentFromMainFirstTime,
          {},
          {
            adaptive: true,
            height: "auto",
            width: 300
          }
        );
      }
    },
    async created() {
      window.fbq("track", "כישורים חברתיים");
      if (this.$store.getters['communication-skills-page/isLoaded']) {
        this.ready();
      }
      else
      {
     this.isLoaded = false;
    }
      // if (!this.$store.getters['teacher-educational/isLoaded']){
      //   this.$store.dispatch('teacher-educational/updateData');
      // }

    },
  };
</script>
<style scoped lang="scss">
.circle {
     height: 80px;
     border-radius: 15px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-squares 2s infinite ease-out;
}
.line {
  padding: 10px;
  position: absolute;
     height: 12px;
     left: 135px;
     width:100%;
      display:block;
     margin-bottom:6px;
     border-radius: 2px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation: wave-lines 2s infinite ease-out;
}
 
 .flex1{
    flex: 1;
}
 .flex2{
    flex: 2;
}
 .h8{
    height: 8px !important;
}
 .h10{
    height: 10px !important;
}
 .h12{
    height: 12px !important;
}
 .h15{
    height: 15px !important;
}
 .h17{
    height: 17px !important;
}
 .h20{
    height: 20px !important;
}
 .h25{
    height: 25px !important;
}
 .w25{
    width: 25% !important
}
 .w40{
    width:40% !important;
}
 .w50{
    width: 50% !important
}
 .w75{
    width: 75% !important
}
 .m10{
    margin-bottom: 10px !important;
}
 .circle{
    border-radius: 50% !important;
     height: 84px !important;
     width: 84px;
}
 @keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
.title-style{
  // font-family: 'Kartiv';
font-style: italic;
font-weight: 700;
font-size: 20px;
line-height: 24px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}
.educational-classes {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {

    flex-grow: 1;
    min-height: 450px;


    .educational {
      .header {
        background: #00465B;
        padding: 5px 30px 25px 30px;

        .title {
          margin: 10px auto;
          width: 100%;
          font-family: 'App';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;

          color: #FFFFFF;
          @media screen and (max-width: 330px){
            width: unset;
          }
        }
        .undertitle {
          width: 310px;
          margin: 0 auto;
          @media screen and (max-width: 330px){
            width: unset;
          }
          .element {
            display: flex;
            align-items: center;
            text-align: right;
            color: #FFFFFF;
            margin: 2px 0;
            font-family: 'App';
            font-style: italic;
            font-weight: 200;
            font-size: 12px;
            line-height: 16px;
            text-align: right;

            color: #FFFFFF;
            .round {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              @media screen and (max-width: 330px){
                margin-left: 4px;
              }
            }

            .green {
              background: #8CC63F;
            }

            .yellow {
              background: #FBB03B;
            }
          }
        }
      }
      .gifts-wrapper{
        .gifts-section{
          background: #B56576;
          padding: 0 10px;
          .title{
            font-weight: bold;
            padding: 10px 0 10px;
            font-size: 21px;
            text-align: center;
            color: #FFFFFF;
          }
          .gifts{
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .gift-element{
              margin: 0 16px;
              @media screen and (max-width: 420px){
                margin: 0;
              }
            }
          }
        }
      }

    }
  }
}
</style>