<template>
  <welcome-wrapper>
    <div class="welcome">
      <div class="body">
        <div class="title heebo" dir="rtl">
          הכניסה ל PlayList
        </div>
      </div>
      <div class="footer">
        <router-link to="/reg" class="theme-button-primary">
          התחל
        </router-link>
      </div>
    </div>
  </welcome-wrapper>
</template>

<script>
import WelcomeWrapper from "../components/WelcomeWrapper.vue";

export default {
  components: {
    WelcomeWrapper
  }
};
</script>

<style lang="scss" scoped>
.welcome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.body {
  margin-top: 93px;
}

.title {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  line-height: 31px;
  color: #fff;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
  padding-left: 45px;
  padding-right: 45px;
}

.footer a {
  height: 42px;
}

.footer .theme-button-primary {
  margin-bottom: 18px;
}
</style>
