<template lang="html">
  <transition name="fade-anim">
    <modal v-if="$store.getters['modals/target-save/isOpened']" @close="close">
      <div class="th-modal container">
        <div v-if="$store.getters['modals/target-save/title']!==''" class="header">

          <div class="title">{{$ml.get('דו"ח היעדים נשלח בהצלחה אל')}}: <span>{{ $store.getters['modals/target-save/title'] }}</span></div>
        </div>
        <div class="text">{{$ml.get('ניתן להגדיר יעדים נוספים למחנכות שכבה או למורה מקצועית')}}</div>

        <app-btn   @click="close" class="save-b">{{$ml.get('לעריכת יעדים נוספים')}}</app-btn>

      </div>
    </modal>
  </transition>
</template>

<script>
import Modal from './Modal'

export default {
  components: {
    Modal,

  },
  methods: {
    close() {
      this.$store.commit('modals/target-save/close');
    }
  }
}
</script>

<style lang="scss" scoped>

.modal {
  &.fade-anim {
    &-enter {
      .container {
        transform: translateY(-50px);
      }
    }
    &-enter-to {
      .container {
        transform: translateY(0);
      }
    }

    &-leave {
      .container {
        transform: translateY(0);
      }
    }
    &-leave-to {
      .container {
        transform: translateY(-50px);
      }
    }

    &-leave-active,
    &-enter-active {
      .container {
        transition: transform 0.3s ease;
      }
    }
  }
}

.container {
  width: 375px;
  height: 180px;
  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;
  border-radius: 10px;
  @media screen and (max-width: 375px){
    width: 80%;
  }
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAF6F9;
    height: 50px;
    .title {
      display: flex;
      align-items: center;
      font-style: italic;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #005D7A;
      span{
        font-weight: bold;
      }
    }
  }


  .text{
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #005D7A;
  }
  .save-b{
    background: #FFFFFF;
    border: 1px solid #149CC6;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 23px;
    width: 180px;
    height: 36px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: #149CC6;
  }
}

</style>
