<template>
  <div class="empty-basket">
      <div class="header">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM0 0V2H2L5.6 9.6L4.2 12C4.1 12.3 4 12.7 4 13C4 14.1 4.9 15 6 15H18V13H6.4C6.3 13 6.2 12.9 6.2 12.8V12.7L7.1 11H14.5C15.3 11 15.9 10.6 16.2 10L19.8 3.5C20 3.3 20 3.2 20 3C20 2.4 19.6 2 19 2H4.2L3.3 0H0ZM16 16C14.9 16 14 16.9 14 18C14 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z" fill="#005D79"/>
        </svg>

        <div class="text">{{$ml.get("סל הקניות שלך ריק")}}</div>
      </div>
      <div class="links-wrapper">





        <div    class="link">
          <div    class="link-element">


            <div class="description">
              <span>{{$ml.get('מנוי למשחקים באפליקציה')}}.</span> <br/>
              <span> {{$ml.get('משחקים יצירתיים מחוץ למסך')}} ,{{$ml.get('ללא צורך בציוד')}}</span>



            </div>
            <router-link v-if="!$store.getters['user/checkSubs'] && !$store.getters['sub/getDisabled']" class="link-b"    :to="{name: 'subsPlans'}">{{$ml.get('לרכישת מנוי')}}</router-link>
            <!-- <button
                v-if="!$store.getters['user/checkSubs']"
               style="margin-right: -6px;"
                class="link-b"
                @click="subscribeSched">{{$ml.get('לרכישת מנוי')}}</button> -->
                <span v-else class="link-b">{{$ml.get('מנוי נרכש')}}</span>
          </div>
        </div>


        <div    class="link">
          <div    class="link-element">
            <div class="description">
              <span>  {{$ml.get('ערכות משחקים פיזיות - 80 רעיונות')}} </span> <br/>
             <span> {{$ml.get('למשחקים יצירתיים ופשוטים שאינם דורשים ציוד')}}</span>
            </div>
             <router-link class="link-b"    :to="{name: 'funkit'}">{{$ml.get('לצפייה בערכות')}}</router-link>
          </div>
        </div>

        <div    class="link">
          <div    class="link-element">
            <div class="description">

              <span> {{$ml.get('סדנאות משחק חווייתיות ומגבשות למורים')}},</span>  <br/>
              <span>  {{$ml.get('עובדים')}}, {{$ml.get('תלמידים')}}, {{$ml.get('אירועים ולמשפחה')}} </span>
            </div>
             <router-link class="link-b"   :to="{name: 'workshop'}">{{$ml.get('לצפייה בסדנאות')}}</router-link>
          </div>
        </div>

        <div    class="link">
          <div    class="link-element">

            <div class="description">{{$ml.get('לרכישה כמותית')}}</div>
            <button class="link-b" @click="open"  >{{$ml.get('ליצירת קשר')}}</button>
           </div>
        </div>

      </div>
  </div>
</template>

<script>
  import { SubscribeAPI } from '@/api';
  import Payment from "@/components/modals/Payment";

  export default {
    name: "EmptyBasket",
    props:{
      item:{},
    },
    data(){
      return{
        sub:      {
          title: 'מנוי למשחקים באפליקציה.\n' +
              'משחקים יצירתיים מחוץ למסך, ללא צורך בציוד ',
          button:{
            text: 'מנוי למשחקים',
            link: 'subscribe',
          }
        },
        links:[
          {
            title: 'ערכות משחקים פיזיות - 80 רעיונות \n' +
                ' למשחקים יצירתיים ופשוטים שאינם דורשים ציוד\n',
            button:{
              text: 'לצפייה בערכות ',
              link: 'funkit',
            }
          },
          {
            title: 'סדנאות משחק חווייתיות ומגבשות למורים, \n' +
                ' תלמידים, עובדים, אירועים ולמשפחה\n',
            button:{
              text: 'לצפייה בסדנאות',
              link: 'workshop',
            }
          },
          {
            title: 'לרכישה כמותית',
            button:{
              text: 'ליצירת קשר',
              link: 'contacts',
            }
          },
        ]
      }
    },
    computed:{
      getSubscribe(){
        return new Date(this.$store.getters['user/data'].subscribe*1000) > new Date()
      },
      user(){
      return this.$store.getters['user/data'];
    },
    translatedSchool(){
      return this.$store.getters['user/translatedSchool'];
    },
    school(){
      return this.$store.getters['user/school']
    },
    currentRoute(){
      return this.$router.currentRoute.name;
    }
    },

    methods:{
      open(){
        this.$store.commit('modals/contacts/open');
      },
      async subscribeSched() {
      let successfulPage=window.location.origin+'/#/successfulPayment';
      let errorPage=window.location.origin+'/#/errorPayment';
      const data = await SubscribeAPI.subscribe(successfulPage,errorPage)
          .then(res => res.data);

      this.$modal.show(
          Payment,
          {
            url: data.url,
            id_transaction: data.id_transaction,
            type: 'subs',
            ended: () => {
              this.$store.commit("playlist/reset");
              this.$store.dispatch("user/updateData");
              this.$router.push('/');
              this.$modal.hide(Payment);
            }
          },
          {
            adaptive: true,
            height: "90%",
            width: "90%"
          }
      );
    },
    }
  }
</script>

<style scoped lang="scss">

  .empty-basket {
    position: relative;
    border-radius: 6px;
    padding: 10px 15px 30px 15px;
    margin-top: 20px;
    text-align: right;
    .header{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 30px 0;
      .text{
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #00465B;
      }
      svg{
        margin-left: 10px;
      }
    }
    .links-wrapper{
      .link-element{
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 30px 10px 30px;
        background: #DDF0F5;
        border-radius: 6px;
        .description{
          width: 260px;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #000000;
        }
         .link-b{
          margin-top: 18px;
          padding: 5px 25px;
          background: #FDC671;
          border-radius: 18px;
          font-size: 15px;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
</style>