<template>
  <div
    class="app-loading"
    :class="{
      'size-default': size === 'default',
      'size-big': size === 'big'
    }"
  >
    <LoadingSVG :style="{ top: pos }" v-if="loading" />
    <slot v-else />
  </div>
</template>

<script>
import LoadingSVG from "./LoadingSVG";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    // 50%, 40px
    pos: {
      default: "50%"
    },
    size: {
      default: "default"
    }
  },
  components: {
    LoadingSVG
  }
};
</script>

<style lang="scss" scoped>
.app-loading {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .loading-svg {
    position: absolute;
    left: 50%;
    fill: #333;
    top: 50%;
  }

  &.size-default {
    .loading-svg {
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
    }
  }

  &.size-big {
    .loading-svg {
      width: 40px;
      height: 40px;
      margin-left: -20px;
      margin-top: -20px;
    }
  }
}
</style>
