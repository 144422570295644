<template>
  <div :style="{direction:direction}" class="modal">
    <p >{{$ml.get('רוצה להתנתק')}}?</p>
    <div class="buttons">

      <button @click="$emit('close')" class="theme-button-outline">{{$ml.get('לא')}}</button>
      <button @click="logout" class="theme-button-outline">      {{$ml.get('כן')}}      </button>
    </div>
  </div>
</template>

<script>
import { AuthAPI } from '@/api'
export default {
  methods: {
    logout() {
      AuthAPI.logout().then(()=>{
      this.$store.commit("user/logout");
      this.$store.commit("playlist/reset");
      this.$emit("close");
      this.$router.push({name:'auth-reg'})
      });
    }
  },
  computed:{
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  }
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 10px;
  p {
    margin-top: 0;
  }
  button {
    margin: 0 10px;
    border-color: #eb5757;
    color: #005d7a;
  }
}
</style>
