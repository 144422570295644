import API from '@/api'

const TutorialsStore = {
  namespaced: true,
  state: {
    data: [],
    dataSearch:[],
    loaded: false,
    loadedSearch:false,
  },
  mutations: {
    saveData(state, data) {
      state.data = data;
    },
    saveDataSearch(state, data) {
      state.dataSearch = data;
    },
    load(state) {
      state.loaded = true;
    },
    loadSearch(state){
      state.loadedSearch = true;
    },
    clear(state){
      localStorage.removeItem('tutorials');
      state.data = [];
    },
    clearSearchGame(state){
      localStorage.removeItem('tutorialsSearch');
      state.dataSearch = [];
    },
    setTutorialSearch(state, key) {
      const row = state.data.find(n => n.key == key);
      if(!row) {
        state.data.push({
          key: key,
          value: true
        });
      }
      localStorage.setItem('tutorialsSearch', JSON.stringify(state.data))
    },
    setTutorial(state, key) {
      const row = state.data.find(n => n.key == key);
      if(!row) {
        state.data.push({
          key: key,
          value: true
        });
      }
      localStorage.setItem('tutorials', JSON.stringify(state.data))
    },
  },
  actions: {
    updateData({ commit }) {
      const data = localStorage.getItem('tutorials');
      commit('saveData', data ? JSON.parse(data) : []);
      commit('load');

      const data2 = localStorage.getItem('tutorialsSearch');
      commit('saveDataSearch', data2 ? JSON.parse(data2):[]);
      commit('loadSearch');
    },
  },
  getters: {
    isUsed: state => key => {
      const row = state.data.find(n => n.key == key);
      if(!row) {
        return false;
      } else {
        return !!row.value;
      }
    },
    isUsedSearch: state => key => {
      const row = state.dataSearch.find(n => n.key == key);
      if(!row) {
        return false;
      } else {
        return !!row.value;
      }
    },
    isLoaded(state) {
      return state.loaded;
    },
    isLoadedSearch(state) {
      return state.loadedSearch;
    },
  }
};

export { TutorialsStore }
