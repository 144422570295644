<template>
  <main class="img-slider-bg-index">
    <div class="popup">
      <slot></slot>
    </div>
    <button
      class="theme-button-primary"
      v-if="button && onClick"
      @click="onClick"
    >
      {{ button }}
    </button>
  </main>
</template>

<script>
export default {
  props: { button: String, onClick: Function }
};
</script>

<style lang="scss" scoped>
main {

  display: flex;
  flex-direction: column;
  background-color: #005d7a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.popup {
  display: flex;
  flex-direction: column;
  margin: 31px 13px 23px;
  flex: 1;
  background-color: #56bcdc;
  border-radius: 10px;
}

button {
  margin-left: 23px;
  margin-right: 23px;
  margin-bottom: 33px;
  height: 44px;
  background: #043443;
  border: 1px solid #118188;
}
</style>
