<template>
  <div
    class="text-input app-text-input"
    :class="{
      active: isActiveLabel,
      'styling-default': styling === 'default',
      'styling-inline-inverse': styling === 'inline-inverse',
      disabled: disabled
    }"
  >
    <input-label :active="isActiveLabel">
      {{ label }}
    </input-label>
    <div v-if="hint" class="hint">
      {{ hint }}
    </div>
    <div v-if="infoTip" class="tip-container">
      <info-tip size="18" :title="infoTip" :position="'right'" />
    </div>
    <input
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :required="required"
      :maxlength="maxlength"
      :minlength="minlength"
      :min="min"
      :max="max"
      :disabled="disabled"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>
import InputLabel from "./InputLabel";

export default {
  name: "text-input",
  components: {
    InputLabel
  },
  props: {
    value: {
      required: true,
      default: ""
    },
    infoTip: {
      required: false,
      default: false
    },
    label: {},
    placeholder: {},
    autocomplete: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxlength: {},
    minlength: {},
    type: {
      default: "text"
    },
    hint: {},
    min: {},
    max: {},
    disabled: {
      required: false,
      default: false
    },
    styling: {
      default: "default"
    }
  },
  data() {
    return {
      focused: false
    };
  },
  computed: {
    isActiveLabel() {
      const is_valid = this.value !== null;
      return (is_valid && this.value.toString().length) || this.focused
        ? true
        : false;
    }
  },
  methods: {
    onInput(e) {
      let val = e.target.value;

      if (this.type === "number") {
        if (this.min && parseFloat(this.min) > parseFloat(val)) {
          val = this.min;
          e.target.value = val;
        }
        if (this.max && parseFloat(this.max) < parseFloat(val)) {
          val = this.max;
          e.target.value = val;
        }
      }

      this.$emit("input", val);
    },
    onBlur() {
      this.focused = false;
    },
    onFocus() {
      this.focused = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-text-input {
  width: 100%;
  font-size: 14px;
  height: 42px;
  position: relative;
  padding: 1px;

  .hint {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 0;
    font-size: 14px;
    pointer-events: none;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    outline: none;
    font-size: 16px;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      text-transform: capitalize;
      font-size: 18px;
      opacity: 0.65;
      color: #333;
    }
  }

  .tip-container {
    position: absolute;
    right: 5px;
    bottom: calc(100% + 5px);
  }

  &.styling-default {
    input {
      border: 1px solid #005d7a;
      border-radius: 7px;
      border-style: solid;
      background-color: #fff;
      color: #005d7a;
      box-shadow: 0 0 0 transparent;
      transition: box-shadow 0.1s ease;

      &:focus {
        // box-shadow: 0 0 0 4px rgba($color: #6f9c40, $alpha: 0.2);
      }
    }

    &.disabled {
      border-color: #999;
      input {
        color: #999;
        border-color: #e0e0e0;
      }
    }

    &.active {
      .hint {
        color: #6f9c40;
      }
    }
  }

  &.styling-inline-inverse {
    padding: 0 5px;
    border-bottom: 1px solid rgba(#fff, 0.3);

    input {
      border: 0;
      color: #fff;
      background: transparent;
      padding: 0;
    }

    .input-label {
      color: #eee;
    }
  }

  &.disabled {
    border-color: #999;
    input {
      color: #999;
      border-color: #e0e0e0;
    }
  }
}
</style>
