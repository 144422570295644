<template>
  <div class="faq-element">
    <button class="show-b" @click="flag=!flag">
      <span      class="icon">
        <svg :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div class="terms">
      </div>

      <button @click="flag=!flag" > {{$ml.get('שעת חינוך')}}
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-0.000453115 4.69235L4.24219 8.93499V0.449707L-0.000453115 4.69235Z" fill="#06364B"/>
        </svg>
      </button>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >

        <div class="items">
              <div class="item-w"
                   v-if="!!$store.getters['playlist-gifts/getById'](''+item.id_gift)"
                   :key="index*33"
                   v-for="(item, index) in giftsA "
              >
            <div class="item"

            >
              <CheckboxWrapper

                :style="item.preferable  ?
               'border: 1px solid #AAD9E7;' : 'opacity:0.6;border: 1px solid #C4C4C4;' "
                :class="[ item.preferable  ?
               'active' : 'unactive' ]"
                :disable="true"
              >
                <template v-slot >
                  <div
                    @click="item.preferable=!item.preferable"
                    class="checkbox-slot">
                    <img :src="$store.getters['playlist-gifts/getById'](''+item.id_gift).img_url" alt="">
                    <div class="title">{{$store.getters['playlist-gifts/getById'](''+item.id_gift).name}}</div>
                  </div>
                </template>
              </CheckboxWrapper>
            </div>

        </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import CheckboxWrapper from "@/components/CheckboxWrapper";
  import {  GiftsAPI } from '@/api';
  import {mapGetters} from "vuex";

  export default {
    props:{
      report:{
        title:{},
        games: {},
      },
      goal:{},
    },
    components:{
      CheckboxWrapper,
    },
    computed: {
      ...mapGetters({
        sections: 'playlist-sections/data',
        user: 'user/data',
        giftsA: 'director-targets/gifts'
      }),

    },

    created() {
      this.loading.sync = true;
    },
    data() {
      return {
        flag: false,
        loading: {
          save: false,
          sync: false,
        },
        gifts: [],
        selected: [],
      }
    },
  }
</script>

<style lang="scss" scoped>
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .faq-element{


    position: relative;
    margin: 2px 0;



    transition: all .4s ease-in-out;
    color: #828282;
    .question{
      background: #AAD9E7;
      padding: 15px 41px 15px 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #06364B;
      .terms{
        left: 5px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #00465B;
        .term{
          margin: 0 10px;
          width: 77px;

        }
      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        width: 88%;
        text-align: start;
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: 9px;
      top: 5px;
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      padding: 12px 0 25px 0;
      background: #F2F2F2;
      transition: all 0.4s ease-in-out;
      .footer{
        padding: 10px 20px 0 0;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name{
          font-weight: bold;
          font-size: 14px;
          text-align: right;
          color: #00465B;
        }
        .info{
          display: flex;
          margin-left: 20px;
        }
      }
        .text{
          width: 180px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

        }
      }
    }
  }
  .item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
    button{
      display: flex;
      align-items: center;
      .name {
        font-size: 12px;
        color: #149CC6;
      }
      .color {
        margin-left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
    }
  }
  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 340px;
    margin: 0 auto;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-bottom: 53px;
    @media screen and (max-width: 340px) {
      width: 100%;
    }
    .item-w{

    }
    .item {
      background: white;
      margin: 5px 7px;
      border-radius: 4px;
      width: 70px;
      height: 80px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @media screen and (max-width: 340px) {
        margin: 5px 3px;
      }
      .checkbox-wrapper{

        position: relative;
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        color: #06364B;
        border-radius: 4px;
        .title{
          font-size: 12px;
          line-height: 1;
          margin-bottom: 5px;
        }
        .played {
          line-height: 1;
          font-size: 10px;
        }
        .checkbox-slot {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 4;
          width: 100%;
          height: 100%;
        }
        &.active{
          &:after{
            position: absolute;
            content: '';

            background: white;
            border: 1px solid #669EAF;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            top: 3px;
            right: 5px;
            z-index: 1;
          }
          &:before{
            position: absolute;
            content: '';
            height: 3px;
            z-index: 2;
            width: 6px;
            border-left: 1px solid #00465B;
            border-bottom: 1px solid #00465B;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 7px;
            right: 8px;
          }
        }
        &.unactive{
          &:after{
            top: 3px;
            right: 5px;
            position: absolute;
            content: '';
            background: #FFFFFF;
            border: 1px solid #669EAF;
            width: 12px;
            height: 12px;
            border-radius: 50%;

          }
        }
      }
      img {
        min-height: 30px;
        height: 30px;
        align-self: center;
        margin-bottom: 10px;
      }

      span {
        font-size: small;
        text-align: center;
        line-height: 1.2;
        padding: 0 10px;
      }

      .dot {
        top: 4px;
        left: 4px;
        z-index: 1;
        position: absolute;
        display: flex;
        align-self: flex-end;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        min-height: 10px;

      }
    }
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

