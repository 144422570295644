import { render, staticRenderFns } from "./TeacherTutorial.vue?vue&type=template&id=621548eb&scoped=true&lang=html&"
import script from "./TeacherTutorial.vue?vue&type=script&lang=js&"
export * from "./TeacherTutorial.vue?vue&type=script&lang=js&"
import style0 from "./TeacherTutorial.vue?vue&type=style&index=0&id=621548eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621548eb",
  null
  
)

export default component.exports