<template>
 <button @click="goGame" class="game-element">
    <div class="img-w">
      <img :src="game.img" alt="">
    </div>
   <div class="footer">
     <div class="name">{{translated.data.name? translated.data.name : game.translation[0].data.name}}</div>
     <div class="total"><span style="font-size: 11px">{{game.played}}</span> <span class="gray">{{$ml.get('פעמים שוחק')}}</span>  </div>
   </div>
 </button>
</template>

<script>
export default {
  props: {
    game: {}
  },
  methods:{
    goGame(){
      this.$router.push({name: 'targeted-playlist', params: { id_playlist: this.game.id_playlist }});
    }
  },
  computed:{
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.game.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
        })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  //
 .game-element{
   width: 84px;
   height: 115px;
   padding: 5px;
   margin: 5px;
   background: #FFFFFF;
   border-radius: 4px;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
   .img-w{
     width: 54px;
     height: 54px;
     display: flex;
     align-items: center;
     justify-content: center;
     img{
       max-width: 54px;
       max-height: 54px;
     }
   }
   .footer{
     margin-top: 5px;
     height: 50%;
     display: flex;

     flex-direction: column;
     justify-content: space-between;
     .name{
       font-family: 'Cool';
       font-size: 17px;
       line-height: 15px;
       text-align: right;
       color: #00465B;
     }
     .total{
       font-weight: normal;
       font-size: 10px;
       line-height: 14px;
       text-align: right;
       color: #4D4D4D;
       position: absolute;
       bottom: 1px;
       right: 4px;
       .gray{
         color: #4D4D4D;
       }
     }
   }
 }
</style>