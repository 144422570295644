<template lang="html">
  <transition name="fade">
    <modal v-if="$store.getters['modals/teacher-tutorial/isOpened']" @close="close">
      <div :style="{direction:direction}" class="th-modal container">
        <button class="close-b" @click="close">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="5.48832" y1="4.51266" x2="14.6807" y2="13.705" stroke="black"/>
            <line x1="14.6797" y1="4.67875" x2="5.48734" y2="13.8711" stroke="black"/>
          </svg>
        </button>

        <div class="title">{{$ml.get('תורני משחקים')}}</div>
        <div class="list">
          <div class="li-w">
            <div class="number">1</div>
            <div  class="li">


               <span>{{$ml.get('בתחילת כל שבוע שני תורני משחקים עומדים מול')}}</span>
              <span>{{$ml.get('הכיתה ומציגים 3 משחקים שהם בחרו מפלייליסט')}}</span></div>
          </div>
          <div class="li-w">
            <div class="number">2</div>

            <div class="li">{{$ml.get('המשחקים מוקרנים בכיתה מאתר פלייליסט')}}</div>
          </div>
          <div class="li-w">
            <div class="number">3</div>
            <div class="li">{{$ml.get('תורני המשחקים מעודדים בהפסקות לשחק במשחקי השבוע עם פנקס לרישום אלו שמשחקים')}}</div>
          </div>
          <div class="li-w">
            <div class="number">4</div>
            <div class="li">{{$ml.get('בכל חודש נערכת בכיתה הצבעה לדירוג המשחקים')}}</div>
          </div>
          <div class="li-w">
            <div class="number">5</div>
            <div class="li">{{$ml.get('תורני משחקים מתחלפים בכל שבוע')}}</div>
          </div>
        </div>
        <div class="description">
          <div class="span">


              <span>{{$ml.get('הפעלת מערך תורני המשחקים בקביעות')}}</span> <br>
              <span>{{$ml.get('חשובה להטמעת התוכנית')}}!</span>
          </div>
        </div>

        <app-btn   @click="close" class="save-b">{{$ml.get('הבנתי')}}</app-btn>

      </div>
    </modal>
  </transition>
</template>

<script>
import Modal from './Modal'
import ModalClose from './ModalClose'

export default {
  components: {
    Modal,
    ModalClose,
  },
  methods: {
    close() {
      this.$store.commit('modals/teacher-tutorial/close');
    }
  },
  computed:{
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  }
}
</script>

<style lang="scss" scoped>

.modal {
  &.fade-anim {
    &-enter {
      .container {
        transform: translateY(-50px);
      }
    }
    &-enter-to {
      .container {
        transform: translateY(0);
      }
    }

    &-leave {
      .container {
        transform: translateY(0);
      }
    }
    &-leave-to {
      .container {
        transform: translateY(-50px);
      }
    }

    &-leave-active,
    &-enter-active {
      .container {
        transition: transform 0.3s ease;
      }
    }
  }
}

.container {
  position: relative;
  width: 335px;
  padding: 25px 20px;
  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;
  border-radius: 10px;
  .close-b{
    position: absolute;
    left: 0;
    top: 0;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #06364B;
  }
  .description{
    padding: 8px 0;
    margin: 15px auto;
    /*border-top: 1px solid #A9DDF3;*/
    /*border-bottom: 1px solid #A9DDF3;*/
    .span{
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      color: #00465B;
    }
   }
 .list{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   .li-w{
     margin: 10px 0;
     display: flex;
     flex-direction: row;
     align-items: baseline;
     justify-content: center;
     .li{
       width: 272px;
       font-style: normal;
       font-weight: normal;
       font-size: 14px;
       text-align: right;
       color: #000000;
     }
     .number{
       margin-left: 11px;
       width: 18px;
       height: 18px;
       background: #A0CCDA;
       border-radius: 50%;
       display: flex;
       align-items: center;
       justify-content: center;
       color: #FFFFFF;
       font-weight: normal;
       font-size: 12px;
       flex-shrink: 0;
     }
   }
 }
  .save-b{
    margin: 0 auto;
    margin-top: 23px;
    font-size: 15px;
    width: 180px;
    height: 36px;
  }
}

</style>
