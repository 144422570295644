<template>
  <main dir="rtl" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{$ml.get("ערכות משחקים")}}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading style="background: white" class="sm-cont">
      <template class="basket">
        <div v-if="$store.getters['funkit/data'].length>0" class="full-basket">
          <div class="items-wrapper">
            <FunkitBasketElement
              @update="total"
              :item="item"
              v-for="item in $store.getters['funkit/data']" />
            <div class="info">

              <div class="sent">
                {{$ml.get("משלוח: חינם")}}
              </div>
              <div :key="update" class="total">
                {{$ml.get("סה”כ לתשלום")}}: {{sum}}  {{$ml.get("ש”ח")}}
              </div>
            </div>
          </div>
          <div class="footer">

            <btn @click="goBuy" class="purchase-b">{{$ml.get("לרכישה")}}</btn>
          </div>
        </div>
        <div v-else class="empty">
          <EmptyBasket />
        </div>
      </template>
    </app-loading>
  </main>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import FunkitBasketElement from "@/components/funkit/FunkitBasketElement";
  import Btn from "@/components/ui/app-btn/AppBtn";
  import EmptyBasket from "@/components/funkit/EmptyBasket";
  export default {
  data() {
    return {
      data: [],
      update: '',
      sum : 0,
    };
  },
    computed:{
    },
    methods:{
     total(data){
       this.update= data;
         this.sum =  this.$store.getters['funkit/data'].reduce((sum, current)=> {
           return (sum + (current.price*current.count));
         }, 0);
     },
      goBuy(){
       this.$router.push({name: 'funkit-delivery', params:{type: 'multiple'}})
      }
    },
    created() {
      this.total(234);
    },
    components: {
    Btn,
    SimpleHeader,
    SidebarToggler,
    FunkitBasketElement,
    EmptyBasket
  },

};
</script>

<style lang="scss" scoped>
.basket{
  background: #FFFFFF;

}
.items-wrapper{
  .info{
    margin-top: 14px;
    .sent{
      font-size: 12px;
      line-height: 16px;
      text-align: right;

      color: #787878;
    }
  }
}
.footer{
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .purchase-b{
    margin: 10px auto;
    background: #FDC671;
    border-radius: 18px;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>
