<template>
  <div class="add-your-game">
    <div class="top-block">
      <div class="free-activity">
        <button class="show-b" @click="flag=!flag">
      <span class="icon">
        <svg width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.6 9.8L1 6.2M12.2 6.2V1M21.4 9.8L25 6.2" stroke="#FBB03B" stroke-linecap="round"/>
          <path
          d="M10.4996 31.4763C10.4996 32.0169 10.8461 32.4031 11.4235 32.4031C12.2704 32.4031 13.0787 32.4031 13.9256 32.4031C14.3106 32.4031 14.6185 32.21 14.734 31.8239C14.1951 31.8239 13.6562 31.8239 13.1172 31.8239C13.0018 31.8239 12.9248 31.8239 12.8093 31.7852C12.6168 31.7466 12.5013 31.5922 12.5013 31.3605C12.5013 31.1674 12.6168 31.0129 12.8093 30.9743C12.8863 30.9357 13.0018 30.9357 13.1172 30.9357C13.6562 30.9357 14.2336 30.9357 14.811 30.9357L14.811 30.2793C14.2336 30.2793 13.6946 30.2793 13.1557 30.2793C13.0402 30.2793 12.9633 30.2793 12.8478 30.2793C12.6553 30.2406 12.5013 30.0862 12.5013 29.8931C12.5013 29.7 12.6168 29.507 12.8093 29.4683C12.8863 29.4297 13.0018 29.4297 13.0787 29.4297C13.6562 29.4297 14.1951 29.4297 14.811 29.4297L14.811 28.8505C13.3867 29.2367 11.9624 29.2753 10.4996 28.8891L10.4996 29.3525C10.4996 30.0862 10.4996 30.7813 10.4996 31.4763ZM12.3089 33.8705C12.5013 34.0249 12.7323 34.0249 12.9633 33.8705C13.1942 33.7546 13.3097 33.5229 13.2712 33.2526L12.0009 33.2526C12.0009 33.5229 12.0779 33.716 12.3089 33.8705ZM12.7323 28.8119C13.7331 28.8119 14.7725 28.696 15.7348 28.5416C15.6963 28.5802 15.6578 28.6574 15.6578 28.7347C15.6578 29.6228 15.6578 30.511 15.6578 31.3991C15.6578 31.9783 15.4654 32.4803 15.0419 32.8279C14.7725 33.0209 14.4645 33.1368 14.1566 33.3298C14.1181 33.5229 14.0796 33.7546 13.9641 33.9863C13.7716 34.4883 13.3482 34.72 12.8093 34.8745L12.3858 34.8745C11.8854 34.7586 11.462 34.4883 11.2695 34.0249C11.154 33.7932 11.154 33.5615 11.077 33.2912C11.0386 33.2912 11.0001 33.2912 10.9616 33.2526C10.1532 33.0595 9.65277 32.4803 9.61427 31.6308C9.57578 30.704 9.57578 29.8159 9.61427 28.8891C9.61427 28.7347 9.57578 28.6188 9.4218 28.5802C9.34481 28.5416 9.26783 28.503 9.15234 28.4644C10.3072 28.6574 11.5005 28.8119 12.7323 28.8119Z"
          fill="#FBB03B"/>
          <path
          d="M4.86301 16.4147L12.5618 19.5812C12.7928 19.697 12.9468 19.9287 12.9468 20.1604C12.9468 20.4307 12.7928 20.6624 12.5618 20.7396L5.13247 23.7903C6.55675 26.4161 9.32832 28.0766 12.3309 28.0766C16.8732 28.0766 20.5301 24.3695 20.5301 19.8515C20.5301 15.2948 16.8347 11.6264 12.3309 11.6264C9.09736 11.6264 6.17181 13.5185 4.86301 16.4147ZM3.43873 16.5305C4.82452 12.8234 8.36597 10.3521 12.3309 10.3521C17.5661 10.3521 21.8004 14.5998 21.8004 19.8515C21.8004 25.1032 17.5661 29.3509 12.3309 29.3509C8.59694 29.3509 5.20946 27.1498 3.6697 23.7516C3.59271 23.5972 3.59271 23.4041 3.6697 23.2496C3.74668 23.0952 3.86217 22.9793 4.01614 22.9021L10.6371 20.199L3.78518 17.3415C3.47723 17.2256 3.32325 16.8781 3.43873 16.5305Z"
          fill="#FBB03B"/>
          <path
           d="M14.0309 17.8367C13.7309 17.6633 13.6325 17.2944 13.8061 16.9934C14.0761 16.5252 13.9216 15.9454 13.4549 15.6757C12.9882 15.4059 12.4093 15.5619 12.1393 16.03C11.9657 16.331 11.5973 16.4302 11.2973 16.2568C10.9973 16.0834 10.8989 15.7145 11.0725 15.4135C11.6897 14.3434 13.0386 14.0083 14.0721 14.6056C15.1389 15.2221 15.4708 16.5733 14.8729 17.61C14.6993 17.9109 14.3309 18.0101 14.0309 17.8367Z"
           fill="#FBB03B"/>
        </svg>

        <span>המשגה</span>

        <svg v-if="currentItem.teachers_guide" :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14"
        viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 6L6.65685 11.6569L12.3137 6" stroke="white" stroke-width="2"/>
        </svg>

     </span>
        </button>
        <div class="question">
          <button @click="back()" class="terms">
            {{$ml.get("חזרה למערך השיעור")}}
          </button>

          <button @click="flag=!flag">{{$ml.get("פעילות חופשית")}}</button>
        </div>
        <transition name="fade-move-left">
          <div class="answer" v-if="flag && currentItem.teachers_guide">
            {{currentItem.teachers_guide}}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentItem: 'playlist/currentItem',
    }),
  },
  data() {
    return {
      flag: true,
    }
  },
  mounted() {
  },
  methods:{
    back(){
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>

.add-your-game {
  display: flex;
  flex-direction: column;
  align-items: center;

  .top-block {
    background: #06364B;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}

.isActive {
  font-size: 14px;
  opacity: 1;
  margin-top: 17px;
  display: block;
  transition: all .4s ease-in-out;
}

.unActive {
  transition: all .4s ease-in-out;
  font-size: 0 !important;
  opacity: 0;
  margin-top: 0 !important;
  display: none;
}

.free-activity {


  position: relative;
  margin: 2px 0;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
  transition: all .4s ease-in-out;
  color: #828282;

  .question {
    transition: all 0.4s ease-in-out;
    width: 440px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }

    padding: 15px 0 15px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #06364B;

    .terms {
      @media screen and (max-width: 480px) {
        left: 30px;
      }
      left: 0;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 15px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: right;
      color: #FFFFFF;
      max-width: 145px;
    }

    button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      width: 88%;

      text-align: start;
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
    }
  }

  .show-b {
    @media screen and (max-width: 480px) {
      right: 30px;
    }
    cursor: pointer;
    position: absolute;
    right: 0;
    padding: 3px;
    top: 5px;

    .icon {
      span {
        margin: 0 5px;
        font-size: 18px;
        text-align: right;
        color: #FBB03B;
      }
    }

    span {
      svg {
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }
    }
  }

  .answer {
    padding: 10px 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 117.8%;
    text-align: right;
    color: #FFFFFF;
    width: 440px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    transition: all 0.4s ease-in-out;

  }
}
</style>