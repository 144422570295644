<template>
  <div class="goal-element">
      <div class="title">{{title}}</div>
      <div class="info">
        <div class="arrs">
           <span class="name"><span> {{$ml.get('משחקים שנשלחו')}}: </span>
            <span>{{playlists.length}}</span>
          </span>

           <a :key="i*2"
             v-for="(el,i) in playlists"
             :href="el.link"><NameTranslated style="color: #3FBCD4;" :translate="el.translation" />
            <span v-if="i!==playlists.length"> ,</span>
          </a>

        </div>
        <div v-if="total" class="name"><span> {{$ml.get('משחקים ששוחקו בבית')}}: </span><span>{{total}}</span></div>

      </div>
  </div>
</template>
<script>
  import NameTranslated from "../../NameTranslated";
  export default {
    props:{
      title:{},
      total:{},
      goal:{

        name: {},
        arr: {},
      },
      playlists: {},
    },
    computed:{
      translated(){
        if (this.$store.getters['lang-store/data']){
          return this.translate.find(item=>{
            if(this.$store.getters['lang-store/data']=='eng')
            {
              return item.langKey === "en"
            }
            else
            {
            return item.langKey === this.$store.getters['lang-store/data']
            }
          }).data.name
        }
      },
    },
    components:{
      NameTranslated,
    },
    data() {
      return {
        arr: [
          {name: ' השגריר', link: 'google.com'},
          {name: ' גולף עפרון', link: 'google.com'}
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
 .goal-element{
   padding: 10px 20px 10px 20px;
  .title{
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: #06364B;
  }
   .info{
     margin-top: 10px;
     display: flex;
     align-items: flex-start;

     flex-direction: column;
     .name{
       margin-left: 7px;
       font-weight: normal;
       font-size: 14px;
       text-align: right;
       color: #4D4D4D;
     }
     .arrs{


       display: flex;
       align-items: center;
       flex-wrap: wrap;
        a{
          font-weight: normal;
          font-size: 14px;
          color: #3FBCD4;
        }
     }
   }
 }
</style>

