import alert from './alert.store'
import teacherT from './teacher-tutorial.store'
import targetSave from './target-save.store'
import contacts from './contacts.store'
import teacherPlaylistFirst from './teacher-playlist-first.store'
import error from './error.store'
import confirm from './confirm.store'
import deleteLiked from './deleteLiked.store'
import contactUS from './contactUs.store'
import inventedGames from './inventedGames.store'
export default {
  namespaced: true,
  modules: {
    'alert': alert,
    'teacher-tutorial': teacherT,
    'target-save' : targetSave,
    'contacts' : contacts,
    'teacher-playlist-first' : teacherPlaylistFirst,
    'error': error,
    'confirm' : confirm,
    'deleteLiked' : deleteLiked,
    'contact-us' : contactUS,
    'inventedGames' : inventedGames,
  },
  state: {

  },
  mutations: {

  },
  actions: {
    closeAll(context) {
    }
  },
  getters: {

  }
};
