<template>
  <div :style="{background: '#f2f2f2'}" class="gifts-wrapper">
  <div class="header">
    <div class="title" style="position: relative;">
      {{translated!=='' ? translated : this.translate[0].data.name }}
       <div
                class="dot"
                :style="{
                backgroundColor: `${$store.getters['playlist-sections/getColorById'](section.id_section)}`
              }"
              ></div>
    </div>
    <div class="options">
      <button :style="giftsLength!=allSection ? 'opacity:1' : 'opacity:0.5'" class="option all" @click="$emit('select')" >{{$ml.get('בחר הכל')}}</button>
      <button :style="allSection===0? 'opacity:0.5' : 'opacity:1'" class="option none" @click="$emit('clear')" >{{$ml.get('נקה הכל')}}</button>
    </div>
  </div>
    <div class="gifts-list">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props:{
    bgColor: {},
    allSection:{},
    giftsLength:{},
    title : {},
    section:{},
  },
  components:{

  },
  computed:{
    translated(){
      if (this.$store.getters['lang-store/data']){
        return this.section.translation.find(item=>{
          if(this.$store.getters['lang-store/data']=='eng')
          {
            return item.langKey === "en"
          }
          else
          {
          return item.langKey === this.$store.getters['lang-store/data']
          }
        }).data.name
      }
    },
    giftsCount(){
      return this.$store.getters['playlist-gifts/getBySectionId'](this.section.id_section)
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.dot {
        top: 4px;
        right: -14px;
        z-index: 1;
        position: absolute;
        display: flex;
        align-self: flex-end;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        min-height: 10px;

      }
  .gifts-wrapper{
    width: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .header{
      padding: 0 10px;
      padding-top: 5px;
      width: 100%;
      max-width: 360px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .options{
        display: flex;
        flex-direction: row;
        align-items: center;
        .option{
          padding: 0;
          padding-left: 14px;

          font-family: 'App';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          /* identical to box height, or 17px */

          text-align: right;

          color: #06364B;

          &:last-child {
            padding: 0;
          }
        }
        .all{
          position: relative;
          &:after{
            position: absolute;
            content: '';
            width: 1px;
            height: 10px;
            background: #A2A2A2;
            left: 7px;
            top: 2px;
          }
        }
      }
      .title{
        margin-right: 9px;
        font-family: 'App';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-align: right;

        color: #06364B;

      }
    }
    .gifts-list{
      max-width: 360px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 5px 0;
      @media screen and (max-width: 372px){
        justify-content: center;
      }
    }
  }
</style>
