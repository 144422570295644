<template>
  <div :style="{background: bgColor}" class="teacher-profile">
      <router-link
        :style="{color: textColor}"
        :to="{name: route}"  >
        <slot />
      </router-link>
  </div>
</template>

<script>
export default {
  name: "TeacherProfile",
  props:{
    route:{
      default: 'main'
    },
    bgColor:{
      default: '#06364B',
    },
    textColor:{
      default: ' #FFFFFF',
    }
  }
}
</script>

<style scoped lang="scss">
  //
.teacher-profile {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 auto;
    font-weight: bold;
    font-size: 22px;
    text-align: right;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>