<template>
  <main dir="rtl" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
         {{$ml.get("ערכות משחקים")}}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading style="background: white"  :loading="load" class="sm-cont">
      <template >
        <div class="top-desc">
          <h2>{{$ml.get("ערכות קלפים למשחקים מחוץ למסך")}}</h2>
          <h4>{{$ml.get("משחקים יצירתיים ופשוטים שאינם דורשים ציוד")}}</h4>
          <a target="_blank" href="https://www.youtube.com/watch?v=1OAbHAT-BcE&feature=youtu.be" class="yt-link">

              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0175 3.4813C20.9186 2.673 20.6279 1.90005 20.1696 1.22694C19.9032 0.92332 19.577 0.677997 19.2114 0.506348C18.8458 0.334699 18.4486 0.240424 18.0449 0.22943C15.0524 3.3444e-06 10.6135 0 10.6135 0C10.6135 0 6.15462 3.3444e-06 3.18205 0.22943C2.7783 0.240424 2.38117 0.334699 2.01555 0.506348C1.64994 0.677997 1.32372 0.92332 1.05735 1.22694C0.599389 1.90023 0.308713 2.67309 0.209475 3.4813C0.0783409 4.6973 0.00842814 5.91913 0 7.14215V8.85786C0.00807652 10.0842 0.0779898 11.3094 0.209475 12.5287C0.309502 13.3336 0.600178 14.1031 1.05735 14.7731C1.68408 15.3906 2.52194 15.7472 3.4015 15.7706C5.09726 15.9402 10.6234 16 10.6234 16C10.6234 16 15.0823 16 18.0549 15.7606C18.458 15.7461 18.854 15.6503 19.2191 15.4789C19.5842 15.3074 19.9109 15.064 20.1796 14.7631C20.6364 14.0929 20.927 13.3235 21.0274 12.5187C21.1589 11.2994 21.2288 10.0742 21.2369 8.84788V7.13217C21.2248 5.91231 21.1516 4.69383 21.0175 3.4813Z"
                  fill="#FF5C5C"/>
                <path d="M7.96094 4.0293V11.9894L14.5943 8.00935L7.96094 4.0293Z" fill="white"/>
              </svg>
            {{$ml.get("לצפייה בכתבה")}}

          </a>
        </div>
        <section class="sm-cont">
          <div class="cards-desc">
            <div class="info">

              <h3>{{$ml.get("מחירון")}}</h3>

              <div class="info-el">1  {{$ml.get("ערכה")}} - 100  {{$ml.get("ש”ח")}}</div>
              <div class="info-el">3  {{$ml.get("ערכה")}} - 260 {{$ml.get("ש”ח")}}</div>
              <div class="info-el">5  {{$ml.get("ערכה")}} - 390 {{$ml.get("ש”ח")}}</div>

              <h3>{{$ml.get("משלוח חינם")}}!</h3>
            </div>
            <div class="footer">
                <div class="txt"> {{$ml.get("לרכישה גדולה פנו אל")}} </div><div class="mail">efi.academia@gmail.com </div>
            </div>
            <img src="@/assets/img/funkit-card.png" alt=""/>
          </div>

          <div class="cards">
            <FunkitCard :item="item"  :key="item.id_funkit"  :style="{ backgroundColor: item.color }"   v-for="item in data" />
          </div>
        </section>
        <FunkitBasket v-if="$store.getters['funkit/data'].length>0" class="shopping-basket" />
      </template>
    </app-loading>
  </main>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
  import FunkitCard from "@/components/funkit/FunkitCard";
  import FunkitBasket from "@/components/funkit/FunkitBasketFixed";
  export default {
  data() {
    return {
      data: [],
      load: false,
    };
  },
  components: {
    SimpleHeader,
    SidebarToggler,
    FunkitCard,
    FunkitBasket,
  },
  created() {
    this.load = true;
    axios.get("/funkit/select").then(res => {
      res.data = res.data.map(n => {
        if (n.text) {
          n.text = n.text.replace(/\n/g, "<br>");
        }

        return n;
      });
      this.data = res.data;
      this.load = false;
    });
  }
};
</script>

<style lang="scss" scoped>
main {
  background: #FFFFFF;
  .top-desc {
    padding: 40px 0 68px 0;
    display: flex;
    flex-direction: column;
    h2{
      font-weight: bold;
      font-size: 26px;
      line-height: 23px;
      text-align: center;
      color: #FF7384;
    }
    h4{
      margin-top: 0;
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #005D7A;
    }
    .yt-link{
      margin: 0 auto;

      width: 145px;
      background: #FFFFFF;
      border: 1px solid #3FBCD4;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 5px 10px;
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #149CC6;
      a{

        svg{
          margin-left: 5px;
        }
        background: #FFFFFF;
        border: 1px solid #3FBCD4;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 5px 10px;
        font-style: italic;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #149CC6;
      }
    }

  }
  .cards-desc {
    position: relative;
    background: #005D7A;
    padding: 15px;
    color: #FFFFFF;
    .info{
      h3{
        font-weight: bold;
        font-size: 24px;
        line-height: 21px;
        text-align: right;
        color: #FFFFFF;
      }
      .info-el{
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #FFFFFF;
      }
    }
    .footer{
      display: flex;
      align-items: center;
      .txt{
        margin-left: 6px;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #FFFFFF;
      }
      .mail{
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: #FFFFFF;
      }
    }
    img{
      position: absolute;
      top: -100px;
      left: -20px;
      @media screen and (max-width: 360px){
        top: -73px;
        left: -10px;
        width: 60%;
      }
    }
    }

  .cards {
    padding: 50px 10px 60px 10px;
    background: #DDF0F5;
  }
}
.shopping-basket{
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
