<template>
  <div class="faq-element">
    <button class="show-b" @click="flag=!flag">
      <span      class="icon">
        <svg :style="!flag? '    transform: rotate(0)' : '    transform: rotate(180deg)'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.6875 6L7.34435 11.6569L13.0012 6" stroke="black" stroke-width="2"/>
        </svg>
     </span>
    </button>
    <div class="question">
      <div class="terms">

        <div
          :class="{widt: direction==='ltr'}"
          :style="!flag ?'color:#AAD9E7' : ''"
          class="term">{{$ml.get('לעומת חודש שעבר')}}
        </div>
        <div
          :class="{widt: direction==='ltr'}"
          :style="!flag ?'color:#AAD9E7' : ''"
          class="term">{{$ml.get('לעומת שנה שעברה')}}
        </div>
      </div>
      <button @click="flag=!flag" >{{$ml.get('שעת חינוך')}}
      </button>
      <button @click="swipe" style="width: 30px;">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-0.000453115 4.69235L4.24219 8.93499V0.449707L-0.000453115 4.69235Z" fill="#06364B"/>
        </svg>
      </button>
    </div>
    <transition name="fade-move-left">
      <div class="answer" v-if="flag" >
        <ReportStatusSolo :title="$ml.get('משחקים בכיף') + ':'" :data="goal" />

        <div class="footer">
         <div class="title">
           <div class="name">{{$ml.get('הערכים שהוגדרו כיעדים')}}:</div>
           <div class="info">


             <div class="done"><EducationalValue :value="$ml.get('בוצע')" :status="true" /></div>
             <div class="cancel"><EducationalValue :value="$ml.get('לא בוצע')" :status="false" /></div>
           </div>
         </div>
          <div class="text">
            <EducationalValue value=" יוזמה נתינה" :status="true" />
            <EducationalValue value="בוצע" :status="true" />
            <EducationalValue value="בוצע" :status="true" />
            <EducationalValue value="בוצע" :status="true" />
            <EducationalValue value="בוצע" :status="false" />
            <EducationalValue value="בוצע" :status="false" />
            <EducationalValue value="בוצע" :status="false" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import ReportStatusSolo from "../ReportStatusSolo";
  import EducationalValue from "../EducationalValue";
  export default {
    props:{
      report:{
        title:{},
        games: {},
      },
      goal:{},
    },
    computed:{
      direction(){
        return this.$store.getters['lang-store/direction']
      }
    },
    components:{
      ReportStatusSolo,
      EducationalValue,
    },
    data() {
      return {
        flag: false,
      }
    },
    methods:{
      swipe(){
        this.$emit('swipe', 'education');
      }
    },
  }
</script>

<style lang="scss" scoped>
  .widt{
    width: 83px!important;
  }
  .isActive{
    font-size: 14px;
    opacity: 1;
    margin-top: 17px;
    display: block;
    transition: all .4s ease-in-out;
  }
  .unActive{
    transition: all .4s ease-in-out;
    font-size: 0!important;
    opacity: 0;
    margin-top: 0!important;
    display: none;
  }
  .faq-element{


    position: relative;
    margin: 2px 0;



    transition: all .4s ease-in-out;
    color: #828282;
    .question{
      background: #AAD9E7;
      padding: 15px 41px 15px 22px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #06364B;
      display: flex;
      .terms{
        left: 5px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #00465B;
        .term{
          margin: 0 10px;
          width: 77px;

        }
      }
      button{
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        text-align: start;
        @media screen and (max-width: 600px){
          font-size: 15px;
        }
      }
    }
    .show-b{
      cursor: pointer;
      position: absolute;
      right: 10px;
      padding: 9px;
      top: 5px;
      span{
        svg{
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .answer{
      padding: 12px 0 25px 0;
      background: #F2F2F2;
      transition: all 0.4s ease-in-out;
      .footer{
        padding: 10px 20px 0 0;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name{
          font-weight: bold;
          font-size: 14px;
          text-align: right;
          color: #00465B;
        }
        .info{
          display: flex;
          margin-left: 20px;
        }
      }
        .text{
          width: 180px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

        }
      }
    }
  }

  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>

