import { AuthAPI } from '@/api';
import store from "@/store/index";

const PagesTitle = {
  namespaced: true,
  state: {
    data: [],
    loadeddataRegPage: false,
  },
  mutations: {

    setData(state, data) {
      state.data = data
    },

    load(state) {
      state.loadeddataRegPage = true;
    },
  },
  actions: {

    async updateData({ commit }) {
        let elem = {
            page_name:'reg_page'
        }
      const data = await AuthAPI.regPagesTitle()
        .then(res => res.data.data);
      commit('setData', data);
      commit('load')
      return data.data;
    },
  
  },
  getters: {

    data(state) {
      return state.data;
    },
    dataRegPage(state) {
      try{
      let title = state.data.find(x=>x.page_name=="reg_page").data;
      return title
      }
      catch(err)
      {
        return null;
      }
    },
    dataSingInPage(state) {
      try{
      let title = state.data.find(x=>x.page_name=="signIn_page").data;
      return title
      }
      catch(err)
      {
        return null;
      }
    },
    dataSignBackPage(state) {
      try{
      let title = state.data.find(x=>x.page_name=="signBack_page").data;
      return title
      }
      catch(err)
      {
        return null;
      }
    },

    isLoaded(state) {
      return state.loadeddataRegPage;
    },
  }
};

export { PagesTitle };
