<template>
  <main dir="rtl" style="background: #005d79">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
          {{ $ml.get("שליחת מתנה") }}
        </span>
      </template>
      <span slot="additional">
        <SidebarToggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading style="background: white" class="sm-cont">
      <div class="main-block">
        <div class="header">
          {{
            $ml.get(
              'מלאו את הפרטים בכרטיס הברכה שישלח למקבל המתנה עם המנוי'
            )
          }}
        </div>
        <div class="main-body">
          <div class="main-bordered">
            <div>
              <div style="display: inline-block; margin-left: 8px">
                {{ $ml.get("היי") }}
              </div>
              <div style="display: inline-block">
                <InputCustom
                  :placeholder="$ml.get('שם מקבל המתנה')"
                  style="width: 140px"
                  :important="true"
                  v-model="reciver"
                />
              </div>
            </div>

            <div style="margin-top: 26px;">
              <CustomSelect
              v-if="$ml.current=='heb'"
              v-model="cause"
              :options="options"
              :default="'בגלל שסתם רציתי לפנק'"
              class="select"
            />
            <CustomSelect
              v-else-if="$ml.current=='ar'"
              v-model="causeAr"
              :options="optionsAr"
              :default="'لأنني أردت فقط تدليل نفسي'"
              class="select"
            />
            <CustomSelect
              v-else-if="$ml.current=='ru'"
              v-model="causeRu"
              :options="optionsRu"
              :default="'Потому что я просто хотел побаловать себя'"
              class="select"
            />
            <CustomSelect
              v-else-if="$ml.current=='eng'"
              v-model="causeEng"
              :options="optionsEng"
              :default="'Because I just wanted to pamper myself'"
              class="select"
            />
            </div>

            <div style="max-width: 300px; margin: auto; left: 0; right: 0; font-family: 'App';font-style: normal;font-weight: normal;font-size: 18px;line-height: 35px;text-align: right;color: #00465B; margin-top: 22px; margin-bottom: 8px;">
              {{ $ml.get("קניתי לך מתנה מיוחדת, מנוי לאפליקציית Playlist עם כ-1,000 משחקים חברתיים שמשוחקים מחוץ למסך, ועוד כ-1,000 שאלות לשיחה זורמת וכייפית") }}.<br/> {{ "אשמח לשמוע איך נהנית מהמתנה" }}.
            </div>

            <div>
              <div style="display: inline-block; margin-left: 8px">
                {{ $ml.get("מאת") }}
              </div>
              <div style="display: inline-block;margin-top: 12px;" >
                <InputCustom
                  :placeholder="$ml.get('שם מעניק המתנה')"
                  style="width: 140px"
                  :important="true"
                  v-model="sender"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-title">
          {{ $ml.get('ניתן יהיה לערוך את ההודעה לפני השליחה') }}
        </div>
        <div class="bottom-button">
          <app-btn @click="share" :class="disabledBtn ? 'active':'disabled'" class="btn-d" >
            {{ $ml.get('המשך') }}
          </app-btn>
        </div>
        <div class="footer-block">
          {{ $ml.get("הודעת אישור תשלח אליך כשהנמען יפתח את המתנה") }}
        </div>
        <img alt="" style="position:absolute;position: absolute;margin: auto;left: 0;right: 0;width: 100%;bottom: 0;z-index: 1;" src="@/assets/img/mask_group2.png"/>
      </div>
    </app-loading>
  </main>
</template>
<script>
import Confirm from "@/components/modals/modal/Confirm";
import SimpleHeader from "@/components/SimpleHeader.vue";
import Payment from "@/components/modals/Payment";
import { SubscribeAPI, AuthAPI, UserAPI } from "@/api";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Btn from "@/components/ui/app-btn/AppBtn";
import { mapGetters } from "vuex";
import CustomSelect from "@/components/ui/CustomSelectReason.vue";
import SendEmailPhoneSend from "@/components/modals/SendEmailPhoneSend.vue";
import AppBtn from "@/components/ui/app-btn/AppBtn.vue";
export default {
  data() {
    return {
      type: null,
      sender: null,
      reciver: null,
      cause: "בגלל שסתם רציתי לפנק",
      options:[
        "בגלל שסתם רציתי לפנק",
        "לכבוד יום ההולדת",
        "לכבוד החג",
        "גלל שסבא וסבתא אוהבים אותך",
        "לכבוד החופש הגדול",
        "לכבוד האירוע",
        "לכבוד ההחלמה",
        "לכבוד ההישג המדהים",
        "לכבוד הטיול",
        "לכבוד יום המשפחה",
        "לכבוד הביקור",
        "בגלל שרציתי לאחל הצלחה",
        "בגלל שאני אוהב אותך",
        "בגלל שאני חושב עליך",
        "בגלל שסתם רציתי לפנק",
      ],
      causeEng: "Because I just wanted to pamper myself",
      optionsEng:[
      "Because I just wanted to pamper myself",
      "In honor of the birthday",
      "In honor of the holiday",
      "Roll that grandparents love you",
      "In honor of the big holiday",
      "In honor of the occasion",
      "In honor of recovery",
      "In honor of the amazing achievement",
      "In honor of the trip",
      "In honor of Family Day",
      "In honor of the visit",
      "Because I wanted to wish you success",
      "Because I love you",
      "Because i think of you",
      "Because I just wanted to pamper myself"
      ],
      causeRu: "Потому что я просто хотел побаловать себя",
      optionsRu:[
      "Потому что я просто хотел побаловать себя",
      "В честь дня рождения",
      "В честь праздника",
      "Бросьте, что бабушка и дедушка любят вас",
      "В честь большого праздника",
      "В честь этого события",
      "В честь выздоровления",
      "В честь удивительного достижения",
      "В честь поездки",
      "В честь Дня семьи",
      "В честь визита",
      "Потому что я хотел пожелать тебе успеха",
      "Потому что я тебя люблю",
      "Потому что я думаю о тебе",
      "Потому что я просто хотел побаловать себя"

      ],
      causeAr: "لأنني أردت فقط تدليل نفسي",
      optionsAr:[
      "لأنني أردت فقط تدليل نفسي",
      "تكريما لعيد الميلاد",
      "تكريما للعطلة",
      "لفة أن الأجداد يحبونك",
      "تكريما للعيد الكبير",
      "تكريما لهذه المناسبة",
      "تكريما للشفاء",
      "تكريما للإنجاز المذهل",
      "تكريما للرحلة",
      "تكريما لعيد الأسرة",
      "تكريما للزيارة",
      "لأنني أردت أن أتمنى لك النجاح",
      "لاننى احبك",
      "لأنني أفكر فيك",
      "لأنني أردت فقط تدليل نفسي"

      ]
    };
  },
  watch: {},
  async created() {
  },
  methods: {
    share() {
      let userAgent = window.navigator.userAgent;
      let type = "";
      let cause = this.cause;
        switch (this.promo.type)
            {
                case 'month':
                type = 'חודשי';
                    break;
                case 'six':
                type = 'ל-6 חודשים';
                    break;
                case 'games':
                type = 'ל-30 משחקים';
                    break;
                default:
                type = 'חודשי';
                    break;
            }
        let str = this.$ml.get("היי ")+this.reciver+"\n, "+cause+"\n"+this.$ml.get("קנינו לך מתנה מיוחדת - מנוי ש לאפליקציית פלייליסט עם כ 1,000 משחקים חברתיים שמשוחקים מחוץ למסך, ועוד כ 1,000 שאלות לשיחה זורמת וכייפית.")
      +this.$ml.get("נשמח לשמוע איך נהנית מהמתנה.")+" \n\n"+this.$ml.get("קוד המתנה להזנה: ")+this.promo.value+ "\n"+this.$ml.get("קישור לאפליקציה באנדרואיד:")+" https://play.google.com/store/apps/details?id=com.playlistgame.app,"+this.$ml.get(" באפל:")+" www.playlistgame.com ";
        
      if(userAgent.includes('ExpoLogin'))
      {
       window.ReactNativeWebView.postMessage(str);
      }
      else if(userAgent.includes('Mobile'))
      {
        navigator.share({
        title: this.$ml.get("שובר מתנה Playlist"),
        text: str,
        });
      }
      else
      {
        
      this.$modal.show(
        SendEmailPhoneSend,
      { 
          sender: this.sender,
          reciver: this.reciver,
          cause: cause
      },
        {
          clickToClose:false,
          adaptive: true,
          height: "320px",
          width: "345px",
        }
      );
      }
    },
  },
  components: {
    Btn,
    Confirm,
    SimpleHeader,
    SidebarToggler,
    CustomSelect,
    AppBtn,
  },
  computed: {
    ...mapGetters({
      promo:"promo-store/data",
      prices: "user/getPrices",
      settingsPlaylist: "playlist/settings",
      settingsShootlist: "shootlist/settings",
      email: "user/getEmail",
    }),
    user() {
      return this.$store.getters["user/data"];
    },
    direction() {
      return this.$store.getters["lang-store/direction"];
    },
    school() {
      return this.$store.getters["user/school"];
    },
    disabledBtn() {
      if ((this.sender != "" && this.sender !=null) && (this.reciver != "" && this.sender !=null)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.links{
  text-align: left;
    font-family: 'App';
    margin-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #00465B !important;


  color: #00465B !important;
  a{
    color: #00465B !important;
  }
}
.code-block{
  width: 300px !important;
  height: 38px !important;
  margin: auto;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  background: #DDF0F5;
  border-radius: 2px;
  margin-top: 26px;
  font-family: 'App';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  color: #00465B;
}
.main-block {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background: #ddf0f5;
  min-height: 700px;

  .header {
    width: 100%;
    max-width: 216px;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 21px;

    font-family: 'App';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */

    text-align: center;

    color: #00465B;
  }

  .main-body {
    width: 100%;
    max-width: 355px;
    height: 440px !important;
    background: #ffffff;
    border-radius: 10px;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 15px;
    padding: 5px;
    .main-bordered {
      padding: 23px;
      padding-top: 31px;
      width: 100%;
      height: 100%;
      border: 2px solid #ddf0f5;
      border-radius: 6px;
    }
  }

  .bottom-title {
    width: 100%;
    max-width: 345px;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 30px;
    text-align: center;

    font-family: "App";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: #00465b;
  }

  .bottom-button {
    z-index: 10;
    width: 100;
    margin-top: 11px;

    .btn-d {
      z-index: 2;
      width: 355px;
      height: 48px;

      background: #fdc671;
      border-radius: 24px;

      margin: auto;
      font-family: "App";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #000000;

      &.disabled {
      background: #c4c4c4 !important;
      color: #4d4d4d !important;
    }
    }
  }

  .footer-block {
    z-index: 10;
    width: 100%;
    max-width: 239px;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 13px;

    font-family: "Kartiv";
    // font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */

    text-align: center;

    color: #00465b;
  }

  .bottom-img {
    // scale: 1.2;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
  }
}
</style>
