<template>
  <main class="teachers-reports" style="background: #005d79;">
    <SimpleHeader route="/main">
      <template v-slot:title>
        <span>
        {{$ml.get('עמידה ביעדים')}}
        </span>
      </template>
      <span slot="additional">
        <sidebar-toggler />
        <router-link :to="'/'" class="home-anchor" style="color: white;">
          <svg
                    width="22"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="position:absolute; top: 4px; right: 24px;"
                  >
                    <path
                      d="M10.3312 0.926701L10.2632 0.863573L10.1951 0.926701L0.931976 9.52313L0.85877 9.59106L0.926614 9.66436L1.78441 10.591L1.85245 10.6645L1.92585 10.5964L2.59093 9.9786V17V17.1H2.69093H9.00112H9.10112V17V10.7907H11.4252V17V17.1H11.5252H17.8354H17.9354V17V9.9786L18.6005 10.5964L18.6739 10.6645L18.7419 10.591L19.5997 9.66436L19.6675 9.59106L19.5943 9.52313L10.3312 0.926701ZM16.4733 8.6246V15.6381H12.8872V9.42883V9.32883H12.7872H7.73908H7.63908V9.42883V15.6381H4.05297V8.6246L10.2632 2.85187L16.4733 8.6246Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.2"
                    />
                  </svg>
        </router-link>
      </span>
    </SimpleHeader>
    <app-loading
      :loading="loading.sync"
      class="sm-cont" style="background: #fff;">
      <template v-if="!loading.sync">
        <div
          class="reports-wrapper">
          <div class="header">
            <div class="title">{{user.surname+' '}}{{user.name}}</div>
            <div :style="{direction:direction}" class="description">
              <span> {{$ml.get('דו“ח זה מספק לכם ולהנהלה מעקב')}}</span> <br>
              <span> {{$ml.get('התקדמות להשגה מוצלחת של היעדים שהוגדרו')}}</span>
            </div>
          </div>
          <div class="option-buttons">
            <button
              @click="flag='reports'"
              :class="  flag==='reports' ? 'active' :  'unactive'"
            > {{$ml.get('עמידה ביעדים')}} </button>

            <button
              @click="flag='goals'"
              :class="flag==='goals' ? 'active' :  'unactive'"
            >{{$ml.get('דו“ח כיתתי')}}</button>
          </div>
<!--          <transition name="fade">-->
          <div class="reports-list" v-if="flag==='reports'" key="reports">
            <Report @swipe="swipe" :goals="goals" />
          </div>
          <div class="goals-list" v-else key="goals">
            <Goals :swiped="goalsOpen"   />
          </div>
<!--          </transition>-->
        </div>

      </template>
    </app-loading>
  </main>
</template>

<script>

import SimpleHeader from "@/components/SimpleHeader.vue";
import SidebarToggler from "@/components/SidebarToggler.vue";
import Report from "@/components/teacher/reports/Report";
import Goals from "@/components/teacher/reports/Goals";
import {TeacherAPI} from "@/api";

export default {
  data() {
    return {
      index: 0,
      flag: 'reports',
      loading: {
        next: false,
        sync: false
      },
      goalsOpen: '',
      goals: [],
      reports: [],
    };
  },
  created() {
    window.fbq("track", "עמידה ביעדים");
  this.loading.sync = true;
    TeacherAPI.getReportData().then(res=>{
      this.goals = res.data;
      this.loading.sync = false;
    }).catch()
  },
  components: {

    // GiftLine,
    SimpleHeader,
    SidebarToggler,
    Report,
    Goals,
  },
  computed:{
    user(){
      return this.$store.getters['user/data'];
    },
    direction(){
      return this.$store.getters['lang-store/direction']
    }
  },
  methods: {
    swipe(data){
      this.goalsOpen = data;
      this.flag='goals'
    }
  }
};
</script>
<style scoped lang="scss">
.teachers-reports {
  background: white;
  display: flex;
  flex-direction: column;

  .app-loading {
    flex-grow: 1;
    min-height: 450px;

    .reports-wrapper {
    .header{
      .title{
        margin: 16px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #06364B;
      }
      .description{
        margin: 0 auto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #000000;
      }
    }
    .reports-list{
      position: relative;
      width: 100%;
    }
      .option-buttons{
        margin: 25px 0 15px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
          width: 50%;
          border-bottom: 1px solid  #C4C4C4;
          &.active{
            color: #06364B;
            border-bottom: 6px solid  #00465B;
          }
          &.unactive{
            color: #4D4D4D;
           padding-bottom: 6px;
          }
        }
      }
    }
  }
}
</style>