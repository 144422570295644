<template>
  <app-loading :loading="loading.sync">
  <div class="goal-wrapper" v-if="!loading.sync">

    <div style="padding:0 0 20px 0" class="select-wrapper">
      <SelectTeacher
        style="margin: 0 auto"
        @input="filter"
        class="select-i"
        placeholder="Chose option"
        :key="lang"
        :options="getTranslatedDates.dates"
        v-model="before.selected"/>
    </div>

    <div class="content-info">
      <div class="question-wrapper">
        <FreeActivity
          :open="swiped==='free'"
          :goals="goals"
          :total="data.played.students"
          :teacher="data.played.teacher"
        />
        <Creativity
          :open="swiped==='creativity'"
          :total_playlist="data.request.total_playlist"
          :total_shootlist="data.request.total_shootlist"
          :playlists="data.request.playlist"
          :shootlists="data.request.shootlist"
           />

        <ClassroomGames
          :open="swiped==='games'"
          :gameClass="data.gameClass"
           />
        <EducationTime
          :open="swiped==='education'"
          :educationClass="data.educationClass"
          />

        <GamesInvention
          v-if="$store.getters['user/data'].position!=='prof-teacher'"
          :status="$store.getters['teacher-targets/inventionCourse']"
          :value="$ml.get('הפעלת קורס המצאת משחקים')"
          :title="$ml.get('קורס המצאת משחקים')" />

        <GamesInvention
          v-if="$store.getters['user/data'].position!=='prof-teacher'"
          :status="$store.getters['teacher-targets/gamesShifts']"
          :value="$ml.get('הפעלת קורס המצאת משחקים')"
          :title="$ml.get('תורני משחקים')"  />
      </div>
    </div>

  </div>
  </app-loading>
</template>

<script>

import FreeActivity from "./reach-goals/FreeActivity";
import Creativity from "./reach-goals/Creativity";
import ClassroomGames from "./reach-goals/ClassroomGames";
import EducationTime from "./reach-goals/EducationTime";
import GamesInvention from "./reach-goals/GamesInvention";
import SelectTeacher from "../SelectTeacher";
import {TeacherAPI} from "@/api";
import axios from 'axios'
export default {
  components: {
    EducationTime,
    ClassroomGames,
    Creativity,
    FreeActivity,
    GamesInvention,
    SelectTeacher,
  },
  props: {
    swiped:{

    }
  },
  created() {
    this.loading.sync = true;

    this.before.selected = this.getTranslatedDates.selected;

    axios.post('/teacher/report/fullreport', {filter: '0'}).then(res=>{
      this.data = res.data.data;
      this.loading.sync = false;
    });


  },
  watch:{
    lang(){
      // this.before.filtered = this.getTranslatedDates.dates;
      this.before.selected = this.getTranslatedDates.selected;
      this.teacherTranslate();
    }
  },
  computed:{
    getTranslatedDates(){
      return this.$store.getters['lang-store/dates'];
    },
    lang(){
      return this.$store.getters['lang-store/data']
    },
    direction(){
      return this.$store.getters['lang-store/direction']
    }
  },
  methods: {
    checkDate(data) {
      return this.getTranslatedDates.dates.filter(item => {
        return item.name === data;
      })[0].index;
    },
    filter(data) {

      TeacherAPI.getPLaylistData(this.checkDate(data)).then(res=>{
        this.data = res.data.data;
      }).catch();
    },
    swipeOpen(value){
      switch (value) {
        case 'free':
        break;
        case 'education':
        break;
        case 'games':
        break;
        case 'creativity':
        break;
      }
    }
  },
  data() {
    return {
      data: [],
      loading:{
        sync: false,
      },
      goals: [
        {
          title: 'פעילות חופשית',
          name: '46 משחקים ששוחקו:',
          arr: [
            {name: ' כדור הפלא', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
            {name: 'אנשים קטנים', link: 'google.com'},
          ]
        },
        {
          title: 'מדברים בכיף',
          name: '28 שאלות שנשאלו:',
          arr: [{name: ' כדור הפלא', link: 'google.com'}, {name: 'השגריר', link: 'google.com'}]
        },
      ],



        before: {
          options: [
            {name: 'שנה', str: 'Year', index: 0},
            {name: 'ינואר', str: 'Jan', index: 1},
            {name: 'פברואר', str: 'Feb', index: 2},
            {name: 'מרץ', str: 'Mar', index: 3},
            {name: 'אפריל', str: 'Apr', index: 4},
            {name: 'מאי', str: 'May', index: 5},
            {name: 'יוני', str: 'Jun', index: 6},
            {name: 'יולי', str: 'Jul', index: 7},
            {name: 'אוגוסט', str: 'Aug', index: 8},
            {name: 'ספטמבר', str: 'Sep', index: 9},
            {name: 'אוקטובר', str: 'Oct', index: 10},
            {name: 'נובמבר', str: 'Nov', index: 11},
            {name: 'דצמבר', str: 'Dec', index: 12},
          ],
          filtered: [],
          selected: 'שנה',
        }
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.goal-wrapper {
  width: 100%;

  h2 {
    font-weight: 600;
    font-size: 24px;
    color: #333333;
  }
}
</style>
