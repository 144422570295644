<template>
  <div class="wrapper">
  <transition name="fade-scale">
    <div v-if="shootlistFirstTime" class="help-popup fade-scale">
      <div :style="{direction:direction}" class="info-wrapper ">
        <div class="info-element border">
          <img class="help-element" src="../../assets/img/icons/settings.svg" alt="">

          <span>{{$ml.get("ספרו לנו עם מי אתם משחקים ונתאים לכם את המשחק עבורכם")}}</span>

        </div>
        <div class="info-element border">
          <svg class="help-element" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.92852 11.9547L9.43215 10.2772C9.10776 9.12585 8.36768 6.23728 8.00243 3.89409C8.66531 1.27092 10.5302 1.88477 11.3798 2.51959C12.8981 4.65595 16.7656 9.81237 19.0811 12.7808C20.6424 14.7823 19.4527 16.6036 17.262 16.4938C14.3914 16.3498 7.92531 16.0991 4.01783 15.682C1.52793 14.4112 2.92083 12.6676 3.92852 11.9547Z" fill="white" stroke="#01465B"/>
          </svg>
          <span>{{$ml.get("לחיצה על החץ מראה מי הבא שעונה")}}</span>
        </div>
        <div class="info-element">
          <img class="help-element" src="../../assets/img/icons/pointer.svg" alt="">
          <span>
          <span>{{$ml.get(" החלקה ימינה - השאלה נענתה החלקה שמאלה - השאלה לא נענתה")}}</span>
          <br>

          <span style="margin-top: 5px;display: inline-block" >{{$ml.get("אפשרי גם בעזרת הכפתורים למטה")}}</span>
            </span>
        </div>
      </div>
      <app-btn class="close-b" styling="inline" @click="watched">
        {{$ml.get("הבנתי")}}
      </app-btn>
    </div>
  </transition>
    <div v-if="shootlistFirstTime" class="wrapper-popup">
    </div>
  </div>
</template>

<script>
  //todo animetion fade-scale
export default {
  data() {
    return {
      loaded: false,
    }
  },
  props:{
    keyValue:{

    }
  },
  methods: {
    watched(e) {
      this.$store.commit('tutorials/setTutorial', this.keyValue);
      this.$store.dispatch('tutorials/updateData');
    }
  },
  computed: {
    shootlistFirstTime() {
      return this.loaded ? !this.$store.getters['tutorials/isUsed'](this.keyValue) : false

      // return this.loaded ? !this.$store.getters['user/shootlistFirstTime'] : false
    },
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  },
  mounted() {
    setTimeout(() => this.loaded = true, 1000);
  }
}
</script>

<style lang="scss" scoped>
.wrapper-popup{
  position: fixed;
  z-index: 9;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  right: 0;
  top: 0;
}

.help-popup {
  position: absolute;
  z-index: 10;
  top: 46px;
  right: 5px;
  width: 266px;

  border-radius: 10px;
  background: #FFFFFF;
  border: 2px solid #3FBCD4;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    right: 7%;
    top: -8px;

    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
  &.fade-scale {
    &-enter {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-to {
      opacity: 1;
      transform: scale(1);
    }
    &-leave {
      opacity: 1;
      transform: scale(1);
    }
    &-leave-to {
      opacity: 0;
      transform: scale(0.8);
    }
    &-enter-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s cubic-bezier(0.635, 1.650, 0.410, 0.865);
    }

    &-leave-active {
      transition: opacity 0.2s ease;
      transition: transform 0.2s ease;
    }
  }
  .help-element{
   color: #01465B;
   margin-left: 12px;
   margin-top: 6px;
  }
  .info-element{
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    color: #01465B;
    align-items: flex-start;
    span{

    }
    @media screen and (max-width: 600px){
      font-size: 14px;
    }
    &.border{
      border-bottom: 1px solid #B8EDFE;
    }
  }
  .close-b{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    background: #3FBCD4;
    border-radius: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #FFFFFF;
    margin: 20px auto;
  }
}

</style>