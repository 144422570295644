<template>
  <welcome-wrapper button="הבא" :onClick="onSubmit">
    <div class="confirm">

      <div class="title heebo">
        {{$ml.get("נא להקליד את הסיסמה")}}
        <br />
        {{$ml.get("שקיבלת כעת בטלפון")}}
      </div>
      <code-input :value="code" :onChange="onChange"></code-input>
      <!--div class="hint">
        לא קיבלת? נסה שנית
        <br>
        לחת כאן לקבלת קוד
      </div-->
      <div class="hint">
        <a @click="reSend" style="cursor:pointer;">{{$ml.get("לחץ כאן לקבלת קוד")}}</a>
      </div>
    </div>
  </welcome-wrapper>
</template>

<script>
import WelcomeWrapper from "@/components/WelcomeWrapper";
import axios from "axios";
import CodeInput from "@/components/CodeInput";

export default {
  data: () => ({ code: "", hash: "" }),
  async mounted() {
    const options = {
      method: "POST",
      data: { phone: this.$route.params.phone },
      baseURL: `${axios.defaults.baseURL}/user/registrateByPhone`
    };
    let self = 0;
    await axios(options)
      .then(function(response) {
        self = response.data.hash;
      })
      .catch(function(error) {
      });
    this.hash = self;
  },
  methods: { onSubmit, onChange, reSend, onAuthEnd },
  components: { WelcomeWrapper, CodeInput }
};

function onAuthEnd() {
  var shared = this.$store.getters.shared;

  if (!shared) {
    this.$router.replace({ name: "main" });
  } else {
    var query = { name: shared.type, params: {} };
    if (shared.type == "playlist") {
      query.params.id_playlist = shared.id;
    } else {
      query.params.id_shootlist = shared.id;
    }
    this.$router.replace(query);
    this.$store.commit("removeShared");
  }
}

function reSend() {
  const options = {
    method: "POST",
    data: { phone: this.$route.params.phone },
    baseURL: `${axios.defaults.baseURL}/user/registrateByPhone`
  };
  let self = 0;
  axios(options)
    .then(function(response) {
      self = response.data.hash;
    })
    .catch(function(error) {
    });
  this.hash = self;
}

function onSubmit() {
  if (!this.code) return;

  const options = {
    method: "POST",
    data: { phone: this.$route.params.phone, code: this.code, hash: this.hash },
    headers: { "Content-Type": "application/json" },
    baseURL: `${axios.defaults.baseURL}/user/confirmByPhone`
  };

  let self = this;
  axios(options)
    .then(res => {
      //alert("Success register");
      self.$store.commit("user/auth", res.data.auth);
      self.$store.dispatch("user/updateData").then(res => {
        self.onAuthEnd();
      });
    })
    .catch(error => {
      self.$store.commit("alert", error);
    });
}

function onChange(code) {
  this.code = code;
}
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  color: #fff;
}

.hint {
  margin-top: 27px;
  margin-bottom: 88px;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  font-family: sans-serif;
}
</style>
