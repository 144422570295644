export default {
  namespaced: true,
  state: {
    trackData:null
  },
  mutations: {
    saveData(state, data) {
      state.trackData = data;
    },
  },
  getters: {
    data(state) {
      return state.trackData;
    }
  }
};
