<template>
  <main dir="rtl" class="img-about-bg">

    <simple-header >
      <template v-slot:title>
        <span>{{$ml.get('תנאי שימוש')}}</span>
      </template>
      <span slot="additional">
        <sidebar-toggler v-if="$store.getters['user/isLogged']"  />
      </span>
    </simple-header>


    <section :style="{direction:direction}" style="background: white; font-family: 'App';" class="sm-cont">
      <div style="text-align: center;">
        <h4 class="heebo" style="font-weight: bold !important;">{{$ml.get('תקנון ותנאי שימוש לאפליקציה-  פלייליסט –')}}</h4>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">1	מטרת האפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">האפליקציה “פלייליסט” (להלן: "<b>האפליקציה</b>") נועדה לעודד משחקים ושאילת שאלות בקרב ילדים, נוער ובוגרים. האפליקציה מציעה מבחר משחקים ושאלות שניתן לשחק "אופ-ליין" אחד עם השני.</p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">בעל האפליקציה רשאי לשנות את ייעודה, להוסיף לה תכנים, מוצרים ו/או שירותים, תחומי עיסוק ומטרות לפי שיקול דעתו.</p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">2	הגדרות </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;"><b>"המשתמש/ת"</b> ו/או <b>"הגולש/ת"</b>– כל אדם, אשר משתמשים באפליקציה. למעט משתמשים לא "פרטיים", כגון, עסקים מקומות עבודה, ארגונים וכיוב'. עם משתמשים שאינם פרטיים אופן השימוש באפליקציה יוסדר בחוזה נפרד וייעודי."</p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;"><b>"בעל האפליקציה"</b> –  אפי מאיר אשר כתובת הדואר האלקטרוני שלו היא <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a></p>
          <p class="temporary-text"><b>"האפליקציה"</b> – אפליקציה ששמה  פלייליסט ו/או אתר ה-שיב  של האפליקציה.</p>
          <p class="temporary-text"><b>"התקנון" ו/או "תנאי השימוש"</b> – מסמך זה על כל הוראותיו וסעיפיו. יובהר כי תקנון זה חל הן על שימוש באפליקציה והן על שימוש באתר האינטרנט של פלייליסט.</p>
          <p class="temporary-text"><b>"האפליקציה"</b> – אפליקציה ששמה  פלייליסט ו/או אתר ה-web  של האפליקציה.</p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">3	כללי </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.1</b>	תקנון זה, כולל בין היתר תנאי שימוש, הוראות בדבר שמירה על פרטיות והוראות בנושא שמירה על זכויות יוצרים. אנא קרא/י בעיון את כל תנאי השימוש קודם לשימוש באפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.2</b>	המשתמש/ת מסכים, בעצם פעולת הורדת האפליקציה ו/או התקנתה במכשיר ו/או השימוש בה, לכל הוראות התקנון. אם המשתמש אינו מסכים באופן מלא ו/או חלקי להוראות התקנון, עליו להמנע מכל שימוש באפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.3</b>	בעל האפליקציה מעביר למשתמש רישיון, לא ייחודי ולא בלעדי, להוריד את האפליקציה למכשיר בו הוא משתמש, ולהשתמש בה – אך ורק בהתאם להוראות התקנון.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.4</b>	המשתמש/ת מצהיר כי הוא מעל גיל 18, ואם הוא/היא מתחת לגיל 18 אזי מצהיר כי השימוש באפליקציה נעשית בהסכמת האחראי עליו/ה כנדרש בדין.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.5</b>	התקנון מנוסח בלשון זכר מטעמי נוחות, אולם מכוון לשני המינים. בתקנון זה יחיד משמעו גם רבים.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>3.6</b>	התקנון ותנאי השימוש חלים על כל שימוש באפליקציה, בכל אמצעי ו/או מכשיר שהוא.
          </p>
          
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">4	פרטיות </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>4.1</b>	בעל האפליקציה מכבד את פרטיות הגולשים ופועל לפי כל דין. לקריאת מדיניות הפרטיות המלאה אנא הכנסו לעמוד מדיניות הפרטיות.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>4.2</b>	בעל האפליקציה אוסף, בהסכמת הגולשים, פרטי יצירת קשר כגון – שם, ו/או כתובת דואר אלקטרוני, ו/או מספר טלפון - וזאת על מנת לצרפם לרשימת תפוצה, לשלוח סקירות ומידע בתחומי הפעילות של האפליקציה, ו/או לשלוח מידע שיווקי או פרסומי אשר עשוי לעניין את המשתמשים. אם אינכם מעוניינים  להיכלל ברשימה, או אם פרטי הקשר שלכם נכנסו בשוגג לרשימת התפוצה יש לשלוח הודעת הסרה (הודעת סירוב) לכתובת  <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a>
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>4.3</b>	או ללחוץ על הקישור "הסר" בתחתית המייל/ניוזלטר שנשלח.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>4.4</b>	בעל האפליקציה לא ימסור מידע אודות המשתמשים לכל צד ג', למעט במקרים המפורטים במדיניות הפרטיות.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">5	קניין רוחני </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.1</b>	הקניין הרוחני באפליקציה – לרבות אך לא רק - סימני מסחר, זכויות יוצרים, מדגמים ו/או עיצובים, לוגואים, מאגרי מידע, טקסטים, תמונות, קטעי וידאו , קטעי סאונד, דימויים, סודות מסחריים ו/או כל נכס/קניין רוחני אחר, שייכים לבעל האפליקציה, ו/או לצד ג' אשר הרשה את שימושם או הצגתם. זאת אלא אם צוין אחרת. אם הנך מזהה הפרה , אנא פנה אלינו בכתובת  efi.academia@gmail.com ובעל האפליקציה יברר ויסדיר את העניין תוך זמן סביר מקבלת הפניה.  
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b><u>5.2	אסור להעתיק מהאפליקציה תוכן כגון משחקים, שאלות, סרטוני משחקים, אין להעתיק את מבנה האפליקציה. אין למשתמש אישור ו/או רשות, לעשות כל שימוש, לרבות העתקה, פרסום, העמדה לרשות הציבור, שידור, עשיית יצירה נגזרת, בקניין הרוחני באפליקציה, אלא אם כן קיבל לכך אישור בכתב מבעל האפליקציה.</u></b>
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.3</b>	אין לעשות שימוש בשם האפליקציה ו/או בתוכנה בכל דרך אשר יכולה להטעות, לפגוע במוניטין, ליצור גניבת עין או בכל דרך אחרת אשר יכולה לגרום לנזק ממוני או אחר לבעל האפליקציה ו/או לצד ג'.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.4</b>	ייתכן ובאפליקציה יהיו קישורים (hyperlinks) אשר מפנים לאתרים  ו/או אפליקציות חיצוניות (להלן <b>'אתרים חיצוניים'</b>) שאינם בבעלות בעל האפליקציה ואינם בשליטתו. הקניין הרוחני באתרים חיצוניים הינו בבעלות צד ג' ואין לבעל האפליקציה כל זכות בהם. אין בעצם הטמעת הקישורים באפליקציה כדי להרשות, או לאשר כל פעולה שהיא ביחס לנכסי קניין רוחני באתרים החיצוניים. אם מצאת את האתרים החיצוניים כפוגעניים או ככאלה אשר מפרים את הדין אנא ידע את בעל האפליקציה בהקדם בכתובת <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a> והוא יפעל לבירור התלונה וטיפול בה.          
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.5</b>	בנוסף, באפליקציה עשויות להופיע פרסומות שמקורן בחברות ו/או עסקים שאינם בבעלות ו/או באחריות בעל האפליקציה. אין לבעל האפליקציה כל אחריות בנוגע לתוכן פרסומות אלו. אם נתקלתם בפרסומת פוגענית ו/או פרסומת אשר מפרה את החוק אנא פנו אל בעל האפליקציה בכתובת <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a> בבקשה להסרת הפרסומת.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.6</b>	אין לבצע כל  שימוש ביצירה אשר מופיעה באפליקציה, מבלי לקבל לכך אישור מראש.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>5.7</b>	האפליקציה מאפשרת למשתמשים להציע ו/או לשתף ו/או להעלות רעיונות למשחקים ולשאלות. מובהר בזאת כי משתמש שהציע ו/או שיתף ו/או פרסם שאלה או משחק, מאשר בזאת לבעל האפליקציה לעשות כל שימוש בשאלה ו/או במשחק, לכל מטרה שהיא, ללא הגבלה של זמן, מקום גיאוגרפי, בכל מדיום שהוא, וזאת ללא כל תמורה. המשתמש מוותר בזאת על זכותו המוסרית, ובעל האפליקציה אינו חייב לתת כל קרדיט עבור השימוש במשחק ו/או בשאלה.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">6	השימוש באפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: normal !important;">כללי - </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            לאחר שהמשתמש הוריד את האפליקציה למכשיר, ניתן לעשות בה שימוש מוגבל ללא תשלום. לאחר שהמשתמש שיחק כ-25 משחקים וגם שאל 40 שאלות, המשך השימוש באפליקציה יהיה כרוך בהרשמה ותשלום. בעל האפליקציה רשאי לקבוע כי השימוש יהיה כרוך בהזנת פרטים אישיים. בעל האפליקציה רשאי לשנות את היקף השימוש החינמי באפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;  margin-bottom: 0px;">
            המשתמש יוכל לבחור בין שתי דרכים להרשמה בתשלום:
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-right: 20px;">
            <b>1.</b>	תשלום מנוי חודשי.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;  margin-right: 20px;">
            <b>2.</b>	תשלום מראש לתקופה קצובה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-bottom: 0px;">
            תהליך ההרשמה לאפליקציה:
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>6.1</b>	המשתמש יזין פרטים מזהים.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>6.2</b>	המשתמש יבחר את סוג החבילה בה הוא מעוניין
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>6.3</b>	 המשתמש יזין פרטי אשראי, יאשר את נכונות הפרטים ויאשר כי קרא את תקנון האפליקציה. אישור העסקה יהיה לאחר קבלת האישור מחברת האשראי.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>6.4</b>	בעל האפליקציה רשאי לעדכן מעת לעת את תעריפי השימוש באפליקציה. בעל האפליקציה יודיע למשתמשים מראש על עדכון תעריפים, ועל האופן שבו עליהם לאשר את המשך השימוש באפליקציה לאור השינויים.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>6.5</b>	המשתמש מתחייב להזין פרטים אישיים נכונים ומדויקים.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">7	ביטול הרשמה לאפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>7.1</b>	לאחר שנרשם בתשלום, המשתמש רשאי לבטל את הרשמתו לאפליקציה בהודעה לבעל האתר בכתובת <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a> בכפוף לאמור בסעיפים הבאים.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>7.2</b>	אם המשתמש במסלול תשלום מנוי חודשי, ביטול ההרשמה יכנס לתוקף ביום האחרון לפני מועד החיוב הבא, והחל ממועד זה המשתמש לא יחוייב.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>7.3</b>	אם המשתמש שילם מראש, עבור שימוש באפליקציה לתקופה קצובה, בעל האפליקציה לא ישיב לו תשלומים ששולמו עד למועד הביטול, וביטול ההרשמה יכנס לתוקף באופן מיידי.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>7.4</b>	בשאלות ופניות בנוגע לביטולים והחזרים אנא פנו לבעל האפליקציה באמצעות דואר אלקטרוני efi.academia@gmail.com והוא יבחן את בקשתכם וישיב תוך זמן סביר. 
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">8	הוראות שחרור מאחריות </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.1</b>	בעל האפליקציה ו/או מי מטעמו אינו אחראי לכל נזק, ממוני ו/או אחר אשר עשוי להיגרם למשתמש כתוצאה מהשימוש באפליקציה. 
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.2</b>	כל משתמש באפליקציה, עושה זאת על אחריותו המלאה, ועליו לנקוט בזהירות הנדרשת בנסיבות העניין..
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.3</b>	בעל האפליקציה רשאי לפרסם באפליקציה מעת לעת מאמרים, כתבות, דעות, סקירות וכיוב'. (להלן "פרסומים"). האמור בפרסומים הינו על דעת בעל האפליקציה בלבד ולמטרות סקירה והעשרה בלבד. אין לראות בפרסומים יעוץ מקצועי ו/או המלצה, ו/או הנחיה. ייתכן ויפלו טעויות, ו/או אי-דיוקים, ו/או מצב שבו תוכן בפרסום לא יהיה עדכני. בעל האפליקציה לא ישא באחריות בגין כל נזק שעשוי להגרם למשתמש בשל הסתמכות על האמור בפרסום, ולא יחוב כלפי המשתמש בגין הסתמכות כזו. 
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.4</b>	אם משתמש באפליקציה מצא תוכן כפוגעני, ו/או ככזה אשר עלול לגרום לנזק כלשהוא, עליו לפנות לבעל האפליקציה בבקשה להסרת התוכן. בעל האפליקציה יבחן את הפנייה וישקול את הסרת התכנים לפי שיקול דעתו.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.5</b>	האפליקציה מאוחסנת בשרת אשר מאובטח על ידי חברה חיצונית. בעל האפליקציה אינו אחראי לנזק אשר יגרם כתוצאה מפריצה חיצונית– בין היתר –  בשל הפצת/מכירת מידע כתוצאה מפריצה, שיבוש במידע, פגיעה בזכויות יוצרים או קניין רוחני אחר, או כל נזק אחר שיגרם כתוצאה מחבלה באפליקציה ובתכניה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.6</b>	בעל האפליקציה אינו אחראי לתוכן מודעות חיצוניות ( תמונות/קישורים)  אשר עשויות להופיע /להיטמע באפליקציה, או לתוכן/מידע אשר מופיע באתרים אליהם הקישורים מובילים. בעל האפליקציה אינו אחראי לבדיקה ו/או פיקוח על התוכן באתרים אליהם מפנים הקישורים.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>8.7</b>	אם נתקלת במודעה ו/או קישור פוגעני אשר מפנה לאתר חיצוני אנא הודע/י לבעל האפליקציה בקישור <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a> והוא יפעל להסדיר את העניין בהקדם האפשרי.
          </p>
        </div>
          <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">9	זמינות האפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>9.1</b>	בעל האפליקציה עושה כמיטב יכולתו כדי שהיא תפעל באופן תקין ורצוף. יחד עם זאת, אין התחייבות מצד בעל האפליקציה כי היא תפעל בכל עת. יתכנו שיבושים בזמינות/ צפייה / גלישה, אשר עשויים לנבוע מעדכונים ו/או עבודות תשתית, ו/או תקלות במכשיר האישי של המשתמש, ו/או בגלל ספק האינטרנט שלו, שרת חיצוני, או בשל תקלות בתשתית הגלישה שלו. כמו כן יתכנו תקלות הנובעות משירות האחסון של האפליקציה, כגון פריצה או גורמים אחרים. בעל האפליקציה ישתדל להודיע מראש על הפרעות מתוכננות בשימוש אולם אינו מחויב לכך.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>9.2</b>	 בעל האפליקציה שומר את הזכות, לפי שיקול דעתו, להפסיק ו/או לשנות את אופן פעילות האפליקציה, ו/או לשנות את תנאי השימוש בה. בעל האפליקציה לא יהיה אחראי כלפי כל צד ג' בשל כל נזק, ממוני ו/או אחר, אשר עשוי להיגרם לו כתוצאה מהפסקת הפעלת האפליקציה ו/או שינוי באופן הפעלתה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            כמו כן חלק מפעילות האפליקציה מתבססת על פלטפורמות חיצוניות, ואין כל התחייבות מצד בעל האפליקציה כי הן תפעלנה בכל עת.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>9.3</b>	בעל האפליקציה שומר לעצמו, לפי שיקול דעתו, את הזכות למנוע ו/או להגביל שימוש באפליקציה כלפי משתמש. הגבלה ו/או מניעת שימוש תינקט כלפי כל משתמש אשר יפר אחת ו/או יותר מהוראות תנאי השימוש באפליקציה. בעל האפליקציה לא יהיה אחראי כלפי כל נזק, ממוני ו/או אחר, אשר עשוי להיגרם למשתמש כתוצאה מהגבלה ו/או מניעת היכולת שלו להשתמש באפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>9.4</b>	באחריות המשתמש בלבד לוודא כי המכשיר שבבעלותו ומערכת ההפעלה שלו הולמים ומתאימים להפעלת האפליקציה. מובהר בזאת כי בעל האפליקציה אינו אחראי ואינו מתחייב כי האפליקציה תפעל על גבי כל מכשיר.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">10	יצירת קשר וזמינות בעל האפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            ניתן ליצור קשר עם בעל האפליקציה בדרכים הבאות:
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
          <a href="mailto:efi.academia@gmail.com">efi.academia@gmail.com</a>
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            בעל האפליקציה יעשה מאמץ להשיב לפניות לא יאוחר מ-5 ימי עסקים ממועד קבלת הפניה.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">11	איסור פגיעה באפליקציה ושימושים אסורים </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>11.1</b>	אין לבצע כל פעולה באופן ישיר או על ידי צד ג', אשר עשויה לפגוע באפליקציה, ו/או בזמינות הגלישה שלה, ו/או בתוכן המוצג בה, ו/או לשבש את פעולתה. אין לעשות שימוש בקוד תוכנה ו/או כל פעולה אלקטרונית/דיגיטלית  ו/או אוטומטית אחרת לרבות - וירוס ו/או 'תולעת' ו/או 'בוט' למטרת פגיעה כאמור באפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>11.2</b>	האפליקציה נועדה לשימוש אישי , לא מסחרי , בלבד. למשתמש ו/או לצד ג' אסור לבצע כל פעולה אשר נועדה לעשות שימוש בתכני האפליקציה למטרה מסחרית ו/או כל מטרה אחרת. בסעיף זה "תכני האפליקציה"– תכני טקסט, תמונות, עיצובים, גרפיקות, קטעי וידאו ו/או סאונד ו/או פרטים אישיים של המשתמשים, מידע סטטיסטי אודות פעילות המשתמשים, קוד תוכנה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>11.3</b>	המשתמש מתחייב לא לעשות כל שימוש שאינו חוקי, ו/או כזה אשר מנוגד לתנאי השימוש של האפליקציה.
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>11.4</b>	מבלי לגרוע באמור בשאר סעיפי התקנון, בעל האפליקציה שומר לעצמו את הזכות לחסום משתמש ו/או להגביל את גישתו באופן מיידי, לאחד ו/או יותר משירותי האפליקציה, וזאת במקרה שהמשתמש הפר אחת ו/או יותר מהוראות תקנון זה. 
          </p>
          
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">12	אחריות המשתמש </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>12.1</b>	המשתמש/ת והוא/היא בלבד אחראי/ת לכל נזק שיגרם כתוצאה מהפרת הוראות התקנון, והוא/היא ישפה את בעל האפליקציה בגין כל נזק כלכלי או אחר, שיגרם כתוצאה מהפרת הוראות התקנון לרבות הוצאות משפטיות ו/או אחרות שיהיו בזיקה להפרת הוראות התקנון.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">13	עדכונים ושינויים </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>13.1</b>	בעל האפליקציה שומר את הזכות לעדכן ו/או לשנות מעת לעת ולפי הצורך את תקנון האפליקציה. על בעל האפליקציה לא תחול החובה לעדכן את המשתמשים בשינויים בתקנון תנאי השימוש, תוקף השינויים יהיה מרגע העדכון, והם יחייבו את המשתמשים החל ממועד עדכונם.          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>13.2</b>	בעל האפליקציה יודיע על שינויים מהותיים באפליקציה ו/או בתקנון באמצעות פרסום הודעה בממשק האפליקציה. המשתמש יתבקש לאשר שינויים אלו, והמשך השימוש באפליקציה יהיה מותנה בהסכמה לשינויים.           </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            <b>13.3</b>	בעל האפליקציה רשאי בכל עת לעדכן ו/או לשנות את מבנה האפליקציה ו/או תוכנה. זאת לרבות שינוי בשירותים המוצעים באפליקציה ו/או תנאי הפעילות שלה.          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">14	תוקף תקנון האפליקציה </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-bottom: 0px;">
            התקנון יהיה בתוקף החל ממועד תחילת השימוש באפליקציה במכשיר של המשתמש, ועד המועד הבא:
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-right: 20px;">
            <b>1</b>	מחיקתה של האפליקציה 
          </p>
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-right: 20px;">
            <b>2</b>	שליחת הודעה בכתב לבעל האפליקציה בכתובת המייל <a href="efi.academia@gmail.com">efi.academia@gmail.com</a> לפיה המשתמש מודיע על ביטול ההסכם ומצהיר על הפסקת השימוש באפליקציה. ביטול ההסכם ייכנס לתוקף לאחר שנשלחה הודעת אישור מבעל האפליקציה למשתמש.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <h4 class="headings" style="font-weight: bold !important;">15	סמכות שיפוט  </h4>
        </div>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px;">
            הדין החל על כך סכסוך או מחלקות בנוגע לתקנון זה יהיה הדין הישראלי וסמכות השיפוט הבלעדית והייחודית בכל עניין הנוגע לתקנון תנאי שימוש זה יהיה לבית משפט אשר לו הסמכות העניינית בירושלים, ישראל.
          </p>
        </div>
        <br>
        <div style="text-align: right;">
          <p class="temporary-text" style="font-weight: normal; font-size: 16px; margin-bottom: 40px;">
            תקנון זה מהווה יצירה מוגנת וככזה מוגן על ידי חוק זכויות יוצרים. אין להעתיק, לפרסם ו/או לעשות כל שימוש ללא אישור מבעל הזכויות – עו"ד מוטי כהן <a href="moticohen.adv@gmail.com">moticohen.adv@gmail.com</a>
          </p>
        </div>




      </div>

      <!-- <router-link :to="{name: 'privacy-policy'}">
        <span class="fugaz">
          Privacy policy
        </span>
      </router-link> -->
    </section>


  </main>
</template>

<script>
  import SimpleHeader from "@/components/SimpleHeader.vue";
  import SidebarToggler from "@/components/SidebarToggler.vue";
export default {
  components: {
    SimpleHeader,
    SidebarToggler,
  },
  computed:{
    direction(){
      return this.$store.getters['lang-store/direction'];
    },
  },
  created(){
    window.fbq("track", "תנאי שימוש");
  }
};
</script>

<style lang="scss" scoped>
.headings{
  font-size: 17px;
  margin-bottom: 5px;
}
.temporary-text{
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
}
main {
  background-color: #eb5757;
  background-position: bottom -40px right -40px;
  background-repeat: no-repeat;
  background-size: auto;
  section {
    padding: 20px;
    // padding-bottom: 150px;
    h4 {
      font-weight: 400;
    }
  }
}
</style>
